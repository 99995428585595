import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const ProfessionalHIW=() => {
  const cards = [
    {
      imageSrc:
        "https://img.freepik.com/free-vector/accept-request-concept-illustration_114360-3219.jpg?w=740&t=st=1657087450~exp=1657088050~hmac=d1f5560651dc5782e7b0bcbe0dbe70a28361204bc8abd072c160f12c9f948fba",
      title: "Accept a Mock Interview Request or Mentorship Session Request",
      description:
        "Check the Mock Interview Requests or Mentorship Session Requests received from the students with a single click, and Accept those requests as per your suitability.",
    },

    {
      imageSrc:
        "https://img.freepik.com/free-vector/online-job-interview_52683-44077.jpg?t=st=1657087061~exp=1657087661~hmac=4d0716f754d3bd9b1590ca5fba10c383791222f6252a1f2affbbaa103b67e72c&w=1060",
      title: "After payment by the student, Go for it!",
      description:
        "Once a student pays for the Mock Interview or Mentorship Session to confirm it (after your acceptance), join the respective mock interview link or mentorship session link as per the schedule from the page of [Upcoming Sessions]. Conduct the Mock Interview or Mentorship Session of the student and provide real time Feedback during the mock interview or mentorship session, and Feedback along with the Action Items for Improvement in a written format after the mock interview or mentorship session (from [Completed Sessions] page)",
    },
  ];

  return (
    <Container>
      <SingleColumn style={{ paddingTop: "0" }}>
        <HeadingInfoContainer>
          <HeadingTitle style={{fontSize:"2.3rem"}}>For Professionals</HeadingTitle>
        </HeadingInfoContainer>

        <Content style={{ marginTop: "0" }}>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1} style={{ marginTop: "0" }}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Title>{card.title}</Title>
                <Description className="normalText">{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
export default ProfessionalHIW
