import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getCompanyLogo } from "../../../utility/helper";
import { ServicesContainer, ServicesH1 } from "./ServiceElements";
import "./styles.css";

const Slider = () => {
  const company = [
    {
      name: "Amazon",
      logo: "amazon.png",
    },
    {
      name: "Microsoft",
      logo: "microsoft.png",
    },
    {
      name: "Google",
      logo: "google.png",
    },
    {
      name: "Goldman Sachs",
      logo: "goldman_sachs.png",
    },
    {
      name: "IBM",
      logo: "ibm.png",
    },
    {
      name: "Flipkart",
      logo: "flipkart.png",
    },
    {
      name: "JPMorgan Chase & Co.",
      logo: "jpmorgan.jfif",
    },
    {
      name: "American Express",
      logo: "american_express.png",
    },
    // {
    //   name: "Meta",
    //   logo: "meta.png",
    // },
    {
      name: "Citi",
      logo: "citi.png",
    },
    {
      name: "Meesho",
      logo: "meesho.png",
    },
    {
      name: "Swiggy",
      logo: "swiggy.png",
    },
    {
      name: "Analog Devices",
      logo: "analog_devices.svg",
    },
    {
      name: "Standard Chartered",
      logo: "standard_chartered.png",
    },
    {
      name: "Dunzo",
      logo: "dunzo.png",
    },
    {
      name: "Fuzitsu",
      logo: "fuzitsu.png",
    },
    {
      name: "Publicis Sapient",
      logo: "publicis_sapient.png",
    },
    {
      name: "Udaan",
      logo: "udaan.png",
    },
    {
      name: "Wells Fargo",
      logo: "wells_fargo.png",
    },
    {
      name: "Fin Mechanics",
      logo: "fin_mechanics.png",
    },
    {
      name: "Credit Suisse",
      logo: "credit_suisse.png",
    },
    {
      name: "Deutsche Bank",
      logo: "deutsche_bank.jpg",
    },
    {
      name: "Auctus Advisors",
      logo: "auctus_advisors.jpg",
    },
    {
      name: "ZS",
      logo: "zs.png",
    },
    {
      name: "Boeing",
      logo: "boeing.png",
    },
    {
      name: "Myntra",
      logo: "myntra.png",
    },
    {
      name: "Siemens",
      logo: "siemens.png",
    },
    {
      name: "PayPal",
      logo: "paypal.jpg"
    },
    {
      name: "Ola",
      logo: "ola.png"
    },
    {
      name: "Oracle",
      logo: "oracle.png"
    },
    {
      name: "Deloitte",
      logo: "deloitte.png"
    },
    {
      name: "Cisco",
      logo: "cisco.png"
    },
    {
      name: "Walmart",
      logo: "walmart.png"
    },
    {
      name: "Uber",
      logo: "uber.jpg"
    },
    {
      name: "Nomura",
      logo: "nomura.png"
    },
    {
      name: "Barclays",
      logo: "barclays.png"
    },
    {
      name: "Mastercard",
      logo: "mastercard.png"
    },
    {
      name: "Sprinklr",
      logo: "sprinklr.png"
    },
    {
      name: "EY",
      logo: "ey.png"
    },
    {
      name: "Qualcomm",
      logo: "qualcomm.png"
    },
    {
      name: "HSBC",
      logo: "hsbc.png",
    },
    {
      name: "McKinsey & Company",
      logo: "mckinsey.png",
    },
    {
      name: "Facebook",
      logo: "facebook.png",
    },
    {
      name: "Morgan Stanley",
      logo: "morgan_stanley.png",
    },
    {
      name: "Boston Consulting Group (BCG)",
      logo: "bcg.png",
    },
    {
      name: "PwC",
      logo: "pwc.png",
    },
    {
      name: "Netflix",
      logo: "netflix.png",
    },
    {
      name: "Bain & Company",
      logo: "bain.png",
    },
    {
      name: "Directi",
      logo: "directi.png",
    },
    {
      name: "IQVIA",
      logo: "iqvia.png",
    },
    {
      name: "Kotak Mahindra Bank",
      logo: "kotak.png",
    },
    {
      name: "BNY Mellon",
      logo: "bny_mellon.png",
    },
    {
      name: "Hexagon Capability Center India Pvt. Ltd.",
      logo: "hexagon.png",
    },
  ];
  // console.log(company);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const company_1 = company.splice(0, company.length / 2);
  const company_2 = company.splice(0, company.length);
  console.log(company_1);
  console.log(company_2);

  return (
    <ServicesContainer
      style={{ height: "fit-content", backgroundColor: "transparent" }}
      id="partner"
    >
      <ServicesH1 className="page-heading-h1">Companies</ServicesH1>

      <hr />
      {/* <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        focusOnSelect={true}
        className="car2 "
        sliderClass="slid"
        autoPlaySpeed={2000}
        centerMode={false}
        keyBoardControl={true}
        customTransition="transform 2000ms linear"
        transitionDuration={200}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="item-class"
        renderDotsOutside={false}
      >
        {company_1.map((item) => {
          return (
            <img
              style={{ height: "100%" }}
              src={`/images/company_logo/${item.logo}`}
              className="sponcer_bigimg1"
              alt={item.name}
            />
          );
        })} */}
      {/* <img src="https://i.ibb.co/gtwSgFC/prs.png" className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://i.ibb.co/R7H0cJd/CSTEP-Logo-Color.png"  className="sponcer_bigimg1"alt="Parterns_img"/>
<img src="https://i.ibb.co/QMbrXjh/School-Of-Politics.png" className="sponcer_bigimg1"  alt="Parterns_img"/>
<img src="https://i.ibb.co/k1CNFr6/Bala-Janagraha.png"  className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://i.ibb.co/sgh2fFc/class-sathi.png" className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://startupxplore.com/uploads/ff8080816ca837ec016ca9da915c00dc-large.png"  className="sponcer_bigimg1"alt="Parterns_img"/>
<img src="https://i1.wp.com/iasdelhi.org/wp-content/uploads/2018/06/forumIAS_logo.png" className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://i.ibb.co/Ypx07jS/unnamed.jpg" alt="Parterns_img" className="sponcer_bigimg1"/>
<img src="https://i.ibb.co/vhgbx4t/Taghive.png" className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://i.ibb.co/F75RcvW/Jarvis-Logo-2-X2-ft.png" className="sponcer_bigimg1"  alt="Parterns_img"/>
<img src="https://i.ibb.co/FBRyDsG/moolya.png" className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://i.ibb.co/sWKHtXS/C3s-India-Default-Image.jpg" alt="Parterns_img" className="sponcer_bigimg1"/>
<img src="https://i.ibb.co/sQ6gqJf/wp-favicon3-1.png"  className="sponcer_bigimg1" alt="Parterns_img"/>

        */}
      {/* </Carousel>
      <Carousel
        responsive={responsive}
        // swipeable={true}
        draggable={true}
        showDots={false}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        focusOnSelect={true}
        className="car2 "
        sliderClass="slid"
        autoPlaySpeed={2000}
        centerMode={false}
        keyBoardControl={true}
        customTransition="transform 2000ms linear"
        transitionDuration={200}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="item-class"
        renderDotsOutside={false}
      >
        {company_2.map((item) => {
          return (
            //<img src={require('../../../images/company_logo/' + item.logo)} className="sponcer_bigimg1" alt={item.name}/>
            <img
              style={{ height: "100%" }}
              src={`/images/company_logo/${item.logo}`}
              className="sponcer_bigimg1 sponcer_bigimg2"
              alt={item.name}
            />
            //    )
          );
        })} */}
      {/* <img src="https://i.ibb.co/gtwSgFC/prs.png" className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://i.ibb.co/R7H0cJd/CSTEP-Logo-Color.png"  className="sponcer_bigimg1"alt="Parterns_img"/>
<img src="https://i.ibb.co/QMbrXjh/School-Of-Politics.png" className="sponcer_bigimg1"  alt="Parterns_img"/>
<img src="https://i.ibb.co/k1CNFr6/Bala-Janagraha.png"  className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://i.ibb.co/sgh2fFc/class-sathi.png" className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://startupxplore.com/uploads/ff8080816ca837ec016ca9da915c00dc-large.png"  className="sponcer_bigimg1"alt="Parterns_img"/>
<img src="https://i1.wp.com/iasdelhi.org/wp-content/uploads/2018/06/forumIAS_logo.png" className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://i.ibb.co/Ypx07jS/unnamed.jpg" alt="Parterns_img" className="sponcer_bigimg1"/>
<img src="https://i.ibb.co/vhgbx4t/Taghive.png" className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://i.ibb.co/F75RcvW/Jarvis-Logo-2-X2-ft.png" className="sponcer_bigimg1"  alt="Parterns_img"/>
<img src="https://i.ibb.co/FBRyDsG/moolya.png" className="sponcer_bigimg1" alt="Parterns_img"/>
<img src="https://i.ibb.co/sWKHtXS/C3s-India-Default-Image.jpg" alt="Parterns_img" className="sponcer_bigimg1"/>
<img src="https://i.ibb.co/sQ6gqJf/wp-favicon3-1.png"  className="sponcer_bigimg1" alt="Parterns_img"/>

        */}
      {/* </Carousel> */}
      <hr />

      <div
        style={{
          height: "150px",
          display: "flex",
          alignItems: "center",
          width: "clamp(100px,90%,1000px)",
          overflowX: "hidden",
          // whiteSpace: "nowrap",
          position: "relative",
          padding: "25px 0px",
          borderBottom: "1px solid #e0e0e0",
          borderTop: "2px solid #e0e0e0",
        }}
      >
        <div
          className="leftfade"
          style={{
            position: "absolute",
            height: "100%",
            left: "0px",
            zIndex: "2",
            width: "clamp(20px, 5%, 50px)",
            backgroundImage: "linear-gradient(to right, white , transparent)",
          }}
        ></div>
        <div
          className="rightfade"
          style={{
            position: "absolute",
            height: "100%",
            right: "0px",
            zIndex: "2",
            width: "clamp(20px, 5%, 50px)",
            backgroundImage: "linear-gradient(to right, transparent , white)",
          }}
        ></div>
        <div
          className="animate-companies-1"
          style={{ display: "flex", height: "100%", alignItems: "center" }}
        >
          {company_1.map((item) => {
            return (
              <img
                style={{
                  // maxHeight: "100%",
                  padding: `${
                    item.logo == "morgan_stanley.png" ||
                    item.logo == "bcg.png" ||
                    item.logo == "directi.png" ||
                    item.logo == "microsoft.png" ||
                    item.logo == "bny_mellon.png" ||
                    item.logo == "kotak.png" ||
                    item.logo == "hexagon.png"
                    ? "20px 10px"
                    : "10px"
                }`,
                  // maxWidth:"150px",
                  marginRight: "75px",
                  display: "inline",
                }}
                src={`/images/company_logo/${item.logo}`}
                className=""
                alt={item.name}
              />
            );
          })}
        </div>
      </div>
      <div
        style={{
          height: "150px",
          display: "flex",
          alignItems: "center",
          width: "clamp(100px,90%,1000px)",
          overflowX: "hidden",
          padding: "25px 0px",
          borderTop: "1px solid #e0e0e0",
          borderBottom: "2px solid #e0e0e0",

          // whiteSpace: "nowrap",
          position: "relative",
        }}
      >
        <div
          className="leftfade"
          style={{
            position: "absolute",
            height: "100%",
            left: "0px",
            zIndex: "2",
            width: "clamp(20px, 5%, 50px)",
            backgroundImage: "linear-gradient(to right, white , transparent)",
          }}
        ></div>
        <div
          className="rightfade"
          style={{
            position: "absolute",
            height: "100%",
            right: "0px",
            zIndex: "2",
            width: "clamp(20px, 5%, 50px)",
            backgroundImage: "linear-gradient(to right, transparent , white)",
          }}
        ></div>
        <div
          className="animate-companies-2"
          style={{ display: "flex", height: "100%", alignItems: "center" }}
        >
          {company_2.map((item, i) => {
            // item = company_2[company.length-i-1]
            // console.log("item",item,company.length,company.length,company.length-i-1)
            return (
              // <div className="image-box">
                <img
                  style={{
                    // maxHeight: "100%",
                    // height: `${
                    //   item.logo == "morgan_stanley.png" ||
                    //   item.logo == "bcg.png" ||
                    //   item.logo == "directi.png" ||
                    //   item.logo == "microsoft.png" ||
                    //   item.logo == "bny_mellon.png" ||
                    //   item.logo == "kotak.png" ||
                    //   item.logo == "hexagon.png"
                    //     ? "50px"
                    //     : "100%"
                    // }`,
                    padding: `${
                      item.logo == "morgan_stanley.png" ||
                      item.logo == "bcg.png" ||
                      item.logo == "directi.png" ||
                      item.logo == "microsoft.png" ||
                      item.logo == "bny_mellon.png" ||
                      item.logo == "kotak.png" ||
                      item.logo == "hexagon.png"
                        ? "20px 10px"
                        : "10px"
                    }`,
                    // maxWidth:"150px",
                    marginRight: "75px",
                    display: "inline",
                  }}
                  src={`/images/company_logo/${item.logo}`}
                  className=""
                  alt={item.name}
                />
              //  </div>
            );
          })}
        </div>
      </div>
    </ServicesContainer>
  );
};

export default Slider;
