import React, { useEffect } from "react";
import { Button, Typography, Box, Rating, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@mui/icons-material/Edit";
// import ReadMoreReact from "read-more-react";
import { Grid, Chip } from "@mui/material";
import {
  downloadPDF,
  programValues,
  t24HDecimalTo12HFormat,
  toastStyle,
  convertDateFormat,
  isIIT,
} from "../../../utility/helper";
import LZString from "lz-string";
import axios from "axios";
import { BASE, FEEDBACK } from "../../../constants/endpoints";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import StudCardDetails from "components/misc/StudCardDetails";

import "./CompletedInterview.css";
import { FaCopy } from "react-icons/fa";
import { PRICING_CHANGE_DATE } from "constants/variables";
import moment from "moment";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = React.useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};
const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const CompletedInterview = ({
  name,
  desg,
  slotStart,
  image,
  date,
  slotEnd,
  branch,
  college,
  program,
  year,
  id,
  profile,
  subprofile,
  status,
  zIndex,
  shift,
  remove,
  index,
  sIndex,
  counter,
  accepted,
  setAccepted,
  declined,
  setDeclined,
  cardId,
  resume,
  mockId,
  reqId,
  meetUrl,
  iId,
  isUnavailable,
  isDisable,
  isDelete,
  price,
  sessDuration,
  sessType
}) => {
  const [int, setint] = React.useState();
  const [value, setValue] = React.useState(3.5);
  const [hover, setHover] = React.useState(-1);
  const [feedback, setFeedback] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [comment, setComment] = React.useState("");

  const handleVisibility = () => {
    // console.log(document.visibilityState)
  }
  useEffect(() => {
    // console.log(document.visibilityState)
    document.addEventListener("visibilitychange", handleVisibility)
    if (!feedback && mockId) {
      axios.get(`${FEEDBACK}/${mockId}`)
        .then((res) => {
          if (res.data) setFeedback(true)
        })
        .catch((err) => {
          console.log(err)
          setFeedback(false)
        })
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisibility)
    }
  }, [document.visibilityState, mockId])


  useEffect(() => {
    if (mockId)
      axios
        .get(`${BASE}/send/getMockInterview/${mockId}`)
        .then((res) => {
          setint(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

    // axios
    //   .get(FEEDBACK + "/" + mockId)
    //   .then((res) => {
    //     console.log(res.data);
    //     setFeedback(true);
    //   })
    //   .catch((err) => {
    //     // console.log(err, "----a-sd--asd-----sad-as-das-d-----asd");
    //     setFeedback(false);
    //   });
  }, [mockId]);

  const handleSubmit = async (value, comment) => {
    console.log(value);
    console.log(comment);
    await axios
      .post(`${BASE}/send/addRating/${iId}`, {
        mockId: mockId,
        reqId: reqId,
        meetUrl: meetUrl,
        iRating: value,
        iComment: comment,
      })
      .then((res) => {
        console.log(res);
        handleClose();
        toast.success("Thankyou for the review.", {
          ...toastStyle.success,
          position: "top-center",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(mockId);
      toast.success(`Copied to clipboard`, {
        ...toastStyle.success,
      });
    } catch (error) {
      console.error('Failed to copy text:', error);
      toast.success(`Failed to copy text`, {
        ...toastStyle.loading,
      });
    }
  };
  return (
    <>
      <div
        className={
          "relative w-full overflow-hidden bg-white box-shadow-request-card grid grid-cols-4 rounded-lg my-4 p-2 s-desktop-card"
        }
        id={"stack-" + cardId}
      >
        <div className="border-r border-gray-400 my-auto col-span-1">
          <img
            src={image}
            className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
            alt="Profile"
          />
          <button
            onClick={() => {
              isUnavailable
                ? toast.loading(
                  `Sorry! the user has ${isDisable ? "disabled" : ""} ${isDelete ? "deleted" : ""
                  } this account`,
                  {
                    ...toastStyle.loading,
                    position: "top-center",
                    duration: 2000,
                  }
                )
                : window.open(
                  `${process.env.REACT_APP_FRONTEND_SERVER_URL}/i/student/${id}`,
                  "_blank"
                );
            }}
            className="view-profile-card-btn w-4/5 mt-2 mx-auto"
          >
            View Profile
          </button>
        </div>
        <div className="px-3 col-span-3">
          <div className="flex py-1 justify-between w-full">
            <div className="flex gap-x-2">
              <div className="w-12 h-12 drop-shadow-2 mx-1 rounded-lg">
                {isIIT(college) ? (
                  <img
                    src={require("../../Dashboard/Logos_IITs/" +
                      college.split(" ").join("_") +
                      "_Logo.png")}
                    // className="w-12 h-12 object-contain"
                    alt={college}
                  />
                ) : (
                  <img
                    src={require("../../Dashboard/Logos_IITs/interwiu_logo.png")}
                    // className="w-12 h-12 object-contain"
                    alt={college}
                  />
                )}
              </div>
              <div>
                <h4 className="text-2xl text-left font-medium leading-6">
                  {name}
                </h4>
                <p>
                  {programValues[program]}, {year}, {branch}
                </p>
                <p>{college}</p>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex justify-center h-10 text-blue-500 text-2xl">
                <Button variant="contained" onClick={() => downloadPDF(resume)}>
                  <i className="fa-solid fa-link mr-1"></i> Resume
                </Button>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div>
          </div>
          {/* <div
            className="flex justify-between text-center my-1 gap-x-1 mx-2"
            style={{ alignItems: "flex-start" }}
          >
            <div className="col-span-2">
              <p className="text-base text-gray-400">Profile</p>
              <Chip
                label={subprofile ? `${subprofile} (${profile})`: profile}
                color="info"
                sx={{ fontWeight: "medium",
                height: "auto",
                display: "block",
                maxWidth: "fit-content",
                px: 0.5,
                '& .MuiChip-label' :{
                  whiteSpace: "pre-wrap",
                  display: "flex",
                  flexWrap: "wrap",
                  px: 0.5,
                  fontSize: {
                    sm: "0.8rem"
                  }
                }
                }}
              />
            </div>
            <div className="col-span-2">
              <p className="text-base text-gray-400">Price</p>
              <p className="text-lg">INR process.env.REACT_APP_STD_45MIN</p>
            </div>
            <div className="col-span-2">
              <p className="text-base text-gray-400">Session Duration</p>
              <p className="text-lg">30 Min</p>
            </div>
            <div className="col-span-3" style={{minWidth: "177px"}}>
              <p className="text-base text-gray-400">Interview Slot</p>
              <span className="text-lg">
                {t24HDecimalTo12HFormat(slotStart)}
              </span>
              <span> &nbsp; - &nbsp;</span>
              <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
              <p className="text-lg">{convertDateFormat(date)}</p>
            </div>
          </div>
          <div className="relative flex justify-center">
            <div className="meeting-id">Mock Interview ID: {mockId}</div>
            {int?.iComment && (
              <>
                <br />
                <div className="ratedConfirm" style={{ maxWidth: "160px" }}>
                  Rated and Reviewed
                </div>
              </>
            )}
          </div> */}
          <StudCardDetails
            subprofile={subprofile}
            profile={profile}
            isRescheduled={"other"}
            reschedule={{
              status: "",
              details: null
            }}
            price={new Date(moment(date, "DD/MM/YYYY").format("MM/DD/YYYY")) > new Date('08/28/2023') ? { 45: 400, 30: 250 }[sessDuration] : 400}
            slotEnd={slotEnd}
            slotStart={slotStart}
            date={date}
            sessDuration={new Date(moment(date, "DD/MM/YYYY").format("MM/DD/YYYY")) > new Date('08/28/2023') ? sessDuration : 45}
            sessType={sessType}
          />
          <div className="flex justify-center">
            <div className="meeting-id">{{ interview: "Mock Interview ID", mentorship: "Mentorship Session ID", resume: "Resume Building Session ID" }[sessType]}: {int?.mockId}</div>
          </div>
          {!int?.iComment && (
            <div className="sent-item-btns flex justify-center">
              <div className="rate-btn flex">
                {/* <Button
                variant="contained"
                sx={{
                  backgroundColor: "red",
                }}
                onClick={handleOpen}
              >
                Rate and Review
              </Button> */}
                {(new Date(moment(date, "DD/MM/YYYY").format("MM/DD/YYYY")) <= new Date("08/28/2023") || sessDuration === 45) ? (feedback ? (
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{
                      marginLeft: "10px",
                      borderWidth: "2px",

                      pointerEvents: "none",
                      // backgroundColor:"gray",
                      // color: "white",
                      boxShadow: "none"
                    }}
                  >
                    Feedback Sent
                  </Button>
                ) : (
                  <Link
                    to={"/feedback/interviewer/vc/" + mockId + "/completed"}
                    target="_blank"
                    onClick={() => {
                      window.close();
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "red",
                        marginLeft: "10px",
                      }}
                      className="blink"
                    >
                      Send Feedback
                    </Button>
                  </Link>
                )) : null}

                {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  >
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ textAlign: "center" }}
                  >
                    Rate and Review
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Please Rate and Review your Interview Experience.
                  </Typography>
                  <Box
                    sx={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Rating
                      name="hover-feedback"
                      value={value}
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {value !== null && (
                      <Box sx={{ ml: 2 }}>
                        {labels[hover !== -1 ? hover : value]}
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      id="outlined-multiline-static"
                      label="Review"
                      multiline
                      rows={4}
                      sx={{
                        mb: 2,
                      }}
                      onChange={(event, newValue)=>{setComment(event.target.value)}}
                    />
                    <Button variant="outlined" onClick={()=>{handleSubmit(value,comment)}}>Submit</Button>
                  </Box>
                </Box>
              </Modal> */}
              </div>

            </div>
          )}
        </div>
      </div>

      {/* mobile card */}

      <div
        className="relative overflow-hidden s-card-main s-mobile-card s-mobile-card-shadow"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
        id={"stack-" + cardId}
      >
        <Button
          className="box-shadow-request-card s-mobile-resume-btn"
          variant="contained"
          onClick={() => downloadPDF(resume)}
        >
          <i className="fa-solid fa-link mr-1"></i> Resume
        </Button>
        {/* <div className="border-r border-gray-400 my-auto col-span-1">
          <img
            src={image}
            className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
            alt="Profile"
          />
          <button
            onClick={() => {
              isUnavailable
                ? toast.loading(
                    `Sorry! the user has ${isDisable ? "disabled" : ""} ${
                      isDelete ? "deleted" : ""
                    } this account`,
                    {
                      ...toastStyle.loading,
                      position: "top-center",
                      duration: 2000,
                    }
                  )
                : window.open(
                    `${process.env.REACT_APP_FRONTEND_SERVER_URL}/i/student/${id}`,
                    "_blank"
                  );
            }}
            className="view-profile-card-btn w-4/5 mt-2 mx-auto"
          >
            View Profile
          </button>
        </div> */}

        <div className="s-profile">
          <div
            className=" my-auto col-span-1 s-profile-p-p"

          >
            <img
              src={image}
              className="rounded-full border-2 border-black p-1 block mx-auto"
              alt="Profile"
              width={{ width: "100%" }}
            />
            <button
              style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_FRONTEND_SERVER_URL}/i/student/${id}`,
                  "_blank"
                );
              }}
              className="view-profile-card-btn  mt-2 mx-auto view-profile-card-btn-mobile"
            >
              View Profile
            </button>
          </div>
          <div
            className="flex py-1 justify-between w-full s-profile-n-d-c"
            style={{ alignItems: "start" }}
          >
            <h4 className="text-2xl font-medium">{name}</h4>
            <p>
              {programValues[program]}, {year}, {branch}
            </p>
            <p>{college}</p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginBottom: "8px" }}>
            <div className="s-duration-price" style={{ marginBottom: "10px" }}>
              <div className="">
                <span
                  className="text-base text-gray-400"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  Duration :{" "}
                </span>
                <span className="text-lg">{new Date(moment(date, "DD/MM/YYYY").format("MM/DD/YYYY")) > PRICING_CHANGE_DATE ? "45 Min" : "30 Min"}</span>
              </div>
              <div className="">
                <span
                  className="text-base text-gray-400"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  Price :{" "}
                </span>
                <span className="text-lg">{new Date(moment(date, "DD/MM/YYYY").format("MM/DD/YYYY")) > PRICING_CHANGE_DATE ? "INR 400" : "INR 250"}</span>
              </div>
            </div>

            <div style={{ justifyContent: 'flex-start' }} className="s-duration-price">
              <p
                className="text-base text-gray-400"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginRight: "5px",
                  transform: "translateY(2px)",
                }}
              >
                Interview Slot :{" "}
              </p>
              <div>
                <span className="text-lg">
                  {" "}
                  {t24HDecimalTo12HFormat(slotStart)}
                </span>
                <span> - </span>
                <span className="text-lg">
                  {t24HDecimalTo12HFormat(slotEnd)}
                </span>
                <p className="text-lg">{convertDateFormat(date)}</p>
              </div>
            </div>

            <div className="s-profile-profiles">
              <div className=" col-span-2">
                <div className="text-lg flex flex-wrap gap-1">
                  <p
                    className="text-base text-gray-400"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    Profile :{" "}
                  </p>
                  <Chip
                    label={subprofile ? `${subprofile} (${profile})` : profile}
                    color="info"
                    sx={{ fontWeight: "medium" }}
                    className="s-mobile-changing-chip-property"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="text-green-700 text-lg font-bold pt-1"
          style={{
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "1.4rem",
            textAlign: "center",
          }}
        >
          {{ interview: "Mock Interview ID", mentorship: "Mentorship Session ID", resume: "Resume Building Session ID" }[sessType]}: {mockId} <FaCopy style={{ display: 'inline', fontSize: '1rem', transform: 'translate(6px,-3px)' }} onClick={handleCopy} />
        </div>
        {!int?.iComment && (
          <div className="sent-item-btns flex justify-center w-full">
            <div className="rate-btn flex w-full">
              {feedback ? (
                <Button
                  variant="contained"
                  sx={{
                    borderColor: "gray",
                    color: "#15803d",
                    borderRadius: "0px 0px 10px 10px",
                  }}
                  className="s-mobile-dis-btn w-full"
                >
                  Feedback Sent
                </Button>
              ) : (
                <Link
                  to={"/feedback/interviewer/vc/" + mockId + "/completed"}
                  target="_blank"
                  className="w-full"
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "red",
                      borderRadius: "0px 0px 10px 10px",
                    }}
                    className="blink s-mobile-btn w-full"
                  >
                    Send Feedback
                  </Button>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CompletedInterview;