import React, { useContext } from "react";
import { useNavigate } from "react-router";
import "./NotAuthenticated.css";
import { Button } from "@mui/material";
import { ADMIN_PROFILE_FORM_ROUTE } from "../../constants/routes";
import UserContext from "../../contexts/UserContext";

const NotAuthenticated = ({ text }) => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  return (
    <div className="notAuthenticated">
      <div>
      <p>{text}</p>
      <Button onClick={() => navigate(ADMIN_PROFILE_FORM_ROUTE)} variant="contained">{user?.isAdmin ? "Create your Account" : "Verify your account"}</Button>
      </div>
    </div>
  );
};

export default NotAuthenticated;
