import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import "./Welcome.css";

const Welcome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  setTimeout(() => {
    navigate("/s/find-an-interviewer");
  }, 5000);

  return (
    <div className="welcome">
      <div className="welcome__box">
        <h1>
          <span>Congratulations!</span>
          <br />
          You have successfully created an account as a Student (i.e., an
          Interviewee)!
        </h1>
        <p>
          You can start sending the Mock Interview Requests now. Find the most
          relevant interviewers suiting your requirements{" "}
          <a href="https://interwiu.com/s/find-an-interviewer">
            {" "}
            <span className="text-blue-700 underline"> here </span>{" "}
          </a>
        </p>
        <p>Wish you a Great Experience at interwiu.com!</p>
        <p>For more details, please check your registered Gmail.</p>
      </div>
    </div>
  );
};

export default Welcome;
