import React, { useContext } from "react";
import axios from "axios"
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from 'react-google-login';
import { auth, googleProvider } from "../../firebase";
import { BASE, AUTH_INT_GOOGLE_ENDPOINT } from "../../constants/endpoints";
import { INTERVIEWER_RECIEVED_REQUESTS_ROUTE, INTERVIEWER_PROFILE_FORM_ROUTE } from "../../constants/routes";
import "./Signupprofessional.css";
import UserContext from "../../contexts/UserContext";

const remove = () => {
  const loginFunc = document.querySelector(".signup-professional");
  loginFunc.classList.add("hide");
};

function childClick(event) {
  // console.log("Child clicked");
  event.stopPropagation(); // stop propagation to parent
}


const Signupprofessional = () => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleIntGoogle = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        const email_verified = res.user.emailVerified;
        const email = res.user.email;
        const name = res.user.displayName;
        const sub = res.user.uid;
        const picture = res.user.photoURL;
        axios({
          method: "POST",
          url: AUTH_INT_GOOGLE_ENDPOINT,
          data: { email_verified, email, name, sub, picture },
        }).then((response) => {
          console.log("Google Login Success", response);
          setUser(response.data.user);
          localStorage.setItem(
            "isIAuthenticated",
            JSON.stringify({ token: response.data.token })
          );
          remove();
          if (response.data.user.designation)
            navigate(INTERVIEWER_RECIEVED_REQUESTS_ROUTE);
          else navigate(INTERVIEWER_PROFILE_FORM_ROUTE);
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // const responseSuccessGoogleInt=(response)=>{
  //   console.log(response)
  //   axios({
  //     method:"POST",
  //     url:`${BASE}/auth/int/googlelogin`,
  //     data: {tokenId: response.tokenId}
  //   }).then(response=>{
  //     console.log("Google Login Success",response)
  //     localStorage.setItem('isIAuthenticated',JSON.stringify(response.data.user))
  //     setTimeout(() => {window.location.href = INTERVIEWER_RECIEVED_REQUESTS_ROUTE}, 100);
  //   })
  // }
  
  // const responseErrorGoogleInt=(response)=>{
  //   console.log("Something gone wrong")
  // }

  // const signup = () => {
  //   navigate("/interviewer-profile-form");
  //   const loginFunc = document.querySelector(".signup-professional");
  //   loginFunc.classList.add("hide");
  // };
  
  return (
    <div className="signup-professional hide" style={{ backdropFilter: "blur(10px)", paddingTop:"70px"}} onClick={() => {
      remove();
    }}>
      <div className="login" style={{margin: "10px", minHeight: "50vh"}} onClick={(e)=>childClick(e)}>
        <div
          className="cross"
          onClick={() => {
            remove();
          }}
        >
          <i className="fa-solid fa-circle-xmark"></i>
        </div>
        <div className="login-head  login-head-2">
          <p
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              lineHeight: "1.2",
              backgroundColor: "#E4EDF3",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <span>
              Let’s take Mock Interviews of Students and provide Feedback on
              their performance in realtime!
            </span>
            <span style={{ fontWeight: "bold", marginTop: "10px" }}>
              Create an Account now!
            </span>
          </p>
        </div>
        <div className="login-system">
          <div className="col">
            <h1>Create an Account as a Professional</h1>
            {/* <GoogleLogin
              clientId="752367686296-0scm5me6nrjuc64f9dc36i88c7mstjrt.apps.googleusercontent.com"
              buttonText="Sign in with Google"
              onSuccess={responseSuccessGoogleStd}
              onFailure={responseErrorGoogleStd}
              cookiePolicy={'single_host_origin'}
            /> */}
            <form onSubmit={(e) => handleIntGoogle(e)}>
              <button className="signin-btn login" type="submit">
                <img src={require("../../images/google.png")} alt="Google" />
                Create Account with Google
              </button>
            </form>
          </div>
          <div className="login-image col">
            <img src={require("../../images/group3.png")} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signupprofessional;
