import React from 'react';
import './Responsibilities.css'; // Import the CSS file
import image from "../../../images/ca/ambassador-resp.avif"; // Adjust the path as necessary

const Responsibilities = () => {
    return (
        <div className="responsibilities-container">
            <img src={image} alt="Campus Ambassador" className="responsibilities-image" />
            <div className="responsibilities-content">
                <h2 className="responsibilities-title">Responsibilities of a Campus Ambassador</h2>
                <p className="responsibilities-intro">A campus ambassador (CA) of interwiu.com will:</p>
                <ol className="responsibilities-list">
                    <li>Digitally promote interwiu.com within the institute through groups and chats.</li>
                    <li>Display posters in strategic locations.</li>
                    <li>Discuss interwiu.com benefits with peers frequently.</li>
                    <li>Encourage student registrations.</li>
                    <li>Engage faculty to recommend interwiu.com.</li>
                    <li>Promote session bookings (interviews, resume, mentorship).</li>
                    <li>Interact with interwiu.com social media content.</li>
                    <li>Share social media posts widely.</li>
                    <li>Update WhatsApp status and stories with key info.</li>
                    <li>Expand interwiu.com's reach throughout the institute.</li>
                </ol>
                <p className="responsibilities-note">
                    The interwiu.com Team provides most materials, but CAs can create approved content for rewards.
                </p>
            </div>
        </div>
    );
};

export default Responsibilities;
