import React from "react";
import { useState, useEffect  } from "react";
import { Routes, Route } from "react-router-dom";
import AdminProfileForm from "./adminProfileForm";
import AddStudents from "./addStudents";
import CurrentStudents from "./CurrentStudents";
import MockInterviews from "./MockInterviews";
import FeedBack from "./FeedBack";
import Interviewers from "./Interviewers";
import InterviewProfile from "../Interviewer/BriefInterviewerProfile";
import StudentDashboard from "../Dashboard/BriefStudentsProfile";
import Dashboard from "./Home/Dashboard";
import Cancelled from "./Home/Cancelled";
import Declined from "./Home/Declined";
import AutoRejected from "./Home/AutoRejected";
import Report from "./Home/Report";
import Success from "./Home/Success";
import Welcome from "./Home/Welcome";
import AllStories from "./Home/AllStories";
import UserContext from "../../contexts/UserContext";
import { useContext } from "react";
import NotAuthenticated from "./NotAuthenticated";
import { Oval } from "react-loader-spinner";

const AdminRoutes = () => {
  const {user} = useContext(UserContext);
  const notAuthenticatedText = user?.isAdmin ? "Please create your account first" : "Please verify your account first"
  const [isloaded, setIsloaded] = useState(false)

  const ovalLoader = (
    <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
      <Oval
        height={120}
        width={120}
        color="#306bc9"
        wrapperStyle={{ padding: "50%, 50%" }}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#90caf9"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  useEffect(() => {
    setTimeout(() => {
      setIsloaded(true)
    }, 1000);
  }, [user])
  
  return (
    <div>
      <Routes>
        {/* <Route path="dashboard" element={<Dashboard />} />
        <Route path="find-an-interviewer" element={<SearchInterviews />} />
        <Route path="sent-requests" element={<SentRequests />} />
        <Route path="accepted-requests" element={<AcceptedRequests />} />
        <Route path="upcoming-interviews" element={<UpcomingInterviews />} />
        <Route path="completed-interviews" element={<CompletedInterviews />} />
        <Route path="declined-requests" element={<DeclinedRequests />} />
        <Route path="canceled-interview" element={<CanceledInterviews />} />
        <Route path="add/success-story" element={<StoryForm />} />
        <Route path="account" element={<AccountSettings />} />
        <Route path="deactivated" element={<DeactivatedWelcome />} />
        
        <Route
          exact
          path="interviewer/:id"
          element={
              <InterviewProfile />
          }
        />
        <Route
          path="precancelled-requests"
          element={<AutoRejectedRequest />}
        />
        <Route path="report" element={<ReportIssue />} />
        <Route path="reschedule" element={<Reschedule />} />
        <Route path="profile/:id" element={<Int2Std />} />
        <Route path="profile-form" element={<ProfileForm />} />
        <Route path="cancel" element={<SCancel />} />
        <Route path="welcome" element={<Welcome />} /> */}
        <Route path="profile-form" element={<AdminProfileForm />} />
        <Route path="add-students" element={isloaded ? user?.aVerified ? <AddStudents /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route path="current-students" element={isloaded ? user?.aVerified ? <CurrentStudents /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route path="mock-interviews" element={isloaded ? user?.aVerified ? <MockInterviews /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route path="feedback-comments" element={isloaded ? user?.aVerified ? <FeedBack /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route path="interviewers" element={isloaded ? user?.aVerified ? <Interviewers /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route exact path="interviewer/:id" element={isloaded ? user?.aVerified ? <InterviewProfile /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route exact path="student/:id" element={isloaded ? user?.aVerified ? <StudentDashboard /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route exact path="dashboard" element={isloaded ? user?.aVerified ? <Dashboard /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route path="cancelled-interviews" element={isloaded ? user?.aVerified ? <Cancelled /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route path="declined-interviews" element={isloaded ? user?.aVerified ? <Declined /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route path="all-stories" element={isloaded ? user?.aVerified ? <AllStories /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route
          exact
          path="autorejected-interviews"
          element={isloaded ? user?.aVerified ? <AutoRejected /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader}
        />
        <Route exact path="report-issue" element={isloaded ? user?.aVerified ? <Report /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route exact path="success-stories" element={isloaded ? user?.aVerified ? <Success /> : <NotAuthenticated text={notAuthenticatedText} /> : ovalLoader} />
        <Route exact path="welcome" element={<Welcome />} />
      </Routes>
    </div>
  );
};

export default AdminRoutes;