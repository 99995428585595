import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { BASE } from "../../../constants/endpoints";
import {
  t24HDecimalTo12HFormat,
  convertDateFormat,
  toastStyle,
} from "../../../utility/helper";
import { toast } from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StudCardDetails from "components/misc/StudCardDetails";
// import UserContext from "~/contexts/UserContext";

const IS_TEST_MODE = process.env.REACT_APP_RAZORPAY_TEST_MODE;
const TEST_KEY_ID = process.env.REACT_APP_RAZORPAY_TEST_KEY_ID;
const LIVE_KEY_ID = process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
};
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const AcceptedRequest = ({
  user,
  name,
  id,
  desg,
  subprofile,
  price,
  logo,
  profile,
  date,
  email,
  slotStart,
  image,
  slotEnd,
  status,
  payment,
  req_id,
  isUnavailable,
  isDisable,
  isDelete,
  company,
  sessDuration,
  sessType,
}) => {
  // console.log(isUnavailable);
  // const { user, setUser } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [valid, setValid] = useState(null);
  // const [amount, setAmount] = useState(`${process.env.REACT_APP_STD_45MIN}` * 100);
  // const [amount, setAmount] = useState(`${process.env.REACT_APP_SHORT_30MIN}` * 100);
  const [amount, setAmount] = useState(
    {
      30: `${process.env.REACT_APP_SHORT_30MIN}`,
      45: `${process.env.REACT_APP_STD_45MIN}`,
    }[sessDuration]
  ); // testing purpose switched to always payed
  const [couponApplied, setCouponApplied] = useState(false);

  const [couponError, setCouponError] = useState("");

  const [load, isLoad] = useState(false);
  // const [amountDisplay, setAmountDisplay] = useState({`${process.env.REACT_APP_STD_45MIN}`});
  // const [friendNameCRF, setfriendNameCRF] = useState("");

  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    console.log(e.target.value, "closed modal");
    setOpen(false);
  };

  const handleCouponChange = (e) => {
    if (e.target.value === "") setCouponError("");
    setCoupon(e.target.value);
  };

  const handleApplyCoupon = () => {
    if (user.caId && user.caId == coupon) {
      setCouponError("Please note: As a Campus Ambassador, you cannot use your own coupon.");
    } else {
      axios
        .post(`${BASE}/coupon/apply-coupon`, {
          coupon: coupon,
          amount: {
            30: `${process.env.REACT_APP_SHORT_30MIN}`,
            45: `${process.env.REACT_APP_STD_45MIN}`,
          }[sessDuration],
          userEmail: user.email,
        })
        .then((res) => {
          setCouponApplied(true);
          setOpen(false);
          console.log(res.data);
          setAmount(res.data.amount);
        })
        .catch((err) => {
          console.log(err);
          setCouponError(err.response.data.text);
        });
    }

  };

  const confirm = async () => {
    isLoad(true);
    console.log("line no 346:inside confirm");
    console.log("coupon-s", couponApplied);
    if (couponApplied) {
      console.log("coupon-t", couponApplied);
      try {
        const couponcheck = await axios.post(`${BASE}/coupon/apply-coupon`, {
          coupon: coupon,
          amount: {
            30: `${process.env.REACT_APP_SHORT_30MIN}`,
            45: `${process.env.REACT_APP_STD_45MIN}`,
          }[sessDuration],
          userEmail: user.email,
        });
        setCouponApplied(true);
        setOpen(false);
        // console.log(res.data);
        // setAmount(res.data.amount);
      } catch (err) {
        console.log(err);
        toast.error(`${err.response.data.text}`, {
          ...toastStyle.error,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        // setCouponError(err.response.data.text);
        return;
      }
    }

    const { data } = await axios.patch(
      `${BASE}/send/updateInterviewReq/${req_id}`,
      {
        status: "ReqPaid",
        price: amount,
      }
    );
    if (data.success == false) {
      toast.error(`${data.msg}`, {
        ...toastStyle.error,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return;
    }
    console.log("line no 354:before");

    if (couponApplied && Boolean(coupon)) {
      try {
        let res = await axios.post(`${BASE}/coupon/use-coupon-code`, {
          userName: user.fname,
          coupon: coupon,
          mockId: data.mockId,
          usedTime: new Date(),
        });
        console.log(res, "coupon use response");
      } catch (err) {
        console.log(err, "Use Coupon Error");
        return;
      }
    }
    try {
      const data2 = await axios.patch(
        `${BASE}/send/autorejectrequests/${req_id}`
      );
      isLoad(true);
      console.log("🎆auto reject data", data2.data);
      setTimeout(() => {
        navigate("/s/upcoming-interviews");
      }, 500);
    } catch (err) {
      console.log(err);
      await axios.patch(`${BASE}/send/appendBookedSlot/${req_id}`);
      toast.success(`Mock Interview Confirmed`, {
        ...toastStyle.success,
      });
      setTimeout(() => {
        navigate("/s/upcoming-interviews");
      }, 500);
    }
  };
  const savePaymentDetails = async (req, req_id, coupon) => {
    await confirm();
    console.log("Inside savePaymentDetails Function :");
    axios
      .post(`${BASE}/payment`, { req, req_id })
      .then((res) => {
        console.log("Inside savePaymentDetails req:-", req);
        console.log("Inside savePaymentDetails res:-", res);
        axios.patch(`${BASE}/send/updateInterviewReq/${req_id}`, {
          status: "ReqPaid",
          coupon,
        });
        console.log("This is the coupon ===>", coupon);
        try {
          if (user.referredBy && coupon == user.referredBy) {
            let body = {
              caId: coupon,
              reqId: req_id
            }

            const response = axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/programs/ca/add-session`, body)
          }
        } catch (error) {
          console.log("Error in saving requestId", error);
        }
        axios.patch(`${BASE}/send/autorejectrequests/${req_id}`);
        // axios.patch(`${BASE}/send/appendBookedSlot/${req_id}`);
        // updateCoupon(coupon,couponMyList.indexOf(coupon))

        setTimeout(() => {
          //
          // window.location.href = `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/upcoming-interviews`;
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  async function displayRazorpay() {
    console.log(
      " TEST_KEY_ID :- ",
      TEST_KEY_ID,
      " process.env.RAZORPAY_TEST_KEY_ID :- ",
      process.env.REACT_APP_RAZORPAY_TEST_KEY_ID
    );
    if (process.env.REACT_APP_RAZORPAY_TEST_MODE === "ON")
      console.log("Yes this is test mode.");
    console.log("We are inside display razorpay 1");

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    console.log(res, "203");
    console.log(amount);
    const data = await fetch(
      `${BASE}/payment-gateway/razorpay-individual-coupon-initiation`,
      {
        method: "POST",
        body: JSON.stringify({
          buyerStudId: req_id,
          // amount:amountDisplay
          amount: amount, // 1
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    ).then((t) => t.json());

    console.log("razorpay/ API resp data:-", data);
    const { id } = data;
    const options = {
      key: process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID,
      // process.env.REACT_APP_RAZORPAY_TEST_MODE === "ON"
      //   ? process.env.REACT_APP_RAZORPAY_TEST_KEY_ID
      //   : process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID,
      //key: 'rzp_live_1OOIUAYNKPkGOU',
      //key: 'rzp_test_aij45tszA50GE0',
      //key: IS_TEST_MODE === "0" ? TEST_KEY_ID : LIVE_KEY_ID,
      currency: data.currency,
      //amount: (process.env.REACT_APP_RAZORPAY_1RUPEE_LIVE_MODE === "ON") ? 100 : data.amount.toString(),
      amount: amount * 100,
      // process.env.REACT_APP_RAZORPAY_1RUPEE_LIVE_MODE === "ON" ? 100 : amount*100,
      order_id: id,
      name: `Fyzen Career Solutions Pvt Ltd`,
      description: "Thank you for the confirmation!",
      image:
        "https://media-exp1.licdn.com/dms/image/C560BAQExXV7Oc9sZMA/company-logo_200_200/0/1629144901212?e=2147483647&v=beta&t=x7BZQmslIuY0OgabE7lpKn45A4m53N2jezdjXc6nT_Q",
      handler: function (response) {
        console.log(
          "Inside handler function. Output from Razorpay. response= :-",
          response
        );
        console.log(amount);
        savePaymentDetails(response, req_id, coupon);
      },
      prefill: {
        name: `${user.fname} ${user.lname}`,
        email: `${user.email}`,
        contact: `${user.mobNumber}`,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    console.log("We are inside display razorpay 2");
    console.log("Razorpay Options :- ", options);
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function (response) {
      console.log("Before adding entry :", req_id, id);
      axios.post(`${BASE}/failedPayments`, { req_id, id, response });
      alert(response.error.description);
      console.log("Payment failed :", response);
    });
  }

  function ImageExist(url) {
    if (url?.includes(".svg")) {
      // console.log("svg", url);
      var img = new Image();
      img.src = url;
      // console.log(img.height);
      return img?.getBBox?.height !== 0;
    } else {
      // console.log(".png", url);
      var img2 = new Image();
      img2.src = url;
      // console.log(img2?.getBBox?.height);
      return img2?.height !== 0;
    }
  }
  let imgExist = false;
  return (
    <>
      {load ? (
        <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <div className="relative w-full overflow-hidden bg-white box-shadow-request-card grid grid-cols-4 rounded-lg my-4 p-2 s-desktop-card">
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              style={{ backdropFilter: "blur(10px)" }}
            >
              <Box style={{ width: "95%", maxWidth: "500px" }} sx={style}>
                <div className="p-2 bg-blue-500 flex justify-between cursor-move">
                  <Typography
                    id="modal-modal-description"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    Enter Coupon Code
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </div>
                <div
                  style={{
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    paddingTop: "20px",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                >
                  {amount === 0 && (
                    <p className="pb-2 pt-1" style={{ color: "green" }}></p>
                  )}
                  {amount ===
                    Math.ceil(`${process.env.REACT_APP_STD_45MIN}` / 2) &&
                    valid && (
                      <p className="pb-2 pt-1" style={{ color: "green" }}>
                        Congratulations!&nbsp;
                        {user.institute} has already paid 50% of the complete
                        amount for you! Please pay only the balance amount (INR{" "}
                        {amount}) to confirm this mock interview!
                      </p>
                    )}

                  <TextField
                    onChange={handleCouponChange}
                    fullWidth
                    value={coupon}
                    id="outlined-basic"
                    label="Enter Coupon Code"
                    error={coupon.length !== 0 && couponError?.length !== 0}
                    helperText={coupon.length !== 0 && couponError}
                    variant="outlined"
                  />

                  <br />
                  {amount === `${process.env.REACT_APP_STD_45MIN}` ? (
                    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                      <Button
                        onClick={handleApplyCoupon}
                        variant="contained"
                        color="success"
                      >
                        Apply
                      </Button>
                    </div>
                  ) : (
                    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                      <Button
                        onClick={handleApplyCoupon}
                        variant="contained"
                        color="success"
                      >
                        Apply
                      </Button>
                    </div>
                  )}
                </div>
              </Box>
            </Modal>
            <div className="border-r border-gray-400 my-auto col-span-1">
              <img
                src={image}
                className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
                alt="Profile"
              />
              <button
                onClick={() => {
                  isUnavailable
                    ? toast.loading(
                      `Sorry! the user has ${isDisable ? "disabled" : ""} ${isDelete ? "deleted" : ""
                      } this account`,
                      {
                        ...toastStyle.loading,
                        position: "top-center",
                        duration: 2000,
                      }
                    )
                    : window.open(
                      `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                      "_blank"
                    );
                }}
                className="view-profile-card-btn w-4/5 mt-2 mx-auto"
              >
                View Profile
              </button>
            </div>
            <div className="px-3 col-span-3">
              <div className="flex py-1 justify-between w-full">
                <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
                  {ImageExist(logo[0]) &&
                    imgExist === false &&
                    (imgExist = true) && (
                      <img src={logo[0]} alt="logo" className="h-full w-full" />
                    )}
                  {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                    <img src={logo[1]} alt="logo" className="h-full w-full" />
                  )}
                  {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                    <img src={logo[2]} alt="logo" className="h-full w-full" />
                  )}
                  {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                    <img src={logo[3]} alt="logo" className="h-full w-full" />
                  )}
                  {!imgExist &&
                    !ImageExist(logo[0]) &&
                    !ImageExist(logo[1]) &&
                    !ImageExist(logo[2]) &&
                    !ImageExist(logo[3]) &&
                    (imgExist = true) && (
                      <img src={logo[4]} alt="logo" className="h-full w-full" />
                    )}
                  {/* <img src={logo} alt="logo" className="h-full w-full" /> */}
                </div>
                <div className="text-center">
                  <h4 className="text-2xl font-medium leading-4">{name} </h4>
                  <p>
                    {desg}, {company}
                  </p>
                </div>
                <div className="flex gap-2">
                  <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                    <i className="fa-solid fa-bookmark"></i>
                  </div>
                  <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                    <i className="fa-solid fa-share"></i>
                  </div>
                </div>
              </div>
              {/* <div
                className="flex justify-between text-center my-1 gap-x-1 mx-2"
                style={{ alignItems: "flex-start" }}
              >
                <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "45%"
              
            }}>
                  <p className="text-base text-gray-400">Profile</p>
                   <Chip
                label={subprofile ? `${subprofile} (${profile})`: profile}
                color="info"
                sx={{ fontWeight: "medium",
                height: "auto",
                display: "block",
                maxWidth: "fit-content",
                px: 0.5,
                '& .MuiChip-label' :{
                  whiteSpace: "pre-wrap",
                  display: "flex",
                  flexWrap: "wrap",
                  px: 0.5,
                  fontSize: {
                    sm: "0.8rem"
                  }
                }
                }}
              />
                </div>
                <div style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridGap: "20px",
              width: "50%"
            }}>
                <div>
                  <p className="text-base text-gray-400">Price</p>
                  <p className="text-lg">INR {amount}</p>
                </div>
                <div>
                  <p className="text-base text-gray-400">Session Duration</p>
                  <p className="text-lg">30 Min</p>
                </div>
                <div style={{minWidth: "177px"}}>
                  <p className="text-base text-gray-400">Interview Slot</p>
                  <span className="text-lg">
                    {t24HDecimalTo12HFormat(slotStart)}
                  </span>
                  <span> &nbsp; - &nbsp;</span>
                  <span className="text-lg">
                    {t24HDecimalTo12HFormat(slotEnd)}
                  </span>
                  <p className="text-lg">{convertDateFormat(date)}</p>
                </div>
                </div>
              </div> */}
              <StudCardDetails
                subprofile={subprofile}
                profile={profile}
                isRescheduled={"other"}
                reschedule={{
                  status: "",
                  details: null,
                }}
                price={amount}
                slotEnd={slotEnd}
                slotStart={slotStart}
                date={date}
                sessDuration={sessDuration}
                sessType={sessType}
              />
              <div className="relative flex justify-around">
                <div className="flex justify-around">
                  {amount === 0 && (
                    <div style={{ textAlign: "center", alignItems: "center" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={confirm}
                        style={{
                          fontSize: "15px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        Confirm Interview
                      </Button>
                    </div>
                  )}
                  {amount !== 0 && (
                    <Button
                      variant="contained"
                      onClick={displayRazorpay}
                      style={{
                        fontSize: "15px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      Pay & Confirm
                    </Button>
                  )}
                  {valid !== true && (
                    <Button
                      variant="contained"
                      onClick={handleOpen}
                      color="success"
                      style={{
                        fontSize: "15px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      {couponApplied ? "Change Coupon Code" : "Apply Coupon"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* mobile card */}
          <div
            className="relative overflow-hidden s-card-main s-mobile-card s-mobile-card-shadow"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div className="absolute bottom-1 right-2 text-right">
              <p
                className="text-xs text-gray-400"
                style={{ fontSize: "0.65rem" }}
              >
                Sent 15 Min ago
              </p>
            </div>
            <div className="s-profile">
              <div className=" my-auto col-span-1 s-profile-p-p">
                <img
                  src={image}
                  className="rounded-full border-2 border-black p-1 block mx-auto"
                  alt="Profile"
                  width={{ width: "100%" }}
                />
                <button
                  style={{ whiteSpace: "nowrap" }}
                  onClick={() => {
                    isUnavailable
                      ? toast.loading(
                        `Sorry! the user has ${isDisable ? "disabled" : ""} ${isDelete ? "deleted" : ""
                        } this account`,
                        {
                          ...toastStyle.loading,
                          position: "top-center",
                          duration: 2000,
                        }
                      )
                      : window.open(
                        `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                        "_blank"
                      );
                  }}
                  className="view-profile-card-btn mt-2 mx-auto view-profile-card-btn-mobile"
                >
                  View Profile
                </button>
              </div>
              <div
                className="flex py-1 justify-between w-full s-profile-n-d-c"
                style={{ alignItems: "start" }}
              >
                {/* <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
      {ImageExist(logo[0]) &&
        imgExist === false &&
        (imgExist = true) && (
          <img src={logo[0]} alt="logo" className="h-full w-full" />
        )}
      {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
        <img src={logo[1]} alt="logo" className="h-full w-full" />
      )}
      {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
        <img src={logo[2]} alt="logo" className="h-full w-full" />
      )}
      {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
        <img src={logo[3]} alt="logo" className="h-full w-full" />
      )}
      {!imgExist &&
        !ImageExist(logo[0]) &&
        !ImageExist(logo[1]) &&
        !ImageExist(logo[2]) &&
        !ImageExist(logo[3]) &&
        (imgExist = true) && (
          <img src={logo[4]} alt="logo" className="h-full w-full" />
        )}
    </div> */}
                {/* <div className="text-center "> */}
                <h4 className="text-2xl font-medium">{name}</h4>
                <p>{desg}</p>
                <p>{company}</p>
                {/* </div> */}
                {/* <div className="flex gap-2">
      <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
        <i className="fa-solid fa-bookmark"></i>
      </div>
      <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
        <i className="fa-solid fa-share"></i>
      </div>
    </div> */}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginBottom: "8px" }}>
                <div
                  className="s-duration-price"
                  style={{ marginBottom: "10px" }}
                >
                  <div className="">
                    <span
                      className="text-base text-gray-400"
                      style={{ fontWeight: "bold", color: "black" }}
                    >
                      Duration :{" "}
                    </span>
                    <span className="text-lg">{sessDuration} Min</span>
                  </div>
                  <div className="">
                    <span
                      className="text-base text-gray-400"
                      style={{ fontWeight: "bold", color: "black" }}
                    >
                      Price :{" "}
                    </span>
                    <span className="text-lg">{amount}</span>
                  </div>
                </div>

                <div
                  style={{ justifyContent: "flex-start" }}
                  className="s-duration-price"
                >
                  <p
                    className="text-base text-gray-400 "
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      marginRight: "5px",
                      transform: "translateY(2px)",
                    }}
                  >
                    Interview Slot :{" "}
                  </p>
                  <div style={{ transform: "translateY('-3px')" }}>
                    <span className="text-lg">
                      {" "}
                      {t24HDecimalTo12HFormat(slotStart)}
                    </span>
                    <span> - </span>
                    <span className="text-lg">
                      {t24HDecimalTo12HFormat(slotEnd)}
                    </span>
                    <p className="text-lg">{convertDateFormat(date)}</p>
                  </div>
                </div>

                <div className="s-profile-profiles">
                  <div className=" col-span-2">
                    <div className="text-lg flex flex-wrap gap-1">
                      <p
                        className="text-base text-gray-400"
                        style={{ color: "black", fontWeight: "bold" }}
                      >
                        Profile :{" "}
                      </p>
                      <Chip
                        label={
                          subprofile ? `${subprofile} (${profile})` : profile
                        }
                        color="info"
                        sx={{
                          fontWeight: "medium",
                          maxWidth: 300,
                          display: "flex",
                        }}
                        className="s-mobile-changing-chip-property"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative flex justify-center">
              <div
                style={{
                  display: "flex",
                  gap: "0.4rem",
                  textAlign: "center",
                  alignItems: "stretch",
                  width: "100%",
                }}
              >
                {amount === 0 && (
                  <Button
                    color="success"
                    variant="contained"
                    onClick={confirm}
                    style={{
                      fontSize: "15px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "0px",
                      flex: 1,
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset, 0px 0px 6px grey",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    Confirm Interview
                  </Button>
                )}
                {amount !== 0 && (
                  <Button
                    variant="contained"
                    onClick={displayRazorpay}
                    style={{
                      fontSize: "15px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      alignItems: "center",
                      textAlign: "center",
                      borderRadius: "0px",
                      flex: 1,
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset, 0px 0px 6px grey",
                    }}
                  >
                    Pay & Confirm
                  </Button>
                )}
                {valid !== true && (
                  <Button
                    variant="contained"
                    onClick={handleOpen}
                    color="success"
                    style={{
                      fontSize: "15px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      flex: 1,
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset, 0px 0px 6px grey",
                      borderRadius: "0px",
                    }}
                  >
                    {couponApplied ? "Change Coupon Code" : "Apply Coupon"}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AcceptedRequest;
