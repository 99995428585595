import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl as ContentBase,
} from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "../images/checkbox-circle.svg";
import UserContext from "../contexts/UserContext";
import { BASE } from "../constants/endpoints";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import "./Pricing.css";
// import {
//   compareDate,
//   compareSlots,
//   getCompanyLogo,
//   t12HTo24HDecimalFormat,
//   t24HDecimalTo12HFormat,
//   toastStyle,
// } from "../../../utility/helper.js";
import { toastStyle } from "../utility/helper.js";
import toast from "react-hot-toast";

const EarlyBirdPrice = styled.div`
  ${tw`text-2xl font-bold`}
`;

const PerceivedPrice = styled.div`
  ${tw`text-sm text-gray-500 line-through`}
`;

const Container = tw(ContainerBase)`bg-changed-100 text-gray-100 -mx-8 px-8`;
const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 mx-auto px-0 py-10 sm:px-6 md:px-8 lg:px-12 xl:px-24 sm:py-16 flex flex-col max-w-screen-xl`;
const HeaderContainer = tw.div`mt-10 mb-2 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-gray-300 text-center`;

const PlansContainer = tw.div`mt-12 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium`;
const Plan = styled.div`
  ${tw`w-full max-w-[300px] bg-white rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 flex flex-col justify-between mt-12 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  ${tw`relative`}
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`font-bold tracking-wider mr-3`}
  }
  .featuredText {
    ${tw`text-[1.25rem] font-bold px-3 rounded py-2 bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-full text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-center`}
    .earlyBird {
      ${tw`flex-col flex`}
      .currentPrice {
        ${tw`text-lg font-bold leading-none`}
        .bigText {
          ${tw`text-5xl font-bold`}
        }
      }
      .earlyBirdText {
        ${tw`text-[12px] text-gray-500`}
      }
    }
    .oldPriceContainer {
      ${tw`line-through text-red-700`}
    }
    .oldPrice {
      ${tw`text-gray-700 text-3xl font-normal block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium font-semibold text-gray-700 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-changed-100 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-changed-300 tracking-wide ml-3`}
    }
  }
`;

const PlanAction = tw.div`mt-4`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-changed-300 px-6 py-4 rounded hover:bg-changed-100 focus:shadow-outline focus:outline-none transition-colors duration-300`}
`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-1/6 lg:h-1/3 bg-white z-0`;

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
const savePaymentDetails = async (req, admin) => {
  const successLogRes = await fetch(
    `${BASE}/payment-gateway/successful-payment-handling-individual-coupon-generation`,
    {
      method: "POST",
      body: JSON.stringify({
        // buyerStudId: user.id,
        // order_id: data.id,
        // response
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  ).then((t) => t.json());
  console.log(successLogRes);
  // axios
  //   .post(`${BASE}/payment-gateway/successful-payment-handling-individual-coupon-generation`, { req, admin })
  //   .then((res) => {
  //     // axios.patch(`${BASE}/send/updateAdminPayment/${admin}`);
  //     setTimeout(() => {
  //       window.location.reload(true);
  //     }, 500);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
};

const PricingPage = ({
  subheading = "",
  heading = "Discount and Coupons",
  description = "Make your business carbon neutral and start reaping the benefits of a green economy.",
  plans = null,
  primaryButtonText = "Pay and Get Coupons",
}) => {
  let navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);
  console.log(user, "USERDETAILS");
  const [userData, setUserData] = useState({});
  const [showCoupon, setShowCoupon] = useState(false);

  async function displayRazorpay(price) {
    const amount = parseInt(price);
    console.log(amount);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const data = await fetch(
      `${BASE}/payment-gateway/razorpay-individual-coupon-initiation`,
      {
        method: "POST",
        body: JSON.stringify({
          buyerStudId: user.id,
          amount: amount, //1rs Live mode
          // amount: amount
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    ).then((t) => t.json());

    console.log(data);
    const options = {
      key:
        process.env.REACT_APP_RAZORPAY_TEST_MODE === "ON"
          ? process.env.REACT_APP_RAZORPAY_TEST_KEY_ID
          : process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID,
      currency: data.currency,
      amount:
        process.env.REACT_APP_RAZORPAY_1RUPEE_LIVE_MODE === "ON"
          ? 100
          : amount * 100,
      order_id: data.id,
      name: `Fyzen Career Solutions Pvt Ltd`,
      description: "Thank you for the confirmation!",
      image:
        "https://media-exp1.licdn.com/dms/image/C560BAQExXV7Oc9sZMA/company-logo_200_200/0/1629144901212?e=2147483647&v=beta&t=x7BZQmslIuY0OgabE7lpKn45A4m53N2jezdjXc6nT_Q",

      handler: async function (response) {
        setShowCoupon(true);
        let couponArr = [];
        try {
          const couponCodeRes = await fetch(
            `${BASE}/coupon/individual-coupon-creation`,
            {
              method: "POST",
              body: JSON.stringify({
                planType: amount,
                owner: user.id,
                createdBy: user.id,
                discount: 100,
                interviewCost: amount,
                paymentDue: 0,
                sessDuration,
              }),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            }
          ).then((t) => t.json());

          // console.log(couponCodeRes, "coupon codes");
          couponArr = couponCodeRes.data;
          let coupons = couponArr.map((c) => c.id);
          // console.log(couponArr,"hh")
          // let couponEmailArr={code1:"",code2:"",code3:""}
          // if (couponArr[0]){
          // couponEmailArr.code1=couponArr[0]["id"]?(couponArr[0]["id"]):("");}
          // if (couponArr[1]){
          // couponEmailArr.code2=couponArr[1]["id"]?(couponArr[1]["id"]):("");}
          // if (couponArr[2]){
          // couponEmailArr.code3=couponArr[2]["id"]?(couponArr[2]["id"]):("");}
          console.log(
            coupons.length == 2 ? "Two" : "Three",
            coupons.length == 2 ? "49" : "98",
            couponArr
          );
          await sendCouponCodeToHimself(coupons);
        } catch (error) {
          console.log(error);
        }

        try {
          const successLogRes = await fetch(
            `${BASE}/payment-gateway/successful-payment-handling-individual-coupon-generation`,
            {
              method: "POST",
              body: JSON.stringify({
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                buyerStudId: user.id,
                buyerEmail: user.email,
                buyerName: user.fname + " " + user.lname,
                couponCodes: couponArr,
              }),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            }
          ).then((t) => t.json());
        } catch (error) {
          console.log(error);
        }
      },
      prefill: {
        name: user.fname + " " + user.lname,
        email: user.email,
        contact: user.mobNumber,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", async function (response) {
      console.log("Trying to log in databse");
      const failedLogRes = await fetch(
        `${BASE}/payment-gateway/failed-payment-handling-individual-coupon-generation`,
        {
          method: "POST",
          body: JSON.stringify({
            buyerStudId: user.id,
            order_id: data.id,
            response,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      ).then((t) => t.json());
      // alert(response.error.description);
      console.log("Payment failed due to:", response);
    });
  }
  async function sendCouponCodeToHimself(coupons) {
    console.log(coupons, user.email, user.fname + " " + user.lname);
    const sendRefEmailsHimselfResp = await fetch(
      `${BASE}/coupon/send-email-c100-himself`,
      {
        method: "POST",
        body: JSON.stringify({
          email: user.email,
          userName: user.fname + " " + user.lname,
          coupons,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    ).then((t) => t.json());
  }

  const [sessDuration, setSessDuration] = useState(30);

  const defaultPlans = [
    {
      name: "Uno",
      price: {
        45: ["₹", `${process.env.REACT_APP_STD_45MIN}`],
        30: ["₹", `${process.env.REACT_APP_SHORT_30MIN}`],
      }[sessDuration],
      oldPrice: ["₹", "799", ""],
      description: "1 Mock Interview or 1 Mentorship Session",
      description2: {
        30: "(with Live Feedback)",
        45: `with Resume Review, Live Feedback, Detailed Feedback Report (Documented), and Action Plan for Improvement (Documented)`,
      }[sessDuration],
      features: [
        "Scope 1, 2 & 3 reporting",
        "2 data connectors",
        "Up to 100 employees",
        "GHG Reporting",
      ],
      url: "https://google.com",
      featured: "Just Try It!",
    },
    {
      name: "Duo",
      price: {
        45: ["₹", `${process.env.REACT_APP_DUO_STD_45MIN}`, ""],
        30: ["₹", `${process.env.REACT_APP_DUO_SHORT_30MIN}`],
      }[sessDuration],
      oldPrice: ["₹", "1499", ""],
      description: "2 Sessions",
      description2: {
        30: "(with Live Feedback)",
        45: `with Resume Review, Live Feedback, Detailed Feedback Report (Documented), and Action Plan for Improvement (Documented)`,
      }[sessDuration],
      features: [
        "Scope 1, 2 & 3 reporting",
        "∞ data connectors",
        "Up to 500 employees",
        "Multiple report types",
        "Account Manager",
        "AI-assisted automation",
      ],
      url: "https://google.com",
      featured: { 45: "You Save ₹49", 30: "You Save ₹49" }[sessDuration],
    },
    {
      name: "Trio",
      price: {
        45: ["₹", `${process.env.REACT_APP_TRIO_STD_45MIN}`, ""],
        30: ["₹", `${process.env.REACT_APP_TRIO_SHORT_30MIN}`],
      }[sessDuration],
      oldPrice: ["₹", "2199", ""],
      description: "3 Sessions",
      description2: {
        30: "(with Live Feedback)",
        45: `with Resume Review, Live Feedback, Detailed Feedback Report (Documented), and Action Plan for Improvement (Documented)`,
      }[sessDuration],
      features: [
        "∞ data types",
        "∞ integrations",
        "∞ employees",
        "Custom API",
        "White Label Reporting",
        "Hands-on climate consultancy",
      ],
      url: "https://google.com",
      featured: { 45: "You Save ₹98", 30: "You Save ₹98" }[sessDuration],
    },
  ];

  if (!plans) plans = defaultPlans;
  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
  const [couponCodesVisible, setCouponCodeVisible] = useState(true);

  const handleDrawerToggle = () => {
    setCouponCodeVisible(!couponCodesVisible);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container
      style={{
        marginLeft: "0px",
        marginRight: "0px",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        minHeight: "90vh",
      }}
    >
      {/* 1 && toast.success("Coupon Codes Generated Successfully", {
      ...toastStyle.success,
      position: "top-center",
    })*/}

      <ContentWithPaddingXl>
        <HeaderContainer style={{ marginTop: "0px" }}>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {/* {description && <Description>{description}</Description>} */}
        </HeaderContainer>
        <div className=" self-center w-2/3">
          <div className=" mt-4 duration-div mb-2 text-black rounded-lg shadow-md border bg-white bg-opacity-40">
            <Typography
              className="text-gray-700 rounded-md px-2 bg-white"
              sx={{ fontWeight: 600 }}
            >
              Slot Duration
            </Typography>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              defaultValue={{ 30: "short", 45: "long" }[sessDuration]}
              value={{ 30: "short", 45: "long" }[sessDuration]}
              name="duration-radio-buttons"
              sx={{
                flexDirection: "row",
                justifyContent: "space-evenly",
                flexGrow: 0.8,
                fontWeight: 600,
              }}
              onChange={(e) => {
                console.log(e.target.value);
                switch (e.target.value) {
                  case "short": {
                    setSessDuration(30);
                    break;
                  }
                  case "long": {
                    setSessDuration(45);
                    break;
                  }
                }
              }}
            >
              <FormControlLabel
                sx={{
                  fontWeight: 600,
                }}
                value="short"
                control={<Radio />}
                label="30 minutes"
              />
              <FormControlLabel
                sx={{
                  fontWeight: 600,
                }}
                value="long"
                control={<Radio />}
                label="45 minutes"
              />
            </RadioGroup>
          </div>
        </div>
        <PlansContainer style={{ marginTop: "0rem" }}>
          {plans.map((plan, index) => (
            <Plan
              key={index}
              featured={plan.featured}
              style={{
                marginTop: `${
                  index == 0 && window.innerWidth < 1024 ? "1.5rem" : "3rem"
                }`,
              }}
            >
              <PlanHeader>
                <span
                  className="nameAndFeaturedContainer "
                  style={{ flexDirection: "row" }}
                >
                  <span className="name discount-name">{plan.name}</span>
                  {plan.featured && (
                    <span>
                      <span className="featuredText" style={{ marginLeft: 5 }}>
                        {plan.featured}
                      </span>
                    </span>
                  )}
                </span>
                <div className="pricingContainer">
                  {/* <div className="oldPriceContainer">
                {plan.oldPrice && <span className="oldPrice translate-y-[8px]">{plan.oldPrice}</span>}
                </div> */}
                  <div className="earlyBird">
                    {/* <span className="earlyBirdText">Earlybird Price</span> */}
                    <span className="currentPrice">
                      <span className="bigText">{plan.price[0]}</span>
                      <span className="bigText">{plan.price[1]}</span>
                      {plan.price[2]}{" "}
                    </span>
                  </div>
                </div>
                <p className="description">
                  {plan.description}
                  <br />
                  {plan.description2}
                </p>
              </PlanHeader>
              {/* <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <li className="feature" key={index}>
                    <CheckboxIcon className="icon" />
                    <span className="text">{feature}</span>
                  </li>
                ))}
              </PlanFeatures> */}
              {index === 0 ? null : (
                <PlanAction style={{ cursor: "pointer" }}>
                  <ActionButton
                    as="a"
                    onClick={() => displayRazorpay(plan.price[1])}
                  >
                    {primaryButtonText}
                  </ActionButton>
                </PlanAction>
              )}
            </Plan>
          ))}
        </PlansContainer>
        {showCoupon ? (
          <div>
            <PlanAction style={{ cursor: "pointer" }}>
              <ActionButton
                as="a"
                onClick={() => {
                  navigate("/s/dashboard");
                }}
              >
                Click Here to See Your Coupons
              </ActionButton>
            </PlanAction>
            <h3 style={{ textAlign: "center", color: "black" }}>
              Your coupon codes have been sent to you via Gmail and WhatsApp!
            </h3>
          </div>
        ) : null}
      </ContentWithPaddingXl>
      <WhiteBackgroundOverlay />
    </Container>
  );
};

export default PricingPage;
