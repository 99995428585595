import React from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
// import { css } from "styled-components/macro";
import { SectionHeading } from "../misc/Headings.js";
import { SectionDescription } from "../misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import LockClockIcon from "@mui/icons-material/LockClock";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import "./Features.css";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const displayIcon = (num) => {
  if (num === 0) {
    return <MilitaryTechIcon />;
  } else if (num === 1) {
    return <CurrencyRupeeIcon />;
  } else if (num === 2) {
    return <AllInclusiveIcon />;
  } else if (num === 3) {
    return <AccountTreeIcon />;
  } else if (num === 4) {
    return <LockClockIcon />;
  } else if (num === 5) {
    return <MarkEmailUnreadIcon />;
  }
};
const Features = () => {
  var cards = null,
    heading = "Amazing Features",
    description = "";
  const defaultCards = [
    {
      title: "Premium Quality",
      description:
        "The interviewers and mentors are working professionals from top MNCs, possessing the tonnes of work experience in the roles/companies which students aspire for",
    },
    {
      title: "Affordable",
      description:
        "The mock interview and mentorship session charges are very nominal and far below the market price for equivalent quality, and thus easily affordable for the students",
    },
    {
      title: "Unlimited Mock Interviews and Mentorship Sessions",
      description:
        "A student can undertake any number of mock interviews or mentorship sessions with the same or different professionals for continous improvement",
    },
    {
      title: "Smart Match-making",
      description:
        "Services are offered with most simple and direct approach where students specify the customized preferences, connect with the matching professionals",
    },
    {
      title: "Safe and Secure, 24x7 Services",
      description:
        "The user database is secured with all the possible means. User data is never shared with any third party. Mock Interviews and Mentorship Sessions can be scheduled and conducted anytime",
    },
    {
      title: "Smart and Timely Notifications",
      description:
        "All important events like nearing mock interviews or mentorship sessions, receipt/acceptance of requests, etc are notified to the users via WhatsApp and email",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer style={{ paddingBottom: "0px", paddingTop: "0px" }}>
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card className="cards">
              <span className="imageContainer">{displayIcon(i)}</span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description normalText">{card.description}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
export default Features;
