import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Grid, Box, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import axios from "axios";
import { BASE } from "../../constants/endpoints";
/**================================================================================================== */

const Sunday = ({ date, name, id, price, profile }) => {
  const user = JSON.parse(localStorage.getItem("isAuthenticated"));

  const [tate, settate] = React.useState("");
  const handleChange = (ev) => {
    settate(ev.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(user);
    const handShakeReq = {
      toname: name,
      toId: id,
      fromname: user.username,
      fromId: user.Id,
      slot: tate + " " + date,
      price: price,
      profile: profile,
    };
    console.log(name);
    console.log("hi");
    console.log(user.username);
    axios.post(`${BASE}/api/post`, handShakeReq).then((response) => {
      console.log(response.status);
      console.log(response.data);
    });
    window.alert("Are you sure?");
  };
  return (
    <>
      <FormControl component="fieldset" name="method-of-payment">
        <RadioGroup onChange={handleChange} value={tate} sx={{ ml: 1 }}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <FormControlLabel
                value="09:30 AM - 10:15 AM"
                control={<Radio />}
                label="09:30 AM - 10:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 1,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="01:30 AM - 02:15 AM"
                control={<Radio />}
                label="01:30 AM - 02:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 1,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="10:30 AM - 11:15 AM"
                control={<Radio />}
                label="10:30 AM - 11:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 1,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="2:30 AM - 3:15 AM"
                control={<Radio />}
                label="02:30 AM - 03:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 1,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="6:30 AM - 7:15 AM"
                control={<Radio />}
                label="06:30 AM - 07:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 1,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="5:30 AM - 6:15 AM"
                control={<Radio />}
                label="05:30 AM - 06:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 1,
                }}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box sx={{ display: "flex", mt: 3, mb: 2 }}>
        <AccessTimeIcon
          sx={{
            backgroundColor: "yellowgreen",
            fontSize: 46,
            p: 0.4,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          }}
        />
        <Typography
          sx={{
            backgroundColor: "yellowgreen",
            mr: 1,
            fontSize: 20,
            p: 1,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }}
        >
          Selected Slot
        </Typography>
      </Box>
      {tate ? (
        <p>
          <span style={{ fontSize: 20, fontWeight: "bold" }}>{tate}</span>{" "}
          <span style={{ fontSize: 20 }}>on</span>{" "}
          <span style={{ fontSize: 20, fontWeight: "bold" }}>{date}</span>
        </p>
      ) : (
        ""
      )}
      <Grid xs={12}>
        <form onSubmit={handleSubmit} method="POST">
          <button>Send</button>
        </form>
        {/* <Button variant="contained">Send Request</Button> */}
      </Grid>
    </>
  );
};
/**================================================================================================== */

const Monday = (date) => {
  const [tate, settate] = React.useState("");

  const handleChange = (ev) => {
    settate(ev.target.value);
  };
  return (
    <>
      <FormControl component="fieldset" name="method-of-payment">
        <RadioGroup onChange={handleChange} value={tate}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <FormControlLabel
                value="09:30 AM-10:15 AM"
                control={<Radio />}
                label="9:30 AM-10:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="01:30 AM-02:15 AM"
                control={<Radio />}
                label="1:30 AM-2:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="10:30 AM-11:15 AM"
                control={<Radio />}
                label="10:30 AM-11:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="2:30 AM-3:15 AM"
                control={<Radio />}
                label="2:30 AM-3:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="6:30 AM-7:15 AM"
                control={<Radio />}
                label="6:30 AM-7:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="5:30 AM-6:15 AM"
                control={<Radio />}
                label="5:30 AM-6:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box sx={{ display: "flex", mt: 3, p: 1 }}>
        <AccessTimeIcon
          sx={{ backgroundColor: "yellowgreen", fontSize: 30, p: 0.4 }}
        />
        <Typography
          sx={{
            backgroundColor: "yellowgreen",
            mr: 1,
            fontSize: 20,
            pl: 0.5,
            pr: 0.5,
          }}
        >
          New Slot:
        </Typography>
      </Box>
      {tate ? (
        <p>
          <b>{tate}</b> on <b>{date.date}</b>
        </p>
      ) : (
        ""
      )}
    </>
  );
};
/**================================================================================================== */

const Tuesday = (date) => {
  const [tate, settate] = React.useState("");

  const handleChange = (ev) => {
    settate(ev.target.value);
  };
  return (
    <>
      <FormControl component="fieldset" name="method-of-payment">
        <RadioGroup onChange={handleChange} value={tate}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <FormControlLabel
                value="09:30 AM-10:15 AM"
                control={<Radio />}
                label="9:30 AM-10:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="01:30 AM-02:15 AM"
                control={<Radio />}
                label="1:30 AM-2:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="10:30 AM-11:15 AM"
                control={<Radio />}
                label="10:30 AM-11:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="2:30 AM-3:15 AM"
                control={<Radio />}
                label="2:30 AM-3:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="6:30 AM-7:15 AM"
                control={<Radio />}
                label="6:30 AM-7:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="5:30 AM-6:15 AM"
                control={<Radio />}
                label="5:30 AM-6:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box sx={{ display: "flex", mt: 3, p: 1 }}>
        <AccessTimeIcon
          sx={{ backgroundColor: "yellowgreen", fontSize: 30, p: 0.4 }}
        />
        <Typography
          sx={{
            backgroundColor: "yellowgreen",
            mr: 1,
            fontSize: 20,
            pl: 0.5,
            pr: 0.5,
          }}
        >
          New Slot:
        </Typography>
      </Box>
      {tate ? (
        <p>
          <b>{tate}</b> on <b>{date.date}</b>
        </p>
      ) : (
        ""
      )}
    </>
  );
};
/**================================================================================================== */

const Wednesday = (date) => {
  const [tate, settate] = React.useState("");

  const handleChange = (ev) => {
    settate(ev.target.value);
  };
  return (
    <>
      <FormControl component="fieldset" name="method-of-payment">
        <RadioGroup onChange={handleChange} value={tate}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <FormControlLabel
                value="09:30 AM-10:15 AM"
                control={<Radio />}
                label="9:30 AM-10:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="01:30 AM-02:15 AM"
                control={<Radio />}
                label="1:30 AM-2:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="10:30 AM-11:15 AM"
                control={<Radio />}
                label="10:30 AM-11:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="2:30 AM-3:15 AM"
                control={<Radio />}
                label="2:30 AM-3:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="6:30 AM-7:15 AM"
                control={<Radio />}
                label="6:30 AM-7:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="5:30 AM-6:15 AM"
                control={<Radio />}
                label="5:30 AM-6:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box sx={{ display: "flex", mt: 3, p: 1 }}>
        <AccessTimeIcon
          sx={{ backgroundColor: "yellowgreen", fontSize: 30, p: 0.4 }}
        />
        <Typography
          sx={{
            backgroundColor: "yellowgreen",
            mr: 1,
            fontSize: 20,
            pl: 0.5,
            pr: 0.5,
          }}
        >
          New Slot:
        </Typography>
        {tate ? (
          <p>
            <b>{tate}</b> on <b>{date.date}</b>
          </p>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};
/**================================================================================================== */

const Thursday = (date) => {
  const [tate, settate] = React.useState("");

  const handleChange = (ev) => {
    settate(ev.target.value);
  };
  return (
    <>
      <FormControl component="fieldset" name="method-of-payment">
        <RadioGroup onChange={handleChange} value={tate}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <FormControlLabel
                value="09:30 AM-10:15 AM"
                control={<Radio />}
                label="9:30 AM-10:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="01:30 AM-02:15 AM"
                control={<Radio />}
                label="1:30 AM-2:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="10:30 AM-11:15 AM"
                control={<Radio />}
                label="10:30 AM-11:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="2:30 AM-3:15 AM"
                control={<Radio />}
                label="2:30 AM-3:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="6:30 AM-7:15 AM"
                control={<Radio />}
                label="6:30 AM-7:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="5:30 AM-6:15 AM"
                control={<Radio />}
                label="5:30 AM-6:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box sx={{ display: "flex", mt: 3, p: 1 }}>
        <AccessTimeIcon
          sx={{ backgroundColor: "yellowgreen", fontSize: 30, p: 0.4 }}
        />
        <Typography
          sx={{
            backgroundColor: "yellowgreen",
            mr: 1,
            fontSize: 20,
            pl: 0.5,
            pr: 0.5,
          }}
        >
          New Slot:
        </Typography>
        {tate ? (
          <p>
            <b>{tate}</b> on <b>{date.date}</b>
          </p>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};
/**================================================================================================== */

const Friday = (date) => {
  const [tate, settate] = React.useState("");

  const handleChange = (ev) => {
    settate(ev.target.value);
  };
  return (
    <>
      <FormControl component="fieldset" name="method-of-payment">
        <RadioGroup onChange={handleChange} value={tate}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <FormControlLabel
                value="09:30 AM-10:15 AM"
                control={<Radio />}
                label="9:30 AM-10:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="01:30 AM-02:15 AM"
                control={<Radio />}
                label="1:30 AM-2:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="10:30 AM-11:15 AM"
                control={<Radio />}
                label="10:30 AM-11:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="2:30 AM-3:15 AM"
                control={<Radio />}
                label="2:30 AM-3:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="6:30 AM-7:15 AM"
                control={<Radio />}
                label="6:30 AM-7:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="5:30 AM-6:15 AM"
                control={<Radio />}
                label="5:30 AM-6:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box sx={{ display: "flex", mt: 3, p: 1 }}>
        <AccessTimeIcon
          sx={{ backgroundColor: "yellowgreen", fontSize: 30, p: 0.4 }}
        />
        <Typography
          sx={{
            backgroundColor: "yellowgreen",
            mr: 1,
            fontSize: 20,
            pl: 0.5,
            pr: 0.5,
          }}
        >
          New Slot:
        </Typography>
        {tate ? (
          <p>
            <b>{tate}</b> on <b>{date.date}</b>
          </p>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};
/**================================================================================================== */

const Saturday = (date) => {
  const [tate, settate] = React.useState("");

  const handleChange = (ev) => {
    settate(ev.target.value);
  };
  return (
    <>
      <FormControl component="fieldset" name="method-of-payment">
        <RadioGroup onChange={handleChange} value={tate}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <FormControlLabel
                value="09:30 AM-10:15 AM"
                control={<Radio />}
                label="9:30 AM-10:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="01:30 AM-02:15 AM"
                control={<Radio />}
                label="1:30 AM-2:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="10:30 AM-11:15 AM"
                control={<Radio />}
                label="10:30 AM-11:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="2:30 AM-3:15 AM"
                control={<Radio />}
                label="2:30 AM-3:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="6:30 AM-7:15 AM"
                control={<Radio />}
                label="6:30 AM-7:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="5:30 AM-6:15 AM"
                control={<Radio />}
                label="5:30 AM-6:15 AM"
                sx={{
                  pr: 1,
                  backgroundColor: "orange",
                  borderRadius: 4,
                }}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box sx={{ display: "flex", mt: 3, p: 1 }}>
        <AccessTimeIcon
          sx={{ backgroundColor: "yellowgreen", fontSize: 30, p: 0.4 }}
        />
        <Typography
          sx={{
            backgroundColor: "yellowgreen",
            mr: 1,
            fontSize: 20,
            pl: 0.5,
            pr: 0.5,
          }}
        >
          New Slot:
        </Typography>
        {tate ? (
          <p>
            <b>{tate}</b> on <b>{date.date}</b>
          </p>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};
export { Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday };
