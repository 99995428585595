import React, { useContext } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import testimonials from "../../Home/Partner/testimonialsData";
import "./AllStories.css";
import { Pagination } from "@mui/material";
import { useState } from "react";
import {BASE as BASE_URL} from "../../../constants/endpoints"
import UserContext from "../../../contexts/UserContext";
import axios from "axios";
import { Oval } from "react-loader-spinner";


const splitArray = (arr, rows) => {
  const itemsPerRow = rows;
  return arr.reduce((acc, val, ind) => {
     const currentRow = Math.floor(ind / itemsPerRow);
     if(!acc[currentRow]){
        acc[currentRow] = [val];
     }else{
        acc[currentRow].push(val);
     };
    //  console.log(acc,testimonials)
     return acc;
  }, []);
};


const AllStories = () => {

    const {user} = useContext(UserContext)

  const [stories, setStories] = useState([])

  const testimonialsPages = splitArray(stories, 10)

  const [page, setPage] = useState(1)
  const [isloaded, setIsloaded] = useState(false)
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    axios.get(`${BASE_URL}/admin/success-stories/${user.id}`).then((res) => {
      setStories(res.data)
      setIsloaded(true)
    }).catch(err=>{
      console.log(err)
    })
  },[user])

  
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const color = ["#febfbd", "#fdddaa", "#b1e8ca"];
  return (
    <>
    {isloaded ? 
    (<div className="p-2">
      <div
      style={{paddingTop:"35px"}}
        className="page-heading-h1"
      >
        Success Stories
      </div>
      {/* <div
        className="normalText"
        style={{padding:"20px 10%"}}
      >
        Read the{" "}
        <a
          href="https://www.iitmaa.org/f/mock-interview-12582"
          className="font-semibold underline text-blue-600 hover:text-blue-800"
        >
          Blog Article
        </a>{" "}
        on the role of Fyzen Career Solutions Pvt Ltd (owner of interwiu.com) in
        the success of below students by conducting Mock Interviews (with
        Feedback Sessions) before the Campus Placements (2021-22) at IIT Madras.
        <br /> (published by <b> IIT Madras Alumni Association</b>)
      </div> */}
      {testimonialsPages[page-1]?.map((testimonial, index) => {
        return (
          <div className="my-5 mx-auto">
            <div
              className="text-black rounded-lg p-3 mx-auto main-box"
              style={{
                maxWidth: "1100px",
                backgroundColor: color[index % 3],
              }}
              key={index}
            >
              <div className="col-span-1 my-auto">
                <div
                  className="rounded-3xl m-3 mx-auto overflow-hidden  img-box"
                  style={{ height: "169px", width: "169px" }}
                >
                  <img
                    src={testimonial?.image}
                    className="object-cover h-full w-full"
                    alt="Person"
                  />
                </div>
                <div className="text-center">
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{ fontSize: "1.2rem" }}
                    sx={{ fontWeight: "bold" }}
                  >
                    {testimonial.name}
                  </Typography>
                  {/* <p>
                            Place at {testimonial.company} as {testimonial.position}
                          </p> */}
                </div>
              </div>
              <div className="col-span-3 my-auto">
                <Typography
                  variant="body1"
                  sx={{ m: 0, fontSize: "18px" }}
                  gutterBottom
                >
                  {testimonial.degree} <b>{testimonial.institute}</b>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "18px" }}
                  gutterBottom
                >
                  Placed at <b>{testimonial.placedAt}</b> as{" "}
                  <b>{testimonial.position}</b>
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    textAlign: "justify",
                    lineHeight: 1.2,
                    fontSize: 16,
                    mt: 2,
                  }}
                >
                  <i class="fa-solid fa-quote-left text-green-800"></i>{" "}
                  {testimonial.comment}{" "}
                  <i class="fa-solid fa-quote-right text-green-800"></i>
                </Typography>
              </div>
            </div>
          </div>
        );
      })}
      <Pagination count={Math.ceil(stories?.length/10)} page={page} onChange={handleChange} style={{margin:"30px", display:"flex",justifyContent:"center"}} color="primary" />
    </div>) : (
      (
        <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )
    )}
    </>
  );
};

export default AllStories;
