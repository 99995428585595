import React, { useState } from "react";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
// import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as SvgDecoratorBlob1 } from "./../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "./../../images/svg-decorator-blob-8.svg";
import Link from "@mui/material/Link";

const Column = tw.div`flex flex-col items-center`;

const FAQSContainer = tw.dl` max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const StudentsFAQS = () => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  const faqs = [
    {
      question: "What is interwiu.com?",
      answer:
        "interwiu.com is an online mock interview and mentorship platform which connects the students (as interviewees/mentees) with the professionals from top MNCs (as interviewers/mentors) using a filter-based smart match-making algorithm, to facilitate the Mock Interviews and Mentorship Sessions with integral Feedback. The interviewers and mentors serving at the platform are experienced professionals from various big MNCs like Microsoft, Amazon, Google, Goldman Sachs, McKinsey & Company, IBM, Flipkart, JPMorgan Chase & Co., American Express, Meta, Citi, HSBC, Morgan Stanley, Boston Consulting Group (BCG), Bain & Company, PwC, Netflix, Directi, IQVIA, Kotak Mahindra Bank, BNY Mellon, Hexagon Capability Center India Pvt Ltd, Accenture, Deloitte, etc.",
    },
    {
      question: "How is a Mock Interview or Mentorship Session scheduled?",
      answer:
        "A student browses the professionals, apply filters (profile, suitable date, etc.) to refine his/her search, and send a Mock Interview Request or Mentorship Session Request to each of the best suiting professionals by picking a date and time-slot as per the respective professional's and his/her own availability",
    },
    {
      question:
        "What should I do after receiving a Mock Interview Request or Mentorship Session Request from a student?",
      answer:
        "As you see a Mock Interview Request or Mentorship Session Request received from a student, you can either accept it straightaway, or can first look at the resume of the student and then accept his/her Mock Interview Request or Mentorship Session Request as suitable",
    },
    {
      question:
        "How and when do I get the meeting link for a mock interview or mentorship session?",
      answer:
        "Once you accept a student's Mock Interview Request or Mentorship Session Request, the student makes the payment for the mock interview or mentorship session. Then, a unique meeting link is automatically generated by interwiu.com which gets embedded in the [Join Mock interview] button or [Join Mentorship Session] button provided on the card of each such confirmed mock interview or mentorship session. The same meeting link is provided to the respective student as well",
    },
    {
      question:
        "What structure of a Mock Interview (with Live Feedback) I am expected to keep?",
      answer:
        "The mock interviews should be structured exactly like real placement/internship interviews in every context such as type/kind, level, and number of questions asked; inclusion of stress rounds; environment; etc. Please provide feedback during/after the mock interview on every possible aspect like a student's incorrect or partially correct answers, lack of confidence, lack of clarity, body language, etc. You can convey what went wrong/right, and how the student can improve the performance. Also, you can suggest better ways of answering certain questions asked by you to which the student’s response is unsatisfactory ",
    },
  ];

  return (
    <Container>
      <Column>
        <FAQSContainer>
          {faqs.map((faq, index) => (
            <FAQ
              key={index}
              onClick={() => {
                toggleQuestion(index);
              }}
              className="group"
            >
              <Question>
                <QuestionText className="heading1">{faq.question}</QuestionText>
                <QuestionToggleIcon
                  variants={{
                    collapsed: { rotate: 0 },
                    open: { rotate: -180 },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{
                    duration: 0.02,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  <KeyboardArrowDownIcon />
                </QuestionToggleIcon>
              </Question>
              <Answer
                variants={{
                  open: { opacity: 1, height: "auto", marginTop: "16px" },
                  collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                }}
                initial="collapsed"
                animate={activeQuestionIndex === index ? "open" : "collapsed"}
                transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                className="normalText"
              >
                {faq.answer}
              </Answer>
            </FAQ>
          ))}
        </FAQSContainer>{" "}
        <Link
          href="/i/faqs"
          underline="none"
          style={{
            margin: "20px",
            border: "1px solid",
            borderRadius: "10px",
            padding: "7px 15px",
            backgroundColor: "#f5f5f5",
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
          }}
          sx={{ fontSize: 20, "&:hover": { cursor: "pointer" } }}
        >
          {"Read More FAQs"}
        </Link>{" "}
      </Column>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
export default StudentsFAQS;
