import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import Chip from "@mui/material/Chip";
import Snackbar from "@mui/material/Snackbar";
import { Box, ClickAwayListener, Grid, Select } from "@mui/material";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment"; // Getting day from date and modifying date format
import { Calendar } from "react-multi-date-picker";
import { STUDENT_PROFILE_FORM_ROUTE } from "../../../constants/routes.js";
import UserContext from "../../../contexts/UserContext.js";
import {
  BASE,
  INTERVIEWERS_GETBYFILTERS_ENDPOINT,
} from "../../../constants/endpoints";
import axios from "axios";
import SlotAlreadyBooked from "../../Dashboard/popups/SlotAlreadyBooked.js";
import {
  compareDate,
  compareSlots,
  getCompanyLogo,
  t12HTo24HDecimalFormat,
  t24HDecimalTo12HFormat,
  toastStyle,
} from "../../../utility/helper.js";
import toast from "react-hot-toast";
//import { generateId } from "../../../../../backend/utils.js";
import CoreChip from "components/misc/CoreChip.js";
import { AiTwotoneCalendar } from "react-icons/ai";
import { InfoOutlined } from "@mui/icons-material";
import { AnimatePresence ,motion} from "framer-motion";

const Card = ({
  user,
  name,
  designation,
  company,
  price,
  logo,
  photo,
  profiles,
  id,
  gnrcSchd,
  custSchd,
  setShareUrl,
  studentId,
  coreprofiles,
  specializations,
}) => {
  const navigate = useNavigate();
  // console.log('Logo', logo)

  const [openT, setOpenT] = useState(false);

  const handleTooltipCloseT = () => {
    console.log("close")
    setOpenT(false);
  };

  const handleTooltipOpenT = () => {
    console.log("open")
    setOpenT(true);
  };

  const [showTitle, setShowTitle] = useState(false)
  const chipRef = useRef(null)
  useEffect(() => {
    if (chipRef?.current) {
      // console.log("parent offsetWidth", chipRef.current.offsetWidth)
      // console.log("parent scrollWidth", )
      setShowTitle(chipRef.current.scrollWidth < chipRef.current.children[0].scrollWidth)
      // console.log(chipRef.current.props)
      // setShowTitle(chipRef?.current.value.endsWith('..'));
    }
  }, [chipRef]);

  // Bookmarked?
  const [isBookmarked, setIsBookmarked] = useState(false);
  // const Bookmark = () => {
  //   console.log(
  //     `Name: ${name}, Desg: ${designation}, Id: ${id}, price: ${price}`
  //   );
  //   if (isBookmarked) {
  //     console.log("Bookmark Added!!!");
  //     console.log(
  //       `Name: ${name}, Desg: ${designation}, Id: ${id}, price: ${price}`
  //     );
  //   } else {
  //     console.log("Bookmark Removed");
  //   }
  // };
  // useEffect(() => {
  //   Bookmark();
  // }, [isBookmarked]);

  const chipBox = useRef(null);
  const chipContainer = useRef(null);
  const [moreChips, setMoreChips] = useState(false);
  const [heightContainer, setHeightContainer] = useState(68);

  useEffect(() => {
    if (chipBox.current && chipContainer.current) {
      if (chipBox.current?.offsetHeight > chipContainer.current?.offsetHeight) {
        setMoreChips(true);
      } else {
        setHeightContainer(
          chipBox.current?.offsetHeight > 69 ? 68 : chipBox.current?.offsetHeight
        );
        setMoreChips(false);
      }
    }
  }, [chipBox, chipContainer]);

  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      // console.log("window.scrollY", window.scrollY);
      if (chipBox) {
        if (chipContainer.current?.offsetHeight > 69) {
          chipContainer.current.style.maxHeight = "68px";
          setMoreChips(true);
        }
      }
      // if (chipBox_D) {
      //   if (chipContainer_D.current.offsetHeight > 69) {
      //     chipContainer_D.current.style.height = '68px'
      //     setMoreChips_D(true)
      //   }
      // }
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const openMoreChips = () => {
    if (moreChips) {
      chipContainer.current.style.maxHeight = "500px";
      setMoreChips(false);
    } else {
      chipContainer.current.style.maxHeight = "68px";
      setMoreChips(true);
    }
  };

  const [datePickerMobile, setDatePickerMobile] = useState(false);

  const [openBookmark, setOpenBookmark] = useState(false);
  const [msg, setMsg] = useState("");
  const handleBookmarkClick = () => {
    setOpenBookmark(true);
  };
  const handleBookmarkClose = () => {
    setOpenBookmark(false);
  };

  // Choose a Slot

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    async function fetchData() {
      axios
        .get(`${BASE}/send/getAllInterviewRequestById/${id}`)
        .then((response) => {
          setRequests(response.data);
          displaySlots(selectedDate);
        });
    }
    fetchData();
  };
  const handleClose = () => {
    setOpen(false);
    //setRequestData({});
    setSelectedDate(new Date());
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [requests, setRequests] = useState([]);
  useEffect(() => {
    async function fetchData() {
      console.log(moment(selectedDate).format("DD/MM/YYYY"));
      axios
        .get(`${BASE}/send/getAllInterviewRequestById/${id}`)
        .then((response) => {
          setRequests(response.data);
        });
    }
    fetchData();
  }, []);
  /* 
    - Request Data: This is the data which will be sent when user request for a mock interview
      e.g. requestData = {
            studentId: "",
            interviewerId: "",
            requestId: "",
            slots: "",
            date: "",
            profile: "",
            requestStatus: "",
            paymentId: "nan",
            paymentStatus: "Not Done",
          }; 
  */
  const [requestData, setRequestData] = useState({
    studentId: studentId,
    interviewerId: id,
    requestId: "#Yet_to_be_generated",
    slot: "",
    date: moment(selectedDate).format("DD/MM/YYYY"),
    profile: "",
    requestStatus: 0,
    paymentId: "nan",
    paymentStatus: "Not Done",
  });

  /*  
    To convert 12 Hour Time format to 24 Hours Decimal format(for ease of processing in backend)
    E.g. 12:15 AM -> 0.25, 01:45 PM -> 13.75
  */
  // Handling Change in the form "userData" state variable
  const handleRequestData = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // Append the property if doesn't exist and if does then update
    setRequestData((requestData) => ({
      ...requestData,
      [name]: value,
      studentId: studentId,
      interviewerId: id,
    }));
  };
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  useEffect(() => {
    if (requestData.profile && requestData.slot) setSendBtnDisabled(true);
    else setSendBtnDisabled(false);
  }, [requestData]);

  /* 
    The function 'checkInterviewerAvailability' checks whether interviewer is available for the requested slot just before booking the slot.
    This check we are doing to make sure that interviewer has not updated the schedule just few moments before the current slot booking is happening.
  */
  const [isSlotAlreadyBooked, setisSlotAlredyBooked] = useState(false);
  const slotBookedModalHandler = (data) => {
    setisSlotAlredyBooked(data);
  };
  const checkInterviewerAvailability = async (requestData) => {
    console.log("requestData :-", requestData);
    let searchStr = "";
    searchStr += "?id=" + requestData.interviewerId;
    searchStr += "&profile=" + String(requestData.profile);
    searchStr +=
      "&time=" +
      requestData.slot.slice(1).split(":")[0] +
      "," +
      requestData.slot.slice(1).split(":")[1];
    searchStr += "&date=" + requestData.date;

    console.log("Rakesh Search STring");
    console.log(searchStr);
    let isInterviewerAvailable = false;
    await axios
      .get(INTERVIEWERS_GETBYFILTERS_ENDPOINT + searchStr)
      .then((res) => {
        console.log(
          "res.data[0].id :- ",
          res.data[0].id,
          " requestData.interviewerId :- ",
          requestData.interviewerId,
          " res.data[0].id === requestData.interviewerId :- ",
          res.data.id === requestData.interviewerId
        );
        console.log("Rakesh res.data?.length:");
        console.log(res.data?.length);
        console.log("Rakesh res Data:");
        console.log(res.data);
        if (res.data?.length === 0) {
          setisSlotAlredyBooked(true);
        }
        if (res.data?.length === 1) {
          isInterviewerAvailable = true;
        }
      })
      .catch((err) => console.log(err));
    console.log("isInterviewerAvailable", isInterviewerAvailable);
    return isInterviewerAvailable;
  };

  const addEntryToInterviewReqTable = (requestData) => {
    console.log("Yes");
    var newReq = {
      //reqId: generateId(),
      studId: studentId,
      interviewerId: requestData.interviewerId,
      profile: requestData.profile,
      date: requestData.date, // [DD/MM/YYYY]
      fromTime: requestData.slot.slice(1).split(":")[0], // Time is in 24 hour decimal format.
      toTime: requestData.slot.slice(1).split(":")[1],
      reqStatus: "ReqSent",
      mockId: "",
      reqStatusTime: [new Date(), null, null, null, null],
    };
    axios.post(`${BASE}/send/sendInterviewReq`, newReq).then((response) => {
      console.log(response.status);
      toast.success("Request Sent Successfully", {
        ...toastStyle.success,
        position: "top-center",
      });
    });
  };

  // Displaying the slots to Popup
  const [slotsArray, setSlotsArray] = useState([]);
  const displaySlots = (date) => {
    const currentDate = moment(date).format("DD/MM/YYYY");
    const currentDay = moment(date).format("ddd").toLowerCase();
    let check = false;
    if (custSchd !== 0) {
      custSchd?.map((item) => {
        if (item.date === currentDate) {
          check = true;
          let slotArray = [];
          item.sessions?.map((sess) => {
            let duration = sess.to - sess.from
              // console.log(sess.to, sess.from, duration, duration % 0.75)
              let i = sess.from 
              while (true) {
                let slot = {
                  from: i,
                  to: i + 0.75
                }
                if (i === 0 && duration % 0.75 !== 0) {
                  
                  let extra = duration - Math.floor(duration/0.75)*0.75
                  if (duration < 0.75) {
                    
                    return;
                  } else {
                    slot.from += extra
                    slot.to += extra
                    i += extra
                  }
                } else if (slot.to > 24) {
                  slot.to -= 24
                }
                slotArray.push([slot.from, slot.to]) 
                i += 0.75
                if (i >= sess.to)
                  break;
              }
          });

          setSlotsArray([...slotArray]);
        }
      });
    }
    if (!check) {
      if (gnrcSchd?.length !== 0) {
        gnrcSchd?.map((item) => {
          if (item.day === currentDay) {
            let slotArray = [];
            item.sessions?.map((sess) => {
              let duration = sess.to - sess.from
              // console.log(sess.to, sess.from, duration, duration % 0.75)
              let i = sess.from 
              while (true) {
                let slot = {
                  from: i,
                  to: i + 0.75
                }
                if (i === 0 && duration % 0.75 !== 0) {
                  
                  let extra = duration - Math.floor(duration/0.75)*0.75
                  if (duration < 0.75) {
                    
                    return;
                  } else {
                    slot.from += extra
                    slot.to += extra
                    i += extra
                  }
                } else if (slot.to > 24) {
                  slot.to -= 24
                }
                slotArray.push([slot.from, slot.to]) 
                i += 0.75
                if (i >= sess.to)
                  break;
              }
            });
            setSlotsArray([...slotArray]);
          }
        });
      }
    }
  };

  useEffect(() => {
    displaySlots(selectedDate);
    setRequestData({
      ...requestData,
      date: moment(selectedDate).format("DD/MM/YYYY"),
      slot: "",
    }); // When date changes then date will be updated and slot will reset
  }, [selectedDate]);

  /*
    Filters out all the requests of the selected Date, then iterate through every request in order to change the color of the slot
    to be yellow, green or red with respect to the status of a particular Interview request.
   */
  const currReq = requests.filter(
    (item) =>
      item.date?.indexOf(moment(selectedDate).format("DD/MM/YYYY")) !== -1
  );
  let classes = "rounded-full bg-green-400 my-1 px-2 py-1";
  const settingColor = (item) => {
    if (currReq?.length !== 0) {
      for (let i = 0; i < currReq?.length; i++) {
        if (
          currReq[i].fromTime === item &&
          currReq[i].reqStatus === "ReqAccepted"
        ) {
          classes = "rounded-full bg-yellow-400 my-1 px-2 py-1";
          return true;
        } else if (
          currReq[i].fromTime === item &&
          currReq[i].reqStatus === "ReqPaid"
        ) {
          classes = "rounded-full bg-red-400 my-1 px-2 py-1";
          return true;
        }
      }
    }
    classes = "rounded-full bg-green-400 my-1 px-2 py-1";
    return true;
  };
  function ImageExist(url) {
    if (url?.includes(".svg")) {
      var img = new Image();
      img.src = url;
      // console.log(img.height)
      return img?.getBBox?.height !== 0;
    } else {
      var img2 = new Image();
      img2.src = url;
      // console.log(img2?.getBBox?.height)
      return img2?.height !== 0;
    }
  }
  let imgExist = false;
  return (
    <>
      <div className="grid grid-cols-5 my-2 py-2 border rounded shadow-lg box-shadow-cust s-desktop-card">
        <div className="w-full px-2 col-span-4 border-r">
          <div className="flex justify-between w-full">
            <div className="flex gap-2">
              <div className="h-14 w-14 p-2 rounded-md" id="int-logo">
                {ImageExist(logo[0]) &&
                  imgExist === false &&
                  (imgExist = true) && (
                    <img src={logo[0]} alt="logo" className="h-full w-full" />
                  )}
                {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                  <img src={logo[1]} alt="logo" className="h-full w-full" />
                )}
                {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                  <img src={logo[2]} alt="logo" className="h-full w-full" />
                )}
                {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                  <img src={logo[3]} alt="logo" className="h-full w-full" />
                )}
                {!imgExist &&
                  !ImageExist(logo[0]) &&
                  !ImageExist(logo[1]) &&
                  !ImageExist(logo[2]) &&
                  !ImageExist(logo[3]) &&
                  (imgExist = true) && (
                    <img src={logo[4]} alt="logo" className="h-full w-full" />
                  )}
              </div>
              <div>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                    lineHeight: 1.2,
                  }}
                >
                  {name}
                  {/* John Doe */}
                </Typography>
                <p className="text-md">
                  {designation}
                  {", "} <span className="font-semibold">{company}</span>
                  {/* SDE III, Postman, India */}
                </p>
              </div>
            </div>
            <div className="flex gap-2">
              <Button
                size="large"
                variant="outlined"
                sx={{ padding: "6px", minWidth: "auto" }}
                onClick={() => {
                  setIsBookmarked(!isBookmarked);
                  if (isBookmarked) {
                    // console.log(isBookmarked);
                    setMsg("Bookmark Removed");
                  } else {
                    // console.log(isBookmarked);
                    setMsg("Bookmark Added");
                  }
                  handleBookmarkClick();
                }}
              >
                {isBookmarked ? (
                  <BookmarkAddedIcon size="large" />
                ) : (
                  // <i className="fa-solid fa-trash-can"></i>
                  <BookmarkBorderIcon size="large" />
                  // <i className="fa-solid fa-bookmark"></i>
                )}
              </Button>
              <Snackbar
                open={openBookmark}
                onClose={handleBookmarkClose}
                message={msg}
                key="bottom left"
              />
              <Button
                size="large"
                variant="outlined"
                color="success"
                sx={{ padding: "6px", minWidth: "auto", fontSize: "24px" }}
                onClick={() => {
                  document
                    .getElementById("share-cont")
                    .classList.remove("hide");
                  setShareUrl("www.twitter.co.in");
                }}
              >
                <i className="fa-solid fa-share"></i>
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-4 justify-between text-center my-1 gap-x-4">
            <div className="mx-2 col-span-2">
              <p className="text-base text-gray-400">Profile</p>
              {/* <p className="detail-body">{profile}</p> */}
              <div className="text-lg flex flex-wrap justify-center gap-1">
                {profiles.map((profile) => (coreprofiles?.includes(profile) ? null : (
                  <Chip
                    label={profile}
                    color="info"
                    sx={{ fontWeight: "bold", letterSpacing: "1px" }}
                  />)
                ))}
                {
                  coreprofiles?.map((coreProfile) => (
                    <CoreChip branch={coreProfile} specs={specializations.filter((s) => s.profile === coreProfile && s.subDomains?.length > 0)} />
                  ))
                }
              </div>
            </div>
            <div className="mx-2">
              <p className="text-base text-gray-400">Duration</p>
              <p className="text-lg">45 Min</p>
            </div>
            <div className="mx-2">
              <p className="text-base text-gray-400">Price</p>
              <p className="text-lg">{price}</p>
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              style={{
                marginTop: "10px",
                textTransform: "none",
                backgroundColor: "#0057c2",
              }}
              variant="contained"
              onClick={handleOpen}
            >
              View Slots
            </Button>
            {/* <Modal open={open} onClose={handleClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 24,
                  overflow: "hidden",
                  minWidth: "750px",
                }}
              >
                <div className="flex flex-row justify-between gap-2 bg-blue-500 p-2">
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      mx: 2,
                    }}
                  >
                    Request a Mock Interview (with Feedback Session)
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CancelIcon sx={{ color: "white" }} />
                  </IconButton>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div className="col-span-1 m-2 flex flex-row justify-center">
                    <div>
                      <Typography
                        id="modal-modal-title"
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          my: 1,
                        }}
                      >
                        {name}
                      </Typography>
                      <div className="w-full flex flex-row justify-center my-1">
                        <Calendar
                          minDate={new Date().setDate(new Date().getDate())}
                          zIndex={0}
                          value={selectedDate}
                          onChange={(e) => setSelectedDate(new Date(e))}
                          mapDays={({ date, selectedDate, isSameDate }) => {
                            let props = {};
                            if (isSameDate(date, selectedDate)) {
                              props.style = {
                                color: "#fff",
                                backgroundColor: "#00a86b",
                                border: "1px solid #000",
                              };
                              return props;
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 m-2 mt-0 my-2 flex flex-row justify-center items-center">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="h5" component="h5">
                        All Slots
                      </Typography>
                      <RadioGroup
                        row
                        name="slot"
                        id="slot"
                        value={requestData.slot || ""}
                        onChange={handleRequestData}
                        sx={{
                          maxHeight: "250px",
                          overflowX: "auto",
                          textAlign: "center",
                        }}
                      >
                        {slotsArray?.length !== 0 ? (
                          slotsArray.map(
                            (item) =>
                              (compareSlots(item[0]) ||
                                !compareDate(selectedDate, new Date())) &&
                              settingColor(item[0]) === true && (
                                <>
                                  {" "}
                                  <div
                                    style={{
                                      // width: "51%",
                                      margin: "auto",
                                      marginTop: "5px",
                                      paddingLeft: "30px",
                                      paddingTop: "7px",
                                      paddingRight: "30px",
                                      paddingBottom: "7px",
                                    }}
                                    className={classes}
                                  >
                                    {t24HDecimalTo12HFormat(item[0]) +
                                      " - " +
                                      t24HDecimalTo12HFormat(item[1])}
                                  </div>
                                  <br />
                                </>
                              )
                          )
                        ) : (
                          <p className="text-center">
                            No slots available on{" "}
                            <span className="font-bold">
                              {moment(selectedDate).format("Do MMM, YYYY")}
                            </span>
                          </p>
                        )}
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal> */}
            {isSlotAlreadyBooked ? (
              <SlotAlreadyBooked
                isSlotAlreadyBooked={isSlotAlreadyBooked}
                slotBookedModalHandler={slotBookedModalHandler}
              />
            ) : null}
          </div>
        </div>
        <div
          className="cols-span-1 px-2 h-full flex items-center justify-center"
          style={{ flexDirection: "column" }}
        >
          <img
            src={photo || require("../../../images/photo.png")}
            alt="Profile"
            className="block rounded-full w-11/12 p-1 border-2 border-gray-400"
          />
          <button
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_FRONTEND_SERVER_URL}/admin/student/${id}`,
                "_blank"
              );
            }}
            className="view-profile-card-btn w-4/5 mt-2 mx-auto px-2"
            style={{ width: "90%" }}
          >
            View Profile
          </button>
        </div>
      </div>

      <div className="s-card-main s-mobile-card s-mobile-card-shadow">
        <div className="s-profile">
          <div
            className="cols-span-1 h-full flex items-center justify-center s-profile-p-p"
            style={{ flexDirection: "column" }}
          >
            <img
              src={photo || require("../../../images/photo.png")}
              alt="Profile"
              className="block rounded-full w-11/12 p-1 border-2 border-gray-400"
            />
            <button
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_FRONTEND_SERVER_URL}/admin/student/${id}`,
                  "_blank"
                );
              }}
              className="view-profile-card-btn mt-2 mx-auto px-2 view-profile-card-btn-mobile"
              style={{ whiteSpace: "nowrap" }}
            >
              View Profile
            </button>
          </div>

          <div className="s-profile-n-d-c">
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontWeight: "bold",
                lineHeight: 1.2,
              }}
            >
              {name}
              {/* John Doe */}
            </Typography>

            <p className="text-md">
              {designation}
              {", "}
              {/* SDE III, */}
            </p>
            <p className="text-md">
              {company}
              {/* Postman */}
            </p>
          </div>
        </div>

        <div className="s-duration-price">
          <div className="mx-2">
            <span
              className="text-base text-gray-400"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Duration :{" "}
            </span>
            <span className="text-lg">30 Min</span>
          </div>
          <div className="mx-2">
            <span
              className="text-base text-gray-400"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Price :{" "}
            </span>
            <span className="text-lg">{price}</span>
          </div>
        </div>
        <div className="s-profile-profiles">
          <div className="mx-2 col-span-2">
            <p
              className="text-base text-gray-400"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Profiles :{" "}
            </p>
            <div
              ref={chipContainer}
              className={`max-h-[${heightContainer}px]`}
              style={{ overflow: "hidden" ,transition:'all 1s ease-in-out'}}
            >
              <div ref={chipBox} className="text-lg flex flex-wrap gap-1">
                {profiles?.map((profile) => (coreprofiles?.includes(profile) ? null : (
                  <Chip
                    label={profile}
                    color="info"
                    // onClick={handleTooltipOpenT}
                    sx={{ fontWeight: "bold", letterSpacing: "1px" }}
                  /> 
                )))}
                {
                  coreprofiles?.map((coreProfile) => (
                    <CoreChip style={{ width: 'auto' }} branch={coreProfile} specs={specializations.filter((s) => s.profile === coreProfile && s.subDomains?.length > 0)} />
                  ))
                }
              </div>
            </div>
            <AnimatePresence>
              {moreChips && (
                <motion.a
                  initial={{ height: "0px" ,marginTop: "0rem"}}
                  animate={{ height: "auto" ,marginTop: "0.5rem"}}
                  exit={{ height: "0px" ,marginTop: "0rem"}}
                  transition={{
                    type: "spring",
                    damping: 20,
                    stiffness: 100
                  }}
                  style={{
                    display: "block",
                    textAlign: "right",
                    fontSize: "15px",
                    color: "blue",
                    marginTop: "0.5rem",
                    overflow: "hidden",
                  }}
                  onClick={openMoreChips}
                >
                  ... <u>Show More</u>
                </motion.a>
              )}
            </AnimatePresence>
          </div>
        </div>
        <div className="s-choose-slot">
          <div className="flex justify-center">
            <Button
              style={{
                marginTop: "10px",
                textTransform: "none",
                backgroundColor: "#0057c2",
                width: "100%",
                borderRadius: "0px",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="contained"
              onClick={handleOpen}
            >
              View Slots
            </Button>
            <Modal open={open} onClose={handleClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 24,
                  overflow: "hidden",
                  width: "90%",
                  maxWidth: '750px',
                  minHeight: '60%'
                }}
              >
                <div className="flex flex-row justify-between gap-2 bg-blue-500 p-2">
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      mx: 2,
                    }}
                    className="slot-headding"
                  >
                    Slots for Mock Interview (with Feedback Session)
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CancelIcon sx={{ color: "white" }} />
                  </IconButton>
                </div>
                <div className={`grid grid-cols-${window.innerWidth < 600 ? '1' : '2'} gap-3`}>
                  <div className="col-span-1 m-2 flex flex-row justify-center">
                    <div>
                      <Typography
                        id="modal-modal-title"
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          my: 1,
                        }}
                        className="slot-name"
                      >
                        {name}
                      </Typography>
                      <div className="w-full flex flex-row justify-center my-1 s-desktop-card">
                        <Calendar
                          minDate={new Date().setDate(new Date().getDate())}
                          zIndex={0}
                          value={selectedDate}
                          onChange={(e) => setSelectedDate(new Date(e))}
                          mapDays={({ date, selectedDate, isSameDate }) => {
                            let props = {};
                            if (isSameDate(date, selectedDate)) {
                              props.style = {
                                color: "#fff",
                                backgroundColor: "#00a86b",
                                border: "1px solid #000",
                              };
                              return props;
                            }
                          }}
                        />
                      </div>
                      <div
                        className="s-mobile-card"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div className="tt ml-auto">
                          <span
                            style={{
                              fontWeight: "500",
                              lineHeight: "30px",
                              marginRight: "10px",
                            }}
                          >
                            Select Date:{" "}
                          </span>
                          <button
                            class="button-13"
                            onClick={() =>
                              setDatePickerMobile(!datePickerMobile)
                            }
                            role="button"
                          >
                            {selectedDate !== ""
                              ? moment(selectedDate).format("DD MMM YYYY") + " "
                              : ""}{" "}
                            <AiTwotoneCalendar
                              style={{
                                display: "inline-block",
                                verticalAlign: "text-top",
                              }}
                            />
                          </button>
                          <span
                            style={{
                              background: "black",
                              color: "white",
                              padding: "3px 5px",
                              top: "-146px",
                              textAlign: "left",
                            }}
                            className="calendar-mobile-tooltip tttext"
                          >
                            <Calendar
                              className="s-mobile-calendar-calendar"
                              minDate={new Date().setDate(new Date().getDate())}
                              zIndex={1}
                              value={selectedDate}
                              onChange={(e) => {
                                setSelectedDate(new Date(e));
                                setDatePickerMobile(!datePickerMobile);
                              }}
                              mapDays={({ date, selectedDate, isSameDate }) => {
                                let props = {};
                                if (isSameDate(date, selectedDate)) {
                                  props.style = {
                                    color: "#fff",
                                    backgroundColor: "#00a86b",
                                    border: "1px solid #000",
                                  };
                                  return props;
                                }
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 m-2 mt-0 my-2 flex flex-row justify-center items-center">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="h5" lassName="slot-name" component="h5">
                        All Slots
                      </Typography>
                      <RadioGroup
                        row
                        name="slot"
                        id="slot"
                        value={requestData.slot || ""}
                        onChange={handleRequestData}
                        sx={{
                          maxHeight: "250px",
                          overflowX: "auto",
                          textAlign: "center",
                        }}
                      >
                        {slotsArray?.length !== 0 ? (
                          slotsArray.map(
                            (item) =>
                              (compareSlots(item[0]) ||
                                !compareDate(selectedDate, new Date())) &&
                              settingColor(item[0]) === true && (
                                <>
                                  {" "}
                                  <div
                                    style={{
                                      // width: "51%",
                                      margin: "auto",
                                      marginTop: "5px",
                                      paddingLeft: "30px",
                                      paddingTop: "7px",
                                      paddingRight: "30px",
                                      paddingBottom: "7px",
                                    }}
                                    className={classes}
                                  >
                                    {t24HDecimalTo12HFormat(item[0]) +
                                      " - " +
                                      t24HDecimalTo12HFormat(item[1])}
                                  </div>
                                  <br />
                                </>
                              )
                          )
                        ) : (
                          <p className="text-center">
                            No slots available on{" "}
                            <span className="font-bold">
                              {moment(selectedDate).format("Do MMM, YYYY")}
                            </span>
                          </p>
                        )}
                      </RadioGroup>
                    </div>
                  </div>
                </div>
                {/* <div className="w-full flex flex-row justify-center my-2">
                  <Button
                    color="success"
                    variant="contained"
                    disabled={!sendBtnDisabled}
                    onClick={() => {
                      console.log(
                        "date: ",
                        moment(selectedDate).format("DD/MM/YYYY")
                      );
                      console.log(
                        "requestData Before sending to checkInterviewerAvailability(): ",
                        requestData
                      );
                      console.log("Sending Request...");
                      checkInterviewerAvailability(requestData).then(
                        (isInterviewerAvailable) => {
                          if (isInterviewerAvailable)
                            addEntryToInterviewReqTable(requestData);
                        }
                      );
                      handleClose();
                    }}
                  >
                    Send Request
                  </Button>
                </div> */}
              </Box>
            </Modal>
            {isSlotAlreadyBooked ? (
              <SlotAlreadyBooked
                isSlotAlreadyBooked={isSlotAlreadyBooked}
                slotBookedModalHandler={slotBookedModalHandler}
              />
            ) : null}
          </div>
        </div>
      </div >
    </>
  );
};

export default Card;
