import React from "react";
import { useNavigate } from "react-router";
import "./NotAuthenticated.css";
import { Button } from "@mui/material";
import { INTERVIEWER_PROFILE_FORM_ROUTE } from "../../constants/routes";

const NotAuthenticated = ({ text }) => {
  const navigate = useNavigate();
  return (
    <div className="notAuthenticated">
      <div>
      <p>{text}</p>
      <Button onClick={() => navigate(INTERVIEWER_PROFILE_FORM_ROUTE)} variant="contained">Complete your Account</Button>
      </div>
    </div>
  );
};

export default NotAuthenticated;
