import React, { Component } from 'react';

class ErrorBoundary extends Component {
    componentDidCatch(error, errorInfo) {
        // Log the error to an error reporting service
        console.error("ErrorBoundary caught an error", error, errorInfo);
    }

    render() {
        // Simply render the children as usual
        return this.props.children;
    }
}

export default ErrorBoundary;
