import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Chip } from "@mui/material";
import axios from "axios";
import { BASE } from "../../../constants/endpoints";
import {
  t24HDecimalTo12HFormat,
  convertDateFormat,
  toastStyle,
} from "../../../utility/helper";
import toast from "react-hot-toast";
import StudCardDetails from "components/misc/StudCardDetails";
import { FaCopy } from "react-icons/fa";
const SentRequest = ({
  user,
  name,
  desg,
  price,
  image,
  profile,
  slotStart,
  date,
  slotEnd,
  status,
  subprofile,
  id,
  logo,
  isRescheduled,
  mockId,
  reqId,
  isUnavailable,
  isDisable,
  isDelete,
  company,
  reschedule,
  sessDuration,
  sessType
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // if (status !== "pending") {
  //   //&& user.Id!=result.fromId
  //   return;
  // }

  const handleCopy = async (mockId) => {
    try {
      await navigator.clipboard.writeText(mockId);
      toast.success(`Copied to clipboard`, {
        ...toastStyle.success,
      });
    } catch (error) {
      console.error('Failed to copy text:', error);
      toast.success(`Failed to copy text`, {
        ...toastStyle.loading,
      });
    }
  };

  function ImageExist(url) {
    if (url?.includes(".svg")) {
      var img = new Image();
      img.src = url;
      return img?.getBBox?.height !== 0;
    } else {
      var img2 = new Image();
      img2.src = url;
      return img2?.height !== 0;
    }
  }
  let imgExist = false;
  return (
    <>
      {(isRescheduled === "true" || isRescheduled === true) && reschedule.status === "sent" && (
        <div
          className={"box-shadow-request-card px-3 py-2 s-desktop-card"}
          style={{
            position: "absolute",
            right: "30px",
            zIndex: "2",
            backgroundColor: "#f5ed4e",
            borderRadius: "10px",
          }}
        >
          <p>Reschedule Request</p>
        </div>
      )}
      <div
        className="relative overflow-hidden bg-white box-shadow-request-card grid grid-cols-4 rounded-lg my-4 p-2 max-w-[900px] s-desktop-card"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="absolute bottom-1 right-2 text-right">
          <p className="text-xs text-gray-400">Sent 15 Min ago</p>
        </div>
        <div className="border-r border-gray-400 my-auto col-span-1">
          <img
            src={image}
            className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
            alt="Profile"
          />
          <button
            onClick={() => {
              isUnavailable
                ? toast.loading(
                  `Sorry! the user has ${isDisable ? "disabled" : ""} ${isDelete ? "deleted" : ""
                  } this account`,
                  {
                    ...toastStyle.loading,
                    position: "top-center",
                    duration: 2000,
                  }
                )
                : window.open(
                  `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                  "_blank"
                );
            }}
            className="view-profile-card-btn w-4/5 mt-2 mx-auto"
          >
            View Profile
          </button>
        </div>
        <div className="px-3 col-span-3" style={{ textAlign: "center" }}>
          <div className="flex py-1 justify-between w-full">
            <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
              {ImageExist(logo[0]) &&
                imgExist === false &&
                (imgExist = true) && (
                  <img src={logo[0]} alt="logo" className="h-full w-full" />
                )}
              {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                <img src={logo[1]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                <img src={logo[2]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                <img src={logo[3]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist &&
                !ImageExist(logo[0]) &&
                !ImageExist(logo[1]) &&
                !ImageExist(logo[2]) &&
                !ImageExist(logo[3]) &&
                (imgExist = true) && (
                  <img src={logo[4]} alt="logo" className="h-full w-full" />
                )}
            </div>
            <div className="text-center">
              <h4 className="text-2xl font-medium leading-4">{name}</h4>
              <p>
                {" "}
                {desg}, {company}
              </p>
            </div>
            <div className="flex gap-2">
              {/* <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div> */}
            </div>
          </div>
          {/* <div
            className="flex justify-between text-center my-1 gap-x-1 mx-2"
            style={{ alignItems: "flex-start" }}
          >
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "45%"
              
            }}>
              <p className="text-base text-gray-400">Profile</p>
              <Chip
                label={subprofile ? `${subprofile} (${profile})`: profile}
                color="info"
                sx={{ fontWeight: "medium",
                height: "auto",
                display: "block",
                maxWidth: "fit-content",
                px: 0.5,
                '& .MuiChip-label' :{
                  whiteSpace: "pre-wrap",
                  display: "flex",
                  flexWrap: "wrap",
                  px: 0.5,
                  fontSize: {
                    sm: "0.8rem"
                  }
                }
                }}
              />
            </div>
            <div style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridGap: "20px",
              width: "50%"
            }}>
            <div>
              <p className="text-base text-gray-400">Price</p>
              <p className="text-lg">INR {price}</p>
            </div>
            <div>
              <p className="text-base text-gray-400">Session Duration</p>
              <p className="text-lg">30 Min</p>
            </div>
            <div style={{minWidth: "177px"}}>
              <p className="text-base text-gray-400">Interview Slot</p>
              <span className="text-lg">
                {t24HDecimalTo12HFormat([true,"true"].includes(isRescheduled)? reschedule.details?.reschFromTime:slotStart)}
              </span>
              <span> &nbsp; - &nbsp;</span>
              <span className="text-lg">{t24HDecimalTo12HFormat([true,"true"].includes(isRescheduled)? reschedule.details?.reschToTime:slotEnd)}</span>
              <p className="text-lg">{convertDateFormat([true,"true"].includes(isRescheduled)? reschedule.details?.reschDate || date:date)}</p>
            </div>
            </div>
          </div> */}
          <StudCardDetails
            subprofile={subprofile}
            profile={profile}
            isRescheduled={isRescheduled}
            reschedule={reschedule}
            price={price}
            slotEnd={slotEnd}
            slotStart={slotStart}
            date={date}
            sessDuration={sessDuration}
            sessType={sessType}
          />
          {mockId && (
            <div className="text-green-700 pb-2 text-lg font-bold">
              {{ interview: "Mock Interview ID", mentorship: "Mentorship Session ID", resume: "Resume Building Session ID" }[sessType]}: {mockId}
            </div>
          )}
          <div className="relative flex justify-center">
            <p className="text-green-700 font-medium text-lg border-2 px-2 py-1 rounded">
              <i className="fa-solid fa-check"></i> Request Sent
            </p>

            {!isRescheduled && isRescheduled !== "true" && (
              <div className="cancel-dropdown-cont">
                <div className="cancel-dropdown flex">
                  <i className="fa-solid fa-caret-right"></i>
                </div>
                <button
                  className="cust-btn cancel-btn"
                  onClick={handleClickOpen}
                >
                  Cancel Request
                </button>
                <div>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Cancel this Request"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure that you want to cancel this request?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          axios
                            .post(`${BASE}/send/removeReq/${reqId}`)
                            .then((res) => {
                              console.log(res);
                              toast.success("Request Deleted Successfully", {
                                ...toastStyle.success,
                                position: "top-center",
                              });
                              setTimeout(() => {
                                window.location.reload(true);
                              }, 1000);
                            });
                        }}
                        autoFocus
                      >
                        YES
                      </Button>
                      <Button onClick={handleClose}>NO</Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*-------------------- MOBILE CARD -----------------------*/}

      <div
        className="relative overflow-hidden s-card-main s-mobile-card s-mobile-card-shadow"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
      >
        {(isRescheduled === "true" || isRescheduled === true) && reschedule.status === "sent" && (
          <div
            className={"s-mobile-card"}
            style={{
              position: "absolute",
              left: "0px",
              zIndex: "2",
              backgroundColor: "#f5ed4e",
              borderBottomRightRadius: "10px",
              padding: '0.2rem 0.75rem 0.1rem 0.75rem',

            }}
          >
            <p style={{
              fontSize: '0.8rem',
              fontFamily: 'sans-serif',
              letterSpacing: '1.5px'
            }}>Reschedule Request</p>
          </div>
        )}
        <div className="absolute bottom-1 right-2 text-right">
          <p className="text-xs text-gray-400" style={{ fontSize: '0.65rem' }}>Sent 15 Min ago</p>
        </div>
        <div className="s-profile">
          <div className=" my-auto col-span-1 s-profile-p-p">
            <img
              src={image}
              className="rounded-full border-2 border-black p-1 block mx-auto"
              alt="Profile"
              width={{ width: "100%" }}
            />
            <button
              style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                isUnavailable
                  ? toast.loading(
                    `Sorry! the user has ${isDisable ? "disabled" : ""} ${isDelete ? "deleted" : ""
                    } this account`,
                    {
                      ...toastStyle.loading,
                      position: "top-center",
                      duration: 2000,
                    }
                  )
                  : window.open(
                    `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                    "_blank"
                  );
              }}
              className="view-profile-card-btn mt-2 mx-auto view-profile-card-btn-mobile"
            >
              View Profile
            </button>
          </div>
          <div
            className="flex py-1 justify-between w-full s-profile-n-d-c"
            style={{ alignItems: "start" }}
          >
            {/* <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
              {ImageExist(logo[0]) &&
                imgExist === false &&
                (imgExist = true) && (
                  <img src={logo[0]} alt="logo" className="h-full w-full" />
                )}
              {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                <img src={logo[1]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                <img src={logo[2]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                <img src={logo[3]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist &&
                !ImageExist(logo[0]) &&
                !ImageExist(logo[1]) &&
                !ImageExist(logo[2]) &&
                !ImageExist(logo[3]) &&
                (imgExist = true) && (
                  <img src={logo[4]} alt="logo" className="h-full w-full" />
                )}
            </div> */}
            {/* <div className="text-center "> */}
            <h4 className="text-2xl font-medium">{name}</h4>
            <p>{desg}</p>
            <p>{company}</p>
            {/* </div> */}
            {/* <div className="flex gap-2">
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div> */}
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ marginBottom: '8px' }}>
            <div className="s-duration-price" style={{ marginBottom: '10px' }}>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Duration : </span>
                <span className="text-lg">{sessDuration}&nbsp;Min</span>
              </div>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Price : </span>
                <span className="text-lg">{price}</span>
              </div>
            </div>

            <div style={{ justifyContent: 'flex-start' }} className="s-duration-price">
              <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold', marginRight: '5px', transform: 'translateY(2px)' }}>Interview Slot : </p>
              <div >
                <span className="text-lg"> {t24HDecimalTo12HFormat([true, "true"].includes(isRescheduled) ? reschedule.details?.reschFromTime : slotStart)}</span>
                <span> - </span>
                <span className="text-lg">{t24HDecimalTo12HFormat([true, "true"].includes(isRescheduled) ? reschedule.details?.reschToTime : slotEnd)}</span>
                <p className="text-lg">{convertDateFormat([true, "true"].includes(isRescheduled) ? reschedule.details?.reschDate || date : date)}</p>
              </div>
            </div>

            <div className="s-profile-profiles">
              <div className=" col-span-2">
                <div className="text-lg flex flex-wrap gap-1">
                  <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold' }}>Profile : </p>
                  <Chip
                    label={subprofile ? `${subprofile} (${profile})` : profile}
                    color="info"
                    sx={{ fontWeight: "medium" }}
                    className="s-mobile-changing-chip-property"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="" style={{ textAlign: "center" }}>
          {mockId && (
            <div className="text-green-700 pb-2 text-lg font-bold"
              style={{
                fontSize: '13px',
                textAlign: 'center'
              }}>
              {{ interview: "Mock Interview ID", mentorship: "Mentorship Session ID", resume: "Resume Building Session ID" }[sessType]}: {mockId} <FaCopy style={{ display: 'inline', fontSize: '1rem' }} onClick={() => handleCopy(mockId)} />
            </div>
          )}
          <div className="relative flex justify-center">
            <p className="text-green-700 font-medium text-lg border-2 px-2 py-1" style={{ width: '100%' }}>
              <i className="fa-solid fa-check"></i> Request Sent
            </p>

            {!isRescheduled && isRescheduled !== "true" && (
              <div className="cancel-dropdown-cont">
                <div className="cancel-dropdown flex">
                  <i className="fa-solid fa-caret-right"></i>
                </div>
                <button
                  className="cust-btn cancel-btn"
                  onClick={handleClickOpen}
                >
                  Cancel Request
                </button>
                <div>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Cancel this Request"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure that you want to cancel this request?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          axios
                            .post(`${BASE}/send/removeReq/${reqId}`)
                            .then((res) => {
                              console.log(res);
                              toast.success("Request Deleted Successfully", {
                                ...toastStyle.success,
                                position: "top-center",
                              });
                              setTimeout(() => {
                                window.location.reload(true);
                              }, 1000);
                            });
                        }}
                        autoFocus
                      >
                        YES
                      </Button>
                      <Button onClick={handleClose}>NO</Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SentRequest;
