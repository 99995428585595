import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { toastStyle } from "../../../utility/helper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UserContext from "../../../contexts/UserContext";
import axios from "axios";
import { BASE } from "../../../constants/endpoints";
import { Box, Button, Chip, IconButton, MenuItem, Pagination, Select, Radio } from "@mui/material";
import { ADMIN_GETBYFILTERS_ENDPOINT } from "../../../constants/endpoints";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Oval } from "react-loader-spinner";
import { Grid, Typography } from '@mui/material';
import { Divider } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { FormControl, FormControlLabel } from "@mui/material";
import { FormGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RadioGroup } from "@mui/material";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
  faInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AddedStudents = () => {
  const { user } = useContext(UserContext);
  console.log(user);
  const navigate = useNavigate();
  const [awaited, setAwaited] = useState([]);
  const [ans, setAns] = useState([]);
  const [name, setName] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);
  const [adminNames, setAdminNames] = useState([]);
  const [adminIds, setAdminIds] = useState([]);
  const [adminName, setAdmin] = useState("");

  const [amount, setAmount] = useState(0);
  const [allAdmins, setAllAdmins] = useState([]);
  const [del, setDel] = useState(false);
  const [show, setShow] = useState(false);
  const [isloaded, setIsloaded] = useState(false);
  const [couponAdmin, setCouponAdmin] = useState("");
  const [canProceed, setCanProceed] = useState(false)
  const [noOfStudents, setNoOfStudents] = useState(0)
  const [noOfInterviews, setNoOfInterviews] = useState(0)
  const [payment, setPayment] = useState(-1)
  const [amount2, setAmount2] = useState(0)
  const [isPaymentDone, setIsPaymentDone] = useState(false)
  const [couponStudents, setCouponStudents] = useState([])
  const [generateCouponLoading, setGenerateCouponLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${BASE}/send/all-admins/${user.iid}`)
      .then((res) => {
        setAllAdmins(res.data);
        console.log(res.data, "--021-0-120-31");
        // res.data?.map((item) => {console.log(item.isGenerated)})
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  useEffect(() => {
    if (search && (search !== null || search !== "")) {
      let r = [];
      for (let i = 0; i < awaited.length; i++) {
        // console.log(awaited[i].sName.toLowerCase(), name.toLowerCase());
        if (
          awaited[i].sName?.toLowerCase().includes(name?.toLowerCase()) &&
          awaited[i].addedBy?.toLowerCase().includes(search?.toLowerCase())
        ) {
          r.push(awaited[i]);
        }
      }
      // setAns(r.slice((page-1)*100,100));
      setAns(r);
    } else if (name && (name !== null || name !== "")) {
      // console.log(name.toLowerCase());
      let r = [];
      for (let i = 0; i < awaited.length; i++) {
        // console.log(awaited[i].sName.toLowerCase(), name.toLowerCase());
        if (awaited[i].sName.toLowerCase().includes(name.toLowerCase())) {
          r.push(awaited[i]);
        }
      }
      // setAns(r.slice((page-1)*100,100));
      setAns(r);
    } else {
      // setAns(awaited.slice((page-1)*100,100));
      setAns(awaited);
    }
  }, [name]);

  useEffect(() => {
    if (name && (name !== null || name !== "")) {
      let r = [];
      for (let i = 0; i < awaited.length; i++) {
        // console.log(ans[i].addedBy);
        if (
          awaited[i].sName.toLowerCase().includes(name.toLowerCase()) &&
          awaited[i].addedBy.toLowerCase().includes(search.toLowerCase())
        ) {
          r.push(awaited[i]);
        }
      }
      // setAns(r.slice((page-1)*100,100));
      setAns(r);
    } else if (search && (search !== null || search !== "")) {
      // console.log(true);
      let r = [];
      for (let i = 0; i < awaited.length; i++) {
        // console.log(ans[i].addedBy);
        if (awaited[i].addedBy.toLowerCase().includes(search.toLowerCase())) {
          r.push(awaited[i]);
        }
      }
      // setAns(r.slice((page-1)*100,100));
      setAns(r);
    } else {
      // setAns(awaited.slice((page-1)*100,100));
      setAns(awaited);
    }
  }, [search]);

  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/admin/added/${user.id}`)
        .then((response) => {
          console.log(response);
          // console.log(response.data);
          setAwaited(response.data);
          setAns(response.data.slice(0, 100));
          if (response.data.length > 100) {
            setRight(true);
          }
          let x = [];
          let y = [];
          console.log(response.data);
          for (let i = 0; i < response.data.length; i++) {
            x.push(response.data[i].addedBy);
            y.push(response.data[i].adminId);
          }
          setAdminNames(x.filter((v, i, a) => a.indexOf(v) === i));
          setAdminIds(y.filter((v, i, a) => a.indexOf(v) === i));
          setIsloaded(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [user]);

  useEffect(() => {
    console.log(noOfStudents);
    console.log(noOfInterviews);
    console.log(payment);
    if (payment === 1 || payment === "1") {
      Number(noOfStudents) &&
        Number(noOfInterviews) &&
        Number(noOfStudents) <= 500 &&
        setAmount2(
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 489 * 100
          ) / 100
        );

      Number(noOfStudents) > 500 &&
        Number(noOfStudents) <= 900 &&
        setAmount2(
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 475 * 100
          ) / 100
        );

      Number(noOfStudents) > 900 &&
        setAmount2(
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 449 * 100
          ) / 100
        );
    }
    if (payment === 3 || payment === "3") {
      Number(noOfStudents) &&
        Number(noOfInterviews) &&
        Number(noOfStudents) <= 500 &&
        setAmount2(
          (Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 489
          ) *
            100) /
          200
        );

      Number(noOfStudents) > 500 &&
        Number(noOfStudents) <= 900 &&
        setAmount2(
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 475
          ) / 200
        );

      Number(noOfStudents) > 900 &&
        setAmount2(
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 449
          ) / 200
        );
    }
    if (payment === 2 || payment === "2") {
      setAmount2(0);
    }

    console.log(amount2)
  }, [noOfStudents, noOfInterviews, payment])

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const savePaymentDetails = async (req, admin) => {
    axios
      .post(`${BASE}/payment`, { req, admin })
      .then((res) => {
        axios.patch(`${BASE}/send/updateAdminPayment/${admin}`);
        // setTimeout(() => {
        //   window.location.reload(true);
        // }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function displayRazorpay(admin) {
    //setting amount
    //setting amount

    let amount = 0;
    console.log(admin);

    const changeAmount = (val) => {
      amount = val;
    };

    if (admin.iPaymentPref === 1 || admin.iPaymentPref === "1") {
      Number(admin.number) &&
        Number(admin.interviews) &&
        Number(admin.number) <= 500 &&
        changeAmount(
          Math.round(
            Number(admin.number) * Number(admin.interviews) * 489 * 100
          ) / 100
        );

      Number(admin.number) > 500 &&
        Number(admin.number) <= 900 &&
        changeAmount(
          Math.round(
            Number(admin.number) * Number(admin.interviews) * 475 * 100
          ) / 100
        );

      Number(admin.number) > 900 &&
        changeAmount(
          Math.round(
            Number(admin.number) * Number(admin.interviews) * 449 * 100
          ) / 100
        );
    }
    if (admin.iPaymentPref === 3 || admin.iPaymentPref === "3") {
      Number(admin.number) &&
        Number(admin.interviews) &&
        Number(admin.number) <= 500 &&
        changeAmount(
          (Math.round(Number(admin.number) * Number(admin.interviews) * 489) *
            100) /
          200
        );

      Number(admin.number) > 500 &&
        Number(admin.number) <= 900 &&
        changeAmount(
          Math.round(Number(admin.number) * Number(admin.interviews) * 475) /
          200
        );

      Number(admin.number) > 900 &&
        changeAmount(
          Math.round(Number(admin.number) * Number(admin.interviews) * 449) /
          200
        );
    }
    if (admin.iPaymentPref === 2 || admin.iPaymentPref === "2") {
      changeAmount(0);
    }

    setAmount(amount);

    //setting amount
    //setting amount

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const data = await fetch(`${BASE}/razorpay`, {
      method: "POST",
      body: JSON.stringify({
        id: admin.id,
        amount,
        admin: admin.id,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((t) => t.json());

    const { id } = data;
    console.log(data);
    const options = {
      key:
        process.env.REACT_APP_RAZORPAY_TEST_MODE === "ON"
          ? process.env.REACT_APP_RAZORPAY_TEST_KEY_ID
          : process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID,
      currency: data.currency,
      amount:
        process.env.REACT_APP_RAZORPAY_1RUPEE_LIVE_MODE === "ON"
          ? amount * 100
          : amount * 100,
      order_id:
        process.env.REACT_APP_RAZORPAY_TEST_MODE === "ON" ? undefined : id,
      // id: id,
      name: `Fyzen Career Solutions Pvt Ltd`,
      description: "Thank you for the confirmation!",
      image:
        "https://media-exp1.licdn.com/dms/image/C560BAQExXV7Oc9sZMA/company-logo_200_200/0/1629144901212?e=2147483647&v=beta&t=x7BZQmslIuY0OgabE7lpKn45A4m53N2jezdjXc6nT_Q",
      handler: async function (response) {
        console.log(
          "Inside handler function. Output from Razorpay. response= :-",
          response
        );
        console.log(response, "---12-3--2-213-12-33-12", user.id);
        await savePaymentDetails(response, admin.id);
        await generateCoupon(admin?.csvLink, admin);
      },
      prefill: {
        name: `${admin.iname}`,
        email: `${admin.iemail}`,
        contact: `${admin.imobNumber}`,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    // paymentObject.on("payment.failed", function (response) {
    //   console.log("Before adding entry :", user.id, id);
    //   axios.post(`${BASE}/failedPayments`, { id: user.id });
    //   // alert(response.error.description);
    //   console.log("Payment failed :", response);
    // });
  }

  async function displayRazorpay2() {
    return new Promise(async function(resolve) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const data = await fetch(`${BASE}/razorpay`, {
      method: "POST",
      body: JSON.stringify({
        id: user.id,
        amount : amount2,
        admin: user.id,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((t) => t.json());

    const { id } = data;
    console.log(data);
    const options = {
      key:
        process.env.REACT_APP_RAZORPAY_TEST_MODE === "ON"
          ? process.env.REACT_APP_RAZORPAY_TEST_KEY_ID
          : process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID,
      currency: data.currency,
      amount:
        process.env.REACT_APP_RAZORPAY_1RUPEE_LIVE_MODE === "ON"
          ? amount2 * 100
          : amount2 * 100,
      order_id:
        process.env.REACT_APP_RAZORPAY_TEST_MODE === "ON" ? undefined : id,
      // id: id,
      name: `Fyzen Career Solutions Pvt Ltd`,
      description: "Thank you for the confirmation!",
      image:
        "https://media-exp1.licdn.com/dms/image/C560BAQExXV7Oc9sZMA/company-logo_200_200/0/1629144901212?e=2147483647&v=beta&t=x7BZQmslIuY0OgabE7lpKn45A4m53N2jezdjXc6nT_Q",
      handler: async function (response) {
        console.log(
          "Inside handler function. Output from Razorpay. response= :-",
          response
        );
        console.log(response, "---12-3--2-213-12-33-12", user.id);
        await generateCoupon2();
        // await savePaymentDetails(response, user.id);
        // setreset(false);
      },
      prefill: {
        name: `${user.iname}`,
        email: `${user.iemail}`,
        contact: `${user.imobNumber}`,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    // paymentObject.on("payment.failed", function (response) {
    //   console.log("Before adding entry :", userData.id, id);
    //   axios.post(`${BASE}/failedPayments`, { id: userData.id });
    //   // alert(response.error.description);
    //   console.log("Payment failed :", response);
    // });
    setIsPaymentDone(true);
  });
  }

  //generating coupons code
  const generateCoupon = async (base64, admin) => {
    let am = 0;
    if (admin.iPaymentPref == 2) {
      if (admin.number <= 500) {
        am =
          Math.round(
            Number(admin.number) * Number(admin.interviews) * 489 * 100
          ) / 100;
      } else if (admin.number > 500 && admin.number <= 900) {
        am =
          Math.round(
            Number(admin.number) * Number(admin.interviews) * 475 * 100
          ) / 100;
      } else if (admin.number > 900) {
        am =
          Math.round(
            Number(admin.number) * Number(admin.interviews) * 449 * 100
          ) / 100;
      }
    }

    const res = await axios.patch(
      `${BASE}/send/admin/generateCouponCodes/${admin.id}/${admin.iPaymentPref == 1 ? 0 : admin.iPaymentPref == 3 ? amount : am
      }`
    );
    toast.success(`Coupon generated Successfully`, {
      ...toastStyle.success,
    });
    handleSend(admin);
  };

  const generateCoupon2 = async () => {
    return new Promise(async function(resolve) {
    setGenerateCouponLoading(true)
    let am = 0;
    if (payment === 2) {
      if (noOfStudents <= 500) {
        am =
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 489 * 100
          ) / 100;
      } else if (noOfStudents > 500 && noOfStudents <= 900) {
        am =
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 475 * 100
          ) / 100;
      } else if (noOfStudents > 900) {
        am =
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 449 * 100
          ) / 100;
      }
    }
    console.log(adminIds[couponAdmin-1]);
    console.log(couponAdmin);
    let couponAdminId = -1;
    if(couponAdmin >= 1) couponAdminId = adminIds[couponAdmin-1];
    const res = await axios.patch(
      `${BASE}/send/admin/generateCouponCodes2/${user.id}/${payment === 1 ? 0 : payment === 3 ? amount2 : am
      }`, {
        couponAdminId,
        couponStudents,
        payment,
        noOfStudents,
        noOfInterviews,
        status : "added"
      }
    );
    console.log(res);
    toast.success(`Coupon generated Successfully`, {
      ...toastStyle.success,
    });
    setIsPaymentDone(false);
    setGenerateCouponLoading(false)
    window.location.reload(true);
  });
    // handleSend(admin);
  }

  //sending coupon codes
  const handleSend = async (admin) => {
    const res = await axios.patch(
      `${BASE}/send/admin/sendCouponCodes/${admin.id}`
    );
    toast.success(`Coupon Sent Successfully`, {
      ...toastStyle.success,
    });

    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  const handleChange = (event, value) => {
    window.scrollTo(0, 0);
    if (page - value == 1) {
      handleLeft()
    } else {
      handleRight()
    }
    setPage(value);
  };
  const handleLeft = () => {
    if (page !== 1) {

      let final = awaited;
      if (search) {
        final.filter((item) => (item.addedBy === search))
      }

      if (name) {
        final.filter((item) => (item.iname === name))
      }

      setAns(final.slice(page * 100 - 200, (page - 1) * 100));
      setPage(page - 1);
      setRight(true);
      window.scrollTo(0, 0);
      if (page - 1 === 1) {
        setLeft(false);
      }
    } else {
      setLeft(false);
    }
  };

  const handleRight = () => {
    if (page !== Math.ceil(awaited.length / 100)) {

      let final = awaited;
      if (search) {
        final.filter((item) => (item.addedBy === search))
      }

      if (name) {
        final.filter((item) => (item.iname === name))
      }

      setAns(final.slice(page * 100, page * 100 + 100));
      setPage(page + 1);
      setLeft(true);
      window.scrollTo(0, 0);
      if (page + 1 === Math.ceil(final.length / 100)) {
        setRight(false);
      }
    } else {
      setRight(false);
    }
  };

  const getInterviews = (item) => {


    for (let i = 0; i < allAdmins.length; i++) {

      if (allAdmins[i].id == item.adminId) {
        return allAdmins[i].interviews;
      }
    }
    return 0;
  };

  const handleShow = async (adminId = "", sEmail = "", sName = "") => {
    setShow(!show);
    setDel({ adminId, sEmail, sName, instituteId: user.iid });
  };

  const handleDelete = async () => {
    setShow(!show);

    if (del.adminId === "") {

      axios
        .post(`${BASE}/send/admin/deleteFiltered`, { ans })
        .then((res) => {
          toast.success("Deleted Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);

        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        });
    } else {
      console.log("deleteOneStudent")
      axios
        .post(`${BASE}/send/admin/deleteOneStudent`, { del })
        .then((res) => {
          console.log(res)
          toast.success("Deleted Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        });
    }
  };

  const handleCancel = (event) => {
    setCanProceed(false);
    setCouponAdmin("");
    setNoOfInterviews(0);
    setPayment(-1);
  }

  const handleCouponAdmin = (event) => {
    setCouponAdmin(event.target.value);
    console.log(event.target.value);
    let couponAdminStudents = awaited;
    let couponEmails = [];
    if(event.target.value && event.target.value !== "") couponAdminStudents = couponAdminStudents.filter((student) => student.addedBy === adminNames[event.target.value-1]);
    couponAdminStudents = couponAdminStudents.filter((student) => student.sInterviews > 0);
    setNoOfStudents(couponAdminStudents.length);
    couponAdminStudents.forEach((student) => couponEmails.push(student.sEmail));
    setCouponStudents(couponEmails);
    console.log(couponEmails)
  }

  const handleChangeRadio2 = (event) => {
    setPayment(event.target.value);
  }

  return (
    <>
    {isloaded ?
    (<div>
      <div style={{alignItems:'start'}} className={`gap-2 flex-wrap justify-start items-start max-[600px]:flex grid ${window.innerWidth < 600 ? 'grid-cols-1':'grid-cols-3'}`}>
        {allAdmins?.map((admin, index) =>
          ((admin?.interviews && admin?.number) && (!admin.isGenerated || !admin?.isPayment)) ? (
            <div style={{width:'fit-content',margin:'auto'}}>
              {admin?.number>0 && !admin.isGenerated && (
                <p>
                  • {admin?.aTitle} {admin?.aFname} {admin?.aLname}
                </p>
              )}
              {admin?.number>0 && !admin?.isPayment ? (
                <>
                <div style={{display : "flex"}}>
                <Button
                  variant="contained"
                  onClick={() => {
                    displayRazorpay(admin);
                  }}
                  style={{
                    fontSize: `${window.innerWidth < 600 ? '13px' : '15px'}`,
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginBottom: "10px",

                  }}
                  key={index}
                >
                  Pay and Generate Coupon Codes for Students added by {(admin?.aFname !== user?.aFname || admin?.aLname !== user?.aLname) ? admin?.aTitle + " " + admin?.aFname + " " + admin?.aLname : "me"}
                </Button>
                <sup style={{ color: "red" }}># </sup>
                </div>
                <p><sup style={{ color: "red" }}># </sup>The last added set of students for which you are yet to pay</p>
                </>
              ) :
               {/* admin?.number>0 && admin.isPayment && !admin.isGenerated ? (
                <Button
                  onClick={() => generateCoupon(admin?.csvLink, admin)}
                  disabled={admin?.isGenerated}
                  className="mb-2"
                  variant="contained"
                  color="secondary"
                  style={{
                    fontSize: `${window.innerWidth < 600 ? '13px' : '15px'}`,
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginBottom: "10px",
                  }}
                  key={index}
                >
                  Generate Coupon Codes for Students added by {admin?.aTitle} {admin?.aFname}{" "}
                  {admin?.aLname}
                </Button>
              ) : */}
               (
                <></>
              )}
            </div>
          ) : null
        )}
        {/* {allAdmins?.map((admin, index) => {
          console.log('yoyo', index, admin?.interviews && admin?.number, !admin.isGenerated, !admin?.isPayment)
          return (
            ((admin?.interviews && admin?.number) && (!admin.isGenerated || !admin?.isPayment)) ? (
              <div style={{width:'fit-content',margin:'auto'}}>
                {!admin.isGenerated && (
                  <p>
                    • {admin?.aTitle} {admin?.aFname} {admin?.aLname}
                  </p>
                )}
                {!admin?.isPayment ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      displayRazorpay(admin);
                    }}
                    style={{
                      fontSize: `${window.innerWidth < 600 ? '13px' : '15px'}`,
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      marginBottom: "10px",
                      

                    }}
                    key={index}
                  >
                    Pay for Students added by {admin?.aTitle} {admin?.aFname}{" "}
                    {admin?.aLname}
                  </Button>
                ) : admin.isPayment && !admin.isGenerated ? (
                  <Button
                    onClick={() => generateCoupon(admin?.csvLink, admin)}
                    disabled={admin?.isGenerated}
                    className="mb-2"
                    variant="contained"
                    color="secondary"
                    style={{
                      fontSize: `${window.innerWidth < 600 ? '13px' : '15px'}`,
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      marginBottom: "10px",
                      
                    }}
                    key={index}
                  >
                    Generate Coupon Codes for Students added by {admin?.aTitle} {admin?.aFname}{" "}
                    {admin?.aLname}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            ) : null
          )
        })} */}


      </div>

      {/* admin?.interviews &&
                admin?.number &&
                admin?.isPayment &&
                !admin?.isGenerated && (
                  <Button
                    onClick={() => generateCoupon(admin?.csvLink)}
                    disabled={admin?.isGenerated}
                    className="mb-2"
                    variant="contained"
                    key={index}
                  >
                    Generate Coupon Codes
                  </Button>
                ) */}

      {/* {user.interviews && user.number && !user.isPayment && (
        <Button
          variant="contained"
          onClick={()=>{displayRazorpay(admin)}}
          style={{
            fontSize: "15px",
            paddingLeft: "10px",
            paddingRight: "10px",
            marginBottom: "10px",
          }}
        >
          Pay & Confirm
        </Button>
      )}
      {user.interviews &&
        user.number &&
        user.isPayment &&
        !user.isGenerated && (
          <Button
            onClick={() => generateCoupon(user.csvLink,admin)}
            disabled={user.isGenerated}
            className="mb-2"
            variant="contained"
          >
            Generate Coupon Codes
          </Button>
        )} */}

      {show && (
        <div className="popup w-[90%] max-w-[400px] flex flex-col">
          <p className="text-[20px] text-center">Are you sure you want to remove {del.sName ? del.sName : "students added by " + search}?</p>
          <div
            className="mt-[50px] flex justify-evenly"
            style={{ width: "100%" }}
          >
            <Button variant="contained" color="success" onClick={handleDelete}>
              YES
            </Button>
            <Button variant="contained" color="error" onClick={() => { handleShow() }}>
              NO
            </Button>
          </div>
        </div>
      )}
      
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid container alignItems="center" sx={{ backgroundColor: 'rgb(255, 222, 222)', padding: '5px', margin : "20px 0", maxWidth: "fit-content" }} className="rounded-lg">
        <Grid item>
          <Typography sx={{padding : "0 10px"}}>Generate more Coupon Codes for the students added by </Typography>
        </Grid>
        <Grid item sx={{paddingRight : "10px", paddingLeft : "10px"}}>
          <Select style={{height : "40px", minWidth : "100px"}} value={couponAdmin} onChange={handleCouponAdmin}>
            <MenuItem value={0}>All Admins</MenuItem>
              {adminNames.map((item, index) => {
                console.log('adminNames ', item);
                return (
                  <MenuItem key={index} value={index + 1}>
                    {item}
                  </MenuItem>
                );
              })}
          </Select>
        </Grid>
        {((couponAdmin !== undefined && couponAdmin !== "" && noOfStudents > 0) &&
          <Grid item>
            <Button variant="contained" onClick={() => {setCanProceed(true)}}>Proceed</Button>
          </Grid>
        )}
        {((couponAdmin !== undefined && couponAdmin !== "" && noOfStudents > 0) &&
          <Grid item>
            <Button variant="link" onClick={() => {handleCancel()}}>Cancel</Button>
          </Grid>
        )}
      </Grid>
      </div>

      {canProceed && (
          <Typography
            variant="h5"
            component="h5"
            align="center"
            style={{
              margin: "20px",
              fontFamily: "'Ubuntu', sans-serif",
              fontSize:'30px'
            }}
          >
            {`Generate more Coupon Codes for ${!couponAdmin ? "All students" : adminNames[couponAdmin-1] === user.aFname + " " + user.aLname ? "Students added by me" : "Students added by " + adminNames[couponAdmin-1]}`}
          </Typography>
      )}

      {canProceed &&
        (<form>
        <div className="profile-form-cont2">
          <div className="profile-form-text">
            <div className="institution cust-row flex max-[600px]:flex-col max-[600px]:mb-[30px]">
              <div style={{ width: '100%' }} className="cust-row flex max-[600px]:m-[0px]">
                <div className="element w-50">
                  <label htmlFor="number">
                    Total No. of Students interested for Mock Interviews
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    name="number"
                    className="border-2 w-[100%] rounded h-[56px] max-[600px]:h-[40px]"
                    placeholder="Number of Students"
                    displayEmpty
                    // error={fieldError.institute}
                    value={noOfStudents}
                    onChange={(e) => {
                      setNoOfStudents(e.target.value);
                    }}
                    autoComplete="nope"
                    disabled
                  />
                </div>
              </div>
              <div className="element">
                <label htmlFor="interviews">
                  No. of Mock Interviews to be booked per student
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  value={noOfInterviews || ""}
                  name="interviews"
                  id="interviews"
                  onChange={(e) => {
                    setNoOfInterviews(e.target.value);
                  }}
                  className="h-[56px] max-[600px]:h-[40px]"
                  displayEmpty
                  MenuProps={MenuProps}
                  sx={{ width: 1 }}
                  inputProps={{ "aria-label": "Without label" }}
                  defaultValue=""
                  // disabled={
                  //   userData.isPayment === true ||
                  //   userData.number === 0 ||
                  //   ((payment === 1 ||
                  //     payment === "1" ||
                  //     payment === 3 ||
                  //     payment === "3") &&
                  //     userData.isPayment === false &&
                  //     userData.interviews === user.interviews &&
                  //     Number(payment) === Number(userData.iPaymentPref) &&
                  //     userData.number === user.number &&
                  //     amount !== 0)
                  // }
                >
                  <MenuItem hidden disabled value="">
                    Select No. of Interviews
                  </MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </div>
            </div>
            <p style={{ maxWidth: '300px', width: '90%' }} className="bg-red-500 p-[10px] text-white rounded max-[600px]:m-[auto]">
              Total Number of Interviews = {noOfStudents * noOfInterviews}
            </p>
            {noOfStudents > 0 && (
              <div>
                <Divider sx={{ marginTop: "20px" }}></Divider>
                <FormControl
                  sx={{ mt: 1 }}
                  component="fieldset"
                  variant="standard"
                >
                  <label
                    htmlFor="ibranches"
                    style={{
                      color: "#095a54",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Your Plan
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <FormGroup>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        // onChange={handleChangeRadio}
                        label="Silver Plan: [No. of Students <= 500] : 10% Discount in Service Charges"
                        checked={noOfStudents <= 500}
                        disabled={
                          noOfStudents > 500 
                          // || user.isPayment ||
                          // (userData.isPayment === false &&
                          //   userData.interviews === user.interviews &&
                          //   Number(payment) ===
                          //   Number(userData.iPaymentPref) &&
                          //   userData.number === user.number &&
                          //   amount !== 0)
                        }
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        // onChange={handleChangeRadio}
                        label="Gold Plan: [500 < No. of Students <= 900] : 25% Discount in Service Charges"
                        checked={
                          noOfStudents <= 900 && noOfStudents > 500
                        }
                        disabled={
                          noOfStudents > 900 || noOfStudents <= 500
                          // || userData.number <= 500 ||
                          // user.isPayment ||
                          // (userData.isPayment === false &&
                          //   userData.interviews === user.interviews &&
                          //   Number(payment) ===
                          //   Number(userData.iPaymentPref) &&
                          //   userData.number === user.number &&
                          //   amount !== 0)
                        }
                      />
                      <FormControlLabel
                        value={3}
                        control={<Radio />}
                        // onChange={handleChangeRadio}
                        label="Diamond Plan: [No. of Students > 900] : 50% Discount in Service Charges"
                        checked={noOfStudents > 900}
                        disabled={
                          noOfStudents <= 900 
                          // || user.isPayment ||
                          // (userData.isPayment === false &&
                          //   userData.interviews === user.interviews &&
                          //   Number(payment) ===
                          //   Number(userData.iPaymentPref) &&
                          //   userData.number === user.number &&
                          //   amount !== 0)
                        }
                      />
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
                <>
                  <p style={{ maxWidth: '300px', width: '90%' }} className="mt-5 p-[10px] text-white rounded bg-blue-500 min-w-[300px] max-[600px]:m-[auto]">
                    Total Amount ={" INR "}
                    {payment === 1
                      ? amount2
                      : payment === 3
                        ? amount2 * 2
                        : noOfStudents <= 500
                          ? Math.round(
                            Number(noOfStudents) *
                            Number(noOfInterviews) *
                            489 *
                            100
                          ) / 100
                          : noOfStudents <= 900 && noOfStudents > 500
                            ? Math.round(
                              Number(noOfStudents) *
                              Number(noOfInterviews) *
                              475 *
                              100
                            ) / 100
                            : Math.round(
                              Number(noOfStudents) *
                              Number(noOfInterviews) *
                              449 *
                              100
                            ) / 100}
                  </p>
                  <Divider sx={{ marginTop: "20px" }}></Divider>
                </>
              </div>
            )}
            <div>
              {noOfStudents > 0 && (
                <FormControl
                  sx={{ mt: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <label
                    htmlFor="ibranches"
                    style={{
                      color: "#095a54",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Payment Preference
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  {/* <FormLabel
                  component="legend"
                  sx={{
                    color: "#095a54",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Payment Preference <span style={{ color: "red" }}>*</span>
                </FormLabel> */}
                  <FormGroup>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        onChange={handleChangeRadio2}
                        label="Institute will pay the complete amount for mock interviews for all the students"
                        checked={payment === 1 || payment === "1"}
                        // disabled={
                          // user.isPayment ||
                          // (userData.isPayment === false &&
                          //   userData.interviews === user.interviews &&
                          //   Number(payment) ===
                          //   Number(userData.iPaymentPref) &&
                          //   userData.number === user.number &&
                          //   amount !== 0)
                        // }
                      />
                      <FormControlLabel
                        value={3}
                        control={<Radio />}
                        onChange={handleChangeRadio2}
                        label="Institute and Students will pay 50% each of the complete amount for each mock interview"
                        checked={payment === 3 || payment === "3"}
                        // disabled={
                        //   user.isPayment ||
                        //   (userData.isPayment === false &&
                        //     userData.interviews === user.interviews &&
                        //     Number(payment) ===
                        //     Number(userData.iPaymentPref) &&
                        //     userData.number === user.number &&
                        //     amount !== 0)
                        // }
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        onChange={handleChangeRadio2}
                        label="Students will pay the complete amount (on their own) for their mock interviews individually"
                        checked={payment === 2 || payment === "2"}
                        // disabled={
                        //   user.isPayment ||
                        //   (userData.isPayment === false &&
                        //     userData.interviews === user.interviews &&
                        //     Number(payment) ===
                        //     Number(userData.iPaymentPref) &&
                        //     userData.number === user.number &&
                        //     amount !== 0)
                        // }
                      />
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
              )}
              <Divider sx={{ marginTop: "20px" }}></Divider>
            </div>
            {(payment === 1 ||
              payment === "1" ||
              payment === 3 ||
              payment === "3" ||
              payment === 2 ||
              payment === "2") &&
              noOfInterviews > 0 &&
              noOfStudents > 0 && (
                <>
                  <p style={{ maxWidth: '300px', width: '90%' }} className="my-[20px] p-[10px] text-white rounded w-[90%] max-w-[250px]  bg-green-500 max-[600px]:mx-[auto]">
                    Amount Payable 
                    = {" INR "}{amount2}
                  </p>
                  <Divider sx={{ marginTop: "20px" }}></Divider>
                </>
              )}
          </div>
        </div>
        
        {(payment === 1 ||
          payment === "1" ||
          payment === 3 ||
          payment === "3") &&
          noOfInterviews > 0 &&
          noOfStudents > 0 && (
            <div className="relative flex justify-center mr-4 mt-2 mb-2">
              <form>
                <Button
                  variant="contained"
                  onClick={() => {
                    displayRazorpay2();
                    // await generateCoupon2();
                  }}
                  style={{
                    fontSize: "15px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Pay & Generate Coupon Codes
                </Button>
              </form>
              {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value=""
                IconComponent={() => (
                  <FontAwesomeIcon
                    className="text-center text-white border-none absolute -z-1"
                    icon={faChevronDown}
                    style={{ pointerEvents: "none" }}
                  />
                )}
                className="h-[39px] w-[40px] flex items-center justify-center bg-[#1876D1] text-white border-none rounded-[5px]"
              >
                <MenuItem
                  onClick={() => {
                    navigate("/admin/current-students");
                  }}
                  className=""
                  value="pay-later"
                >
                  PAY LATER
                </MenuItem>
              </Select> */}
              {/* <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="tooltip ">
                    If you choose [Pay Later], you or any other admin <br />{" "}
                    can make the payment from the page of [Added Students]{" "}
                    <br /> on [Current Students] page
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  className="mr-[10px] rounded-full bg-white text-black p-2 w-[15px]"
                  icon={faInfo}
                  style={{
                    border: "1px solid black",
                    transform: "scale(0.75)",
                  }}
                />
              </OverlayTrigger> */}
              {/* <div className="tt ml-[10px]">
                <InfoOutlined
                  sx={{
                    color: "rgb(83, 81, 81)",
                    fontSize: "20px",
                  }}
                />
                <span
                  style={{
                    background: "black",
                    color: "white",
                    padding: "3px 5px",
                    top: "-60px",
                    textAlign: "left",
                  }}
                  className="tttext"
                >
                  If you choose [Pay Later], you or any other admin <br />{" "}
                  can make the payment from the page of [Added Students]{" "}
                  <br /> on [Current Students] page
                </span>
              </div> */}
            </div>
          )}
      </form>)
      }

      {(payment === 2 || payment === "2") && noOfInterviews > 0 && noOfStudents > 0 && (
        <div className="profile-form-cont2">
          <div className="profile-form-text">
              <div style={{ margin: '10px auto' }} className="relative flex my-2 max-[600px]:flex-col">
                {
                  !generateCouponLoading && noOfStudents > 0 &&
                  <Button
                    color="secondary"
                    onClick={() => {generateCoupon2()}}
                    // disabled={generated || user.isGenerated}
                    variant="contained"
                    className="max-[600px]:my-[1rem]"
                    style={{ margin: '1rem auto' }}
                  >
                    Generate Coupon Codes
                  </Button>}
                {generateCouponLoading &&
                  <>
                    <div style={{ height: '7em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div class="preloader">
                        <div class="preloader__square"></div>
                        <div class="preloader__square"></div>
                        <div class="preloader__square"></div>
                        <div class="preloader__square"></div>
                      </div>
                      <div class="status s-mobile-one-word-per-line">Generating Coupon Codes<span class="status__dot">.</span><span class="status__dot">.</span><span class="status__dot">.</span></div>
                    </div>
                  </>
                }
                {/* {(generated || user.isGenerated) && (
                  <div className="max-[600px]:text-[14px]">
                    <p style={{ paddingLeft: 10 }}>
                      <i
                        class="fa fa-check-circle"
                        style={{ color: "#2aad05" }}
                      ></i>{" "}
                      Coupon Codes Generated Successfully
                    </p>
                    <p style={{ paddingLeft: 10 }}>
                      <i
                        class="fa fa-check-circle"
                        style={{ color: "#2aad05" }}
                      ></i>{" "}
                      The coupon code(s) have been sent to the students
                      individually via Gmail and WhatsApp
                    </p>
                  </div>
                )} */}
              </div>
          </div>
        </div>
      )}

      { user.aFname + " " + user.aLname === search && <div className="flex justify-end mb-3" style={{ width: "100%" }}>
        {search && (
          <Button color="error" onClick={() => { handleShow() }} variant="contained">
            Remove Students Added by {adminNames[adminName - 1]}
          </Button>
        )}
        {/* <Button color="error" onClick={handleShow} variant="contained">
          Delete
        </Button> */}
      </div>}

      {/* <div
        style={{ marginBottom: '10px', borderRadius: '10px', display: 'flex', justifyContent: 'space-between', padding: '5px 30px' }}
        className=" flex-1 text-lg px-2 py-1 border-2 rounded bg-white grid grid-cols-5 s-mobile-admin-searchbar"
        id="searchbar-input"
      >
        <input
          type="text"
          className="mx-1 py-2 outline-none w-auto col-span-4 text-base"
          value={name || ""}
          onChange={(e) => {
            setName(e.target.value);
          }}
          name="search"
          id="search"
          placeholder="Search Student by Name"
        />
        <div
          className="text-2xl w-12 cursor-pointer hover:text-sky-200 col-span-1 flex flex-row justify-between gap-1"
          id="search-bar"
        >
          {name ? (
            <IconButton sx={{ p: 0, minWidth: 0 }} onClick={() => setName("")}>
              <DeleteIcon />
            </IconButton>
          ) : (
            <div className="h-6 w-6"></div>
          )}
          <IconButton sx={{ p: 0, minWidth: 0 }}>
            <SearchIcon />
          </IconButton>
        </div>
      </div> */}

      <div
        className="w-auto text-lg px-2 py-1 bg-white rounded-md grid grid-cols-5 s-mobile-admin-searchbar mb-3"
        id="searchbar-input"
        style={{marginTop : "2rem"}}
      >
        <input
          type="text"
          className="mx-1 py-2 outline-none w-auto col-span-4 text-base"
          value={name || ""}
          onChange={(e) => {
            setName(e.target.value);
          }}
          name="search"
          id="search"
          placeholder="Search Student by Name"
        />
        <div
          className="text-2xl w-12 cursor-pointer hover:text-sky-200 col-span-1 flex flex-row justify-between gap-1"
          id="search-bar"
        >
          {name ? (
            <IconButton
              sx={{ p: "3px", minWidth: 0, border: "1px solid gray" }}
              onClick={() => setName("")}
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <div className="h-6 w-6"></div>
          )}
          <IconButton
            sx={{
              p: "3px",
              minWidth: 0,
              border: "1px solid gray",
              marginLeft: "5px",
            }}
          >
            <SearchIcon />
          </IconButton>
        </div>
      </div>
      {/* <div
        className="w-auto text-lg px-2 py-1 border-2 rounded bg-white grid grid-cols-5"
        id="searchbar-input"
      >
        <input
          type="text"
          className="mx-1 py-2 outline-none w-auto col-span-4 text-base"
          value={filters.search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          name="search"
          id="search"
          placeholder="Search Interviewer by Name"
        />
        <div
          className="text-2xl w-12 cursor-pointer hover:text-sky-200 col-span-1 flex flex-row justify-between gap-1"
          id="search-bar"
        >
          {filters.search ? (
            <IconButton
              sx={{ p: 0, minWidth: 0 }}
              onClick={() => setSearch("")}
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <div className="h-6 w-6"></div>
          )}
          <IconButton sx={{ p: 0, minWidth: 0 }}>
            <SearchIcon />
          </IconButton>
        </div>
      </div> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} className="table-admin" aria-label="simple table">
          <TableHead style={{ background: '#dbf2fd' }} sx={{ textAlign: "center" }}>
            <TableRow style={{ background: '#dbf2fd' }}>
              <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}>Sr. No.</TableCell>
              <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}>Student's Name</TableCell>
              <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                Email
              </TableCell>
              <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                Whatsapp No.
              </TableCell>
              <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                No. of Mock Interviews
              </TableCell>
              <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                Coupon Codes
                <p><OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="tooltip">
                      The coupon codes which <br /> are struck-through have <br /> been already used by <br /> the respective student(s) <br /> to book mock interview(s)
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    className="mr-[10px] rounded-full bg-black text-white p-1 w-[20px] scale-75"
                    icon={faInfo}
                  />
                </OverlayTrigger>
                </p>
              </TableCell>
              <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                Added By
                <Select
                  style={{ minWidth: '80px', maxWidth: '250px' }}
                  labelId="demo-simple-select-label"
                  className="ml-2"
                  id="demo-simple-select"
                  value={adminName}
                  onChange={(e) => {
                    setSearch(adminNames[e.target.value - 1]);
                    setAdmin(e.target.value);
                  }}
                >
                  <MenuItem value={0}>All</MenuItem>
                  {adminNames.map((item, index) => {
                    return (
                      <MenuItem key={index} value={index + 1}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
                <p><OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="tooltip">
                      By choosing your name <br />from this drop-down menu, <br />you can remove one or more <br />students added by you
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    className="mr-[10px] rounded-full bg-black text-white p-1 w-[20px] scale-75"
                    icon={faInfo}
                  />
                </OverlayTrigger>
                </p>
              </TableCell>
              <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-admin" sx={{ textAlign: "center" }}>
            {ans &&
              ans !== [] &&
              ans.map((item, index) =>
                item?.sCoupon?.length == 0 && getInterviews(item) == 0 ? (
                  <></>
                ) : (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      backgroundColor: `${index % 2 === 1 ? "#f2f2f2" : "#ffffff"}`,
                    }}
                  >
                    <TableCell
                      sx={{ textAlign: "center" }}
                      component="th"
                      scope="row"
                    >
                      {(page - 1) * 100 + index + 1}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} align="right">
                      {item.sName}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", wordBreak: 'break-all', minWidth:'250px' }} align="right">
                      {item.sEmail}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} align="right">
                      {item.sWhatsapp}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} align="right">
                      {item.sInterviews === 0 ? "-" : item.sInterviews}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} align="right">
                      {item?.sCoupon?.length !== 0
                        ? item?.sCoupon?.map((item2, index) => (
                          <span key={index} style={{ textDecoration: `${item.used[index] ? "line-through" : "none"}` }}>
                            {item2.code}
                            {index !== item.sCoupon.length - 1 ? "," : ""}{" "}
                            &nbsp;
                          </span>
                        ))
                        : "-"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} align="right">
                      {item.addedBy}
                    </TableCell>
                    {user.id === item.adminId && (
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        <FontAwesomeIcon
                          color="red"
                          className="cursor-pointer"
                          icon={faTrash}
                          onClick={() => {
                            console.log("item", item)
                            handleShow(item.adminId, item.sEmail, item.sName);
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-center mt-3">
        <div className="flex bg-white p-[10px] rounded-xl">
          {/* {left && (
            <FontAwesomeIcon
              onClick={handleLeft}
              className="cursor-pointer"
              icon={faChevronLeft}
            />
          )}
          <p className="mx-[10px]">{page}</p>
          {right && (
            <FontAwesomeIcon
              onClick={handleRight}
              className="cursor-pointer"
              icon={faChevronRight}
            />
          )} */}
          <Pagination
            count={Math.ceil(awaited?.length / 100)}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </div>
      </div>
    </div>)
    : (
      (
        <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )
    )}
    </>
  );
};

export default AddedStudents;