import {
    Container,
    Typography,
} from '@mui/material'

const PaymentPolicy = () => {
    return (
        <Container sx={{
            pt: 3,
            px: 2
        }}>
            <Typography variant="h4">Payment Policy</Typography>
            <Typography sx={{
                maxWidth:"85vw"
            }}>
                <br/>
                <Typography variant="h6" fontWeight={"bold"}>Payment from Students</Typography>
                The price per mock interview (with live feedback) displayed to a student contains a major component as interviewer’s fee and a minor component as service fee of interwiu.com (Fyzen Career Solutions Pvt Ltd). A student needs to pay the displayed price to interwiu.com (Fyzen Career Solutions) as soon as his/her mock interview request is accepted by an interviewer to confirm the respective mock interview (with live feedback). However, the students can use the Coupon Codes (provided by their Institute; or obtained by referring a friend, or getting referred by a friend, or taking Duo/Trio packs) to avail partial or full discounts.     
                <br/><br/><Typography variant="h6" fontWeight={"bold"}>Payment to Interviewers</Typography>
                The interviewer’s fee is credited to an interviewer by interwiu.com (Fyzen Career Solutions) within a window of 7 hours for a given mock interview after it is conducted successfully as per the schedule subject to the below conditions. 
                <br/> ● The mock interview duration is no less than 40 minutes 
                <br/> ● The interviewer has successfully submitted the Feedback Form for the respective mock interview by accessing it either from the top navigation bar of that mock interview’s (video call’s) window, or from the card of that mock interview lying on the [Completed Interviews] page (the card can be found only after completion of the respective mock interview slot) 
                <br/> ● No issue(s) has(have) been reported by the student/interviewer about the respective mock interview  
            </Typography>
        </Container>
    )
}

export default PaymentPolicy