import React from "react";
import Navbar from "./Navbar";
import Typography from "@mui/material/Typography";

const Myprofile = () => {
  return (
    <>
      <div className="flex fixed mt-40">
        <Navbar />
        <div>
          <Typography
            variant="h5"
            component="h5"
            style={{
              margin: "10px",
              fontFamily: "'Ubuntu', sans-serif",
            }}
          >
            Dashboard
          </Typography>
          <div>
            Hello John! You have 16 new applications. It’s a lot of work today!
            So let’s start.
          </div>
        </div>
      </div>
    </>
  );
};

export default Myprofile;
