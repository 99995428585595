import Modal from "@mui/material/Modal"
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Button } from "antd";

const SlotAlreadyBooked = ({isSlotAlreadyBooked,slotBookedModalHandler}) => {
 //state variables for the Modal State
 const handleClose = () => {
    slotBookedModalHandler(false);
 } 
 return (
    <Modal
      open={isSlotAlreadyBooked}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 24,
                  overflow: "hidden",
                  minWidth: "750px",
                }}>
        <Typography id="modal-modal-title" variant="h4" component="h4">
          Slot Booking Error
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Slot you are trying to book is alredy booked by someone or Interviewer has changed his schedule.
          Refresh your page to reload the changes.
        </Typography>
        <div className="w-full flex flex-row justify-center my-2">
        <Button color="success" variant="contained" onClick={handleClose}> Close </Button>
        </div>
      </Box>
    </Modal>
      
 );
};
  
export default SlotAlreadyBooked;