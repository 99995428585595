import React from "react";
import { useState, useEffect } from "react";
import { Calendar } from "react-multi-date-picker";
import moment from "moment";
import TimePreference from "./TimePreference";

const TimePicker = () => {
  const [value, setValue] = useState(new Date());

  const [red, setRed] = useState([1, 2, 4, 6, 8, 12, 16, 17, 23, 24]);
  const [yellow, setYellow] = useState([3, 10, 15, 18, 22, 25]);
  const [green, setGreen] = useState([7, 9, 13, 14, 20, 21, 22, 27]);

  useEffect(() => {
    console.log(moment(value).format("DD/MM/YYYY"));
  }, [value]);
  useEffect(() => {
    setTimeout(() => {
      setRed([1, 2, 4, 6, 8, 12, 16, 17, 18, 23, 24]);
    }, 2000);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setRed([1, 2, 4, 6, 8, 12, 16, 17, 23, 24]);
    }, 4000);
  }, []);
  moment("1:30", "H:mm").format("hh:mm A");

  return (
    <div>
      <TimePreference />
      <Calendar
        minDate={new Date().setDate(new Date().getDate())}
        value={value}
        onChange={setValue}
        mapDays={({ date, selectedDate, isSameDate }) => {
          let props = {};

          if (isSameDate(date, selectedDate)) {
            props.style = {
              color: "#000",
              backgroundColor: "#00b0f0",
              border: "1px solid #000",
            };
            return props;
          }

          // if ([4, 5, 6, 7].includes(date.day)) {
          //   return { className: "text-red-500" };
          // }
          // if ([10, 11, 12, 13].includes(date.day)) {
          //   return { className: "text-yellow-500" };
          // }
          // if ([15, 16, 17].includes(date.day)) {
          //   return { className: "text-green-500" };
          // }
          if (red.includes(date.day)) {
            return { className: "bg-red-500" };
          }
          if (yellow.includes(date.day)) {
            return { className: "bg-yellow-500" };
          }
          if (green.includes(date.day)) {
            return { className: "bg-green-500" };
          }
        }}
      />
    </div>
  );
};

export default TimePicker;
