import React, { useState, CSSProperties, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { testimonials2022 } from "./Partner/testimonialsData";
import { testimonials2023 } from "./Partner/testimonialsData2023";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Button } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { SUCCESS_STORIES_ROUTE } from "../../constants/routes";
import "./SuccessStories.css";
import Svg1 from "../../images/SVG/inverted commas.svg";
import Svg2 from "../../images/SVG/inverted commas2.svg";

import {
  useTransition,
  animated,
  AnimatedProps,
  useSpringRef,
} from "@react-spring/web";

const pages = [
  ({ style }) => (
    <animated.div style={{ ...style, background: "lightpink" }}>A</animated.div>
  ),
  ({ style }) => (
    <animated.div style={{ ...style, background: "lightblue" }}>B</animated.div>
  ),
  ({ style }) => (
    <animated.div style={{ ...style, background: "lightgreen" }}>
      C
    </animated.div>
  ),
];

// testimonials = testimonials2023 + testimonials;

var testimonials = [...testimonials2023, ...testimonials2022].map((item) => ({
  ...item,
}));
console.log("testimonials: ", testimonials);

const p = testimonials.map((testimonial, index) => {
  return ({ style }) => {
    <animated.div
      key={`${index}index`}
      style={{ ...style, background: "lightpink" }}
    >
      {testimonial.name}
    </animated.div>;
  };
});
console.log(p, pages);

const SuccessStories = () => {
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  const [width, setWidth] = useState(windowWidth.current);
  const [words, setWords] = useState(400);
  const [changeTransition, setChangeTransition] = useState({
    from: "100%",
    Leave: "-50%",
  });
  // window.addEventListener("resize", (e) => {
  //   // setWidth(window.innerWidth);
  //   // if(width < 600){
  //   //   setWords(200)
  //   // }else{
  //   //   setWords(400)
  //   // }
  //   // console.log(words)
  // })
  // console.log(width);

  useEffect(() => {
    setWidth(window.innerWidth);
    if (width < 600) {
      setWords(200);
    } else {
      setWords(400);
    }
    console.log(words);
  }, []);

  // console.log('width: ', windowWidth.current);
  // console.log('height: ', windowWidth.current);

  const [index, set] = useState(0);
  const onClick = () => {
    setChangeTransition({
      from: "100%",
      Leave: "-50%",
    });
    set((state) => (state + 1) % testimonials.length);
  };
  const transRef = useSpringRef();
  const transitions = useTransition(index, {
    ref: transRef,
    keys: null,
    from: {
      opacity: 0,
      transform: `translate3d(${changeTransition.from},0,0)`,
    },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: {
      opacity: 0,
      transform: `translate3d(${changeTransition.Leave},0,0)`,
    },
  });
  useEffect(() => {
    transRef.start();
  }, [index]);

  const onLeftClick = () => {
    // console.log("left");
    setChangeTransition({
      from: "-100%",
      Leave: "50%",
    });
    // console.log(index)
    set((state) => (state - 1 + testimonials.length) % testimonials.length);
    // console.log(index)
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // onClick();
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div
        style={{ paddingTop: "35px", paddingBottom: "25px" }}
        className="page-heading-h1"
      >
        Success Stories
      </div>
      <div style={{ paddingBottom: "30px" }} className="w-screen background">
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            color: "black",
            textAlign: "justify",
            pt: 1,
            pb: 3,
            fontSize: 18,
            lineHeight: 1.2,
            m: "auto",
            padding: "0 20px",
            maxWidth: "800px",
            fontFamily: "'Ubuntu', 'sans-serif'",
          }}
          style={{ paddingTop: "30px" }}
          className="normalText"
        >
          Read the{" "}
          <a
            href="https://www.iitmaa.org/f/mock-interview-12582"
            className="font-semibold underline text-blue-600 hover:text-blue-800"
          >
            Blog Article
          </a>{" "}
          on the role of Fyzen Career Solutions Pvt Ltd (owner of interwiu.com)
          in the success of students by conducting Mock Interviews (with
          Feedback Sessions) before the Campus Placements (2021-22) at IIT
          Madras: published by <b> IIT Madras Alumni Association</b>
        </Typography>

        <div className="div-testimonial">
          {/* <Carousel
          emulateTouch={false}
          swipeable={false}
          selectedItem={count}
          showArrows={false}
          infiniteLoop={true}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={3000}
          onChange={(e) => setCount(e)}
        >
          {testimonials.map((testimonial, index) => {
            return (
              <div style={{ borderRadius: "30px" , fontFamily:  "'Ubuntu', 'sans-serif'"}}>
                <div className="text-black rounded-lg bg-white p-3 grid grid-cols-4 gap-3 flex-col items-stretch sm:flex-row testimonial-flex" style={{ display: "flex"  }} key={index} id="testimonial">
                  <div className="col-span-1 my-auto ">
                    <div className="rounded-3xl m-3 mx-auto overflow-hidden border-8 border-white" style={{ height: "169px", width: "169px" }}>
                      <img
                        src={require("../../images/testimonial-image/" +
                          testimonial.name +
                          ".jpg")}
                        className="object-cover"
                        alt="Person"
                      />
                    </div>
                    <div className="text-center">
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{ fontWeight: "bold" }}
                        style={{ fontFamily:  "'Ubuntu', 'sans-serif'"}}
                      >
                        {testimonial.name}
                      </Typography>
                    </div>
                  </div>
                  <div className="col-span-3 my-auto text-left">
                    <Typography
                      variant="body1"
                      sx={{ m: 0, fontSize: "18px" }}
                      gutterBottom
                      style={{ fontFamily:  "'Ubuntu', 'sans-serif'"}}
                    >
                      {testimonial.degree} <b> IIT Madras</b>, 2022
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "18px", pb: 1 }}
                      gutterBottom
                      style={{ fontFamily:  "'Ubuntu', 'sans-serif'"}}
                    >
                      Placed at <b>{testimonial.placedIn}</b> as{" "}
                      <b>{testimonial.position}</b>
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "justify",
                        lineHeight: 1.2,
                      }}
                      style={{ fontFamily:  "'Ubuntu', 'sans-serif'"}}
                    >
                      <div id="line-clamp">
                        <i class="fa-solid fa-quote-left text-green-700 pr-2"></i>{" "}
                        {testimonial.comment}
                        <i class="fa-solid fa-quote-right text-green-700 pl-2"></i>
                      </div>{" "}
                    </Typography>
                  </div>
                </div>
              </div>
            );
          })}

        </Carousel> */}
          <div className="main-testimonial">
            {transitions((style, i) => {
              const Page = p[i];
              const testimonial = testimonials[i];
              if (testimonial.comment.length > words) {
                testimonial.comment =
                  testimonial.comment.substring(0, words) + "...";
              }
              return (
                <animated.div className="testimonial-div" style={{ ...style }}>
                  <div className="iamge-border">
                    <img
                      src={
                        testimonial.hasOwnProperty("Year")
                          ? require("../../images/testimonial-image/2023/" +
                              testimonial.name +
                              ".png")
                          : require("../../images/testimonial-image/" +
                              testimonial.name +
                              ".jpg")
                      }
                      alt={testimonial.name}
                    />
                  </div>
                  <div className="testimonial-details">
                    <p>
                      {testimonial.hasOwnProperty("Branch")
                        ? testimonial["degree"] + ", " + testimonial["Branch"]
                        : testimonial.degree}
                      <br></br>
                      {testimonial.hasOwnProperty("Institute") ? (
                        <b>
                          {testimonial["Institute"]}, {testimonial["Year"]}
                        </b>
                      ) : (
                        <b>IIT Madras, 2022</b>
                      )}
                    </p>

                    <p>
                      Placed at <b>{testimonial.placedIn}</b> as{" "}
                      <b>{testimonial.position}</b>
                    </p>
                  </div>
                  <p className="testimonial-comment">
                    {testimonial.comment}
                    <img className="inverted1" src={Svg1} alt='"' />
                    <img className="inverted2" src={Svg2} alt='"' />
                  </p>
                  <p className="testimonial-name">~ {testimonial.name}</p>
                  {/* <div className="testimonial-content">
                </div> */}
                  <IconButton
                    className="prev-btn"
                    aria-label="Previous"
                    size="large"
                    onClick={() => onLeftClick()}
                  >
                    <ArrowBackIosIcon
                      style={{ transform: "translate(5px,0px)" }}
                    />
                  </IconButton>
                  <IconButton
                    className="next-btn"
                    aria-label="Next"
                    size="large"
                    onClick={onClick}
                  >
                    <ArrowForwardIosIcon
                      style={{ transform: "translate(2px,0px)" }}
                    />
                  </IconButton>
                </animated.div>
              );
            })}
          </div>

          <div className="my-1 flex justify-center show-mobile">
            <IconButton
              className="prev-btn-mobile"
              aria-label="Previous"
              size="large"
              onClick={onLeftClick}
            >
              <ArrowBackIosIcon style={{ transform: "translate(5px,0px)" }} />
            </IconButton>
            <IconButton
              className="next-btn-mobile"
              aria-label="Next"
              size="large"
              onClick={onClick}
            >
              <ArrowForwardIosIcon
                style={{ transform: "translate(2px,0px)" }}
              />
            </IconButton>
          </div>
          <div className="flex justify-center pb-4">
            <Link className="allSuccessStoriesBtn" to={SUCCESS_STORIES_ROUTE}>
              See All Success Stories
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessStories;
