import React from "react";
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacyWrapper">
        <h1 className="privacyHead page-heading-h1">Privacy Policy</h1>
        <p className="privacyText normalText">Last Updated: 8th Nov, 2022</p>
        <p className="privacyText normalText">Welcome to interwiu.com!</p>
        <a className="privacyText normalText" href="https://interwiu.com/">
          {" "}
          https://interwiu.com/
        </a>
        <span className="privacyText normalText">
          website is owned by Fyzen Career Solutions Pvt Ltd, which is a data
          controller of your personal data.
        </span>
        <p className="privacyText normalText">
          We have adopted this Privacy Policy, which determines how we are
          processing the information collected by interwiu.com, which also
          provides the reasons why we must collect certain personal data about
          you. Therefore, you must read this Privacy Policy before using
          interwiu.com website.
        </p>
        <p className="privacyText normalText">
          We take care of your personal data and undertake to guarantee its
          confidentiality and security.
        </p>
        <h2 className="privacySubHead heading1">Personal information we collect:</h2>
        <p className="privacyText normalText">
          When you visit the interwiu.com, we automatically collect certain
          information about your device, including information about your web
          browser, IP address, time zone, and some of the installed cookies on
          your device. Additionally, as you browse the Site, we collect
          information about the individual web pages or products you view, what
          websites or search terms referred you to the Site, and how you
          interact with the Site. We refer to this automatically-collected
          information as “Device Information.” Moreover, we might collect the
          personal data you provide to us (including but not limited to Name,
          Surname, Contact Number, Email Address, payment information, etc.)
          during registration to be able to fulfill the agreement.
        </p>
        <h2 className="privacySubHead heading1">Why do we process your data?</h2>
        <p className="privacyText normalText">
          Our top priority is customer data security, and, as such, we may
          process only minimal user data, only as much as it is absolutely
          necessary to maintain the website. Information collected automatically
          is used only to identify potential cases of abuse and establish
          statistical information regarding website usage. This statistical
          information is not otherwise aggregated in such a way that it would
          identify any particular user of the system.
        </p>
        <p className="privacyText normalText">
          You can visit the website without telling us who you are or revealing
          any information, by which someone could identify you as a specific,
          identifiable individual. If, however, you wish to use some of the
          website’s features, or you wish to receive our newsletter or provide
          other details by filling a form, you may provide personal data to us,
          such as your email, first name, last name, position/designation,
          organization, contact number, etc. You can choose not to provide us
          with your personal data, but then you may not be able to take
          advantage of most of the website’s features. Users who are uncertain
          about what information is mandatory are welcome to contact us via
          support@interwiu.com.
        </p>
        <h2 className="privacySubHead heading1">Your rights:</h2>
        <p className="privacyText normalText">
          You have the following rights related to your personal data: The right
          to be informed. The right of access. The right to rectification. The
          right to restrict processing. The right to data portability. The right
          to object. Rights in relation to automated decision-making and
          profiling. If you would like to exercise this right, please contact us
          through the contact information provided on the website. Additionally,
          we note that we are processing your information in order to fulfill
          contracts we might have with you (for example, if you make a booking
          through the Site), or otherwise to pursue our legitimate business
          interests listed above.
        </p>
        <h2 className="privacySubHead heading1">Links to other websites:</h2>
        <p className="privacyText normalText">
          Our website may contain links to other websites that are not owned or
          controlled by us. Please be aware that we are not responsible for such
          other websites or third parties' privacy practices. We encourage you
          to be aware when you leave our website and read the privacy statements
          of each website that may collect personal information.
        </p>
        <h2 className="privacySubHead heading1">Information security:</h2>
        <p className="privacyText normalText">
          We secure information you provide on computer servers in a controlled,
          secure environment, protected from unauthorized access, use, or
          disclosure. We keep reasonable administrative, technical, and physical
          safeguards to protect against unauthorized access, use, modification,
          and personal data disclosure in its control and custody. However, no
          data transmission over the Internet or wireless network can be
          guaranteed.
        </p>
        <h2 className="privacySubHead heading1">Legal disclosure:</h2>
        <p className="privacyText normalText">
          We will disclose any information we collect, use or receive if
          required or permitted by law, such as to comply with a subpoena or
          similar legal process, and when we believe in good faith that
          disclosure is necessary to protect our rights, protect your safety or
          the safety of others, investigate fraud, or respond to a government
          request.
        </p>
        <h2 className="privacySubHead heading1">Contact information:</h2>
        <p className="privacyText normalText">
          If you would like to contact us to understand more about this Policy
          or wish to contact us concerning any matter relating to individual
          rights and your Personal Information, you may send an email to
          support@interwiu.com.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
