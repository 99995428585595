export const testimonials2023 = [
  // {
  //   studId: "Sb80ah414w",
  //   name: "Anubhav Singh",
  //   Institute: "IIT Bhubaneswar",
  //   degree: "B.Tech",
  //   Branch: "Biosciences",
  //   Year: 2023,
  //   placedIn: "Amazon",
  //   position: "Software Development Engineer 1",
  //   comment:
  //     "My experience was quite good. The interviewer was very professional and polite. This experience helped me a lot in my actual interview",
  // },
  {
    studId: "S1eqmq2o4e",
    name: "Shikhar Tiwari",
    Institute: "IIT (BHU) Varanasi",
    degree: "Dual Degree (B.Tech + M.Tech)",
    Branch: "Biomedical Engineering",
    Year: 2023,
    placedIn: "JPMorgan Chase & Co.",
    position: "Analyst",
    comment:
      "I utilized the interwiu.com platform to participate in mock interviews tailored for the Analyst role. The platform allowed me to connect with mentors having an immense experience at the specific companies which I was targeting. The interwiu.com's realistic interview scenarios and the expert feedback I received proved instrumental in refining my technical knowledge, improving my communication skills, and presenting myself as a strong candidate for the analyst role.",
  },
  {
    studId: "Saxdlrkzhb",
    name: "Sanket Kajare",
    Institute: "IIT (ISM) Dhanbad",
    degree: "B.Tech",
    Branch: "Civil Engineering",
    Year: 2023,
    placedIn: "Sprinklr",
    position: "Product Analyst ",
    comment:
      "A mock interview at interwiu.com helped me better analyse my preparation in an interview environment. It also helped me to know some of the non-conventional questions that an interviewer might ask, which turned out to be very helpful in my real interview",
  },
  {
    studId: "S1yc85g5i6",
    name: "Nandni Sinha",
    Institute: "IIT Kanpur",
    degree: "B.Tech",
    Branch: "Materials Science & Engineering",
    Year: 2023,
    placedIn: "Alvarez & Marsal",
    position: "Associate",
    comment:
      "A truly remarkable platform! The mock interviews were really useful in giving me the type of exposure of a typical Consulting interview. My mentors were really good and they provided me with a proper feedback to improve upon. I would definitely recommend this platform (interwiu.com) to anyone appearing for the placement/job-switch/internship interviews",
  },
  {
    studId: "S1kktub7yd",
    name: "Abithan P S",
    Institute: "IIT Madras",
    degree: "B.Tech",
    Branch: "Mechanical Engineering",
    Year: 2023,
    placedIn: "Ernst & Young",
    position: "Associate Consultant",
    comment:
      "The mock interviews were well rounded, and all the required aspects were covered in a very structured format during my mock interviews",
  },
  {
    studId: "S1rdxrb0zg",
    name: "Hruthik  V S",
    Institute: "IIT Madras",
    degree: "Dual Degree (B.Tech + M.Tech)",
    Branch: "Mechanical Engineering",
    Year: 2023,
    placedIn: "Honda R&D Japan",
    position: "AI based Recognition And Control System Engineer",
    comment:
      "The mock interview I took really helped me get accustomed to the stress faced during a real interview. The type of questions asked from me included both, Technical and HR. The tips provided by the interviewer enabled me to streamline my thoughts and answer to the point during the real interview",
  },
  {
    studId: "S2fwijpicj",
    name: "Loke",
    Institute: "IIT Madras",
    degree: "B.Tech",
    Branch: "Mechanical Engineering",
    Year: 2023,
    placedIn: "Deloitte",
    position: "Analyst",
    comment:
      "The interviewers at interwiu.com are very helpful. They boosted-up my confidence before the real interview, and also pointed out the things where I could do much better during the interview",
  },
  {
    studId: "S1e8zbne2v",
    name: "Jai Narayan Suresh",
    Institute: "IIT Madras",
    degree: "B.Tech",
    Branch: "Chemical Engineering",
    Year: 2023,
    placedIn: "Axis Bank ",
    position: "Data Scientist ",
    comment:
      "I had taken up a mock interview in the Analytics profile at interwiu.com. After the interview, the mock interviewer gave me a broad overview of what interviewers look for and how to approach a question, explaining the projects (body of work) or line items from the resume. The feedback provided was pretty honest and constructive which helped me leverage my skills and abilities better. It also helped me ace my placement interview with confidence and secure a role I'd always been passionate about. I'd like to thank interwiu.com for this initiative of mapping aspirants with relevant professionals (interviewers) for the greater good",
  },
  {
    studId: "Sjfhtuid8q",
    name: "Ayan Srivastava",
    Institute: "IIT Kanpur",
    degree: "M.Tech",
    Branch: "Civil Engineering",
    Year: 2023,
    placedIn: "SAP Labs",
    position: "Associate Data Scientist",
    comment:
      "The in-depth discussion that took place during the mocks at interwiu.com along with the insightful feedback that I got during and after those mocks proved to be monumental in my placement journey, as I could analyse my strengths and weaknesses in an interview-like environment, well before the actual interview.\n\n\n",
  },

  {
    studId: "S9ye6kyo65",
    name: "Janani M",
    Institute: "IIT Madras",
    degree: "B.Tech",
    Branch: "Chemical Engineering",
    Year: 2023,
    placedIn: "Wells Fargo ",
    position: "Program Associate ",
    comment:
      "The mock interview with a previously placed senior of mine (who is an interviewer at interwiu.com) gave some real perspectives of an interview which served as a confidence check for my preparation. Thanks to the platform! ",
  },

  {
    studId: "S3885wfwb9",
    name: "Jayesh Chandan",
    Institute: "IIT Kharagpur",
    degree: "B.Tech",
    Branch: "Computer Science and Engineering",
    Year: 2023,
    placedIn: "Enphase Energy",
    position: "Software Engineer",
    comment:
      "interwiu.com has been very helpful to me in my placement journey. It is an amazing initiative for people who are serious about their placement preparation and want to get placed in their dream MNCs. I want to thank the members of interwiu.com team for their help. I would recommend it to everyone for placement preparation",
  },

  {
    studId: "S6u5943q36",
    name: "Saad Ahmad",
    Institute: "IIT Kanpur",
    degree: "B.Tech",
    Branch: "Mechanical Engineering",
    Year: 2023,
    placedIn: "Rakuten Group, Inc., Japan",
    position: "Applications Engineer",
    comment:
      "I appeared for a mock interview in the Software profile. The mock interview was really helpful. The feedback I received was comprehensive, and it gave me a clear understanding of where and how I can enhance my skills. The insights provided by my interviewer helped me to give more structured and well thought-out answers ",
  },

  {
    studId: "S1q4pi4atk",
    name: "Tejaswi Arvapalli",
    Institute: "IIT (ISM) Dhanbad",
    degree: "B.Tech",
    Branch: "Computer Science and Engineering",
    Year: 2023,
    placedIn: "Jio",
    position: "Software Development Engineer 1",
    comment:
      "I am thrilled to share that I've secured an on-campus placement at Jio after successfully completing a mock interview at interwiu.com! The platform's realistic interview simulations and valuable feedback helped me build confidence and improve my skills significantly. I'm grateful for the opportunity and excited to start this new chapter of my career journey. A big shoutout to interwiu.com for providing such a valuable resource for job seekers like me!",
  },

  {
    studId: "S17mu3a60b",
    name: "Anushka RajLakshmi",
    Institute: "IIT Guwahati",
    degree: "M.Tech",
    Branch: "Chemical Engineering",
    Year: 2023,
    placedIn: "TCS Research and Innovation",
    position: "System Engineer",
    comment:
      "I am extremely grateful to the interwiu.com team as it played a very important role in helping me secure a campus placement during phase-1. I had appeared for mock interview sessions at interwiu.com and honestly, those sessions felt like a real interview session with a very friendly and helping interviewing process. Not only the interviewers prepared me well for the interview rounds through technical questions, but they also guided me on how to face the non-technical questions and how to improve my resume. The interviewers were very motivating and helpful. Thanks again to the interwiu.com team for facilitating these sessions",
  },

  {
    studId: "S66f3tm5r6",
    name: "Dipika Agrawal",
    Institute: "IIT Guwahati",
    degree: "B.Tech",
    Branch: "Mathematics",
    Year: 2023,
    placedIn: "BNY Mellon",
    position: "Software Developer ",
    comment:
      "I appeared for a mock interview in Software Engineering profile. That interview experience was very helpful and informative. The interviewer provided a really descriptive feedback which helped me a lot during actual interview process",
  },

  {
    studId: "S10k6tayae",
    name: "Basu Garg",
    Institute: "IIT (BHU) Varanasi",
    degree: "Dual Degree (B.Tech + M.Tech)",
    Branch: "Chemistry",
    Year: 2023,
    placedIn: "FPL Technologies ",
    position: "Data Scientist ",
    comment:
      "It was really a great learning experience. It  helped me to boost-up my confidence and upgrade my technical skills. Mock Interviews including Case Studies really prepared me for the actual interviews and made me learn how to tackle any new challenges",
  },

  {
    studId: "S46o9qus2c",
    name: "Ajay Edupuganti",
    Institute: "IIT Madras",
    degree: "Dual Degree (B.Tech + M.Tech)",
    Branch: "Aerospace Engineering",
    Year: 2023,
    placedIn: "Wipro Linecraft AI",
    position: "Software Developer ",
    comment:
      "Thank you for creating this platform. It was incredibly helpful. I booked the mock interviews based on my shortlist and after those interviews, I received a detailed feedback and suggestions. This platform has significantly improved my interview experience",
  },
  {
    studId: "S1snd83xuc",
    name: "Siddharth Saurav",
    Institute: "IIT (BHU) Varanasi",
    degree: "B.Tech",
    Branch: "Chemical Engineering",
    Year: 2023,
    placedIn: "Perfios",
    position: "Software Development Engineer",
    comment:
      "It is always better to have some mock interview experience before facing the real heat. interwiu.com helped me connect with one great interviewer for a mock interview before my real interview",
  },

  {
    studId: "S31xzlkaza",
    name: "Kunal Garg",
    Institute: "IIT Guwahati",
    degree: "B.Tech",
    Branch: "Mechanical Engineering",
    Year: 2023,
    placedIn: "Gemini Solutions ",
    position: "Software Engineer",
    comment:
      "Interviewer cleared all my doubts and provided me with much needed confidence before appearing for the actual interview",
  },
  {
    studId: "S2g63yixyi",
    name: "Rahman Alam",
    Institute: "IIT Delhi",
    degree: "M.Tech",
    Branch: "Applied Mechanics",
    Year: 2023,
    placedIn: "VE Commercial Vehicles",
    position: "Manager",
    comment:
      "It's a nice platform for both freshers and experience-holders as you will always be able to find a more experienced person than you at interwiu.com who can guide you throughout your journey",
  },

  {
    studId: "S29bqdqt1a",
    name: "Somdeep Acharyya",
    Institute: "IIT Madras",
    degree: "M.Tech",
    Branch: "Management Studies",
    Year: 2023,
    placedIn: "LatentView Analytics",
    position: "Senior Data Analyst",
    comment:
      "interwiu.com helped me a lot to prepare for my placement interviews. With their expert support and the questions asked during the mock interviews, it gets easier to gain confidence and brush your skills up which makes you feel that you will easily crack the real interviews",
  },
  {
    studId: "S67gy7fc4r",
    name: "Gokul",
    Institute: "IIT Madras",
    degree: "Dual Degree (B.Tech + M.Tech)",
    Branch: "Mechanical Engineering",
    Year: 2023,
    placedIn: "Monarch Tractor",
    position: "Perception Engineer",
    comment:
      "interwiu.com has helped me prepare mentally for the interviews, and the feedback from the interviewer was really helpful as well. I would reccomend it to anyone who has job interviews in the near future.",
  },
  {
    studId: "S2re62iwmk",
    name: "Amar Agnihotri",
    Institute: "IIT Delhi",
    degree: "Dual Degree (B.Tech + M.Tech)",
    Branch: "Computer Science and Engineering",
    Year: 2023,
    placedIn: "NableIT ",
    position: "Software Engineer",
    comment:
      "It is a good platform to get pre-interview experience. interwiu.com provides interview experience based on the recruiting company and its job requirements in particular. So, go for it! Best of Luck!",
  },
  {
    studId: "S29g0lbb03",
    name: "Suvit",
    Institute: "IIT Madras",
    degree: "M.Tech",
    Branch: "Applied Mechanics",
    Year: 2023,
    placedIn: "Amadasoft and K-12 Technology Ltd",
    position: "Software Developer ",
    comment:
      "I appeared for around 4 to 5 mock interviews related to my field at interwiu.com. In each interview, I got to know different shades of the questions that can be asked from my resume. Based on those questions, I prepared the topics which were important for the placement season. Via mock interviews, I got to know my strengths on which I have to play during real interviews and also my weakness which I have to eliminate. In each subsequent interview, I tried to eliminate most of weaknesses which were pointed in the previous interviews. Here is an advice to my juniors: try to face these mock interviews at interwiu.com as real placement interviews, and most of your fear about communication and techninical knowledge will be eliminated to a large extent",
  },

  {
    studId: "S1zs8ph4n7",
    name: "Vasantha Kumar",
    Institute: "IIT Madras",
    degree: "M.Tech",
    Branch: "Biotechnology",
    Year: 2023,
    placedIn: "Info Edge (India) Limited (Naukri.com)",
    position: "Data Scientist",
    comment:
      "My mock interview with an interviewer from IBM helped me to identify the area to work upon and eventually boosted-up my confidence. ",
  },
  {
    studId: "S19a64gny4",
    name: "Kundan Sharma",
    Institute: "IIT (ISM) Dhanbad",
    degree: "B.Tech",
    Branch: "Mining Engineering",
    Year: 2023,
    placedIn: "Tredence",
    position: "Data Scientist ",
    comment:
      "Mock interviews always help before the real ones, and it's a really good platform for mock interviews",
  },

  {
    studId: "S4jm0hoykg",
    name: "Arun Nayak",
    Institute: "IIT Kharagpur",
    degree: "B.Tech",
    Branch: "Metallurgical and Materials Engineering",
    Year: 2023,
    placedIn: "UIDAI",
    position: "Technical Executive",
    comment:
      "I am really grateful for the pre-placement interview experience provided by interwiu.com. It helped me a lot in my interview preparation by giving an overview of how the interview process exactly will be and what type of questions I can expect in my real interviews",
  },
];
