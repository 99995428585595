// When Interviewer views Students Profile on any Card in Received Requests, Accepted Requests, Upcoming Interviews and Completed Interviews
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { Box, Rating, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { BASE } from "../../constants/endpoints";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const Int2Std = (id) => {
  // const [list, setList] = useState([
  //   "Data Science",
  //   "Banking and Finance",
  //   "Consulting",
  // ]);

  const [profiledata, setProfileData] = React.useState("");
  useEffect(() => {
    async function fetchData() {
      await axios
        .get(`${BASE}/send/interviewrs/${id}`)
        .then((response) => {
          setProfileData(response.data);
          console.log(profiledata);
        })
        .catch((error) => {
          console.log("ERROR");
          console.log(error);
        });
    }
    fetchData();
  }, []);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const [value, setValue] = React.useState(3.5);
  const [hover, setHover] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [unOpen, setUnOpen] = useState(false);
  const handleClickOpen = () => {
    setUnOpen(true);
  };
  const handleClickClose = () => {
    setUnOpen(false);
  };
  return (
    <>
      {/* <Navbarlogedin /> */}
      <div className="interviews w-full">
        <div className="w-full p-2 my-2 border-2 rounded flex justify-center">
          <Typography
            variant="h4"
            component="h4"
            sx={{
              // textAlign: "center",
              display: "inline",
              color: "#1265a8",
              fontWeight: "bold",
              margin: "0 auto",
            }}
          >
            John's Profile (int-std)
          </Typography>
        </div>
        <div className="grid mx-2" id="profile-cont">
          <div className="m-2 overflow-hidden">
            <div className="border rounded mb-4">
              <div className="h-32 opacity-80 overflow-hidden">
                <img src={require("../../images/cover2.webp")} alt="Cover" />
              </div>
              <div className="relative grid py-2" id="profile-bio">
                <div className="relative">
                  <div className="absolute w-40 -top-16 left-3 mx-auto">
                    <img
                      src={require("../../images/photo.png")}
                      className="rounded-full p-1 border bg-white"
                      alt="Profile"
                    />
                  </div>
                </div>
                <div className="relative">
                  <Typography
                    variant="h4"
                    component="h4"
                    sx={{
                      color: "#e84c3d",
                      fontWeight: "bold",
                    }}
                  >
                    John Doe
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ lineHeight: 1, fontSize: "16px" }}
                  >
                    B.Tech
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ lineHeight: 1, fontSize: "16px" }}
                  >
                    Computer Science Engineering
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ lineHeight: 1, fontSize: "16px" }}
                  >
                    IIT Kharagpur
                  </Typography>

                  <p className="text-gray-500 leading-5"></p>
                  <div className="text-2xl my-1 flex w-40 justify-between">
                    <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                      <i className="fa-brands fa-linkedin"></i>
                    </div>
                    <div className="hover:text-blue-500 hover:bg-gray-100 text-black cursor-pointer border rounded p-2 w-12 text-center">
                      <i className="fa-brands fa-github"></i>
                    </div>
                    <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                      <i className="fa-brands fa-twitter"></i>
                    </div>
                  </div>
                  <div className="absolute flex top-1 right-2 gap-2">
                    <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                      <i className="fa-solid fa-bookmark"></i>
                    </div>
                    <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                      <i className="fa-solid fa-share"></i>
                    </div>
                  </div>
                </div>
                <div className="relative h-full">
                  <div className="w-20 border rounded-md mx-auto my-2 px-2 py-1">
                    <img
                      src={require("../../images/iitd.png")}
                      alt="iitd"
                      style={{ width: "70px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded border p-2">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Description
              </Typography>
              <p>I'm a Student, looking for Mock Interview.</p>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Resume
              </Typography>
              <Button variant="contained">
                <i className="fa-solid fa-link"></i> Resume
              </Button>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                Profile Preference
              </Typography>
              <div>
                <Chip label="Software" variant="outlined" />
                <Chip label="Data Analysis" variant="outlined" />
              </div>
            </div>
          </div>
          <div className="border rounded p-2 m-2">
            <div className="border rounded mb-2">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                Feeds
              </Typography>
              <div className="border rounded drop-shadow-lg my-2">
                <img
                  src={require("../../images/join-now.jpg")}
                  alt="Join Now"
                />
              </div>
            </div>
            <div className="border rounded p-1 py-2 my-2">
              <p className="text-sm text-justify font-medium">
                John Doe has sent you a request for Mock Interview (with
                Feedback Session)!
              </p>
              <div className="border rounded p-1 text-sm">
                <p>
                  <span className="font-medium text-gray-500">Profile: </span>{" "}
                  Data Science
                </p>
                <p>
                  <span className="font-medium text-gray-500">Slot: </span>{" "}
                  [5:00PM - 5:45PM] 20 June, 2022
                </p>
                <div className="text-right">
                  <p className="text-xs text-gray-400">Sent 15 Min ago</p>
                </div>
              </div>
              <div className="flex justify-center">
                <Button
                  variant="contained"
                  sx={{
                    height: "24px",
                    backgroundColor: "green",
                    marginRight: "10px",
                    fontSize: "12px",
                  }}
                >
                  Accept
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: "24px",
                    backgroundColor: "red",
                    fontSize: "12px",
                  }}
                >
                  Decline
                </Button>
              </div>
            </div>
            <div className="border rounded p-1 py-2 my-2">
              <p className="text-sm font-medium text-justify">
                You have accepted the request of Mr. John Doe for a Mock
                Interview (with Feedback Session)!
              </p>
              <div className="border rounded p-1 text-sm">
                <p>
                  <span className="font-medium text-gray-500">Profile: </span>{" "}
                  Data Science
                </p>
                <p>
                  <span className="font-medium text-gray-500">Slot: </span>{" "}
                  [5:00PM - 5:45PM] 20 June, 2022
                </p>
                <div className="text-right">
                  <p className="text-xs text-gray-400">Accepted 15 Min ago</p>
                </div>
              </div>
              <p className="text-sm font-medium text-center text-blue-400">
                He/she will pay and confirm it soon!
              </p>
              <div className="flex justify-center text-red-600">
                <img
                  src={require("../../images/loading.gif")}
                  alt="loading"
                  style={{
                    width: "30px",
                    marginRight: "10px",
                  }}
                />

                <div className="cancel-dropdown-cont" style={{ width: "100%" }}>
                  <div className="relative" id="cancel">
                    <p>
                      Waiting for John Doe to Pay & Confirm
                      <i className="fa-solid fa-caret-right border rounded-md p-1 ml-1"></i>
                    </p>
                    <button
                      className="absolute hidden w-36 h-7 bottom-0 right-3 z-20 border border-red-600 rounded px-1 text-sm bg-red-600 text-white"
                      id="cancel-btn"
                      onClick={handleClickOpen}
                    >
                      Unaccept Request
                    </button>
                  </div>
                  <div>
                    <Dialog
                      open={unOpen}
                      onClose={handleClickClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Use Google's location service?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Let Google help apps determine location. This means
                          sending anonymous location data to Google, even when
                          no apps are running.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button autoFocus>YES</Button>
                        <Button onClick={handleClickClose}>NO</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
            <div className="border rounded p-1 py-2 my-2">
              <p className="text-sm text-justify font-semibold text-green-600">
                Your Mock Interview (with Feedback Session) has been Confirmed
                with Mr. John Doe!
              </p>
              <div className="border rounded p-1 text-sm">
                <p>
                  <span className="font-medium text-gray-500">Profile: </span>{" "}
                  Data Science
                </p>
                <p>
                  <span className="font-medium text-gray-500">Slot: </span>{" "}
                  [5:00PM - 5:45PM] 20 June, 2022
                </p>
                <div className="text-right">
                  <p className="text-xs text-gray-400">Updated 15 Min ago</p>
                </div>
              </div>
              <div className="flex justify-center">
                <Button
                  sx={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "#00b0f0",
                    border: "1px solid #00b0f0",
                  }}
                >
                  Join Meeting
                </Button>
              </div>
            </div>
            <div className="border rounded p-1 py-2 my-2">
              <div>
                <p className="text-green-600 text-justify font-semibold">
                  Hope you had a pleasant Mock Interview (with Feedback Session)
                  of Mr. John Doe
                </p>
              </div>
              <div className="border rounded p-1 text-sm">
                <p>
                  <span className="font-medium text-gray-500">Profile: </span>{" "}
                  Data Science
                </p>
                <p>
                  <span className="font-medium text-gray-500">Slot: </span>{" "}
                  [5:00PM - 5:45PM] 20 June, 2022
                </p>
                <div className="text-right">
                  <p className="text-xs text-gray-400">Completed 15 Min ago</p>
                </div>
              </div>
              <div className="rate-btn flex justify-center">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                  }}
                  onClick={handleOpen}
                >
                  Rate and Review
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Box
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    >
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ textAlign: "center" }}
                    >
                      Rate and Review
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Please Rate and Review your Interview Experience.
                    </Typography>
                    <Box
                      sx={{
                        width: 200,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Rating
                        name="hover-feedback"
                        value={value}
                        precision={0.5}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      {value !== null && (
                        <Box sx={{ ml: 2 }}>
                          {labels[hover !== -1 ? hover : value]}
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        id="outlined-multiline-static"
                        label="Review"
                        multiline
                        rows={4}
                        sx={{
                          mb: 2,
                        }}
                      />
                      <Button variant="outlined">Submit</Button>
                    </Box>
                  </Box>
                </Modal>
              </div>
            </div>
            <div className="border rounded p-1 py-2 my-2">
              <div>
                <p className="text-justify">
                  Thanks for Rating/Reviewing your Mock Interview exprience with
                  John Doe
                </p>
                <p>
                  <span className="font-semibold text-green-700">Rating: </span>
                  4.5 <i className="fa-solid fa-star"></i>
                </p>
                <p>
                  <span className="font-semibold text-green-700">Review: </span>
                  Absolutely Best
                </p>
              </div>
              <div className="border rounded p-1 text-sm">
                <p>
                  <span className="font-medium text-gray-500">Profile: </span>{" "}
                  Data Science
                </p>
                <p>
                  <span className="font-medium text-gray-500">Slot: </span>{" "}
                  [5:00PM - 5:45PM] 20 June, 2022
                </p>
                <div className="text-right">
                  <p className="text-xs text-gray-400">Completed 15 Min ago</p>
                </div>
              </div>
            </div>
            <div className="border rounded p-1 py-2 my-2">
              <p className="text-sm text-justify font-medium">
                Utsav Bhardwaj has prepared himself better based on the Feedback
                provided by you last time, and now wants to test it out!
              </p>
              <p className="text-sm text-center font-medium text-red-500">
                Utsav has sent you a request for Mock Interview (with Feedback
                Session) again!
              </p>
              <div className="border rounded p-1 text-sm">
                <p>
                  <span className="font-medium text-gray-500">Profile: </span>{" "}
                  Data Science
                </p>
                <p>
                  <span className="font-medium text-gray-500">Slot: </span>{" "}
                  [5:00PM - 5:45PM] 20 June, 2022
                </p>
                <div className="text-right">
                  <p className="text-xs text-gray-400">Updated 15 Min ago</p>
                </div>
              </div>
              <div className="flex justify-center">
                <Button
                  variant="contained"
                  sx={{
                    height: "24px",
                    backgroundColor: "green",
                    marginRight: "10px",
                    fontSize: "12px",
                  }}
                >
                  Accept
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: "24px",
                    backgroundColor: "red",
                    fontSize: "12px",
                  }}
                >
                  Decline
                </Button>
              </div>
            </div>
            <div className="rounded border my-2 px-2">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                Top Interviewers
              </Typography>
              <div>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                officia dolore quos voluptatem dicta qui exercitationem unde
                nemo quia asperiores. Necessitatibus aliquam earum vel hic et
                blanditiis adipisci accusantium qui.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Int2Std;
