import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import SearchInterviews from "./SearchInterviews";
import SentRequests from "./SentRequests";
import AcceptedRequests from "./AcceptedRequests";
import CompletedInterviews from "./CompletedInterviews";
import UpcomingInterviews from "./UpcomingInterviews";
import AutoRejectedRequest from "./AutoRejectedRequests";
import DeclinedRequests from "./sub-section/DeclinedRequests";
// import CanceledInterview from "./cards/CanceledInterview";
import ReportIssue from "./ReportIssue";
import Reschedule from "./Reschedule";
import SCancel from "./SCancel";
import Int2Std from "../Interviewer/Int2Std";
import ProfileForm from "./ProfileForm";
import Welcome from "./Welcome";
import InterviewProfile from "../Interviewer/BriefInterviewerProfile";
import CanceledInterviews from "./sub-section/CanceledInterviews";
import StoryForm from "./StoryForm";
import AccountSettings from "./AccountSettings";
import DeactivatedWelcome from "./DeactivateWelcome";

const StudentRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="find-an-interviewer" element={<SearchInterviews />} />
        <Route path="sent-requests" element={<SentRequests />} />
        <Route path="accepted-requests" element={<AcceptedRequests />} />
        <Route path="upcoming-interviews" element={<UpcomingInterviews />} />
        <Route path="completed-interviews" element={<CompletedInterviews />} />
        <Route path="declined-requests" element={<DeclinedRequests />} />
        <Route path="canceled-interview" element={<CanceledInterviews />} />
        <Route path="add/success-story" element={<StoryForm />} />
        <Route path="account" element={<AccountSettings />} />
        <Route path="deactivated" element={<DeactivatedWelcome />} />
        
        <Route
          exact
          path="interviewer/:id"
          element={
              <InterviewProfile />
          }
        />
        <Route
          path="precancelled-requests"
          element={<AutoRejectedRequest />}
        />
        <Route path="report" element={<ReportIssue />} />
        <Route path="reschedule" element={<Reschedule />} />
        <Route path="profile/:id" element={<Int2Std />} />
        <Route path="profile-form" element={<ProfileForm />} />
        <Route path="cancel" element={<SCancel />} />
        <Route path="welcome" element={<Welcome />} />
      </Routes>
    </div>
  );
};

export default StudentRoutes;
