

import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { auth, googleProvider } from "../../firebase";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import {
  STUDENT_FIND_AN_INTERVIEWER_ROUTE,
  INTERVIEWER_RECIEVED_REQUESTS_ROUTE,
  INTERVIEWER_PROFILE_FORM_ROUTE,
  STUDENT_WELCOME_ROUTE,
  STUDENT_PROFILE_FORM_ROUTE,
  STUDENT_DEACTIVATE_ROUTE,
  INTERVIEWER_DEACTIVATE_ROUTE,
} from "../../constants/routes";
import {
  AUTH_STD_GOOGLE_ENDPOINT,
  AUTH_INT_GOOGLE_ENDPOINT,
  AUTH_ADM_GOOGLE_ENDPOINT,
} from "../../constants/endpoints";
import UserContext from "../../contexts/UserContext";
import toast from "react-hot-toast";
import { toastStyle } from "../../utility/helper";
// import { useNavigate } from "react-router-dom";



// const navigate = useNavigate();

const removeBack = (e) => {
  const loginFunc = document.querySelector(".login-container");

  loginFunc.classList.add("hide");


};
function childClick(event) {
  event.stopPropagation(); // stop propagation to parent
}


const LoginSuccessStory = () => {


  const [modal, setModal] = useState(true)

  // const navigate = useNavigate();
  const navigate = useNavigate(); // To route to another location
  const { setUser } = useContext(UserContext);

  // navigate("/auth/logout")

  // localStorage.removeItem("company")


  const remove = () => {
    const loginFunc = document.querySelector(".login-container");
    console.log("HEY");
    loginFunc.classList.remove("display");
    loginFunc.classList.add("hide");
    console.log(localStorage.getItem("company"));
    console.log(localStorage.getItem("company"));
    setModal(false);
    // navigate(0)
    localStorage.removeItem("company")

  };

  console.log("login reloaded...");
  const handleAdmGoogle = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        const email_verified = res.user.emailVerified;
        const iemail = res.user.email;
        const iname = "";
        const sub = res.user.uid;
        const picture = res.user.photoURL;
        axios({
          method: "POST",
          url: AUTH_ADM_GOOGLE_ENDPOINT,
          data: { email_verified, iemail, iname, sub, picture },
        }).then((response) => {
          console.log("Google Login Success", response);
          setUser(response.data.user);
          localStorage.setItem(
            "isAAuthenticated",
            JSON.stringify({ token: response.data.token })
          );
          remove();
          if (response.data.user.aVerified) {
            navigate("/admin/current-students");
          } else if (response.data.user.institute)
            navigate("/admin/profile-form");
          else {
            navigate("/admin/profile-form");
            setTimeout(() => {
              toast.success(`You Logged in Successfully`, {
                ...toastStyle.success,
              });
            }, 100);
            setTimeout(() => {
              toast.loading(`Please fill your profile details`, {
                ...toastStyle.loading,
                duration: 3500,
              });
            }, 2500);
          }
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleStdGoogle = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        const email_verified = res.user.emailVerified;
        const email = res.user.email;
        const name = res.user.displayName;
        const sub = res.user.uid;
        const picture = res.user.photoURL;
        console.log("res: ", res);
        try {
          axios({
            method: "POST",
            url: AUTH_STD_GOOGLE_ENDPOINT,
            data: { email_verified, email, name, sub, picture },
          })
            .then((response) => {
              console.log("Google Login Success", response.data);
              setUser({
                ...response.data.user,
                photo:
                  response.data.user.photo,
              });
              localStorage.setItem(
                "isAuthenticated",
                JSON.stringify({ token: response.data.token })
              );
              remove();
              if (response.data.deactivated) {
                navigate(STUDENT_DEACTIVATE_ROUTE);
                return;
              } else if (
                response.data.user.mobNumber &&
                !response.data.deactivated
              )
                navigate(STUDENT_FIND_AN_INTERVIEWER_ROUTE);
              else {
                navigate(STUDENT_PROFILE_FORM_ROUTE);
                setTimeout(() => {
                  toast.success(`You Logged in Successfully`, {
                    ...toastStyle.success,
                  });
                }, 100);
                setTimeout(() => {
                  toast.loading(`Please fill your profile details`, {
                    ...toastStyle.loading,
                    duration: 3500,
                  });
                }, 2500);
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleIntGoogle = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        const email_verified = res.user.emailVerified;
        const email = res.user.email;
        const name = res.user.displayName;
        const sub = res.user.uid;
        const picture = res.user.photoURL;
        try {
          axios
            .post(AUTH_INT_GOOGLE_ENDPOINT, {
              email_verified,
              email,
              name,
              sub,
              picture,
            })
            .then((response) => {
              console.log("Google Login Success", response);
              setUser({
                ...response.data.user,
                photo:
                  response.data.user.photo,
              });
              localStorage.setItem(
                "isIAuthenticated",
                JSON.stringify({ token: response.data.token })
              );
              remove();
              if (response.data.deactivated) {
                navigate(INTERVIEWER_DEACTIVATE_ROUTE);
                return;
              } else if (response.data.user.designation)
                navigate(INTERVIEWER_RECIEVED_REQUESTS_ROUTE);
              else {
                navigate(INTERVIEWER_PROFILE_FORM_ROUTE);
                setTimeout(() => {
                  toast.success(`You Logged in Successfully`, {
                    ...toastStyle.success,
                  });
                }, 100);
                setTimeout(() => {
                  toast.loading(`Please fill your profile details`, {
                    ...toastStyle.loading,
                    duration: 3500,
                  });
                }, 2500);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  if(modal){
    document.body.classList.add('active-modal')
  }else{
    document.body.classList.remove('active-modal')
  }

  return (
    <div
    style={{ backdropFilter: "blur(10px)",zIndex:"10000", }}
    className="login-container display"
      onClick={(e) => {
        removeBack(e);
      }}
    >
      <div className="login" style={{ minHeight: "40vh", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", maxWidth: "80vw" }} onClick={(e) => childClick(e)}>
        <div
          className="cross"
          onClick={() => {
            remove();
          }}
        >
          <i className="fa-solid fa-circle-xmark"></i>
        </div>

        <div className="login-head" style={{ background: "none" }} >
          <p style={{ }}>
            The founding team of interwiu.com heartily congratulates you for getting placed
            at <span style={{ color: "#004953" }}>{localStorage.getItem("company") &&  localStorage.getItem("company").toUpperCase()}</span> as <span style={{ color: "#004953" }} >{localStorage.getItem("profile").toUpperCase() && localStorage.getItem("profile").toUpperCase()}</span>!
          </p>
        </div>

        <div className="login-head" style={{ color: "black", background: "none" }}>
          <p >
            We are glad to know that we were able to help you in securing this placement.
            We wish you All the Best for a highly successful Professional Journey ahead!
          </p>
        </div>

        <div className="login-head" style={{ color: "blue", background: "none" }}>
          <p >
            Now, let’s Join the community of our premium Mock Interviewers, and help your juniors
            by taking their mock interviews and providing them with live feedback! You will also get monetary returns for the same!
          </p>
        </div>


        <div className="login-system" style={{display:"flex", flexWrap:"wrap",width: "90vw", gap: "1.5rem" ,justifyContent:"center", alignItems:"center" }}>


          <div style={{ display: "flex", position:"relative" }} className="div-login">

            <div className="col" >

              {/* <p style={{position:"absolute",}} >*</p> */}

              <h1 style={{ marginBottom: "0px", padding: "5px 0px" }}>Log In as a Professional</h1>
              <h5 style={{ textAlign: "center", marginBottom: "13px" }}>
                Create an Account as a Professional
              </h5>

              <form onSubmit={(e) => handleIntGoogle(e)}>
                <button className="signin-btn login" type="submit">
                  <img src={require("../../images/google.png")} alt="Google" />
                  Create Account with Google
                </button>
              </form>

            </div>
            {/* <p>hey</p> */}

            <div  className="check">*</div>

          </div>


        </div>


   {/* <p>hey</p> */}
        <div className="login-head" style={{ color: "red", background: "none" }}>
          <p >
            * If you had used an email address lying in your Institute Domain Name (for example, @smail.iitm.ac.in for IIT Madras students) earlier to create an account as a student, please don’t use that now. Instead, please use your personal Gmail address now to register as a Professional (i.e. as a Mock Interviewer) at interwiu.com
          </p>
        </div>

      </div>
    </div>
  );
};

export default LoginSuccessStory;


