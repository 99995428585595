import React, { useContext, useEffect, useRef, useState } from "react";
import "./InterviewerUpcoming.css";
import UpcomingInterview from "./cards/UpcomingInterview";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import DatePicker from "react-date-picker";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import axios from "axios";
import { Helmet } from "react-helmet";
import { BASE } from "../../constants/endpoints";
import { COMPANY_NAME } from "../../constants/variables";
import { Oval, ThreeDots } from "react-loader-spinner";
import UserContext from "../../contexts/UserContext";
import {
  compare,
  compareSlots,
  getCountForUpcoming,
  t24HDecimalTo12HFormat,
} from "../../utility/helper";
import moment from "moment-timezone";
import Pagination from "@mui/material/Pagination";

const InterviewerUpcoming = () => {
  const { user } = useContext(UserContext);
  const [upcomingRequest, setupcomingRequest] = useState([]);
  const [SentStudents, setSentStudents] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const params = {
    reqStatus: "ReqPaid",
    userRole: "Interviewer",
    reqSite: "Upcoming"
  };

  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, { params })
        .then((response) => {
          console.log(response)
          setupcomingRequest(response.data.Requests);
          setSentStudents(response.data.SentInterViewers)
          console.log(response.data.Requests)
          setLoaded(true);
        })
    }
    fetchData();
  }, []);
  const [cards, setCards] = React.useState([]);
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, []);
  function TransitionRight(props) {
    setDate(null);
    return <Slide {...props} direction="right" />;
  }
  const [date, setDate] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // React.useEffect(() => {
  //   async function fetchData() {
  //     const req = await axios.get(`${BASE}/api/get`);
  //     setCards(req.data);
  //     console.log(req.data);
  //   }
  //   fetchData();
  // }, []);

  // Pagination
  const resultReq = [];
  const resultInt = [];
  for (let i = 0; i < upcomingRequest.length; i++) {
    // if(upcomingRequest[i].reschedule.isRescheduled !== 'true'){
    resultReq.push(upcomingRequest[i]);
    resultInt.push(SentStudents[i]);
    // }
  }

  const [page, setPage] = useState(1); // No of Pages
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [pageNo, setPageNo] = useState([0, 10]);
  useEffect(() => {
    // console.log([page * 10 - 1, page * 10]);
    setPageNo([(page - 1) * 10, page * 10]);
    window.scrollTo(0, 0);
  }, [page, upcomingRequest]);
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Upcoming Interviews</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <div className="interviews min-h-screen">
        {/* <div className="flex justify-start items-start m-4 w-auto" style={{color : "red"}}>
          <span>The inbuilt Video Call Application of interwiu.com (where the mock interview takes place) does not work on any of the Apple devices (MacBook, iPad, iPhone) at all. It works on Android phones, but you might face some connection issues. So, if possible, kindly please use <strong>Only a WINDOWS LAPTOP</strong> for taking Mock Interviews or Mentorship Sessions. Also, please use Only <strong>GOOGLE CHROME</strong> Browser</span>
        </div> */}
        <div className="flex justify-center items-center mb-4" id="head">
          <h2 className="interview-head text-2xl font-medium">
            Upcoming Interviews (
            {resultReq
              ? page * 10 -
              9 +
              " - " +
              (getCountForUpcoming(resultReq) > page * 10
                ? page * 10
                : getCountForUpcoming(resultReq))
              : null}
            ) out of ({resultReq ? getCountForUpcoming(resultReq) : 0})
          </h2>
        </div>
        <div className="flex justify-between mx-8 s-mobile-flex-dir-col">
          <div>
            Showing the results for {date ? date.toDateString() : "all days"}
          </div>
          {/*
            <div className="flex gap-2">
              <label htmlFor="date">Filter by Date: </label>
              <DatePicker
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                value={date}
              />
            </div>
          */}

        </div>
        {loaded ? (<div className="mx-auto max-w-[900px]">
          {upcomingRequest?.length !== 0 ? (
            <>
              {upcomingRequest
                ?.slice(pageNo[0], pageNo[1])
                .map(
                  (result, index) =>
                    (moment().tz("Asia/Kolkata").diff(moment(result.date + " " + t24HDecimalTo12HFormat(result.toTime), 'DD/MM/YYYY hh:mm a'), 'seconds')) < 0
                    && (
                      <UpcomingInterview
                        user={user}
                        name={
                          SentStudents[pageNo[0] + index].fname +
                          " " +
                          SentStudents[pageNo[0] + index].lname
                        }
                        branch={SentStudents[pageNo[0] + index].branch}
                        college={SentStudents[pageNo[0] + index].institute}
                        program={SentStudents[pageNo[0] + index].program}
                        year={SentStudents[pageNo[0] + index].year}
                        price="400"
                        profile={upcomingRequest[pageNo[0] + index].profile}
                        subprofile={upcomingRequest[pageNo[0] + index].subprofile}
                        slotStart={upcomingRequest[pageNo[0] + index].fromTime}
                        slotEnd={upcomingRequest[pageNo[0] + index].toTime}
                        status={upcomingRequest[pageNo[0] + index].reqStatus}
                        id={SentStudents[pageNo[0] + index].id}
                        date={upcomingRequest[pageNo[0] + index].date}
                        logo={require("../../images/amazon.png")}
                        resume={SentStudents[pageNo[0] + index].resume}
                        image={SentStudents[pageNo[0] + index].isDisabled === true || SentStudents.isDelete === true ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' : SentStudents[pageNo[0] + index].photo}
                        mockId={upcomingRequest[pageNo[0] + index].mockId}
                        isRescheduled={upcomingRequest[pageNo[0] + index]?.reschedule?.isRescheduled || upcomingRequest[pageNo[0] + index]?.reschedule.reason || upcomingRequest[pageNo[0] + index]?.reschedule.desc || upcomingRequest[pageNo[0] + index]?.reschedule.count === 1}
                        reschedule={upcomingRequest[pageNo[0] + index]?.reschedule}
                        isUnavailable={SentStudents[pageNo[0] + index].isDisabled === true || SentStudents[pageNo[0] + index].isDelete === true}
                        isDisable={SentStudents[pageNo[0] + index].isDisabled === true}
                        isDelete={SentStudents[pageNo[0] + index].isDelete === true}
                        sessDuration={upcomingRequest[pageNo[0] + index].sessDuration}
                        sessType={upcomingRequest[pageNo[0] + index].sessType}
                      />
                    )
                )}
              {/* Pagination */}
              {getCountForUpcoming(upcomingRequest) !== 0 && <div className="flex flex-row justify-center mt-4 my-2">
                <Pagination
                  count={Math.ceil((getCountForUpcoming(upcomingRequest)) / 10)}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </div>}
            </>
          ) : <></>
            /*
            (
              <div className="mt-52 flex flex-col justify-center items-center p-2 text-xl text-blue-600 text-center font-medium">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#265bd1"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={false}
                />
                <p>No interviews yet</p>
              </div>
            )
            */
          }
        </div>) : (<div className="fullPageLoader">
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>)}
      </div>
    </>
  );
};

export default InterviewerUpcoming;
