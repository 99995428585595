import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../constants/variables";
import { BASE } from "../../constants/endpoints";
import UserContext from "../../contexts/UserContext";
import { programValues } from "../../utility/helper";
import Interviewer from "./feedback/Interviewers";
import Students from "./feedback/Students";
import Navbarlogedin from "./Navbarlogedin";

const FeedBack = () => {
  const [value, setValue] = React.useState("1");
  const { user } = useContext(UserContext);
  console.log(user);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Feedback</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <Box
        sx={{
          // mt: 4,
          typography: "body1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "20px",
          paddingBottom: "100px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
          }}
          style={{fontSize: 'clamp(20px,6vw,3rem)', marginTop: 'clamp(20px,-6vw,3rem)', marginBottom: 'clamp(20px,-6vw,3rem)'}}
        >
          Feedback & Comments
        </Typography>
        <TabContext value={value}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="By Interviewers" value="1" />
              <Tab label="By Students" value="2" />
            </TabList>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TabPanel className="s-mobile-padding-tab" value="1">
              <Interviewer />
            </TabPanel>
            <TabPanel className="s-mobile-padding-tab" value="2">
              <Students />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>{" "}
    </>
  );
};

export default FeedBack;
