import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Chip } from "@mui/material";
import axios from "axios";
import { BASE } from "../../../constants/endpoints";
import { convertDateFormat, t24HDecimalTo12HFormat } from "../../../utility/helper";
import StudCardDetails from "components/misc/StudCardDetails";

const AutoRejectedRequest = ({
  user,
  name,
  desg,
  price,
  image,
  profile,
  subprofile,
  slotStart,
  date,
  slotEnd,
  status,
  id,
  company,
  logo,
  sessDuration,
  sessType
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function ImageExist(url) {
    if (url?.includes(".svg")) {
      console.log("svg", url);
      var img = new Image();
      img.src = url;
      console.log(img.height);
      return img?.getBBox?.height !== 0;
    } else {
      console.log(".png", url);
      var img2 = new Image();
      img2.src = url;
      console.log(img2?.getBBox?.height);
      return img2?.height !== 0;
    }
  }
  let imgExist = false;
  return (
    <>
      <div className="relative w-full overflow-hidden bg-white box-shadow-request-card grid grid-cols-4 rounded-lg my-4 p-2 s-desktop-card">
        <div className="absolute bottom-1 right-2 text-right">
          <p className="text-xs text-gray-400">Sent 15 Min ago</p>
        </div>
        <div className="border-r border-gray-400 my-auto col-span-1">
          <img
            src={image}
            className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
            alt="Profile"
          />
          <button
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                "_blank"
              );
            }}
            className="view-profile-card-btn w-4/5 mt-2 mx-auto"
          >
            View Profile
          </button>
        </div>
        <div className="px-3 col-span-3">
          <div className="flex py-1 justify-between w-full">
            <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
              {ImageExist(logo[0]) &&
                imgExist === false &&
                (imgExist = true) && (
                  <img src={logo[0]} alt="logo" className="h-full w-full" />
                )}
              {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                <img src={logo[1]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                <img src={logo[2]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                <img src={logo[3]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist &&
                !ImageExist(logo[0]) &&
                !ImageExist(logo[1]) &&
                !ImageExist(logo[2]) &&
                !ImageExist(logo[3]) &&
                (imgExist = true) && (
                  <img src={logo[4]} alt="logo" className="h-full w-full" />
                )}
            </div>
            <div className="text-center">
              <h4 className="text-2xl font-medium leading-4">{name}</h4>
              <p>
                {" "}
                {desg}, {company}
              </p>
            </div>
            <div className="flex gap-2">
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div>
          </div>
          {/* <div
            className="flex justify-between text-center my-1 gap-x-1 mx-2"
            style={{ alignItems: "flex-start" }}
          >
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "45%"
              
            }}>
              <p className="text-base text-gray-400">Profile</p>
              <Chip
                label={subprofile ? `${subprofile} (${profile})`: profile}
                color="info"
                sx={{ fontWeight: "medium",
                height: "auto",
                display: "block",
                maxWidth: "fit-content",
                px: 0.5,
                '& .MuiChip-label' :{
                  whiteSpace: "pre-wrap",
                  display: "flex",
                  flexWrap: "wrap",
                  px: 0.5,
                  fontSize: {
                    sm: "0.8rem"
                  }
                }
                }}
              />
            </div>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "45%"
              
            }}>
            <div>
              <p className="text-base text-gray-400">Price</p>
              <p className="text-lg">INR {price}</p>
            </div>
            <div>
              <p className="text-base text-gray-400">Session Duration</p>
              <p className="text-lg">30 Min</p>
            </div>
            <div style={{minWidth: "177px"}}>
              <p className="text-base text-gray-400">Interview Slot</p>
              <span className="text-lg">
                {t24HDecimalTo12HFormat(slotStart)}
              </span>
              <span> &nbsp; - &nbsp;</span>
              <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
              <p className="text-lg">{date}</p>
            </div>
            </div>
          </div> */}
          <StudCardDetails 
                subprofile={subprofile}
                profile={profile}
                isRescheduled={"other"}
                reschedule={{
                  status: "",
                  details: null
                }}
                price={price}
                slotEnd={slotEnd}
                slotStart={slotStart}
                date={date}
                sessDuration={sessDuration}
                sessType={sessType}
              />
          <div className="relative flex justify-center">
            <p className="text-red-700 font-medium text-lg border-2 px-2 py-1 rounded">
              <i class="fa-solid fa-xmark"></i> Request Rejected
            </p>
          </div>
        </div>
      </div>


      {/*-------------------- MOBILE CARD -----------------------*/}

      <div
        className="relative overflow-hidden s-card-main s-mobile-card s-mobile-card-shadow"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
      >
        <div className="absolute bottom-1 right-2 text-right">
          <p className="text-xs text-gray-400" style={{ fontSize: '0.65rem' }}>Sent 15 Min ago</p>
        </div>
        <div className="s-profile">
          <div className=" my-auto col-span-1 s-profile-p-p">
            <img
              src={image}
              className="rounded-full border-2 border-black p-1 block mx-auto"
              alt="Profile"
              width={{ width: "100%" }}
            />
            <button
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                "_blank"
              );
            }}
            className="view-profile-card-btn  mt-2 mx-auto view-profile-card-btn-mobile"
          >
            View Profile
          </button>
          </div>
          <div
            className="flex py-1 justify-between w-full s-profile-n-d-c"
            style={{ alignItems: "start" }}
          >
            {/* <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
              {ImageExist(logo[0]) &&
                imgExist === false &&
                (imgExist = true) && (
                  <img src={logo[0]} alt="logo" className="h-full w-full" />
                )}
              {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                <img src={logo[1]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                <img src={logo[2]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                <img src={logo[3]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist &&
                !ImageExist(logo[0]) &&
                !ImageExist(logo[1]) &&
                !ImageExist(logo[2]) &&
                !ImageExist(logo[3]) &&
                (imgExist = true) && (
                  <img src={logo[4]} alt="logo" className="h-full w-full" />
                )}
            </div> */}
            {/* <div className="text-center "> */}
            <h4 className="text-2xl font-medium">{name}</h4>
            <p>{desg}</p>
            <p>{company}</p>
            {/* </div> */}
            {/* <div className="flex gap-2">
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div> */}
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ marginBottom: '8px' }}>
            <div className="s-duration-price" style={{marginBottom:'10px'}}>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Duration : </span>
                <span className="text-lg">{sessDuration} Min</span>
              </div>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Price : </span>
                <span className="text-lg">{price}</span>
              </div>
            </div>

            <div style={{justifyContent: 'flex-start'}} className="s-duration-price">
              <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold', marginRight: '5px' ,transform:'translateY(2px)'}}>Interview Slot : </p>
              <div >
                <span className="text-lg"> {t24HDecimalTo12HFormat(slotStart)}</span>
                <span> - </span>
                <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
                <p className="text-lg">{convertDateFormat(date)}</p>
              </div>
            </div>

            <div className="s-profile-profiles">
              <div className=" col-span-2">
                <div className="text-lg flex flex-wrap gap-1">
                  <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold' }}>Profile : </p>
                  <Chip
                    label={subprofile ? `${subprofile} (${profile})`: profile}
                    color="info"
                    sx={{ fontWeight: "medium" }}
                    className="s-mobile-changing-chip-property"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="" style={{ textAlign: "center" }}>
          <div className="relative flex justify-center">
            <p className="text-red-700 font-medium text-lg border-2 px-2 py-1" style={{ width: '100%' }}>
              <i className="fa-solid fa-check"></i> Request Rejected
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoRejectedRequest;
