import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { special } from "../../constants/branchSpecilization";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment"; // Getting day from date and modifying date format
import toast from "react-hot-toast";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import $ from "jquery"
import {
  reduce_image_file_size,
  Specialization,
  t12HTo24HDecimalFormat,
  t24HDecimalTo12HFormat,
  toastStyle,
} from "../../utility/helper";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import "react-phone-number-input/style.css";
import "./InterviewerProfileForm.css";
import "../Dashboard/cards/Card.css";
import {
  BASE,
  INTERVIEWER_UPDATE_ENDPOINT,
  INTERVIEWERS_GET_ENDPOINT,
} from "../../constants/endpoints";
import {
  INTERVIEWER_RECIEVED_REQUESTS_ROUTE,
  INTERVIEWER_WELCOME_ROUTE,
} from "../../constants/routes";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import axios from "axios";
import LZString from "lz-string";
import FileBase from "react-file-base64";

import {
  Checkbox,
  Divider,
  FormControl,
  FormGroup,
  IconButton,
  TextField,
  Tooltip,
  unstable_createMuiStrictModeTheme,
} from "@mui/material";
import TimePreference from "./TimePreference/TimePreferenceNew";
import UserContext from "../../contexts/UserContext";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ContentPasteOffSharp, Info } from "@mui/icons-material";
// Properties for controlling drop down menu height and width in Select Component
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const InterviewerProfileForm = () => {
  const timeRef = useRef(null);
  const propref = useRef(null);
  const propref2 = useRef(null);
  const { user, setUser } = useContext(UserContext);
  const [isNew, setIsNew] = useState(true);
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  // Form Data
  const [userData, setUserData] = useState({});
  const [photo, setPhoto] = useState(null);
  const [newImage, setnewImage] = useState("");

  const [otherCore, setOtherCore] = useState("");
  const [showCore, setShowCore] = useState(false);
  useEffect(() => {
    const element = propref.current;
    if (element && window.innerWidth < 600) {
      element.style.padding = '5px';
      console.log('ho gaaya bhai', element)

    } else {
      console.log('kismat hi kharab hai')
    }
    const element2 = propref2.current;
    if (element2 && window.innerWidth < 600) {
      element2.style.padding = '5px';
      console.log('ho gaaya bhai2', element2)

    } else {
      console.log('kismat hi kharab hai2')
    }
  }, [])


  useEffect(() => {
    setUserData(user);
    setIsNew(!Boolean(user.designation));
    setLoaded(true);

    window.scrollTo(0, 0);
  }, [location, user]);

  // To set and handle the profile preference to interviewer profile preference
  const [company, setCompany] = useState("");

  // Adding and removing of items in the profiles
  const handlePrefChange = (e) => {
    const profile = e.target.value.slice(-1).slice(-1)[0];
    if (profile)
      setUserData((userData) =>
        userData.profiles.indexOf(profile) === -1
          ? { ...userData, profiles: [...userData.profiles, profile] }
          : userData
      );
  };

  // Adding and removing of companies
  const handleCompChange = () => {
    if (company) {
      setUserData((userData) =>
        userData.prevCompanies.indexOf(company) === -1
          ? { ...userData, prevCompanies: [...userData.prevCompanies, company] }
          : userData
      );
      setCompany("");
    }
  };

  // Form Data
  const [pfpImage, setpfpImage] = useState(null);

  const isGnrcSchdValid = (gnrcSchd) => {
    // return true
    if (!gnrcSchd) return false;
    return gnrcSchd.map((d) => d.sessions.length > 0).includes(true);
  };

  const isCstmSchdValid = (cstmSchd) => {
    return true
    // if (!cstmSchd) return false;
    // return Object.keys(cstmSchd)?.length > 0;
  };

  // useEffect(() => {
  //   // setInputs(user);
  // }, [user]);

  // Handling Change in the form "inputs" state variable
  // To delete selected profiles explicitly from the selection list
  const handleProfilePrefDelete = (label) =>
    setUserData((userData) => ({
      ...userData,
      profiles: userData.profiles.filter((item) => item !== label),
    }));

  // To delete selected companies explicitly from the selection list
  const handleCompaniesDelete = (label) =>
    setUserData((userData) => ({
      ...userData,
      prevCompanies: userData.prevCompanies.filter((item) => item !== label),
    }));

  // Handling Change in the form "userData" state variable
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(name, value);
    // Append the property if doesn't exist and if does then update
    setUserData((userData) => ({ ...userData, [name]: value }));
  };
  const [selection, setSelection] = useState('technical'); // to handle the radio button selection
  const [hr, setHR] = useState([]);

  useEffect(() => {
    if (userData && userData.hrprofiles) {
      setSelection(userData?.hrprofiles?.length === 0 ? 'technical' : 'hr')
      setHR(userData?.hrprofiles?.length === 0 ? [] : userData?.hrprofiles)
    }

  }, [userData.hrprofiles])

  const handleRadioChange = (e) => {
    setSelection(e.target.value);
    if (e.target.value == 'hr') {
      setUserData((userData) => ({
        ...userData,
        coreprofiles: [],
        profiles: [],
        specializations: [],
      }));
    } else {
      setUserData((userData) => ({
        ...userData,
        hrprofiles: [],
      }));
    }
  };
  // Handling Change in the form "userData" state variable
  const handleNumber = (event) => {
    // console.log(event);
    // const value = event.target.value;
    // // Append the property if doesn't exist and if does then update
    // setUserData({ ...userData, [name]: value });
  };

  // const [years, setYears] = useState([]);
  const institutes = [
    "IIT Bhilai",
    "IIT (BHU) Varanasi",
    "IIT Bhubaneswar",
    "IIT Bombay",
    "IIT Delhi",
    "IIT (ISM) Dhanbad",
    "IIT Dharwad",
    "IIT Gandhinagar",
    "IIT Goa",
    "IIT Guwahati",
    "IIT Hyderabad",
    "IIT Indore	",
    "IIT Jammu",
    "IIT Kanpur",
    "IIT Kharagpur",
    "IIT Madras",
    "IIT Mandi",
    "IIT Jodhpur",
    "IIT Palakkad",
    "IIT Patna",
    "IIT Roorkee",
    "IIT Ropar",
    "IIT Tirupati",
  ];

  // Year of Graduated Institute
  const years = [];
  for (let year = 2023; year >= 1955; year--) {
    years.push(year);
  }
  // -------

  // Input fields Error Handling and Disabling
  const [fieldError, setFieldError] = useState({
    gradInsti: false,
    gradInstiManual: false,
    gradInstiManualDisabled: false,
    gradYear: false,
    profiles: false,
    paymentPref: false,
  });

  /*  
    To convert 12 Hour Time format to 24 Hours Decimal format(for ease of processing in backend)
    E.g. 12:15 AM -> 0.25, 01:45 PM -> 13.75
  */

  // Convert Generic Schedule for Backend
  const gnrcSchdForBackend = (schedule) => {
    let result = [];
    for (let key in schedule) {
      let temp = [];
      schedule[key].forEach((item) => {
        temp.push({
          from: t12HTo24HDecimalFormat(item.final[0]),
          to: t12HTo24HDecimalFormat(item.final[1]),
        });
      });
      result.push({ day: key, sessions: temp });
    }
    return result;
  };
  // Convert Generic Schedule for Frontend
  const gnrcSchdForFrontend = (weekSchedule) => {
    let result = {};
    weekSchedule?.map((daySchedule) => {
      result[daySchedule.day] = daySchedule.sessions.map((session) => ({
        // init: [session.from * 2, session.to * 2 - 1],
        final: [
          t24HDecimalTo12HFormat(session.from),
          t24HDecimalTo12HFormat(session.to),
        ],
      }));
    });
    return result;
  };
  // Convert Generic Schedule for Backend
  const reverseGnrcSchdForBackend = (schedule) => {
    let result = [];
    for (let key in schedule) {
      let temp = [];
      schedule[key].forEach((item) => {
        temp.push({
          from: t12HTo24HDecimalFormat(item.final[0]),
          to: t12HTo24HDecimalFormat(item.final[1]),
        });
      });
      result.push({ day: key, sessions: temp });
    }
    return result;
  };
  // Convert Custom Schedule for Backend
  const custSchdForBackend = (schedule) => {
    let result = [];
    Object.values(schedule)?.map((item) => {
      let temp = [];
      item.slots.map((slotItem) => {
        temp.push({
          from: t12HTo24HDecimalFormat(slotItem.final[0]),
          to: t12HTo24HDecimalFormat(slotItem.final[1]),
        });
      });
      result.push({ date: item.date, sessions: temp });
    });
    return result;
  };
  // Convert Custom Schedule for Frontend
  const custSchdForFrontend = (schedule) => {
    let result = [];
    schedule?.map((item) => {
      let temp = [];
      item.sessions.map((slotItem) => {
        temp.push({
          // init: [slotItem.from * 2, slotItem.to * 2 - 1],
          final: [
            t24HDecimalTo12HFormat(slotItem.from),
            t24HDecimalTo12HFormat(slotItem.to),
          ],
        });
      });
      result.push({ date: item.date, slots: temp });
    });
    return result;
  };
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState();

  const handleOpen = (value) => {
    setOpen(true);
    setCurrent(value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [state2, setState2] = React.useState({
    core: user.coreprofiles.length > 0,
    nonCore: user.profiles.length > 0,
  });
  const { core, nonCore } = state2;
  const handleChangeCheckBox2 = (event) => {
    setState2({
      ...state2,
      [event.target.name]: event.target.checked,
    });
  };
  const handleNonCorePrefChange = (e) => {
    const noncoreprofile = e.target.value.slice(-1).slice(-1)[0];
    if (noncoreprofile)
      setUserData((userData) =>
        userData.profiles.indexOf(noncoreprofile) === -1
          ? {
            ...userData,
            profiles: [...userData.profiles, noncoreprofile],
          }
          : userData
      );
  };
  const handleNonCoreProfilePrefDelete = (label) =>
    setUserData((userData) => ({
      ...userData,
      profiles: userData.profiles.filter((item) => item !== label),
    }));
  const handleCorePrefChange = (e) => {
    const coreprofile = e.target.value;

    if (coreprofile === "Other") {
      return;
    }
    console.log(e.target.value, userData.coreprofiles, userData.specializations)
    if (coreprofile)
      setUserData((prev) => ({
        ...prev,
        coreprofiles: coreprofile
      }))
  };
  function findItem(item) {
    return item.profile === current;
  }
  const handleCoreProfilePrefDelete = (label) => {
    let arr = [...userData.specializations];

    arr = arr.filter((item) => item.profile !== label);

    return setUserData((userData) => ({
      ...userData,
      coreprofiles: userData.coreprofiles.filter((item) => item !== label),
      specializations: [...arr],
    }));
  };

  const handleSpecializationPrefChange = (e) => {
    const specialization = e.target.value.subdomain;
    const profile = e.target.value.profile;
    console.log(userData.specializations, profile, specialization);

    if (e.target.checked) {
      let arr = [...userData.specializations];

      let k = false;

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].profile === profile) {
          k = true;
          arr[i]["subDomains"].push(specialization);
          break;
        }
      }

      if (!k) {
        arr.push({ profile: profile, subDomains: [specialization] });
      }

      setUserData((userData) => ({
        ...userData,
        specializations: [...arr],
      }));
    } else {
      let arr = [...userData.specializations];

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].profile === profile) {
          arr[i]["subDomains"] = arr[i]["subDomains"].filter(
            (item) => item !== specialization
          );
          break;
        }
      }
      setUserData((userData) => ({
        ...userData,
        specializations: [...arr],
      }));
    }

    // const specialization = e.target.value;
    // if (specialization) {
    //   const i = userData.specializations.findIndex(findItem);
    //   console.log(i);
    //   if (i !== -1) {
    //     if (
    //       userData.specializations[i].subDomains.indexOf(specialization) === -1
    //     ) {
    //       let s = userData.specializations[i];
    //       let ans = {
    //         profile: current,
    //         subDomains: [...s.subDomains, specialization],
    //       };
    //       setUserData((userData) => ({
    //         ...userData,
    //         specializations: userData.specializations.filter(
    //           (item) => item.profile !== current
    //         ),
    //       }));
    //       setUserData(
    //         (userData) => ({
    //           ...userData,
    //           specializations: [...userData.specializations, ans],
    //         })
    //         // : userData
    //       );
    //     }
    //   } else {
    //     setUserData((userData) => ({
    //       ...userData,
    //       specializations: [
    //         ...userData.specializations,
    //         { profile: current, subDomains: [specialization] },
    //       ],
    //     }));
    //   }
    //   console.log(userData);
    // } else {
    //   console.log("none");
    // }
    // setUserData((userData) =>
    //   userData.specializations.indexOf(specialization) === -1
    //     ? {
    //         ...userData,
    //         specializations: [...userData.specializations, specialization],
    //       }
    //     : userData
    // );
  };
  const handleSpecializationProfilePrefDelete = (label) =>
    setUserData((userData) => ({
      ...userData,
      specializations: userData.specializations.filter(
        (item) => item !== label
      ),
    }));
  const [checked, setChecked] = useState();

  // const handleChangeRadio = (event) => {
  //   console.log(checked);
  //   setChecked(event.target.value);
  // };

  const checkSubdomain = (ele, profile) => {
    for (let i = 0; i < userData.specializations.length; i++) {
      if (userData.specializations[i].profile === profile) {
        if (userData.specializations[i].subDomains.indexOf(ele) !== -1) {
          return true;
        }
      }
    }
  };
  // const [finalGnrcSchd, setFinalGnrcSchd] = useState({}); // Final generic schedule that we will get from Add Time Preference
  // const [finalCustSchd, setFinalCustSchd] = useState({}); // Final custom schedule that we will get from Add Time Preference

  // useEffect(() => {
  //   console.log(
  //     "finalGnrcSchd: ",
  //     finalGnrcSchd,
  //     " finalCustSchd: ",
  //     finalCustSchd
  //   );
  //   console.log(
  //     "finalGnrcSchd: ",
  //     gnrcSchdForBackend(finalGnrcSchd),
  //     " finalCustSchd: ",
  //     custSchdForBackend(finalCustSchd)
  //   );
  // }, [finalGnrcSchd, finalCustSchd]);

  // HandleSubmit
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(userData);

    if (userData.coreprofiles.length === 0 && userData.profiles.length === 0 && userData.hrprofiles.length === 0) {
      const box = document.getElementById("profilepreference");
      box.style.border = "1px solid red";
      // box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else {
      const box = document.getElementById("profilepreference");
      box.style.border = "none";
    }

    // Checking all the fields to be filled and should follow the rules
    if (!userData.fname) {
      const box = document.getElementById("fname");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    }
    if (!userData.lname) {
      const box = document.getElementById("lname");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    }
    if (
      !(userData.mobNumber && isValidPhoneNumber(String(userData.mobNumber)))
    ) {
      const box = document.getElementById("mobNum");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("mobNum").style.borderColor = "#e5e7eb";

    if (!userData.designation) {
      const box = document.getElementById("designation");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("designation").style.borderColor = "#e5e7eb";

    if (!userData.currCompany) {
      const box = document.getElementById("currCompany");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("currCompany").style.borderColor = "#e5e7eb";

    // if (!userData.location) {
    //   const box = document.getElementById("location");
    //   box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // } else document.getElementById("location").style.borderColor = "#e5e7eb";

    if (!userData.gradInsti) {
      setFieldError({ ...fieldError, gradInsti: true });
      const box = document.getElementById("gradInsti");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("gradInsti").style.borderColor = "#e5e7eb";

    if (userData.gradInsti === "manual" && !userData.manualInst) {
      setFieldError({ ...fieldError, gradInstiManual: true });
      const box = document.getElementById("manualInst");
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    }

    if (!userData.gradYear) {
      setFieldError({ ...fieldError, gradYear: true });
      const box = document.getElementById("gradYear");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("gradYear").style.borderColor = "#e5e7eb";

    // if (userData.profiles?.length === 0) {
    //   setFieldError({ ...fieldError, profiles: true });
    //   const box = document.getElementById("profile");
    //   box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // } else document.getElementById("profile").style.borderColor = "#e5e7eb";

    if (!userData.paymentPref) {
      setFieldError({ ...fieldError, paymentPref: true });
      const box = document.getElementById("paymentPrefLabel");
      box.style.color = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("paymentPref").style.borderColor = "#e5e7eb";

    if (userData.paymentPref === "self") {
      if (!userData.accName) {
        setFieldError({ ...fieldError, accName: true });
        const box = document.getElementById("accName");
        box.style.borderColor = "#FF0000";
        window.scrollTo(0, box.offsetTop);
        return box.focus();
      } else document.getElementById("accNo").style.borderColor = "#e5e7eb";

      if (!userData.accNo) {
        setFieldError({ ...fieldError, accNo: true });
        const box = document.getElementById("accNo");
        box.style.borderColor = "#FF0000";
        window.scrollTo(0, box.offsetTop);
        return box.focus();
      } else document.getElementById("accNo").style.borderColor = "#e5e7eb";

      if (!userData.ifsc) {
        setFieldError({ ...fieldError, ifsc: true });
        const box = document.getElementById("ifsc");
        box.style.borderColor = "#FF0000";
        window.scrollTo(0, box.offsetTop);
        return box.focus();
      } else document.getElementById("ifsc").style.borderColor = "#e5e7eb";

      if (!userData.bankName) {
        setFieldError({ ...fieldError, bankName: true });
        const box = document.getElementById("bankName");
        box.style.borderColor = "#FF0000";
        window.scrollTo(0, box.offsetTop);
        return box.focus();
      } else document.getElementById("bankName").style.borderColor = "#e5e7eb";

      if (!userData.branchCode) {
        setFieldError({ ...fieldError, branchCode: true });
        const box = document.getElementById("branchCode");
        box.style.borderColor = "#FF0000";
        window.scrollTo(0, box.offsetTop);
        return box.focus();
      } else
        document.getElementById("branchCode").style.borderColor = "#e5e7eb";
      if (!userData.upiId) {
        setFieldError({ ...fieldError, upiId: true });
        const box = document.getElementById("upiId");
        box.style.borderColor = "#FF0000";
        window.scrollTo(0, box.offsetTop);
        return box.focus();
      } else
        document.getElementById("upiId").style.borderColor = "#e5e7eb";
    }

    // if (!photo) {
    //   const box = document.getElementById("photo");
    //   box.style.color = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return
    // } else document.getElementById("linkedin").style.color = "black";

    if (!userData.linkedInURL) {
      const box = document.getElementById("linkedin");
      box.style.borderColor = "#FF0000";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else document.getElementById("linkedin").style.borderColor = "#e5e7eb";

    // getting generic schedule
    const finalGnrcSchd = JSON.parse(
      localStorage.getItem("interwiu.com")
    )?.gnrcSchdule;
    const finalCstmSchd = JSON.parse(
      localStorage.getItem("interwiu.com")
    )?.cstmSchdule;
    console.log(finalCstmSchd, "finalcst")
    if (newImage) {
      userData.photo = newImage;
    }
    console.log(userData);
    if (!isGnrcSchdValid(finalGnrcSchd) || !isCstmSchdValid(finalCstmSchd)) {
      if (timeRef.current) {
        timeRef.current.style.display = "inline";
        window.scrollTo(0, timeRef.current.offsetTop - 100);
      }
      return;
    } else {
      if (timeRef.current) timeRef.current.style.display = "none";
    }
    axios
      .patch(INTERVIEWER_UPDATE_ENDPOINT + "/" + user._id, {
        ...userData,
        GnrcSchedule: finalGnrcSchd
          ? finalGnrcSchd
          : userData.GnrcSchedule,
        CustomSchedule: finalCstmSchd
          ? finalCstmSchd
          : userData.CustomSchedule,
        isNew,
      })
      .then((response) => {
        setUser(userData);
        const localData = JSON.parse(localStorage.getItem("interwiu.com"));
        if (localData) {
          delete localData.gnrcSchdule;
          delete localData.cstmSchdule;
          localStorage.setItem("interwiu.com", JSON.stringify(localData));
        }
        navigate(
          isNew
            ? INTERVIEWER_WELCOME_ROUTE
            : INTERVIEWER_RECIEVED_REQUESTS_ROUTE
        );
        window.location.reload(true);
      })
      .catch((err) => {
        const localData = JSON.parse(localStorage.getItem("interwiu.com"));
        if (localData) {
          delete localData.gnrcSchdule;
          delete localData.cstmSchdule;
          localStorage.setItem("interwiu.com", JSON.stringify(localData));
        }
        console.log(err);
      });
  };

  // Thanks Greeting for Payment method when selected to Alumin Association
  const styleOfGreeting = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  var ImageUrl = `${user.photo}`;
  return (
    <>
      <div className="profile-form bg-white py-1">
        <Typography
          variant="h4"
          component="h4"
          sx={{ fontWeight: "bold", color: "#29ab87", padding: "15px 20px", fontSize: '22px' }}
        >
          {!user?.designation
            ? "Create an Account as Professional"
            : "Edit Profile"}
        </Typography>
        {loaded ? (
          <form onSubmit={handleSubmit}>
            {/* Profile Form Container */}
            <Modal open={open} onClose={handleClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 24,
                  overflow: "hidden",
                  minWidth: "750px",
                }}
              >
                <div className="flex flex-row justify-between gap-2 bg-blue-500 p-2">
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      mx: 2,
                    }}
                  >
                    Choose Specialization
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CancelIcon sx={{ color: "white" }} />
                  </IconButton>
                </div>
                <p className="pl-3 pt-2">
                  {" "}
                  If you do not have any specialization (for example, if you are
                  a BE/B.Tech student), then you can just ignore and close this
                  pop-up box
                </p>
                <FormGroup>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    className="pl-3"
                  >
                    {Specialization[`${current}`]?.map((res, index) => {
                      return (
                        <p>
                          <FormControlLabel
                            value={res}
                            control={<Radio />}
                            // onChange={handleChangeRadio}
                            label={res}
                          // checked={payment === 1 || payment === "1"}
                          />
                        </p>
                      );
                    })}
                  </RadioGroup>
                </FormGroup>
                <div style={{ textAlign: "center" }} className="pb-3">
                  <Button
                    sx={{ textAlign: "center" }}
                    variant="contained"
                    color="success"
                    onClick={() => {
                      let s = { target: { value: checked } };
                      // handleSpecializationPrefChange(s);
                      handleClose();
                    }}
                  >
                    Save
                  </Button>
                </div>
                {/* </div> */}
              </Box>
            </Modal>
            <div className="profile-form-cont mb-[20px]">
              {/* Col-70 */}

              <div className="profile-form-text">

                {/* mobile profile photo */}


                <div className="profile-photo border rounded-md p-2 profile-form-cont-3-s flex-col">
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                      fontSize: '16px',
                      color: '#095a54',
                    }}
                  >
                    Profile Photo
                  </Typography>
                  <img
                    src={ImageUrl || user.photo}
                    className="upload"
                    alt="Upload"
                    style={{ width: '100px', borderRadius: '100px', marginBottom: '0.5rem' }}
                  />
                  <div style={{ transform: 'scale(0.9) translateX(-10px)' }} className="filebase">
                    <FileBase

                      type="file"
                      multiple={false}
                      id="image"
                      onDone={async ({ base64 }) => {
                        setnewImage(await reduce_image_file_size(base64));
                      }}
                    />
                  </div>

                </div>
                {/* First Name and Last Name */}

                <div className="name cust-row flex  max-[600px]:flex-wrap">
                  <div className="element" style={{ width: "auto" }}>
                    <label htmlFor="title">
                      Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      name="title"
                      id="title"
                      // error={errs.title}
                      value={userData.title || ""}
                      onChange={handleChange}
                      autoWidth
                      sx={{ padding: "0px", width: '100%' }}
                    >
                      <MenuItem value="mr">Mr.</MenuItem>
                      <MenuItem value="mrs">Ms.</MenuItem>
                      <MenuItem value="dr">Dr.</MenuItem>
                      <MenuItem value="prof">Prof.</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </div>
                  <div className="element">
                    <label htmlFor="firstname">
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    {/* <TextField id="fname" variant="outlined" /> */}
                    <input
                      type="text"
                      name="fname"
                      id="fname"
                      className="border-2 rounded"
                      value={userData.fname}
                      onChange={(e) => {
                        e.target.style.borderColor = "#e5e7eb";
                        handleChange(e);
                      }}
                      placeholder="First Name"
                      style={{}}
                    />
                  </div>
                  <div className="element">
                    <label htmlFor="lastname">
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="lname"
                      id="lname"
                      className="border-2 rounded"
                      value={userData.lname}
                      onChange={(e) => {
                        e.target.style.borderColor = "#e5e7eb";
                        handleChange(e);
                      }}
                      placeholder="Last Name"
                      style={{}}
                    />
                  </div>
                </div>

                {/* Email and Mobile Number */}

                <div className="email cust-row flex   max-[600px]:flex-wrap">
                  <div className="element">
                    <label htmlFor="email">
                      Email (only Gmail)<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email-field"
                      className="border-2 rounded"
                      value={userData.email}
                      disabled
                      placeholder="Email"
                      style={{}}
                    />
                  </div>
                  <div className="element">
                    <label htmlFor="number">
                      WhatsApp Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="border-2 rounded px-3" id="mobNum">
                      <PhoneInput
                        // international
                        // countryCallingCodeEditable={false}
                        name="mobNumber"
                        focusInputOnCountrySelection
                        value={
                          userData.mobNumber
                            ? String(userData.mobNumber)
                            : "+91"
                        }
                        onChange={(number) =>
                          setUserData((userData) => ({
                            ...userData,
                            mobNumber: number,
                          }))
                        }
                        error={
                          userData.mobNumber
                            ? isValidPhoneNumber(String(userData.mobNumber))
                              ? undefined
                              : "Invalid phone number"
                            : "Ph"
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* Company and Organisation */}
                <div className="email cust-row flex   max-[600px]:flex-wrap">
                  <div className="element">
                    <label htmlFor="desg">
                      Current Designation<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="designation"
                      id="designation"
                      className="border-2 rounded"
                      value={userData.designation}
                      onChange={(e) => {
                        e.target.style.borderColor = "#e5e7eb";
                        handleChange(e);
                      }}
                      placeholder="Position"
                      style={{}}
                    />
                  </div>
                  <div className="element">
                    <label htmlFor="org">
                      Current Company/Organization
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="currCompany"
                      id="currCompany"
                      className="border-2 rounded"
                      value={userData.currCompany}
                      onChange={(e) => {
                        e.target.style.borderColor = "#e5e7eb";
                        handleChange(e);
                      }}
                      placeholder="Company/Organization"
                      autoComplete="nope"
                      style={{}}
                    />
                  </div>
                </div>

                {/* Past Companies */}
                <div className="profilepref cust-row flex">
                  <div className="element">
                    <label htmlFor="prevCompanies">
                      Past Companies/Organizations
                    </label>
                    <div className="flex max-[600px]:flex-col">
                      <div className="flex-row interviewerProfileFormPrevCompInput flex ">
                        <input
                          type="text"
                          name="companies"
                          id="companies"
                          className="border-2 rounded"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          placeholder="Past Company/Organization"
                          autoComplete="nope"
                          style={{
                            maxWidth: "355px",
                          }}
                        />
                        <Button
                          variant="contained"
                          style={{ transform: 'scale(0.8)' }}
                          onClick={() => handleCompChange()}
                        >
                          <AddRoundedIcon />
                        </Button>
                      </div>
                      <div className="profile-pref-list rounded p-2">
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {userData.prevCompanies?.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              onDelete={() => {
                                handleCompaniesDelete(value);
                              }}
                            />
                          ))}
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Graduated From */}
                <div
                  className="branch cust-row flex   max-[600px]:flex-wrap"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="element">
                    {/* <label htmlFor="institute">
                    Graduated from
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    value={userData.gradInsti || ""}
                    name="gradInsti"
                    id="gradInsti"
                    error={fieldError.gradInsti}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError({ ...fieldError, gradInsti: false });
                    }}
                    displayEmpty
                    MenuProps={MenuProps}
                    sx={{ width: 1 }}
                    inputProps={{ "aria-label": "Without label" }}
                    style={{ textOverflow: "ellipsis" }}
                    defaultValue=""
                  >
                    <MenuItem hidden disabled value="">
                      Select Institute
                    </MenuItem>
                    <MenuItem value="manual">
                      <p className="text-blue-500 italic">
                        Enter Institute Manually
                      </p>
                    </MenuItem>
                    {institutes?.map((item) => {
                      return (
                        <MenuItem key={`grad${item}`} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {userData.gradInsti === "manual" ? (
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        width: 1,
                        gap: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "4px 0px",
                        marginTop: "4px",
                      }}
                    >
                      <OutlinedInput
                        placeholder="Enter Institute Here"
                        id="manualInst"
                        disabled={fieldError.gradInstiManualDisabled}
                        error={fieldError.gradInstiManual}
                        value={userData?.manualInst}
                        onChange={(e) => {
                          // console.log(e.target.value);
                          setUserData((userData) => ({
                            ...userData,
                            manualInst: e.target.value,
                          }));
                          setFieldError({
                            ...fieldError,
                            gradInstiManual: false,
                          });
                        }}
                      />
                    </Box>
                  ) : null} */}
                    <div
                      className="branch cust-row flex"
                      style={{ margin: 0, padding: 0 }}
                    >
                      <div className="element w-50">
                        <label htmlFor="institute">
                          Graduated from
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          name="gradInsti"
                          id="gradInsti"
                          className="border-2 rounded"
                          placeholder="Institute"
                          style={{}}
                          displayEmpty
                          error={fieldError.gradInsti}
                          value={userData.gradInsti || ""}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldError({ ...fieldError, gradInsti: false });
                          }}
                          autoComplete="nope"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="element">
                    <label htmlFor="year">
                      Graduated in
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      name="gradYear"
                      id="gradYear"
                      value={Number(userData.gradYear)}
                      error={fieldError.gradYear}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldError({ ...fieldError, gradYear: false });
                      }}
                      displayEmpty
                      MenuProps={MenuProps}
                      sx={{ width: 1 }}
                      inputProps={{ "aria-label": "Without label" }}
                      style={{ textOverflow: "ellipsis" }}
                      defaultValue=""
                    >
                      <MenuItem hidden disabled value="">
                        Select Year
                      </MenuItem>
                      {years?.map((year) => {
                        return (
                          <MenuItem key={`grad${year}`} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                {/* Profile Preference */}
                {/* Profile Preference */}
              </div>

              {/* Col-30 */}

              <div className="s-rectify-margin profile-form-profile s-desktop-card s-resume-950" style={{ margin: '0px !important' }}>
                {/* Profile Photo */}

                <div className="profile-photo border rounded-md p-2">
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                    }}
                  >
                    Profile Photo
                  </Typography>
                  <img
                    src={ImageUrl || user.photo}
                    className="upload"
                    alt="Upload"
                  />
                  <div className="filebase">
                    <FileBase
                      type="file"
                      multiple={false}
                      id="image"
                      onDone={async ({ base64 }) => {
                        setnewImage(await reduce_image_file_size(base64));
                      }}
                    />
                  </div>
                  {/* <input
                  type="file"
                  name="photo"
                  id="photo"
                  className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                  value={""}
                  accept="image/png, image/jpg, image/jpeg, image/url"
                  onChange={(e) => {
                    const value = e.target.files[0];
                    // setUserData({ ...userData, photo: value });
                    setPhoto(value);
                    // console.log(value);
                    var image = document.querySelector(".upload");
                    image.src = URL.createObjectURL(value);
                    // document.getElementById("photo").value = value;
                  }}
                /> */}
                </div>
                {/* <button onClick={() => {}}>
                Upload
              </button> */}
                <div className="my-3 p-2 border rounded-md text-left">
                  <Typography
                    variant="h5"
                    component="h5"
                    align="center"
                    style={{
                      margin: "10px",
                      fontFamily: "'Ubuntu', sans-serif",
                    }}
                  >
                    Social Links
                  </Typography>
                  <div className="my-3">
                    <label htmlFor="linkedin" className="flex justify-between">
                      <span><i className="fa-brands fa-linkedin"></i> LinkedIn
                        <span style={{ color: "red" }}>*</span></span>
                      <div className="tt px-auto pb-1">
                        <InfoOutlined sx={{ color: 'rgb(83, 81, 81)', fontSize: '20px', }} />
                        <span className="tttext -translate-y-12">{`Please open LinkedIn in browser, go to your profile page 
                        by clicking on [View Profile] option under [Me] button, and copy-paste 
                        the exact Profile URL from the respective tab (containing https://www.linkedin.com/in/...) `}
                        </span>
                      </div>
                    </label>
                    <input
                      type="url"
                      name="linkedInURL"
                      id="linkedin"
                      value={userData.linkedInURL}
                      onChange={(e) => {
                        e.target.style.borderColor = "#e5e7eb";
                        handleChange(e);
                      }}
                      className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      placeholder="LinkedIn URL"
                    />
                  </div>
                  <div className="my-3">
                    <label htmlFor="github">
                      <i className="fa-brands fa-github"></i> GitHub
                    </label>
                    <input
                      type="url"
                      name="githubURL"
                      id="githubURL"
                      value={userData.githubURL}
                      onChange={(e) => {
                        e.target.style.borderColor = "#e5e7eb";
                        handleChange(e);
                      }}
                      className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      placeholder="GitHub URL"
                    />
                  </div>
                  <div className="my-3">
                    <label htmlFor="twitter">
                      <i className="fa-brands fa-twitter"></i> Twitter
                    </label>
                    <input
                      type="url"
                      name="twitterURL"
                      id="twitter"
                      value={userData.twitterURL}
                      onChange={(e) => {
                        e.target.style.borderColor = "#e5e7eb";
                        handleChange(e);
                      }}
                      className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      placeholder="Twitter URL"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Add Time Preference */}
            {/* Profile preference */}
            <Divider style={{ width: "95%", margin: "auto" }} />

            <div className="flex m-[22px] flex-col max-[600px]:m-[10px]">
              <div className="profilepref cust-row flex">
                <div className="element">
                  <label htmlFor="profilepreference">
                    Profile Preferences<span style={{ color: "red" }}>*</span>
                  </label>
                  {/* <FormControl
                    sx={{
                      m: 3,
                      mt: 0,
                      ml: 0,
                      display: "flex",
                      justifyContent: "space",
                    }}
                    id="profilepreference"
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={nonCore}
                            onChange={handleChangeCheckBox2}
                            name="nonCore"
                          />
                        }
                        sx={{ width: "50%" }}
                        label="Non Core"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={core}
                            onChange={handleChangeCheckBox2}
                            name="core"
                          />
                        }
                        label="Core"
                      />
                    </FormGroup>
                  </FormControl> */}
                  <RadioGroup
                    row
                    aria-label="selection"
                    name="row-radio-buttons-group"
                    value={selection}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel value="technical" control={<Radio />} label="Technical (Core/Non-core)" />
                    <FormControlLabel value="hr" control={<Radio />} label="HR" />
                  </RadioGroup>

                  {selection === 'hr' && (
                    <>
                      <div>
                        <Select
                          multiple
                          value={hr}
                          onChange={(e) => {
                            setHR(e.target.value);
                            userData.hrprofiles = e.target.value;
                          }}
                          displayEmpty
                          renderValue={(selected) => selected.length > 0 ? selected.join(', ') : "Select HR Options"}
                          sx={{ width: '100%' }}
                        >
                          <MenuItem value="Human Resources">Human Resources</MenuItem>
                          <MenuItem value="Recruitment">Recruitment</MenuItem>
                          <MenuItem value="Employee Relations">Employee Relations</MenuItem>
                          <MenuItem value="Compensation and Benefits">Compensation and Benefits</MenuItem>
                          <MenuItem value="Learning and Development">Learning and Development</MenuItem>
                          <MenuItem value="HR Analytics">HR Analytics</MenuItem>
                          <MenuItem value="Talent Management">Talent Management</MenuItem>
                          <MenuItem value="Diversity and Inclusion">Diversity and Inclusion</MenuItem>

                        </Select>
                      </div>
                      <div className="profile-pref-list rounded p-2 pt-2 max-[600px]:p-0 max-[600px]:pt-2">
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                            minHeight: "0",
                            alignItems: 'stretch'
                          }}
                        >
                          {userData.hrprofiles.map((value) => (
                            <div
                              key={value}
                              className="relative flex justify-between text-md rounded-full border p-1 mb-2 mx-auto text-white blue-mobile-color"
                              style={{
                                backgroundColor: "#ebebeb",
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "14px",
                                borderRadius: '10px',
                                width: '100%'
                              }}
                            >
                              <div className="mx-1 text-black">
                                {value} &nbsp;
                              </div>
                              <div
                                className="mx-1 cursor-pointer"
                                onClick={() => setHR(hr.filter((item) => item !== value))}
                              >
                                <i
                                  className="fa fa-times-circle text-gray-400 hover:text-gray-600"
                                  style={{ fontSize: '16px' }}
                                  id="iconCross"
                                ></i>
                              </div>
                            </div>
                          ))}
                        </Box>
                      </div>
                    </>
                  )}

                  <div style={{ display: selection !== 'hr' ? "flex" : "none" }}>
                    <div style={{ flex: 1, paddingRight: '2%', borderRight: '1px solid rgba(0,0,0,0.3)', width: '10px' }}>

                      <FormControl
                        sx={{
                          m: 3,
                          mt: 0,
                          ml: 0,
                          display: "flex",
                          justifyContent: "space",
                        }}
                        id="profilepreference"
                        component="fieldset"
                        variant="standard"
                      ><FormControlLabel
                          control={
                            <Checkbox
                              checked={nonCore}
                              onChange={(e) => {
                                handleChangeCheckBox2(e);
                                if (!e.target.checked) {
                                  setUserData((userData) => ({
                                    ...userData,
                                    profiles: [],
                                  }))
                                }
                              }}
                              name="nonCore"
                            />
                          }

                          label="Non Core"
                        /></FormControl>
                      <div
                        className="flex"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          minWidth: "90%",
                        }}
                      >
                        {nonCore && (
                          <>
                            <div className="flex w-full justify-end cursor-pointer">
                              <p
                                className="underline text-blue-500 s-mobile-text-14"
                                onClick={(e) => {
                                  setUserData((userData) => ({
                                    ...userData,
                                    profiles: [],
                                  }));
                                }}
                              >
                                clear
                              </p>
                            </div>
                            <Select
                              multiple
                              displayEmpty
                              id="profilesPref"
                              ref={propref2}
                              value={userData.profiles || []}
                              sx={{ width: 1 }}
                              renderValue={() => "Select Preferences"}
                              // error={fieldError.profilesPref}
                              onChange={(e) => {
                                handleNonCorePrefChange(e);
                                // setFieldError({ ...fieldError, profilesPref: false });
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="Data Science">
                                Data Science
                              </MenuItem>
                              <MenuItem value="Software">Software</MenuItem>
                              <MenuItem value="Banking and Finance">
                                Banking and Finance
                              </MenuItem>
                              <MenuItem value="Consulting">Consulting</MenuItem>
                              <MenuItem value="Analytics">Analytics</MenuItem>
                              <MenuItem value="Quant">Quant</MenuItem>
                              <MenuItem value="Product Management">
                                Product Management
                              </MenuItem>
                              <MenuItem value="Operations">Operations</MenuItem>
                              <MenuItem value="Supply Chain">
                                Supply Chain
                              </MenuItem>
                              <MenuItem value="FMCG">FMCG</MenuItem>
                              <MenuItem value="Operations Research">
                                Operations Research
                              </MenuItem>
                              <MenuItem value="Sales">Sales</MenuItem>
                              <MenuItem value="Inventory Management">
                                Inventory Management
                              </MenuItem>
                              <MenuItem value="Logistics">Logistics</MenuItem>
                            </Select>
                            <div className="profile-pref-list rounded p-2 pt-2 max-[600px]:p-0 max-[600px]:pt-2">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                  minHeight: "0",
                                  alignItems: 'stretch'
                                }}
                              >
                                {userData.profiles?.map((value) => (
                                  <div
                                    className="relative flex justify-between text-md rounded-full border p-1 mb-2 mx-auto text-white blue-mobile-color"
                                    style={{
                                      // width: "0px",
                                      backgroundColor: "#ebebeb",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontSize: "14px",
                                      borderRadius: '10px',
                                      width: '100%'
                                    }}
                                  >
                                    <div className="mx-1 text-black">
                                      {value} &nbsp;
                                    </div>
                                    <div
                                      className="mx-1 cursor-pointer "
                                      onClick={() =>
                                        handleNonCoreProfilePrefDelete(value)
                                      }
                                    >
                                      <i
                                        className="fa fa-times-circle text-gray-400 hover:text-gray-600"
                                        style={{ fontSize: '16px' }}
                                        id="iconCross"
                                      // style={{ color: "#aeaeae" }}
                                      ></i>
                                    </div>
                                  </div>
                                ))}
                              </Box>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div style={{ flex: 1, paddingLeft: '2%', borderLeft: '1px solid rgba(0,0,0,0.3)', width: '10px' }}>
                      <FormControl
                        sx={{
                          m: 3,
                          mt: 0,
                          ml: 0,
                          display: "flex",
                          justifyContent: "space",
                        }}
                        id="profilepreference"
                        component="fieldset"
                        variant="standard"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={core}
                              onChange={(e) => {
                                handleChangeCheckBox2(e)
                                if (!e.target.checked) {
                                  setUserData((userData) => ({
                                    ...userData,
                                    coreprofiles: [],
                                    specializations: [],
                                  }))
                                }
                              }}
                              name="core"
                            />
                          }
                          label="Core"
                        />
                      </FormControl>
                      <div
                        className="flex"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: '100%'
                        }}
                      >
                        {core && (
                          <>
                            <div className="flex w-full justify-end cursor-pointer">
                              <p
                                className="underline text-blue-500 s-mobile-text-14"
                                onClick={(e) => {
                                  setUserData((userData) => ({
                                    ...userData,
                                    coreprofiles: [],
                                    specializations: [],
                                  }));
                                }}
                              >
                                clear
                              </p>
                            </div>
                            <Select
                              multiple
                              displayEmpty
                              id="profilesPref"
                              ref={propref}
                              value={userData.coreprofiles || []}
                              sx={{ width: 1 }}
                              renderValue={() => "Select Preferences"}
                              // error={fieldError.profilesPref}
                              onChange={(e) => {
                                handleCorePrefChange(e);
                                // setFieldError({ ...fieldError, profilesPref: false });

                                if (e.target.value == "Other") {
                                  setShowCore(true);
                                } else {
                                  setShowCore(false);
                                }
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              {Object.keys(special).map((key) => (
                                <MenuItem value={key}>{key}</MenuItem>
                              ))}
                              {/* <MenuItem value="Other">Other</MenuItem> */}
                            </Select>
                            {showCore && (
                              <div className="flex items-center">
                                <input
                                  type="text"
                                  name="othercore"
                                  className="border-2 rounded"
                                  placeholder="Other Core Specialization"
                                  style={{

                                    marginRight: "10px",
                                    width: "250px",
                                    overflow: "scroll",
                                  }}
                                  value={otherCore}
                                  onChange={(e) => {
                                    setOtherCore(e.target.value);
                                  }}
                                  autoComplete="nope"
                                />
                                <p
                                  name="othercore"
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    color: "skyblue",
                                  }}
                                  onClick={(e) => {
                                    handleCorePrefChange({
                                      target: {
                                        name: "coreprofiles",
                                        value: otherCore,
                                      },
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCheck} />
                                </p>
                                <p
                                  name="othercore"
                                  value=""
                                  style={{
                                    marginRight: "10px",
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    setOtherCore("");
                                    handleCorePrefChange({
                                      target: {
                                        name: "coreprofiles",
                                        value: "",
                                      },
                                    });
                                    setShowCore(false);
                                    document.querySelector(
                                      "input[name=othercore]"
                                    ).disabled = false;
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </p>
                              </div>
                            )}
                            <div className="profile-pref-list pt-2 flex flex-row">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  // gap: 0.5,
                                  minHeight: "0",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  width: '100%'
                                }}
                              >
                                {userData.coreprofiles?.map((val, index) => (
                                  <div className="flex w-full items-stretch">
                                    <div className="w-full">
                                      <Button
                                        className="relative flex justify-between text-md rounded-full border p-1 mx-auto text-white blue-mobile-color"
                                        style={{
                                          // width: "0px",
                                          backgroundColor: "#ebebeb",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          fontSize: "14px",
                                          marginBottom: "10px",
                                          marginTop: `${index == 0 ? '0rem' : '20px'}`,
                                          width: '100%'
                                        }}
                                      >
                                        <div className="mx-1 text-black">
                                          {val}
                                        </div>
                                        <div className="mx-1 cursor-pointer ">
                                          <i

                                            onClick={() =>
                                              handleCoreProfilePrefDelete(val)
                                            }
                                            className="fa fa-times-circle text-gray-400 hover:text-gray-600"
                                            style={{ fontSize: '16px' }}
                                            id="iconCross"
                                          ></i>
                                        </div>
                                      </Button>

                                      <FormControl>
                                        <FormGroup>
                                          {special[val]?.map((ele, index) => (
                                            <FormControlLabel
                                              className="odd-grey"
                                              control={
                                                <Checkbox
                                                  key={index}
                                                  name={ele}
                                                  checked={checkSubdomain(
                                                    ele,
                                                    val
                                                  )}
                                                  onChange={(e) => {
                                                    let s = {
                                                      target: {
                                                        checked:
                                                          e.target.checked,
                                                        value: {
                                                          profile: val,
                                                          subdomain:
                                                            e.target.name,
                                                        },
                                                      },
                                                    };
                                                    handleSpecializationPrefChange(
                                                      s
                                                    );
                                                  }}
                                                />
                                              }
                                              label={ele}
                                            />
                                          ))}
                                        </FormGroup>
                                      </FormControl>
                                    </div>
                                  </div>
                                ))}
                                {/* {userData.coreprofiles?.map((value) => (
                                    <div
                                      className="relative flex justify-between text-md rounded-full border p-1 mb-2 mx-auto text-white"
                                      style={{
                                        // width: "0px",
                                        backgroundColor: "#ebebeb",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <div className="mx-1 text-black">
                                        {value} &nbsp;
                                      </div>
                                      <div className="mx-1 cursor-pointer ">
                                        <i
                                          className="fa-solid fa-pen-to-square pr-2 text-gray-400 hover:text-gray-600"
                                          onClick={() => handleOpen(value)}
                                        ></i>
                                        <i
                                          onClick={() =>
                                            handleCoreProfilePrefDelete(value)
                                          }
                                          className="fa fa-times-circle text-gray-400 hover:text-gray-600"
                                          id="iconCross"
                                        ></i>
                                      </div>
                                    </div>
                                  ))} */}
                              </Box>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Contribution */}
              <div className="email border-2 rounded border-green-600 px-1 py-2">
                <div className="element ">
                  {/* <p className="text-lg">
                    For each successfully completed Mock Interview (with
                    Feedback Session), an amount of INR 250 is paid by the
                    student for you. Would you like to keep it with you, or
                    donate it to Fyzen Career Solutions Pvt Ltd for improvement
                    of this platform (interwiu.com)?
                  </p> */}
                  <p style={{ lineHeight: 'revert' }} className="text-lg text-justify max-[600px]:text-[14px]">
                    For each successfully completed Mock Interview or Mentorship Session (with
                    Feedback), an amount of INR 400 for an Extended Session (45 Minutes) and INR 250 for a Standard Session (30 Minutes) is paid by the
                    student for you (We completely understand that the present
                    remuneration is low, however it will definitely be
                    increased in near future. As the platform is new, the
                    charges have to be affordable for a large section of
                    students so that the platform stays active).
                    <br />
                    <br />
                    Would you like to keep this amount with you, or donate it to
                    Fyzen Career Solutions Pvt Ltd for improvement of the
                    platform (interwiu.com)?
                  </p>
                  <p id="paymentPrefLabel" className="mt-2 text-blue-500">
                    Choose your payment preference
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <RadioGroup
                    row
                    id="paymentPref"
                    name="paymentPref"
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError({ ...fieldError, paymentPref: false });
                    }}
                  >
                    <FormControlLabel
                      value="self"
                      checked={userData.paymentPref === "self"}
                      control={<Radio />}
                      label="Opt for Self"
                    />
                    <FormControlLabel
                      value="donate"
                      checked={userData.paymentPref === "donate"}
                      control={<Radio />}
                      label="Donate to Fyzen Career Solutions for
                      improvement of interwiu.com"
                    />
                  </RadioGroup>
                  {userData.paymentPref === "self" ? (
                    <>
                      <div className="element" id="addAc">
                        <Typography
                          variant="h6"
                          component="h6"
                          className="s-mobile-main-h"
                          style={{
                            marginTop: "12px",
                            fontFamily: "'Ubuntu', sans-serif",
                            color: "rgb(59 130 246)",
                          }}
                        >
                          Add Bank Account details
                        </Typography>
                        <div className="flex gap-5 max-[600px]:flex-wrap">
                          <div className="element">
                            <label htmlFor="acno">
                              Account Holder's Name
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="accName"
                              id="accName"
                              className="border-2 rounded"
                              value={userData.accName}
                              onChange={(e) => {
                                e.target.style.borderColor = "#e5e7eb";
                                handleChange(e);
                              }}
                              placeholder="Account Holder's Name"
                              autoComplete="nope"
                              style={{

                              }}
                            />
                          </div>
                          <div className="element">
                            <label htmlFor="acno">
                              Account Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="accNo"
                              id="accNo"
                              className="border-2 rounded"
                              value={userData.accNo}
                              onChange={(e) => {
                                e.target.style.borderColor = "#e5e7eb";
                                handleChange(e);
                              }}
                              placeholder="Account Number"
                              autoComplete="nope"
                              style={{

                              }}
                            />
                          </div>
                        </div>
                        <div className="flex gap-5 mt-4 max-[600px]:flex-wrap">
                          <div className="element">
                            <label htmlFor="ifsc">
                              IFSC<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="ifsc"
                              id="ifsc"
                              className="border-2 rounded"
                              value={userData.ifsc}
                              onChange={(e) => {
                                e.target.style.borderColor = "#e5e7eb";
                                handleChange(e);
                              }}
                              placeholder="IFSC"
                              autoComplete="nope"
                              style={{

                              }}
                            />
                          </div>
                          <div className="element">
                            <label htmlFor="bankname">
                              Name of the Bank
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="bankName"
                              id="bankName"
                              className="border-2 rounded"
                              value={userData.bankName}
                              onChange={(e) => {
                                e.target.style.borderColor = "#e5e7eb";
                                handleChange(e);
                              }}
                              placeholder="Name of the Bank"
                              autoComplete="nope"
                              style={{

                              }}
                            />
                          </div>
                        </div>
                        <div className="flex gap-5 mt-4 max-[600px]:flex-wrap">
                          <div className="element">
                            <label htmlFor="branchCode">
                              Branch Code
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="branchCode"
                              id="branchCode"
                              className="border-2 rounded"
                              value={userData.branchCode}
                              onChange={(e) => {
                                e.target.style.borderColor = "#e5e7eb";
                                handleChange(e);
                              }}
                              placeholder="Branch Code"
                              autoComplete="nope"
                              style={{

                              }}
                            />
                          </div>
                          <div className="element">
                            <label
                              htmlFor="upiId"
                              className="flex flex-row justify-between"
                            >
                              <p>UPI ID<span style={{ color: "red" }}>*</span></p>
                              <span className="text-black text-sm">
                                {" "}
                                (Recommended)
                              </span>
                            </label>
                            <input
                              type="text"
                              name="upiId"
                              id="upiId"
                              className="border-2 rounded"
                              value={userData.upiId}
                              onChange={(e) => {
                                e.target.style.borderColor = "#e5e7eb";
                                handleChange(e);
                              }}
                              placeholder="UPI ID"
                              autoComplete="nope"
                              style={{

                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {userData.paymentPref === "donate" ? (
                    <p className="s-mobile-thankyou-mesg" style={{ color: "green" }}>
                      Thank you for your kind gesture! <br />
                      Fyzen Career Solutions will utilize your contribution for
                      improvement of interwiu.com
                    </p>
                  ) : null}
                </div>
              </div>

              {/* Description */}
              <div className="profilepref cust-row flex">
                <div className="element">
                  <label htmlFor="desc">Your Description {"(Bio)"}</label>
                  <textarea
                    name="desc"
                    id="desc"
                    cols="30"
                    rows="6"
                    value={userData.desc}
                    onChange={(e) => {
                      e.target.style.borderColor = "#e5e7eb";
                      handleChange(e);
                    }}
                    placeholder="Tell us about yourself"
                    className="border p-1 focus:outline-none resize-none max-[600px]:text-[14px]"
                  ></textarea>
                </div>
              </div>
            </div>
            {/* Profile preference */}


            <TimePreference
              timeRef={timeRef}
              gnrcSchdule={gnrcSchdForFrontend(userData.GnrcSchedule)}
              cstmSchdule={userData.CustomSchedule}
            />

            <div className="m-3 p-2 border rounded-md text-left profile-form-cont-3-s">
              <Typography
                variant="h5"
                component="h5"
                align="center"
                style={{
                  margin: "10px",
                  fontFamily: "'Ubuntu', sans-serif",
                }}
              >
                Social Links
              </Typography>
              <div className="my-3">
                <label htmlFor="linkedin" className="flex justify-between">
                  <span><i className="fa-brands fa-linkedin"></i> LinkedIn
                    <span style={{ color: "red" }}>*</span></span>
                  <div className="tt px-auto">
                    <InfoOutlined sx={{ color: 'rgb(83, 81, 81)', fontSize: '20px', }} />
                    <span className="tttext -translate-y-12">{`Please open LinkedIn in browser, go to your profile page 
                        by clicking on [View Profile] option under [Me] button, and copy-paste 
                        the exact Profile URL from the respective tab (containing https://www.linkedin.com/in/...) `}
                    </span>
                  </div>
                </label>
                <input
                  type="url"
                  name="linkedInURL"
                  id="linkedin"
                  value={userData.linkedInURL}
                  onChange={(e) => {
                    e.target.style.borderColor = "#e5e7eb";
                    handleChange(e);
                  }}
                  className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 s-mobile-text-14"
                  placeholder="LinkedIn URL"
                />
              </div>
              <div className="my-3">
                <label htmlFor="github">
                  <i className="fa-brands fa-github"></i> GitHub
                </label>
                <input
                  type="url"
                  name="githubURL"
                  id="githubURL"
                  value={userData.githubURL}
                  onChange={(e) => {
                    e.target.style.borderColor = "#e5e7eb";
                    handleChange(e);
                  }}
                  className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 s-mobile-text-14"
                  placeholder="GitHub URL"
                />
              </div>
              <div className="my-3">
                <label htmlFor="twitter">
                  <i className="fa-brands fa-twitter"></i> Twitter
                </label>
                <input
                  type="url"
                  name="twitterURL"
                  id="twitter"
                  value={userData.twitterURL}
                  onChange={(e) => {
                    e.target.style.borderColor = "#e5e7eb";
                    handleChange(e);
                  }}
                  className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 s-mobile-text-14"
                  placeholder="Twitter URL"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="px-2"
            >
              <div></div>
              <button type="submit" className="cust-btn create-btn">
                {!user?.designation ? "Create an Account" : "Update Details"}
              </button>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className="tt ml-auto">
                  <InfoOutlined sx={{ color: 'rgb(83, 81, 81)', fontSize: '20px' }} />
                  <span className="tttext">By clicking on{" "}
                    {user?.designation
                      ? ["[Update Details]"]
                      : ["[Create Account]"]}{" "}
                    button, you agree to our Terms and Conditions and Privacy
                    Policy</span>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <div className="fullPageLoader">
            <Oval
              height={120}
              width={120}
              color="#306bc9"
              wrapperStyle={{ padding: "50%, 50%" }}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#90caf9"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default InterviewerProfileForm;
