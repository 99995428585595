import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import UserContext from '../../../contexts/UserContext';
import './SignUps.css';

function SignUps() {
    const { user } = useContext(UserContext);
    const [signUps, setSignUps] = useState([]);

    const getSignUps = async (caId) => {
        console.log("CA id:", caId);
        const body = {
            caId: caId
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/programs/ca/get-signups`, body);
            console.log("Received sign-ups ==>", response);
            setSignUps(response.data.names);
        } catch (error) {
            console.log("Error in getting sign-ups ==>", error);
        }
    };

    useEffect(() => {
        if (user.caId) {
            getSignUps(user.caId);
        }
    }, [user]);

    return (
        <div className="signups-container">
            {signUps.length > 0 ? (
                <>
                    <h2 className="signups-header">Total Sign-Ups: {signUps.length}</h2>
                    <p className="share-message"><strong>{user.caId} </strong>is your referral code. Share this code with your friends and ask them to use this referral code while signing up.</p>
                    <table className="signups-table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {signUps.map((name, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className="name-column">{name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <p className="no-signups-message">
                    No one has signed-up at <span className="highlight">interwiu.com</span> using your referral code <strong className="code">{user.caId}</strong> so far. Share it with your friends and ask them to sign-up ASAP!
                </p>
            )}
        </div>
    );
}

export default SignUps;
