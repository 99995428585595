import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Process.css';
import UserContext from '../../../contexts/UserContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import { toastStyle } from '../../../utility/helper';
import { Modal, Backdrop, Fade, Typography, Button, Box } from '@mui/material';

const Process = () => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    useEffect(() => {
        setInstituteName(user?.institute);
        setStudId(user?.id);
    }, [user]);
    const [isEditing, setIsEditing] = useState(false);

    const [instituteName, setInstituteName] = useState(user?.institute);
    const [studId, setStudId] = useState(user?.id);
    const [newInstituteName, setNewInstituteName] = useState(instituteName);
    const [referralCode, setReferralCode] = useState('ABC123'); // Placeholder code
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [countdown, setCountdown] = useState(5);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        setInstituteName(newInstituteName);
        if (studId && newInstituteName) {
            const body = {
                id: studId,
                institute: newInstituteName
            }
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/programs/ca/change-institute-name`, body)
            } catch (error) {
                toast.error("Error in modifying institute name. Please try after some time", {
                    ...toastStyle.error,
                });
            }

        }
        setIsEditing(false);
    };
    const handleLogin = () => {
        window.open("/", "_blank");
        setTimeout(() => {
            window.close()
        }, 1500)

    };
    const handleCancel = () => {
        setNewInstituteName(instituteName);
        setIsEditing(false);
    };

    const handleMarkAmbassador = async () => {
        const body = {
            studId: studId,
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/programs/ca/add-ca`, body);
            setReferralCode(response.data.caId);
            setIsModalOpen(true);

            // Start the countdown timer
            let timer = 10;
            const countdownInterval = setInterval(() => {
                timer -= 1;
                setCountdown(timer);
                if (timer === 0) {
                    clearInterval(countdownInterval);
                    window.open("/programs/ca/sign-ups", "_blank");
                    window.close();
                }
            }, 1000);
        } catch (error) {
            console.error("Error adding Campus Ambassador:", error);
            toast.error("Failed to mark as Campus Ambassador. Please try again.", {
                ...toastStyle.error,
            });
        }
    };



    return (
        <div className="campus-ambassador-container">
            <h2 className="title">Become a Campus Ambassador</h2>
            <p className="info-text">
                Mark yourself as a Campus Ambassador of your institute and receive a <strong>Referral Code</strong> to share with your batchmates, seniors, and juniors. They can use this code while creating their student account at <strong>interwiu.com</strong> and get a <strong>Flat 10% Discount</strong> on session bookings.
            </p>
            {user.id ? (<div className="info-section">
                <button className="mark-button" onClick={handleMarkAmbassador}>
                    Mark myself as a Campus Ambassador of {instituteName}
                </button>
                <a href="#" className="modify-link" onClick={handleEditClick}>
                    Modify Institute Name
                </a>
            </div>) : (
                <div className='info-section'>
                    <button className="mark-button" onClick={handleLogin}>
                        Please login or create a student account before proceeding
                    </button>
                </div>)}

            {isEditing && (
                <div className="edit-popup">
                    <h3>Edit Institute Name</h3>
                    <input
                        type="text"
                        value={newInstituteName}
                        onChange={(e) => setNewInstituteName(e.target.value)}
                        placeholder="Enter new institute name"
                    />
                    <div className="popup-buttons">
                        <button className="save-button" onClick={handleSave}>
                            Save
                        </button>
                        <button className="cancel-button" onClick={handleCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            )}


            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={isModalOpen}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: '90%', sm: '80%', md: '50%' }, // Adjusted for a more compact view
                            bgcolor: 'background.default',
                            borderRadius: 4,
                            boxShadow: 4,
                            p: 4,
                            textAlign: 'center',
                            background: 'linear-gradient(135deg, #f5f5f5, #ffffff)', // Gradient background
                        }}
                    >
                        <Typography variant="h4" component="h2" sx={{ fontWeight: 700, mb: 2 }}>
                            Congratulations!
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            You are now a Campus Ambassador of <strong>{instituteName}</strong>.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            Your Referral Code is <strong>{referralCode}</strong>.
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                            The interwiu.com team looks forward to working with you!
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 3 }}>
                            You will be redirected to the Campus Ambassador Portal in <strong>{countdown}</strong> seconds.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    borderRadius: 20,
                                    px: 4,
                                    py: 1,
                                    fontWeight: 600,
                                    transition: '0.3s',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    },
                                }}
                                onClick={() => setIsModalOpen(false)}
                            >
                                Close
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{
                                    borderRadius: 20,
                                    px: 4,
                                    py: 1,
                                    fontWeight: 600,
                                    transition: '0.3s',
                                    '&:hover': {
                                        bgcolor: 'secondary.light',
                                        borderColor: 'secondary.main',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    },
                                }}
                                onClick={() => window.open("/programs/ca/sign-ups", "_blank")}
                            >
                                Go to Portal
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>

        </div>
    );
};

export default Process;
