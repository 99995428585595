import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Box,
  Chip,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import toast from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BASE } from "../../../constants/endpoints";
import UserContext from "../../../contexts/UserContext";
import { programValues, t24HDecimalTo12HFormat, toastStyle } from "../../../utility/helper";
import { Link } from "react-router-dom";
import { Calendar } from "react-multi-date-picker";
import { IoCloseCircle } from "react-icons/io5";
import { AiTwotoneCalendar } from "react-icons/ai";
import { special } from '../../../constants/branchSpecilization';
import './Interviewers.css';
import { FaCopy } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import { compareToTime } from "../../../utility/helper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Interviewer = () => {
  const { user } = useContext(UserContext);
  const [interviews, setInterviews] = useState([]);
  const [interviewers, setInterviewers] = useState([]);
  const [students, setStudents] = useState([]);
  const [mocks, setMocks] = useState([]);
  const [feedback, setFeedback] = useState([]);

  const [page, setPage] = useState(1);
  const [pageNo, setPageNo] = useState([0, 100]);

  const [branches, setBranches] = useState([]);
  const [profile, setProfile] = useState(null);


  const [ans, setAns] = useState([]);
  const [year, setYear] = useState(null);
  const [branch, setBranch] = useState(null);
  const [program, setProgram] = useState(null);
  const [appearing, setAppearing] = useState(null);
  const [name, setName] = useState(null);

  const [combine, setCombine] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [date, setDate] = useState(null);

  const [coreProfile, setCoreProfile] = useState(null);
  const [nonCoreProfile, setNonCoreProfile] = useState(null);
  const [specialization, setSpecialization] = useState(null);
  const [showCalender, setShowCalender] = useState(false);

  const [datePickerMobile, setDatePickerMobile] = useState(false)
  const [isloaded, setIsloaded] = useState(false)

  const nonprofiles = [
    "Data Science",
    "Software",
    "Banking and Finance",
    "Consulting",
    "Analytics",
    "Product Management",
    "Operations",
    "Supply Chain",
    "FMCG",
    "Operations Research",
    "Sales",
    "Inventory Management",
    "Logistics",
  ];

  const nonCoreFilter = (e) => {
    let finalf = combine;
    if (e.target.value) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) !== -1
      );
    }
    if (year) {
      finalf = finalf.filter((res) => res.resStud.year === `${year}`);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.resStud.branch === branch);
    }
    if (name) {
      finalf = finalf.filter(
        (res) =>
          (res.resStud.fname + " " + res.resStud.lname)
            .toLowerCase()
            .includes(name.toLowerCase()) === true
      );
    }
    if (date && date !== "01/01/1970") {
      finalf = finalf.filter((res) => res.result.date === `${date}`);
    }
    if (program) {
      finalf = finalf.filter((res) => res.resStud.program === program);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === false
        );
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === false &&
            res.resStud.appearingFor.two === true
        );
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };

  const coreFilter = (e) => {
    let finalf = combine;
    if (e.target.value) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) === -1
      );
    }
    if (year) {
      finalf = finalf.filter((res) => res.resStud.year === `${year}`);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.resStud.branch === branch);
    }
    if (name) {
      finalf = finalf.filter(
        (res) =>
          (res.resStud.fname + " " + res.resStud.lname)
            .toLowerCase()
            .includes(name.toLowerCase()) === true
      );
    }
    if (date && date !== "01/01/1970") {
      finalf = finalf.filter((res) => res.result.date === `${date}`);
    }
    if (program) {
      finalf = finalf.filter((res) => res.resStud.program === program);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === false
        );
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === false &&
            res.resStud.appearingFor.two === true
        );
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };

  const nameFilter = (e) => {
    setName(e.target.value);
    let finalf = combine;

    if (profile) {
      finalf = finalf.filter((res) => res.result.profile === profile);
    }
    if (coreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) === -1
      );
    }

    if (nonCoreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) !== -1
      );
    }
    if (e.target.value && (e.target.value !== null || e.target.value !== "")) {
      finalf = finalf.filter(
        (res) =>
          (res.resStud.fname + " " + res.resStud.lname)
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) === true
      );
    }
    if (year) {
      finalf = finalf.filter((res) => res.resStud.year === `${year}`);
    }
    if (date && date !== "01/01/1970") {
      finalf = finalf.filter((res) => res.result.date === `${date}`);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.resStud.branch === branch);
    }
    if (program) {
      finalf = finalf.filter((res) => res.resStud.program === program);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === false
        );
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === false &&
            res.resStud.appearingFor.two === true
        );
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };

  const profileFilter = (e) => {
    console.log(year);
    setProfile(e.target.value);
    setSpecialization(null);
    let finalf = combine;

    if (e.target.value) {
      finalf = finalf.filter((res) => res.result.profile === e.target.value);
    }
    if (coreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) === -1
      );
    }

    if (nonCoreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) !== -1
      );
    }

    if (e.target.value) {
      finalf = finalf.filter((res) => res.result.profile === e.target.value);
    }

    if (year) {
      finalf = finalf.filter((res) => res.resStud.year === year);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.resStud.branch === branch);
    }
    if (name) {
      finalf = finalf.filter(
        (res) =>
          (res.resStud.fname + " " + res.resStud.lname)
            .toLowerCase()
            .includes(name.toLowerCase()) === true
      );
    }
    if (date && date !== "01/01/1970") {
      finalf = finalf.filter((res) => res.result.date === `${date}`);
    }

    if (program) {
      finalf = finalf.filter((res) => res.resStud.program === program);
    }

    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === false
        );
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === false &&
            res.resStud.appearingFor.two === true
        );
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };

  const specializationFilter = (e) => {
    setSpecialization(e.target.value);
    let finalf = combine;
    if (e.target.value) {
      finalf = finalf.filter((res) => res.resStud.specialization === e.target.value);
    }

    if (branch) {
      finalf = finalf.filter((res) => res.resStud.branch === branch);
    }
    if (name) {
      finalf = finalf.filter(
        (res) =>
          (res.resStud.fname + " " + res.resStud.lname)
            .toLowerCase()
            .includes(name.toLowerCase()) === true
      );
    }
    if (date && date !== "01/01/1970") {
      finalf = finalf.filter((res) => res.result.date === `${date}`);
    }
    if (program) {
      finalf = finalf.filter((res) => res.resStud.program === program);
    }
    if (specialization) {
      finalf = finalf.filter((res) => res.resStud.specialization === specialization);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === false
        );
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === false &&
            res.resStud.appearingFor.two === true
        );
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };

  const dateFilter = (e) => {
    let d = new Date(e);
    const date = moment(d).format("DD/MM/YYYY");
    console.log(date);
    setDate(date);
    let finalf = combine;

    if (profile) {
      finalf = finalf.filter((res) => res.result.profile === profile);
    }

    if (coreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) === -1
      );
    }

    if (nonCoreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) !== -1
      );
    }

    if (date && date !== "01/01/1970") {
      finalf = finalf.filter((res) => res.result.date === `${date}`);
    }
    if (name) {
      finalf = finalf.filter(
        (res) =>
          (res.resStud.fname + " " + res.resStud.lname)
            .toLowerCase()
            .includes(name.toLowerCase()) === true
      );
    }
    if (year) {
      finalf = finalf.filter((res) => res.resStud.year === `${year}`);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.resStud.branch === branch);
    }
    if (program) {
      finalf = finalf.filter((res) => res.resStud.program === program);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === false
        );
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === false &&
            res.resStud.appearingFor.two === true
        );
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };
  const yearFilter = (e) => {
    console.log(profile);
    setYear(e.target.value);
    let finalf = combine;

    if (profile) {
      finalf = finalf.filter((res) => res.result.profile === profile);
    }

    if (e.target.value) {
      finalf = finalf.filter(
        (res) => res.resStud.year === `${e.target.value}`
      );
    }

    if (coreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) === -1
      );
    }

    if (nonCoreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) !== -1
      );
    }

    if (date && date !== "01/01/1970") {
      finalf = finalf.filter((res) => res.result.date === `${date}`);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.resStud.branch === branch);
    }
    if (name) {
      finalf = finalf.filter(
        (res) =>
          (res.resStud.fname + " " + res.resStud.lname)
            .toLowerCase()
            .includes(name.toLowerCase()) === true
      );
    }
    if (program) {
      finalf = finalf.filter((res) => res.resStud.program === program);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === false
        );
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === false &&
            res.resStud.appearingFor.two === true
        );
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };
  const branchFilter = (e) => {
    console.log(e.target.value);
    setBranch(e.target.value);
    let finalf = combine;

    if (profile) {
      finalf = finalf.filter((res) => res.result.profile === profile);
    }

    if (coreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) === -1
      );
    }

    if (nonCoreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) !== -1
      );
    }

    if (year) {
      finalf = finalf.filter((res) => res.resStud.year === year);
    }
    if (e.target.value) {
      finalf = finalf.filter((res) => res.resStud.branch === e.target.value);
    }
    if (name) {
      finalf = finalf.filter(
        (res) =>
          (res.resStud.fname + " " + res.resStud.lname)
            .toLowerCase()
            .includes(name.toLowerCase()) === true
      );
    }
    if (date && date !== "01/01/1970") {
      finalf = finalf.filter((res) => res.result.date === `${date}`);
    }
    if (program) {
      finalf = finalf.filter((res) => res.resStud.program === program);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === false
        );
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === false &&
            res.resStud.appearingFor.two === true
        );
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };
  const programFilter = (e) => {
    console.log(e.target.value);
    setProgram(e.target.value);
    let finalf = combine;

    if (profile) {
      finalf = finalf.filter((res) => res.result.profile === profile);
    }
    if (coreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) === -1
      );
    }

    if (nonCoreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) !== -1
      );
    }

    if (year) {
      finalf = finalf.filter((res) => res.resStud.year === year);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.resStud.branch === branch);
    }
    if (date && date !== "01/01/1970") {
      finalf = finalf.filter((res) => res.result.date === `${date}`);
    }
    if (name) {
      finalf = finalf.filter(
        (res) =>
          (res.resStud.fname + " " + res.resStud.lname)
            .toLowerCase()
            .includes(name.toLowerCase()) === true
      );
    }
    if (e.target.value) {
      finalf = finalf.filter((res) => res.resStud.program === e.target.value);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === false
        );
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === false &&
            res.resStud.appearingFor.two === true
        );
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };
  const appearingFilter = (e) => {
    console.log(e.target.value);
    setAppearing(
      e.target.value === 1
        ? "Campus Placements"
        : e.target.value === 2
          ? "Internship Interviews"
          : e.target.value === 3
            ? "Both"
            : ""
    );
    let finalf = combine;

    if (profile) {
      finalf = finalf.filter((res) => res.result.profile === profile);
    }

    if (coreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) === -1
      );
    }

    if (nonCoreProfile) {
      finalf = finalf.filter(
        (res) => nonprofiles.indexOf(res.result.profile) !== -1
      );
    }

    if (year) {
      finalf = finalf.filter((res) => res.resStud.year === year);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.resStud.branch === branch);
    }
    if (date && date !== "01/01/1970") {
      finalf = finalf.filter((res) => res.result.date === `${date}`);
    }
    if (name) {
      finalf = finalf.filter(
        (res) =>
          (res.resStud.fname + " " + res.resStud.lname)
            .toLowerCase()
            .includes(name.toLowerCase()) === true
      );
    }
    if (program) {
      finalf = finalf.filter((res) => res.resStud.program === program);
    }
    console.log(finalf);
    if (e.target.value) {
      if (e.target.value === 1 || e.target.value === "1") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === false
        );
      } else if (e.target.value === 2 || e.target.value === "2") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === false &&
            res.resStud.appearingFor.two === true
        );
      } else if (e.target.value === 3 || e.target.value === "3") {
        finalf = finalf.filter(
          (res) =>
            res.resStud.appearingFor.one === true &&
            res.resStud.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };

  const nameDelete = (e) => {
    let s = { target: { value: null } };
    nameFilter(s);
  };
  const yearDelete = (e) => {
    let s = { target: { value: null } };
    yearFilter(s);
  };
  const branchDelete = (e) => {
    let s = { target: { value: null } };
    branchFilter(s);
  };
  const programDelete = (e) => {
    let s = { target: { value: null } };
    programFilter(s);
  };
  const appearingDelete = (e) => {
    let s = { target: { value: null } };
    appearingFilter(s);
  };
  const coreDelete = (e) => {
    let s = { target: { value: null } };
    nonCoreFilter(s);
  };
  const nonCoreDelete = (e) => {
    let s = { target: { value: null } };
    coreFilter(s);
  };
  const profileDelete = (e) => {
    let s = { target: { value: null } };
    profileFilter(s);
  };

  const dateDelete = (e) => {
    dateFilter(null);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };



  // useEffect(()=>{
  //   console.log("-------------------------------")
  //   console.log(nonCoreProfile, "nonCoreProfile");
  //   console.log(coreProfile, "coreProfile");
  //   console.log(profile, "profile");
  //   console.log(selectedDate, "selectedDate");
  //   console.log(program,"program");
  //   console.log(year,"year");
  //   console.log(appearing,"appearingFor");
  //   console.log(branch,"branch");
  //   console.log(name,"name");
  //   console.log("-------------------------------")
  // },[nonCoreProfile,coreProfile,profile,selectedDate,program,year,appearing,branch,name])

  useEffect(() => {
    setPageNo([(page - 1) * 100, page * 100]);
    window.scrollTo(0, 0);
  }, [page, interviewers, interviews, mocks, students, feedback]);

  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/admin/getFeedback/${user.id}`)
        .then((response) => {
          console.log(response.data, "---------------");
          setInterviews(response.data?.result);
          setInterviewers(response.data?.resInt);
          setStudents(response.data?.resStud);
          setMocks(response.data?.mocksStud);
          setFeedback(response.data?.intReviews);
          setBranches(response.data?.branches.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())));

          const len = response.data?.result.length;
          let ans = [];
          for (let i = 0; i < len; i++) {
            ans.push({
              result: response.data.result[i],
              resInt: response.data.resInt[i],
              resStud: response.data.resStud[i],
              mocksStud: response.data.mocksStud[i],
              intReviews: response.data.intReviews[i],
            });
          }
          ans = ans.filter((mock) => mock.intReviews !== undefined)
          ans.sort(compareToTime);
          setCombine(ans);
          setAns(ans);
          console.log(ans);

          setPage(1);
          setIsloaded(true)
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);


  const handleCopy = async (mockId) => {
    try {
      await navigator.clipboard.writeText(mockId);
      toast.success(`Copied to clipboard`, {
        ...toastStyle.success,
      });
    } catch (error) {
      console.error('Failed to copy text:', error);
      toast.success(`Failed to copy text`, {
        ...toastStyle.loading,
      });
    }
  };

  return (
    <>
      {isloaded ?
        (<div>
          <div
            className="w-auto text-lg px-2 py-1 bg-white rounded-md border-black border-[1px] border-solid grid grid-cols-5 s-mobile-admin-searchbar"
            id="searchbar-input"
          >
            <input
              type="text"
              className="mx-1 py-2 outline-none w-auto col-span-4 text-base"
              value={name || ""}
              onChange={nameFilter}
              name="search"
              id="search"
              placeholder="Search Student by Name"
            />
            <div
              className="text-2xl w-12 cursor-pointer hover:text-sky-200 col-span-1 flex flex-row justify-between gap-1"
              id="search-bar"
            >
              {name ? (
                <IconButton sx={{ p: "3px", minWidth: 0, border: "1px solid gray" }} onClick={nameDelete}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <div className="h-6 w-6"></div>
              )}
              <IconButton sx={{ p: "3px", minWidth: 0, border: "1px solid gray" }}>
                <SearchIcon />
              </IconButton>
            </div>
          </div>

          {/* <div className="flex justify-between bg-white p-3 my-3 rounded-lg" style={{alignItems:"flex-start"}}>
        <div className="my-3">
          <Select
            // multiple
            displayEmpty
            required
            value={year}
            sx={{ width: 1 }}
            renderValue={() => "Year"}
            onChange={yearFilter}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={"1"}>1</MenuItem>
            <MenuItem value={"2"}>2</MenuItem>
            <MenuItem value={"3"}>3</MenuItem>
            <MenuItem value={"4"}>4</MenuItem>
            <MenuItem value={"5"}>5</MenuItem>
          </Select>
          {year ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                width: 1,
                p: 2,
              }}
            >
              <Chip
sx={{
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
  }}
                key={year}
                label={year}
                onDelete={() => {
                  yearDelete(year);
                }}
              />
            </Box>
          ) : null}
        </div>
        <div className="my-3">
          <Select
            // multiple
            displayEmpty
            required
            value={branch}
            sx={{ width: 1 }}
            renderValue={() => "Branch"}
            onChange={branchFilter}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem hidden disabled value="">
              Select Branch/Department
            </MenuItem>
            {branches?.map((branch, index) => {
              return(
              <MenuItem key={index} value={branch}>
                {branch}
              </MenuItem>
            )})}
            
          </Select>
          {branch ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                width: 1,
                p: 2,
              }}
            >
              <Chip
sx={{
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
  }}
                key={branch}
                label={branch}
                onDelete={() => {
                  branchDelete(branch);
                }}
              />
            </Box>
          ) : null}
        </div>
        <div>
          <div className="my-3">
            <Select
              // multiple
              displayEmpty
              required
              value={program}
              sx={{ width: 1 }}
              renderValue={() => "Program"}
              onChange={programFilter}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="btech">B.Tech</MenuItem>
              <MenuItem value="dual">Dual Degree (B.Tech + M.Tech)</MenuItem>
              <MenuItem value="dualbsms">Dual Degree (BS + MS)</MenuItem>
              <MenuItem value="mtech">M.Tech</MenuItem>
              <MenuItem value="ms">MS (by Research)</MenuItem>
              <MenuItem value="mba">MBA</MenuItem>
              <MenuItem value="msc">M.Sc</MenuItem>
              <MenuItem value="ima">Intergrated MA</MenuItem>
              <MenuItem value="phd">PhD</MenuItem>
            </Select>
          </div>
          {program ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                width: 1,
                p: 2,
              }}
            >
              <Chip
sx={{
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
  }}
                key={program}
                label={
                  program === "btech"
                    ? "B.Tech"
                    : program === "dual"
                    ? "Dual Degree (B.Tech + M.Tech)"
                    : program === "dualbsms"
                    ? "Dual Degree (BS + MS)"
                    : program === "mtech"
                    ? "M.Tech"
                    : program === "ms"
                    ? "MS (by Research)"
                    : program === "mba"
                    ? "MBA"
                    : program === "msc"
                    ? "M.Sc"
                    : program === "ima"
                    ? "Intergrated MA"
                    : "PhD"
                }
                onDelete={() => {
                  programDelete(program);
                }}
              />
            </Box>
          ) : null}
        </div>
        <div className="my-3 border-solid border-[1px] border-gray-300 px-2 rounded-sm">
          <div className="my-3 flex flex-col">
            <Typography variant="body1" gutterBottom>
              Choose a Date{" "}
              <FontAwesomeIcon
                onClick={() => {
                  setShowCalender(!showCalender);
                }}
                className="cursor-pointer"
                icon={faCalendarDays}
              />
            </Typography>
            {selectedDate && (
              <div
                className="flex relative justify-center items-center"
                style={{ width: "max-content" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: "20px" }}
                >
                  {selectedDate.toLocaleDateString()}
                </Button>
                <FontAwesomeIcon
                  icon={faTrash}
                  className="text-red-600 pb-[20px] ml-[10px]  cursor-pointer"
                  onClick={(e) => {
                    setSelectedDate(null);
                  }}
                />
              </div>
            )}
            {showCalender && (
              <div className="w-full flex flex-row justify-center">
                <Calendar
                  minDate={new Date().setDate(new Date().getDate())}
                  zIndex={0}
                  value={selectedDate}
                  onChange={(e) => {
                    console.log(new Date(e), "----------");
                    setSelectedDate(new Date(e));
                    setShowCalender(!showCalender);
                    dateFilter(e);
                  }}
                  className=""
                  mapDays={({ date, selectedDate, isSameDate }) => {
                    let props = {};
                    if (isSameDate(date, selectedDate)) {
                      props.style = {
                        color: "#fff",
                        backgroundColor: "#00a86b",
                        border: "1px solid #000",
                      };
                      return props;
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="my-3">
          <Select
            // multiple
            displayEmpty
            required
            value={appearing}
            sx={{ width: 1 }}
            renderValue={() => "Appearing For"}
            onChange={appearingFilter}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={1}>Campus Placements</MenuItem>
            <MenuItem value={2}>Internship Interviews</MenuItem>
            <MenuItem value={3}>Both</MenuItem>
          </Select>
          {appearing ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                width: 1,
                p: 2,
              }}
            >
              <Chip
sx={{
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
  }}
                key={appearing}
                label={appearing}
                onDelete={() => {
                  appearingDelete(appearing);
                }}
              />
            </Box>
          ) : null}
        </div>
        <div className="my-3">
          <Select
            // multiple
            displayEmpty
            required
            value={appearing}
            sx={{ width: 1 }}
            renderValue={() =>
              coreProfile
                ? "Core"
                : nonCoreProfile
                ? "Non-Core"
                : "Mock Interview Profile"
            }
            onChange={(e) => {
              if (e.target.value === "Non-Core") {
                setNonCoreProfile(true);
                setCoreProfile(false);
                setSpecialization(null);
                setProfile(null);
                coreDelete("core");
                nonCoreFilter(e);
              } else if (e.target.value === "Core") {
                setNonCoreProfile(false);
                setCoreProfile(true);
                setSpecialization(null);
                setProfile(null);
                nonCoreDelete();
                coreFilter(e);
              } else {
                setNonCoreProfile(false);
                setCoreProfile(false);
                setSpecialization(null);
                setProfile(null);
                coreDelete("core");
                nonCoreDelete("non-core");
              }
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="Core">Core</MenuItem>
            <MenuItem value="Non-Core">Non-Core</MenuItem>
          </Select>

          {nonCoreProfile && (
            <div className="my-3">
              <div className="flex w-full justify-end cursor-pointer">
                <p
                  className="underline text-blue-500 "
                  onClick={(e) => {
                    setNonCoreProfile(false);
                    setCoreProfile(false);
                    setSpecialization(null);
                    setProfile(null);
                    profileDelete(profile);
                    coreDelete("core");
                    nonCoreDelete("non-core");
                  }}
                >
                  clear
                </p>
              </div>
              <Select
                // multiple
                displayEmpty
                required
                value={profile || "Select Non-Core Profile"}
                renderValue={() => profile || "Select Non-Core Profile"}
                sx={{ width: 1 }}
                MenuProps={MenuProps}
                onChange={profileFilter}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Select Profile" disabled>
                  Select Profile
                </MenuItem>
                <MenuItem value="Data Science">Data Science</MenuItem>
                <MenuItem value="Software">Software</MenuItem>
                <MenuItem value="Banking and Finance">
                  Banking and Finance
                </MenuItem>
                <MenuItem value="Consulting">Consulting</MenuItem>
                <MenuItem value="Analytics">Analytics</MenuItem>
                <MenuItem value="Product Management">
                  Product Management
                </MenuItem>
                <MenuItem value="Operations">Operations</MenuItem>
                <MenuItem value="Supply Chain">Supply Chain</MenuItem>
                <MenuItem value="FMCG">FMCG</MenuItem>
                <MenuItem value="Operations Research">
                  Operations Research
                </MenuItem>
                <MenuItem value="Sales">Sales</MenuItem>
                <MenuItem value="Inventory Management">
                  Inventory Management
                </MenuItem>
                <MenuItem value="Logistics">Logistics</MenuItem>
              </Select>
            </div>
          )}
          {coreProfile && (
            <div className="my-3">
              <div className="flex w-full justify-end cursor-pointer">
                <p
                  className="underline text-blue-500 "
                  onClick={(e) => {
                    setNonCoreProfile(false);
                    setCoreProfile(false);
                    setSpecialization(null);
                    setProfile(null);
                    profileDelete(profile);
                  }}
                >
                  clear
                </p>
              </div>
              <Select
                // multiple
                displayEmpty
                required
                value={profile || "Select Core Profile"}
                renderValue={() => profile || "Select Core Profile"}
                sx={{ width: 1 }}
                onChange={profileFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem hidden disabled value="">
                  Select Branch/Department
                </MenuItem>
                <MenuItem value="Aerospace Engineering">
                  Aerospace Engineering
                </MenuItem>
                <MenuItem value="Applied Mechanics">Applied Mechanics</MenuItem>
                <MenuItem value="Biomedical Engineering ">
                  Biomedical Engineering
                </MenuItem>
                <MenuItem value="Bioscience and Bioengineering">
                  Bioscience and Bioengineering
                </MenuItem>
                <MenuItem value="Biotechnology">Biotechnology</MenuItem>
                <MenuItem value="Chemical Engineering">
                  Chemical Engineering
                </MenuItem>
                <MenuItem value="Chemistry">Chemistry</MenuItem>
                <MenuItem value="Civil Engineering">Civil Engineering</MenuItem>
                <MenuItem value="Computer Science and Engineering">
                  Computer Science and Engineering
                </MenuItem>
                <MenuItem value="Economics">Economics</MenuItem>
                <MenuItem value="Electrical Engineering">
                  Electrical Engineering
                </MenuItem>
                <MenuItem value="Electrical and Electronics Engineering">
                  Electrical and Electronics Engineering
                </MenuItem>
                <MenuItem value="Electronics and Communication Engineering">
                  Electronics and Communication Engineering
                </MenuItem>
                <MenuItem value="Engineering Design">
                  Engineering Design
                </MenuItem>
                <MenuItem value="Humanities and Social Sciences">
                  Humanities and Social Sciences
                </MenuItem>
                <MenuItem value="Management/Business Studies">
                  Management/Business Studies
                </MenuItem>
                <MenuItem value="Mathematics/Mathematics and Computing">
                  Mathematics/Mathematics and Computing
                </MenuItem>
                <MenuItem value="Mechanical Engineering">
                  Mechanical Engineering
                </MenuItem>
                <MenuItem value="Metallurgical and Materials Engineering">
                  Metallurgical and Materials Engineering
                </MenuItem>
                <MenuItem value="Mining Engineering">
                  Mining Engineering
                </MenuItem>
                <MenuItem value="Ocean Engineering">Ocean Engineering</MenuItem>
                <MenuItem value="Physics/Engineering Physics">
                  Physics/Engineering Physics
                </MenuItem>
              </Select>
            </div>
          )}

          
        </div>
      </div> */}

          <div style={{ alignItems: 'flex-start', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} className="flex justify-between p-3 mt-3 rounded-lg s-desktop-card s-desktop-card-mx">
            <div className="my-0" style={{ alignItems: "flex-start" }}>
              <Select
                // multiple
                displayEmpty
                required
                value={year}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    if (year == null || year === "") return "Year"
                    return year
                  }
                  return "Year"
                }}
                onChange={yearFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={"1st year"}>1st year</MenuItem>
                <MenuItem value={"2nd year"}>2nd year</MenuItem>
                <MenuItem value={"3rd year"}>3rd year</MenuItem>
                <MenuItem value={"4th year"}>4th year</MenuItem>
                <MenuItem value={"5th year"}>5th year</MenuItem>
                <MenuItem value={"6th year"}>6th year</MenuItem>
              </Select>
              {(year && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: '0.75rem 0.75rem 0rem 0.75rem',
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={year}
                    label={year}
                    onDelete={() => {
                      yearDelete(year);
                    }}
                  />
                </Box>
              ) : null}
            </div>
            <div className="my-0">
              <Select
                // multiple
                displayEmpty
                required
                value={branch}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    if (program == null || program === "") return "Program"
                    return program
                  }
                  else return "Branch"
                }}
                onChange={branchFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem hidden disabled value="">
                  Select Branch/Department
                </MenuItem>
                {branches.map((branch, index) => (
                  <MenuItem key={index} value={branch}>
                    {branch}
                  </MenuItem>
                ))}
                {/* <MenuItem value="Aerospace Engineering">
              Aerospace Engineering
            </MenuItem>
            <MenuItem value="Applied Mechanics">Applied Mechanics</MenuItem>
            <MenuItem value="Biomedical Engineering ">
              Biomedical Engineering
            </MenuItem>
            <MenuItem value="Bioscience and Bioengineering">
              Bioscience and Bioengineering
            </MenuItem>
            <MenuItem value="Biotechnology">Biotechnology</MenuItem>
            <MenuItem value="Chemical Engineering">
              Chemical Engineering
            </MenuItem>
            <MenuItem value="Chemistry">Chemistry</MenuItem>
            <MenuItem value="Civil Engineering">Civil Engineering</MenuItem>
            <MenuItem value="Computer Science and Engineering">
              Computer Science and Engineering
            </MenuItem>
            <MenuItem value="Economics">Economics</MenuItem>
            <MenuItem value="Electrical Engineering">
              Electrical Engineering
            </MenuItem>
            <MenuItem value="Electrical and Electronics Engineering">
              Electrical and Electronics Engineering
            </MenuItem>
            <MenuItem value="Electronics and Communication Engineering">
              Electronics and Communication Engineering
            </MenuItem>
            <MenuItem value="Engineering Design">Engineering Design</MenuItem>
            <MenuItem value="Humanities and Social Sciences">
              Humanities and Social Sciences
            </MenuItem>
            <MenuItem value="Management/Business Studies">
              Management/Business Studies
            </MenuItem>
            <MenuItem value="Mathematics/Mathematics and Computing">
              Mathematics/Mathematics and Computing
            </MenuItem>
            <MenuItem value="Mechanical Engineering">
              Mechanical Engineering
            </MenuItem>
            <MenuItem value="Metallurgical and Materials Engineering">
              Metallurgical and Materials Engineering
            </MenuItem>
            <MenuItem value="Mining Engineering">Mining Engineering</MenuItem>
            <MenuItem value="Ocean Engineering">Ocean Engineering</MenuItem>
            <MenuItem value="Physics/Engineering Physics">
              Physics/Engineering Physics
            </MenuItem> */}
              </Select>
              {(branch && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: '0.75rem 0.75rem 0rem 0.75rem',
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={branch}
                    label={branch}
                    onDelete={() => {
                      branchDelete(branch);
                    }}
                  />
                </Box>
              ) : null}
            </div>
            <div>
              <div className="my-0">
                <Select
                  // multiple
                  displayEmpty
                  required
                  value={program}
                  sx={{ width: 1 }}
                  renderValue={() => {
                    if (window.innerWidth < 600) {
                      if (program == null || program === "") return "Program"
                      return program
                    }
                    else return "Program"
                  }}
                  onChange={programFilter}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="btech">B.Tech</MenuItem>
                  <MenuItem value="dual">Dual Degree (B.Tech + M.Tech)</MenuItem>
                  <MenuItem value="dualbsms">Dual Degree (BS + MS)</MenuItem>
                  <MenuItem value="mtech">M.Tech</MenuItem>
                  <MenuItem value="ms">MS (by Research)</MenuItem>
                  <MenuItem value="mba">MBA</MenuItem>
                  <MenuItem value="msc">M.Sc</MenuItem>
                  <MenuItem value="ma">MA</MenuItem>
                  <MenuItem value="ima">Intergrated MA</MenuItem>
                  <MenuItem value="phd">PhD</MenuItem>
                </Select>
              </div>
              {(program && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: '0.75rem 0.75rem 0rem 0.75rem',
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={program}
                    label={
                      program === "btech"
                        ? "B.Tech"
                        : program === "dual"
                          ? "Dual Degree (B.Tech + M.Tech)"
                          : program === "dualbsms"
                            ? "Dual Degree (BS + MS)"
                            : program === "mtech"
                              ? "M.Tech"
                              : program === "ms"
                                ? "MS (by Research)"
                                : program === "mba"
                                  ? "MBA"
                                  : program === "msc"
                                    ? "M.Sc"
                                    : program === "ima"
                                      ? "Intergrated MA"
                                      : program === "ma"
                                        ? "MA"
                                        : "PhD"
                    }
                    onDelete={() => {
                      programDelete(program);
                    }}
                  />
                </Box>
              ) : null}
            </div>
            <div className="my-0 border-solid border-[1px] border-gray-300 px-2 rounded-sm">
              <div className="my-3 mb-[9px] flex flex-col">
                <Typography style={{ marginTop: '3px' }} variant="body1" gutterBottom>
                  Choose a Date{" "}
                  <FontAwesomeIcon
                    onClick={() => {
                      setShowCalender(!showCalender);
                    }}
                    className="cursor-pointer"
                    icon={faCalendarDays}
                  />
                </Typography>
                {selectedDate && (
                  <div
                    className="flex relative justify-center items-center"
                    style={{ width: "max-content" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: "7px" }}
                    >
                      {moment(selectedDate).format("DD MMM YYYY")}
                    </Button>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="text-red-600 pb-[20px] ml-[10px]  cursor-pointer"
                      onClick={(e) => {
                        setSelectedDate(null);
                        dateDelete(date);
                      }}
                    />
                  </div>
                )}
                {showCalender && (
                  <div className="w-full flex flex-row justify-center">
                    <Calendar
                      // minDate={new Date().setDate(new Date().getDate())}
                      zIndex={0}
                      value={selectedDate}
                      onChange={(e) => {
                        console.log(new Date(e), "----------");
                        setSelectedDate(new Date(e));
                        setShowCalender(!showCalender);
                        dateFilter(e);
                      }}
                      className=""
                      mapDays={({ date, selectedDate, isSameDate }) => {
                        let props = {};
                        if (isSameDate(date, selectedDate)) {
                          props.style = {
                            color: "#fff",
                            backgroundColor: "#00a86b",
                            border: "1px solid #000",
                          };
                          return props;
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="my-0">
              <Select
                // multiple
                displayEmpty
                required
                value={appearing}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    if (appearing == null || appearing === "") return "Appearing For"
                    return appearing
                  } else return "Appearing For"
                }}
                onChange={appearingFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={1}>Campus Placements</MenuItem>
                <MenuItem value={2}>Internship Interviews</MenuItem>
                <MenuItem value={3}>Both</MenuItem>
              </Select>
              {appearing ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: '0.75rem 0.75rem 0rem 0.75rem',
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={appearing}
                    label={appearing}
                    onDelete={() => {
                      appearingDelete(appearing);
                    }}
                  />
                </Box>
              ) : null}
            </div>
            <div className="my-0 w-[20%]">
              <Select
                // multiple
                displayEmpty
                required
                value={appearing}
                sx={{ width: 1 }}
                renderValue={() =>
                  coreProfile
                    ? "Core"
                    : nonCoreProfile
                      ? "Non-Core"
                      : "Mock Interview Profile"
                }
                onChange={(e) => {
                  if (e.target.value === "Non-Core") {
                    setNonCoreProfile(true);
                    setCoreProfile(false);
                    setSpecialization(null);
                    setProfile(null);
                    coreDelete("core");
                    nonCoreFilter(e);
                  } else if (e.target.value === "Core") {
                    setNonCoreProfile(false);
                    setCoreProfile(true);
                    setSpecialization(null);
                    setProfile(null);
                    nonCoreDelete("non-core");
                    coreFilter(e);
                  } else {
                    setNonCoreProfile(false);
                    setCoreProfile(false);
                    setSpecialization(null);
                    setProfile(null);
                    coreDelete("core");
                    nonCoreDelete("non-core");
                  }
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Core">Core</MenuItem>
                <MenuItem value="Non-Core">Non-Core</MenuItem>
              </Select>


              {nonCoreProfile && (
                <div className="my-0">
                  <div className="flex w-full justify-end cursor-pointer">
                    <p
                      className="underline text-blue-500 "
                      onClick={(e) => {
                        setNonCoreProfile(false);
                        setCoreProfile(false);
                        setSpecialization(null);
                        setProfile(null);
                        profileDelete(profile);
                        coreDelete("core");
                        nonCoreDelete("non-core");
                      }}
                    >
                      clear
                    </p>
                  </div>
                  <Select
                    // multiple
                    displayEmpty
                    required
                    value={profile || "Select Non-Core Profile"}
                    renderValue={() => profile || "Select Non-Core Profile"}
                    sx={{ width: 1 }}
                    MenuProps={MenuProps}
                    onChange={profileFilter}
                    inputProps={{ "aria-label": "Without label" }}
                    className="fix-desktop-select-multiline"
                  >
                    <MenuItem value="Select Profile" disabled>
                      Select Profile
                    </MenuItem>
                    <MenuItem value="Data Science">Data Science</MenuItem>
                    <MenuItem value="Software">Software</MenuItem>
                    <MenuItem value="Banking and Finance">
                      Banking and Finance
                    </MenuItem>
                    <MenuItem value="Consulting">Consulting</MenuItem>
                    <MenuItem value="Analytics">Analytics</MenuItem>
                    <MenuItem value="Product Management">
                      Product Management
                    </MenuItem>
                    <MenuItem value="Operations">Operations</MenuItem>
                    <MenuItem value="Supply Chain">Supply Chain</MenuItem>
                    <MenuItem value="FMCG">FMCG</MenuItem>
                    <MenuItem value="Operations Research">
                      Operations Research
                    </MenuItem>
                    <MenuItem value="Sales">Sales</MenuItem>
                    <MenuItem value="Inventory Management">
                      Inventory Management
                    </MenuItem>
                    <MenuItem value="Logistics">Logistics</MenuItem>
                  </Select>
                </div>
              )}
              {coreProfile && (
                <div className="my-0">
                  <div className="flex w-full justify-end cursor-pointer">
                    <p
                      className="underline text-blue-500 "
                      onClick={(e) => {
                        setNonCoreProfile(false);
                        setCoreProfile(false);
                        setSpecialization(null);
                        setProfile(null);
                        profileDelete(profile);
                        coreDelete("core");
                        nonCoreDelete("non-core");
                      }}
                    >
                      clear
                    </p>
                  </div>
                  <Select
                    // multiple
                    displayEmpty
                    required
                    value={profile || "Select Core Profile"}
                    renderValue={() => profile || "Select Core Profile"}
                    sx={{ width: 1 }}
                    onChange={profileFilter}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    className="fix-desktop-select-multiline"
                  >
                    <MenuItem hidden disabled value="">
                      Select Branch/Department
                    </MenuItem>
                    <MenuItem value="Aerospace Engineering">
                      Aerospace Engineering
                    </MenuItem>
                    <MenuItem value="Applied Mechanics">Applied Mechanics</MenuItem>
                    <MenuItem value="Biomedical Engineering">
                      Biomedical Engineering
                    </MenuItem>
                    <MenuItem value="Bioscience and Bioengineering">
                      Bioscience and Bioengineering
                    </MenuItem>
                    <MenuItem value="Biotechnology">Biotechnology</MenuItem>
                    <MenuItem value="Chemical Engineering">
                      Chemical Engineering
                    </MenuItem>
                    <MenuItem value="Chemistry">Chemistry</MenuItem>
                    <MenuItem value="Civil Engineering">Civil Engineering</MenuItem>
                    <MenuItem value="Computer Science and Engineering">
                      Computer Science and Engineering
                    </MenuItem>
                    <MenuItem value="Economics">Economics</MenuItem>
                    <MenuItem value="Electrical Engineering">
                      Electrical Engineering
                    </MenuItem>
                    <MenuItem value="Electrical and Electronics Engineering">
                      Electrical and Electronics Engineering
                    </MenuItem>
                    <MenuItem value="Electronics and Communication Engineering">
                      Electronics and Communication Engineering
                    </MenuItem>
                    <MenuItem value="Engineering Design">
                      Engineering Design
                    </MenuItem>
                    <MenuItem value="Humanities and Social Sciences">
                      Humanities and Social Sciences
                    </MenuItem>
                    <MenuItem value="Management/Business Studies">
                      Management/Business Studies
                    </MenuItem>
                    <MenuItem value="Mathematics/Mathematics and Computing">
                      Mathematics/Mathematics and Computing
                    </MenuItem>
                    <MenuItem value="Mechanical Engineering">
                      Mechanical Engineering
                    </MenuItem>
                    <MenuItem value="Metallurgical and Materials Engineering">
                      Metallurgical and Materials Engineering
                    </MenuItem>
                    <MenuItem value="Mining Engineering">
                      Mining Engineering
                    </MenuItem>
                    <MenuItem value="Ocean Engineering">Ocean Engineering</MenuItem>
                    <MenuItem value="Physics/Engineering Physics">
                      Physics/Engineering Physics
                    </MenuItem>
                  </Select>
                </div>
              )}
              {coreProfile && profile && (
                <div className="my-0">
                  <div className="flex w-full justify-end cursor-pointer">
                    <p
                      className="underline text-blue-500 fix-desktop-select-multiline"
                      onClick={(e) => {
                        setNonCoreProfile(false);
                        setCoreProfile(false);
                        setSpecialization(null);
                        setProfile(null);
                        setBranch(null);
                        setSpecialization(null);
                        profileDelete(profile);
                        coreDelete("core");
                        nonCoreDelete("non-core");
                        // specializationDelete("specialization");
                      }}
                    >
                      clear
                    </p>
                  </div>
                  <Select
                    // multiple
                    displayEmpty
                    required
                    value={specialization || "Select Specialization"}
                    renderValue={() => specialization || "Select Specialization"}
                    sx={{ width: 1 }}
                    onChange={specializationFilter}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    className="fix-desktop-select-multiline"
                  >
                    {
                      special[profile]?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))
                    }
                  </Select>
                </div>
              )}

              {/* {profile ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                width: 1,
                p:'0.75rem 0.75rem 0rem 0.75rem',
              }}
            >
              <Chip
sx={{
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
  }}
                key={profile}
                label={profile}
                onDelete={() => {
                  // coreDelete(core);
                }}
              />
            </Box>
          ) : null} */}
            </div>
            {/* <div className="my-3">
          <Select
            // multiple
            displayEmpty
            required
            value={appearing}
            sx={{ width: 1 }}
            renderValue={() => "Mock Interview Profile"}
            onChange={coreFilter}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="Core">Core</MenuItem>
            <MenuItem value="Non-Core">Non-Core</MenuItem>
          </Select>
          {core ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                width: 1,
                p: 2,
              }}
            >
              <Chip
sx={{
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
  }}
                key={core}
                label={core}
                onDelete={() => {
                  coreDelete(core);
                }}
              />
            </Box>
          ) : null}
        </div> */}
          </div>
          <div style={{ alignItems: 'flex-start', backgroundColor: 'rgb(255, 222, 222)', flexWrap: 'wrap', gap: '0.5rem', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: "0.75rem 0.5rem" }} className="flex justify-between bg-white p-3 my-3 rounded-lg s-mobile-card s-mobile-card-mx ">

            {/* <div style={{ alignItems: 'flex-start', flexWrap: 'wrap', gap: '0.5rem',boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: "0.75rem 0.5rem" }} className="flex justify-between bg-white p-3 mt-3 rounded-lg s-mobile-card"> */}
            <div className="w-[48%] relative" style={{ alignItems: "flex-start" }}>

              <Select
                // multiple
                displayEmpty
                required
                value={year}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    if (year == null || year === "") return "Year"
                    return year
                  }
                  return "Year"
                }}
                onChange={yearFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={"1st year"}>1st year</MenuItem>
                <MenuItem value={"2nd year"}>2nd year</MenuItem>
                <MenuItem value={"3rd year"}>3rd year</MenuItem>
                <MenuItem value={"4th year"}>4th year</MenuItem>
                <MenuItem value={"5th year"}>5th year</MenuItem>
                <MenuItem value={"6th year"}>6th year</MenuItem>
              </Select>
              {
                (year && window.innerWidth < 600) ? (
                  <button onClick={() => { setYear(null); yearDelete(year); }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                ) : null
              }
              {(year && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: 2,
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={year}
                    label={year}
                    onDelete={() => {
                      yearDelete(year);
                    }}
                  />
                </Box>
              ) : null}
            </div>
            <div className="w-[48%] relative">
              <div className="">
                <Select
                  // multiple
                  displayEmpty
                  required
                  value={program}
                  sx={{ width: 1 }}
                  renderValue={() => {
                    if (window.innerWidth < 600) {
                      if (program == null || program === "") return "Program"
                      return program === "btech"
                        ? "B.Tech"
                        : program === "dual"
                          ? "Dual Degree (B.Tech + M.Tech)"
                          : program === "dualbsms"
                            ? "Dual Degree (BS + MS)"
                            : program === "mtech"
                              ? "M.Tech"
                              : program === "ms"
                                ? "MS (by Research)"
                                : program === "mba"
                                  ? "MBA"
                                  : program === "msc"
                                    ? "M.Sc"
                                    : program === "ima"
                                      ? "Intergrated MA"
                                      : "PhD"
                    }
                    else return "Program"
                  }}
                  onChange={programFilter}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="btech">B.Tech</MenuItem>
                  <MenuItem value="dual">Dual Degree (B.Tech + M.Tech)</MenuItem>
                  <MenuItem value="dualbsms">Dual Degree (BS + MS)</MenuItem>
                  <MenuItem value="mtech">M.Tech</MenuItem>
                  <MenuItem value="ms">MS (by Research)</MenuItem>
                  <MenuItem value="mba">MBA</MenuItem>
                  <MenuItem value="msc">M.Sc</MenuItem>
                  <MenuItem value="ima">Intergrated MA</MenuItem>
                  <MenuItem value="phd">PhD</MenuItem>
                </Select>
                {
                  (program && window.innerWidth < 600) ? (
                    <button onClick={() => { setProgram(null); programDelete(program); }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                  ) : null
                }
              </div>
              {(program && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: 2,
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={program}
                    label={
                      program === "btech"
                        ? "B.Tech"
                        : program === "dual"
                          ? "Dual Degree (B.Tech + M.Tech)"
                          : program === "dualbsms"
                            ? "Dual Degree (BS + MS)"
                            : program === "mtech"
                              ? "M.Tech"
                              : program === "ms"
                                ? "MS (by Research)"
                                : program === "mba"
                                  ? "MBA"
                                  : program === "msc"
                                    ? "M.Sc"
                                    : program === "ima"
                                      ? "Intergrated MA"
                                      : "PhD"
                    }
                    onDelete={() => {
                      programDelete(program);
                    }}
                  />
                </Box>
              ) : null}
            </div>
            <div className="w-[100%] relative">

              <Select
                // multiple
                displayEmpty
                required
                value={branch}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    if (branch == null || branch === "") return "Branch"
                    return branch
                  } else return "Branch"
                }}
                onChange={branchFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem hidden disabled value="">
                  Select Branch/Department
                </MenuItem>
                {branches.map((branch, index) => (
                  <MenuItem key={index} value={branch}>
                    {branch}
                  </MenuItem>
                ))}
                {/* <MenuItem value="Aerospace Engineering">
              Aerospace Engineering
            </MenuItem>
            <MenuItem value="Applied Mechanics">Applied Mechanics</MenuItem>
            <MenuItem value="Biomedical Engineering ">
              Biomedical Engineering
            </MenuItem>
            <MenuItem value="Bioscience and Bioengineering">
              Bioscience and Bioengineering
            </MenuItem>
            <MenuItem value="Biotechnology">Biotechnology</MenuItem>
            <MenuItem value="Chemical Engineering">
              Chemical Engineering
            </MenuItem>
            <MenuItem value="Chemistry">Chemistry</MenuItem>
            <MenuItem value="Civil Engineering">Civil Engineering</MenuItem>
            <MenuItem value="Computer Science and Engineering">
              Computer Science and Engineering
            </MenuItem>
            <MenuItem value="Economics">Economics</MenuItem>
            <MenuItem value="Electrical Engineering">
              Electrical Engineering
            </MenuItem>
            <MenuItem value="Electrical and Electronics Engineering">
              Electrical and Electronics Engineering
            </MenuItem>
            <MenuItem value="Electronics and Communication Engineering">
              Electronics and Communication Engineering
            </MenuItem>
            <MenuItem value="Engineering Design">Engineering Design</MenuItem>
            <MenuItem value="Humanities and Social Sciences">
              Humanities and Social Sciences
            </MenuItem>
            <MenuItem value="Management/Business Studies">
              Management/Business Studies
            </MenuItem>
            <MenuItem value="Mathematics/Mathematics and Computing">
              Mathematics/Mathematics and Computing
            </MenuItem>
            <MenuItem value="Mechanical Engineering">
              Mechanical Engineering
            </MenuItem>
            <MenuItem value="Metallurgical and Materials Engineering">
              Metallurgical and Materials Engineering
            </MenuItem>
            <MenuItem value="Mining Engineering">Mining Engineering</MenuItem>
            <MenuItem value="Ocean Engineering">Ocean Engineering</MenuItem>
            <MenuItem value="Physics/Engineering Physics">
              Physics/Engineering Physics
            </MenuItem> */}
              </Select>
              {
                (branch && window.innerWidth < 600) ? (
                  <button onClick={() => { setBranch(null); branchDelete(branch); }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                ) : null
              }
              {(branch && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: 2,
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={branch}
                    label={branch}
                    onDelete={() => {
                      branchDelete(branch);
                    }}
                  />
                </Box>
              ) : null}
            </div>

            <div className="w-[100%] relative">

              <Select
                // multiple
                displayEmpty
                required
                value={appearing}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    if (appearing == null || appearing === "") return "Appearing For"
                    return appearing
                  } else return "Appearing For"
                }}
                onChange={appearingFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={1}>Campus Placements</MenuItem>
                <MenuItem value={2}>Internship Interviews</MenuItem>
                <MenuItem value={3}>Both</MenuItem>
              </Select>
              {
                (appearing && window.innerWidth < 600) ? (
                  <button onClick={() => { setAppearing(null); appearingDelete(appearing); }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                ) : null
              }
              {(appearing && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: 2,
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={appearing}
                    label={appearing}
                    onDelete={() => {
                      appearingDelete(appearing);
                    }}
                  />
                </Box>
              ) : null}
            </div>

            <div className="w-[48%] relative">

              <Select
                // multiple
                displayEmpty
                required
                value={appearing}
                sx={{ width: 1 }}
                renderValue={() =>
                  coreProfile
                    ? "Core"
                    : nonCoreProfile
                      ? "Non-Core"
                      : "Mock Interview Profile"
                }
                onChange={(e) => {
                  if (e.target.value === "Non-Core") {
                    setNonCoreProfile(true);
                    setCoreProfile(false);
                    setSpecialization(null);
                    setProfile(null);
                    coreDelete("core");
                    nonCoreFilter(e);
                  } else if (e.target.value === "Core") {
                    setNonCoreProfile(false);
                    setCoreProfile(true);
                    setSpecialization(null);
                    setProfile(null);
                    nonCoreDelete("non-core");
                    coreFilter(e);
                  } else {
                    setNonCoreProfile(false);
                    setCoreProfile(false);
                    setSpecialization(null);
                    setProfile(null);
                    coreDelete("core");
                    nonCoreDelete("non-core");
                  }
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Core">Core</MenuItem>
                <MenuItem value="Non-Core">Non-Core</MenuItem>
              </Select>

              {
                ((coreProfile || nonCoreProfile) && window.innerWidth < 600) ? (
                  <button onClick={() => {
                    setNonCoreProfile(false);
                    setCoreProfile(false);
                    setSpecialization(null);
                    setProfile(null);
                    profileDelete(profile);
                    coreDelete("core");
                    nonCoreDelete("non-core");
                  }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                ) : null
              }

              {nonCoreProfile && (
                <div className="mt-3">
                  <Select
                    // multiple
                    displayEmpty
                    required
                    value={profile || "Select Non-Core Profile"}
                    renderValue={() => profile || "Select Non-Core Profile"}
                    sx={{ width: 1 }}
                    MenuProps={MenuProps}
                    onChange={profileFilter}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="Select Profile" disabled>
                      Select Profile
                    </MenuItem>
                    <MenuItem value="Data Science">Data Science</MenuItem>
                    <MenuItem value="Software">Software</MenuItem>
                    <MenuItem value="Banking and Finance">
                      Banking and Finance
                    </MenuItem>
                    <MenuItem value="Consulting">Consulting</MenuItem>
                    <MenuItem value="Analytics">Analytics</MenuItem>
                    <MenuItem value="Product Management">
                      Product Management
                    </MenuItem>
                    <MenuItem value="Operations">Operations</MenuItem>
                    <MenuItem value="Supply Chain">Supply Chain</MenuItem>
                    <MenuItem value="FMCG">FMCG</MenuItem>
                    <MenuItem value="Operations Research">
                      Operations Research
                    </MenuItem>
                    <MenuItem value="Sales">Sales</MenuItem>
                    <MenuItem value="Inventory Management">
                      Inventory Management
                    </MenuItem>
                    <MenuItem value="Logistics">Logistics</MenuItem>
                  </Select>
                </div>
              )}







              {/* {profile ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                width: 1,
                p: 2,
              }}
            >
              <Chip
sx={{
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
  }}
                key={profile}
                label={profile}
                onDelete={() => {
                  // coreDelete(core);
                }}
              />
            </Box>
          ) : null} */}
            </div>

            {/* <div className="w-[48%] border-solid border-[1px] border-gray-300 px-2 rounded-sm">
          <div className="my-3 flex flex-col">
            <Typography variant="body1" gutterBottom>
              Choose a Date{" "}
              <FontAwesomeIcon
                onClick={() => {
                  setShowCalender(!showCalender);
                }}
                className="cursor-pointer"
                icon={faCalendarDays}
              />
            </Typography>
            {selectedDate && (
              <div
                className="flex relative justify-center items-center"
                style={{ width: "max-content" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: "20px" }}
                >
                  {selectedDate.toLocaleDateString()}
                </Button>
                <FontAwesomeIcon
                  icon={faTrash}
                  className="text-red-600 pb-[20px] ml-[10px]  cursor-pointer"
                  onClick={(e) => {
                    setSelectedDate(null);
                  }}
                />
              </div>
            )}
            {showCalender && (
              <div className="w-full flex flex-row justify-center">
                <Calendar
                  minDate={new Date().setDate(new Date().getDate())}
                  zIndex={0}
                  value={selectedDate}
                  onChange={(e) => {
                    console.log(new Date(e), "----------");
                    setSelectedDate(new Date(e));
                    setShowCalender(!showCalender);
                    dateFilter(e);
                  }}
                  className=""
                  mapDays={({ date, selectedDate, isSameDate }) => {
                    let props = {};
                    if (isSameDate(date, selectedDate)) {
                      props.style = {
                        color: "#fff",
                        backgroundColor: "#00a86b",
                        border: "1px solid #000",
                      };
                      return props;
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div> */}
            <div className="ml-[6px] tt ml-auto s-mobile-card" style={{ position: 'relative', flex: 1 }}>
              <button style={{ width: '100%', padding: '0px', whiteSpace: 'nowrap' }} class="button-13" onClick={() => setDatePickerMobile(!datePickerMobile)} role="button"><AiTwotoneCalendar style={{ display: 'inline-block', verticalAlign: 'inherit' }} /> {selectedDate !== null
                ? moment(selectedDate).format("DD MMM YYYY") + " "
                : "Select Date"} {
                  selectedDate !== null && <button style={{ width: 'auto', float: 'right', padding: '0px 6px' }} class="button-13" onClick={(e) => { e.stopPropagation(); setSelectedDate(null); dateDelete(date); }} ><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1px)" }} /></button>
                }</button>

              <span style={{ background: 'black', color: 'white', padding: '3px 5px', top: '-146px', textAlign: 'left' }} className="calendar-mobile-tooltip tttext">
                <Calendar
                  className="s-mobile-calendar-calendar"
                  // minDate={new Date().setDate(new Date().getDate())}
                  zIndex={1}
                  value={selectedDate}
                  onChange={(e) => { setSelectedDate(new Date(e)); setDatePickerMobile(!datePickerMobile); dateFilter(e); }}
                  mapDays={({ date, selectedDate, isSameDate }) => {
                    let props = {};
                    if (isSameDate(date, selectedDate)) {
                      props.style = {
                        color: "#fff",
                        backgroundColor: "#00a86b",
                        border: "1px solid #000"
                      };
                      return props;
                    }
                  }}
                />
              </span>
            </div>

            {coreProfile && (
              <div className="w-[48%]">
                <div className="my-0">

                  <Select
                    // multiple
                    displayEmpty
                    required
                    value={profile || "Select Core Profile"}
                    renderValue={() => profile || "Select Core Profile"}
                    sx={{ width: 1 }}
                    onChange={profileFilter}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem hidden disabled value="">
                      Select Branch/Department
                    </MenuItem>
                    <MenuItem value="Aerospace Engineering">
                      Aerospace Engineering
                    </MenuItem>
                    <MenuItem value="Applied Mechanics">Applied Mechanics</MenuItem>
                    <MenuItem value="Biomedical Engineering">
                      Biomedical Engineering
                    </MenuItem>
                    <MenuItem value="Bioscience and Bioengineering">
                      Bioscience and Bioengineering
                    </MenuItem>
                    <MenuItem value="Biotechnology">Biotechnology</MenuItem>
                    <MenuItem value="Chemical Engineering">
                      Chemical Engineering
                    </MenuItem>
                    <MenuItem value="Chemistry">Chemistry</MenuItem>
                    <MenuItem value="Civil Engineering">Civil Engineering</MenuItem>
                    <MenuItem value="Computer Science and Engineering">
                      Computer Science and Engineering
                    </MenuItem>
                    <MenuItem value="Economics">Economics</MenuItem>
                    <MenuItem value="Electrical Engineering">
                      Electrical Engineering
                    </MenuItem>
                    <MenuItem value="Electrical and Electronics Engineering">
                      Electrical and Electronics Engineering
                    </MenuItem>
                    <MenuItem value="Electronics and Communication Engineering">
                      Electronics and Communication Engineering
                    </MenuItem>
                    <MenuItem value="Engineering Design">
                      Engineering Design
                    </MenuItem>
                    <MenuItem value="Humanities and Social Sciences">
                      Humanities and Social Sciences
                    </MenuItem>
                    <MenuItem value="Management/Business Studies">
                      Management/Business Studies
                    </MenuItem>
                    <MenuItem value="Mathematics/Mathematics and Computing">
                      Mathematics/Mathematics and Computing
                    </MenuItem>
                    <MenuItem value="Mechanical Engineering">
                      Mechanical Engineering
                    </MenuItem>
                    <MenuItem value="Metallurgical and Materials Engineering">
                      Metallurgical and Materials Engineering
                    </MenuItem>
                    <MenuItem value="Mining Engineering">
                      Mining Engineering
                    </MenuItem>
                    <MenuItem value="Ocean Engineering">Ocean Engineering</MenuItem>
                    <MenuItem value="Physics/Engineering Physics">
                      Physics/Engineering Physics
                    </MenuItem>
                  </Select>
                </div>
              </div>
            )}

            {coreProfile && profile && (
              <div className="w-[48%]">
                <div className="my-0">

                  <Select
                    // multiple
                    displayEmpty
                    required
                    value={specialization || "Select Specialization"}
                    renderValue={() => specialization || "Select Specialization"}
                    sx={{ width: 1 }}
                    onChange={specializationFilter}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {
                      special[profile]?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))
                    }
                  </Select>
                </div>

              </div>

            )}

            {/* <div className="my-3">
          <Select
            // multiple
            displayEmpty
            required
            value={appearing}
            sx={{ width: 1 }}
            renderValue={() => "Mock Interview Profile"}
            onChange={coreFilter}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="Core">Core</MenuItem>
            <MenuItem value="Non-Core">Non-Core</MenuItem>
          </Select>
          {core ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                width: 1,
                p: 2,
              }}
            >
              <Chip
sx={{
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
  }}
                key={core}
                label={core}
                onDelete={() => {
                  coreDelete(core);
                }}
              />
            </Box>
          ) : null}
        </div> */}
          </div>

          {/* <div className="my-3 flex flex-col">
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
          }}
          gutterBottom
        >
          Choose a Date{" "}
          <FontAwesomeIcon
            onClick={() => {
              setShowCalender(!showCalender);
            }}
            className="cursor-pointer"
            icon={faCalendarDays}
          />
        </Typography>
        {selectedDate && (
          <div
            className="flex relative justify-center items-center"
            style={{ width: "max-content" }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginBottom: "20px" }}
            >
              {selectedDate.toLocaleDateString()}
            </Button>
            <FontAwesomeIcon
              icon={faTrash}
              className="text-red-600 pb-[20px] ml-[10px]  cursor-pointer"
              onClick={() => {
                setSelectedDate(null);
              }}
            />
          </div>
        )}
        {showCalender && (
          <div className="w-full flex flex-row justify-center">
            <Calendar
              minDate={new Date().setDate(new Date().getDate())}
              zIndex={0}
              value={selectedDate}
              onChange={(e) => {
                console.log(new Date(e), "----------");
                setSelectedDate(new Date(e));
                setShowCalender(!showCalender);
                dateFilter(e);
              }}
              mapDays={({ date, selectedDate, isSameDate }) => {
                let props = {};
                if (isSameDate(date, selectedDate)) {
                  props.style = {
                    color: "#fff",
                    backgroundColor: "#00a86b",
                    border: "1px solid #000",
                  };
                  return props;
                }
              }}
            />
          </div>
        )}
      </div> */}

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: '#dbf2fd' }} sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}>
                <TableRow>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}>Sr. No.</TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}>Mock ID</TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Profile/Sub-Profile
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Interview Schedule
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Interviewer
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Student
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Feedback
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}>
                {ans !== [] &&
                  ans.slice(pageNo[0], pageNo[1]).map((item, index) => {
                    if (nonCoreProfile) {
                      if (nonprofiles.indexOf(ans[pageNo[0] + index].result.profile) === -1) {
                        return <></>;
                      }
                    }

                    if (coreProfile) {
                      if (nonprofiles.indexOf(ans[pageNo[0] + index].result.profile) !== -1) {
                        return <></>;
                      }
                    }

                    if (profile) {
                      if (profile !== ans[pageNo[0] + index].result.profile) {
                        return <></>;
                      }
                    }

                    if (program) {
                      if (program !== ans[pageNo[0] + index].resStud.program) {
                        return <></>;
                      }
                    }

                    if (year) {
                      if (year !== ans[pageNo[0] + index].resStud.year) {
                        return <></>;
                      }
                    }

                    if (appearing) {
                      if (appearing === "Internship Interviews") {
                        if (!ans[pageNo[0] + index].resStud.appearingFor.two) {
                          return <></>;
                        }
                      }
                      else if (appearing === "Campus Placements") {
                        if (!ans[pageNo[0] + index].resStud.appearingFor.one) {
                          return <></>;
                        }
                      }
                      else {
                        if (!ans[pageNo[0] + index].resStud.appearingFor.one || !ans[pageNo[0] + index].resStud.appearingFor.two) {
                          return <></>;
                        }
                      }
                    }

                    if (branch) {
                      if (branch !== ans[pageNo[0] + index].resStud.branch) {
                        return <></>;
                      }
                    }

                    {/* if (name) {
                  if (name !== ans[pageNo[0] + index].resStud.fname + " " + ans[pageNo[0] + index].resStud.lname) {
                    return <></>;
                  }
                } */}

                    if (selectedDate) {
                      if (moment(selectedDate).format("DD/MM/YYYY") !== ans[pageNo[0] + index].result.date) {
                        return <></>;
                      }
                    }


                    return (
                      ans.find((o) => o.intReviews?.mock_id === item.mocksStud?.mockId) && (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            backgroundColor: `${index % 2 === 1 ? "#f2f2f2" : "#ffffff"}`,
                          }}
                        >
                          <TableCell
                            sx={{ textAlign: "center" }}
                            component="th"
                            scope="row"
                          >
                            {pageNo[0] + index + 1}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", whiteSpace: 'nowrap' }} align="right">
                            {ans[pageNo[0] + index].mocksStud.mockId} <FaCopy style={{ display: 'inline', fontSize: '1rem' }} onClick={() => handleCopy(ans[pageNo[0] + index].mocksStud.mockId)} />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }} align="right">
                            {
                              ans[pageNo[0] + index].result.subprofile ? <span style={{ color: 'rgba(0,0,0,0.87)' }}>{`${ans[pageNo[0] + index].result.subprofile}`}<span style={{ color: 'rgba(0,0,0,1)', fontWeight: '500' }}>{`(${ans[pageNo[0] + index].result.profile})`}</span></span> : <span>{`${ans[pageNo[0] + index].result.profile}`}</span>
                            }
                            {/* {interviews[pageNo[0] + index].profile}{" "}
                        {interviews[pageNo[0] + index].subprofile ? "/" + interviews[pageNo[0] + index].subprofile
                          : ""}
                          {console.log('here new -> ', interviews ,'<=>',interviews[pageNo[0] + index].profile,"<=>", interviews[pageNo[0] + index].subprofile, '<=>',pageNo[0] + index)} */}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", width: "200px" }} align="right">
                            {t24HDecimalTo12HFormat(
                              ans[pageNo[0] + index].result.fromTime
                            )}{" "}
                            -{" "}
                            {t24HDecimalTo12HFormat(
                              ans[pageNo[0] + index].result.toTime
                            )}
                            <br />
                            {ans[pageNo[0] + index].result.date}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center", width: "200px" }} align="right">
                            <a
                              href={`${process.env.REACT_APP_FRONTEND_SERVER_URL
                                }/admin/interviewer/${ans[pageNo[0] + index].resInt.id
                                }`}
                              target="_blank"
                              className="text-blue-700"
                            >
                              {ans[pageNo[0] + index].resInt.fname +
                                " " +
                                ans[pageNo[0] + index].resInt.lname}

                            </a>
                            <br />({ans[pageNo[0] + index].resInt.designation},{" "}
                            {ans[pageNo[0] + index].resInt.currCompany})
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }} align="right">
                            <a
                              href={`${process.env.REACT_APP_FRONTEND_SERVER_URL
                                }/admin/student/${ans[pageNo[0] + index].resStud.id}`}
                              target="_blank"
                              className="text-blue-700"
                            >
                              {ans[pageNo[0] + index].resStud.fname +
                                " " +
                                ans[pageNo[0] + index].resStud.lname}{" "}

                            </a>
                            <br />(
                            {programValues[ans[pageNo[0] + index].resStud.program]}
                            ,&nbsp;
                            {ans[pageNo[0] + index].resStud.year},{" "}
                            {ans[pageNo[0] + index].resStud.branch})
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }} align="right">
                            <Link
                              to={
                                "/feedback/student/vc/" +
                                ans[pageNo[0] + index].mocksStud.mockId
                              }
                              target="_blank"
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "blue",
                                }}
                                style={{ margin: "10px 10px" }}
                              >
                                View Feedback
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <div className="flex flex-row justify-center mt-4 my-2">
            <Pagination
              count={Math.ceil(ans?.length / 100)}
              page={page}
              onChange={handleChange}
              color="primary"
            />
          </div>
        </div>) :
        (
          (
            <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
              <Oval
                height={120}
                width={120}
                color="#306bc9"
                wrapperStyle={{ padding: "50%, 50%" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#90caf9"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          )
        )}
    </>
  );
};

export default Interviewer;