// export const devConfig = {
//   topic: " test topic",
//   name: "Chrome",
//   password: "pass",
//   role: 1,
// };
export const devConfig = {
  topic: "",
  name: "",
  password: "",
  features: ["video", "audio", "settings", "users", "chat", "share"],
  role: 1,
  signature: "",
};
