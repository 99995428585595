import React from "react";
import { useEffect } from "react";
import axios from "axios";

import "./Bookmark.css";
import Interview from "../cards/Interview";
import SentRequest from "../cards/SentRequest";
import AcceptedRequest from "../cards/AcceptedRequest";
import UpcomingInterview from "../cards/UpcomingInterview2";
import CompletedInterview from "../cards/CompletedInterview";
import {BASE} from "../../../constants/endpoints";

const Bookmark = (userDetails) => {
  const user = userDetails.user;

  // Search Interviews
  const [interviews, setInterviews] = React.useState();
  useEffect(() => {
    async function fetchData() {
      const req = await axios.get(
        `${BASE}/send/interviewersdemo`
      );
      setInterviews(req.data);
      console.log(req.data);
    }
    fetchData();
  }, []);

  // Sent Requests
  const [sent, setSent] = React.useState();
  useEffect(() => {
    async function fetchData() {
      const req = await axios.get(`${BASE}/api/get`);
      setSent(req.data);
    }
    fetchData();
  }, []);

  // Accepted Requests
  const [accepted, setAccepted] = React.useState();
  useEffect(() => {
    async function fetchData() {
      const req = await axios.get(`${BASE}/api/get`);
      setAccepted(req.data);
    }
    fetchData();
  }, []);

  // Upcoming Interviews
  const [upcoming, setUpcoming] = React.useState();
  useEffect(() => {
    async function fetchData() {
      const req = await axios.get(`${BASE}/api/get`);
      setUpcoming(req.data);
      // console.log(req.data);
    }
    fetchData();
  }, []);

  return (
    <div className="h-full flex justify-center fixed top-0 z-20" id="bookmark">
      <div
        className="bookmark-container relative bg-white rounded-md p-2 text-center"
        id="bookmark-container"
      >
        <h2>Bookmarked Interviewers</h2>
        <div
          className="desc-cross"
          onClick={() => {
            document.querySelector(".bookmark").style.visibility = "hidden";
          }}
        >
          <i className="fa-solid fa-circle-xmark"></i>
        </div>
        <div className="bookmark-item">
          {/* Search Interviews */}
          {interviews?.map((result) => (
            <>
              <Interview
                user={user}
                name={result.name}
                desg={result.designation}
                price={result.price}
                profile={result.profile}
                id={result._id}
                logo={require("../../../images/amazon.png")}
                setShareUrl="youtube"
              />
            </>
          ))}

          {/* Sent Requests */}
          {sent?.map((result) => (
            <>
              <SentRequest
                user={user}
                name={result.toname}
                desg={result.designation}
                price={result.price}
                profile={result.profile}
                slot={result.slot}
                status={result.status}
                id={result._id}
                logo={require("../../../images/amazon.png")}
              />
            </>
          ))}

          {/* Accepted Requests */}
          {accepted?.map((result) => (
            <AcceptedRequest
              user={user}
              name={result.toname}
              desg={result.designation}
              price={result.price}
              profile={result.profile}
              slot={result.slot}
              status={result.status}
              payment={result.payment}
              id={result._id}
              logo={require("../../../images/amazon.png")}
            />
          ))}

          {/* Upcoming Interviews */}
          {upcoming?.map((result) => (
            <>
              <UpcomingInterview
                user={user}
                name={result.toname}
                desg={result.designation}
                price={result.price}
                profile={result.profile}
                slot={result.slot}
                status={result.status}
                id={result._id}
                payment={result.payment}
                update="active"
                logo={require("../../../images/amazon.png")}
              />
            </>
          ))}

          {/* Completed Interviews */}
          <>
            <CompletedInterview />
            <CompletedInterview />
          </>
        </div>
      </div>
    </div>
  );
};

export default Bookmark;
