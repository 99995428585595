import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDKyXueshrkkkq7NeNXPcLzKp1-jw3lHPs",
  authDomain: "interwiu-fyzen.firebaseapp.com",
  projectId: "interwiu-fyzen",
  storageBucket: "interwiu-fyzen.appspot.com",
  messagingSenderId: "235616940657",
  appId: "1:235616940657:web:598823fecd9373e5fb61d0",
  measurementId: "G-S9KLZB3FSX",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();