import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../constants/variables";
import Navbarlogedin from "./Navbarlogedin";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Completed from "./Interviews/Completed";
import Upcoming from "./Interviews/Upcoming";
import UserContext from "../../contexts/UserContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const MockInterviews = () => {
  const [value, setValue] = React.useState("1");
  const { user } = useContext(UserContext);
  console.log(user);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Current Students</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <Box
        sx={{
          // mt: 4,
          typography: "body1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "20px",
          paddingBottom: "100px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
          }}
          style={{fontSize: 'clamp(20px,6vw,3rem)', marginTop: 'clamp(20px,-6vw,3rem)', marginBottom: 'clamp(20px,-6vw,3rem)'}}
        >
          Mock Interviews
        </Typography>
        <TabContext value={value}>
          <Box>
            <TabList  className="s-mobile-tabs-list-center" onChange={handleChange} aria-label="lab API tabs example">
              <Tab className="s-mobile-one-word-per-line" label="Upcoming Interviews" value="1" />
              <Tab className="s-mobile-one-word-per-line" label="Completed Interviews" value="2" />
            </TabList>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TabPanel className="s-mobile-padding-tab" value="1">
              <Upcoming />
            </TabPanel>
            <TabPanel className="s-mobile-padding-tab" value="2">
              <Completed />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>{" "}
    </>
  );
};

export default MockInterviews;
