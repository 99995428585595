import { Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./Validator.css";
import { BASE } from "../../../../constants/endpoints";
import { Link, useParams, useSearchParams, useNavigate } from "react-router-dom";
import UserContext from "contexts/UserContext";
import {
    ZoomContext,
    ZoomMediaContext,
    ZoomUserContext,
} from "../../Context/globalContext";
import TextLoading from "../CallEndPages/TextLoading";
import axios from "axios";
import ZoomVideo from "@zoom/videosdk";
import toast from "react-hot-toast";
import { toastStyle } from "../../../../utility/helper";
import { error } from "jquery";
// import ModalDetails from "../Modals/Instructions";

const ValidatorZoom = ({ setMediaStream }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const client = useContext(ZoomContext);
    const [userAuthorised, setUserAuthorised] = useState(false);
    const meetingArgs = useContext(ZoomUserContext);
    const { mock_id: ROOM_ID, id: accountId, name: user } = useParams();
    const { user: currUser, setUser: setCurrUser } = useContext(UserContext);
    const [loading, setIsLoading] = useState(true);
    const [sessionType, setSessionType] = useState("Session");
    const [join, setJoin] = useState(false);
    const handleJoin = () => setJoin(true)
    const [instructions, setInstructions] = useState({});

    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true)
    const handleModalClose = () => setModalOpen(false)

    meetingArgs.topic = ROOM_ID;
    meetingArgs.password = ROOM_ID;
    meetingArgs.name = accountId;

    const getToken = async (options) => {
        let result = await fetch(
            `${BASE}/zoom/generate-sdk-token`,
            options
        );
        result = await result.json();
        return result;
    };
    useEffect(() => {
        const getSessionType = async () => {
            try {
                const res = await axios.get(`${BASE}/send/getSessType/${ROOM_ID}`);
                const sessType = res.data.sessType;
                setSessionType(sessType === "mentorship" ? "Mentorship Session" : sessType === "resume" ? "Resume Building Session" : "Mock Interview");
            } catch (err) {
                console.log(err);
            }
        };

        getSessionType();
    }, [ROOM_ID]);

    useEffect(() => {
        const authorizeUser = async () => {
            setUserAuthorised(currUser?.id === accountId);
            if (currUser?.id === accountId && !meetingArgs.signature) {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(meetingArgs),
                };
                const tokenResponse = await getToken(requestOptions);
                meetingArgs.signature = tokenResponse;
                setIsLoading(false);
            }
        };

        authorizeUser();
    }, [currUser, accountId, meetingArgs]);

    useEffect(() => {
        const joinMeeting = async () => {
            try {
                await client.init("en-US", "Global", { patchJsMedia: true });
                await client.join(meetingArgs.topic, String(meetingArgs.signature), meetingArgs.name, meetingArgs.password, 20);
                const stream = client.getMediaStream();
                setMediaStream(stream);
                navigate(`/vc/${ROOM_ID}/${accountId}/${user}`);
            } catch (error) {
                console.log("Error Joining Meeting", error);
                if (error.reason === "duplicated operation") {
                    console.log("Redirecting to call");
                    const stream = client.getMediaStream();
                    setMediaStream(stream);
                    navigate(`/vc/${ROOM_ID}/${accountId}/${user}`);
                }
                else if (error.errorCode === 3009) {
                    toast.error("Joining from multiple devices detected. Please rejoin after 5 minutes", {
                        ...toastStyle.error,
                    });
                    setJoin(false)
                } else {
                    toast.error("Some error occured. Please try again in some time.", {
                        ...toastStyle.error,
                    });
                    setJoin(false)
                }

            }
        };

        if (join) {
            joinMeeting();
        }

        return () => {
            ZoomVideo.destroyClient();
        };
    }, [join, client, meetingArgs, ROOM_ID, accountId, user, navigate]);

    useEffect(() => {
        if (accountId.startsWith('S')) {
            setInstructions({
                title: "Session Details",
                text: [
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
                    "do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim",
                    "ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                ]
            })
        }
    }, [accountId])

    return (
        <div className="validator">
            {/* <ModalDetails open={modalOpen} handleClose={handleModalClose} Text={instructions} handleJoin={handleJoin} /> */}
            <div className="validator__box">
                <p className="top__text">Join this {sessionType}?</p>
                <div className="buttons">
                    <Link
                        style={{
                            pointerEvents: userAuthorised ? "auto" : "none"
                        }}
                        target="_self"
                    >
                        <Button
                            variant={userAuthorised && !join ? "contained" : "outlined"}
                            size="large"
                            disabled={join}
                            style={{
                                pointerEvents: userAuthorised && !join ? "auto" : "none"
                            }}
                            onClick={() => setJoin(true)}
                            sx={{
                                backgroundColor: userAuthorised && !join ? "success.main" : "default.main"
                            }}
                        >
                            {userAuthorised ? (join ? <TextLoading text="Joining..." /> : "Join Now") : <TextLoading text="Verifying..." />}
                        </Button>
                    </Link>
                    <Link to="/" target="_blank">
                        <Button
                            variant="outlined"
                            style={{ backgroundColor: "white" }}
                            size="large"
                            onClick={() => window.close()}
                            color="error"
                        >
                            Cancel
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ValidatorZoom;
