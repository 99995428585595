import { Button, Chip } from "@mui/material";
import React from "react";
import { toast } from "react-hot-toast";
import { convertDateFormat, downloadPDF, isIIT, programValues, t24HDecimalTo12HFormat, toastStyle } from "../../../utility/helper";
import StudCardDetails from "components/misc/StudCardDetails";
import { FaCopy } from "react-icons/fa";

const CanceledInterview = ({ name,
  desg,
  slotStart,
  image,
  slotEnd,
  branch,
  college,
  program,
  year,
  id,
  date,
  profile,
  subprofile,
  resume,
  mockId,
  isRescheduled,
  isDisable,
  isDelete,
  isUnavailable,
  suggestedSlotStart,
  suggestedSlotEnd,
  suggestedDate,
  cancelledBy, cancelledDate, cancelledTime,
  sessDuration,
  sessType
}) => {
  
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(mockId);
      toast.success(`Copied to clipboard`, {
        ...toastStyle.success,
      });
    } catch (error) {
      console.error('Failed to copy text:', error);
      toast.success(`Failed to copy text`, {
        ...toastStyle.loading,
      });
    }
  };

  return (
    <>
      <div className="relative sent-item my-4 p-2 s-desktop-card">
        <div className="border-r border-gray-400 my-auto col-span-1">
          <img
            src={image}
            className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
            alt="Profile"
          />
          <button onClick={() => {
            isUnavailable ? toast.loading(`Sorry! the user has ${isDisable ? 'disabled' : ''} ${isDelete ? 'deleted' : ''} this account`, {
              ...toastStyle.loading,
              position: 'top-center',
              duration: 2000,
            }) : window.open(`${process.env.REACT_APP_FRONTEND_SERVER_URL}/i/student/${id}`, "_blank")
          }} className="view-profile-card-btn w-4/5 mt-2 mx-auto">
            View Profile
          </button>
        </div>
        <div className="sent-item-info">
          <div className="sent-item-head">
            <div>
              <div className="flex py-1 justify-between w-full">
                <div className="w-12 h-12 drop-shadow-2 mx-1 rounded-lg">
                  {isIIT(college) ? (
                    <img
                      src={require("../../Dashboard/Logos_IITs/" +
                        college.split(" ").join("_") +
                        "_Logo.png")}
                      // className="w-12 h-12 object-contain"
                      alt={college}
                    />
                  ) : <img
                    src={require('../../Dashboard/Logos_IITs/interwiu_logo.png')}
                    // className="w-12 h-12 object-contain"
                    alt={college}
                  />}
                </div>
                <div>
                  <div>
                    <h4 className="text-2xl text-left font-medium leading-6">
                      {name}
                    </h4>
                    <p>{programValues[program]}, {year}, {branch}</p>
                    <p>{college}</p>
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="flex justify-center h-10 text-blue-500 text-2xl">
                    <Button variant="contained" onClick={() => downloadPDF((resume))}>
                      <i className="fa-solid fa-link mr-1"></i> Resume
                    </Button>
                  </div>
                  <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                    <i className="fa-solid fa-bookmark"></i>
                  </div>
                  <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                    <i className="fa-solid fa-share"></i>
                  </div>
                </div>
              </div>
              {/* <div
                className="flex justify-between text-center my-1 gap-x-1 mx-2"
                style={{ alignItems: "flex-start" }}
              >
                <div className="col-span-2">
                  <p className="text-base text-gray-400">Profile</p>
                  <Chip
                label={subprofile ? `${subprofile} (${profile})`: profile}
                color="info"
                sx={{ fontWeight: "medium",
                height: "auto",
                display: "block",
                maxWidth: "fit-content",
                px: 0.5,
                '& .MuiChip-label' :{
                  whiteSpace: "pre-wrap",
                  display: "flex",
                  flexWrap: "wrap",
                  px: 0.5,
                  fontSize: {
                    sm: "0.8rem"
                  }
                }
                }}
              />
                </div>
                <div className="col-span-2">
                  <p className="text-base text-gray-400">Price</p>
                  <p className="text-lg">INR process.env.REACT_APP_STD_45MIN</p>
                </div>
                <div className="col-span-2">
                  <p className="text-base text-gray-400">Session Duration</p>
                  <p className="text-lg">30 Min</p>
                </div>
                <div className="col-span-3" style={{minWidth: "177px"}}>
                  <p className="text-base text-gray-400">Interview Slot</p>
                  <span className="text-lg">{t24HDecimalTo12HFormat(slotStart)}</span>
                  <span> &nbsp; - &nbsp;</span>
                  <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
                  <p className="text-lg">{convertDateFormat(date)}</p>
                </div>
              </div> */}
               <StudCardDetails 
                subprofile={subprofile}
                profile={profile}
                isRescheduled={"other"}
                reschedule={{
                  status: "",
                  details: null
                }}
                price={{45:400,30:250}[sessDuration]}
                slotEnd={slotEnd}
                slotStart={slotStart}
                date={date}
                sessDuration={sessDuration}
                sessType={sessType}
              />
              {suggestedDate && suggestedSlotEnd && suggestedSlotStart && <div className="py-2">
                <span className="text-lg">Suggested Slot :- </span>
                <span className="text-lg">{t24HDecimalTo12HFormat(suggestedSlotStart)}</span>
                <span> &nbsp; - &nbsp;</span>
                <span className="text-lg">{t24HDecimalTo12HFormat(suggestedSlotEnd)}, </span>
                <span className="text-lg">{convertDateFormat(suggestedDate)}</span>
              </div>}
              <div className="sent-item-btns flex justify-center">
                <p className="text-red-700 font-medium text-lg border-2  px-2 py-1 rounded">
                  <i className="fa-solid fa-xmark"></i> Mock Interview Cancelled
                </p>
              </div>
              <div className="text-green-700 text-lg font-bold pt-1" style={{ fontSize: '16px' }}>
                Mock Interview ID: {mockId}; {cancelledBy && cancelledDate
                  && cancelledTime && `Canceled by ${cancelledBy[0] === 'I' ? 'You' : 'Student'} at ${cancelledTime} on ${cancelledDate}`}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="relative overflow-hidden s-card-main s-mobile-card s-mobile-card-shadow"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
      >
        <Button className="box-shadow-request-card s-mobile-resume-btn" variant="contained" onClick={() => downloadPDF((resume))}>
          <i className="fa-solid fa-link mr-1"></i> Resume
        </Button>
        <div className="absolute bottom-1 right-2 text-right">
          <p className="text-xs text-gray-400" style={{ fontSize: '0.65rem' }}>Sent 15 Min ago</p>
        </div>
        <div className="s-profile">
          <div className=" my-auto col-span-1 s-profile-p-p">
            <img
              src={image}
              className="rounded-full border-2 border-black p-1 block mx-auto"
              alt="Profile"
              width={{ width: "100%" }}
            />
            <button
              style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_FRONTEND_SERVER_URL}/i/student/${id}`,
                  "_blank"
                );
              }}
              className="view-profile-card-btn  mt-2 mx-auto view-profile-card-btn-mobile"
            >
              View Profile
            </button>
          </div>
          <div
            className="flex py-1 justify-between w-full s-profile-n-d-c"
            style={{ alignItems: "start" }}
          >
            {/* <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
              {ImageExist(logo[0]) &&
                imgExist === false &&
                (imgExist = true) && (
                  <img src={logo[0]} alt="logo" className="h-full w-full" />
                )}
              {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                <img src={logo[1]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                <img src={logo[2]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                <img src={logo[3]} alt="logo" className="h-full w-full" />
              )}
              {!imgExist &&
                !ImageExist(logo[0]) &&
                !ImageExist(logo[1]) &&
                !ImageExist(logo[2]) &&
                !ImageExist(logo[3]) &&
                (imgExist = true) && (
                  <img src={logo[4]} alt="logo" className="h-full w-full" />
                )}
            </div> */}
            {/* <div className="text-center "> */}
            <h4 className="text-2xl font-medium">{name}</h4>
            <p>{programValues[program]}, {year}, {branch}</p>
            <p>{college}</p>
            {/* <p>{desg}</p>
            <p>{company}</p> */}
            {/* </div> */}
            {/* <div className="flex gap-2">
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div>
            </div> */}
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ marginBottom: '8px' }}>
            <div className="s-duration-price" style={{ marginBottom: '10px' }}>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Duration : </span>
                <span className="text-lg">{sessDuration} Min</span>
              </div>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Price : </span>
                <span className="text-lg">INR {{45:400,30:250}[sessDuration]}</span>
              </div>
            </div>

            <div style={{justifyContent: 'flex-start'}} className="s-duration-price">
              <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold', marginRight: '5px' ,transform:'translateY(2px)'}}>Interview Slot : </p>
              <div >
                <span className="text-lg"> {t24HDecimalTo12HFormat(slotStart)}</span>
                <span> - </span>
                <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
                <p className="text-lg">{convertDateFormat(date)}</p>
              </div>
            </div>

            <div className="s-profile-profiles">
              <div className=" col-span-2">
                <div className="text-lg flex flex-wrap gap-1">
                  <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold' }}>Profile : </p>
                  <Chip
                    label={subprofile ? `${subprofile} (${profile})`: profile}
                    color="info"
                    sx={{ fontWeight: "medium" }}
                    className="s-mobile-changing-chip-property"
                  />
                </div>
              </div>
            </div>
          </div>

        </div>

            <div
              className="text-green-700 text-lg font-bold pt-1 s-profile-profiles"
              style={{ fontSize: "13px" ,width:'90%',margin:'-10px auto 15px auto',fontWeight:'500', lineHeight:'1.4rem' }}
            >
              Mock Interview ID: {mockId} <FaCopy style={{display: 'inline', fontSize: '1rem',transform: 'translate(6px,-3px)'}} onClick={handleCopy} /> <br/>{cancelledBy && cancelledDate
                  && cancelledTime && `Canceled by ${cancelledBy[0] === 'I' ? 'You' : 'Student'} at ${cancelledTime} on ${cancelledDate}`}
            </div>


        <div className="" style={{ textAlign: "center" }}>
          <div className="relative flex justify-center">
            <p className="text-red-700 font-medium text-lg border-2 px-2 py-1" style={{ width: '100%' }}>
              <i className="fa-solid fa-check"></i> Mock Interview Cancelled
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default CanceledInterview;