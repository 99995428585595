import { Close } from "@mui/icons-material";
import { Modal, Box, Button, Typography, IconButton } from "@mui/material";
import React from "react";

const EndCallModal = ({ open, handleClose, leaveCall }) => {
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description" sx={{}}>
            <Box
                sx={{
                    minWidth: "max-content",
                    bgcolor: "#383838",
                    borderRadius: "10px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 300,
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", top: 0, right: 0 }}>
                    <Close sx={{ fill: "white", zoom: 1.2 }} />
                </IconButton>
                {/* <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }} component="h2" gutterBottom>
                    End Call
                </Typography> */}
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        variant="contained"
                        sx={{
                            mt: 2,
                            width: "100%", // Ensure both buttons have the same width
                            maxWidth: "200px",
                            bgcolor: "#f44336", // Red background color
                            "&:hover": {
                                bgcolor: "#d32f2f", // Darker red on hover
                            },
                        }}
                        onClick={() => {
                            console.log("Clicked end session 🛻")
                            leaveCall(true)
                        }}
                    >
                        End Session for all
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            mt: 2,
                            width: "100%", // Ensure both buttons have the same width
                            maxWidth: "200px",
                            bgcolor: "#bdbdbd", // Grey background color
                            "&:hover": {
                                bgcolor: "#9e9e9e", // Darker grey on hover
                            },
                        }}
                        onClick={() => {
                            console.log("Clicked leave session 🛻")
                            leaveCall(false)
                        }}
                    >
                        Leave Session
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};


export default EndCallModal;