import React from 'react';
import './Benefits.css'; // Import the CSS file
import image from "../../../images/ca/ambassador-benefits.png"; // Adjust the path as necessary

const Benefits = () => {
    return (
        <div className="benefits-container">
            <div className="benefits-content">
                <h2 className="benefits-title">Benefits of a Campus Ambassador</h2>
                <ul className="benefits-list">
                    <li>
                        <span className="highlight">T-shirt Reward:</span> Receive a T-shirt after position confirmation (25 new registrations and 1-2 session bookings within the first 15 days).
                    </li>
                    <li>
                        <span className="highlight">Free Sessions:</span> Get one FREE SESSION for every 10 sessions booked using the referral code (up to 50 sessions).
                    </li>
                    <li>
                        <span className="highlight">Career Opportunities:</span> Referral to startups/companies for internships or jobs if 50 sessions are booked using the referral code.
                    </li>
                    <li>
                        <span className="highlight">Certificate:</span> Receive a Certificate of Appreciation upon successfully completing the tenure.
                    </li>
                    <li>
                        <span className="highlight">Leaderboard Rewards:</span> Additional benefits for securing a top spot on the Campus Ambassadors’ Leaderboard.
                    </li>
                    <li>
                        <span className="highlight">Bonuses & Goodies:</span> Possible monetary bonuses and goodies for a decent number of session bookings, even if not reaching 50.
                    </li>
                    <li>
                        <span className="highlight">Internship Preference:</span> Preferred for open internship positions at interwiu.com.
                    </li>
                </ul>
            </div>
            <img src={image} alt="Campus Ambassador Benefits" className="benefits-image" />
        </div>
    );
};

export default Benefits;
