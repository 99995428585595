import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "./InterviewerAccepted.css";
import AcceptedRequest from "./cards/AcceptedRequest";
// import TextField from "@mui/material/TextField";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DatePicker from "react-date-picker";
// import Snackbar from "@mui/material/Snackbar";
// import Slide from "@mui/material/Slide";
import { Helmet } from "react-helmet";
import axios from "axios";
import { BASE } from "../../constants/endpoints";
import { COMPANY_NAME } from "../../constants/variables";
import { Oval, ThreeDots } from "react-loader-spinner";
import UserContext from "../../contexts/UserContext";
import {
  calculateCount,
  compare,
  t24HDecimalTo12HFormat,
} from "../../utility/helper";
import Accordian from "./cards/Accordian";
import Pagination from "@mui/material/Pagination";
import moment from "moment-timezone";

function InterviewerAccepted() {
  const { user } = useContext(UserContext);
  const [acceptedRequest, setacceptedRequest] = useState([]);
  const [SentStudents, setSentStudents] = useState([]);
  const [cards, setCards] = React.useState([]);
  const [date, setDate] = React.useState(null);
  const [stack, setStack] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const rendered = useRef(false);
  const card = [];
  const params = {
    reqStatus: "ReqAccepted",
    userRole: "Interviewer",
  };
  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, { params })
        .then((response) => {
          console.log("This is the response ===>", response);
          if (rendered.current) return;
          setacceptedRequest(response.data.Requests);
          setSentStudents(response.data.SentInterViewers);
          rendered.current = true;
          setCards(response.data.Requests);
          setLoaded(true);
        });
    }
    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, []);
  useEffect(() => {
    acceptedRequest.sort(compare);
    const result = acceptedRequest.filter(
      (req) =>
        moment()
          .tz("Asia/Kolkata")
          .diff(
            moment(
              req.date + " " + t24HDecimalTo12HFormat(req.fromTime),
              "DD/MM/YYYY hh:mm a"
            ),
            "seconds"
          ) < 0
    );
    console.log("resultttt", result);
    result.sort(compare);

    setCards(result);
  }, [acceptedRequest]);
  const stacking = () => {
    let localCards = [];
    // console.log(localCards)
    cards.map((item, index) => {
      let c1 = false;
      if (localCards?.length === 0) {
        localCards.push({
          slot: item.fromTime,
          date: item.date,
          array: [{ requests: item, students: SentStudents[index] }],
        });
        c1 = true;
      }
      if (c1 === false) {
        let check = false;
        localCards.map((cItem, cindex) => {
          if (cItem.slot === item.fromTime && cItem.date === item.date) {
            localCards[cindex].array.push({
              requests: item,
              students: SentStudents[index],
            });
            check = true;
          }
        });
        if (!check) {
          localCards.push({
            slot: item.fromTime,
            date: item.date,
            array: [{ requests: item, students: SentStudents[index] }],
          });
        }
      }
    });
    setStack(localCards);
  };
  const remove = (first, second) => {
    let localCards = [...stack];
    localCards[first].array.splice(second, 1);
    setStack(localCards);
  };
  const removeEntire = (first) => {
    let localCards = [...stack];
    localCards.splice(first, 1);
    setStack(localCards);
  };
  useEffect(() => {
    stacking();
  }, [cards]);
  useEffect(() => {
    console.log(stack);
  }, [stack]);
  const variable = calculateCount(acceptedRequest);

  // Pagination
  const [page, setPage] = useState(1); // No of Pages
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [pageNo, setPageNo] = useState([0, 10]);
  useEffect(() => {
    // console.log([page * 10 - 1, page * 10]);
    setPageNo([(page - 1) * 10, page * 10]);
    window.scrollTo(0, 0);
  }, [page, acceptedRequest]);

  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Accepted Requests</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <div className="interviews min-h-screen">
        {/* <div
          className="flex justify-start items-start m-4 w-auto"
          style={{ color: "red" }}
        >
          <span>
            The inbuilt Video Call Application of interwiu.com (where the mock
            interview takes place) does not work on any of the Apple devices
            (MacBook, iPad, iPhone) at all. It works on Android phones, but you
            might face some connection issues. So, if possible, kindly please
            use <strong>Only a WINDOWS LAPTOP</strong> for taking Mock
            Interviews or Mentorship Sessions. Also, please use Only{" "}
            <strong>GOOGLE CHROME</strong> Browser
          </span>
        </div> */}
        <div className="flex justify-center items-center mb-4" id="head">
          <h2 className="interview-head text-2xl font-medium">
            Accepted Requests (
            {/* {acceptedRequest
              ? page * 10 -
                9 +
                " - " +
                (variable > page * 10
                  ? page * 10
                  : variable)
              : null}
            ) out of ({acceptedRequest ? variable : 0}) */}
            {acceptedRequest.length
              ? page * 10 -
              9 +
              " - " +
              (acceptedRequest.length > page * 10
                ? page * 10
                : acceptedRequest.length)
              : null}
            ) out of ({acceptedRequest.length ? variable : 0})
          </h2>
        </div>
        <div className="flex justify-between mx-8 s-mobile-flex-dir-col">
          <div>
            Showing the results for {date ? date.toDateString() : "all days"}
          </div>
          {/*
          <div className="flex gap-2">
            <label htmlFor="date">Filter by Date: </label>
            <DatePicker
              onChange={(newValue) => {
                setDate(newValue);
              }}
              value={date}
            />
          </div>
          */}
        </div>
        {loaded ? (
          <div className="mx-auto max-w-[900px]">
            {stack?.slice(pageNo[0], pageNo[1]).map((item, index) => {
              if (item?.length === 0) {
                return;
              }
              return (
                <Accordian
                  array={item.array}
                  index={index}
                  stack={stack}
                  setStack={setStack}
                  remove={remove}
                  removeEntire={removeEntire}
                  isAccepted={true}
                />
              );
            })}
            {/* Pagination */}
            <div className="flex flex-row justify-center mt-4 my-2">
              <Pagination
                count={Math.ceil(variable / 10)}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </div>
          </div>
        ) : (
          <div className="fullPageLoader">
            <Oval
              height={120}
              width={120}
              color="#306bc9"
              wrapperStyle={{ padding: "50%, 50%" }}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#90caf9"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default InterviewerAccepted;
