import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import UserContext from '../../../contexts/UserContext';
import './Coupons.css';

function Coupons() {
    const { user } = useContext(UserContext);
    const [coupons, setCoupons] = useState([]);
    const [sessionCount, setSessionCount] = useState(0);

    const getCoupons = async (caId) => {
        console.log("CA id:", caId);
        const body = {
            caId: caId
        };
        try {
            // const response = await axios.post(`http://localhost:8585/programs/ca/get-coupons`, body);
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/programs/ca/get-coupons`, body);
            console.log("Received coupons ==>", response);
            setCoupons(response.data.coupons);
            setSessionCount(response.data.sessionCount);
        } catch (error) {
            console.log("Error in getting coupons ==>", error);
        }
    };

    useEffect(() => {
        if (user.caId) {
            getCoupons(user.caId);
        }
    }, [user]);

    const renderMessage = () => {
        if (sessionCount === 0) {
            return (
                <p className="no-bookings-message">
                    No one has booked a session at <span className="highlight">interwiu.com</span> using your referral code <strong className="code">{user.caId}</strong> so far.
                </p>
            );
        } else if (sessionCount < 10) {
            return (
                <p className="no-coupons-message">
                    You have not reached the minimum mark of 10 session bookings (by your friends) at <span className="highlight">interwiu.com</span> using your referral code <strong className="code">{user.caId}</strong> so far. Share it with more friends and ask them to book a session ASAP!
                </p>
            );
        }
        return null;
    };

    return (
        <div className="coupons-container">
            <h2 className="coupons-header">My Coupon Codes</h2>
            {coupons.length > 0 ? (
                <>
                    <p className="coupons-info">You have earned {coupons.length} coupon{coupons.length > 1 ? 's' : ''} so far. Maximum limit: 5 coupons.</p>
                    <p className="coupons-info">
                        Earn a coupon for every 10 sessions booked with your referral code: <strong className="code">{user.caId}</strong>
                    </p>
                    <div className="coupons-list">
                        {coupons.map((coupon, index) => (
                            <div key={index} className={`coupon-item`}>
                                {/* <div key={index} className={`coupon-item ${coupon.used ? 'used' : ''}`}> */}
                                {coupon}
                            </div>
                        ))}
                    </div>
                </>
            ) : renderMessage()}
        </div>
    );
}

export default Coupons;