import React, { useState } from "react";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
// import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as SvgDecoratorBlob1 } from "./../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "./../../images/svg-decorator-blob-8.svg";
import { Typography } from "@mui/material";

const Column = tw.div`flex flex-col items-center`;

const FAQSContainer = tw.dl` max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const StudentsFAQS = () => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  const faqs = [
    {
      question: "What is interwiu.com?",
      answer:
        "interwiu.com is an online mock interview and mentorship platform which connects the students (as interviewees/mentees) with the professionals from top MNCs (as interviewers/mentors) using a filter-based smart match-making algorithm, to facilitate the Mock Interviews and Mentorship Sessions with integral Feedback. The interviewers and mentors serving at the platform are experienced professionals from various big MNCs like Microsoft, Amazon, Google, Goldman Sachs, McKinsey & Company, IBM, Flipkart, JPMorgan Chase & Co., American Express, Meta, Citi, HSBC, Morgan Stanley, Boston Consulting Group (BCG), Bain & Company, PwC, Netflix, Directi, IQVIA, Kotak Mahindra Bank, BNY Mellon, Hexagon Capability Center India Pvt Ltd, Accenture, Deloitte, etc.",
    },
    {
      question: "How do I schedule a Mock Interview or Mentorship Session?",
      answer:
        "Browse professionals, apply filters (duration, profile, company of professional, and suitable date) to refine your search, and send a Mock Interview Request or Mentorship Session Request to each of the best suiting professionals by picking a date and time-slot as per your own and professional’s availability",
    },
    {
      question: "When do I pay for a Mock Interview or Mentorship Session?",
      answer:
        "Once your mock interview request or mentorship session request is accepted by a professional, you have to pay using the associated payment gateway to confirm a mock interview or mentorship session",
    },
    {
      question:
        "How and when do I get the meeting link for a mock interview or mentorship session?",
      answer:
        "Once you pay for a mock interview or mentorship session, the meeting link for your mock interview or mentorship session is automatically created by interwiu.com and embedded in the [Join Mock interview] button or [Join Mentorship Session] button provided on the card of each such confirmed mock interview or mentorship session. The same meeting link is provided to your interviewer/mentor as well",
    },
    {
      question:
        "How is the structure of a Mock Interview (with Live Feedback)?",
      answer:
        "The mock interviews are structured exactly like real placement/internship interviews in every context such as kind/type, level, and number of questions asked; inclusion of stress rounds; environment; etc. The feedback is provided by your interviewer during/after the mock interview on every possible aspect like your incorrect or partially correct answers, lack of confidence, lack of clarity, body language, etc. Interviewers also suggest better ways of answering certain questions",
    },
    {
      question:
        "What is the duration of a Mock Interview or Mentorship Session?",
      answer:
        "interwiu.com offers Mock Interviews and Mentorship Sessions of two different durations, viz., 30 minutes and 45 minutes. You can book any of them. In case of 45 minutes, the duration can be extended based on your requirements and interviewer's/mentor's  availability",
    },
    {
      question: "Why do I need to create an account or a profile?",
      answer:
        "Your short profile is needed to match and connect you with the most relevant and best suiting professionals. Your profile also helps your prospective interviewers/mentors to know about you before the mock interview or mentorship session, which ensures the credibility of mock interviews or mentorship sessions",
    },
    {
      question:
        "If I appear for multiple mock interviews or mentorship sessions, one after another with some intermittent gaps, will those sessions be arranged with the same professional or different professionals?",
      answer:
        "After a mock interview or mentorship session is done, it's completely your choice whether to pick the same professional or different professionals for the subsequent mock interviews or mentorship sessions",
    },
    {
      question: "How do I find a relevant professional?",
      answer:
        "Once you have browsed the professionals, apply the filters to refine your search, and thus find the professionals best suiting your requirements/preferences ",
    },
    {
      question:
        "How can I reschedule a confirmed Mock Interview or Mentorship Session?",
      answer:
        "You can go to the [Reschedule a Session] option, pick a new slot as per the professional's availability, and book another slot with the same professional",
    },
    {
      question:
        "How can I cancel a confirmed Mock Interview or Mentorship Session?",
      answer:
        "You can go to the [Cancel a Session] option to cancel a confirmed mock interview or mentorship session. The coupon code shared with you after cancellation will be governed by our cancellation policy",
    },
    {
      question:
        "How and when can I report an issue about an upcoming or a completed mock interview or mentorship session?",
      answer:
        "You can report an issue about any upcoming or completed mock interview or mentorship session by using the [Report an Issue] option only upto 6 hours after the respective mock interview or mentorship session slot",
    },
    {
      question: "How can I check the profiles of professionals?",
      answer:
        "You can visit any professional's profile with a single click. You can also visit his/her LinkedIn profile using the respective link on his/her interwiu.com profile page",
    },
    {
      question:
        "How and when do I receive my feedback from my interviewer/mentor?",
      answer:
        "You receive a detailed feedback from your interviewer/mentor in a verbal/documented form during and/or in the end of your each mock interview or mentorship session",
    },
    {
      question: "What can I expect from a Mock Interview (with Live Feedback)?",
      answer:
        "You can expect to feel the heat of actual placement/internship interviews in the mock interviews, which are constituted with all the relevant questions in a well structured format. The feedback provided with each mock interview session will help you to understand and rectify your mistakes, realize what went right/wrong, prepare better, improve the performance in subsequent mock interviews, and thus ultimately enhance the odds of cracking the real placement/internship interviews in a highly competitive environment",
    },
  ];

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <Typography sx={{}} variant="h3">
            FAQs for Students
          </Typography>

          <Typography>Got a question? We're here to answer!</Typography>

          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{
                    duration: 0.3,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
export default StudentsFAQS;
