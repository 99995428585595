// This form is shown when Student wants to see Interviewers Profile.

import React, { useContext, useReducer, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import axios from "axios";
import "./InterviewerDashboard.css";
import { BASE } from "../../constants/endpoints";
import UserContext from "../../contexts/UserContext";
import {
  INTERVIEWER_PROFILE_FORM_ROUTE,
  INTERVIEWER_RECIEVED_REQUESTS_ROUTE,
} from "../../constants/routes";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { getCompanyLogo, t24HDecimalTo12HFormat } from "../../utility/helper";
import { Oval } from "react-loader-spinner";
import CoreChip from "../misc/CoreChip";

const InterviewProfile = () => {
  const [user, setUser] = useState();
  const [loaded, setLoaded] = useState(false);
  console.log(window.location.pathname)
  const id = window.location.pathname.split('interviewer/')[1]
  const location = useLocation();
  useEffect(() => {
    axios.get(`${BASE}/int/interviewer/${id}`).then((data) => {
      setUser(data.data)
      setLoaded(true);
    }).catch((err) => { console.log(err) })
  }, [])
  useEffect(() => {
    console.log(user);
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let navigate = useNavigate();
  useEffect(() => {
    document.title =
      "Profile - " + (user?.fname + (user?.lname ? " " + user?.lname : ""));
  });

  // Check if image exist
  function imageExists(image_url) {
    var http = new XMLHttpRequest();
    http.open("HEAD", image_url, false);
    http.send();
    return http.status != 404;
  }

  /*  
    To convert 24 Hour Decimal Time format to 12 Hours format(for ease of processing in frontend)
    E.g. 12.25 -> 12:15 PM
  */
  // For converting Generic Time Preferences for frontend using backend
  const gnrcSchdForFrontend = (weekSchedule) => {
    let result = {};
    weekSchedule?.map((daySchedule) => {
      result[daySchedule.day] = daySchedule.sessions.map((session) => ({
        init: [session.from * 2, session.to * 2 - 1],
        final: [
          t24HDecimalTo12HFormat(session.from),
          t24HDecimalTo12HFormat(session.to),
        ],
      }));
    });
    return result;
  };

  // Generic Time Preferences for Frontend
  var gnrcSlots = gnrcSchdForFrontend(user?.GnrcSchedule);
  console.log("gnrcSlots: ", gnrcSlots);

  // Convert Custom Schedule for Frontend
  const custSchdForFrontend = (schedule) => {
    let result = [];
    schedule?.map((item) => {
      let temp = [];
      item.sessions.map((slotItem) => {
        temp.push({
          init: [slotItem.from * 2, slotItem.to * 2 - 1],
          final: [
            t24HDecimalTo12HFormat(slotItem.from),
            t24HDecimalTo12HFormat(slotItem.to),
          ],
        });
      });
      result.push({ date: item.date, slots: temp });
    });
    return result;
  };

  // array.sort(function (a, b) {
  //   // Turn your strings into dates, and then subtract them
  //   // to get a value that is either negative, positive, or zero.
  //   return new Date(b.date) - new Date(a.date);
  // });
  var custSlots = custSchdForFrontend(user?.CustomSchedule).sort(function (
    a,
    b
  ) {
    var aa = a.date.split("/").reverse().join(),
      bb = b.date.split("/").reverse().join();
    return aa < bb ? -1 : aa > bb ? 1 : 0;
  });
  console.log("custSlots: ", custSlots);
  if (!user) {
    return <div className="fullPageLoader">
      <Oval
        height={120}
        width={120}
        color="#306bc9"
        wrapperStyle={{ padding: "50%, 50%" }}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#90caf9"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  }
  const logo = getCompanyLogo(user?.currCompany);
  function ImageExist(url) {
    if (url?.includes('.svg')) {
      console.log('svg', url);
      var img = new Image();
      img.src = url;
      console.log(img.height)
      return img?.getBBox?.height !== 0;
    } else {
      console.log('.png', url);
      var img2 = new Image();
      img2.src = url;
      console.log(img2?.getBBox?.height)
      return img2?.height !== 0;
    }
  }
  let imgExist = false;
  return (
    <div className="interviews">
      <div className="w-full p-2 my-2 border-2 rounded flex justify-center">
        <Typography
          variant="h4"
          component="h4"
          sx={{
            // textAlign: "center",
            display: "inline",
            color: "#1265a8",
            fontWeight: "bold",
            margin: "0 auto",
            fontSize: "2rem"
          }}
        >
          Interviewer's Profile
        </Typography>
      </div>
      {loaded ? (<div className="grid">
        <div className="m-2 overflow-hidden">
          {/* <div className="border rounded mb-4">
              <div className="h-32 opacity-80 overflow-hidden">
                <img src={require("../../images/cover2.webp")} alt="Cover" />
              </div>
              <div className="relative grid py-2" id="profile-bio">
                <div className="relative">
                  <div className="absolute w-40 -top-16 left-3 mx-auto">
                    <img
                      src={user?.photo}
                      className="w-full h-full object-cover rounded-full p-1 border bg-white"
                      alt="Profile"
                    />
                  </div>
                </div>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                  <div>
                    <Typography
                      variant="h4"
                      component="h4"
                      sx={{
                        color: "#e84c3d",
                        fontWeight: "bold",
                      }}
                    >
                      {user?.fname + (user?.lname ? " " + user?.lname : "")}
                    </Typography>
                    <Chip
                      label={user?.designation + " - " + user?.currCompany}
                      sx={{ fontSize: "16px" }}
                    />
                    <br/>
                    <p className="text-gray-500 leading-5"></p>
                    <div className="text-2xl my-1 mt-3 flex w-40 justify-between">
                      {user?.linkedInURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-1 w-12 text-3xl text-center">
                          <i
                            className="fa-brands fa-linkedin"
                            onClick={() => window.open(user.linkedInURL)}
                          ></i>
                        </div>
                      ) : null}
                      {user?.githubURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-black cursor-pointer border rounded p-1 w-12 text-3xl text-center">
                          <i
                            className="fa-brands fa-github"
                            onClick={() => window.open(user.githubURL)}
                          ></i>
                        </div>
                      ) : null}
                      {user?.twitterURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-1 w-12 text-3xl text-center">
                          <i
                            className="fa-brands fa-twitter"
                            onClick={() => window.open(user.twitterURL)}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div style={{width:'150px', textAlign:'center',display:'flex', flexDirection:'column', justifyContent:'space-evenly'}}>
                  {
                      ImageExist(logo[0]) && imgExist === false && (imgExist=true) &&
                      <img style={{maxWidth:'120px', paddingRight:'10px', margin:'auto'}} src={logo[0]} alt="company"></img>
                    }
                    {
                      !imgExist && ImageExist(logo[1])  && (imgExist=true) &&
                      <img style={{maxWidth:'120px', paddingRight:'10px', margin:'auto'}} src={logo[1]} alt="company"></img>
                    }
                    {
                      !imgExist && ImageExist(logo[2]) && (imgExist=true) &&
                      <img style={{maxWidth:'120px', paddingRight:'10px', margin:'auto'}} src={logo[2]} alt="company"></img>
                    }
                    {
                      !imgExist && ImageExist(logo[3])  && (imgExist=true) &&
                      <img style={{maxWidth:'120px', paddingRight:'10px', margin:'auto'}} src={logo[3]} alt="company"></img>
                    }
                    {
                      !imgExist && !ImageExist(logo[0]) && !ImageExist(logo[1]) && !ImageExist(logo[2]) && !ImageExist(logo[3])  && (imgExist=true) &&
                      <img style={{maxWidth:'120px', paddingRight:'10px', margin:'auto'}} src={logo[4]} alt="company"></img>
                    }
                  </div>
                </div>
              </div>
            </div> */}
          <div className="border rounded mb-2">
            <div className="h-32 opacity-80 s-mobile-overflow-h" style={{ position: 'relative' }}>
              <img src={require("../../images/cover2.webp")} alt="Cover" />
              <div className="s-mobile-card" style={{ position: 'absolute', bottom: '-50px', left: '0', right: '0', justifyContent: 'center' }}>
                <div className="" style={{ width: '43%' }}>
                  <img
                    src={user.photo}
                    style={{ width: '100%', height: '100%' }}
                    className="h-full w-full object-cover rounded-full p-1 border bg-white"
                    alt="Profile"
                  />
                </div>
              </div>
            </div>
            <div className="relative py-2 s-mobile-flex-dir-col s-desktop-card" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} id="profile-bio">
              <div className="" style={{ transform: 'translate(14%,-50%)', width: '20%' }}>
                <div className="" style={{ width: '100%' }}>
                  <img
                    src={user.photo}
                    className="h-full w-full object-cover rounded-full p-1 border bg-white"
                    alt="Profile"
                  />
                </div>
              </div>
              <div style={{width:'90%',paddingLeft:'15%', maxWidth: '90%' }}>
                <Typography
                  variant="h4"
                  component="h4"
                  sx={{
                    color: "#e84c3d",
                    fontWeight: "bold",
                  }}
                >
                  {user.fname + (user.lname ? " " + user.lname : "")}
                </Typography>
                <div
                  className="flex flex-col mt-2"
                  style={{ alignItems: "flex-start" }}
                >

                  <Chip
                    className="s-mobile-changing-chip-property"
                    // label={`${profiledata.designation} ${profiledata.company} ${profiledata.location}`}
                    label={user.designation}
                    sx={{ fontSize: "16px" }}
                  />
                </div>
                <div
                  className="flex flex-col mt-2"
                  style={{ alignItems: "flex-start" }}
                >

                  <Chip
                    className="s-mobile-changing-chip-property"
                    // label={`${profiledata.designation} ${profiledata.company} ${profiledata.location}`}
                    label={user.currCompany}
                    sx={{ fontSize: "16px" }}
                  />
                </div>
                {/* <Typography
                    variant="h6"
                    component="h6"
                    sx={{ lineHeight: 1, fontSize: "16px" }}
                  >
                    B.Tech, IIT Kharagpur
                  </Typography> */}

                <p className="text-gray-500 leading-5"></p>
                <div className="text-2xl my-1 flex gap-2">
                  {user.linkedInURL ? (
                    <button onClick={() => {
                      window.open(
                        user.linkedInURL
                          ? user.linkedInURL
                          : "/dashboard",
                        "_blank"
                      );
                    }} className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                      <i
                        className="fa-brands fa-linkedin"
                        // onClick={() => {
                        //   window.open(
                        //     user.linkedInURL
                        //       ? user.linkedInURL
                        //       : "/dashboard",
                        //     "_blank"
                        //   );
                        // }}
                      ></i>
                    </button>
                  ) : null}
                  {user.githubURL ? (
                    <button onClick={() => {
                      window.open(
                        user.githubURL ? user.githubURL : "/dashboard",
                        "_blank"
                      );
                    }} className="hover:text-blue-500 hover:bg-gray-100 text-black cursor-pointer border rounded p-2 w-12 text-center">
                      <i
                        className="fa-brands fa-github"
                        // onClick={() => {
                        //   window.open(
                        //     user.githubURL ? user.githubURL : "/dashboard",
                        //     "_blank"
                        //   );
                        // }}
                      ></i>
                    </button>
                  ) : null}
                  {user.twitterURL ? (
                    <button onClick={() => {
                      window.open(
                        user.twitterURL
                          ? user.twitterURL
                          : "/dashboard",
                        "_blank"
                      );
                    }} className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                      <i
                        className="fa-brands fa-twitter"
                        // onClick={() => {
                        //   window.open(
                        //     user.twitterURL
                        //       ? user.twitterURL
                        //       : "/dashboard",
                        //     "_blank"
                        //   );
                        // }}
                      ></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="s-mobile-card" style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', marginTop: '17%' }} id="profile-bio">

              <div style={{ maxWidth: '90%' }}>
                <Typography
                  variant="h4"
                  component="h4"
                  sx={{
                    color: "#e84c3d",
                    fontWeight: "bold",
                  }}
                >
                  {user.fname + (user.lname ? " " + user.lname : "")}
                </Typography>
                <div
                  className="flex flex-col mt-2"
                  style={{ alignItems: "flex-start" }}
                >

                  <Chip
                    className="s-mobile-changing-chip-property"
                    // label={`${profiledata.designation} ${profiledata.company} ${profiledata.location}`}
                    label={user.designation}
                    sx={{ fontSize: "16px" }}
                  />
                </div>
                <div
                  className="flex flex-col mt-2"
                  style={{ alignItems: "flex-start" }}
                >

                  <Chip
                    className="s-mobile-changing-chip-property"
                    // label={`${profiledata.designation} ${profiledata.company} ${profiledata.location}`}
                    label={user.currCompany}
                    sx={{ fontSize: "16px" }}
                  />
                </div>

                <p className="text-gray-500 leading-5"></p>
                <div className="text-2xl my-1 flex gap-2">
                  {user.linkedInURL ? (
                    <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                      <i
                        className="fa-brands fa-linkedin"
                        onClick={() => {
                          window.open(
                            user.linkedInURL
                              ? user.linkedInURL
                              : "/dashboard",
                            "_blank"
                          );
                        }}
                      ></i>
                    </div>
                  ) : null}
                  {user.githubURL ? (
                    <div className="hover:text-blue-500 hover:bg-gray-100 text-black cursor-pointer border rounded p-2 w-12 text-center">
                      <i
                        className="fa-brands fa-github"
                        onClick={() => {
                          window.open(
                            user.githubURL ? user.githubURL : "/dashboard",
                            "_blank"
                          );
                        }}
                      ></i>
                    </div>
                  ) : null}
                  {user.twitterURL ? (
                    <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                      <i
                        className="fa-brands fa-twitter"
                        onClick={() => {
                          window.open(
                            user.twitterURL
                              ? user.twitterURL
                              : "/dashboard",
                            "_blank"
                          );
                        }}
                      ></i>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="rounded border p-2">
            {user.desc && <><Typography
              variant="h6"
              component="h6"
              sx={{
                fontWeight: "bold",
                color: "darkgreen",
              }}
              className="s-mobile-profile-subHeading"
            >
              Description
            </Typography>
              <p style={{whiteSpace:'pre-wrap'}} className="mx-3">{user.desc}</p></>}
            <div className="my-3">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontWeight: "bold",
                  color: "darkgreen",
                }}
                className="s-mobile-profile-subHeading"
              >
                {`Profile Preference(s)`}
              </Typography>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.3rem', height: "auto" }} className="mx-3">
                {user?.profiles.map((profile) => (
                  <Chip
                    key={"profile" + profile}
                    style={{ marginRight: "10px" }}
                    label={profile}
                    variant="outlined"
                  />
                ))}
                {
                  user?.coreprofiles?.map((coreProfile) => (
                      <CoreChip 
                      page="iprofile"
                      customStyle={{
                        marginRight: "10px"
                      }}
                      branch={coreProfile} specs={
                        user?.specializations?.filter((s) => s.profile === coreProfile && s.subDomains?.filter(sd => sd !== "Other").length>0)
                        .map(spec => ({
                          profile: spec.profile,
                          subDomains: spec.subDomains.filter(sd => sd !== "Other")
                        }))
                      }
                      />
                    ))
                }
              </div>
            </div>
            {
              user.prevCompanies.length !== 0 && user.prevCompanies && <><Typography
                variant="h6"
                component="h6"
                sx={{
                  fontWeight: "bold",
                  color: "darkgreen",
                }}
                
                className="s-mobile-profile-subHeading"
              >
                Previous Companies
              </Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.3rem' }}  className="mx-3">
                  {user?.prevCompanies.map((company) => (
                    <Chip
                      key={"company" + company}
                      style={{ marginRight: "10px" }}
                      label={company}
                      variant="outlined"
                    />
                  ))}
                </div>
              </>
            }
            <div className="mt-3">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontWeight: "bold",
                  color: "darkgreen",
                }}
                className="s-mobile-profile-subHeading"
              >
                Time Preferences for Mock Interviews
                {/* {"(" +
                  new Date().toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }) +
                  ")"} */}
              </Typography>

              <div>
                <div className="rounded-md border border-black my-2 p-2">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "green",
                      fontWeight: "bold",
                    }}
                    className="s-mobile-profile-text"
                  >
                    Day-wise Time Preferences
                  </Typography>
                  {Object.keys(gnrcSlots)?.length !== 0
                    ? Object.keys(gnrcSlots).map((item) =>
                      gnrcSlots[item]?.length !== 0 ? (
                        <div className="flex" style={{ gap: '0.3rem', margin: '0.2rem' }}>
                          <p style={{ flex: '1' }} className="col-span-1 capitalize s-desktop-card s-desktop-card-remove-margin s-margin-0">
                            {item === "mon"
                              ? "Monday"
                              : item === "tue"
                                ? "Tuesday"
                                : item === "wed"
                                  ? "Wednesday"
                                  : item === "thu"
                                    ? "Thursday"
                                    : item === "fri"
                                      ? "Friday"
                                      : item === "sat"
                                        ? "Saturday"
                                        : item === "sun"
                                          ? "Sunday"
                                          : null}
                          </p>
                          <div style={{ flex: '4',flexWrap:'wrap' }} className="col-span-5 flex flex-row gap-2 s-desktop-card s-desktop-card-remove-margin">
                            {gnrcSlots[item].map((arr) => (
                              <Chip
                                label={arr.final[0] + " - " + arr.final[1]}
                                variant="outlined"
                              />
                            ))}
                          </div>
                          <p style={{ flex: '1' }} className="col-span-1 capitalize s-mobile-card s-mobile-profile-text">
                            {item === "mon"
                              ? "Monday"
                              : item === "tue"
                                ? "Tuesday"
                                : item === "wed"
                                  ? "Wednesday"
                                  : item === "thu"
                                    ? "Thursday"
                                    : item === "fri"
                                      ? "Friday"
                                      : item === "sat"
                                        ? "Saturday"
                                        : item === "sun"
                                          ? "Sunday"
                                          : null}
                          </p>
                          <div style={{ flex: '2' }} className="col-span-5 flex flex-row gap-2 s-mobile-card">
                            
                              <Chip
                                className="s-mobile-changing-chip-property-day-wise"
                                label={(gnrcSlots[item].map((arr) => {return (arr.final[0] + " - " + arr.final[1])})).join('\r\n')}
                                variant="outlined"
                              />
                          </div>
                        </div>
                      ) : null
                    )
                    : "No Schedule Specified"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>) : (
        <div className="fullPageLoader">
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </div>
  );
};

export default InterviewProfile;
