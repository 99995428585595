import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { BASE } from "../../constants/endpoints";
import axios from "axios";
import Divider from "@mui/material/Divider";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Badge from "@mui/material/Badge";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import UserContext from "../../contexts/UserContext";

import "./Navbarlogedin.css";
import Bookmark from "./popups/Bookmark";
import {
  HOME_ROUTE,
  STUDENT_ACCEPTED_REQUESTS_ROUTE,
  STUDENT_COMPLETED_INTERVIEWS_ROUTE,
  STUDENT_DASHBOARD_ROUTE,
  STUDENT_FIND_AN_INTERVIEWER_ROUTE,
  STUDENT_SENT_REQUESTS_ROUTE,
  STUDENT_UPCOMING_INTERVIEWS_ROUTE,
  STUDENT_DECLINED_REQUESTS_ROUTE,
  STUDENT_CANCELED_INTERVIEW_ROUTE,
  STUDENT_PRECANCELLED_REQUESTS_ROUTE,
  STUDENT_RESCHEDULE_ROUTE,
  STUDENT_REPORT_ROUTE,
  STUDENT_CANCEL_ROUTE,
  STUDENT_PROFILE_FORM_ROUTE,
  SUCCESS_STORIES_ROUTE,
  SUCCESS_ADD_STORIES_ROUTE,
  STUDENT_ACCOUNT_SETTINGS_ROUTE,
  PLAN_ROUTE,
  REFER_ROUTE,
} from "../../constants/routes";
import toast from "react-hot-toast";
import { toastStyle } from "../../utility/helper";
import { useLocation } from 'react-router-dom';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


const Navbarlogedin = () => {
  const location = useLocation();
  // console.log(location.pathname.split("/").pop())
  let navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  // Profile Dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorNt, setAnchorNt] = useState(null);
  const openNt = Boolean(anchorNt);
  const [tag, setTag] = useState({ accepted: false, completed: false });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNtClick = (event) => {
    setAnchorNt(event.currentTarget);
  };

  const handleNtClose = () => {
    setAnchorNt(null);
  };

  const handlePopupOpen = () => {
    setIsOpen(true);
  };

  const handlePopupClose = () => {
    setIsOpen(false);
  };

  const loggingOut = () => {
    if (localStorage.getItem("isAuthenticated"))
      localStorage.removeItem("isAuthenticated");
    setUser({});
    navigate(HOME_ROUTE);
  };

  const params1 = {
    reqStatus: "ReqAccepted",
    userRole: "Student",
  };
  const params2 = {
    reqStatus: "ReqPaid",
    userRole: "Student",
    completed: "Completed",
  };
  useEffect(() => {
    async function getRequests() {
      let x = { accepted: false, completed: false };
      await axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, {
          params: params1,
        })
        .then((response) => {
          const requests = response.data.Requests;

          let ints = response.data.Requests;
          let count = 0;
          ints?.map((int) => {
            axios
              .get(
                `${BASE}/send/student/acceptedInterviewRequestById/${int.reqId}`
              )
              .then((response) => {
                // console.log(response.data, "--------denied");
                if (response.data) {
                  count = +1;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          });

          // console.log(count, requests.length, "-----accepted-----");

          if (count !== requests.length) {
            x["accepted"] = true;
          } else {
            x["accepted"] = false;
          }
        });


      await axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, {
          params: params2,
        })
        .then((response) => {
          const requests = response.data.Requests;

          let ints = response.data.Requests;
          let count = 0;

          ints?.map((int) => {
            axios
              .get(
                `${BASE}/send/student/completedInterviewRequestById/${int.reqId}`
              )
              .then((response) => {
                if (response.data.viewedAccepted) {
                  count++;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          });

          if (count !== requests.length) {
            x["completed"] = true;
          } else {
            x["completed"] = false;
          }
        });

      setTag(x);
    }

    getRequests();
  }, [user]);

  useEffect(() => {
    console.log(user);
  }, [user]);

  // useEffect(() => {
  //   // window.location.reload(false);
  //   console.log("reloading...");
  // }, [navigate]);
  const changeRoute = () => {
    toast.success(`Hello`, {
      ...toastStyle.success,
    });
    return STUDENT_PROFILE_FORM_ROUTE;
  };

  // hide navbar on scroll down

  const [hide, setHide] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      // console.log('window.scrollY',window.scrollY)
      if (window.scrollY > lastScrollY && window.scrollY > 100) {
        // if scroll down hide the navbar
        setHide(true);
      } else {
        // if scroll up hide the navbar
        setHide(false);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handlePopupClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="flex justify-center h-full">
          <Bookmark />
        </div>
      </Modal>
      <div className={`navbarlogedin flex ${hide && "hidden-login-s"}`}>
        <div className="logo">
          <Link to={HOME_ROUTE}>
            <h1 className="text-2xl font-medium">interwiu.com</h1>
          </Link>
        </div>
        <>
          {user.mobNumber !== "" ? (
            <ul className="nav-list flex">
              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  to={STUDENT_FIND_AN_INTERVIEWER_ROUTE}
                >
                  <div>Find a Professional</div>
                </NavLink>
              </li>

              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  to={STUDENT_SENT_REQUESTS_ROUTE}
                >
                  <div>Sent Requests</div>
                </NavLink>
              </li>

              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  to={STUDENT_ACCEPTED_REQUESTS_ROUTE}
                >
                  <div className="flex">
                    Accepted Requests{" "}
                    {tag.accepted && <div className="notify"></div>}
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  to={STUDENT_UPCOMING_INTERVIEWS_ROUTE}
                >
                  <div>Upcoming Sessions</div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  to={STUDENT_COMPLETED_INTERVIEWS_ROUTE}
                >
                  <div className="flex">
                    Completed Sessions{" "}
                    {tag.completed && <div className="notify"></div>}
                  </div>
                </NavLink>
              </li>
            </ul>
          ) : (
            <ul className="nav-list flex">
              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  onClick={() => {
                    toast.loading(
                      `Please fill your profile details to continue`,
                      {
                        ...toastStyle.loading,
                        duration: 3500,
                      }
                    );
                  }}
                  to={STUDENT_PROFILE_FORM_ROUTE}
                >
                  <div>Find an Interviewer</div>
                </NavLink>
              </li>

              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  onClick={() => {
                    toast.loading(
                      `Please fill your profile details to continue`,
                      {
                        ...toastStyle.loading,
                        duration: 3500,
                      }
                    );
                  }}
                  to={STUDENT_PROFILE_FORM_ROUTE}
                >
                  <div>Sent Requests</div>
                </NavLink>
              </li>

              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  onClick={() => {
                    toast.loading(
                      `Please fill your profile details to continue`,
                      {
                        ...toastStyle.loading,
                        duration: 3500,
                      }
                    );
                  }}
                  to={STUDENT_PROFILE_FORM_ROUTE}
                >
                  <div className="flex">
                    Accepted Requests <div className="notify"></div>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  onClick={() => {
                    toast.loading(
                      `Please fill your profile details to continue`,
                      {
                        ...toastStyle.loading,
                        duration: 3500,
                      }
                    );
                  }}
                  to={STUDENT_PROFILE_FORM_ROUTE}
                >
                  <div>Upcoming Interviews</div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  end
                  className="nav-item hover-underline-animation"
                  activeClassName="active"
                  onClick={() => {
                    toast.loading(
                      `Please fill your profile details to continue`,
                      {
                        ...toastStyle.loading,
                        duration: 3500,
                      }
                    );
                  }}
                  to={STUDENT_PROFILE_FORM_ROUTE}
                >
                  <div className="flex">
                    Completed Interviews <div className="notify"></div>
                  </div>
                </NavLink>
              </li>
            </ul>
          )}
          <div className="sidebar flex">
            {/* Notification Bell */}
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Notification">
                <Button
                  onClick={handleNtClick}
                  size="small"
                  sx={{ color: "black" }}
                  aria-controls={openNt ? "notification" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openNt ? "true" : undefined}
                >
                  <div
                    className="notifier rounded w-full flex justify-center border hover:bg-slate-200"
                    style={{ height: "50px" }}
                  >
                    <Badge badgeContent={4} color="primary">
                      <NotificationsIcon color="action" />
                    </Badge>
                  </div>
                </Button>
              </Tooltip>
            </Box> */}
            <Menu
              anchorEl={anchorNt}
              id="Notification"
              open={openNt}
              onClose={handleNtClose}
              onClick={handleNtClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1,
                  px: 1,
                  width: 300,
                  borderRadius: 2,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                subheader={
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontWeight: "bold",
                      marginLeft: "12px",
                    }}
                  >
                    Notifications
                  </Typography>
                }
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="John Doe"
                    secondary="You're meeting is accepted by John Doe"
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <WorkIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Work" secondary="Jan 7, 2014" />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <BeachAccessIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Vacation" secondary="July 20, 2014" />
                </ListItem>
              </List>
            </Menu>

            {/* Profile Dropdown */}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}

            >
              <Tooltip title="My Profile">
                <Button
                  onClick={handleClick}
                  size="small"
                  sx={{ color: "black" }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <div className="sidebar-profile px-2 rounded border cursor-pointer hover:bg-slate-200 flex">
                    <div className="sidebar-profile-img mx-1 flex">
                      <img
                        src={user.photo || require("../../images/photo.png")}
                        alt=""
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                    <div
                      className="text-sm mx-1 flex"
                      style={{ flexDirection: "column" }}
                    >
                      <p>Me</p>
                      <i className="fa-solid fa-caret-down"></i>
                    </div>
                  </div>
                </Button>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1,
                  px: 1,
                  width: 300,
                  borderRadius: 2,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Typography>
                <div className=" profile-dropdown-head flex">
                  <div>
                    <img
                      src={user.photo}
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%",
                        objectFit: "contain"
                      }}
                      alt=""
                    />
                  </div>
                  <div>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ fontWeight: "bold" }}
                    >
                      {user.fname + (user.lname ? " " + user.lname : "")}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>ID : {user.id}</Typography>
                    <p>{user.institute || ""}</p>
                  </div>
                </div>
              </Typography>
              <button
                type="button"
                className="cust-btn view-profile-btn"
                onClick={() => {
                  navigate(STUDENT_DASHBOARD_ROUTE);
                }}

              >
                View Profile
              </button>
              <Divider />
              {/* <MenuItem id="dropdown-btn" onClick={handlePopupOpen}>
                <p>
                  <i className="fa-solid fa-bookmark w-5"></i> Bookmarks
                </p>
              </MenuItem> */}
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(STUDENT_PRECANCELLED_REQUESTS_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'precancelled-requests' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'precancelled-requests' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-circle-xmark w-5"></i> Auto-Rejected
                  Requests
                </p>
              </MenuItem>
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(STUDENT_DECLINED_REQUESTS_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'declined-requests' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'declined-requests' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-circle-xmark w-5"></i> Declined
                  Requests
                </p>
              </MenuItem>
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(STUDENT_CANCELED_INTERVIEW_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'canceled-interview' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'canceled-interview' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-ban w-5"></i> Canceled Sessions
                </p>
              </MenuItem>
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(STUDENT_RESCHEDULE_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'reschedule' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'reschedule' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-clock w-5"></i> Reschedule a Session
                </p>
              </MenuItem>
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(STUDENT_CANCEL_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'cancel' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'cancel' ? 'black' : ''}` }}
              >
                <p>
                  <img
                    src={require("../../images/cross.png")}
                    alt="cross"
                    style={{ width: "18px", display: "inline" }}
                  />{" "}
                  Cancel a Session
                </p>
              </MenuItem>
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(STUDENT_REPORT_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'report' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'report' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-triangle-exclamation w-5"></i>{" "}
                  Report an Issue
                </p>
              </MenuItem>
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(SUCCESS_ADD_STORIES_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'success-story' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'success-story' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-trophy w-5"></i> Add a Success Story
                </p>
              </MenuItem>
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(PLAN_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'plans' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'plans' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-gift w-5"></i> Discount and Coupons
                </p>
              </MenuItem>
              {!user.caId ? (<MenuItem
                id="dropdown-btn"
                onClick={() => {
                  window.open("/programs/ca", "_blank");
                }}
              >
                <p>
                  <i className="fa-solid fa-bullhorn w-5"></i> {" "}Be a Campus Ambassador
                </p>
              </MenuItem>) : (
                <MenuItem
                  id="dropdown-btn"
                  onClick={() => {
                    window.open("/programs/ca/sign-ups", "_blank");
                  }}
                >
                  <p>
                    <i className="fa-solid fa-bullhorn w-5"></i> {" "}Campus Ambassador Portal
                  </p>
                </MenuItem>)}

              {/* <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(REFER_ROUTE);
                }}
                style={{background:`${location.pathname.split("/").pop()==='refer' ? 'rgb(196, 240, 255)':''}`,color:`${location.pathname.split('/').pop()==='refer'?'black':''}`}}
              >
                <p>
                  <i className="fa-solid fa-user-plus w-5"></i> Refer Your
                  Friends
                </p>
              </MenuItem> */}
              {/* <MenuItem id="dropdown-btn"
                onClick={() => {
                  navigate(STUDENT_ACCOUNT_SETTINGS_ROUTE);
                }}
              >
                <p>
                  <i className="fa-solid fa-gear w-5"></i> Account Settings
                </p>
              </MenuItem> */}
              <hr />
              <form>
                <button className="cust-btn logout-btn" onClick={loggingOut}>
                  <i className="fa-solid fa-arrow-right-from-bracket"></i> Log
                  Out
                </button>
              </form>
            </Menu>
            <IconButton
              aria-label="more"
              className="navLoggedInDropdownIcon"
              id="long-button"
              aria-controls={Boolean(anchorEl2) ? "long-menu" : undefined}
              aria-expanded={Boolean(anchorEl2) ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e) => setAnchorEl2(e.currentTarget)}
            >
              <MenuRoundedIcon className="here" />
            </IconButton>
            <Menu
              id="long-menu"
              className="navLoggedInDropdown"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl2}
              open={Boolean(anchorEl2)}
              onClose={() => setAnchorEl2(null)}
            >
              <Link onClick={() => { setAnchorEl2(null) }} to={STUDENT_FIND_AN_INTERVIEWER_ROUTE}>
                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'find-an-interviewer' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'find-an-interviewer' ? 'black' : ''}` }}>
                  <p>Find an Interviewer</p>
                </MenuItem>
              </Link>
              <Link onClick={() => { setAnchorEl2(null) }} to={STUDENT_SENT_REQUESTS_ROUTE}>
                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'sent-requests' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'sent-requests' ? 'black' : ''}` }}>
                  <p>Sent Requests</p>
                </MenuItem>
              </Link>
              <Link onClick={() => { setAnchorEl2(null) }} to={STUDENT_ACCEPTED_REQUESTS_ROUTE}>
                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'accepted-requests' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'accepted-requests' ? 'black' : ''}` }}>
                  <p className="flex">
                    Accepted Requests <div className="notify"></div>
                  </p>
                </MenuItem>
              </Link>
              <Link onClick={() => { setAnchorEl2(null) }} to={STUDENT_UPCOMING_INTERVIEWS_ROUTE}>
                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'upcoming-interviews' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'upcoming-interviews' ? 'black' : ''}` }}>
                  <p>Upcoming Interviews</p>
                </MenuItem>
              </Link>
              <Link onClick={() => { setAnchorEl2(null) }} to={STUDENT_COMPLETED_INTERVIEWS_ROUTE}>
                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'completed-interviews' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'completed-interviews' ? 'black' : ''}` }}>
                  <p className="flex">
                    Completed Interviews <div className="notify"></div>
                  </p>
                </MenuItem>
              </Link>
            </Menu>
          </div>
        </>
      </div>
    </>
  );
};

export default React.memo(Navbarlogedin);
<ul className="nav-list flex">
  {/* 
<li>
  {user.mobNumber===""?
  <NavLink
    end
    className="nav-item hover-underline-animation"
    activeClassName="active"
    to={STUDENT_PROFILE_FORM_ROUTE}
    onClick={()=>{toast.success(`Hello`, {
      ...toastStyle.success,
    });}}
  >
    <div>Find an Interviewer</div>
  </NavLink> :
    <NavLink
    end
    className="nav-item hover-underline-animation"
    activeClassName="active"
    to={STUDENT_FIND_AN_INTERVIEWER_ROUTE}
  >
    <div>Find an Interviewer</div>
  </NavLink> }
</li>

<li>
  {user.mobNumber===""?
  <NavLink
    end
    className="nav-item hover-underline-animation"
    activeClassName="active"
    to={STUDENT_PROFILE_FORM_ROUTE}
    onClick={()=>{toast.success(`Hello`, {
      ...toastStyle.success,
    });}}
  >
    <div>Sent Requests</div>
  </NavLink> : 
    <NavLink
    end
    className="nav-item hover-underline-animation"
    activeClassName="active"
    to={STUDENT_SENT_REQUESTS_ROUTE}
  >
    <div>Sent Requests</div>
  </NavLink>
  }
</li>

<li>
  {user.mobNumber===""?
    <NavLink
    end
    className="nav-item hover-underline-animation"
    activeClassName="active"
    to={STUDENT_PROFILE_FORM_ROUTE}
    onClick={()=>{toast.success(`Hello`, {
      ...toastStyle.success,
    });}}
  >
    <div className="flex">
      Accepted Requests <div className="notify"></div>
    </div>
  </NavLink>:<NavLink
    end
    className="nav-item hover-underline-animation"
    activeClassName="active"
    to={STUDENT_ACCEPTED_REQUESTS_ROUTE}
  >
    <div className="flex">
      Accepted Requests <div className="notify"></div>
    </div>
  </NavLink>}
</li>

{user.mobNumber===""?
<li>
  <NavLink
    end
    className="nav-item hover-underline-animation"
    activeClassName="active"
    to={STUDENT_PROFILE_FORM_ROUTE}
    onClick={()=>{toast.success(`Hello`, {
      ...toastStyle.success,
    });}}
  >
    <div>Upcoming Interviews</div>
  </NavLink>
</li>:<li>
  <NavLink
    end
    className="nav-item hover-underline-animation"
    activeClassName="active"
    to={STUDENT_UPCOMING_INTERVIEWS_ROUTE}
  >
    <div>Upcoming Interviews</div>
  </NavLink>
</li>
}
{user.mobNumber===""?
  <li>
    <NavLink
      end
      className="nav-item hover-underline-animation"
      activeClassName="active"
      to={STUDENT_PROFILE_FORM_ROUTE}
      onClick={()=>{toast.success(`Hello`, {
        ...toastStyle.success,
      });}}
    >
      <div className="flex">
        Completed Interviews <div className="notify"></div>
      </div>
    </NavLink>
  </li> : <NavLink
      end
      className="nav-item hover-underline-animation"
      activeClassName="active"
      to={STUDENT_COMPLETED_INTERVIEWS_ROUTE}
    >
      <div className="flex">
        Completed Interviews <div className="notify"></div>
      </div>
    </NavLink>
} */}
</ul>;
