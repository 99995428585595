// // import dotenv from "dotenv"
// // dotenv.config();

export const BASE = process.env.REACT_APP_BACKEND_SERVER_URL; // uncomment when live!
// export const BASE = process.env.REACT_APP_FRONTEND_SERVER_URL; // uncomment when live!
// Auth
export const AUTH_TOKEN_ENDPOINT = BASE + "/auth/token";
// Auth - Students
export const AUTH_STD_GOOGLE_ENDPOINT = BASE + "/auth/std/googlelogin";
export const AUTH_STD_FACEBOOK_ENDPOINT = BASE + "/auth/std/facebooklogin";
export const AUTH_STD_LINKEDIN_ENDPOINT = BASE + "/auth/linkedin";
export const AUTH_SINGLE_GMAIL_INSTITUTE =
  BASE + "/auth/checkSingleGmailInstitute";
export const AUTH_CUSTOM_EMAIL = BASE + "/auth/customEmailCheck";
export const AUTH_CUSTOM_SIGNUP = BASE + "/auth/customEmailSignup";
// Auth - Professionals
export const AUTH_INT_GOOGLE_ENDPOINT = BASE + "/auth/int/googlelogin";
export const AUTH_INT_FACEBOOK_ENDPOINT = BASE + "/auth/int/facebooklogin";
export const AUTH_INT_LINKEDIN_ENDPOINT = BASE + "/auth/i/linkedin";
// Interviewer
export const INTERVIEWERS_GET_ENDPOINT = BASE + "/int/get";
export const INTERVIEWERS_GETBYFILTERS_ENDPOINT = BASE + "/int/getByFilters";
export const INTERVIEWER_UPDATE_ENDPOINT = BASE + "/int/update";
// Admin
export const AUTH_ADM_GOOGLE_ENDPOINT = BASE + "/auth/adm/googlelogin";
export const ADMIN_GETBYFILTERS_ENDPOINT = BASE + "/int/adm/getByFilters";
// Video Call
export const VIDEO_CALL_GET_CHATS = BASE + "/vc/chats";
export const VIDEO_CALL_GET_TIMER = BASE + "/vc/timer";
export const VIDEO_CALL_UPLOAD_FILE = BASE + "/vc/file/upload";
export const GET_UPCOMING_MOCK_INTERVIEWS = BASE + "/vc/upcoming-interview";
export const GET_REQUEST_INTERVIEWS = BASE + "/vc/request-interviews";
export const GET_STUDENT_INFO = BASE + "/vc/student-info";
export const GET_INETRVIEWER_INFO = BASE + "/vc/interviewer-info";
export const FEEDBACK = BASE + "/vc/feedback";
export const GET_STUDENT_INTERVIEWS = BASE + "/vc/get-student-interviews";
export const GET_INTERVIEWER_INTERVIEWS =
  BASE + "/vc/get-interviewer-interviews";
export const GET_INTERVIEW_DETAILS = BASE + "/vc/get-interview-details";
