import React from "react";
// import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { testimonials2022 } from "./Partner/testimonialsData";
import { testimonials2023 } from "./Partner/testimonialsData2023";
import "./SeeAllTestimonial.css";
import { Pagination } from "@mui/material";
import { useState } from "react";

const splitArray = (arr, rows) => {
  const itemsPerRow = rows;
  return arr.reduce((acc, val, ind) => {
    const currentRow = Math.floor(ind / itemsPerRow);
    if (!acc[currentRow]) {
      acc[currentRow] = [val];
    } else {
      acc[currentRow].push(val);
    }
    //  console.log(acc,testimonials)
    return acc;
  }, []);
};

const SeeAllTestimonial = () => {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const color = ["#febfbd", "#fdddaa", "#b1e8ca"];

  const [value, setValue] = useState("1");
  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  const testimonialsPages = splitArray(
    value == "1" ? testimonials2022 : testimonials2023,
    10
  );

  return (
    <div className="p-2">
      <div style={{ paddingTop: "35px" }} className="page-heading-h1">
        Success Stories
      </div>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabsChange}
              aria-label="lab API tabs example"
              sx={{ fontSize: "32px" }}
              centered
            >
              <Tab
                label="2022"
                value="1"
                sx={{ fontSize: "24px", fontWeight: 700 }}
              />
              <Tab
                label="2023"
                value="2"
                sx={{ fontSize: "24px", fontWeight: 700 }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="normalText details-box">
              Read the{" "}
              <a
                href="https://www.iitmaa.org/f/mock-interview-12582"
                className="underline text-blue-600 hover:text-blue-800"
              >
                Blog Article
              </a>{" "}
              on the role of Fyzen Career Solutions Pvt Ltd (owner of
              interwiu.com) in the success of below students by conducting Mock
              Interviews (with Feedback Sessions) before the Campus Placements
              (2021-22) at IIT Madras.
              <br /> (published by <b> IIT Madras Alumni Association</b>)
            </div>
            {testimonialsPages[page - 1].map((testimonial, index) => {
              return (
                <div className="my-5 mx-auto">
                  <div
                    className="text-black rounded-lg p-3 mx-auto main-box"
                    style={{
                      maxWidth: "1100px",
                      backgroundColor: color[index % 3],
                    }}
                    key={index}
                  >
                    <div className="col-span-1 my-auto">
                      <div
                        className="rounded-3xl m-3 mx-auto overflow-hidden  img-box"
                        style={{ height: "169px", width: "169px" }}
                      >
                        {value === "1" ? (
                          <img
                            src={require("../../images/testimonial-image/" +
                              testimonial.name +
                              ".jpg")}
                            className="object-cover"
                            alt="Person"
                          />
                        ) : null}
                      </div>
                      <div className="text-center">
                        <Typography
                          variant="h5"
                          component="h5"
                          style={{ fontSize: "1.2rem" }}
                          sx={{ fontWeight: "bold" }}
                        >
                          {testimonial.name}
                        </Typography>
                        {/* <p>
                            Place at {testimonial.company} as {testimonial.position}
                          </p> */}
                      </div>
                    </div>
                    <div className="col-span-3 my-auto">
                      <Typography
                        variant="body1"
                        sx={{ m: 0, fontSize: "18px" }}
                        gutterBottom
                      >
                        {testimonial.degree} <b>IIT Madras</b>, 2022
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "18px" }}
                        gutterBottom
                      >
                        Placed at <b>{testimonial.placedIn}</b> as{" "}
                        <b>{testimonial.position}</b>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "justify",
                          lineHeight: 1.2,
                          fontSize: 16,
                          mt: 2,
                        }}
                      >
                        <i class="fa-solid fa-quote-left text-green-800"></i>{" "}
                        {testimonial.comment}{" "}
                        <i class="fa-solid fa-quote-right text-green-800"></i>
                      </Typography>
                    </div>
                  </div>
                </div>
              );
            })}
            <Pagination
              count={Math.ceil(testimonials2022.length / 10)}
              page={page}
              onChange={handleChange}
              style={{
                margin: "30px",
                display: "flex",
                justifyContent: "center",
              }}
              color="primary"
            />
          </TabPanel>
          <TabPanel value="2">
            {testimonialsPages[page - 1].map((testimonial, index) => {
              return (
                <div className="my-5 mx-auto">
                  <div
                    className="text-black rounded-lg p-3 mx-auto main-box"
                    style={{
                      maxWidth: "1100px",
                      backgroundColor: color[index % 3],
                    }}
                    key={index}
                  >
                    <div className="col-span-1 my-auto">
                      <div
                        className="rounded-3xl m-3 mx-auto overflow-hidden  img-box"
                        style={{ height: "169px", width: "169px" }}
                      >
                        {value === "2" ? (
                          <img
                            src={
                              require("../../images/testimonial-image/2023/" +
                                testimonial.name +
                                ".png") || require("../../images/photo.png")
                            }
                            className="object-cover h-full w-full"
                            alt="Person"
                          />
                        ) : null}
                      </div>
                      <div className="text-center">
                        <Typography
                          variant="h5"
                          component="h5"
                          style={{ fontSize: "1.2rem" }}
                          sx={{ fontWeight: "bold" }}
                        >
                          {testimonial.name}
                        </Typography>
                        {/* <p>
                            Place at {testimonial.company} as {testimonial.position}
                          </p> */}
                      </div>
                    </div>
                    <div className="col-span-3 my-auto">
                      <Typography
                        variant="body1"
                        sx={{ m: 0, fontSize: "18px" }}
                        gutterBottom
                      >
                        {testimonial.degree}, {testimonial.Branch},{" "}
                        <span className="font-bold">
                          {testimonial.Institute}
                        </span>
                        , {testimonial.Year}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "18px" }}
                        gutterBottom
                      >
                        Placed at <b>{testimonial.placedIn}</b> as{" "}
                        <b>{testimonial.position}</b>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "justify",
                          lineHeight: 1.2,
                          fontSize: 16,
                          mt: 2,
                        }}
                      >
                        <i class="fa-solid fa-quote-left text-green-800"></i>{" "}
                        {testimonial.comment}{" "}
                        <i class="fa-solid fa-quote-right text-green-800"></i>
                      </Typography>
                    </div>
                  </div>
                </div>
              );
            })}
            <Pagination
              count={Math.ceil(testimonials2023.length / 10)}
              page={page}
              onChange={handleChange}
              style={{
                margin: "30px",
                display: "flex",
                justifyContent: "center",
              }}
              color="primary"
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default SeeAllTestimonial;
