import React, { useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Rating,
  TextField,
  Chip,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@material-ui/core/IconButton";
import {
  t24HDecimalTo12HFormat,
  toastStyle,
  convertDateFormat,
} from "../../../../utility/helper";
import toast from "react-hot-toast";
import axios from "axios";
import { BASE, GET_UPCOMING_MOCK_INTERVIEWS, GET_REQUEST_INTERVIEWS } from "../../../../constants/endpoints"
import "./RateAndReview.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { EditAttributesOutlined } from "@mui/icons-material";
import { MdEditNote } from "react-icons/md";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '90%',
  maxWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};


const RateAndReview = ({ mock_id, setRated, rated, page }) => {
  const [int, setint] = React.useState();
  const [value, setValue] = React.useState(3.5);
  const [hover, setHover] = React.useState(-1);
  const [comment, setComment] = React.useState("");
  const [interviewInfo, setInterviewInfo] = React.useState();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    axios.get(GET_UPCOMING_MOCK_INTERVIEWS + "/" + mock_id).then(res => {
      setInterviewInfo(res.data)
    }).catch(err => {
      console.log(err);
    })

  }, [])


  useEffect(() => {
    axios.get(`${BASE}/send/getMockInterview/${mock_id}`).then((res) => {
      setValue(res.data?.sRating)
      setComment(res.data?.sComment)
    });
  }, []);

  useEffect(() => {
    if (!mock_id) return;
    let interruptData = window.localStorage.getItem("vcInterrupt")
    if (interruptData === '[object Object]' || !interruptData)
      interruptData = {
        mockId: [],
        mockStatus: {}
      }
    else {
      interruptData = JSON.parse(interruptData)
    }
    interruptData.mockStatus = {
      ...interruptData.mockStatus,
      [mock_id]: {
        ...interruptData.mockStatus[mock_id],
        rated
      }
    }
    window.localStorage.setItem("vcInterrupt", JSON.stringify(interruptData))
  }, [rated, mock_id])

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (value) => {
    console.log(value);
    console.log(comment);
    console.log(interviewInfo);
    await axios.get(GET_REQUEST_INTERVIEWS + "/" + mock_id).then(res => {
      setInterviewInfo({ ...interviewInfo, sId: res.data.studId })
      const sId = res.data.studId;
      axios
        .post(`${BASE}/send/addRating/${sId}`, {
          mockId: mock_id,
          reqId: interviewInfo.reqId,
          meetUrl: interviewInfo.meetUrl,
          sRating: value,
          sComment: comment,
        })
        .then((res) => {
          console.log(res);
          handleClose();
          setRated(true)
          toast.success("Thank you for the review.", {
            ...toastStyle.success,
            position: "top-center",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }).catch(err => {
      console.log(err);
    })
  };

  return (
    <>
      <Button
        sx={{
          color: !rated ? "white" : "primary.light",
          borderRadius: "5px",
          border: "1px solid",
          borderColor: "primary.light",
          backgroundColor: !rated ? "error.main" : "transparent",
          '&:hover': {
            backgroundColor: !rated ? "error.dark" : "transparent"
          },
          px: 0.5,
          py: 0,
          pointerEvents: !rated ? "auto" : "none"
        }}
        onClick={handleOpen}
        className={page === "end" ? "" : !rated ? "s-mobile-btn-vc blink" : "s-mobile-btn-vc"}
      >
        {!rated ? "Rate & Review" : <span>
          Rated & Reviewed&nbsp;
          <FontAwesomeIcon
            icon={faPenToSquare}
            className="fa-edit-icon"
            onClick={() => {
              // alert("clicked")
              setint(null);
              setOpen(!open);
            }}
          />
        </span>}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Rate and Review
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please Rate and Review your Interview Experience.
          </Typography>
          <Box
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Rating
              name="hover-feedback"
              value={value}
              precision={0.5}
              getLabelText={getLabelText}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            {value !== null && (
              <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <TextField
              id="outlined-multiline-static"
              label="Review"
              multiline
              rows={4}
              sx={{
                mb: 2,
              }}
              value={comment}
              onChange={(event, newValue) => {
                setComment(event.target.value);
              }}
            />
            <Button
              variant="outlined"
              onClick={() => {
                handleSubmit(value);
              }}
              style={{ border: '1px solid #1565C0', borderRadius: '5px', width: '70%', margin: 'auto' }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RateAndReview;
