import { useRef, useState, useEffect } from 'react'
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Chip,
  Divider,
  Box,
  IconButton,
  ClickAwayListener,
  Tooltip,
  Button
} from "@mui/material"
import { ExpandMore } from "@mui/icons-material";




const CoreChip = ({ branch, specs, page, customStyle }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    console.log("close")
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    console.log("open")
    setOpen(true);
  };
  const [open2, setOpen2] = useState(false);

  const handleTooltipClose2 = () => {
    console.log("close")
    setOpen2(false);
  };

  const handleTooltipOpen2 = () => {
    console.log("open")
    setOpen2(true);
  };
  // console.log(coreprofiles,specializations)
  // console.log("specs prop",specs)
  const [showTitle, setShowTitle] = useState(false)
  const chipRef = useRef(null)
  useEffect(() => {
    if (chipRef?.current) {
      // console.log("parent offsetWidth", chipRef.current.offsetWidth)
      // console.log("parent scrollWidth", )
      setShowTitle(chipRef.current.scrollWidth < chipRef.current.children[0].scrollWidth)
      // console.log(chipRef.current.props)
      // setShowTitle(chipRef?.current.value.endsWith('..'));
    }
  }, [chipRef]);

  const [showTitle2, setShowTitle2] = useState(false)
  const chipRef2 = useRef(null)
  useEffect(() => {
    if (chipRef2?.current) {
      // console.log("parent offsetWidth", chipRef.current.offsetWidth)
      // console.log("parent scrollWidth", )
      setShowTitle2(chipRef2.current.scrollWidth < chipRef2.current.children[0].scrollWidth)
      // console.log(chipRef.current.props)
      // setShowTitle(chipRef?.current.value.endsWith('..'));
    }
  }, [chipRef2]);

  let isCustom = page === "iprofile"

  const noSpecChip = (
    <Box sx={{
      height: "min-content",
      display: isCustom ? "inline-flex" : "flex",
      justifyContent: "flex-start",
      width: isCustom ? "fit-content" : "100%",
      ...customStyle
    }} >
      {/* {
        !showTitle2 ? <Chip
        ref={chipRef2}
        title={showTitle2 ? branch : null}
        label={branch}
        color={isCustom ? "default" : "info"}
        variant={isCustom ? "outlined" : "filled"}
        sx={{ letterSpacing: isCustom ? "0" : "1px", fontWeight: isCustom ? "normal" : "bold", maxWidth: "100%" }}/>
        : <Chip
        ref={chipRef2}
        title={showTitle2 ? branch : null}
        label={branch}
        color={isCustom ? "default" : "info"}
        variant={isCustom ? "outlined" : "filled"}
        sx={{ letterSpacing: isCustom ? "0" : "1px", fontWeight: isCustom ? "normal" : "bold", maxWidth: "100%" }}/>
      } */}
      {/* <Chip
          ref={chipRef2}
          title={showTitle2 ? branch : null}
          label={branch}
          color={isCustom ? "default" : "info"}
          variant={isCustom ? "outlined" : "filled"}
          sx={{ letterSpacing: isCustom ? "0" : "1px", fontWeight: isCustom ? "normal" : "bold", maxWidth: "100%" }}
        /> */}
      {/* 
      <div style={{ width: '100%' }} className='s-mobile-chip-tooltop-dist'>
        <ClickAwayListener onClickAway={handleTooltipClose2}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            placement="top"
            onClose={handleTooltipClose2}
            open={open2}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={showTitle ? branch : null}
          > */}
      {/* <Chip
              ref={chipRef2}
              title={showTitle ? branch : null}
              label={branch}
              // onClick={handleTooltipOpen2}
              color={isCustom ? "default" : "info"}
              variant={isCustom ? "outlined" : "filled"}
              sx={{ letterSpacing: isCustom ? "0" : "1px", fontWeight: isCustom ? "normal" : "bold", maxWidth: "100%" }}
            /> */}
      { }
      {/* </Tooltip>
        </ClickAwayListener>
      </div> */}

      {
        !showTitle2 || window.innerWidth>600 ?
          <Chip
            ref={chipRef2}
            title={showTitle2 ? branch : null}
            label={branch}
            // onClick={handleTooltipOpen2}
            color={isCustom ? "default" : "info"}
            variant={isCustom ? "outlined" : "filled"}
            sx={{ letterSpacing: isCustom ? "0" : "1px", fontWeight: isCustom ? "normal" : "bold", maxWidth: "100%"}}
          /> :
            <div className="tt ml-auto w-full">
              <Chip
                ref={chipRef2}
                title={showTitle2 ? branch : null}
                label={branch}
                // onClick={handleTooltipOpen2}
                color={isCustom ? "default" : "info"}
                variant={isCustom ? "outlined" : "filled"}
                sx={{ letterSpacing: isCustom ? "0" : "1px", fontWeight: isCustom ? "normal" : "bold", maxWidth: "100%"}}
              />
              <span  style={{fontSize:'0.8rem', background: 'black', color: 'white', padding: '3px 5px' ,bottom:'106%', width:'100%',top:'auto'}} className="tttext">
                {showTitle2 ? branch : null}
              </span>
            </div>

      }

    </Box>
  )
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasSpecChip = (
    <Box sx={{
      height: "min-content",
      display: isCustom ? "inline-flex" : "flex",
      alignItems: "center",
      maxWidth: "91%",
      minWidth: isCustom ? "fit-content" : "",
      ...customStyle
    }} >
      {!showTitle || window.innerWidth >600 ? <Chip
        ref={chipRef}
        title={showTitle ? branch : null}
        label={branch}
        color={isCustom ? "default" : "info"}
        variant={isCustom ? "outlined" : "filled"}
        sx={{
          letterSpacing: isCustom ? "0" : "1px",
          fontWeight: isCustom ? "normal" : "bold",
          borderRadius: '100px',
          '&:first-child': {
            borderRadius: '100px 0 0 100px',
          },
          '&:last-child': {
            borderRadius: '0 100px 100px 0',
          },
          '&:hover': {
            borderRadius: '100px 0 0 100px',
          }
        }}
      /> :
        // <div style={{ width: '100%' }} className='s-mobile-chip-tooltop-dist'>
        //   <ClickAwayListener onClickAway={handleTooltipClose}>
        //     <Tooltip
        //       PopperProps={{
        //         disablePortal: true,
        //       }}
        //       placement="top"
        //       onClose={handleTooltipClose}
        //       open={open}
        //       disableFocusListener
        //       disableHoverListener
        //       disableTouchListener
        //       title={showTitle ? branch : null}
        //     >
        //     </Tooltip>
        //   </ClickAwayListener>
        // </div>

          <div className="tt ml-auto w-full">
              <Chip
                ref={chipRef}
                // title={showTitle ? branch : null}
                label={branch}
                color={isCustom ? "default" : "info"}
                variant={isCustom ? "outlined" : "filled"}
                // onClick={handleTooltipOpen}
                sx={{
                  letterSpacing: isCustom ? "0" : "1px",
                  fontWeight: isCustom ? "normal" : "bold",
                  borderRadius: '100px',
                  '&:first-child': {
                    borderRadius: '100px 0 0 100px',
                  },
                  '&:last-child': {
                    borderRadius: '100px 0 0 100px',
                  },
                  '&:hover': {
                    borderRadius: '100px 0 0 100px',
                  }
                }}
              />
              <span  style={{fontSize:'0.8rem', background: 'black', color: 'white', padding: '3px 5px' ,bottom:'106%', width:'100%',top:'auto'}} className="tttext">
                {showTitle ? branch : null}
              </span>
            </div>

      }

      <Divider orientation="vertical" />
      <IconButton onClick={handleClick} sx={{
        m: 0,
        p: 0,
        backgroundColor: isCustom ? "white" : "info.main",
        color: isCustom ? "black" : "white",
        height: "32px",
        borderRadius: '100px',
        '&:first-child': {
          borderRadius: '100px 0 0 100px',
        },
        '&:last-child': {
          borderRadius: '0 100px 100px 0',
        },
        '&:hover': {
          borderRadius: '0 100px 100px 0',
          backgroundColor: isCustom ? "default.light" : "info.dark"
        },
        border: isCustom ? "1px solid #bdbdbd" : "",
        // borderColor: isCustom?"":""
      }}>
        <ExpandMore />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={{
          zoom: 0.8,
          maxWidth: "400px"
        }}>
          {specs[0]?.subDomains?.map((spec, index) => (
            <ListItem key={index} >
              <ListItemText primary={spec} sx={{
                whiteSpace: "break-spaces"
              }} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  )

  return (specs[0] && specs.length !== 0) ? hasSpecChip : noSpecChip

}

export default CoreChip;