import { createContext } from "react";
import { VideoClient } from "@zoom/videosdk";
export const ZoomUserContext = createContext();
export const ZoomContext = createContext(VideoClient);
export const ZoomMediaContext = createContext({
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
  mediaStream: null,
});
