import React, { useState } from "react";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
// import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as SvgDecoratorBlob1 } from "./../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "./../../images/svg-decorator-blob-8.svg";
import { Typography } from "@mui/material";

const Column = tw.div`flex flex-col items-center`;

const FAQSContainer = tw.dl` max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const ProfessionalFAQS = () => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  const faqs = [
    {
      question: "What is interwiu.com?",
      answer:
        "interwiu.com is an online mock interview and mentorship platform which connects the students (as interviewees/mentees) with the professionals from top MNCs (as interviewers/mentors) using a filter-based smart match-making algorithm, to facilitate the Mock Interviews and Mentorship Sessions with integral Feedback. The interviewers and mentors serving at the platform are experienced professionals from various big MNCs like Microsoft, Amazon, Google, Goldman Sachs, McKinsey & Company, IBM, Flipkart, JPMorgan Chase & Co., American Express, Meta, Citi, HSBC, Morgan Stanley, Boston Consulting Group (BCG), Bain & Company, PwC, Netflix, Directi, IQVIA, Kotak Mahindra Bank, BNY Mellon, Hexagon Capability Center India Pvt Ltd, Accenture, Deloitte, etc.",
    },
    {
      question: "How is a Mock Interview or Mentorship Session scheduled?",
      answer:
        "A student browses the professionals, apply filters (profile, suitable date, etc.) to refine his/her search, and send a Mock Interview Request or Mentorship Session Request to each of the best suiting professionals by picking a date and time-slot as per the respective professional's and his/her own availability",
    },
    {
      question:
        "What should I do after receiving a Mock Interview Request or Mentorship Session Request from a student?",
      answer:
        "As you see a Mock Interview Request or Mentorship Session Request received from a student, you can either accept it straightaway, or can first look at the resume of the student and then accept his/her Mock Interview Request or Mentorship Session Request as suitable",
    },
    {
      question:
        "How and when do I get the meeting link for a mock interview or mentorship session?",
      answer:
        "Once you accept a student's Mock Interview Request or Mentorship Session Request, the student makes the payment for the mock interview or mentorship session. Then, a unique meeting link is automatically generated by interwiu.com which gets embedded in the [Join Mock interview] button or [Join Mentorship Session] button provided on the card of each such confirmed mock interview or mentorship session. The same meeting link is provided to the respective student as well",
    },
    {
      question:
        "What structure of a Mock Interview (with Live Feedback) I am expected to keep?",
      answer:
        "The mock interviews should be structured exactly like real placement/internship interviews in every context such as type/kind, level, and number of questions asked; inclusion of stress rounds; environment; etc. Please provide feedback during/after the mock interview on every possible aspect like a student's incorrect or partially correct answers, lack of confidence, lack of clarity, body language, etc. You can convey what went wrong/right, and how the student can improve the performance. Also, you can suggest better ways of answering certain questions asked by you to which the student's response is unsatisfactory ",
    },
    {
      question:
        "What is the duration of a Mock Interview or Mentorship Session?",
      answer:
        "interwiu.com offers Mock Interviews and Mentorship Sessions of two different durations, viz., 30 minutes and 45 minutes. The student can send a request for any of them. In case of 45 minutes, the duration can be extended based on your availability and requirements of the student",
    },
    {
      question:
        "How and when do I receive my payment for a mock interview or mentorship session?",
      answer:
        "After the mock interview or mentorship session is successfully done as per the schedule, your payment will be credited to you by interwiu.com within a window of 7 hours",
    },
    {
      question: "Why do I need to create an account or a profile?",
      answer:
        "Your short profile is needed so that the most relevant and best suiting students can find you on interwiu.com, and then request you for a mock interview or mentorship session",
    },
    {
      question: "How and when should I provide the feedback to the student?",
      answer:
        "You can convey the detailed feedback points to the student in a verbal form during and/or in the end of the mock interview, as required/suitable. In case of extended (45 minutes) mock interviews or mentorship sessions, please fill up the feedback form and submit the same immediately after the mock interview or mentorship session",
    },
    {
      question:
        "Can I take multiple mock interviews or mentorship sessions? If yes, will I be assigned the same student or different students?",
      answer:
        "Yes, you can take any number of mock interviews or mentorship sessions depending on the mock interview requests or mentorship session requests received by you. It depends on a student whether to request you for a mock interview or mentorship session repeatedly, one after another. You are free to accept any or all of the mock interview requests or mentorship session requests irrespective of whether the requests are made by the same student or different students. In case there are repeated requests from the same student, you can track his/her progress as per the feedback that you provide each time",
    },
    {
      question:
        "How and when can I report an issue about an upcoming or a completed mock interview or mentorship session?",
      answer:
        "You can report an issue about any upcoming or completed mock interview or mentorship session by using the [Report an Issue] option only upto 6 hours after the respective mock interview slot or mentorship session slot",
    },
    {
      question:
        "What can I do if I am not available for an already confirmed mock interview or mentorship session?",
      answer:
        "If the student has paid for the mock interview or mentorship session (to confirm it) after you accepted his/her mock interview request or mentorship session request, but now you are not going to be available during the scheduled time-slot of the mock interview or mentorship session, you can ask the student to reschedule it as per your availability now and cancel the current mock interview or mentorship session from your end by using the [Cancel a Session] option",
    },
  ];

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <Typography sx={{}} variant="h3">
            FAQs for Professionals
          </Typography>

          <Typography>Got a question? We're here to answer! </Typography>

          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{
                    duration: 0.3,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
export default ProfessionalFAQS;
