import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import axios from "axios";
import "./StoryForm.css";
import toast from "react-hot-toast";
import { reduce_image_file_size, toastStyle } from "../../utility/helper";
import { Helmet } from "react-helmet";
import { BASE } from "../../constants/endpoints";
import Navbarlogedin from "./Navbarlogedin";
import { COMPANY_NAME } from "../../constants/variables";
import UserContext from "../../contexts/UserContext";
import FileBase from "react-file-base64";
import { Oval } from "react-loader-spinner";
import { IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StoryForm = () => {
  let navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);
  // console.log(user.photo)
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // User Data
  const [userData, setUserData] = useState({});
  const [photo, setPhoto] = useState(null);
  const [imageSize, setimageSize] = useState(false);
  const [newImage, setnewImage] = useState("");
  const [achievement, setAchievement] = useState();
  // Handling Change
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(name, value);
    setUserData((userData) => ({ ...userData, [name]: value }));
  };
  const [loaded, setLoaded] = useState(true);
  const handleSubmit = async (e) => {

    const validateLinkedInLink = (linkedInURL) => {
      const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9-_]+\/?$/;
      return regex.test(linkedInURL);
    };

    // console.log(userData);
    if(imageSize){
      alert("Please upload image of size less than 2.5MB")
    }
    else if (
      !userData.companyName ||
      !userData.companyRole ||
      !newImage ||
      !userData.comment ||
      !achievement ||
      !userData.linkedInURL
    ) {
      alert("Kindly fill all the required fields");
    } else {

      if(!validateLinkedInLink(userData.linkedInURL)){
        alert("Please give a valid LinkedIn Profile link");
        return;
      }

      e.preventDefault();
      axios
        .patch(`${BASE}/send/addSuccessStories/${user.id}`, {
          userData,
          newImage,
          achievement,
          institute: user.institute,
          name: user.fname + " " + user.lname,
          degree: user.branch,
        })
        .then((res) => {
          // console.log(res.data);
          toast.success("Success Story Uploaded Successfully", {
            ...toastStyle.success,
            position: "top-center",
          });
          setTimeout(() => {
            if(achievement === "I got Placed!"){
              
              localStorage.setItem("name",user.fname + " " + user.lname)
              localStorage.setItem("company",userData.companyName)
              localStorage.setItem("profile",userData.companyRole)

              console.log(localStorage.getItem("name"));
              console.log(localStorage.getItem("company"));
              console.log(localStorage.getItem("profile"));

              if (localStorage.getItem("isAuthenticated"))
              localStorage.removeItem("isAuthenticated");
              setUser({});

              // axios.get(`${BASE}/auth/logout`).then(()=>{
                navigate('/')
              // })
              // <form action={`${BASE}/auth/logout`}>
              //     <button className="cust-btn logout-btn" onClick={loggingOut}>
              //       <i className="fa-solid fa-arrow-right-from-bracket"></i> Log
              //       Out
              //     </button>
              // </form>
              // navigate('/')

            }else{
              navigate('/s/find-an-interviewer')
            }
          }, 1000);
        })
        .catch((e) => {
          console.log(e);
        });
      // console.log(e);
      // console.log(userData);
    }
  };
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Add a Success Story</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      {loaded ? (
        <div className="profile-form py-1" style={{}}>
          <h1
            className="text-5xl font-large text-center pt-8 pb-4 page-heading-h1"
            style={{ paddingLeft: `${window.innerWidth < 600 ? '1rem' : '0rem'}`, textAlign: `${window.innerWidth < 600 ? 'left' : 'center'}`, margin: '0px', color: "black", fontWeight: "bold", fontSize: 'clamp(20px, 6vw, 3rem)', marginTop: 'clamp(0px, -6vw, 3rem)', marginBottom: 'clamp(0px, -6vw, 3rem)' }}
          >
            Add a Success Story
          </h1>
          {/* <form> */}
          <div className="story-form-cont" style={{}}>
            <div className="profile-form-text" style={{}}>
              <div className="name cust-row" style={{}}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: 'wrap',
                    gap: '4%',
                    // border: "3px solid red"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // flexDirection: "column",
                      alignItems: "center",
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                      minWidth: "100%",
                      // border: "3px solid black"
                    }}
                  >
                    <div
                      className="element"
                      style={{ paddingBottom: "15px", width: window.innerWidth > 600 ? "40%" : "100%" }}
                    >
                      <label htmlFor="firstname">
                        Your Achievement <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        // multiple
                        displayEmpty
                        required
                        sx={{ width: 1 }}
                        renderValue={() => achievement || "Your Achievement"}
                        MenuProps={MenuProps}
                        onChange={(e) => {
                          setAchievement(e.target.value);
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="I got Placed!">I got Placed!</MenuItem>
                        <MenuItem value="I secured an Internship!">
                          I secured an Internship!
                        </MenuItem>
                      </Select>
                    </div>



                    <div
                      className="element"
                      style={{ paddingBottom: "15px", width: 'fit-content', marginRight: "7px" }}
                    >
                      <label
                        htmlFor="lastname"
                        className="pb-2"
                        style={{ paddingBottom: "20px" }}
                      >
                        Photo to be displayed on the platform (interwiu.com){" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <div className="filebase pt-2" style={{ marginLeft: "20px" }}>
                        <FileBase
                          type="file"
                          multiple={false}
                          style={{ paddingLeft: "0" }}
                          onDone={async ({ base64, size }) => {
                            if (parseInt(size) < 2500) {
                              setimageSize(false);
                              setnewImage(await reduce_image_file_size(base64));
                              console.log(await reduce_image_file_size(base64));
                            } else {
                              // console.log(size)
                              // console.log(!newImage)
                              setimageSize(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                    {/* {achievement &&
                      <div
                        className="element"
                        style={{ paddingBottom: "15px" }}
                      >
                        <label htmlFor="firstname">
                          {achievement === "I got Placed!"
                            ? "Placed at"
                            : achievement === "I secured an Internship!"
                              ? "Secured an Internship at"
                              : ""}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          id="fname"
                          type="text"
                          name="companyName"
                          className="border-2 rounded"
                          onChange={handleChange}
                          placeholder="Full name of the Company"
                          style={{ height: "56px" }}
                        />
                      </div>
                    } */}
                    {/* <div
                      className="element"
                      style={{ paddingBottom: "15px", marginTop:"13px" }}
                    >
                      <label htmlFor="lastname">
                        Placed as <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        id="lname"
                        type="text"
                        name="companyRole"
                        className="border-2 rounded"
                        // value={userData.lname}
                        onChange={handleChange}
                        placeholder="Name of the Profile"
                        style={{ height: "56px" }}
                      />
                    </div> */}
                  </div>

                </div>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  // flexWrap: 'wrap',
                  gap: '4%',
                  // border: "3px solid red"
                }}>
                  <div style={{
                    display: "flex",
                    // flexDirection: "column",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: 'space-between',
                    minWidth: "100%",
                    // border: "3px solid black"
                  }}>
                    {achievement &&
                      <div
                        className="element"
                        style={{ paddingBottom: "15px", width: window.innerWidth > 600 ? "40%" : "100%" }}
                      >
                        <label htmlFor="firstname">
                          {achievement === "I got Placed!"
                            ? "Placed at"
                            : achievement === "I secured an Internship!"
                              ? "Secured an Internship at"
                              : ""}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          id="fname"
                          type="text"
                          name="companyName"
                          className="border-2 rounded"
                          onChange={handleChange}
                          placeholder="Full name of the Company"
                          style={{ height: "56px" }}
                        />
                      </div>
                    }

                    <div className="element" style={{ paddingBottom: "15px", width: window.innerWidth > 600 ? "40%" : "100%", marginRight: "4%" }}>
                      <label htmlFor="linkedin">
                        <i className="fa-brands fa-linkedin"></i> LinkedIn
                        <span style={{ color: "red" }}>*</span>
                        {/* <i class="fa fa-info-circle" aria-hidden="true" style={{ marginLeft: "50px" }}></i>  */}

                        <Tooltip title="Please open LinkedIn in browser, go to your profile page by clicking on [View Profile] option under [Me] button, and copy-paste the exaxt Profile URL from the respective tab (containing https://www.linkedin.com/in/...) " placement="right">
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        {/* 
                        <button title="Please open LinkedIn in browser, go to your profile page by clicking on [View Profile] option under [Me] button, and copy-paste the exaxt Profile URL from the respective tab (containing https://www.linkedin.com/in/...) " style={{marginLeft:"40px", border:"2px solid red"}}>i</button> */}


                      </label>
                      <input
                        type="text"
                        name="linkedInURL"
                        id="linkedin"
                        // value={userData.linkedInURL}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 s-mobile-text-14"
                        placeholder="LinkedIn URL"
                        style={{ height: "56px" }}

                      />
                    </div>

                  </div>


                </div>
                {/* 
                <div
                    className="element"
                    style={{ paddingBottom: "15px", width: 'fit-content' }}
                  >
                    <label
                      htmlFor="lastname"
                      className="pb-2"
                      style={{ paddingBottom: "20px" }}
                    >
                      Photo to be displayed on the platform (interwiu.com){" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>

                    <div className="filebase pt-2" style={{marginLeft:"20px"}}>
                      <FileBase
                        type="file"
                        multiple={false}
                        style={{ paddingLeft: "0" }}
                        onDone={async ({ base64, size }) => {
                          if (parseInt(size) < 500) {
                            setimageSize(false);
                            setnewImage(await reduce_image_file_size(base64));
                            console.log(await reduce_image_file_size(base64));
                          } else {
                            setimageSize(true);
                          }
                        }}
                      />
                    </div>
                  </div> */}

                <div
                  className="element"
                  style={{ paddingBottom: "15px", marginTop: "13px", width: window.innerWidth > 600 ? "40%" : "100%" }}
                >
                  <label htmlFor="lastname">
                    Placed as <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="lname"
                    type="text"
                    name="companyRole"
                    className="border-2 rounded"
                    // value={userData.lname}
                    onChange={handleChange}
                    placeholder="Name of the Profile"
                    style={{ height: "56px" }}
                  />
                </div>


                <div className="element" style={{ paddingBottom: "15px" }}>
                  <label htmlFor="lastname">
                    Comment to be displayed on the platform (interwiu.com)
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <textarea
                    id="lname"
                    type="text"
                    name="comment"
                    className="border-2 rounded s-desktop-card"
                    // value={userData.lname}
                    onChange={handleChange}
                    placeholder="Your Comment"
                    rows={4}
                    style={{
                      width: "100%",
                      maxWidth: '900px',
                      paddingTop: "7px",
                      paddingLeft: "7px",
                      marginTop: "5px",
                    }}
                  />
                  <textarea
                    id="lname"
                    type="text"
                    name="comment"
                    className="border-2 rounded s-mobile-card"
                    // value={userData.lname}
                    onChange={handleChange}
                    placeholder="Your Comment"
                    rows={7}
                    style={{
                      width: "100%",
                      maxWidth: '900px',
                      paddingTop: "7px",
                      paddingLeft: "7px",
                      marginTop: "5px",
                    }}
                  />
                </div>
                <div className="element" style={{ paddingBottom: "20px" }}>
                  <div className="element" style={{ paddingBottom: "15px" }}>
                    <label htmlFor="lastname">
                      Suggestions for improvement of the platform (interwiu.com)
                    </label>
                    <br />
                    <textarea
                      id="lname"
                      type="text"
                      name="suggestion"
                      className="border-2 rounded s-desktop-card"
                      // value={userData.lname}
                      onChange={handleChange}
                      placeholder="Suggestions"
                      rows={4}
                      style={{
                        width: "100%",
                        maxWidth: '900px',
                        paddingTop: "7px",
                        paddingLeft: "7px",
                        marginTop: "5px",
                      }}
                    />
                    <textarea
                      id="lname"
                      type="text"
                      name="suggestion"
                      className="border-2 rounded s-mobile-card"
                      // value={userData.lname}
                      onChange={handleChange}
                      placeholder="Suggestions"
                      rows={7}
                      style={{
                        width: "100%",
                        maxWidth: '900px',
                        paddingTop: "7px",
                        paddingLeft: "7px",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                  <p
                    style={{
                      textAlign: "justify",
                      textJustify: "inter-word",
                      fontSize: "13px",
                    }}
                  >
                    By submitting this form, I am providing my consent to Fyzen
                    Career Solutions Pvt Ltd (owner of interwiu.com) to share my
                    Success Story containing my details (such as name, photo,
                    academic affiliation, mock interview(s) taken, placement
                    status, LinkedIn profile, etc) and my comment on its platform (interwiu.com)
                    or anywhere else for promotion, marketing, etc. I also agree
                    that Fyzen Career Solutions Pvt Ltd can suitably
                    restructure/modify my comment or any legitimate details for
                    good (for example, it might be required if there is a
                    grammatical or spelling error anywhere in the comment, name
                    of the company, etc)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            // type="submit"
            onClick={(e) => handleSubmit(e)}
            className="cust-btn create-btn"
          // style={{backgroundColor:'green'}}
          >
            Submit
          </button>
          {/* </form> */}
        </div>
      ) : (
        <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </>
  );
};

export default StoryForm;
