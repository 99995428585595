import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { Link, Navigate } from "react-router-dom";
import { Link as Lnk } from "react-router-dom";
import { Link } from "react-scroll";
// mui
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ReactGA from "react-ga"

// import { useNavigate } from "react-router-dom";

import "./Navbar.css";
import UserContext from "../../contexts/UserContext";

const Navbar = ({ isDisabled }) => {
  // let navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useContext(UserContext);
  const menue = [
    { id: "home", name: "Home" },
    { id: "getStarted", name: "Get Started" },
    { id: "howItWorks", name: "How It Works" },
    { id: "features", name: "Features" },
    { id: "profiles", name: "Profiles" },
    { id: "pricing", name: "Pricing" },
    { id: "testimonials", name: "Success Stories" },
    { id: "faqs", name: "FAQs" },
  ];
  console.log(user);
  const userRole =
    JSON.parse(localStorage.getItem("isAuthenticated")) ||
    JSON.parse(localStorage.getItem("isIAuthenticated"));

  // scroll down hide navbar and scroll up show navbar
  const [hide, setHide] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      // console.log('window.scrollY',window.scrollY)
      if (window.scrollY > lastScrollY && window.scrollY > 100) {
        // if scroll down hide the navbar
        setHide(true);
      } else {
        // if scroll up hide the navbar
        setHide(false);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  // ------------

  return (
    <div
      className={`navbar flex ${hide && "hidden"}`}
      style={{
        backdropFilter: "blur(20px)",
        backgroundColor: "rgba(255,255,255,1)",
      }}
    >
      <div className="logo">
        <Lnk to="/" className="alogo">
          <h1>interwiu.com</h1>
        </Lnk>
      </div>

      <ul className="nav-list flex">
        {!userRole?.role && location.pathname === "/" ? (
          <li className="nav-item">
            {/* <Link to="/" className="active">
            Home
          </Link> */}
            <Link
              to="home"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
            >
              Home
            </Link>
            <Link
              to="getStarted"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
            >
              Get Started
            </Link>
            <Link
              to="howItWorks"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation hidden-howitworks"
            >
              How it Works
            </Link>
            <Link
              to="features"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
            >
              Features
            </Link>
            <Link
              to="profiles"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
            >
              Profiles
            </Link>
            <Link
              to="pricing"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
            >
              Pricing
            </Link>
            <Link
              to="testimonials"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation hidden-success"
            >
              Success Stories
            </Link>
            <Link
              to="faqs"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
            >
              FAQs
            </Link>
          </li>
        ) : null}
      </ul>

      <div className="sidebar flex">
        {(!user.fname || !user.isDisabled) && (
          <button
            className="cust-btn login-btn"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              const loginFunc = document.querySelector(".login-container");
              loginFunc.classList.remove("hide");
              document.body.style.overflow = "hidden";
              ReactGA.event({
                category: "Log In",
                action: "Log In button",
                label: "Visitor clicked Log In button"
              })
            }}
          >
            Log In
          </button>
        )}
        <IconButton
          aria-label="more"
          className="navDropdownIcon IconButton"
          id="long-button"
          aria-controls={Boolean(anchorEl) ? "long-menu" : undefined}
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          aria-haspopup="true"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            console.log("clicked");
          }}
        >
          <MenuRoundedIcon />
        </IconButton>

        {/* -------------------MOBILE VIEW NAV BAR ------------------ */}

        <Menu
          id="long-menu"
          className="navDropdown"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {menue.map((item, index) => {
            return (

              <MenuItem
                key={index}
                className={`hide-ele-1 ${item.id == "howItWorks" ? "hide-ele-3" : ""
                  } ${item.id == "testimonials" ? "hide-ele-2" : ""} ${item.id == "faqs" ? "remove-border-bt" : ""
                  }`}
                style={{ padding: '0px' }}
                onClick={() => { setAnchorEl(null) }}
              >
                <Link
                  style={{ textDecoration: "none", color: "black", padding: '6px 16px', width: '100%', height: '100%', display: 'inline-block' }}
                  to={item.id}
                  spy={true}
                  smooth={true}
                  className="tabs hover-underline-animation"
                  onClick={() => setAnchorEl(null)}
                >
                  {item.name}
                </Link>
              </MenuItem>
            );
          })}
          {/* <MenuItem className="hide-ele-1">
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="home"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
              onClick={() => setAnchorEl(null)}
            >
              Home
            </Link>
          </MenuItem>
          <MenuItem className="hide-ele-1 hide-ele-3">
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="howItWorks"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
              onClick={() => setAnchorEl(null)}
            >
              How it Works
            </Link>
          </MenuItem>
          <MenuItem className="hide-ele-1">
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="features"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
              onClick={() => setAnchorEl(null)}
            >
              Features
            </Link>
          </MenuItem>
          <MenuItem className="hide-ele-1">
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="profiles"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
              onClick={() => setAnchorEl(null)}
            >
              Profiles
            </Link>
          </MenuItem>
          <MenuItem className="hide-ele-1">
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="pricing"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
              onClick={() => setAnchorEl(null)}
            >
              Pricing
            </Link>
          </MenuItem>
          <MenuItem className="hide-ele-1 hide-ele-2">
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="testimonials"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
              onClick={() => setAnchorEl(null)}
            >
              Success Stories
            </Link>
          </MenuItem>
          <MenuItem className="hide-ele-1 remove-border-bt">
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="faqs"
              spy={true}
              smooth={true}
              className="tabs hover-underline-animation"
              onClick={() => setAnchorEl(null)}
            >
              FAQs
            </Link>
          </MenuItem> */}
        </Menu>
        {/* -------------------MOBILE VIEW NAV BAR END ------------------ */}
      </div>
    </div>
  );
};

export default Navbar;
