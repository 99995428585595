import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import "./Share.css";

const Share = ({ url }) => {
  //   const [shareVisible, setShareVisible] = useState(false);
  return (
    <div className="hide" id="share-cont">
      <div
        className="absolute border rounded p-2 flex justify-center bg-white"
        id="share"
        style={{ width: "400px", height: "200px" }}
      >
        <div>
          <div
            className="absolute top-2 right-2 text-xl cursor-pointer hover:text-red-500"
            onClick={() => {
              console.log("clicked");
              document.getElementById("share-cont").classList.add("hide");
            }}
          >
            <i className="fa-solid fa-circle-xmark"></i>
          </div>
          <div className="flex my-4 justify-between">
            <EmailShareButton url={url}>
              <EmailIcon size={32} round={true} />
            </EmailShareButton>
            <FacebookShareButton url={url}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <FacebookMessengerShareButton url={url}>
              <FacebookMessengerIcon size={32} round={true} />
            </FacebookMessengerShareButton>
            <LinkedinShareButton url={url}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
            <TelegramShareButton url={url}>
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>
            <TwitterShareButton url={url}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton url={url}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
          </div>
          <div className="flex">
            <p
              className="border p-2 text-center"
              id="share-url"
              style={{ width: "200px", height: "40px" }}
            >
              {url}
            </p>
            <Button
              variant="outlined"
              sx={{ width: "60px", height: "40px" }}
              onClick={() => {
                var copyText = document.getElementById("share-url").innerText;
                // copyText.execCommand("copy");
                // copyText.select();
                console.log(copyText);
              }}
            >
              Copy
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
