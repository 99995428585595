import { Typography } from '@mui/material';
import './TextLoading.css'
import React from 'react';

const TextLoading = ({ text, interval, children }) => {
    return (
        <React.Fragment>
        <Typography sx={{
            display: "inline",
            animation: `blink-slow ${interval ?? 1.2}s infinite`
        }} >{ text }</Typography>
        </React.Fragment>
    );
};

export default TextLoading;
