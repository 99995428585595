import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import UpcomingInterview from "./cards/UpcomingInterview2";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
import Navbarlogedin from "./Navbarlogedin";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Modal,
  IconButton,
  Tooltip, MenuItem, Select,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./CompletedInterviews.css";
import { Link } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import ReactMarkdown from "markdown-to-jsx";
import {
  BASE,
  INTERVIEWERS_GETBYFILTERS_ENDPOINT,
} from "../../constants/endpoints";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarPicker from "@mui/lab/CalendarPicker";
import ErrorIcon from "@mui/icons-material/Error";

import {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
} from "./days";
import { COMPANY_NAME } from "../../constants/variables";
import {
  compareDate,
  compareSlots,
  compareTime,
  compareTimeForCancel,
  getCompanyLogo,
  t24HDecimalTo12HFormat,
  toastStyle,
} from "../../utility/helper";
import moment from "moment-timezone";
import { Calendar } from "react-multi-date-picker";
import toast from "react-hot-toast";
import SlotAlreadyBooked from "./popups/SlotAlreadyBooked";
import UserContext from "../../contexts/UserContext";
import { AiFillCloseSquare, AiTwotoneCalendar } from "react-icons/ai";
import { InfoOutlined } from "@mui/icons-material";
import content from "../Home/data/footerDialog";
const Container = tw.div`relative bg-white`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "fit-content",
    },
  },
};

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Issue = () => {
  const { user } = useContext(UserContext);
  const [show, setShow] = React.useState(false);
  const [date, setDate] = React.useState(new Date());
  const [hmopen, sethmOpen] = React.useState(false);
  const [checked, setChecked] = React.useState("");
  const [status, setStatus] = useState();
  const [resStatus, setResStatus] = useState();
  const [open, setOpen] = useState(false);
  const [requests, setRequests] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [slotsArray, setSlotsArray] = useState([]);
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [changingInput, setChangingInput] = useState(false)


  const [openz, setOpenz] = React.useState(false);

  const handleClickOpenz = () => {
    setOpenz(true);
  };

  const handleClosez = () => {
    setOpenz(false);
  };

  const handleChangeRadio = (event) => {
    if (event.target.value !== 5 || event.target.value !== "5") {
      setError(false);
    }
    setChecked(event.target.value);
  };
  const handleClickOpen = () => {
    sethmOpen(true);
  };
  const handleClickClose = () => {
    sethmOpen(false);
  };
  const [data, setData] = React.useState({
    textofArea: "",
  });
  const handleChangeofBox = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };
  const display = (day) => {
    const date = day.toLocaleDateString();
    day = day.getDay();
    if (day === 0) {
      return <Sunday date={date} />;
    } else if (day === 1) {
      return <Monday date={date} />;
    } else if (day === 2) {
      return <Tuesday date={date} />;
    } else if (day === 3) {
      return <Wednesday date={date} />;
    } else if (day === 4) {
      return <Thursday date={date} />;
    } else if (day === 5) {
      return <Friday date={date} />;
    } else {
      return <Saturday date={date} />;
    }
  };
  const subheading = "",
    heading = (
      <>
        <span tw="text-changed-100">Cancel a Session</span>
        <wbr />
      </>
    ),
    description =
      "Please Enter the Mock Interview ID and then proceed to cancel your mock interview",
    textOnLeft = true;
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [cards, setCards] = React.useState(null);
  const [interviewer, setInterViewer] = React.useState(null);
  const [verified, setVerified] = useState(false);

  const [inputs, setInputs] = React.useState({
    idVerify: "",
  });
  const [requestData, setRequestData] = useState({
    studentId: cards?.studId,
    interviewerId: interviewer?.id,
    requestId: "#Yet_to_be_generated",
    slot: "",
    date: moment(selectedDate).format("DD/MM/YYYY"),
    profile: "",
    subprofile: "",
    requestStatus: 0,
    paymentId: "nan",
    paymentStatus: "Not Done",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    if (requestData?.slot) setSendBtnDisabled(true);
    else setSendBtnDisabled(false);
  }, [requestData]);
  const [isSlotAlreadyBooked, setisSlotAlredyBooked] = useState(false);
  const slotBookedModalHandler = (data) => {
    setisSlotAlredyBooked(data);
  };
  const handleChange = (event) => {
    setChangingInput(true)
    const name = event.target.name;
    const value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };
  const id = interviewer?.id;
  const name =
    interviewer?.fname + (interviewer?.lname ? " " + interviewer?.lname : "");

  const designation = interviewer?.designation;
  const company = interviewer?.currCompany;
  const price = interviewer?.price || `INR ${process.env.REACT_APP_STD_45MIN}`;
  const profiles = interviewer?.profiles || [];
  const coreprofiles = interviewer?.coreprofiles || [];
  const specializations = interviewer?.specializations || [];
  const photo = interviewer?.photo;
  const logo = getCompanyLogo(interviewer?.currCompany);
  const gnrcSchd = interviewer?.GnrcSchedule ? interviewer?.GnrcSchedule : [];

  const custSchd = interviewer?.CustomSchedule
    ? interviewer?.CustomSchedule
    : [];

  const studentId = user.id;
  const displaySlots = (date) => {
    const currentDate = moment(date).format("DD/MM/YYYY");
    const currentDay = moment(date).format("ddd").toLowerCase();
    let check = false;
    if (custSchd !== 0) {
      custSchd?.map((item) => {
        if (item.date === currentDate) {
          check = true;
          let slotArray = [];
          item.sessions?.map((sess) => {
            let duration = sess.to - sess.from
              // console.log(sess.to, sess.from, duration, duration % 0.75)
              let i = sess.from 
              while (true) {
                let slot = {
                  from: i,
                  to: i + 0.75
                }
                if (i === 0 && duration % 0.75 !== 0) {
                  
                  let extra = duration - Math.floor(duration/0.75)*0.75
                  if (duration < 0.75) {
                    
                    return;
                  } else {
                    slot.from += extra
                    slot.to += extra
                    i += extra
                  }
                } else if (slot.to > 24) {
                  slot.to -= 24
                }
                slotArray.push([slot.from, slot.to]) 
                i += 0.75
                if (i >= sess.to)
                  break;
              }
          });

          setSlotsArray([...slotArray]);
        }
      });
    }
    if (!check) {
      if (gnrcSchd?.length !== 0) {
        gnrcSchd?.map((item) => {
          if (item.day === currentDay) {
            let slotArray = [];
            item.sessions?.map((sess) => {
              let duration = sess.to - sess.from
              // console.log(sess.to, sess.from, duration, duration % 0.75)
              let i = sess.from 
              while (true) {
                let slot = {
                  from: i,
                  to: i + 0.75
                }
                if (i === 0 && duration % 0.75 !== 0) {
                  
                  let extra = duration - Math.floor(duration/0.75)*0.75
                  if (duration < 0.75) {
                    
                    return;
                  } else {
                    slot.from += extra
                    slot.to += extra
                    i += extra
                  }
                } else if (slot.to > 24) {
                  slot.to -= 24
                }
                slotArray.push([slot.from, slot.to]) 
                i += 0.75
                if (i >= sess.to)
                  break;
              }
            });
            setSlotsArray([...slotArray]);
          }
        });
      }
    }
  };
  let classes = "rounded-full bg-green-400 my-1 px-2 py-1";
  const settingColor = (item) => {
    if (currReq?.length !== 0) {
      for (let i = 0; i < currReq?.length; i++) {
        if (
          currReq[i].fromTime === item &&
          currReq[i].reqStatus === "ReqAccepted"
        ) {
          classes = "rounded-full bg-yellow-400 my-1 px-2 py-1";
          return true;
        } else if (
          currReq[i].fromTime === item &&
          currReq[i].reqStatus === "ReqPaid"
        ) {
          classes = "rounded-full bg-red-400 my-1 px-2 py-1";
          return true;
        }
      }
    }
    classes = "rounded-full bg-green-400 my-1 px-2 py-1";
    return true;
  };
  useEffect(() => {
    displaySlots(selectedDate);
    setRequestData({
      ...requestData,
      date: moment(selectedDate).format("DD/MM/YYYY"),
      slot: "",
    }); // When date changes then date will be updated and slot will reset
  }, [selectedDate]);
  async function fetchData() {
    setChangingInput(false)
    setVerified(false)
    console.log(inputs.idVerify);
    if (inputs.idVerify === "") {
      window.alert("Please enter a valid Mock Interview ID");
      return;
    }
    await axios
      .get(`${BASE}/send/getRequest/${inputs.idVerify}`)
      .then((response) => {
        console.log(response.data);
        setCards(response.data);
        console.log(cards?.reschedule)
        setVerified(true);
        axios
          .get(`${BASE}/int/interviewer/${response.data.interviewerId}`)
          .then((res) => {
            setInterViewer(res.data);
          });
      })
      .catch((error) => {
        window.alert("Please enter a valid Mock Interview ID");
        setStatus("invalid");
        setResStatus("invalid");
        setVerified(false);
        console.error(error);
      });
  }
  let currDate = moment(new Date()).format("DD/MM/YYYY");

  useEffect(() => {
    const result = compareTimeForCancel(
      cards?.fromTime,
      cards?.toTime,
      cards?.date
    );
    setStatus(result);
    const result2 = compareTime(cards?.fromTime, cards?.toTime, cards?.date);
    setResStatus(result2);
    console.log(status);
    console.log(currDate, " ", cards?.date);
  }, [cards]);
  const currReq = requests.filter(
    (item) =>
      item.date?.indexOf(moment(selectedDate).format("DD/MM/YYYY")) !== -1
  );

  const handleOpen = () => {
    setOpen(true);
    console.log(interviewer?.id);
    async function fetchData() {
      axios
        .get(`${BASE}/send/getAllInterviewRequestById/${interviewer?.id}`)
        .then((response) => {
          console.log(response.data);
          setRequests(response.data);
          displaySlots(selectedDate);
        });
    }
    setOpen(true);
    console.log(open);
    fetchData();
  };
  const handleClose = () => {
    setOpen(false);
    //setRequestData({});
    setSelectedDate(new Date());
  };
  const rescheduleRequest = (requestData) => {
    console.log(data, "-----------------9979788");
    // axios
    //   .patch(`${BASE}/send/rescheduleInterview/${cards?.reqId}`, {
    //     value: checked,
    //     desc: data.textofArea,
    //     date: requestData?.date,
    //     fromTime: requestData?.slot.slice(1).split(":")[0],
    //     toTime: requestData?.slot.slice(1).split(":")[1],
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     toast.success("Reschedule Request Sent Successfully", {
    //       ...toastStyle.success,
    //     });
    //     setUpdated(true);
    //     // window.location.reload(true);
    //     setTimeout(() => {
    //       window.location.reload(true);
    //     }, 1000);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  };
  const checkInterviewerAvailability = async (requestData) => {
    console.log("requestData :-", requestData);
    let searchStr = "";
    searchStr += "?id=" + requestData?.interviewerId;
    searchStr += "&profile=" + String(requestData?.profile);
    searchStr +=
      "&time=" +
      requestData?.slot.slice(1).split(":")[0] +
      "," +
      requestData?.slot.slice(1).split(":")[1];
    searchStr += "&date=" + requestData?.date;

    console.log("Rakesh Search STring");
    console.log(searchStr);
    let isInterviewerAvailable = false;
    await axios
      .get(INTERVIEWERS_GETBYFILTERS_ENDPOINT + searchStr)
      .then((res) => {
        console.log(
          "res.data[0].id :- ",
          res.data[0].id,
          " requestData?.interviewerId :- ",
          requestData?.interviewerId,
          " res.data[0].id === requestData?.interviewerId :- ",
          res.data.id === requestData?.interviewerId
        );
        console.log("Rakesh res.data?.length:");
        console.log(res.data?.length);
        console.log("Rakesh res Data:");
        console.log(res.data);
        if (res.data?.length === 0) {
          setisSlotAlredyBooked(true);
        }
        if (res.data?.length === 1) {
          isInterviewerAvailable = true;
        }
      })
      .catch((err) => console.log(err));
    console.log("isInterviewerAvailable", isInterviewerAvailable);
    return isInterviewerAvailable;
  };
  const handleRequestData = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // Append the property if doesn't exist and if does then update
    if (value === "none") {
      setRequestData((requestData) => ({
        ...requestData,
        [name]: "",
        studentId: studentId,
        interviewerId: id,
      }));
    }
    else {
      setRequestData((requestData) => ({
        ...requestData,
        [name]: value,
        studentId: studentId,
        interviewerId: id,
        [name === "profile" ? "subprofile" : null]: null
      }));
    }
  };
  const [toolTip, setToolTip] = useState(false);
  const [datePickerMobile, setDatePickerMobile] = useState(false);
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Cancel Interview</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <Container>
        <TwoColumn style={{ padding: "10px" }}>
          <TextColumn
            textOnLeft={textOnLeft}
            style={{
              maxWidth: "1000px",
              marginLeft: "auto",
              marginRight: "auto",
              minHeight: "87vh",
              marginTop: "2vw",
            }}
          >
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading
                style={{ textAlign: "left", textShadow: "1px 1px 3px #b7b7b7" }}
              >
                {heading}
              </Heading>
              {description && (
                <Description style={{ textAlign: "left" }}>
                  {description}
                </Description>
              )}
            </TextContent>
            {/* <Grid container spacing={1}>
              <Grid item xs={4}>
              <TextField
                  id="outlined-basic"
                  label="Enter the Mock Interview ID"
                  variant="outlined"
                  type="text"
                  name="idVerify"
                  className="border-2 rounded"
                  value={inputs.idVerify}
                  onChange={handleChange}
                  placeholder="Mock Id"
                  sx={{ width: 1 }}
                />
                <Button onClick={fetchData}>Verify</Button>
              </Grid>
            </Grid> */}
            <div style={{ marginTop: "20px" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <TextField
                  id="outlined-basic"
                  label="Enter the Mock Interview ID"
                  variant="outlined"
                  type="text"
                  name="idVerify"
                  className="border-2 rounded"
                  value={inputs.idVerify}
                  onChange={handleChange}
                  placeholder="Mock Id"
                />
                <Button onClick={fetchData}
                  variant={!changingInput && verified ? "outlined" : "contained"}
                  color={!changingInput && verified ? "success" : "primary"}>
                  {!changingInput && verified ? "Verified" : "Verify"}</Button>
              </div>
            </div>
            {verified && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <h5>Details of the Mock Interview</h5>
                  {cards && (
                    <UpcomingInterview
                      name={interviewer?.fname}
                      desg={interviewer?.designation}
                      company={interviewer?.currCompany}
                      price={cards?.price}
                      profile={cards?.profile}
                      subprofile={cards?.subprofile}
                      slotStart={cards?.fromTime}
                      slotEnd={cards?.toTime}
                      status={cards?.reqStatus}
                      id={interviewer?.id}
                      date={cards?.date}
                      image={interviewer?.photo}
                      mockId={cards?.mockId}
                      logo={getCompanyLogo(interviewer?.currCompany)}
                      req_id={cards?.reqId}
                      payment={cards?.payment}
                      // rescheduled='true'
                      isCancelled={cards?.cancelled?.isCancelled}
                      isUnavailable={
                        cards?.isDisabled === true || cards?.isDelete === true
                      }
                      isDisable={cards?.isDisabled === true}
                      isDelete={cards?.isDelete === true}
                      reschedule={cards?.reschedule}
                      isRescheduled={cards?.reschedule?.isRescheduled}
                      sessDuration={cards?.sessDuration}
                      sessType={cards?.sessType}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            {verified && cards?.cancelled?.isCancelled === "true" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorIcon
                  color="error"
                  style={{ marginRight: "13px" }}
                  fontSize="large"
                />
                <p
                  className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                  style={{ color: "#d32f2f" }}
                >
                  Sorry! This mock interview has already been Cancelled.
                </p>
              </div>
            )}
            {verified &&
              status === "passed" &&
              cards?.cancelled?.isCancelled !== "true" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon
                    color="error"
                    style={{ marginRight: "13px" }}
                    fontSize="large"
                  />
                  <p
                    className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                    style={{ color: "#d32f2f" }}
                  >
                    Sorry! This mock interview cannot be cancelled because its
                    slot has already passed.
                  </p>
                </div>
              )}
            {verified &&
              status === "ongoing" &&
              cards?.cancelled?.isCancelled !== "true" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon
                    color="error"
                    style={{ marginRight: "13px" }}
                    fontSize="large"
                  />
                  <p
                    className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                    style={{ color: "#d32f2f" }}
                  >
                    Sorry! This mock interview cannot be cancelled because its
                    slot has already started.
                  </p>
                </div>
              )}
            {/* {
              verified && status === 'partial' && 
              <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                <ErrorIcon color='error' style={{marginRight:'13px'}} fontSize='large'/>
                <p className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100" style={{color:'#d32f2f'}}>
                  Partial
                </p>
              </div>
            }
            {
              verified && status === 'full' && 
              <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                <ErrorIcon color='error' style={{marginRight:'13px'}} fontSize='large'/>
                <p className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100" style={{color:'#d32f2f', fontSize:'19px'}}>
                  Full
                </p>
              </div>
            }
            {
              verified && status === 'zero' && 
              <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                <ErrorIcon color='error' style={{marginRight:'13px'}} fontSize='large'/>
                <p className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100" style={{color:'#d32f2f', fontSize:'19px'}}>
                  Zero.
                </p>
              </div>
            } */}
            {verified &&
              cards?.cancelled?.isCancelled !== "true" &&
              (status === "partial" ||
                status === "full" ||
                status === "zero") && (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend">
                      Why do you want to cancel this mock interview?
                    </FormLabel>
                    <FormGroup className="removeWordbreak">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value={1}
                          label="I got something else to do at that time"
                          onChange={handleChangeRadio}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={2}
                          label="I think I can perform well in my actual placement/internship interview without having appeared in a mock interview"
                          onChange={handleChangeRadio}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={3}
                          label="I am not interested for this profile anymore "
                          onChange={handleChangeRadio}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={4}
                          label="I want to have my mock interviewer with another interviewer"
                          onChange={handleChangeRadio}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={5}
                          label="Other Issues"
                          onChange={handleChangeRadio}
                        />
                        <TextField
                          type="text"
                          name="textofArea"
                          label="Describe your issue(s) in detail"
                          multiline
                          rows={4}
                          sx={{ mt: 2 }}
                          value={data.textofArea}
                          onChange={handleChangeofBox}
                          error={error}
                        />
                      </RadioGroup>
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
            <Modal class open={open} onClose={handleClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 24,
                  overflow: "hidden",
                  maxWidth: "750px",
                  width: "95%",
                }}
              >
                <div className="flex flex-row justify-between gap-2 bg-blue-500 p-2">
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      mx: 2,
                    }}
                    className="slot-headding"
                  >
                    Request a Mock Interview (with Feedback Session)
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CancelIcon sx={{ color: "white" }} />
                  </IconButton>
                </div>
                {/* {coreprofiles.length>0 && specializations && specializations?.map(s => s.subDomains.length===0).includes(false) ? <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "start",
                        pointerEvents: "none"
                      }}
                    >
                      <div className="tt ml-auto mr-2">
                        <InfoOutlined
                          sx={{ color: "rgb(83, 81, 81)", fontSize: "20px", pointerEvents:"auto" }}
                        />
                        <span style={{ background: 'black', color: 'white', padding: '3px 5px' }} className="tttext">
                        Choosing a specialization for a Core Profile is not mandatory
                        </span>
                      </div>
                  </div>:null} */}
                <div className="grid grid-cols-2 max-[600px]:grid-cols-1 gap-3">
                  <div className="col-span-1 m-2 flex flex-row justify-center">
                    <div>
                      <Typography
                        id="modal-modal-title"
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          my: 1,
                        }}
                        style={{ marginBottom: "20px" }}
                        className="slot-name"
                      >
                        To: {name}
                      </Typography>
                      <div className="w-full flex flex-row justify-center my-1 hide-picker">
                        <Calendar
                          minDate={new Date().setDate(new Date().getDate())}
                          zIndex={0}
                          value={selectedDate}
                          onChange={(e) => setSelectedDate(new Date(e))}
                          mapDays={({ date, selectedDate, isSameDate }) => {
                            let props = {};
                            if (isSameDate(date, selectedDate)) {
                              props.style = {
                                color: "#fff",
                                backgroundColor: "#00a86b",
                                border: "1px solid #000",
                              };
                              return props;
                            }
                          }}
                        />
                      </div>
                      {/* <MuiPickersUtilsProvider className="s-mobile-card" utils={LocalizedUtils}>
                        <DatePicker

                          style={{ flex: 1, }}
                          disablePast
                          placeholder="Select Date"
                          zIndex={0}
                          value={selectedDate}
                          showTabs={false}
                          onChange={(e) => { setSelectedDate(new Date(e)) }}
                        // onChange={(e) => setSelectedDate(new Date(e))}
                        />
                      </MuiPickersUtilsProvider> */}
                      {/* <div className="s-mobile-card" style={{ position: 'relative' }}>
                        <span style={{ fontWeight: '500', lineHeight: '30px', marginRight: '10px' }}>Select Date: </span><button class="button-13" onClick={() => setDatePickerMobile(!datePickerMobile)} role="button">{selectedDate !== ""
                          ? moment(selectedDate).format("DD MMM YYYY") + " "
                          : ""} <AiTwotoneCalendar style={{ display: 'inline-block', verticalAlign: 'text-top' }} /></button>
                        {
                          datePickerMobile && <Calendar
                            className="s-mobile-calendar-calendar"
                            minDate={new Date().setDate(new Date().getDate())}
                            zIndex={1}
                            value={selectedDate}
                            onChange={(e) => { setSelectedDate(new Date(e)); setDatePickerMobile(!datePickerMobile) }}
                            mapDays={({ date, selectedDate, isSameDate }) => {
                              let props = {};
                              if (isSameDate(date, selectedDate)) {
                                props.style = {
                                  color: "#fff",
                                  backgroundColor: "#00a86b",
                                  border: "1px solid #000"
                                };
                                return props;
                              }
                            }}
                          />
                        }
                      </div> */}
                      <div
                        className="s-mobile-card"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div className="tt ml-auto">
                          <span
                            style={{
                              fontWeight: "500",
                              lineHeight: "30px",
                              marginRight: "10px",
                            }}
                          >
                            Select Date:{" "}
                          </span>
                          <button
                            class="button-13"
                            onClick={() =>
                              setDatePickerMobile(!datePickerMobile)
                            }
                            role="button"
                          >
                            {selectedDate !== ""
                              ? moment(selectedDate).format("DD MMM YYYY") + " "
                              : ""}{" "}
                            <AiTwotoneCalendar
                              style={{
                                display: "inline-block",
                                verticalAlign: "text-top",
                              }}
                            />
                          </button>
                          <span
                            style={{
                              background: "black",
                              color: "white",
                              padding: "3px 5px",
                              top: "-146px",
                              textAlign: "left",
                            }}
                            className="calendar-mobile-tooltip tttext"
                          >
                            <Calendar
                              className="s-mobile-calendar-calendar"
                              minDate={new Date().setDate(new Date().getDate())}
                              zIndex={1}
                              value={selectedDate}
                              onChange={(e) => {
                                setSelectedDate(new Date(e));
                                setDatePickerMobile(!datePickerMobile);
                              }}
                              mapDays={({ date, selectedDate, isSameDate }) => {
                                let props = {};
                                if (isSameDate(date, selectedDate)) {
                                  props.style = {
                                    color: "#fff",
                                    backgroundColor: "#00a86b",
                                    border: "1px solid #000",
                                  };
                                  return props;
                                }
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 m-2 my-2 flex flex-row justify-center items-center">
                    <div>
                      <Typography
                        variant="p"
                        sx={{ mb: 2, fontSize: "15px" }}
                      // className="heading1"
                      >
                        <b>Profile:</b> {cards?.subprofile ? `${cards?.subprofile} (${cards?.profile})` : cards?.profile}
                      </Typography>
                      <hr className="mx-4 my-3 border-black" />
                      <div className="relative border-2 border-gray-400 rounded p-1 my-1 flex flex-wrap">
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-green-500"></div>
                          Available
                        </div>
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-orange-400"></div>
                          Conditionally Available
                        </div>
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-red-500"></div>
                          Occupied
                        </div>
                        <div className="absolute right-0 flex mr-3 text-blue-600 w-5 cursor-pointer">
                          {/* <Tooltip
                            open={toolTip}

                            disableFocusListener
                            title={
                              <>
                                <p className="my-1">
                                  Green: The slot is completely available to you
                                </p>
                                <p className="my-1">
                                  Orange: Another student has already chosen the
                                  slot, but is yet to pay and confirm. You can
                                  still request that slot, and pay first after
                                  acceptance to confirm
                                </p>
                                <p className="my-1">
                                  Red: The slot is not available to you, because
                                  another student has already confirmed the slot
                                </p>
                              </>
                            }
                          >
                            <img
                              onClick={() => setToolTip(!toolTip)}
                              src={require("../../../images/exclamation.png")}
                              alt="Description"
                              style={{ transform: "rotateZ(180deg)" }}
                            />
                          </Tooltip> */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div className="tt ml-auto">
                              <InfoOutlined
                                sx={{
                                  color: "rgb(83, 81, 81)",
                                  fontSize: "20px",
                                }}
                              />
                              <span
                                style={{
                                  background: "black",
                                  color: "white",
                                  padding: "3px 5px",
                                  top: "-146px",
                                  textAlign: "left",
                                }}
                                className="tttext"
                              >
                                <p className="my-1 mb-[0.5rem]">
                                  <span
                                    style={{
                                      color: "lime",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Green:{" "}
                                  </span>
                                  The slot is completely available to you
                                </p>
                                <p className="my-1 mb-[0.5rem]">
                                  <span
                                    style={{
                                      color: "orange",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Orange:{" "}
                                  </span>
                                  Another student has already chosen the slot,
                                  but is yet to pay and confirm. You can still
                                  request that slot, and pay first after
                                  acceptance to confirm
                                </p>
                                <p className="my-1">
                                  <span
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    Red:{" "}
                                  </span>
                                  The slot is not available to you, because
                                  another student has already confirmed the slot
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Typography
                        variant="h5"
                        component="h5"
                        className="heading1"
                      >
                        Choose a Slot
                      </Typography>
                      <RadioGroup
                        row
                        name="slot"
                        id="slot"
                        value={requestData.slot || ""}
                        onChange={handleRequestData}
                        sx={{ maxHeight: "150px", overflowX: "auto" }}
                      >
                        {slotsArray?.length !== 0 ? (
                          slotsArray.map(
                            (item) =>
                              (compareSlots(item[0]) ||
                                !compareDate(selectedDate, new Date())) &&
                              settingColor(item[0]) === true && (
                                <div className={classes}>
                                  <FormControlLabel
                                    value={"#" + item[0] + ":" + item[1]}
                                    key={"#" + item[0] + ":" + item[1]}
                                    control={<Radio sx={{ py: 0 }} />}
                                    label={
                                      t24HDecimalTo12HFormat(item[0]) +
                                      " - " +
                                      t24HDecimalTo12HFormat(item[1])
                                    }
                                    disabled={classes?.includes("red")}
                                  />
                                </div>
                              )
                          )
                        ) : (
                          <p className="text-center">
                            No slots available on{" "}
                            <span className="font-bold">
                              {moment(selectedDate).format("Do MMM, YYYY")}
                            </span>
                          </p>
                        )}
                      </RadioGroup>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full flex flex-row justify-center my-2"
                  style={{
                    background: " #ffdede",
                    margin: "0px",
                    padding: "8px",
                  }}
                >
                  <Button
                    color="success"
                    variant="contained"
                    disabled={!sendBtnDisabled}
                    onClick={() => {
                      console.log(
                        "date: ",
                        moment(selectedDate).format("DD/MM/YYYY")
                      );
                      console.log(
                        "requestData Before sending to checkInterviewerAvailability(): ",
                        requestData
                      );
                      console.log("Sending Request...");
                      checkInterviewerAvailability(requestData).then(
                        (isInterviewerAvailable) => {
                          console.log("here 🎄",isInterviewerAvailable)
                          if (isInterviewerAvailable)
                            rescheduleRequest(requestData);
                        }
                      );
                      handleClose();
                    }}
                  >
                    Send Request
                  </Button>
                </div>
              </Box>
            </Modal>
            {/* <Modal open={open} onClose={handleClose}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 24,
                  overflow: "hidden",
                  maxWidth: "750px",
                  width: "95%",
                }}
              >
                <div className="flex flex-row justify-between gap-2 bg-blue-500 p-2">
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "white",
                      mx: 2,
                    }}
                    className="slot-headding"
                  >
                    Request a Mock Interview (with Feedback Session)
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CancelIcon sx={{ color: "white" }} />
                  </IconButton>
                </div>
                <div className="grid grid-cols-2 max-[600px]:grid-cols-1 gap-3">
                  <div className="col-span-1 m-2 flex flex-row justify-center">
                    <div>
                      <Typography
                        id="modal-modal-title"
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          my: 1,
                        }}
                      >
                        To: {interviewer?.fname}
                      </Typography>
                      <div className="w-full flex flex-row justify-center my-1 hide-picker">
                        <Calendar
                          minDate={new Date().setDate(new Date().getDate())}
                          zIndex={0}
                          value={selectedDate}
                          onChange={(e) => setSelectedDate(new Date(e))}
                          mapDays={({ date, selectedDate, isSameDate }) => {
                            let props = {};
                            if (isSameDate(date, selectedDate)) {
                              props.style = {
                                color: "#fff",
                                backgroundColor: "#00a86b",
                                border: "1px solid #000",
                              };
                              return props;
                            }
                          }}
                        />
                      </div>
                      
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div className="tt ml-auto">
                          <span
                            style={{
                              fontWeight: "500",
                              lineHeight: "30px",
                              marginRight: "10px",
                            }}
                          >
                            Select Date:{" "}
                          </span>
                          <button
                            class="button-13"
                            onClick={() =>
                              setDatePickerMobile(!datePickerMobile)
                            }
                            role="button"
                          >
                            {selectedDate !== ""
                              ? moment(selectedDate).format("DD MMM YYYY") + " "
                              : ""}{" "}
                            <AiTwotoneCalendar
                              style={{
                                display: "inline-block",
                                verticalAlign: "text-top",
                              }}
                            />
                          </button>
                          <span
                            style={{
                              background: "black",
                              color: "white",
                              padding: "3px 5px",
                              top: "-146px",
                              textAlign: "left",
                            }}
                            className="calendar-mobile-tooltip tttext"
                          >
                            <Calendar
                              className="s-mobile-calendar-calendar"
                              minDate={new Date().setDate(new Date().getDate())}
                              zIndex={1}
                              value={selectedDate}
                              onChange={(e) => {
                                setSelectedDate(new Date(e));
                                setDatePickerMobile(!datePickerMobile);
                              }}
                              mapDays={({ date, selectedDate, isSameDate }) => {
                                let props = {};
                                if (isSameDate(date, selectedDate)) {
                                  props.style = {
                                    color: "#fff",
                                    backgroundColor: "#00a86b",
                                    border: "1px solid #000",
                                  };
                                  return props;
                                }
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 m-2 my-2 flex flex-row justify-center items-center">
                    <div>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{ mb: "2px" }}
                        className="heading1"
                      >
                        Choose a Profile
                      </Typography>
                      <RadioGroup
                        id="profile"
                        name="profile"
                        row
                        value={requestData?.profile || ""}
                        onChange={handleRequestData}
                        sx={{
                          maxHeight: "100px",
                          overflowX: "auto",
                        }}
                      >
                        {profiles.map((profileItem) => (
                          <FormControlLabel
                            value={profileItem}
                            control={<Radio sx={{ py: "2px" }} />}
                            label={profileItem}
                            className="normalText"
                          />
                        ))}
                      </RadioGroup>
                      <hr className="mx-4 my-3 border-black" />
                      <div className="relative border-2 border-gray-400 rounded p-1 my-1 flex flex-wrap">
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-green-500"></div>
                          Available
                        </div>
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-orange-400"></div>
                          Conditionally Available
                        </div>
                        <div className="flex mr-3">
                          <div className="inline-block rounded-full w-4 h-4 mx-1 bg-red-500"></div>
                          Occupied
                        </div>
                        <div className="absolute right-0 flex mr-3 text-blue-600 w-5 cursor-pointer">
                          
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div className="tt ml-auto">
                              <InfoOutlined
                                sx={{
                                  color: "rgb(83, 81, 81)",
                                  fontSize: "20px",
                                }}
                              />
                              <span
                                style={{
                                  background: "black",
                                  color: "white",
                                  padding: "3px 5px",
                                  top: "-146px",
                                  textAlign: "left",
                                }}
                                className="tttext"
                              >
                                <p className="my-1 mb-[0.5rem]">
                                  <span
                                    style={{
                                      color: "lime",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Green:{" "}
                                  </span>
                                  The slot is completely available to you
                                </p>
                                <p className="my-1 mb-[0.5rem]">
                                  <span
                                    style={{
                                      color: "orange",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Orange:{" "}
                                  </span>
                                  Another student has already chosen the slot,
                                  but is yet to pay and confirm. You can still
                                  request that slot, and pay first after
                                  acceptance to confirm
                                </p>
                                <p className="my-1">
                                  <span
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    Red:{" "}
                                  </span>
                                  The slot is not available to you, because
                                  another student has already confirmed the slot
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Typography
                        variant="h5"
                        component="h5"
                        className="heading1"
                      >
                        Choose a Slot
                      </Typography>
                      <RadioGroup
                        row
                        name="slot"
                        id="slot"
                        value={requestData?.slot || ""}
                        onChange={handleRequestData}
                        sx={{ maxHeight: "150px", overflowX: "auto" }}
                      >
                        {slotsArray?.length !== 0 ? (
                          slotsArray.map(
                            (item) =>
                              (compareSlots(item[0]) ||
                                !compareDate(selectedDate, new Date())) &&
                              settingColor(item[0]) === true && (
                                <div className={classes}>
                                  <FormControlLabel
                                    value={"#" + item[0] + ":" + item[1]}
                                    key={"#" + item[0] + ":" + item[1]}
                                    control={<Radio sx={{ py: 0 }} />}
                                    label={
                                      t24HDecimalTo12HFormat(item[0]) +
                                      " - " +
                                      t24HDecimalTo12HFormat(item[1])
                                    }
                                    disabled={classes.includes("red")}
                                  />
                                </div>
                              )
                          )
                        ) : (
                          <p className="text-center">
                            No slots available on{" "}
                            <span className="font-bold">
                              {moment(selectedDate).format("Do MMM, YYYY")}
                            </span>
                          </p>
                        )}
                      </RadioGroup>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full flex flex-row justify-center my-2"
                  style={{
                    background: " #ffdede",
                    margin: "0px",
                    padding: "8px",
                  }}
                >
                  <Button
                    color="success"
                    variant="contained"
                    disabled={!sendBtnDisabled}
                    onClick={() => {
                      console.log("Sending Request...");
                      checkInterviewerAvailability(requestData).then(
                        (isInterviewerAvailable) => {
                          if (isInterviewerAvailable)
                            rescheduleRequest(requestData);
                        }
                      );
                      handleClose();
                    }}
                  >
                    Send Request for Rescheduling
                  </Button>
                </div>
              </Box>
            </Modal> */}
            {isSlotAlreadyBooked ? (
              <SlotAlreadyBooked
                isSlotAlreadyBooked={isSlotAlreadyBooked}
                slotBookedModalHandler={slotBookedModalHandler}
              />
            ) : null}
            {verified && cards?.cancelled?.isCancelled !== "true" && (
              <Grid item sx={{ mt: 2, mb: 2 }}>
                {resStatus === "valid" && cards?.reschedule?.count !== 1 && (
                  <Typography>
                    If the current interview slot does not suit you well, you
                    can better{" "}
                    <a
                      onClick={() => {
                        handleOpen();
                      }}
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        "&:hover": { cursor: "pointer" },
                      }}
                      variant="contained"
                      href="#"
                    >
                      {"Reschedule this Mock Interview"}
                    </a>{" "}
                    &nbsp; rather than cancelling it
                  </Typography>
                )}
                <div className="">
                  {resStatus === "valid" && cards?.reschedule?.count !== 1 && (
                    <>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          console.log(checked);
                          console.log(data.textofArea);
                          if (checked === "") {
                            window.alert("Please select a reason for cancelling");
                            window.scrollTo(0, 0);
                            return;
                          }
                          if (checked === 5 || checked === "5") {
                            console.log("null1");
                            if (
                              data.textofArea === "" ||
                              !data.textofArea ||
                              data.textofArea === null
                            ) {
                              console.log("null");
                              setError(true);
                              return;
                            } else {
                              setError(false);
                            }
                          }
                          handleClickOpen();
                        }}
                        sx={{ mt: 2, fontWeight: "300" }}
                      >
                        Cancel it anyways
                      </Button>
                      <Typography variant="h6" sx={{
                        mt: 1,
                        fontSize: "12px"
                      }}>On cancelling this mock interview, you will receive a Coupon Code
                        which you can use to get a Flat Discount on your next mock interview booking. Read our <a target="_blank" style={{ textDecoration: "underline", color: "blue" }} href="http://localhost:3000/cancellation-policy">Cancellation Policy</a>
                        &nbsp; for more details.
                      </Typography>
                      {/* <div>
                        <Button variant="outlined" onClick={handleClickOpenz}>
                          Open alert dialog
                        </Button>
                        <Dialog
                          open={openz}
                          onClose={handleClosez}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Use Google's location service?"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              <ReactMarkdown className="markdown">
                                {content["Cancellation and Refund Policy"]}
                              </ReactMarkdown>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClosez}>Close</Button>
                          </DialogActions>
                        </Dialog>
                      </div> */}
                    </>
                  )}
                  {(resStatus === "invalid" ||
                    (resStatus === "valid" &&
                      cards?.reschedule?.count === 1)) && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          console.log(checked);
                          console.log(data.textofArea);
                          if (checked === "") {
                            window.alert("Please select a reason for cancelling");
                            window.scrollTo(0, 0);
                            return;
                          }
                          if (checked === 5 || checked === "5") {
                            console.log("null1");
                            if (
                              data.textofArea === "" ||
                              !data.textofArea ||
                              data.textofArea === null
                            ) {
                              console.log("null");
                              setError(true);
                              return;
                            } else {
                              setError(false);
                            }
                          }
                          handleClickOpen();
                        }}
                        sx={{ mt: 2, fontWeight: "300" }}
                      >
                        Cancel the Mock Interview
                      </Button>
                    )}
                  <div>
                    <Dialog
                      open={hmopen}
                      onClose={handleClickClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Cancel this Mock Interview"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure that you want to cancel this mock
                          interview?
                        </DialogContentText>
                        {/* {verified && status === "full" && (
                          <DialogContentText id="alert-dialog-description">
                            You will get a complete refund after this
                            cancellation. Read Cancellation and Refund policy
                            for more details
                          </DialogContentText>
                        )}
                        {verified && status === "partial" && (
                          <DialogContentText id="alert-dialog-description">
                            You will get only a 50% refund after this
                            cancellation. Read Cancellation and Refund policy
                            for more details
                          </DialogContentText>
                        )}
                        {verified && status === "zero" && (
                          <DialogContentText id="alert-dialog-description">
                            You will get no refund after this cancellation. Read
                            Cancellation and Refund policy for more detail
                          </DialogContentText>
                        )} */}
                      </DialogContent>
                      <DialogActions>
                        <Button
                          // sx={{ mt: 2 }}
                          color="error"
                          variant="contained"
                          onClick={() => {
                            const obj = {
                              canceled: true,
                              value: checked,
                              desc: data.textofArea,
                              cancelledBy: "Stud",
                            };
                            axios
                              .patch(
                                `${BASE}/send/cancelMockInterview/${inputs.idVerify}`,
                                obj
                              )
                              .then((response) => {
                                console.log(response.status);
                                console.log(response.data);
                                toast.success(
                                  "Mock Interview Cancelled Successfully",
                                  {
                                    ...toastStyle.success,
                                    position: "top-center",
                                  }
                                );
                                setTimeout(() => {
                                  window.location.reload(true);
                                }, 1000);
                              })
                              .catch((error) => {
                                window.alert(
                                  "Please enter a valid Mock Interview Id"
                                );
                                window.scrollTo(0, 0);
                              });
                          }}
                        >
                          YES
                        </Button>
                        <Button variant="contained" onClick={handleClickClose}>
                          NO
                        </Button>
                      </DialogActions>
                      <div style={{ position: "absolute", top: 0, right: 0 }}>
                        <AiFillCloseSquare
                          onClick={handleClickClose}
                          style={{
                            margin: "0rem",
                            color: "#d32f2f",
                            fontSize: "2rem",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Dialog>
                  </div>
                </div>
              </Grid>
            )}
          </TextColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
export default Issue;
