import React from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
import TeamIllustrationSrc from "./../../images/profiles.jpg";
import { ReactComponent as SvgDotPattern } from "./../../images/dot-pattern.svg";
import "./TwoColumnWithInput.css";
import { special } from "constants/branchSpecilization.js";
const Container = tw.div`relative bg-white`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-5 pb-20 md:pt-10 md:pb-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-3 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Professional = () => {
  const subheading = "",
    heading = <>Profiles offered</>,
    description =
      "Mock Interviews and Mentorship Sessions with Resume Review, Live Feedback, detailed Feedback Report, and Action Plan for Improvement in the most preferred, trendy, new generation, and high-paying profiles",
    imageSrc = TeamIllustrationSrc,
    imageRounded = true,
    imageBorder = false,
    imageShadow = false,
    imageCss = null,
    imageDecoratorBlob = false,
    imageDecoratorBlobCss = null,
    textOnLeft = true;

  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn style={{ paddingTop: "50px", paddingBottom: "0px" }}>
        <TextColumn>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description className="heading1">{description}</Description>
          </TextContent>
        </TextColumn>
        <ImageColumn>
          <Image
            css={imageCss}
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
      </TwoColumn>
      <TwoColumn
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <TextColumn
          textOnLeft={textOnLeft}
          style={{
            transform:
              window.innerWidth > 768 ? "translate(20px, -10px)" : "none",
          }}
        >
          <TextContent>
            <div className="uvp-list " style={{}}>
              <Subheading
                style={{
                  marginTop: "10px",
                  color: "dodgerblue",
                }}
              >
                Non-Core Profiles
              </Subheading>
              <ul>
                <li className="listFont ">Data Science</li>
                <li className="listFont ">Software</li>
                <li className="listFont ">Banking and Finance</li>
                <li className="listFont ">Consulting</li>
                <li className="listFont ">Analytics</li>
                <li className="listFont ">Product Management</li>
                <li className="listFont ">Operations</li>
                <li className="listFont ">Supply Chain</li>
                <li className="listFont ">FMCG</li>
                <li className="listFont ">Operations Research</li>
                <li className="listFont ">Sales</li>
                <li className="listFont ">Inventory Management</li>
                <li className="listFont ">Logistics</li>
              </ul>
            </div>
          </TextContent>
        </TextColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <div className="uvp-list">
              <Subheading
                style={{
                  marginTop: "",
                  color: "teal",
                }}
              >
                Core Profiles
              </Subheading>
              <ul className="ml-24 md:ml-auto">
                {Object.keys(special).map((coreprofile, i) => (
                  <li key={i + coreprofile.length} className="listFont">
                    {coreprofile}
                  </li>
                ))}
              </ul>
            </div>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
export default Professional;
