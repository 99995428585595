// Home
export const HOME_ROUTE = "/";
export const SUCCESS_STORIES_ROUTE = "/testimonials";
export const CONTACT_US_ROUTE = "/contact";
export const ABOUT_US_ROUTE = "/about-us";
export const PRICING_ROUTE = "/pricing";
export const HOW_IT_WORKS_ROUTE = "/how-it-works";
export const HOW_IT_WORKS_STUDENTS_ROUTE = "/s/how-it-works";
export const HOW_IT_WORKS_PROFESSIONALS_ROUTE = "/i/how-it-works";
export const HOW_IT_WORKS_ADMINS_ROUTE = "/admin/how-it-works";
export const PROFILES_ROUTE = "/profiles";
export const FAQS_STUDENTS_ROUTE = "/s/faqs";
export const FAQS_PROFESSIONAL_ROUTE = "/i/faqs";
export const FAQS_ADMIN_ROUTE = "/admin/faqs";
export const TERMS_AND_CONDITIONS_ROUTE = "/terms-and-conditions";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
// Student
export const STUDENT_FIND_AN_INTERVIEWER_ROUTE = "/s/find-an-interviewer";
export const STUDENT_DEACTIVATE_ROUTE = "/s/deactivated";
export const STUDENT_SENT_REQUESTS_ROUTE = "/s/sent-requests";
export const STUDENT_ACCEPTED_REQUESTS_ROUTE = "/s/accepted-requests";
export const STUDENT_DECLINED_REQUESTS_ROUTE = "/s/declined-requests";
export const STUDENT_CANCELED_INTERVIEW_ROUTE = "/s/canceled-interview";
export const STUDENT_PRECANCELLED_REQUESTS_ROUTE = "/s/precancelled-requests";
export const STUDENT_UPCOMING_INTERVIEWS_ROUTE = "/s/upcoming-interviews";
export const STUDENT_COMPLETED_INTERVIEWS_ROUTE = "/s/completed-interviews";
export const STUDENT_DASHBOARD_ROUTE = "/s/dashboard";
export const STUDENT_REPORT_ROUTE = "/s/report";
export const SUCCESS_ADD_STORIES_ROUTE = "/s/add/success-story";
export const STUDENT_RESCHEDULE_ROUTE = "/s/reschedule";
export const STUDENT_PROFILE_ROUTE = "/s/profile";
export const STUDENT_PROFILE_FORM_ROUTE = "/s/profile-form";
export const STUDENT_FAQS_ROUTE = "/s/faqs";
export const STUDENT_HOW_IT_WORKS_ROUTE = "/s/how-it-works";
export const STUDENT_CANCEL_ROUTE = "/s/cancel";
export const STUDENT_WELCOME_ROUTE = "/s/welcome";
export const STUDENT_ACCOUNT_SETTINGS_ROUTE = "/s/account";
export const PLAN_ROUTE = "/plans";
export const REFER_ROUTE = "/refer";
// Interviewer
export const INTERVIEWER_RECIEVED_REQUESTS_ROUTE = "/i/recieved-requests";
export const INTERVIEWER_ACCEPTED_REQUESTS_ROUTE = "/i/accepted-requests";
export const INTERVIEWER_DECLINED_REQUESTS_ROUTE = "/i/declined-requests";
export const INTERVIEWER_UPCOMING_INTERVIEWS_ROUTE = "/i/upcoming-interviews";
export const INTERVIEWER_CANCELED_INTERVIEWS_ROUTE = "/i/canceled-interviews";
export const INTERVIEWER_COMPLETED_INTERVIEWS_ROUTE = "/i/completed-interviews";
export const INTERVIEWER_PROFILE_FORM_ROUTE = "/i/profile-form";
export const INTERVIEWER_MY_PROFILE_ROUTE = "/i/myprofile";
export const INTERVIEWER_PROFILE_FORM_OLD_ROUTE = "/i/profile-form-old";
export const INTERVIEWER_DASHBOARD_ROUTE = "/i/dashboard";
export const INTERVIEWER_TIMEPICKER_ROUTE = "/i/timepicker";
export const INTERVIEWER_PROFILE_ROUTE = "/i/profile";
export const INTERVIEWER_FAQS_ROUTE = "/i/faqs";
export const INTERVIEWER_HOW_IT_WORKS_ROUTE = "/i/how-it-works";
export const INTERVIEWER_REPORT_ROUTE = "/i/report";
export const INTERVIEWER_CANCEL_ROUTE = "/i/cancel";
export const INTERVIEWER_WELCOME_ROUTE = "/i/welcome";
export const INTERVIEWER_ACCOUNT_SETTINGS_ROUTE = "/i/account";
export const INTERVIEWER_DEACTIVATE_ROUTE = "/i/deactivated";
//Admin
export const ADMIN_ADD_STUDENTS_ROUTE = "/admin/add-students";
export const ADMIN_PROFILE_FORM_ROUTE = "/admin/profile-form";
export const ADMIN_CURRENT_STUDENTS_ROUTE = "/admin/current-students";
export const ADMIN_INTERVIEWERS_ROUTE = "/admin/interviewers";
export const ADMIN_MOCK_INTERVIEWS_ROUTE = "/admin/mock-interviews";
export const ADMIN_FEEDBACK_COMMENTS_ROUTE = "/admin/feedback-comments";
export const ADMIN_DASHBOARD_ROUTE = "/admin/dashboard";
export const ADMIN_CANCELLED_ROUTE = "/admin/cancelled-interviews";
export const ADMIN_DECLINED_ROUTE = "/admin/declined-interviews";
export const ADMIN_AUTOREJECTED_ROUTE = "/admin/autorejected-interviews";
export const ADMIN_REPORT_ROUTE = "/admin/report-issue";
export const ADMIN_SUCCESS_ROUTE = "/admin/success-stories";
export const ADMIN_ALlSTORIES_ROUTE = "/admin/all-stories";
export const ADMIN_FAQS_ROUTE = "/admin/faqs";