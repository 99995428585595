import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { BASE } from "../../../constants/endpoints";
import axios from "axios";
import Divider from "@mui/material/Divider";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Badge from "@mui/material/Badge";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import UserContext from "../../../contexts/UserContext";

import "./Navbarlogedin.css";
import Bookmark from "../../Dashboard/popups/Bookmark";
import {
    HOME_ROUTE,
    STUDENT_ACCEPTED_REQUESTS_ROUTE,
    STUDENT_COMPLETED_INTERVIEWS_ROUTE,
    STUDENT_DASHBOARD_ROUTE,
    STUDENT_FIND_AN_INTERVIEWER_ROUTE,
    STUDENT_SENT_REQUESTS_ROUTE,
    STUDENT_UPCOMING_INTERVIEWS_ROUTE,
    STUDENT_DECLINED_REQUESTS_ROUTE,
    STUDENT_CANCELED_INTERVIEW_ROUTE,
    STUDENT_PRECANCELLED_REQUESTS_ROUTE,
    STUDENT_RESCHEDULE_ROUTE,
    STUDENT_REPORT_ROUTE,
    STUDENT_CANCEL_ROUTE,
    STUDENT_PROFILE_FORM_ROUTE,
    SUCCESS_STORIES_ROUTE,
    SUCCESS_ADD_STORIES_ROUTE,
    STUDENT_ACCOUNT_SETTINGS_ROUTE,
    PLAN_ROUTE,
    REFER_ROUTE,
} from "../../../constants/routes";
import toast from "react-hot-toast";
import { toastStyle } from "../../../utility/helper";
import { useLocation } from 'react-router-dom';
const menuOptions = ["Sign-ups", "Session Bookings", "My Coupons", "Leaderboard"]

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};


const NavbarCALogged = () => {
    const location = useLocation();
    // console.log(location.pathname.split("/").pop())
    let navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const [isOpen, setIsOpen] = useState(false);

    // Profile Dropdown
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open = Boolean(anchorEl);
    const [anchorNt, setAnchorNt] = useState(null);
    const openNt = Boolean(anchorNt);
    const [tag, setTag] = useState({ accepted: false, completed: false });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNtClick = (event) => {
        setAnchorNt(event.currentTarget);
    };

    const handleNtClose = () => {
        setAnchorNt(null);
    };

    const handlePopupOpen = () => {
        setIsOpen(true);
    };

    const handlePopupClose = () => {
        setIsOpen(false);
    };

    const loggingOut = () => {
        if (localStorage.getItem("isAuthenticated"))
            localStorage.removeItem("isAuthenticated");
        setUser({});
        navigate(HOME_ROUTE);
    };

    const params1 = {
        reqStatus: "ReqAccepted",
        userRole: "Student",
    };
    const params2 = {
        reqStatus: "ReqPaid",
        userRole: "Student",
        completed: "Completed",
    };
    useEffect(() => {
        async function getRequests() {
            let x = { accepted: false, completed: false };
            await axios
                .get(`${BASE}/send/getInterviewRequestById/${user.id}`, {
                    params: params1,
                })
                .then((response) => {
                    const requests = response.data.Requests;

                    let ints = response.data.Requests;
                    let count = 0;
                    ints?.map((int) => {
                        axios
                            .get(
                                `${BASE}/send/student/acceptedInterviewRequestById/${int.reqId}`
                            )
                            .then((response) => {
                                // console.log(response.data, "--------denied");
                                if (response.data) {
                                    count = +1;
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });

                    // console.log(count, requests.length, "-----accepted-----");

                    if (count !== requests.length) {
                        x["accepted"] = true;
                    } else {
                        x["accepted"] = false;
                    }
                });


            await axios
                .get(`${BASE}/send/getInterviewRequestById/${user.id}`, {
                    params: params2,
                })
                .then((response) => {
                    const requests = response.data.Requests;

                    let ints = response.data.Requests;
                    let count = 0;

                    ints?.map((int) => {
                        axios
                            .get(
                                `${BASE}/send/student/completedInterviewRequestById/${int.reqId}`
                            )
                            .then((response) => {
                                if (response.data.viewedAccepted) {
                                    count++;
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });

                    if (count !== requests.length) {
                        x["completed"] = true;
                    } else {
                        x["completed"] = false;
                    }
                });

            setTag(x);
        }

        getRequests();
    }, [user]);

    useEffect(() => {
        console.log(user);
    }, [user]);

    // useEffect(() => {
    //   // window.location.reload(false);
    //   console.log("reloading...");
    // }, [navigate]);
    const changeRoute = () => {
        toast.success(`Hello`, {
            ...toastStyle.success,
        });
        return STUDENT_PROFILE_FORM_ROUTE;
    };

    // hide navbar on scroll down

    const [hide, setHide] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    const controlNavbar = () => {
        if (typeof window !== "undefined") {
            // console.log('window.scrollY',window.scrollY)
            if (window.scrollY > lastScrollY && window.scrollY > 100) {
                // if scroll down hide the navbar
                setHide(true);
            } else {
                // if scroll up hide the navbar
                setHide(false);
            }

            // remember current page location to use in the next move
            setLastScrollY(window.scrollY);
        }
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener("scroll", controlNavbar);
            };
        }
    }, [lastScrollY]);

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isOpen}
                onClose={handlePopupClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className="flex justify-center h-full">
                    <Bookmark />
                </div>
            </Modal>
            <div className={`navbarlogedin flex ${hide && "hidden-login-s"}`}>
                <div className="logo">
                    <Link to={HOME_ROUTE} target="_blank">
                        <h1 className="text-2xl font-medium">interwiu.com</h1>
                    </Link>
                </div>
                <>
                    {user.mobNumber !== "" ? (
                        <ul className="nav-list flex">
                            <li>
                                <NavLink
                                    end
                                    className="nav-item hover-underline-animation"
                                    activeClassName="active"
                                    to={`/programs/ca/sign-ups`}
                                >
                                    <div>{menuOptions[0]}</div>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    end
                                    className="nav-item hover-underline-animation"
                                    activeClassName="active"
                                    to={`/programs/ca/sessions`}
                                >
                                    <div>{menuOptions[1]}</div>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    end
                                    className="nav-item hover-underline-animation"
                                    activeClassName="active"
                                    to={"/programs/ca/coupons"}
                                >
                                    <div className="flex">
                                        {menuOptions[2]}
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    end
                                    className="nav-item hover-underline-animation"
                                    activeClassName="active"
                                    to={`/programs/ca/leaderboard`}
                                >
                                    <div>{menuOptions[3]}</div>
                                </NavLink>
                            </li>

                        </ul>
                    ) : (
                        <ul className="nav-list flex">
                            <li>
                                <NavLink
                                    end
                                    className="nav-item hover-underline-animation"
                                    activeClassName="active"
                                    onClick={() => {
                                        toast.loading(
                                            `Please fill your profile details to continue`,
                                            {
                                                ...toastStyle.loading,
                                                duration: 3500,
                                            }
                                        );
                                    }}
                                    to={`/programs/ca/sign-ups`}
                                >
                                    <div>{menuOptions[0]}</div>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    end
                                    className="nav-item hover-underline-animation"
                                    activeClassName="active"
                                    onClick={() => {
                                        toast.loading(
                                            `Please fill your profile details to continue`,
                                            {
                                                ...toastStyle.loading,
                                                duration: 3500,
                                            }
                                        );
                                    }}
                                    to={`/programs/ca/sessions`}
                                >
                                    <div>{menuOptions[1]}</div>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    end
                                    className="nav-item hover-underline-animation"
                                    activeClassName="active"
                                    onClick={() => {
                                        toast.loading(
                                            `Please fill your profile details to continue`,
                                            {
                                                ...toastStyle.loading,
                                                duration: 3500,
                                            }
                                        );
                                    }}
                                    to={"/programs/ca/coupons"}
                                >
                                    <div className="flex">
                                        {menuOptions[2]}
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    end
                                    className="nav-item hover-underline-animation"
                                    activeClassName="active"
                                    onClick={() => {
                                        toast.loading(
                                            `Please fill your profile details to continue`,
                                            {
                                                ...toastStyle.loading,
                                                duration: 3500,
                                            }
                                        );
                                    }}
                                    to={"/programs/ca/leaderboard"}
                                >
                                    <div className="flex">
                                        {menuOptions[3]}
                                    </div>
                                </NavLink>
                            </li>

                        </ul>
                    )}
                    <div className="sidebar flex">
                        {/* Notification Bell */}
                        {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Notification">
                <Button
                  onClick={handleNtClick}
                  size="small"
                  sx={{ color: "black" }}
                  aria-controls={openNt ? "notification" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openNt ? "true" : undefined}
                >
                  <div
                    className="notifier rounded w-full flex justify-center border hover:bg-slate-200"
                    style={{ height: "50px" }}
                  >
                    <Badge badgeContent={4} color="primary">
                      <NotificationsIcon color="action" />
                    </Badge>
                  </div>
                </Button>
              </Tooltip>
            </Box> */}
                        <Menu
                            anchorEl={anchorNt}
                            id="Notification"
                            open={openNt}
                            onClose={handleNtClose}
                            onClick={handleNtClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1,
                                    px: 1,
                                    width: 300,
                                    borderRadius: 2,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&:before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform: "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                            <List
                                sx={{
                                    width: "100%",
                                    maxWidth: 360,
                                    bgcolor: "background.paper",
                                }}
                                subheader={
                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        sx={{
                                            fontWeight: "bold",
                                            marginLeft: "12px",
                                        }}
                                    >
                                        Notifications
                                    </Typography>
                                }
                            >
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ImageIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="John Doe"
                                        secondary="You're meeting is accepted by John Doe"
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WorkIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Work" secondary="Jan 7, 2014" />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BeachAccessIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Vacation" secondary="July 20, 2014" />
                                </ListItem>
                            </List>
                        </Menu>

                        {/* Profile Dropdown */}


                        <IconButton
                            aria-label="more"
                            className="navLoggedInDropdownIcon"
                            id="long-button"
                            aria-controls={Boolean(anchorEl2) ? "long-menu" : undefined}
                            aria-expanded={Boolean(anchorEl2) ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => setAnchorEl2(e.currentTarget)}
                        >
                            <MenuRoundedIcon className="here" />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            className="navLoggedInDropdown"
                            MenuListProps={{
                                "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl2}
                            open={Boolean(anchorEl2)}
                            onClose={() => setAnchorEl2(null)}
                        >
                            <Link onClick={() => { setAnchorEl2(null) }} to={`/programs/ca/sign-ups`}>
                                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'sign-ups' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'sign-ups' ? 'black' : ''}` }}>
                                    <p>{menuOptions[0]}</p>
                                </MenuItem>
                            </Link>
                            <Link onClick={() => { setAnchorEl2(null) }} to={`/programs/ca/sessions`}>
                                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'sessions' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'sessions' ? 'black' : ''}` }}>
                                    <p>{menuOptions[1]}</p>
                                </MenuItem>
                            </Link>
                            <Link onClick={() => { setAnchorEl2(null) }} to={"/programs/ca/coupons"}>
                                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'coupons' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'coupons' ? 'black' : ''}` }}>
                                    <p className="flex">
                                        {menuOptions[2]}
                                    </p>
                                </MenuItem>
                            </Link>
                            <Link onClick={() => { setAnchorEl2(null) }} to={"/programs/ca/leaderboard"}>
                                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'leaderboard' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'leaderboard' ? 'black' : ''}` }}>
                                    <p className="flex">
                                        {menuOptions[3]}
                                    </p>
                                </MenuItem>
                            </Link>

                        </Menu>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                            }}

                        >
                            <h1 className="text-md font-medium caID-nav">ID : <strong>{user.caId}</strong></h1>
                        </Box>
                    </div>

                </>

            </div>
        </>
    );
};

export default React.memo(NavbarCALogged);




// NavbarCALogged
// ├── Modal
// │   └── Bookmark
// ├── div.navbarlogedin
// │   ├── div.logo
// │   │   └── Link
// │   │       └── h1
// │   ├── ul.nav-list
// │   │   ├── li
// │   │   │   └── NavLink
// │   │   ├── li
// │   │   │   └── NavLink
// │   │   ├── li
// │   │   │   └── NavLink
// │   │   ├── li
// │   │   │   └── NavLink
// │   │   └── li
// │   │       └── NavLink
// │   └── div.sidebar
// │       ├── Box
// │       │   └── Tooltip
// │       │       └── Button
// │       │           └── div.sidebar-profile
// │       │               ├── div.sidebar-profile-img
// │       │               │   └── img
// │       │               └── div
// │       │                   ├── p
// │       │                   └── i
// │       ├── Menu (Profile dropdown)
// │       │   ├── Typography
// │       │   │   └── div.profile-dropdown-head
// │       │   ├── button.cust-btn.view-profile-btn
// │       │   ├── Divider
// │       │   ├── MenuItem (multiple)
// │       │   └── form
// │       │       └── button.cust-btn.logout-btn
// │       ├── IconButton
// │       │   └── MenuRoundedIcon
// │       └── Menu (NavLoggedInDropdown)
// │           └── Link (multiple)
// │               └── MenuItem
// └── ul.nav-list (commented out)