import React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="main-page">
      <div className="p-4 bg-white contact-us-main-box">
        <div>
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", textAlign: "center", color: "#fd1c6c" }}
            gutterBottom
            className="page-heading-h1"
          >
            About Us
          </Typography>
        </div>
        <div className="contact-us-img-box">
          <img src={require("../../images/about-us-hero.jpg")} alt="hero" />
        </div>
        <p
          className="normalText"
          style={{
            fontFamily: '"Roboto","Helvetica","Arial"',
            textAlign: "justify",
            letterSpacing: "0.5px",
          }}
        >
          interwiu.com is an Online Mock Interview and Mentorship platform developed by Fyzen Career Solutions Pvt Ltd, which connects the students (as interviewees/mentees) with the professionals from top MNCs (as interviewers/mentors) using a filter-based smart match-making algorithm, to facilitate the Mock Interviews and Mentorship Sessions with integral Feedback. The interviewers and
          mentors serving at the platform are the experienced professionals from
          various big MNCs like Microsoft, Amazon, Google, Goldman Sachs,
          McKinsey & Company, IBM, Flipkart, JPMorgan Chase & Co., American
          Express, Meta, Citi, HSBC, Morgan Stanley, Boston Consulting Group
          (BCG), Bain & Company, PwC, Netflix, Directi, IQVIA, Kotak Mahindra
          Bank, BNY Mellon, Hexagon Capability Center India Pvt Ltd, Accenture,
          Deloitte, etc.
        </p>
        <br />
        <p
          className="normalText"
          style={{
            fontFamily: '"Roboto","Helvetica","Arial"',
            textAlign: "justify",
            letterSpacing: "0.5px",
          }}
        >
          The mock interviews mimic the real placement/internship interviews
          extensively. Via mock interviews, the students learn and practise the
          exhaustive and exclusive approaches required to drive their interviews
          their way. Thus it makes the students confident enough to face and
          sail through the real placement/internship interviews. The
          personalized feedback session following a mock interview helps a
          student understand and rectify the mistakes, realize what went
          right/wrong, prepare better, improve the performance in subsequent
          mock interviews, and thus ultimately enhance the odds of cracking the
          real placement/internship interviews in a highly competitive
          environment.
        </p>
      </div>
      <div className="bg-green-200 py-2 contact-us-main-box-2">
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", textAlign: "center", color: "#fd1c6c" }}
          gutterBottom
        >
          Our Team
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: "justify", mx: "auto" }}
          gutterBottom
          className="normalText"
          style={{ maxWidth: "750px" }}
        >
          The team of Fyzen Career Solutions Pvt Ltd is composed of young and
          skilled enthusiasts, who are deeply passionate about entrepreneurship.
        </Typography>
        
        {/*
        <div className="ourteam-cards" style={{ maxWidth: "800px" }}>
          <div className="w-60 h-60 rounded-md overflow-hidden">
            <img
              src={require("../../images/utsav.png")}
              className="object-cover h-full w-full"
              alt="Utsav Bhardwaj"
            />
          </div>
          <div className="team-details mx-auto">
            <div className="namePost">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", mb: 0 }}
                gutterBottom
              >
                Utsav Bhardwaj{" "}
                <a
                  href="https://www.linkedin.com/in/utsav-bhardwaj-a2131b58/ "
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton aria-label="LinkedIn" size="large">
                    <LinkedInIcon fontSize="inherit" />
                  </IconButton>
                </a>
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "rgb(116,44,255)", fontWeight: "bold" }}
                gutterBottom
              >
                CEO
              </Typography>
            </div>
            <Typography variant="body2" gutterBottom>
              He is an alumnus of IIT Madras, and presently a Joint Doctoral
              Student at The University of Queensland, Australia and IIT Delhi.
              Earlier, he has worked at Eaton India Innovation Center, Pune; and
              IIT Madras Incubation Cell, Chennai.
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold", mb: 0 }}  ><span style={{ color: "rgb(116,44,255)" }}>Contact</span> : utsav.fyzencareer@gmail.com</Typography>
          </div>
        </div>
        */}

        <div
          className="ourteam-cards ourteam-cards-reverse"
          style={{ maxWidth: "800px" }}
        >
          <div className="team-details mx-auto">
            <div className="namePost">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", mb: 0 }}
                gutterBottom
              >
                Rakesk Patil{" "}
                <a
                  href="https://www.linkedin.com/in/rarapatil"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton aria-label="LinkedIn" size="large">
                    <LinkedInIcon fontSize="inherit" />
                  </IconButton>
                </a>
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "rgb(116,44,255)", fontWeight: "bold" }}
                gutterBottom
              >
                CTO
              </Typography>
            </div>
            <Typography variant="body2" gutterBottom>
              He is a research scholar at IIT Madras, and has worked earlier as
              a Software Engineer at Intel, Bangalore; and a Graphics Engineer
              at Nvidia, Bangalore.
            </Typography>
          </div>
          <div className="w-60 h-60 rounded-md overflow-hidden">
            <img
              src={require("../../images/rakesh.png")}
              className="object-cover h-full w-full"
              alt="Utsav Bhardwaj"
            />
          </div>
        </div>
        <div className="ourteam-cards" style={{ maxWidth: "800px" }}>
          <div className="w-60 h-60 rounded-md overflow-hidden">
            <img
              src={require("../../images/photo.png")}
              className="object-cover h-full w-full"
              alt="Utsav Bhardwaj"
            />
          </div>
          <div className="team-details mx-auto">
            <div className="namePost">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", mb: 0 }}
                gutterBottom
              >
                Aqueel Ahmed Vallanchira{" "}
                <a
                  href="https://linkedin.com/in/aqueel-ahmed-vallanchira"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton aria-label="LinkedIn" size="large">
                    <LinkedInIcon fontSize="inherit" />
                  </IconButton>
                </a>
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "rgb(116,44,255)", fontWeight: "bold" }}
                gutterBottom
              >
                Full-Stack Developer
              </Typography>
            </div>
            <Typography variant="body2" gutterBottom>
              Undergraduate Student, IIT Kharagpur
            </Typography>
          </div>
        </div>
        <div
          className="ourteam-cards ourteam-cards-reverse"
          style={{ maxWidth: "800px" }}
        >
          <div className="team-details mx-auto">
            <div className="namePost">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", mb: 0 }}
                gutterBottom
              >
                Aman Pahal{" "}
                <a
                  href="https://www.linkedin.com/in/aman-pahal-a816b6223/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton aria-label="LinkedIn" size="large">
                    <LinkedInIcon fontSize="inherit" />
                  </IconButton>
                </a>
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "rgb(116,44,255)", fontWeight: "bold" }}
                gutterBottom
              >
                Full-Stack Developer
              </Typography>
            </div>
            <Typography variant="body2" gutterBottom>
              Undergraduate Student, IIT Kanpur
            </Typography>
          </div>
          <div className="w-60 h-60 rounded-md overflow-hidden">
            <img
              src={require("../../images/photo.png")}
              className="object-cover h-full w-full"
              alt="Utsav Bhardwaj"
            />
          </div>
        </div>
        <div className="ourteam-cards" style={{ maxWidth: "800px" }}>
          <div className="w-60 h-60 rounded-md overflow-hidden">
            <img
              src={"https://media.licdn.com/dms/image/D4D03AQHjIDlFjBaGSg/profile-displayphoto-shrink_800_800/0/1688391041969?e=1698883200&v=beta&t=YVtprFbFUqxoYe6379RXPp6SS--1k09J6OvkwKDhf28"}
              className="object-cover h-full w-full"
              alt="Ashutosh Agrawal"
            />
          </div>
          <div className="team-details mx-auto">
            <div className="namePost">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", mb: 0 }}
                gutterBottom
              >
                Ashutosh Agrawal{" "}
                <a
                  href="https://www.linkedin.com/in/ashutosh-agrawal-b9235a230/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton aria-label="LinkedIn" size="large">
                    <LinkedInIcon fontSize="inherit" />
                  </IconButton>
                </a>
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "rgb(116,44,255)", fontWeight: "bold" }}
                gutterBottom
              >
                Full-Stack Developer
              </Typography>
            </div>
            <Typography variant="body2" gutterBottom>
              Undergraduate Student, IIT Kanpur
            </Typography>
          </div>
        </div>
        <div
          className="ourteam-cards ourteam-cards-reverse"
          style={{ maxWidth: "800px" }}
        >
          <div className="team-details mx-auto">
            <div className="namePost">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", mb: 0 }}
                gutterBottom
              >
                Mahak Mehta{" "}
                <a
                  href="https://www.linkedin.com/in/mahak-mehta-506611256"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton aria-label="LinkedIn" size="large">
                    <LinkedInIcon fontSize="inherit" />
                  </IconButton>
                </a>
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "rgb(116,44,255)", fontWeight: "bold" }}
                gutterBottom
              >
                PR Manager
              </Typography>
            </div>
            <Typography variant="body2" gutterBottom>
              Undergraduate Student, IIT Kanpur
            </Typography>
          </div>
          <div className="w-60 h-60 rounded-md overflow-hidden">
            <img
              src={"https://media.licdn.com/dms/image/D4D03AQFHuEM5hn00rg/profile-displayphoto-shrink_800_800/0/1686370724629?e=1698883200&v=beta&t=XivYPJXE5UFPvUmv6T89Qbt2vKRf80O1S4Qdba_x4sw"}
              className="object-cover h-full w-full"
              alt="Mahak Mehta"
            />
          </div>
        </div>

        {/* <div className="ourteam-cards" style={{ maxWidth: "800px" }}>
          <div className="w-60 h-60 rounded-md overflow-hidden">
            <img
              src={require("../../images/vaibhav.png")}
              className="object-cover h-full w-full"
              alt="Utsav Bhardwaj"
            />
          </div>
          <div className="team-details mx-auto">
            <div className="namePost">
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", mb: 0 }}
                gutterBottom
              >
                Mr. Vaibhav Goyal{" "}
                <a
                  href="https://www.linkedin.com/in/vaibhavg/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton aria-label="LinkedIn" size="large">
                    <LinkedInIcon fontSize="inherit" />
                  </IconButton>
                </a>
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "rgb(116,44,255)", fontWeight: "bold" }}
                gutterBottom
              >
                Mentor
              </Typography>
            </div>
            <Typography variant="body2" gutterBottom>
              He is an alumnus of IIT Madras and SP Jain Institute of Management
              & Research. He holds a sound professional experience of around 19
              years, distributed across various technological roles at firms
              like Nokia, DigiVive, Hungama, Jio, Shemaroo Entertainment Ltd.,
              etc.
            </Typography>
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};
export default AboutUs;
