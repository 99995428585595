import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import UserContext from '../../../contexts/UserContext';
import './LeaderBoard.css';

function LeaderBoard() {
    const { user } = useContext(UserContext);
    const [leaderboard, setLeaderboard] = useState([]);
    const [myRank, setMyRank] = useState(null);

    const getLeaderboard = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/programs/ca/get-leaderboard`);
            console.log("Received leaderboard ==>", response);
            setLeaderboard(response.data.leaderboard);
            const myRankData = response.data.leaderboard.find(ca => ca.caId === user.caId);
            setMyRank(myRankData ? myRankData.rank : null);
        } catch (error) {
            console.log("Error in getting leaderboard ==>", error);
        }
    };

    useEffect(() => {
        getLeaderboard();
    }, [user]);

    return (
        <div className="leaderboard-container">
            <h2 className="leaderboard-header">LeaderBoard</h2>
            {myRank && (
                <div className="my-rank-box">
                    My Rank: <span className="rank-number">{myRank}</span>
                </div>
            )}
            <div className="leaderboard-list">
                {leaderboard.map((ca, index) => (
                    <div
                        key={index}
                        className={`leaderboard-item ${ca.rank === myRank ? 'my-rank-row' : ''}`}
                    >
                        <div className="rank">{ca.rank}</div>
                        <div className="ca-info">
                            <div className="name">{ca.name}</div>
                            <div className="institute">{ca.institute}</div>
                        </div>
                    </div>
                ))}
            </div>
            {myRank === 1 ? (
                <div className="congratulations-box">
                    <p className="congratulations-message">
                        🎉 Congratulations! You're at the top of the leaderboard! 🏆
                    </p>
                    <p className="encouragement">
                        Keep up the excellent work and maintain your position as the leader!
                    </p>
                </div>
            ) : (
                <div className="formula-box">
                    <p className="formula">Score = (0.3 * Number of Sign-ups) + (0.7 * Number of Bookings)</p>
                    <p className="note">
                        Increase the number of sign-ups and more importantly the number of session bookings using your referral code <strong className="code">{user.caId}</strong> to improve your Rank further.
                    </p>
                </div>
            )}
        </div>
    );
}

export default LeaderBoard;