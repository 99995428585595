import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Chip from "@mui/material/Chip";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// import Searchable from "react-searchable-dropdown";
import { Helmet } from "react-helmet";
import axios from "axios";
import Navbarlogedin from "../Navbarlogedin";
import { BASE } from "../../../constants/endpoints";
import UserContext from "../../../contexts/UserContext";
import "../../Dashboard/Dashboard.css";
import { COMPANY_NAME } from "../../../constants/variables";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  ADMIN_INTERVIEWERS_ROUTE,
  STUDENT_PROFILE_FORM_ROUTE,
} from "../../../constants/routes.js";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { downloadPDF, isIIT, programValues } from "../../../utility/helper";
import LZString from "lz-string";
import { Oval } from "react-loader-spinner";
import { faCheck, faGlobe, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ButtonBase, CircularProgress, Divider, MenuItem, Select } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-hot-toast";
import { LinkedIn } from "@mui/icons-material";
import "./dashboard.css"
const Dashboard = () => {
  const [list, setList] = useState([
    "Data Science",
    "Banking and Finance",
    "Consulting",
  ]);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  let navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [profiledata, setProfileData] = useState("");
  const [loaded, setLoaded] = useState(true);
  // Profile Completion Percentage
  const [percent, setPercent] = useState(10);
  const [verified, setVerified] = useState([]);
  const [check, setcheck] = useState([]);
  const [statsLoading, setStatsLoading] = useState(false);
  const [statsLoadingPart2, setStatsLoadingPart2] = useState(false);

  const [showOtherAdmins, setShowOtherAdmins] = useState(false);

  const [otherAdmin, setOtherAdmin] = useState({
    tTitle: "",
    tEmail: "",
    tWhatsapp: "",
    tFname: "",
    tLname: "",
    tEmail: "",
    tPos: "",
    tPrimaryAdmin: user.id,
  });

  const [addAdmin, setAdmin] = useState(false);

  const [stats, setStats] = useState({
    added: 0,
    completed: 0,
    upcoming: 0,
    registered: 0,
    feedback: { q1: <i>To be updated</i>, q2: <i>To be updated</i>, q3: <i>To be updated</i>, q4: <i>To be updated</i> },
  });

  useEffect(() => {
    let x = [];
    setStatsLoading(true);
    axios
      .get(`${BASE}/send/admin/added/${user.id}`)
      .then((res) => {
        x.push(res.data.length);
        axios
          .get(`${BASE}/send/admin/registered/${user.id}`)
          .then((response) => {
            x.push(response.data.ans.length);
            axios
              .get(`${BASE}/send/admin/getCompletedInterviews/${user.id}`)
              .then((res) => {
                x.push(res.data.requests.length);
                axios
                  .get(`${BASE}/send/admin/getUpcomingInterviews/${user.id}`)
                  .then((res) => {
                    x.push(res.data.requests.length);
                    setStats({
                      added: x[0],
                      registered: x[1],
                      completed: x[2],
                      upcoming: x[3],
                      feedback: { q1: <i>To be updated</i>, q2: <i>To be updated</i>, q3: <i>To be updated</i>, q4: <i>To be updated</i> }
                    });
                    setStatsLoading(false);
                    setStatsLoadingPart2(true);
                    axios
                      .get(`${BASE}/send/admin/getFeedback/${user.id}`)
                      .then((response) => {
                        let y = [0, 0, 0, 0];
                        if (response?.data?.intReviews.length) {
                          for (
                            let i = 0;
                            i < response.data?.intReviews.length;
                            i++
                          ) {
                            console.log(
                              response.data.intReviews[i].scores,
                              "-------------"
                            );
                            y[0] =
                              y[0] +
                              parseInt(response.data.intReviews[i].scores.q1);
                            y[1] =
                              y[1] +
                              parseInt(response.data.intReviews[i].scores.q2);
                            y[2] =
                              y[2] +
                              parseInt(response.data.intReviews[i].scores.q3);
                            y[3] =
                              y[3] +
                              parseInt(response.data.intReviews[i].scores.q4);
                          }
                          y[0] =
                            Math.round(
                              (y[0] / response.data?.intReviews.length) * 100
                            ) / 100;
                          y[1] =
                            Math.round(
                              (y[1] / response.data?.intReviews.length) * 100
                            ) / 100;
                          y[2] =
                            Math.round(
                              (y[2] / response.data?.intReviews.length) * 100
                            ) / 100;
                          y[3] =
                            Math.round(
                              (y[3] / response.data?.intReviews.length) * 100
                            ) / 100;
                          setStats({
                            added: x[0],
                            registered: x[1],
                            completed: x[2],
                            upcoming: x[3],
                            feedback: { q1: y[0], q2: y[1], q3: y[2], q4: y[3] },
                          });
                          setStatsLoadingPart2(false)
                        }
                        else{
                          setStatsLoadingPart2(false)
                        }
                      }).catch((err) => {
                        console.log(err);
                      });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  useEffect(() => {
    if (user.teamMates !== {}) {
      user.teamMates?.forEach((element, index) => {
        axios
          .get(`${BASE}/admin/get/adminTeammates/${element.tEmail}`)
          .then((res) => {
            console.log(res, "-21-3-0123-0123-", index);
            setcheck([]);
            setcheck((prev) => {
              prev[index] = res.data;
              return prev;
            });
            setVerified((prev) => {
              prev[index] = res.data;
              return prev;
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!otherAdmin.tTitle) {
      const box = document.getElementById("ttitle");
      box.style.border = "2px solid red";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else {
      const box = document.getElementById("ttitle");
      box.style.border = "2px solid #E5E7EB";
    }
    if (!otherAdmin.tFname) {
      const box = document.getElementById("tfname");
      box.style.border = "2px solid red";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else {
      const box = document.getElementById("tfname");
      box.style.border = "2px solid #E5E7EB";
    }

    if (!otherAdmin.tEmail) {
      const box = document.getElementById("temail");
      box.style.border = "2px solid red";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else if (otherAdmin.tEmail.includes("@gmail.com")) {
      if (user.teamMates?.find((o) => o.tEmail === otherAdmin.tEmail)) {
        alert("Email already exists for the Admin");
        const box = document.getElementById("temail");
        box.style.border = "2px solid red";
        window.scrollTo(0, box.offsetTop);
        return box.focus();
      } else {
        const box = document.getElementById("temail");
        box.style.border = "2px solid #E5E7EB";
      }
    } else {
      alert("Enter a valid Gmail Email Address");
      const box = document.getElementById("temail");
      box.style.border = "2px solid red";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    }

    if (!otherAdmin.tWhatsapp || otherAdmin.tWhatsapp.length < 10) {
      const box = document.getElementById("twhatsapp");
      box.style.border = "2px solid red";
      window.scrollTo(0, box.offsetTop);
      return box.focus();
    } else {
      const box = document.getElementById("twhatsapp");
      box.style.border = "2px solid #E5E7EB";
    }

    console.log(user);
    console.log(otherAdmin);
    if(otherAdmin.tEmail === user.pemail){
      alert("You can not add yourself");
      document.querySelector("#temail").style.borderColor = "#FF0000";
      return document.querySelector("#temail").focus();
    }
    axios
      .post(`${BASE}/send/admin/add/adminTeammate/${user.id}`, otherAdmin)
      .then((res) => {
        const foundAdmin = res?.data?.foundAdmin
        if (foundAdmin) {
          alert("This admin is already registered under an institute");
          document.querySelector(".foundAdminMessage").innerHTML = foundAdmin + " is already registered as an admin at interwiu.com. Please try with a different Gmail address of this person";
          return document.querySelector(".foundAdminMessage").style.display = "";
        }
        else {
          toast.success("Admin Added Successfully");
          setAdmin(!addAdmin);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | My Profile</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <div className="interviews">
        <div className="w-full p-2 my-2 border-2 rounded flex justify-center">
          <Typography
            variant="h4"
            component="h4"
            sx={{
              // textAlign: "center",
              display: "inline",
              color: "#1265a8",
              fontWeight: "bold",
              margin: "0 auto",
            }}
          >
            My Profile
          </Typography>
        </div>
        {loaded ? (
          <div className="grid mx-2 profilechange max-[700px]:mx-0">
            <div className="m-2 overflow-hidden max-[700px]:mx-0">
              <div className="border rounded mb-2">
                <div className="h-32 opacity-80 s-mobile-overflow-h" style={{ position: 'relative' }}>
                  <img src={require("../../../images/cover2.webp")} alt="Cover" />
                  <div className="s-mobile-card" style={{ position: 'absolute', bottom: '-50px', left: '0', right: '0', justifyContent: 'center' }}>
                    <div className="" style={{ width: '43%', position: 'realtive' }}>
                      <img
                        src={user.iLogo}
                        style={{ width: '100%', height: '100%' }}
                        className="h-full w-full object-cover rounded-full p-1 border bg-white"
                        alt="Profile"
                      />

                    </div>
                  </div>

                </div>
                <div className="relative grid py-5 s-desktop-card" id="profile-bio">
                  <div className="relative">
                    <div className="absolute w-40 h-40 -top-16 left-3">
                      <img
                        src={user.iLogo}
                        style={{ width: '100%', height: '100%' }}
                        className="h-full w-full object-cover rounded-full p-1 border bg-white"
                        alt="Profile"
                      />
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="h4"
                      component="h4"
                      sx={{
                        color: "#e84c3d",
                        fontWeight: "bold",
                      }}
                    >
                      {user.iname}
                    </Typography>
                    <p>
                      <i class="fa fa-envelope" aria-hidden="true"></i> &nbsp;{" "}
                      {user.iemail}
                    </p>
                    <p>
                      <i class="fa fa-phone" aria-hidden="true"></i> &nbsp;{" "}
                      {user.imobNumber}
                    </p>
                    <Box
                      sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, marginTop: "10px" }}
                    >
                      {user.isAdmin === false &&
                        user.ibranches?.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      {user.isAdmin === true &&
                        user.ibranches?.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                          />
                        ))}
                    </Box>
                    <p className="text-gray-500 leading-5"></p>
                    <p className="text-gray-500 leading-5"></p>
                  </div>
                  <div
                    className="relative"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="text-2xl my-1 pb-1 flex gap-2">
                      {user.iLinkedIn ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-linkedin"
                            onClick={() => {
                              window.open(
                                user.iLinkedIn ? user.iLinkedIn : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.iWebsite ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa fa-globe"
                            onClick={() => {
                              window.open(
                                user.iWebsite ? user.iWebsite : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="text-center mr-2 border rounded-md p-1 mx-auto text-green-600 cursor-pointer hover:text-blue-600 hover:bg-gray-100"
                      onClick={() => {
                        navigate("/admin/profile-form");
                      }}
                    >
                      <p>
                        <i className="fa-solid fa-pen-to-square"></i> Edit
                        Profile
                      </p>
                    </div>
                  </div>
                </div>



                <div className="s-mobile-card" style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', marginTop: '17%', padding: '10px', postion: 'relative' }} id="profile-bio">
                  <div>
                    <Typography
                      variant="h4"
                      component="h4"
                      sx={{
                        color: "#e84c3d",
                        fontWeight: "bold",
                      }}
                    >
                      {user.iname}
                    </Typography>
                    <p style={{ marginTop: '25px' }}>
                      <i class="fa fa-envelope" aria-hidden="true"></i> &nbsp;{" "}
                      {user.iemail}
                    </p>
                    <p style={{ marginBottom: '25px' }}>
                      <i class="fa fa-phone" aria-hidden="true"></i> &nbsp;{" "}
                      {user.imobNumber}
                    </p>
                    <Box
                      sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, marginTop: "10px" }}
                    >
                      {user.isAdmin === false &&
                        user.ibranches?.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      {user.isAdmin === true &&
                        user.ibranches?.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                          />
                        ))}
                    </Box>
                    <p className="text-gray-500 leading-5"></p>
                    <p className="text-gray-500 leading-5"></p>
                  </div>
                  <div
                    className="relative"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: '100%',
                      marginTop: '10px',
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="text-2xl my-1 pb-1 flex gap-2">
                      {user.iLinkedIn ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-linkedin"
                            onClick={() => {
                              window.open(
                                user.iLinkedIn ? user.iLinkedIn : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.iWebsite ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa fa-globe"
                            onClick={() => {
                              window.open(
                                user.iWebsite ? user.iWebsite : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="text-center mr-2 border rounded-md p-1 mx-auto text-green-600 cursor-pointer hover:text-blue-600 hover:bg-gray-100"
                      onClick={() => {
                        navigate("/admin/profile-form");
                      }}
                    >
                      <p>
                        <i className="fa-solid fa-pen-to-square"></i> Edit
                        Profile
                      </p>
                    </div>
                  </div>
                </div>
              </div>


              <div style={{ flexDirection: 'column' }} className="text-[12px] my-3 p-2 border rounded-md text-left s-mobile-card">
                <Typography
                  variant="h5"
                  component="h5"
                  align="center"
                  style={{
                    margin: "10px",
                    fontFamily: "'Ubuntu', sans-serif",
                  }}
                  className="s-mobile-profile-subHeading"
                >
                  Statistics
                </Typography>
                <l className="s-mobile-text-14" style={{ position: 'relative' }}>
                  {
                    statsLoading && <div style={{ position: 'absolute', top: 0, left: 0, right: 0, backgroundColor: 'rgba(255,255,255,0.75)', zIndex: 1, height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <CircularProgress />
                    </div>
                  }
                  <li className="mt-[10px]">
                    Total Number of Students added = {stats.added}
                  </li>
                  <li className="mt-[10px]">
                    Number of Students Registered = {stats.registered}
                  </li>
                  <li className="mt-[10px]">
                    Number of Completed Mock Interviews = {stats.completed}
                  </li>
                  <li className="mt-[10px]">
                    Number of Upcoming Mock Interviews = {stats.upcoming}
                  </li>
                  <li className="mt-[10px]" style={{ position: 'relative' }}>
                    {
                      statsLoadingPart2 && <div style={{ position: 'absolute', top: 0, left: 0, right: 0, backgroundColor: 'rgba(255,255,255,0.75)', zIndex: 1, height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                      </div>
                    }
                    Average Score of Students as per the <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Feedback provided by
                    Interviewers
                    <ul className="ml-[20px]">
                      <li className="mt-[10px]">
                        i. Knowledge, skillset, and experience in the desired
                        roles = {stats?.feedback?.q1}
                      </li>
                      <li className="mt-[10px]">
                        ii. Approach and ability to solve a problem ={" "}
                        {stats?.feedback?.q2}
                      </li>
                      <li className="mt-[10px]">
                        iii. Way of Introduction = {stats?.feedback?.q3}
                      </li>
                      <li className="mt-[10px]">
                        iv. Body language and communication skills ={" "}
                        {stats?.feedback?.q4}
                      </li>
                    </ul>
                  </li>
                </l>
              </div>

              <div className="rounded border p-3 my-2">
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "26px",
                    paddingBottom: "8px",
                    paddingTop: "12px",
                  }}
                  className="s-mobile-profile-subHeading"
                >
                  My Details
                </Typography>
                <div>
                  <p className="py-1">
                    <span className="s-mobile-text-14" style={{ fontWeight: "bold" }}>Name</span> :{" "}
                    {user.aTitle}
                    {". "}
                    {user.aFname} {user.aLname}
                  </p>
                  <p style={{ wordBreak: 'break-all' }} className="py-1">
                    <span className="s-mobile-text-14" style={{ fontWeight: "bold" }}>Email</span> :{" "}
                    {user.aEmail}
                  </p>
                  <p className="py-1">
                    <span className="s-mobile-text-14" style={{ fontWeight: "bold" }}>Mobile No.</span> :{" "}
                    {user.amobNumber}
                  </p>
                  <p className="py-1">
                    <span className="s-mobile-text-14" style={{ fontWeight: "bold" }}>
                      Position/Designation
                    </span>{" "}
                    : {user.aPos}
                  </p>
                </div>
                <Divider style={{ margin: "20px 0" }} />
                <div className="">
                  {user.teamMates?.length > 0 &&
                    (<Button
                    variant="outlined"
                    color={!showOtherAdmins ? "primary" : "secondary"}
                    onClick={() => {
                      setShowOtherAdmins(!showOtherAdmins);
                    }}
                  >
                    {!showOtherAdmins
                      ? "View Other Admins"
                      : "Hide Other Admins"}
                  </Button>)}
                  <div className="mt-[10px] flex">
                    {addAdmin && (
                      <Button
                        variant="contained"
                        color="success"
                        className=""
                        style={{ marginRight: "10px" }}
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Save Admin
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color={`${addAdmin ? "error" : "primary"}`}
                      onClick={() => {
                        setAdmin(!addAdmin);
                        setOtherAdmin({
                          tTitle: "",
                          tEmail: "",
                          tWhatsapp: "",
                          tFname: "",
                          tLname: "",
                          tEmail: "",
                          tPos: "",
                          tPrimaryAdmin: user.id,
                        });
                      }}
                    >
                      {!addAdmin ? "Add Another Admin" : "Close"}
                    </Button>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className="tooltip">
                          The admins whom you add here,<br /> can add further admins from their end
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        className="ml-[10px] rounded-full bg-white text-gray-700  w-[20px] border-solid border-[2px] p-1"
                        icon={faInfo}
                      />
                    </OverlayTrigger>
                  </div>
                </div>

                {showOtherAdmins &&
                  user.teamMates &&
                  user.teamMates.length !== 0 && (
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "26px",
                      }}
                      className="pt-4 pb-0 flex justify-between s-mobile-flex-dir-col"
                    >
                      <p className="s-mobile-profile-subHeading">Other Admins</p>
                    </Typography>
                  )}

                {/* Add other admin popup */}
                {/* Add other admin popup */}

                {addAdmin && (
                  <div>
                    <div className="relative rounded border p-2 my-5 border-black">
                      <div className="name cust-row flex max-[600px]:flex-col" style={{ alignItems: 'flex-start', columnGap : "10px" }}>
                        <div className="element" style={{ width: "auto" }}>
                          <label className="max-[600px]:w-[100%] max-[600px]:inline-block" htmlFor="title">
                            Title<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            name="tTitle"
                            id="ttitle"
                            autoWidth
                            className="h-[56px] max-[600px]:h-[40px] max-[600px]:w-[100px]"
                            sx={{ padding: "0px" }}
                            onChange={(e) => {
                              setOtherAdmin({
                                ...otherAdmin,
                                tTitle: e.target.value,
                              });
                            }}
                            required
                          >
                            <MenuItem value="Mr">Mr.</MenuItem>
                            <MenuItem value="Ms">Ms.</MenuItem>
                            <MenuItem value="Prof">Prof.</MenuItem>
                            <MenuItem value="Dr">Dr.</MenuItem>
                          </Select>
                        </div>
                        <div className="element">
                          <label htmlFor="tFname">
                            First Name of Admin
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="tfname"
                            type="text"
                            name="tFname"
                            className="border-2 rounded w-full h-[56px] max-[600px]:h-[40px] px-4"
                            onChange={(e) => {
                              setOtherAdmin({
                                ...otherAdmin,
                                tFname: e.target.value,
                              });
                            }}
                            required
                            placeholder="First Name"
                          />
                        </div>
                        <div className="element">
                          <label htmlFor="tLname">Last Name of Admin</label>
                          <input
                            id="tlname"
                            type="text"
                            name="tLname"
                            className="border-2 rounded w-full h-[56px] max-[600px]:h-[40px] px-4"
                            placeholder="Last Name"
                            onChange={(e) => {
                              setOtherAdmin({
                                ...otherAdmin,
                                tLname: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="email cust-row flex max-[600px]:flex-col">
                        <div
                          className="element flex w-full flex-col"
                          style={{ alignItems: "flex-start" }}
                        >
                          <label htmlFor="tPos ">
                            Position/Designation of Admin
                          </label>
                          <input
                            type="text"
                            name="tPos"
                            id="tpos"
                            className="border-2 rounded w-full h-[56px] max-[600px]:h-[40px] px-4"
                            placeholder="Position/Designation"
                            onChange={(e) => {
                              setOtherAdmin({
                                ...otherAdmin,
                                tPos: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="email cust-row flex max-[600px]:flex-col" style={{columnGap : "10px"}}>
                        <div className="element">
                          <label htmlFor="tEmail">
                            Gmail address of Admin
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="email"
                            id="temail"
                            name="tEmail"
                            className="border-2 rounded temail w-full h-[56px] max-[600px]:h-[40px] px-4"
                            required
                            placeholder="Email"
                            onChange={(e) => {
                              setOtherAdmin({
                                ...otherAdmin,
                                tEmail: e.target.value,
                              });
                              document.querySelector(".foundAdminMessage").style.display = "none"
                            }}
                          />
                        </div>
                        <div className="element">
                          <label htmlFor="tWhatsapp">
                            WhatsApp Number of Admin
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <div className="border-2 rounded" id="twhatsapp">
                            <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="IN"
                              name="tWhatsapp"
                              className="twhatsapp h-[56px] max-[600px]:h-[40px] px-4"
                              placeholder="WhatsApp No."
                              required
                              onChange={(e) => {
                                setOtherAdmin({ ...otherAdmin, tWhatsapp: e });
                              }}
                              value={
                                otherAdmin.tWhatsapp
                                  ? String(otherAdmin.tWhatsapp)
                                  : "+91"
                              }
                              error={
                                otherAdmin.tWhatsapp
                                  ? isValidPhoneNumber(
                                    String(otherAdmin.tWhatsapp)
                                  )
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Ph"
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <p className="foundAdminMessage" style={{ color: "red", fontSize: "12px", display: "none" }}></p>
                    </div>
                  </div>
                )}

                {/* Add other admin popup */}
                {/* Add other admin popup */}

                {showOtherAdmins &&
                  user.teamMates?.map((res, index) => {
                    return (
                      <div className="py-1">
                        <p className="py-1 s-mobile-text-14">
                          <span style={{ fontWeight: "bold" }}>• Name</span> :{" "}
                          {res.tTitle}
                          {". "}
                          {res.tFname} {res.tLname}{" "}
                          <span
                            style={{
                              color: `${verified[index] ? "green" : "red"}`,
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {verified[index] ? (
                              <>
                                (<FontAwesomeIcon icon={faCheck} /> Verified)
                              </>
                            ) : (
                              <></>
                            )}
                          </span>
                        </p>

                        <p style={{ wordBreak: 'break-all' }} className="py-1 s-mobile-text-14">
                          <span style={{ fontWeight: "bold" }}>
                            &nbsp;&nbsp;Email
                          </span>{" "}
                          : {res.tEmail}
                        </p>

                        <p className="py-1 s-mobile-text-14">
                          <span style={{ fontWeight: "bold" }}>
                            &nbsp;&nbsp;Mobile No.
                          </span>{" "}
                          : {res.tWhatsapp}
                        </p>

                        <p className="py-1 s-mobile-text-14">
                          <span style={{ fontWeight: "bold" }}>
                            &nbsp;&nbsp;Position/Designation
                          </span>{" "}
                          : {res.tPos}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="border rounded p-2">
              <div className="border rounded p-1 py-2 text-justify">
                <p className="text-sm font-medium">
                  Interwiu.com has Mock Interviewers from all major and most aspired MNCs, who have been working in the relevant profiles since quite some time. Would you like to check our Interviewers?
                </p>
                <div className="flex justify-center">
                  <Button
                    variant="contained"
                    sx={{ textAlign: "center", fontSize: 12, mt: 1 }}
                    onClick={() => {
                      navigate(ADMIN_INTERVIEWERS_ROUTE);
                    }}
                  >
                    View Interviewers
                  </Button>
                </div>
              </div>

              {/* <div className="border rounded drop-shadow-lg my-2 mx-0">
                <div
                  className="mt-4 mx-auto"
                  style={{ height: "200px", width: "200px" }}
                >
                  <CircularProgressbar value={percent} text={`${percent}%`} />
                </div> */}
              {/* <div className="text-center pt-2 pb-4">
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Profile Completion
                  </Typography>
                  <p>
                    In order to complete your profile, please provide us with
                    the required information.
                  </p>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 8,
                      backgroundColor: "#00a86b",
                      "&:hover": {
                        backgroundColor: "#00a80b",
                      },
                    }}
                    onClick={() => {
                      navigate(STUDENT_PROFILE_FORM_ROUTE);
                    }}
                  >
                    Update Your Profile
                  </Button>
                </div> *
              </div> */}
              <div className="text-[12px] my-3 p-2 border rounded-md text-left s-desktop-card">
                <Typography
                  variant="h5"
                  component="h5"
                  align="center"
                  style={{
                    margin: "10px",
                    fontFamily: "'Ubuntu', sans-serif",
                  }}
                >
                  Statistics
                </Typography>
                <l style={{ position: 'relative' }}>
                  {
                    statsLoading && <div style={{ position: 'absolute', top: 0, left: 0, right: 0, backgroundColor: 'rgba(255,255,255,0.75)', zIndex: 1, height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <CircularProgress />
                    </div>
                  }
                  <li className="mt-[10px]">
                    Total Number of Students added = {stats.added}
                  </li>
                  <li className="mt-[10px]">
                    Number of Students Registered = {stats.registered}
                  </li>
                  <li className="mt-[10px]">
                    Number of Completed Mock Interviews = {stats.completed}
                  </li>
                  <li className="mt-[10px]">
                    Number of Upcoming Mock Interviews = {stats.upcoming}
                  </li>
                  <li className="mt-[10px]" style={{ position: 'relative' }}>
                    {
                      statsLoadingPart2 && <div style={{ position: 'absolute', top: 0, left: 0, right: 0, backgroundColor: 'rgba(255,255,255,0.75)', zIndex: 1, height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                      </div>
                    }
                    Average Score of Students as per the <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Feedback provided by
                    Interviewers
                    <ul className="ml-[20px]">
                      <li className="mt-[10px]">
                        i. Knowledge, skillset, and experience in the desired
                        roles = {stats?.feedback?.q1}
                      </li>
                      <li className="mt-[10px]">
                        ii. Approach and ability to solve a problem ={" "}
                        {stats?.feedback?.q2}
                      </li>
                      <li className="mt-[10px]">
                        iii. Way of Introduction = {stats?.feedback?.q3}
                      </li>
                      <li className="mt-[10px]">
                        iv. Body language and communication skills ={" "}
                        {stats?.feedback?.q4}
                      </li>
                    </ul>
                  </li>
                </l>
              </div>
            </div>
          </div>
        ) : (
          <div className="fullPageLoader">
            <Oval
              height={120}
              width={120}
              color="#306bc9"
              wrapperStyle={{ padding: "50%, 50%" }}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#90caf9"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;