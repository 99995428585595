// This form is shown when Interviewer wants to see Students Profile.
import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// import Searchable from "react-searchable-dropdown";
import { Helmet } from "react-helmet";
import axios from "axios";
import { BASE } from "../../constants/endpoints";
import "./BriefStudentsProfile.css";
import { COMPANY_NAME } from "../../constants/variables";
import {
  STUDENT_FIND_AN_INTERVIEWER_ROUTE,
  STUDENT_PROFILE_FORM_ROUTE,
} from "../../constants/routes.js";
import { downloadPDF, isIIT, programValues } from "../../utility/helper";
import LZString from "lz-string";
import { Oval } from "react-loader-spinner";
const StudentDashboard = () => {
  const [user, setUser] = useState();
  console.log(user);
  const [loaded, setLoaded] = useState(false);
  // console.log(window.location.pathname)
  const id = window.location.pathname.split("student/")[1];
  console.log(id);
  const location = useLocation();
  useEffect(() => {
    axios
      .get(`${BASE}/send/student/${id}`)
      .then((data) => {
        setUser(data.data);
        setLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    console.log(user);
  }, [user]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  let navigate = useNavigate();
  const [profiledata, setProfileData] = useState("");
  // Profile Completion Percentage
  const [percent, setPercent] = useState(10);
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Students Profile</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      {/* <Navbarlogedin /> */}
      <div className="interviews">
        <div className="w-full p-2 my-2 border-2 rounded flex justify-center">
          <Typography
            variant="h4"
            component="h4"
            sx={{
              // textAlign: "center",
              display: "inline",
              color: "#1265a8",
              fontWeight: "bold",
              margin: "0 auto",
            }}
          >
            Student's Profile
          </Typography>
        </div>
        {loaded ? (
          <div className="grid">
            <div className=" overflow-hidden">
              {/* <div className="border rounded mb-2">
                <div className="h-32 opacity-80 overflow-hidden">
                  <img src={require("../../images/cover2.webp")} alt="Cover" />
                </div>
                <div className="relative grid py-2" id="profile-bio">
                  <div className="relative">
                    <div className="absolute w-40 h-40 -top-16 left-3">
                      <img
                        src={user?.photo}
                        className="h-full w-full object-cover rounded-full p-1 border bg-white"
                        alt="Profile"
                      />
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="h4"
                      component="h4"
                      sx={{
                        color: "#e84c3d",
                        fontWeight: "bold",
                      }}
                    >
                      {user?.fname + (user?.lname ? " " + user?.lname : "")}
                    </Typography>
                    <div
                      className="flex flex-col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <Chip
                        label={
                          programValues[user?.program] +
                          ", " +
                          (user?.branch ? user?.branch : "")
                        }
                        sx={{ fontSize: "16px" }}
                      />
                      <Chip
                        label={user?.institute ? user?.institute : ""}
                        sx={{ fontSize: "16px" }}
                        className="mt-2"
                      />

                    </div>

                    <p className="text-gray-500 leading-5"></p>
                    <div className="text-2xl my-1 flex gap-2">
                      {user?.linkedInURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-linkedin"
                            onClick={() => {
                              window.open(
                                user?.linkedInURL
                                  ? user?.linkedInURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user?.githubURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-black cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-github"
                            onClick={() => {
                              window.open(
                                user?.githubURL
                                  ? user?.githubURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user?.twitterURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-twitter"
                            onClick={() => {
                              window.open(
                                user?.twitterURL
                                  ? user?.twitterURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="relative">
                    <div className="w-20 border rounded-md mx-auto my-2 px-2 py-1">
                      {isIIT(user?.institute) ? (
                        <img
                          src={require("./Logos_IITs/" +
                            user?.institute.split(" ").join("_") +
                            "_Logo.png")}
                          alt={user?.institute}
                        />
                      ) : (
                        <img
                          src={require("./Logos_IITs/interwiu_logo.png")}
                          alt={user?.institute}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="border rounded mb-2">
                <div className="h-32 opacity-80 s-mobile-overflow-h" style={{ position: 'relative' }}>
                  <img style={{ borderRadius: '4px 4px 0px 0px' }} src={require("../../images/cover2.webp")} alt="Cover" />
                  <div className="s-mobile-card" style={{ position: 'absolute', bottom: '-50px', left: '0', right: '0', justifyContent: 'center' }}>
                    <div className="" style={{ width: '43%', position: 'realtive' }}>
                      <img
                        src={user.photo}
                        style={{ width: '100%', height: '100%' }}
                        className="h-full w-full object-cover rounded-full p-1 border bg-white"
                        alt="Profile"
                      />

                    </div>
                  </div>

                </div>
                <div className="relative p-2 s-mobile-flex-dir-col s-desktop-card" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }} id="profile-bio">
                  <div className="" style={{ transform: 'translate(14%,-50%)', width: '20%' }}>
                    <div className="" style={{ width: '100%' }}>
                      <img
                        src={user.photo}
                        className="h-full w-full object-cover rounded-full p-1 border bg-white"
                        alt="Profile"
                      />
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="h4"
                      component="h4"
                      sx={{
                        color: "#e84c3d",
                        fontWeight: "bold",
                      }}
                    >
                      {user.fname + (user.lname ? " " + user.lname : "")}
                    </Typography>
                    {/* <div
                      className="flex flex-col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <Chip
                        label={
                          programValues[user.program] +
                          ", " +
                          (user.branch ? user.branch : "")
                        }
                        sx={{ fontSize: "16px" }}
                      />
                      <Chip
                        label={user.institute ? user.institute : ""}
                        sx={{ fontSize: "16px" }}
                        className="mt-2"
                      />
                    </div> */}
                    <div
                      className="flex flex-col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <Chip
                        label={
                          programValues[user?.program] +
                          ", " +
                          (user?.year)
                        }
                        sx={{ fontSize: "16px" }}
                        className="mt-2 "
                      />
                      <Chip
                        label={
                          (user?.branch ? user?.branch : "")
                        }
                        sx={{ fontSize: "16px" }}
                        className="mt-2 s-mobile-changing-chip-property"
                      />
                      {
                        (user.specialization != undefined && user.specialization !== "") && (
                          <Chip
                            label={

                              ("Specialization: " + user?.specialization)
                            }
                            sx={{ fontSize: "16px" }}
                            className="mt-2 s-mobile-changing-chip-property"
                          />
                        )
                      }

                      <Chip
                        label={user?.institute ? user?.institute : ""}
                        sx={{ fontSize: "16px" }}
                        className="mt-2 s-mobile-changing-chip-property"
                      />
                      {/* <Chip
                      label={"B.Tech, IIT Kharagpur"}
                      sx={{ fontSize: "20px" }}
                    /> */}
                    </div>
                    {/* <Typography
                    variant="h6"
                    component="h6"
                    sx={{ lineHeight: 1, fontSize: "16px" }}
                  >
                    B.Tech, IIT Kharagpur
                  </Typography> */}

                    <p className="text-gray-500 leading-5"></p>
                    <div className="text-2xl my-1 flex gap-2">
                      {user.linkedInURL ? (
                        <button onClick={() => {
                          window.open(
                            user.linkedInURL
                              ? user.linkedInURL
                              : "/dashboard",
                            "_blank"
                          );
                        }} className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-linkedin"
                          // onClick={() => {
                          //   window.open(
                          //     user.linkedInURL
                          //       ? user.linkedInURL
                          //       : "/dashboard",
                          //     "_blank"
                          //   );
                          // }}
                          ></i>
                        </button>
                      ) : null}
                      {user.githubURL ? (
                        <button onClick={() => {
                          window.open(
                            user.githubURL ? user.githubURL : "/dashboard",
                            "_blank"
                          );
                        }} className="hover:text-blue-500 hover:bg-gray-100 text-black cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-github"
                          // onClick={() => {
                          //   window.open(
                          //     user.githubURL ? user.githubURL : "/dashboard",
                          //     "_blank"
                          //   );
                          // }}
                          ></i>
                        </button>
                      ) : null}
                      {user.twitterURL ? (
                        <button onClick={() => {
                          window.open(
                            user.twitterURL
                              ? user.twitterURL
                              : "/dashboard",
                            "_blank"
                          );
                        }} className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-twitter"
                          // onClick={() => {
                          //   window.open(
                          //     user.twitterURL
                          //       ? user.twitterURL
                          //       : "/dashboard",
                          //     "_blank"
                          //   );
                          // }}
                          ></i>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} className="relative">
                    <div className="w-20 border rounded-md mx-auto my-2 px-2 py-1">
                      {user.iLogo !== "" ? <img
                        src={user.iLogo}
                        alt={user.institute}
                      /> : (
                        user.institute && isIIT(user?.institute) ? (
                          <img
                            src={require("./Logos_IITs/" +
                              user.institute.split(" ").join("_") +
                              "_Logo.png")}
                            // className="w-12 h-12 object-contain"
                            alt={user.institute}
                          />
                        ) : (
                          <img
                            src={require("./Logos_IITs/interwiu_logo.png")}
                            // className="w-12 h-12 object-contain"
                            alt={user.institute}
                          />
                        ))}
                    </div>
                  </div>
                </div>

                <div className="s-mobile-card" style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', marginTop: '17%', postion: 'relative' }} id="profile-bio">
                  <div style={{ position: 'absolute', top: '43vw', right: '0.4rem', transform: 'scale(0.7)' }} className="relative s-mobile-card">
                    <div className="w-20 border rounded-md mx-auto my-2 px-2 py-1">
                      {user.institute && isIIT(user?.institute) ? (
                        <img
                          src={require("./Logos_IITs/" +
                            user.institute.split(" ").join("_") +
                            "_Logo.png")}
                          // className="w-12 h-12 object-contain"
                          alt={user.institute}
                        />
                      ) : (
                        <img
                          src={require("./Logos_IITs/interwiu_logo.png")}
                          // className="w-12 h-12 object-contain"
                          alt={user.institute}
                        />
                      )}
                    </div>

                  </div>
                  <div>
                    <Typography
                      variant="h4"
                      component="h4"
                      sx={{
                        color: "#e84c3d",
                        fontWeight: "bold",
                      }}
                    >
                      {user.fname + (user.lname ? " " + user.lname : "")}
                    </Typography>
                    {/* <div
                      className="flex flex-col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <Chip
                        label={
                          programValues[user.program] +
                          ", " +
                          (user.branch ? user.branch : "")
                        }
                        sx={{ fontSize: "16px" }}
                      />
                      <Chip
                        label={user.institute ? user.institute : ""}
                        sx={{ fontSize: "16px" }}
                        className="mt-2"
                      />
                    </div> */}
                    <div
                      className="flex flex-col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <Chip
                        label={
                          programValues[user?.program] +
                          ", " +
                          (user?.year)
                        }
                        sx={{ fontSize: "16px" }}
                        className="mt-2 s-mobile-changing-chip-property"
                      />
                      <Chip
                        label={
                          (user?.branch ? user?.branch : "")
                        }
                        sx={{ fontSize: "16px" }}
                        className="mt-2 s-mobile-changing-chip-property"
                      />
                      {
                        user.specialization != undefined && (
                          <Chip
                            label={

                              ("Specialization: " + user?.specialization)
                            }
                            sx={{ fontSize: "16px" }}
                            className="mt-2 s-mobile-changing-chip-property"
                          />
                        )
                      }

                      <Chip
                        label={user?.institute ? user?.institute : ""}
                        sx={{ fontSize: "16px" }}
                        className="mt-2 s-mobile-changing-chip-property"
                      />
                      {/* <Chip
                      label={"B.Tech, IIT Kharagpur"}
                      sx={{ fontSize: "20px" }}
                    /> */}
                    </div>

                    <p className="text-gray-500 leading-5"></p>
                    <div className="text-2xl my-1 flex gap-2">
                      {user.linkedInURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-linkedin"
                            onClick={() => {
                              window.open(
                                user.linkedInURL
                                  ? user.linkedInURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.githubURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-black cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-github"
                            onClick={() => {
                              window.open(
                                user.githubURL ? user.githubURL : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.twitterURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-twitter"
                            onClick={() => {
                              window.open(
                                user.twitterURL
                                  ? user.twitterURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="relative s-desktop-card">
                    <div className="w-20 border rounded-md mx-auto my-2 px-2 py-1">
                      {user.institute && isIIT(user?.institute) ? (
                        <img
                          src={require("./Logos_IITs/" +
                            user.institute.split(" ").join("_") +
                            "_Logo.png")}
                          // className="w-12 h-12 object-contain"
                          alt={user.institute}
                        />
                      ) : (
                        <img
                          src={require("./Logos_IITs/interwiu_logo.png")}
                          // className="w-12 h-12 object-contain"
                          alt={user.institute}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded border p-2 my-2">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                  }}
                  className="s-mobile-profile-subHeading"
                >
                  Description
                </Typography>
                <p className="s-mobile-text-14">
                  I'm a Student of {programValues[user?.program]} (
                  {user?.branch}) at {user?.institute}. I'm looking for Mock
                  Interviews.
                </p>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                    marginTop: '10px'
                  }}
                  className="s-mobile-profile-subHeading"
                >
                  Resume
                </Typography>
                <Button
                  onClick={() => downloadPDF(user?.resume)}
                  variant="contained"
                >
                  <i className="fa-solid fa-link"></i> Resume
                </Button>
                {
                  (user?.coreprofiles.length!=0 || user?.profiles.length!=0) && <>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                      className="s-mobile-profile-subHeading"

                    >
                      {`Profile Preference(s)`}
                    </Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.3rem' }}>
                      {user?.coreprofiles.map((item) => (
                        <Chip label={item} variant="outlined" />
                      ))}
                      {user?.profiles.map((item) => (
                        <Chip label={item} variant="outlined" />
                      ))}
                      {/* <Chip label="Software" variant="outlined" />
                <Chip label="Data Analysis" variant="outlined" /> */}
                    </div></>
                }

                {user?.project && (
                  <>
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                      className="s-mobile-profile-subHeading"

                    >
                      {programValues[user?.program]}&nbsp;Project Title
                    </Typography>
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      {user?.project}
                      {/* <Chip label={} variant="outlined" /> */}
                      {/* <Chip label="Software" variant="outlined" />
                  <Chip label="Data Analysis" variant="outlined" /> */}
                    </div>
                  </>
                )}
                {
                  (user.appearingFor.one || user.appearingFor.two) && (
                    <>
                      <Typography
                        variant="h6"
                        component="h6"
                        sx={{
                          fontWeight: "bold",
                          marginTop: "10px",
                        }}
                        className="font-16-500"
                      >
                        Appearing For
                      </Typography>
                      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.3rem' }}>
                        {user.appearingFor.one && <Chip label={`Campus Placements`} variant="outlined" />}
                        {user.appearingFor.two && <Chip label={`Internship Interviews`} variant="outlined" />}
                      </div>
                    </>
                  )
                }
                {user.companies &&
                  user.companies.length !== 0 &&
                  user.companies[0]?.company !== "" && (
                    <>
                      <Typography
                        variant="h6"
                        component="h6"
                        sx={{
                          fontWeight: "bold",
                          marginTop: "10px",
                        }}
                        className="s-mobile-profile-subHeading"

                      >
                        Targeted Companies
                      </Typography>
                      {/* <div>
                        {user?.companies?.map((item) => (
                          <>
                            {item?.company !== "" && (
                              <>
                                • Company - {item?.company} <br />{" "}
                              </>
                            )}
                            {item?.company !== "" && (
                              <>
                                {" "}
                                &nbsp;&nbsp; Profile - {
                                  item?.profile
                                } <br />{" "}
                              </>
                            )}
                            {item?.company !== "" && (
                              <>
                                {" "}
                                &nbsp;&nbsp; Job Description - {
                                  item?.textJD
                                }{" "}
                                <br />
                              </>
                            )}
                          </>
                        ))}
                      </div> */}
                      <div style={{ display: 'grid', gridTemplateColumns: '1fr 40fr', whiteSpace: 'pre' }}>
                        {user.companies?.map((item) => (
                          <>
                            <div style={{ marginRight: '5px' }}>• </div>
                            <div style={{ marginBottom: '0.5rem' }}>
                              {item?.company !== "" && (
                                <>
                                  <span style={{ fontWeight: '500', fontSize: '14px' }}>Company </span> - <span style={{ color: 'rgba(0,0,0,0.65)', fontSize: '14px' }}>{item?.company}</span> <br />
                                </>
                              )}
                              {item?.company !== "" && (
                                <>
                                  <span style={{ fontWeight: '500', fontSize: '14px' }}>Profile</span> - <span style={{ color: 'rgba(0,0,0,0.65)', fontSize: '14px' }}>{item?.profile}</span> <br />
                                </>
                              )}
                              {item?.company !== "" && (
                                <>
                                  <span style={{ fontWeight: '500', fontSize: '14px' }}>Job Description</span> - <span style={{ color: 'rgba(0,0,0,0.65)', fontSize: '14px' }}>{item?.textJD}</span><br />
                                </>
                              )}
                            </div>
                          </>
                        ))}
                      </div>
                    </>
                  )}
              </div>
              {/* <div className="rounded border p-2 my-2">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Past Mock Interviews
              </Typography>
              <div style={{ margin: "auto 20px" }}>
                <CompletedInterview />
                <CompletedInterview />
              </div>
            </div> */}
            </div>
          </div>
        ) : (
          <div className="fullPageLoader">
            <Oval
              height={120}
              width={120}
              color="#306bc9"
              wrapperStyle={{ padding: "50%, 50%" }}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#90caf9"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default StudentDashboard;
