import React from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob } from "../../images/svg-decorator-blob-6.svg";

const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg 
  shadow-sm pt-10 pb-6 px-6 sm:px-10 lg:px-6 lg:pt-10 lg:pb-6 xl:p-10 
  mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 
  shadow-raised `}
`;

// const Plan = styled.div `
//   ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
//   .planHighlight {
//     ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
//   }
//   ${props =>
//     props.featured &&
//     css`
//       background: rgb(100,21,255);
//       background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
// background: rgb(85,60,154);
// background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
// background: rgb(76,81,191);
// background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
//       ${tw`bg-primary-500 text-gray-100`}
//       .planHighlight {
//         ${tw`hidden`}
//       }
//       .duration {
//         ${tw`text-gray-200!`}
//       }
//       ${PlanFeatures} {
//         ${tw`border-indigo-500`}
//       }
//       .feature:not(.mainFeature) {
//         ${tw`text-gray-300!`}
//       }
//       ${BuyNowButton} {
//         ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
//     `}
// `;

// const PlanHeader = styled.div`
//   ${tw`flex flex-col leading-relaxed py-8`}
//   .name {
//     ${tw`font-bold text-xl`}
//   }
//   .price {
//     ${tw`font-bold text-4xl sm:text-5xl my-1`}
//   }
//   .duration {
//     ${tw`text-black font-bold tracking-widest`}
//   }
//   .featuredText {
//     ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
//   }
// `;

const PlanHeader = styled.div`
  ${tw`relative`}
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`font-bold tracking-wider mr-3`}
  }
  .featuredText {
    ${tw`text-[1.25rem] font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-full text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-center`}
    .earlyBird {
      ${tw`flex-col flex`}
      .currentPrice {
        ${tw`text-lg font-bold leading-none`}
        .bigText {
          ${tw`text-5xl font-bold`}
        }
      }
      .earlyBirdText {
        ${tw`text-[12px] text-gray-500`}
      }
    }
    .oldPriceContainer {
      ${tw`line-through text-red-700`}
    }
    .oldPrice {
      ${tw`text-gray-700 text-3xl font-normal block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-700 lg:text-[1rem] xl:text-base`}
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;

const PlanComponent = ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  plans = null,
  primaryButtonText = "Buy Now",
}) => {
  // const defaultPlans = [
  //   {
  //     name: "Uno",
  //     price: `₹${process.env.REACT_APP_STD_45MIN}`,
  //     duration: "1 Mock Interview",
  //     duration2: "(with Live Feedback)",
  //     perceived: "₹799",
  //     priceText: "Earlybird Price"
  //     // mainFeature: "Suited for Personal Blogs",
  //     // features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"],
  //   },
  //   {
  //     name: "Duo",
  //     price: "₹899",
  //     duration: "2 Mock Interviews",
  //     duration2: "(with Live Feedback)",
  //     perceived: "₹1499",
  //     // mainFeature: "Suited for Production Websites",
  //     // features: ["60 Templates", "8 Landing Pages", "22 Internal Pages", "Priority Assistance"],
  //     // featured: false,
  //     featuredText: "Save ₹600",
  //     priceText: "Earlybird Price"
  //   },
  //   {
  //     name: "Trio",
  //     price: "₹1299",
  //     duration: "3 Mock Interviews",
  //     duration2: "(with Live Feedback)",
  //     perceived: "₹2199",
  //     // mainFeature: "Suited for Big Companies",
  //     // features: ["90 Templates", "9 Landing Pages", "37 Internal Pages", "Personal Assistance"],
  //     featuredText: "Save ₹900",
  //     priceText: "Earlybird Price"
  //   },
  // ];

  const defaultPlans = [
    {
      name: "Uno",
      price: ["₹", `${process.env.REACT_APP_SHORT_30MIN}`, ""],
      oldPrice: ["₹", "799", ""],
      description: "1 Mock Interview",
      description1: "1 Mentorship Session",
      description2: "(with Live Feedback)",
      features: [
        "Scope 1, 2 & 3 reporting",
        "2 data connectors",
        "Up to 100 employees",
        "GHG Reporting",
      ],
      url: "https://google.com",
      // featured:"You Save ₹300"
    },
    {
      name: "Duo",
      price: ["₹", `${process.env.REACT_APP_DUO_SHORT_30MIN}`, ""],
      oldPrice: ["₹", `${process.env.REACT_APP_STD_45MIN}`, ""],
      description: "2 Mock Interviews",
      description1: "2 Mentorship Sessions",
      description2: "(with Live Feedback)",
      features: [
        "Scope 1, 2 & 3 reporting",
        "∞ data connectors",
        "Up to 500 employees",
        "Multiple report types",
        "Account Manager",
        "AI-assisted automation",
      ],
      url: "https://google.com",
      featured: "You Save ₹49",
    },
    {
      name: "Trio",
      price: ["₹", `${process.env.REACT_APP_TRIO_SHORT_30MIN}`, ""],
      oldPrice: ["₹", "2199", ""],
      description: "3 Mock Interviews",
      description1: "3 Mentorship Sessions",
      description2: "(with Live Feedback)",
      features: [
        "∞ data types",
        "∞ integrations",
        "∞ employees",
        "Custom API",
        "White Label Reporting",
        "Hands-on climate consultancy",
      ],
      url: "https://google.com",
      featured: "You Save ₹98",
    },
  ];

  if (!plans) plans = defaultPlans;

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(
        115deg,
        rgba(56, 178, 172, 1) 0%,
        rgba(129, 230, 217, 1) 100%
      );
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(
        115deg,
        #6415ff,
        #7431ff,
        #8244ff,
        #8e56ff,
        #9a66ff
      );
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(
        115deg,
        rgba(245, 101, 101, 1) 0%,
        rgba(254, 178, 178, 1) 100%
      );
    `,
  ];

  return (
    <Container>
      <ContentWithPaddingXl>
        <PlansContainer
          style={{
            gap: "3rem",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "stretch",
            padding: "0px 2rem",
            margin: "4rem 0rem",
          }}
        >
          {plans.map((plan, index) => (
            <Plan
              key={index}
              featured={plan.featured}
              style={{
                width: "16.5rem",
                margin: "0px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
              }}
            >
              {/* {!plan.featured && <div style={{height:"1rem",boxShadow: "rgba(255,255,255,0.4) 3px 3px 6px inset"}} className="planHighlight" css={highlightGradientsCss[index % highlightGradientsCss.length]} />} */}

              <PlanHeader>
                <span
                  className="nameAndFeaturedContainer "
                  style={{ flexDirection: "row" }}
                >
                  <span className="name discount-name">{plan.name}</span>
                </span>
                <div className="pricingContainer flex items-end">
                  {/* {plan.oldPrice && <span className="oldPriceContainer"><span className="oldPrice translate-y-[8px]">{plan.oldPrice}</span></span>} */}
                  <div className="earlyBird">
                    {/* <span className="earlyBirdText">Earlybird Price</span> */}
                    <span className="currentPrice">
                      <span className="bigText">{plan.price[0]}</span>
                      <span className="bigText">{plan.price[1]}</span>
                      {plan.price[2]}{" "}
                    </span>
                  </div>
                </div>
                <p className="description">
                  <span className="font-semibold">{plan.description}</span> or{" "}
                  <br />
                  <span className="font-semibold">{plan.description1}</span>
                  <br />
                  {plan.description2}
                </p>
                {plan.featured && (
                  <div className="w-100 flex justify-center items-center mt-5">
                    <span className="featuredText">{plan.featured}</span>
                  </div>
                )}
              </PlanHeader>
              {/* <PlanFeatures>
                <span className="feature mainFeature">{plan.mainFeature}</span>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures> */}
              {/* <PlanAction>
                <BuyNowButton css={!plan.featured && highlightGradientsCss[index]}>{primaryButtonText}</BuyNowButton>
              </PlanAction> */}
            </Plan>
          ))}
          <DecoratorBlob />
        </PlansContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};

export default PlanComponent;
