import React, { useState, useEffect } from "react";
import ReceivedRequest from "./ReceivedRequest";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-date-picker";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { compareSlots, t24HDecimalTo12HFormat, toastStyle } from "../../../utility/helper";
import axios from "axios";
import { BASE } from "../../../constants/endpoints";
import toast from "react-hot-toast";
import moment from "moment-timezone";
const Accordian = ({ array, index, stack, setStack, remove, removeEntire, isAccepted }) => {
  const [expanded, setExpanded] = useState(false);
  let counter = 0;
  const [count, setCount] = useState(0);
  const [height, setHeight] = useState("0px");
  useEffect(() => {
    console.log(count);
  }, [count]);

  useEffect(() => {
    console.log(document.getElementById("stack-ext")?.clientHeight);
    setHeight(String(document.getElementById("stack-ext")?.clientHeight) + "px");
    console.log(height)
  }, [expanded]);
  console.log(array)
  const requests = [];
  for (let i = 0; i < array.length; i++) {
    requests.push(array[i].requests);
  }
  console.log('req', requests)
  console.log('array------------->', array)
  // useEffect(() => {
  //   if (!expanded) {
  //     setOpenNorm()
  //   } else {
  //     document.getElementById("stack-ext").classList.remove("stack-hide");
  //     document.getElementById("stack-norm").classList.add("stack-hide");
  //   }
  // }, [expanded]);
  // (expanded ?
  //     document.getElementById('stack-norm').classList.add('stack-hide')
  //     // document.getElementById('stack-norm').classList.add(stack-hide)
  //     :
  //     null
  // )
  // Removing the card on Accepting or Declining the request
  //   const remove = (first, second) => {
  //     let localCards = [...stack];
  //     localCards[first].array.splice(second, 1);
  //     setStack(localCards);
  //   };
  if (array.length === 0) {
    return null
  }
  if (array.length === 1) {
    // let array[0] = array[0];
    return (
      (
        (moment().tz("Asia/Kolkata").diff(moment(array[0].requests.date + " " + t24HDecimalTo12HFormat(array[0].requests.toTime), 'DD/MM/YYYY hh:mm a'), 'seconds')) < 0
        && <>
          {console.log('array[0].students', array[0].students)}
          <ReceivedRequest
            name={array[0].students?.fname + " " + array[0].students?.lname}
            branch={array[0].students.branch}
            college={array[0].students.institute}
            program={array[0].students.program}
            year={array[0].students.year}
            price={{45:"400",30:"250"}[array[0].requests?.sessDuration]}
            slotStart={array[0].requests.fromTime}
            slotEnd={array[0].requests.toTime}
            reqId={array[0].requests.reqId}
            date={array[0].requests.date}
            status={array[0].requests.reqStatus}
            profile={array[0].requests.profile}
            subprofile={array[0].requests.subprofile}
            // image={array[0].students.photo}
            image={array[0].students.isDisabled === true || array[0].students.isDelete === true ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' : array[0].students.photo}
            counter={counter}
            index={index}
            sIndex={count}
            remove={remove}
            cardId={"sup" + index + "sub" + count}
            isAccepted={isAccepted}
            resume={array[0].students.resume}
            sId={array[0].students.id}
            isRescheduled={array[0].requests?.reschedule?.isRescheduled}
            reschedule={array[0].requests?.reschedule}
            mockId={array[0].requests?.mockId}
            iId={array[0].requests.interviewerId}
            isDisable={array[0].students.isDisabled === true}
            isDelete={array[0].students.isDelete === true}
            isUnavailable={array[0].students.isDisabled === true || array[0].students.isDelete === true}
            sessDuration={array[0].requests.sessDuration}
            sessType={array[0].requests.sessType}
          />
          <hr className="my-2 border-gray-700" />
        </>)
    );
  }

  const acceptReq = async (requests) => {
    const obj = {
      status: "ReqAccepted",
    };
    const reqId = requests.reqId
    const isRescheduled = requests.reschedule.isRescheduled
    const reschedule = requests.reschedule
    try {
      if ((isRescheduled === true || isRescheduled === 'true') && reschedule.status === "sent") {
        const response = await axios.patch(`${BASE}/send/handleRescheduleInterviewer/${reqId}`, {
          status: "accepted",
          reschDetails: reschedule.details
        })
        const responseAutoReject = await axios.patch(
          `${BASE}/send/autorejectrequests/${reqId}`
        )
        return true;
      } else {
        const response = await axios.patch(`${BASE}/send/updateInterviewReq/${reqId}`, obj)
        return true;
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }


  const acceptAllFunction = async () => {
    const obj = {
      requests: requests,
    };
    console.log('😴2', obj);
    for (let i = 0; i < requests.length; i++) {
      let re = await acceptReq(requests[i]);
      if (!re) {
        toast.success('Some Error Occurred', {
          ...toastStyle.error,
          position: "top-center",
          style: { border: '1px solid #713200', padding: '16px', color: '#fff' }
        });
        return;
      }
    }
    toast.success('All Requests Accepted Successfully for this Slot', {
      ...toastStyle.success,
      position: "top-center",
      style: {
        // marginTop:'30%',
        border: '1px solid #713200',
        padding: '16px',
        color: '#fff',
        backgroundColor: '#0e8f11'
      }
    });
    setTimeout(() => {
      window.location.reload(false);
    }, 3000);
  }

  const declineReq = async (requests) => {
    const obj = {
      status: "ReqDeclined",
    };
    const reqId = requests.reqId
    const isRescheduled = requests.reschedule.isRescheduled
    const reschedule = requests.reschedule
    try {
      if ((isRescheduled === true || isRescheduled === 'true') && reschedule.status === "sent") {
        const response = await axios.patch(`${BASE}/send/handleRescheduleInterviewer/${reqId}`, {
          status: "declined"
        })
        const responseAutoReject = await axios.patch(
          `${BASE}/send/autorejectrequests/${reqId}`
        )
        return true;
      } else {
        const response = await axios.patch(`${BASE}/send/updateInterviewReq/${reqId}`, obj)
        return true;
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const declineAllFunction = async () => {
    const obj = {
      requests: requests,
    };
    console.log('😴2', obj);
    for (let i = 0; i < requests.length; i++) {
      let re = await declineReq(requests[i]);
      if (!re) {
        toast.success('Some Error Occurred', {
          ...toastStyle.error,
          position: "top-center",
          style: { border: '1px solid #713200', padding: '16px', color: '#fff' }
        });
        return;
      }
    }
    toast.success('All Requests Declined Successfully for this Slot', {
      ...toastStyle.success,
      position: "top-center",
      style: { border: '1px solid #713200', padding: '16px', color: '#fff', backgroundColor: '#0e8f11' }
    });
    setTimeout(() => {
      window.location.reload(false);
    }, 3000);
  }


  return (
    <>
      <div
        className="box-shadow-request-card rounded-lg my-4"
        style={{ overflow: "hidden" }}
        id="stack-container"
      >
        <div className="flex justify-between px-3 pt-2">
          <Typography
            variant="h5"
            component="h5"
            className="s-desktop-card"
            sx={{
              fontWeight: "bold",
            }}
          // style={{fontSize: "1.4rem"}}
          >
            {array.length} Requests for this slot
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            className="s-mobile-card"
            sx={{
              fontWeight: "bold",
            }}
            style={{ fontSize: "1.2rem" }}
          >
            {array.length} Requests for this slot
          </Typography>
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => {
              setExpanded(!expanded);
              setCount(0)
            }}
          >
            {!expanded ? <KeyboardArrowDownIcon fontSize="inherit" /> : <KeyboardArrowUpIcon fontSize="inherit" />}
          </IconButton>
          {/* <Button variant="outlined" size="large">
                      <KeyboardArrowDownIcon fontSize="inherit" />
                    </Button> */}
        </div>
        <div id="stack-cont">
          {
            (moment().tz("Asia/Kolkata").diff(moment(array[count].requests.date + " " + t24HDecimalTo12HFormat(array[count].requests.toTime), 'DD/MM/YYYY hh:mm a'), 'seconds')) < 0
            && <div
              // className={expanded ? "stack-hide" : "stack-show"}
              id="stack-norm"
              style={{ padding: '0px 10px' }}

            >
              <ReceivedRequest
                name={array[count].students.fname + " " + array[count].students.lname}
                branch={array[count].students.branch}
                college={array[count].students.institute}
                program={array[count].students.program}
                year={array[count].students.year}
                price={{45:"400",30:"250"}[array[count].requests?.sessDuration]}
                slotStart={array[count].requests.fromTime}
                slotEnd={array[count].requests.toTime}
                reqId={array[count].requests.reqId}
                date={array[count].requests.date}
                status={array[count].requests.reqStatus}
                subprofile={array[count].requests.subprofile}
                profile={array[count].requests.profile}
                image={array[count].students.isDisabled === true || array[count].students.isDelete === true ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' : array[count].students.photo}
                counter={counter}
                index={index}
                sIndex={count}
                remove={remove}
                cardId={"sup" + index + "sub" + count}
                isAccepted={isAccepted}
                resume={array[count].students.resume}
                sId={array[count].students.id}
                isRescheduled={array[count].requests?.reschedule?.isRescheduled}
                reschedule={array[count].requests?.reschedule}
                mockId={array[count].requests?.mockId}
                iId={array[count].requests.interviewerId}
                isDisable={array[count].students.isDisabled === true}
                isDelete={array[count].students.isDelete === true}
                isUnavailable={array[count].students.isDisabled === true || array[count].students.isDelete === true}
                sessDuration={array[count].requests.sessDuration}
                sessType={array[count].requests.sessType}
              />
            </div>}
          <div
            // className={expanded ? "stack-show " : "stack-hide "}
            style={expanded ? { overflow: 'unset', transition: 'ease-in-out', transitionDuration: '1s', padding: '0px 10px' } :
              { height: '0px', overflow: 'hidden', transition: 'ease-in-out', transitionDuration: '1s', padding: '0px 10px', position: 'relative' }}
            className={
              " transition-all origin-top duration-100"
            }
            id="stack-ext"
          >
            {array.map((sItem, sIndex) => {
              return (
                sIndex !== 0 &&
                (moment().tz("Asia/Kolkata").diff(moment(sItem.requests.date + " " + t24HDecimalTo12HFormat(sItem.requests.toTime), 'DD/MM/YYYY hh:mm a'), 'seconds')) < 0
                &&
                <ReceivedRequest
                  name={sItem.students.fname + " " + sItem.students.lname}
                  branch={sItem.students.branch}
                  college={sItem.students.institute}
                  program={sItem.students.program}
                  year={sItem.students.year}
                  price={{45:"400",30:"250"}[sItem.requests?.sessDuration]}
                  slotStart={sItem.requests.fromTime}
                  slotEnd={sItem.requests.toTime}
                  reqId={sItem.requests.reqId}
                  date={sItem.requests.date}
                  status={sItem.requests.reqStatus}
                  profile={sItem.requests.profile}
                  // image={sItem.students.photo}
                  image={sItem.students.isDisabled === true || sItem.students.isDelete === true ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' : sItem.students.photo}
                  counter={counter}
                  index={index}
                  sIndex={count}
                  remove={remove}
                  cardId={"sup" + index + "sub" + count}
                  isAccepted={isAccepted}
                  resume={sItem.students.resume}
                  sId={sItem.students.id}
                  iId={sItem.requests.interviewerId}
                  isRescheduled={sItem.requests?.reschedule?.isRescheduled}
                  reschedule={sItem.requests?.reschedule}
                  mockId={sItem.requests?.mockId}
                  isDisable={sItem.students.isDisabled === true}
                  isDelete={sItem.students.isDelete === true}
                  isUnavailable={sItem.students.isDisabled === true || sItem.students.isDelete === true}
                  sessDuration={sItem.requests.sessDuration}
                  sessType={sItem.requests.sessType}
                />
              );
            })}
          </div>
        </div>
        <div className="flex s-desktop-card" style={{ justifyContent: 'space-around' }}>
          <div className="col-span-1 flex justify-center">
            {/* Accepted: {accepted} | Declined: {declined} */}
          </div>
          {!expanded ? (
            <div className="flex justify-center col-span-1" style={{ marginRight: '10%' }}>
              <IconButton
                aria-label="Previous Card"
                onClick={() => {
                  setCount(() => (count === 0 ? array.length - 1 : count - 1));
                  // switchPrevCard();
                }}
              >
                <ArrowBackIosIcon fontSize="large" htmlColor="#000" />
              </IconButton>
              <div>
                {count + 1}/{array.length}
              </div>
              <IconButton
                aria-label="Next Card"
                onClick={() => {
                  setCount(() => (count < array.length - 1 ? count + 1 : 0));
                  //   switchNextCard();
                }}
              >
                <ArrowForwardIosIcon fontSize="large" htmlColor="#000" />
              </IconButton>
            </div>
          ) : (
            <div></div>
          )}
          {isAccepted === false && <div className="relative flex justify-center col-span-1" style={{ alignItems: 'center' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                marginRight: "10px",
              }}
              onClick={
                async () => { acceptAllFunction() }}

            // axios
            //   .patch(`${BASE}/send/acceptAllRequests`, obj)
            //   .then((response) => {
            //     console.log(response);
            //     toast.success('All Requests Accepted Successfully for this Slot', {
            //       ...toastStyle.success,
            //       position: "top-center",
            //       style: {
            //         // marginTop:'30%',
            //         border: '1px solid #713200',
            //         padding: '16px',
            //         color: '#fff',
            //         backgroundColor: '#0e8f11'
            //       }
            //     });
            //     setTimeout(() => {
            //       window.location.reload(false);
            //     }, 3000);
            //   });
            // }}
            >
              Accept All
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "red" }}
              onClick={() => { declineAllFunction() }}
            // const obj = {
            //   requests: requests,
            // };

            //   axios
            //     .patch(`${BASE}/send/declineAllRequests`, obj)
            //     .then((response) => {
            //       console.log(response);
            //       toast.success('All Requests Declined Successfully for this Slot', {
            //         ...toastStyle.success,
            //         position: "top-center",
            //         style: {
            //           // marginTop:'30%',
            //           border: '1px solid #713200',
            //           padding: '16px',
            //           color: '#fff',
            //           backgroundColor: '#0e8f11'
            //         }
            //       });
            //       setTimeout(() => {
            //         window.location.reload(false);
            //       }, 3000);
            //     });
            // }}
            >
              Decline All
            </Button>
          </div>}
        </div>

        <div className="flex s-mobile-flex-dir-col s-mobile-card">
          {!expanded ? (
            <div className="flex justify-center col-span-1">
              <IconButton
                aria-label="Previous Card"
                onClick={() => {
                  setCount(() => (count === 0 ? array.length - 1 : count - 1));
                  // switchPrevCard();
                }}
              >
                <ArrowBackIosIcon fontSize="large" htmlColor="#000" />
              </IconButton>
              <div>
                {count + 1}/{array.length}
              </div>
              <IconButton
                aria-label="Next Card"
                onClick={() => {
                  setCount(() => (count < array.length - 1 ? count + 1 : 0));
                  //   switchNextCard();
                }}
              >
                <ArrowForwardIosIcon fontSize="large" htmlColor="#000" />
              </IconButton>
            </div>
          ) : (
            <div></div>
          )}
          {isAccepted === false && <div className="relative flex justify-center col-span-1" style={{ width: '100%', gap: '4px' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                width: "100%",
                whiteSpace: "nowrap",
                borderRadius: '0px',
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
              }}
              onClick={() => {
                const obj = {
                  requests: requests,
                };
                console.log('🎈', obj)
                axios
                  .patch(`${BASE}/send/acceptAllRequests`, obj)
                  .then((response) => {
                    console.log(response);
                    toast.success('All Requests Accepted Successfully for this Slot', {
                      ...toastStyle.success,
                      position: "top-center",
                      style: {
                        // marginTop:'30%',
                        border: '1px solid #713200',
                        padding: '16px',
                        color: '#fff',
                        backgroundColor: '#0e8f11'
                      }
                    });
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 3000);
                  });
              }}
            >
              Accept All
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                width: "100%", whiteSpace: "nowrap", borderRadius: '0px', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
              }}
              onClick={() => {
                const obj = {
                  requests: requests,
                };

                axios
                  .patch(`${BASE}/send/declineAllRequests`, obj)
                  .then((response) => {
                    console.log(response);
                    toast.success('All Requests Declined Successfully for this Slot', {
                      ...toastStyle.success,
                      position: "top-center",
                      style: {
                        // marginTop:'30%',
                        border: '1px solid #713200',
                        padding: '16px',
                        color: '#fff',
                        backgroundColor: '#0e8f11'
                      }
                    });
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 3000);
                  });
              }}
            >
              Decline All
            </Button>
          </div>}
        </div>
      </div>
      <hr className="my-2 border-gray-700" />
    </>
  );
};

export default Accordian;
