import React, { useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import Divider from "@mui/material/Divider";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Badge from "@mui/material/Badge";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import UserContext from "../../contexts/UserContext";

import "../Dashboard/Navbarlogedin.css";
import Bookmark from "../Dashboard/popups/Bookmark";
import {
  HOME_ROUTE,
  STUDENT_ACCEPTED_REQUESTS_ROUTE,
  STUDENT_COMPLETED_INTERVIEWS_ROUTE,
  STUDENT_DASHBOARD_ROUTE,
  STUDENT_FIND_AN_INTERVIEWER_ROUTE,
  STUDENT_SENT_REQUESTS_ROUTE,
  STUDENT_UPCOMING_INTERVIEWS_ROUTE,
  STUDENT_DECLINED_REQUESTS_ROUTE,
  STUDENT_CANCELED_INTERVIEW_ROUTE,
  STUDENT_PRECANCELLED_REQUESTS_ROUTE,
  STUDENT_RESCHEDULE_ROUTE,
  STUDENT_REPORT_ROUTE,
  STUDENT_CANCEL_ROUTE,
  STUDENT_PROFILE_FORM_ROUTE,
  SUCCESS_STORIES_ROUTE,
  SUCCESS_ADD_STORIES_ROUTE,
  STUDENT_ACCOUNT_SETTINGS_ROUTE,
  ADMIN_ADD_STUDENTS_ROUTE,
  ADMIN_CURRENT_STUDENTS_ROUTE,
  ADMIN_FEEDBACK_COMMENTS_ROUTE,
  ADMIN_MOCK_INTERVIEWS_ROUTE,
  ADMIN_PROFILE_FORM_ROUTE,
  ADMIN_INTERVIEWERS_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  ADMIN_CANCELLED_ROUTE,
  ADMIN_DECLINED_ROUTE,
  ADMIN_AUTOREJECTED_ROUTE,
  ADMIN_SUCCESS_ROUTE,
  ADMIN_REPORT_ROUTE,
} from "../../constants/routes";
import toast from "react-hot-toast";
import { toastStyle } from "../../utility/helper";
import { message } from "antd";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Navbarlogedin = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  // Profile Dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorNt, setAnchorNt] = React.useState(null);
  const openNt = Boolean(anchorNt);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNtClick = (event) => {
    setAnchorNt(event.currentTarget);
  };

  const handleNtClose = () => {
    setAnchorNt(null);
  };

  const handlePopupOpen = () => {
    setIsOpen(true);
  };

  const handlePopupClose = () => {
    setIsOpen(false);
  };

  const loggingOut = () => {
    if (localStorage.getItem("isAAuthenticated"))
      localStorage.removeItem("isAAuthenticated");
    setUser({});
    navigate(HOME_ROUTE);
  };

  useEffect(() => {
    console.log(user);
  }, [user]);

  // useEffect(() => {
  //   // window.location.reload(false);
  //   console.log("reloading...");
  // }, [navigate]);
  const changeRoute = () => {
    toast.success(`Hello`, {
      ...toastStyle.success,
    });
    return STUDENT_PROFILE_FORM_ROUTE;
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handlePopupClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="flex justify-center h-full">
          <Bookmark />
        </div>
      </Modal>
      <div className="navbarlogedin flex">
        <div className="logo">
          <Link to={HOME_ROUTE}>
            <h1 className="text-2xl font-medium">interwiu.com</h1>
          </Link>
        </div>
        <>
          <ul className="nav-list flex">
            <li>
              <NavLink
                end
                className={
                  ({ isActive }) =>
                    (true
                      ? isActive
                        ? "active hover-underline-animation hover:bg-[#e0ffff] "
                        : "hover-underline-animation hover:bg-[#e0ffff] "
                      : " ") + "nav-item "
                  // + (true ? '' : ' pointer-events-none')
                }
                onClick={(e) => {
                  if (!true) {
                    e.preventDefault();
                    toast.error("Please complete your account profile", {
                      ...toastStyle.error,
                    });
                  }
                }}
                // activeClassName="active"
                to={ADMIN_ADD_STUDENTS_ROUTE}
              >
                <div>Add Students</div>
              </NavLink>
            </li>

            <li>
              <NavLink
                end
                className={
                  ({ isActive }) =>
                    (true
                      ? isActive
                        ? "active hover-underline-animation hover:bg-[#e0ffff] "
                        : "hover-underline-animation hover:bg-[#e0ffff] "
                      : " ") + "nav-item "
                  // + (true ? '' : ' pointer-events-none')
                }
                onClick={(e) => {
                  if (!true) {
                    e.preventDefault();
                    toast.error("Please complete your account profile", {
                      ...toastStyle.error,
                    });
                  }
                }}
                // activeClassName="active"
                to={ADMIN_CURRENT_STUDENTS_ROUTE}
              >
                <div>Current Students</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                end
                className={
                  ({ isActive }) =>
                    (true
                      ? isActive
                        ? "active hover-underline-animation hover:bg-[#e0ffff] "
                        : "hover-underline-animation hover:bg-[#e0ffff] "
                      : " ") + "nav-item "
                  // + (true ? '' : ' pointer-events-none')
                }
                onClick={(e) => {
                  if (!true) {
                    e.preventDefault();
                    toast.error("Please complete your account profile", {
                      ...toastStyle.error,
                    });
                  }
                }}
                // activeClassName="active"
                to={ADMIN_INTERVIEWERS_ROUTE}
              >
                <div>Interviewers</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                end
                className={
                  ({ isActive }) =>
                    (true
                      ? isActive
                        ? "active hover-underline-animation hover:bg-[#e0ffff] "
                        : "hover-underline-animation hover:bg-[#e0ffff] "
                      : " ") + "nav-item "
                  // + (true ? '' : ' pointer-events-none')
                }
                onClick={(e) => {
                  if (!true) {
                    e.preventDefault();
                    toast.error("Please complete your account profile", {
                      ...toastStyle.error,
                    });
                  }
                }}
                // activeClassName="active"
                to={ADMIN_MOCK_INTERVIEWS_ROUTE}
              >
                <div className="flex">Mock Interviews</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                end
                className={
                  ({ isActive }) =>
                    (true
                      ? isActive
                        ? "active hover-underline-animation hover:bg-[#e0ffff] "
                        : "hover-underline-animation hover:bg-[#e0ffff] "
                      : " ") + "nav-item "
                  // + (true ? '' : ' pointer-events-none')
                }
                onClick={(e) => {
                  if (!true) {
                    e.preventDefault();
                    toast.error("Please complete your account profile", {
                      ...toastStyle.error,
                    });
                  }
                }}
                // activeClassName="active"
                to={ADMIN_FEEDBACK_COMMENTS_ROUTE}
              >
                <div>Feedback and Comments</div>
              </NavLink>
            </li>
          </ul>
          <div className="sidebar flex">
            {/* Notification Bell */}
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Notification">
                <Button
                  onClick={handleNtClick}
                  size="small"
                  sx={{ color: "black" }}
                  aria-controls={openNt ? "notification" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openNt ? "true" : undefined}
                >
                  <div
                    className="notifier rounded w-full flex justify-center border hover:bg-slate-200"
                    style={{ height: "50px" }}
                  >
                    <Badge badgeContent={4} color="primary">
                      <NotificationsIcon color="action" />
                    </Badge>
                  </div>
                </Button>
              </Tooltip>
            </Box> */}
            <Menu
              anchorEl={anchorNt}
              id="Notification"
              open={openNt}
              onClose={handleNtClose}
              onClick={handleNtClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1,
                  px: 1,
                  width: 300,
                  borderRadius: 2,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                subheader={
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontWeight: "bold",
                      marginLeft: "12px",
                    }}
                  >
                    Notifications
                  </Typography>
                }
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="John Doe"
                    secondary="You're meeting is accepted by John Doe"
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <WorkIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Work" secondary="Jan 7, 2014" />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <BeachAccessIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Vacation" secondary="July 20, 2014" />
                </ListItem>
              </List>
            </Menu>

            {/* Profile Dropdown */}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="My Profile">
                <Button
                  onClick={handleClick}
                  size="small"
                  sx={{ color: "black" }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <div className="sidebar-profile px-2 rounded border cursor-pointer hover:bg-slate-200 flex">
                    <div className="sidebar-profile-img mx-1 flex">
                      <img
                        src={user.photo || user.iLogo || require("../../images/photo.png")}
                        alt=""
                      />
                    </div>
                    <div
                      className="text-sm mx-1 flex"
                      style={{ flexDirection: "column" }}
                    >
                      <p>Me</p>
                      <i className="fa-solid fa-caret-down"></i>
                    </div>
                  </div>
                </Button>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1,
                  px: 1,
                  width: 300,
                  borderRadius: 2,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Typography>
                <div style={{display : "flex"}}>
                  <div style={{ display : "flex", flexShrink : "0", justifyContent : "center", alignItems : "center", flexWrap : "wrap"}}>
                    <img
                      src={user.iLogo}
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%",
                        display : "block",
                        float : "left",
                        marginRight : "10px"
                      }}
                      alt=""
                    />
                  </div>
                  <div>
                    <Typography
                      variant="h5"
                      component="h5"
                      sx={{ fontWeight: "bold" }}
                    >
                      {user.aFname + (user.aLname ? " " + user.aLname : "")}
                    </Typography>
                    <p>{user.iname || ""}</p>
                  </div>
                </div>
              </Typography>
              <button
                type="button"
                className="cust-btn view-profile-btn"
                onClick={() => {
                  navigate(ADMIN_DASHBOARD_ROUTE);
                }}
              >
                View Profile
              </button>
              <Divider />
              {/* <MenuItem id="dropdown-btn" onClick={handlePopupOpen}>
                <p>
                  <i className="fa-solid fa-bookmark w-5"></i> Bookmarks
                </p>
              </MenuItem> */}
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(ADMIN_CANCELLED_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'cancelled-interviews' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'cancelled-interviews' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-ban w-5"></i> Canceled Mock
                  Interviews
                </p>
              </MenuItem>
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(ADMIN_AUTOREJECTED_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'autorejected-interviews' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'autorejected-interviews' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-circle-xmark w-5"></i> Auto-Rejected
                  Requests
                </p>
              </MenuItem>
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(ADMIN_DECLINED_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'declined-interviews' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'declined-interviews' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-circle-xmark w-5"></i> Declined
                  Requests
                </p>
              </MenuItem>
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(ADMIN_REPORT_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'report-issue' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'report-issue' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-triangle-exclamation w-5"></i>{" "}
                  Report an Issue
                </p>
              </MenuItem>
              <MenuItem
                id="dropdown-btn"
                onClick={() => {
                  navigate(ADMIN_SUCCESS_ROUTE);
                }}
                style={{ background: `${location.pathname.split("/").pop() === 'success-stories' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'success-stories' ? 'black' : ''}` }}
              >
                <p>
                  <i className="fa-solid fa-trophy w-5"></i> Success Stories
                </p>
              </MenuItem>
              {/* <MenuItem id="dropdown-btn"
                onClick={() => {
                  navigate(STUDENT_ACCOUNT_SETTINGS_ROUTE);
                }}
              >
                <p>
                  <i className="fa-solid fa-gear w-5"></i> Account Settings
                </p>
              </MenuItem> */}
              <hr />
              <form>
                <button className="cust-btn logout-btn" onClick={loggingOut}>
                  <i className="fa-solid fa-arrow-right-from-bracket"></i> Log
                  Out
                </button>
              </form>
            </Menu>
            <IconButton
              aria-label="more"
              className="navLoggedInDropdownIcon"
              id="long-button"
              aria-controls={Boolean(anchorEl2) ? "long-menu" : undefined}
              aria-expanded={Boolean(anchorEl2) ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e) => setAnchorEl2(e.currentTarget)}
            >
              <MenuRoundedIcon />
            </IconButton>
            <Menu
              id="long-menu"
              className="navLoggedInDropdown"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl2}
              open={Boolean(anchorEl2)}
              onClose={() => setAnchorEl2(null)}
            >
              <Link onClick={() => { setAnchorEl2(null) }} to={ADMIN_ADD_STUDENTS_ROUTE}>
                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'add-students' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'find-an-interviewer' ? 'black' : ''}` }}>
                  <p>Add Students</p>
                </MenuItem>
              </Link>
              <Link onClick={() => { setAnchorEl2(null) }} to={ADMIN_CURRENT_STUDENTS_ROUTE}>
                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'current-students' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'find-an-interviewer' ? 'black' : ''}` }}>
                  <p>Current Students</p>
                </MenuItem>
              </Link>
              <Link onClick={() => { setAnchorEl2(null) }} to={ADMIN_INTERVIEWERS_ROUTE}>
                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'interviewers' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'find-an-interviewer' ? 'black' : ''}` }}>
                  <p className="flex">
                    Interviewers <div className="notify"></div>
                  </p>
                </MenuItem>
              </Link>
              <Link onClick={() => { setAnchorEl2(null) }} to={ADMIN_MOCK_INTERVIEWS_ROUTE}>
                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'mock-interviews' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'find-an-interviewer' ? 'black' : ''}` }}>
                  <p>Mock Interviews</p>
                </MenuItem>
              </Link>
              <Link onClick={() => { setAnchorEl2(null) }} to={ADMIN_FEEDBACK_COMMENTS_ROUTE}>
                <MenuItem style={{ background: `${location.pathname.split("/").pop() === 'feedback-comments' ? 'rgb(196, 240, 255)' : ''}`, color: `${location.pathname.split('/').pop() === 'find-an-interviewer' ? 'black' : ''}` }}>
                  <p className="flex">
                    Feedback and Comments<div className="notify"></div>
                  </p>
                </MenuItem>
              </Link>
            </Menu>
          </div>
        </>
      </div>
    </>
  );
};

export default Navbarlogedin;
<ul className="nav-list flex">
  {/* 
<li>
  {user.mobNumber===""?
  <NavLin
    className={({
      isActive}) =>  ((isActive? 'active ':'') +  "nav-item hover-underline-animation")}


    // activeClassName="active"
    to={S&trueILisActive ? 'active + (true ? ' : '+ (user.profilbg-[#0effff] eComplete ? 'UTE}
    // onCli bg-[#0effff]
      '' : ' pointer-events-none')ck={()=>{toast.success(`Hello`, {
      ...toastStyle.success,   onClick={(e) => {
        if(!true) {
          e.preventDefault();
          toast.error('Please complete your account profile', {...toastStyle.error})
        }
      }}
    });}}
  >
    <div>Find an Interviewer</div>
  </NavLink> :
    <NavLin
    className={({is
      Active}) =>  ((isActive? 'active ':'') +  "nav-item hover-underline-animation")}

 
      // activeClassName="active"
    to={STUDEN&trueNTisActive ? 'active (true ? ' ' : '(userbg-[#0effff] .profileComplete ? ' 'ROUTE}
  bg-[#0effff] // >
    
  + ' : ' pointer-events-none')<div>Find an Interviewer</div>  onClick={(e) => {
    if(!true) {
      e.preventDefault();
      toast.error('Please complete your account profile', {...toastStyle.error})
    }
  }}
  </NavLink> }
</li>

<li>
  {user.mobNumber===""?
  <NavLin
    className={({
      isActive}) =>  ((isActive? 'active ':'') +  "nav-item hover-underline-animation")}


    // activeClassName="active"
    to={S&trueILisActive ? 'active + (true ? ' : '+ (user.profilbg-[#0effff] eComplete ? 'UTE}
    // onCli bg-[#0effff]
      '' : ' pointer-events-none')ck={()=>{toast.success(`Hello`, {
      ...toastStyle.success,   onClick={(e) => {
        if(!true) {
          e.preventDefault();
          toast.error('Please complete your account profile', {...toastStyle.error})
        }
      }}
    });}}
  >
    <div>Sent Requests</div>
  </NavLink> : 
    <NavLin
    className={({is
      Active}) =>  ((isActive? 'active ':'') +  "nav-item hover-underline-animation")}

 
      // activeClassName="active"
    to={STUDEN&trueESisActive ? 'active + (true ? ' : '+ (userbg-[#0effff] .profileComplete ? '
  // >
   bg-[#0effff]  <div >
     '' : ' pointer-events-none')Sent Requests</div>   onClick={(e) => {
      if(!true) {
        e.preventDefault();
        toast.error('Please complete your account profile', {...toastStyle.error})
      }
     }}
  </NavLink>
  }
</li>

<li>
  {user.mobNumber===""?
    <NavLin
    className={({is
      Active}) =>  ((isActive? 'active ':'') +  "nav-item hover-underline-animation")}

 
      // activeClassName="active"
    to={STUDEN&trueORisActive ? 'active   + (true ' : '  + (userbg-[#0effff] .profileComplete '    onClick= bg-[#0effff]{(
     //  ? '' : ' pointer-events-none')
    )=>{toast.success(`Hello`, {
      ...toastStyle.success,   onClick={(e) => {
        if(!true) {
          e.preventDefault();
          toast.error('Please complete your account profile', {...toastStyle.error})
        }
      }}
    });}}
  >
    <div className="flex">
      Accepted Requests <div className="notify"></div>
    </div>
  </NavLink>:<NavLin
    className={({isActive}) 
    =>  ((isActive? 'active ':'') +  "nav-item hover-underline-animation")}

    // act
    iveClassName="active"
    to={STUDENT_&trueQUisActive ? 'active + (true ? ' : '+ (usbg-[#0effff] er.profileComplete ? 'E}
  //  bg-[#0effff]>
     <di
     '' : ' pointer-events-none')v className="flex">   onClick={(e) => {
      if(!true) {
        e.preventDefault();
        toast.error('Please complete your account profile', {...toastStyle.error})
      }
     }}
      Accepted Requests <div className="notify"></div>
    </div>
  </NavLink>}
</li>

{user.mobNumber===""?
<li>
  <NavLin
    className={({
      isActive}) =>  ((isActive? 'active ':'') +  "nav-item hover-underline-animation")}


    // activeClassName="active"
    to={S&trueILisActive ? 'active + (true ? ' : '+ (user.profilbg-[#0effff] eComplete ? 'UTE}
    // onCli bg-[#0effff]
      '' : ' pointer-events-none')ck={()=>{toast.success(`Hello`, {
      ...toastStyle.success,   onClick={(e) => {
        if(!true) {
          e.preventDefault();
          toast.error('Please complete your account profile', {...toastStyle.error})
        }
      }}
    });}}
  >
    <div>Upcoming Interviews</div>
  </NavLink>
</li>:<li>
  <NavLin
    className={({
      isActive}) =>  ((isActive? 'active ':'') +  "nav-item hover-underline-animation")}


    // activeClassName="active"
    to={S&trueMIisActive ? 'active (true ? ' ' : '(user.profilbg-[#0effff] eComplete ? ' 'IEWS_ROUTE}
  / bg-[#0effff]/ 
  +  ' : ' pointer-events-none')  >
    <div>Upcoming Interviews</div>   onClick={(e) => {
      if(!true) {
        e.preventDefault();
        toast.error('Please complete your account profile', {...toastStyle.error})
      }
    }}
  </NavLink>
</li>
}
{user.mobNumber===""?
  <li>
    <NavLink

      cl
      assName={({isActive}) =>  ((isActive? 'active ':'&trueitisActive ? 'acbg-[#0effff] tive true ? '' bg-[#0effff] ' : ' true ? '' 'underline-an
//  
 + ( : ' pointer-events-none')     imation")}
  onClick={(e) => {
    if(!true) {
      e.preventDefault();
      toast.error('Please complete your account profile', {...toastStyle.error})
    }
  }}
      // activeClassName="active"
      to={STUDENT_PROFILE_FORM_ROUTE}
      onClick={()=>{toast.success(`Hello`, {
        ...toastStyle.success,
      });}}
    >
      <div className="flex">
        Completed Interviews <div className="notify"></div>
      </div>
    </NavLink>
  </li> : <NavLink

      cl
      assName={({isActive}) =>  ((isActive? 'active ':'&trueitisActive ? 'activebg-[#0effff]  true ? '' ' : bg-[#0effff] 'user .profileComplete ? '' 'underline-an
//  
 + ( : ' pointer-events-none')     imation")}
      onClick={(e) => {
        if(!true) {
          e.preventDefault();
          toast.error('Please complete your account profile', {...toastStyle.error})
        }
      }}
      // activeClassName="active"
      to={STUDENT_COMPLETED_INTERVIEWS_ROUTE}
    >
      <div className="flex">
        Completed Interviews <div className="notify"></div>
      </div>
    </NavLink>
} */}
</ul>;
