const content = {
    "Payment Policy": `###**Payment from Students**\nThe price per mock interview or mentorship session (with feedback) displayed to a student contains a major component as professional's fee and a minor component as service fee of interwiu.com (Fyzen Career Solutions Pvt Ltd). A student needs to pay the displayed price to interwiu.com (Fyzen Career Solutions) as soon as his/her mock interview request or mentorship session request is accepted by a professional to confirm the respective mock interview or mentorship session (with feedback). However, the students can use the Coupon Codes (provided by interwiu.com, their Institute, or obtained by referring a friend, or getting referred by a friend, or taking Duo/Trio packs) to avail partial or full discounts.     
    \n\n###**Payment to Professionals**\nThe professional's fee is credited to a professional by interwiu.com (Fyzen Career Solutions) within a window of 7 hours for a given mock interview or mentorship session after it is conducted successfully as per the schedule subject to the below conditions. 
    \n\n ● The mock interview duration or mentorship session duration is no less than 35 minutes for an extended session, and 25 minutes for a standard session
    \n\n ● The professional has successfully submitted the Feedback Form (Feedback Report) for the respective mock interview or mentorship session by accessing it either from the top navigation bar of that mock interview's or or mentorship session's (video call's) window, or from the card of that mock interview or mentorship session lying on the [Completed Sessions] page (the card can be found only after completion of the respective mock interview slot or mentorship session slot) 
    \n\n ● No issue(s) has(have) been reported by the student/professional about the respective mock interview or mentorship session  
    `,
    "Rescheduling Policy": `This policy applies to only confirmed (upcoming) mock interviews and mentorship sessions. A given mock interview or mentorship session can be rescheduled only once. Also, only students can initiate a reschedule request.\n\nA student can send a reschedule request to his/her interviewer/mentor for a confirmed (upcoming) mock interview or mentorship session only if the time left for that mock interview or mentorship session is more than 2 hours. If the student realizes during the last 2 hours before a mock interview or mentorship session that he/she would not be able to appear for it, he/she can cancel it and avail 10% Flat Discount on his/her next mock interview booking or mentorship session booking. Once the professional has received a reschedule request sent by the student, it's completely the professional's choice whether to accept it. If the professional accepts it, the concerned mock interview or mentorship session is conducted as per the updated schedule using the same meeting link and without any extra payment at either end. The original (previous) time-slot of the concerned mock interview or mentorship session is vacated at professional's end, and another student can send a mock interview request or mentorship session request for the same. But if the professional does not accept it (i.e., either declines it or leaves it unaddressed), either the mock interview or mentorship session can be conducted as per the original schedule, or it can be canceled by the student. In this case, if the student does not cancel the mock interview or mentorship session, interwiu.com treats it as a successfully completed mock interview or mentorship session irrespective of whether the mock interview or mentorship session has actually taken place.
    `,
    "Terms and Conditions": `This policy applies in context of the already confirmed (thus “upcoming”) mock interviews. There are four major clauses in this policy, which are listed below. a. If a student cancels a mock interview anytime during the last 24 hours preceding the respective mock interview, the student will get no refund for the same. b. If a student cancels a mock interview anytime between 24 and 48 hours preceding the respective mock interview, the student will get only a 50% refund for the same. c. If a student cancels a mock interview anytime before the last 48 hours preceding the respective mock interview, the student will get a complete refund for the same. d. If an interviewer cancels a mock interview, the student will get a complete refund for the same irrespective of the time left for the mock interview.`,
    "Privacy Policy": `This policy applies in context of the already confirmed (thus “upcoming”) mock interviews. There are four major clauses in this policy, which are listed below. a. If a student cancels a mock interview anytime during the last 24 hours preceding the respective mock interview, the student will get no refund for the same. b. If a student cancels a mock interview anytime between 24 and 48 hours preceding the respective mock interview, the student will get only a 50% refund for the same. c. If a student cancels a mock interview anytime before the last 48 hours preceding the respective mock interview, the student will get a complete refund for the same. d. If an interviewer cancels a mock interview, the student will get a complete refund for the same irrespective of the time left for the mock interview.`,
    "Why interwiu.com?": `The interwiu.com connects the professionals from premium companies with the students using a filter-based smart match-making algorithm wherein the user data is kept absolutely private and secured with all the possible means. The unlimited mock interviews or mentorship sessions are offered to the students at highly affordable rates.`,
    "Report an Issue Policy": `An issue can be reported about a mock interview or mentorship session within a window of only 6 hours after the slot of that mock interview or mentorship session is over. If no issue is reported by the student/professional within these 6 hours, the mock interview or mentorship session is considered as a successfully conducted mock interview or mentorship session, and the payment of the concerned professional is processed during the subsequent payout cycle (subject to other conditions outlined in the Payment Policy).\n\nIf a user wants to report an issue about a mock interview or mentorship session but has already missed this window of 6 hours, he/she can contact us directly using the “Contact Us” page.
    `,
    "Why Mock Interviews & Mentorship?": `The mock interviews or mentorship sessions provide a very realistic experience to the students about how their real placement or internship interviews are going to be. The students get to feel the heat of actual placement or internship interviews in the mock interviews, which are constituted with all the relevant questions in a well structured format. The mock interviews are structured exactly like real placement or internship interviews in every context such as kind/type, level, and number of questions asked; environment; etc. The feedback provided by the interviewer with each mock interview session will help the students understand and rectify their mistakes, realize what went right/wrong, prepare better, improve the performance in subsequent mock interviews, and thus ultimately enhance the odds of cracking the real placement or internship interviews in a highly competitive environment. They get to practice answering some of the most expected questions, and get to learn the better ways of answering the same through the interviewers' feedback.`,
    "Cancellation Policy": `This policy applies to only confirmed (upcoming) mock interviews and mentorship sessions. A student or a professional, anyone can cancel a mock interview or mentorship session. There are four major clauses in this policy, which are listed below.
    \n\n ● If a student cancels a mock interview or mentorship session anytime during the last 6 hours preceding the respective mock interview or mentorship session, the student will get a coupon code using which he/she can avail 10% flat discount on his/her next mock interview or mentorship session booking, and the respective professional will get 75% of his/her professional fee.
    \n\n ● If a student cancels a mock interview or mentorship session anytime between 6 and 12 hours preceding the respective mock interview or mentorship session, the student will get a coupon code using which he/she can avail 25% flat discount on his/her next mock interview or mentorship session booking, and the respective professional will get 50% of his/her professional fee.
    \n\n ● If a student cancels a mock interview or mentorship session anytime before the last 12 hours preceding the respective mock interview or mentorship session, the student will get a coupon code using which he/she can avail 50% flat discount on his/her next mock interview or mentorship session booking, and the respective professional will get 0% of his/her professional fee.
    \n\n ● If a professional cancels a mock interview or mentorship session anytime before its scheduled time, the student will get a coupon code using which he/she can avail 100% flat discount on his/her next mock interview or mentorship session booking, and the respective professional will get 0% of his/her professional fee.
    `,
    "Guidelines for Students": `● Please join the Mock Interview Link or Mentorship Session Link before the scheduled time of your concerned mock interview or mentorship session, and in case you experience any issues in getting connected with your interviewer/mentor, just disconnect and connect again
    \n\n ● In addition to the verbal feedback that you receive from your interviewers/mentors during the mock interview sessions and mentorship sessions, please find the detailed Feedback Report along with the Action Items for Improvement (in case they are there) in a written format from the page of [Completed Sessions] also (for extended sessions (45 minutes)) 
    \n\n ● Please keep your video ON during the entire mock interview or mentorship session even if your interviewer/mentor has kept his/her video OFF (this practice will be helpful for you to gain confidence)
    \n\n ● Please ensure a noiseless place for mock interview or mentorship session with uninterrupted power supply, internet connection, and proper lighting
    \n\n ● Please keep a notepad with you so that you can write something anytime during the mock interview or mentorship session, if needed or asked to
    \n\n ● Please be ready with all your projects along with their code opened in the respective code editors on your local machine, so that they can be executed during the mock interview or mentorship session for a live demo
    \n\n ● You should be able to explain your projects along with their respective codes
    \n\n ● If there is anything else which you would like to show/present to your interviewer/mentor, please keep the same ready with you. This will help the interviewer/mentor in making the session more productive and time efficient.
    \n\n ● Please do not ask for any internal or confidential information from your interviewers/mentors about their present and/or past companies
    \n\n ● Please rate and review your experience after each mock interview or mentorship session
    `,
    "Guidelines for Professionals": `● Please join the Mock Interview Link or Mentorship Session Link before the scheduled time of your concerned mock interview or mentorship session, and in case you experience any issues in getting connected with the student, just disconnect and connect again
    \n\n ● Please ensure that the minimum duration of the extended mock interview or mentorship session (with feedback) is 45 minutes. You can exceed the time-frame of 45 minutes (if you are available) in order to improve the quality of the mock interview or mentorship session along with feedback (but there is no extra payment for the same). Please note that such an extension is not provided for standard sessions (30 minutes)
    \n\n ● In addition to the verbal feedback that you provide to the students during the mock interview or mentorship session, please submit the Feedback along with the Action Items for Improvement in a written format for each mock interview and mentorship session using the [Send Feedback] button accessible either from the top navigation bar of the given mock interview's or mentorship session's (video call's) window, or from the card of the given mock interview or mentorship session lying on the [Completed Sessions] page (in case of extended session (45 minutes)). This card can be found only after completion of the respective mock interview slot or mentorship session slot
    \n\n ● Please structure the mock interviews exactly like real placement or internship interviews in every context such as type/kind, level, and number of questions asked; inclusion of stress rounds and HR questions (in addition to the Profile-based questions); environment; etc. Please provide detailed feedback during/after the mock interview on every possible aspect like a student's incorrect or partially correct answers, lack of confidence, lack of clarity, body language, etc. You can convey what went wrong/right, and how the student can improve the performance. Also, you can suggest better ways of answering certain questions asked by you to which the student’s response is unsatisfactory
    \n\n ● Please ensure a noiseless place for mock interview or mentorship session with uninterrupted power supply, internet connection, and proper lighting
    \n\n ● Please keep your video ON during the entire duration of all the mock interviews and mentorship sessions
    \n\n ● Please take the mock interviews and mentorship sessions based on the concerned profiles, without connecting them with your present/past companies
    \n\n ● Please do not disclose any internal or confidential information to a student about your present/past companies. If the student insists on this front even after your denial, please report an issue about that mock interview or mentorship session within a window of 6 hours after that
    `,
    "Guidelines for Institute Admins": `● Please note that an account of a given Institute at interwiu.com can have any number of Admins, categorized as “family of admins”. The very first person of the family, who creates the account of a given Institute at interwiu.com, is considered as the Master Admin who has the exclusive adding/editing rights for a few things like Name, Logo, Contact Details, and web/LinkedIn URL of the institute. He/she can add other admins in the family, who can further add more admins to the family from their end, and all these admins in the family have similar adding/editing rights with regards to the information in the Institute Account of a given institute at interwiu.com 
    \n\n ● Each admin can add the students to the Institute Account of a given institute, and all the added students lie in one place of the institute account. Every admin can view all the students, and can make the payment and generate the coupon codes for the students added by himself/herself as well as any other admin(s) of the respective institute account  
    \n\n ● Please add all the branches/departments of your institute while creating the account of your institute at interwiu.com or later on by visiting and editing your institute profile. The students will be able to pick a branch (to which they belong) while registering (creating their personal account) at interwiu.com only from the list of branches added by the admin(s) of their institute. Notably, any admin can add the branches. 
    \n\n ● Please add the data of students (Name, Gmail address, and WhatsApp Number) as a .csv file only
    \n\n ● The data on the webpages of Institute Admins at interwiu.com takes some time to be loaded unlike students’ and professionals’ interfaces, because of a lot of processing needed for the same at our backend. So, please wait patiently for the same
    \n\n ● The data of mock interviews and mentorship sessions, feedback and comments, etc. belonging to all the registered students of a given institute is displayed on the respective pages which are equally visible to all the admins of the institute account of that institute
    \n\n ● The students of a given institute are allowed to exchange and thus use each other's coupon codes (provided by the institute admin(s) or interwiu.com) for booking discounted mock interviews and mentorship sessions. But someone who is not a student of the given institute (i.e., whose Gmail address has never been “added” by any of the institute admins of the respective institute) will never be able to use a coupon code which has been provided to a student of that institute (i.e., whose Gmail address has been “added” by any of the institute admins of the respective institute)
    `
};
export default content;