// Interviewer Profile Form

import React from "react";
import { useState, useEffect } from "react";
//import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import "./InterviewerProfileForm.css";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Navbarlogedin from "../Dashboard/Navbarlogedin";
// import TimePicker from "rc-time-picker";
// import "rc-time-picker/assets/index.css";
import moment from "moment";
import { TimePicker, Button, Calendar } from "antd";
// import "antd/dist/antd.css";
import locale from "antd/es/date-picker/locale/de_DE";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const dayData = [
  { name: "Monday" },
  { name: "Tuesday" },
  { name: "Wednesday" },
  { name: "Thursday" },
  { name: "Friday" },
  { name: "Saturday" },
  { name: "Sunday" },
];

const InterviewerProfileFormOld = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  // let navigate = useNavigate();

  // const [expanded, setExpanded] = useState(true);

  // const showCheckboxes = () => {
  //   var checkboxes = document.getElementById("checkboxes");
  //   if (expanded) {
  //     checkboxes.style.display = "block";
  //     setExpanded(false);
  //   } else {
  //     checkboxes.style.display = "none";
  //     setExpanded(true);
  //   }
  // };

  const [days, setDays] = useState([]);
  // const arrayDays = JSON.stringify(days);
  // console.log(arrayDays);
  useEffect(() => {
    setDays(dayData);
  }, []);

  const handleDaysChange = (e) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempDay = days.map((day) => {
        return { ...day, isChecked: checked };
      });
      setDays(tempDay);
    } else {
      let tempDay = days.map((day) =>
        day.name === name ? { ...day, isChecked: checked } : day
      );
      setDays(tempDay);
    }
  };

  // const [time, setTime] = useState({});

  // const handleTimeChange = (e) => {
  //   const timing = e.target.id;
  //   setTime({ ...time, [timing]: e.target.value });
  // };

  const [list, setList] = useState([]);

  const [inputs, setInputs] = useState({
    dfname: "",
    dlname: "",
    email: "",
    mobNumber: "",
    message: "",
    org: "",
    location: "",
    profilePreference: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      dfname: inputs.dfname,
      dlname: inputs.dlname,
      email: inputs.email,
      mobNumber: inputs.mobNumber,
      org: inputs.org,
      location: inputs.location,
      profilePreference: inputs.profilePreference,
    };
  };
  const now = moment().hour(0).minute(0);

  const [timeSlot, setTimeSlot] = useState([]);
  const [slots, setSlots] = useState(0);
  console.log(
    moment("10:30 AM", "hh:mm A").add(150, "minutes").format("hh:mm A")
  );
  const [selectedTime, setSelectedTime] = useState("00:00:01");
  const getTimeSlots = (begin, number) => {
    var corr = moment(begin).format("hh:mm a");
    var hour1 = corr.hour();
    if (
      hour1 != 0 &&
      hour1 != 10 &&
      hour1 != 11 &&
      hour1 != 12 &&
      hour1 != 22 &&
      hour1 != 23
    ) {
      var corrString = corr.format("h:mm a");
      corrString = "0" + corrString;
    } else {
      var corrString = corr.format("h:mm a");
    }
    var end = corr.add(30 * number, "minutes");
    var hour2 = end.hour();
    if (
      hour2 != 0 &&
      hour2 != 10 &&
      hour2 != 11 &&
      hour2 != 12 &&
      hour2 != 22 &&
      hour2 != 23
    ) {
      var endString = end.format("h:mm a");
      endString = "0" + endString;
    } else {
      var endString = end.format("h:mm a");
    }
    setTimeSlot([]);

    if (corrString.includes("pm") && endString.includes("am")) {
      setTimeSlot((timeSlot) => [...timeSlot, corrString + " - 11:59 pm"]);
      setTimeSlot((timeSlot) => [...timeSlot, "12:00 am - " + endString]);
    } else {
      setTimeSlot((timeSlot) => [...timeSlot, corrString + " - " + endString]);
    }
    // console.log(timeSlot);
  };
  const [showPref, setShowPref] = useState(false);
  const tooltipText = () => {
    var string =
      "No. of Mock Interviews which you can take in one go, one after another, ";
    string += moment(selectedTime).format("h:mm a") + " onwards";
    return string;
  };
  const selectedSlots = () => {
    var string = "Selected Time Slot";
    if (timeSlot?.length <= 1) {
      string += ":";
    } else {
      if (timeSlot[timeSlot?.length - 1].includes("12:00 am - 12:00 am")) {
        string += ":";
      } else {
        string += "s:";
      }
    }
    return string;
  };
  const [editDelete1, setEditDelete1] = useState(false);
  const [editDelete2, setEditDelete2] = useState(false);
  const [editDelete3, setEditDelete3] = useState(false);
  const [editDelete4, setEditDelete4] = useState(false);
  const [editDelete5, setEditDelete5] = useState(false);
  const [editDelete6, setEditDelete6] = useState(false);
  const [editDelete7, setEditDelete7] = useState(false);

  const [delPop, setDelPop] = useState(false);

  const [monArr, setMonArr] = useState([]);
  const [tuesArr, setTuesArr] = useState([]);
  const [wedArr, setWedArr] = useState([]);
  const [thursArr, setThursArr] = useState([]);
  const [friArr, setFriArr] = useState([]);
  const [satArr, setSatArr] = useState([]);
  const [sunArr, setSunArr] = useState([]);

  // Data Array
  const [editDel, setEditDel] = useState({
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });
  const [daysArr, setDaysArr] = useState({
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  });
  // --------

  useEffect(() => {
    console.log("Slot is now: ", slots);
    if (selectedTime != "00:00:01" && slots != 0) {
      getTimeSlots(selectedTime, slots);
    }
  }, [slots]);
  useEffect(() => {
    console.log("Selected Time is now: ", selectedTime);
    if (selectedTime != "00:00:01" && slots != 0) {
      getTimeSlots(selectedTime, slots);
    }
  }, [selectedTime]);

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleButtonClick = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
      setIsAlertVisible(false);
    }, 5000);
  };
  const [showEx, setShowEx] = useState(false);

  const errorMessage = () => {
    if (
      !days.some((day) => day.isChecked === true) &&
      selectedTime == "00:00:01" &&
      slots == 0
    ) {
      return (
        <div className="alert-inner">
          Please select the [Day(s)], [From Time] and [Number of Slots] before
          saving!
        </div>
      );
    } else if (
      !days.some((day) => day.isChecked === true) &&
      selectedTime == "00:00:01"
    ) {
      return (
        <div className="alert-inner">
          Please select the [Day(s)] and [From Time] before saving!
        </div>
      );
    } else if (!days.some((day) => day.isChecked === true) && slots == 0) {
      return (
        <div className="alert-inner">
          Please select the [Day(s)] and [Number of Slots] before saving!
        </div>
      );
    } else if (selectedTime == "00:00:01" && slots == 0) {
      return (
        <div className="alert-inner">
          Please select the [From Time] and [Number of Slots] before saving!
        </div>
      );
    } else if (!days.some((day) => day.isChecked === true)) {
      return (
        <div className="alert-inner">
          Please select the [Day(s)] on which you are available before saving!
        </div>
      );
    } else if (selectedTime == "00:00:01") {
      return (
        <div className="alert-inner">
          Please select the [From Time] before saving!
        </div>
      );
    } else if (slots == 0) {
      return (
        <div className="alert-inner">
          Please select the [Number of Slots] before saving!
        </div>
      );
    }
  };
  const [buttonPress, setButtonPress] = useState(false);

  const fetchMon = () => {
    if (
      days.some((e) => e.name === "Monday" && e.isChecked === true) &&
      days.some((e) => e.name === "Sunday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      monArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();
      console.log(ArrUnsort1);
      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      monArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();
      console.log(ArrUnsort2);
      setMonArr([]);
      console.log(monArr?.length);
      setMonArr((monArr) => [...monArr, ...ArrUnsort1]);
      setMonArr((monArr) => [...monArr, ...ArrUnsort2]);

      console.log(monArr);
    } else if (days.some((e) => e.name === "Monday" && e.isChecked === true)) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -") && timeSlot?.length == 1) {
          ArrUnsort1.push(e);
        }
      });
      monArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();
      console.log(ArrUnsort1);
      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      monArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();
      console.log(ArrUnsort2);
      setMonArr([]);
      console.log(monArr?.length);
      setMonArr((monArr) => [...monArr, ...ArrUnsort1]);
      setMonArr((monArr) => [...monArr, ...ArrUnsort2]);
      console.log(monArr);
    } else if (
      days.some((e) => e.name === "Sunday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      monArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();
      monArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();
      setMonArr([]);
      setMonArr((monArr) => [...monArr, ...ArrUnsort1]);
      setMonArr((monArr) => [...monArr, ...ArrUnsort2]);
    }
  };
  const fetchTues = () => {
    if (
      days.some((e) => e.name === "Tuesday" && e.isChecked === true) &&
      days.some((e) => e.name === "Monday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      tuesArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      tuesArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setTuesArr([]);

      setTuesArr((tuesArr) => [...tuesArr, ...ArrUnsort1]);
      setTuesArr((tuesArr) => [...tuesArr, ...ArrUnsort2]);
    } else if (days.some((e) => e.name === "Tuesday" && e.isChecked === true)) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -") && timeSlot?.length == 1) {
          ArrUnsort1.push(e);
        }
      });
      tuesArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      tuesArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setTuesArr([]);

      setTuesArr((tuesArr) => [...tuesArr, ...ArrUnsort1]);
      setTuesArr((tuesArr) => [...tuesArr, ...ArrUnsort2]);
    } else if (
      days.some((e) => e.name === "Monday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      tuesArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();
      tuesArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();
      setTuesArr([]);
      setTuesArr((tuesArr) => [...tuesArr, ...ArrUnsort1]);
      setTuesArr((tuesArr) => [...tuesArr, ...ArrUnsort2]);
    }
  };
  const fetchWed = () => {
    if (
      days.some((e) => e.name === "Wednesday" && e.isChecked === true) &&
      days.some((e) => e.name === "Tuesday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      wedArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      wedArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setWedArr([]);

      setWedArr((wedArr) => [...wedArr, ...ArrUnsort1]);
      setWedArr((wedArr) => [...wedArr, ...ArrUnsort2]);
    } else if (
      days.some((e) => e.name === "Wednesday" && e.isChecked === true)
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -") && timeSlot?.length == 1) {
          ArrUnsort1.push(e);
        }
      });
      wedArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      wedArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setWedArr([]);

      setWedArr((wedArr) => [...wedArr, ...ArrUnsort1]);
      setWedArr((wedArr) => [...wedArr, ...ArrUnsort2]);
    } else if (
      days.some((e) => e.name === "Tuesday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      wedArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();
      wedArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();
      setWedArr([]);
      setWedArr((wedArr) => [...wedArr, ...ArrUnsort1]);
      setWedArr((wedArr) => [...wedArr, ...ArrUnsort2]);
    }
  };
  const fetchThurs = () => {
    if (
      days.some((e) => e.name === "Thursday" && e.isChecked === true) &&
      days.some((e) => e.name === "Wednesday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      thursArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      thursArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setThursArr([]);

      setThursArr((thursArr) => [...thursArr, ...ArrUnsort1]);
      setThursArr((thursArr) => [...thursArr, ...ArrUnsort2]);
    } else if (
      days.some((e) => e.name === "Thursday" && e.isChecked === true)
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -") && timeSlot?.length == 1) {
          ArrUnsort1.push(e);
        }
      });
      thursArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      thursArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setThursArr([]);

      setThursArr((thursArr) => [...thursArr, ...ArrUnsort1]);
      setThursArr((thursArr) => [...thursArr, ...ArrUnsort2]);
    } else if (
      days.some((e) => e.name === "Wednesday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      thursArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();
      thursArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();
      setThursArr([]);
      setThursArr((thursArr) => [...thursArr, ...ArrUnsort1]);
      setThursArr((thursArr) => [...thursArr, ...ArrUnsort2]);
    }
  };
  const fetchFri = () => {
    if (
      days.some((e) => e.name === "Friday" && e.isChecked === true) &&
      days.some((e) => e.name === "Thursday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      friArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      friArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setFriArr([]);

      setFriArr((friArr) => [...friArr, ...ArrUnsort1]);
      setFriArr((friArr) => [...friArr, ...ArrUnsort2]);
    } else if (days.some((e) => e.name === "Friday" && e.isChecked === true)) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -") && timeSlot?.length == 1) {
          ArrUnsort1.push(e);
        }
      });
      friArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      friArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setFriArr([]);

      setFriArr((friArr) => [...friArr, ...ArrUnsort1]);
      setFriArr((friArr) => [...friArr, ...ArrUnsort2]);
    } else if (
      days.some((e) => e.name === "Thursday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      friArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();
      friArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();
      setFriArr([]);
      setFriArr((friArr) => [...friArr, ...ArrUnsort1]);
      setFriArr((friArr) => [...friArr, ...ArrUnsort2]);
    }
  };
  const fetchSat = () => {
    if (
      days.some((e) => e.name === "Saturday" && e.isChecked === true) &&
      days.some((e) => e.name === "Friday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      satArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      satArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setSatArr([]);

      setSatArr((satArr) => [...satArr, ...ArrUnsort1]);
      setSatArr((satArr) => [...satArr, ...ArrUnsort2]);
    } else if (
      days.some((e) => e.name === "Saturday" && e.isChecked === true)
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -") && timeSlot?.length == 1) {
          ArrUnsort1.push(e);
        }
      });
      satArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      satArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setSatArr([]);

      setSatArr((satArr) => [...satArr, ...ArrUnsort1]);
      setSatArr((satArr) => [...satArr, ...ArrUnsort2]);
    } else if (
      days.some((e) => e.name === "Friday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      satArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();
      satArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();
      setSatArr([]);
      setSatArr((satArr) => [...satArr, ...ArrUnsort1]);
      setSatArr((satArr) => [...satArr, ...ArrUnsort2]);
    }
  };
  const fetchSun = () => {
    if (
      days.some((e) => e.name === "Sunday" && e.isChecked === true) &&
      days.some((e) => e.name === "Saturday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      sunArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      sunArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setSunArr([]);

      setSunArr((sunArr) => [...sunArr, ...ArrUnsort1]);
      setSunArr((sunArr) => [...sunArr, ...ArrUnsort2]);
    } else if (days.some((e) => e.name === "Sunday" && e.isChecked === true)) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -") && timeSlot?.length == 1) {
          ArrUnsort1.push(e);
        }
      });
      sunArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();

      timeSlot.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      sunArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();

      setSunArr([]);

      setSunArr((sunArr) => [...sunArr, ...ArrUnsort1]);
      setSunArr((sunArr) => [...sunArr, ...ArrUnsort2]);
    } else if (
      days.some((e) => e.name === "Saturday" && e.isChecked === true) &&
      timeSlot?.length > 1 &&
      timeSlot[timeSlot?.length - 2].includes("- 11:59 pm")
    ) {
      var ArrUnsort1 = [];
      var ArrUnsort2 = [];
      timeSlot.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      sunArr.some((e) => {
        if (e.includes("am -")) {
          ArrUnsort1.push(e);
        }
      });
      ArrUnsort1.sort();
      sunArr.some((e) => {
        if (e.includes("pm -")) {
          ArrUnsort2.push(e);
        }
      });
      ArrUnsort2.sort();
      setSunArr([]);
      setSunArr((sunArr) => [...sunArr, ...ArrUnsort1]);
      setSunArr((sunArr) => [...sunArr, ...ArrUnsort2]);
    }
  };
  useEffect(() => {
    fetchMon();
    fetchTues();
    fetchWed();
    fetchThurs();
    fetchFri();
    fetchSat();
    fetchSun();
  }, []);

  const tileDisabled = ({ activeStartDate, date, view }) => {
    return date < new Date();
  };

  const [excep, setExcep] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openEdit = Boolean(anchorEl);
  const handleEditClick = (event) => {
    console.log(Boolean(event.currentTarget.parentElement));
    setAnchorEl(event.currentTarget);
  };
  const handleEditClose = () => {
    setAnchorEl(null);
  };

  const [oEdit, setOEdit] = useState(false);

  return (
    <>
      <div className="profile-form">
        <h1>Create An Account As A Professional</h1>
        <div>
          <IconButton
            id="edit-del-button"
            onClick={() => {
              document.getElementById("edit-del").style.display = "unset";
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="edit-del"
            onClose={(e) => {
              // e.target.setAttribute("open", false);
              document.getElementById("edit-del").style.display = "none";
              // setOEdit(false);
            }}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem
              key="edit"
              onClick={(e) => {
                setOEdit(false);
                console.log("editing...");
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              key="delete"
              onClick={() => {
                setOEdit(false);
                console.log("Deleting...");
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </div>
        {/* <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={openEdit ? "long-menu" : undefined}
            aria-expanded={openEdit ? "true" : undefined}
            aria-haspopup="true"
            onClick={() => {
              setOEdit(!oEdit);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={null}
            open={oEdit}
            onClose={(e) => {
              setOEdit(false);
            }}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem
              key="edit"
              onClick={(e) => {
                setOEdit(false);
                console.log("editing...");
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              key="delete"
              onClick={() => {
                setOEdit(false);
                console.log("Deleting...");
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </div> */}
        <form onSubmit={handleSubmit}>
          {/* Profile Form Container */}

          <div className="profile-form-cont">
            {/* Col-70 */}

            <div className="profile-form-text">
              {/* First Name and Last Name */}

              <div className="name cust-row flex">
                <div className="element">
                  <label htmlFor="firstname">
                    First Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="dfname"
                    required
                    value={inputs.dfname || ""}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                </div>
                <div className="element">
                  <label htmlFor="lastname">
                    Last Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="dlname"
                    value={inputs.dlname}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                </div>
              </div>

              {/* Email and Mobile Number */}

              <div className="email cust-row flex">
                <div className="element">
                  <label htmlFor="email">
                    Email<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={inputs.email}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                </div>
                <div className="element">
                  <label htmlFor="number">
                    Mobile Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    name="mobNumber"
                    value={inputs.mobNumber}
                    onChange={handleChange}
                    placeholder="Mobile Number"
                  />
                </div>
              </div>

              <div className="email cust-row flex">
                <div className="element">
                  <label htmlFor="org">
                    Organisation<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="org"
                    value={inputs.org}
                    onChange={handleChange}
                    placeholder="Organisation"
                  />
                </div>
                <div className="element">
                  <label htmlFor="location">
                    Location/City<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="location"
                    name="location"
                    value={inputs.location}
                    onChange={handleChange}
                    placeholder="Location/City"
                  />
                </div>
              </div>

              {/* Profile Preference */}

              <div className="profilepref cust-row flex">
                <div className="element">
                  <label htmlFor="profilepref">Profile Preferences</label>
                  <select
                    name="profilepref"
                    value={""}
                    onChange={(e) => {
                      handleChange(e);
                      setList((item) => [...item, e.target.value]);
                    }}
                    id="profile-pref"
                  >
                    <option value="" disabled hidden>
                      Select Profile Preferences
                    </option>
                    <option value="Data Science">Data Science</option>
                    <option value="Software">Software</option>
                    <option value="Banking and Finance">
                      Banking and Finance
                    </option>
                    <option value="Consulting">Consulting</option>
                    <option value="Analytics">Analytics</option>
                  </select>
                </div>
                <div className="profile-pref-list">
                  {list.map((item) => {
                    return (
                      <div className="profile-pref-item">
                        <p>{item}</p>{" "}
                        <button
                          type="button"
                          className="cust-btn propref-btn"
                          onClick={(e) => {
                            const items = list.filter(
                              (item) =>
                                item !==
                                e.target.parentElement.childNodes[0].innerHTML
                            );
                            setList(items);
                          }}
                        >
                          x
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Time Preference */}

              <div className="time-preference">
                <div className="element">
                  <p>General Day and Time Preferences for Sessions</p>
                  <div className="element-item">
                    <input type="checkbox" name="preference" id="preference" />
                    <label htmlFor="preference"> No Specific Preferences</label>
                  </div>
                  <div
                    className="exception"
                    style={{
                      display:
                        monArr?.length >= 1 ||
                        tuesArr?.length >= 1 ||
                        wedArr?.length >= 1 ||
                        thursArr?.length >= 1 ||
                        friArr?.length >= 1 ||
                        satArr?.length >= 1 ||
                        sunArr?.length >= 1
                          ? "flex"
                          : "none",
                      marginTop: "20px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setShowEx(!showEx);
                      }}
                    >
                      Add Exceptions
                    </button>
                    <div
                      className="exdiv"
                      style={{ display: showEx ? "flex" : "none" }}
                    >
                      <Calendar
                        className="calendar"
                        tileDisabled={tileDisabled}
                        onSelect={(e) => {
                          setExcep(...excep, moment(e).format("DD/MM/YYYY"));
                          console.log(excep);
                        }}
                      />
                      <button
                        style={{ backgroundColor: "blue", color: "#ffffff" }}
                      >
                        Save
                      </button>
                    </div>
                    <button type="button">Edit Availability</button>
                  </div>
                  {/* <div className="pref-item">
                    <ul>
                      {arrayDays !== []
                        ? arrayDays.map((item) => {
                            return <li>{item}</li>;
                          })
                        : null}
                      <li>
                        Monday - 12:00 to 13:00{" "}
                        <button
                          type="button"
                          onClick={(e) => {
                            e.target.parentElement.parentElement.remove();
                          }}
                          className="cust-btn trash-btn"
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      </li>
                    </ul>
                  </div> */}
                  <button
                    type="button"
                    className="cust-btn add-time-btn"
                    onClick={() => {
                      const addPref = document.querySelector(".popup");
                      addPref.classList.remove("hide");
                    }}
                    style={{ display: showPref ? "none" : "" }}
                  >
                    Add Time Preference
                  </button>
                  <div
                    className="preferences"
                    style={{ display: showPref ? "" : "none" }}
                  >
                    <div
                      className="monpref"
                      style={{
                        display: monArr?.length >= 1 ? "flex" : "none",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h5 style={{ fontSize: "18px", fontWeight: "500" }}>
                        Monday
                      </h5>
                      <ul>
                        {monArr.map((slot) => (
                          <div
                            style={{
                              width: "170px",
                              height: "35px",
                              borderRadius: "17.5px",
                              backgroundColor: "#7FFF00",
                              color: "#000000",
                              padding: "10px 5px 10px 12px",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              marginBottom: "5px",
                              border: "1px solid black",
                              fontSize: "15px",
                            }}
                            key={slot}
                          >
                            {slot}
                            <div style={{ marginRight: "-10px" }}>
                              <MoreVertIcon
                                style={{ color: "#000000", cursor: "pointer" }}
                                onClick={() => {
                                  setEditDelete1(!editDelete1);
                                }}
                              />
                              <div
                                className="editdelete"
                                style={{
                                  display: editDelete1 ? "flex" : "none",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    const addPref =
                                      document.querySelector(".popup");
                                    addPref.classList.remove("hide");
                                    setSelectedTime(
                                      moment(slot.substr(0, 7), "h:mm a")
                                    );

                                    var start = moment(
                                      slot.substr(0, 7),
                                      "h:mm a"
                                    );
                                    var end = moment(
                                      slot.substr(11, 18),
                                      "h:mm a"
                                    );
                                    var duration = moment.duration(
                                      end.diff(start)
                                    );
                                    var mins = parseInt(duration.asMinutes());
                                    if (mins % 30 == 0) {
                                      setSlots(mins / 30);
                                    } else {
                                      setSlots(mins / 30 + 1);
                                    }

                                    setTimeSlot([]);
                                    days.forEach((day) => {
                                      day.isChecked = false;
                                    });
                                    days.some((day) => {
                                      if (day.name == "Monday") {
                                        day.isChecked = true;
                                      }
                                    });
                                    monArr.splice(monArr.indexOf(slot), 1);
                                  }}
                                >
                                  Edit
                                </div>
                                <div
                                  onClick={() => {
                                    setDelPop(!delPop);
                                  }}
                                >
                                  Delete
                                  <div
                                    className="delPopup"
                                    style={{
                                      display: delPop ? "block" : "none",
                                    }}
                                  >
                                    <span>Are You Sure?</span>
                                    <div className="buttons">
                                      <button
                                        onClick={() => {
                                          for (
                                            var i = 0;
                                            i < monArr?.length;
                                            i++
                                          ) {
                                            if (monArr[i] == slot) {
                                              var spliced = monArr.splice(i, 1);
                                            }
                                          }
                                          setDelPop(false);
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        onClick={() => {
                                          setDelPop(false);
                                        }}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="tuespref"
                      style={{
                        display: tuesArr?.length >= 1 ? "flex" : "none",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h5 style={{ fontSize: "18px", fontWeight: "500" }}>
                        Tuesday
                      </h5>
                      <ul>
                        {tuesArr.map((slot) => (
                          <div
                            style={{
                              width: "170px",
                              height: "35px",
                              borderRadius: "17.5px",
                              backgroundColor: "#7FFF00",
                              color: "#000000",
                              padding: "10px 5px 10px 12px",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              marginBottom: "5px",
                              border: "1px solid black",
                              fontSize: "15px",
                            }}
                            key={slot}
                          >
                            {slot}
                            <div style={{ marginRight: "-10px" }}>
                              <MoreVertIcon
                                style={{ color: "#000000", cursor: "pointer" }}
                                onClick={() => {
                                  setEditDelete2(!editDelete2);
                                }}
                              />
                              <div
                                className="editdelete"
                                style={{
                                  display: editDelete2 ? "flex" : "none",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    const addPref =
                                      document.querySelector(".popup");
                                    addPref.classList.remove("hide");
                                    setSelectedTime(
                                      moment(slot.substr(0, 7), "h:mm a")
                                    );

                                    var start = moment(
                                      slot.substr(0, 7),
                                      "h:mm a"
                                    );
                                    var end = moment(
                                      slot.substr(11, 18),
                                      "h:mm a"
                                    );
                                    var duration = moment.duration(
                                      end.diff(start)
                                    );
                                    var mins = parseInt(duration.asMinutes());
                                    if (mins % 30 == 0) {
                                      setSlots(mins / 30);
                                    } else {
                                      setSlots(mins / 30 + 1);
                                    }
                                    setTimeSlot([]);
                                    days.forEach((day) => {
                                      day.isChecked = false;
                                    });
                                    days.some((day) => {
                                      if (day.name == "Tuesday") {
                                        day.isChecked = true;
                                      }
                                    });
                                    tuesArr.splice(tuesArr.indexOf(slot), 1);
                                  }}
                                >
                                  Edit
                                </div>
                                <div
                                  onClick={() => {
                                    setDelPop(!delPop);
                                  }}
                                >
                                  Delete
                                  <div
                                    className="delPopup"
                                    style={{
                                      display: delPop ? "block" : "none",
                                    }}
                                  >
                                    <span>Are You Sure?</span>
                                    <div className="buttons">
                                      <button
                                        onClick={() => {
                                          for (
                                            var i = 0;
                                            i < tuesArr?.length;
                                            i++
                                          ) {
                                            if (tuesArr[i] == slot) {
                                              var spliced = tuesArr.splice(
                                                i,
                                                1
                                              );
                                            }
                                          }
                                          setDelPop(false);
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        onClick={() => {
                                          setDelPop(false);
                                        }}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="wedpref"
                      style={{
                        display: wedArr?.length >= 1 ? "flex" : "none",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h5 style={{ fontSize: "18px", fontWeight: "500" }}>
                        Wednesday
                      </h5>
                      <ul>
                        {wedArr.map((slot) => (
                          <div
                            style={{
                              width: "170px",
                              height: "35px",
                              borderRadius: "17.5px",
                              backgroundColor: "#7FFF00",
                              color: "#000000",
                              padding: "10px 5px 10px 12px",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              marginBottom: "5px",
                              border: "1px solid black",
                              fontSize: "15px",
                            }}
                            key={slot}
                          >
                            {slot}
                            <div style={{ marginRight: "-10px" }}>
                              <MoreVertIcon
                                style={{ color: "#000000", cursor: "pointer" }}
                                onClick={() => {
                                  setEditDelete3(!editDelete3);
                                }}
                              />
                              <div
                                className="editdelete"
                                style={{
                                  display: editDelete3 ? "flex" : "none",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    const addPref =
                                      document.querySelector(".popup");
                                    addPref.classList.remove("hide");
                                    setSelectedTime(
                                      moment(slot.substr(0, 7), "h:mm a")
                                    );

                                    var start = moment(
                                      slot.substr(0, 7),
                                      "h:mm a"
                                    );
                                    var end = moment(
                                      slot.substr(11, 18),
                                      "h:mm a"
                                    );
                                    var duration = moment.duration(
                                      end.diff(start)
                                    );
                                    var mins = parseInt(duration.asMinutes());
                                    if (mins % 30 == 0) {
                                      setSlots(mins / 30);
                                    } else {
                                      setSlots(mins / 30 + 1);
                                    }
                                    setTimeSlot([]);
                                    days.forEach((day) => {
                                      day.isChecked = false;
                                    });
                                    days.some((day) => {
                                      if (day.name == "Wednesday") {
                                        day.isChecked = true;
                                      }
                                    });
                                    wedArr.splice(monArr.indexOf(slot), 1);
                                  }}
                                >
                                  Edit
                                </div>
                                <div
                                  onClick={() => {
                                    setDelPop(!delPop);
                                  }}
                                >
                                  Delete
                                  <div
                                    className="delPopup"
                                    style={{
                                      display: delPop ? "block" : "none",
                                    }}
                                  >
                                    <span>Are You Sure?</span>
                                    <div className="buttons">
                                      <button
                                        onClick={() => {
                                          for (
                                            var i = 0;
                                            i < wedArr?.length;
                                            i++
                                          ) {
                                            if (wedArr[i] == slot) {
                                              var spliced = wedArr.splice(i, 1);
                                            }
                                          }
                                          setDelPop(false);
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        onClick={() => {
                                          setDelPop(false);
                                        }}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="thurspref"
                      style={{
                        display: thursArr?.length >= 1 ? "flex" : "none",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h5 style={{ fontSize: "18px", fontWeight: "500" }}>
                        Thursday
                      </h5>
                      <ul>
                        {thursArr.map((slot) => (
                          <div
                            style={{
                              width: "170px",
                              height: "35px",
                              borderRadius: "17.5px",
                              backgroundColor: "#7FFF00",
                              color: "#000000",
                              padding: "10px 5px 10px 12px",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              marginBottom: "5px",
                              border: "1px solid black",
                              fontSize: "15px",
                            }}
                            key={slot}
                          >
                            {slot}
                            <div style={{ marginRight: "-10px" }}>
                              <MoreVertIcon
                                style={{ color: "#000000", cursor: "pointer" }}
                                onClick={() => {
                                  setEditDelete4(!editDelete4);
                                }}
                              />
                              <div
                                className="editdelete"
                                style={{
                                  display: editDelete4 ? "flex" : "none",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    const addPref =
                                      document.querySelector(".popup");
                                    addPref.classList.remove("hide");
                                    setSelectedTime(
                                      moment(slot.substr(0, 7), "h:mm a")
                                    );

                                    var start = moment(
                                      slot.substr(0, 7),
                                      "h:mm a"
                                    );
                                    var end = moment(
                                      slot.substr(11, 18),
                                      "h:mm a"
                                    );
                                    var duration = moment.duration(
                                      end.diff(start)
                                    );
                                    var mins = parseInt(duration.asMinutes());
                                    if (mins % 30 == 0) {
                                      setSlots(mins / 30);
                                    } else {
                                      setSlots(mins / 30 + 1);
                                    }
                                    setTimeSlot([]);
                                    days.forEach((day) => {
                                      day.isChecked = false;
                                    });
                                    days.some((day) => {
                                      if (day.name == "Thursday") {
                                        day.isChecked = true;
                                      }
                                    });
                                    thursArr.splice(thursArr.indexOf(slot), 1);
                                  }}
                                >
                                  Edit
                                </div>
                                <div
                                  onClick={() => {
                                    setDelPop(!delPop);
                                  }}
                                >
                                  Delete
                                  <div
                                    className="delPopup"
                                    style={{
                                      display: delPop ? "block" : "none",
                                    }}
                                  >
                                    <span>Are You Sure?</span>
                                    <div className="buttons">
                                      <button
                                        onClick={() => {
                                          for (
                                            var i = 0;
                                            i < thursArr?.length;
                                            i++
                                          ) {
                                            if (thursArr[i] == slot) {
                                              var spliced = thursArr.splice(
                                                i,
                                                1
                                              );
                                            }
                                          }
                                          setDelPop(false);
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        onClick={() => {
                                          setDelPop(false);
                                        }}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="fripref"
                      style={{
                        display: friArr?.length >= 1 ? "flex" : "none",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h5 style={{ fontSize: "18px", fontWeight: "500" }}>
                        Friday
                      </h5>
                      <ul>
                        {friArr.map((slot) => (
                          <div
                            style={{
                              width: "170px",
                              height: "35px",
                              borderRadius: "17.5px",
                              backgroundColor: "#7FFF00",
                              color: "#000000",
                              padding: "10px 5px 10px 12px",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              marginBottom: "5px",
                              border: "1px solid black",
                              fontSize: "15px",
                            }}
                            key={slot}
                          >
                            {slot}
                            <div style={{ marginRight: "-10px" }}>
                              <MoreVertIcon
                                style={{ color: "#000000", cursor: "pointer" }}
                                onClick={() => {
                                  setEditDelete5(!editDelete5);
                                }}
                              />
                              <div
                                className="editdelete"
                                style={{
                                  display: editDelete5 ? "flex" : "none",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    const addPref =
                                      document.querySelector(".popup");
                                    addPref.classList.remove("hide");
                                    setSelectedTime(
                                      moment(slot.substr(0, 7), "h:mm a")
                                    );

                                    var start = moment(
                                      slot.substr(0, 7),
                                      "h:mm a"
                                    );
                                    var end = moment(
                                      slot.substr(11, 18),
                                      "h:mm a"
                                    );
                                    var duration = moment.duration(
                                      end.diff(start)
                                    );
                                    var mins = parseInt(duration.asMinutes());
                                    if (mins % 30 == 0) {
                                      setSlots(mins / 30);
                                    } else {
                                      setSlots(mins / 30 + 1);
                                    }
                                    setTimeSlot([]);
                                    days.forEach((day) => {
                                      day.isChecked = false;
                                    });
                                    days.some((day) => {
                                      if (day.name == "Friday") {
                                        day.isChecked = true;
                                      }
                                    });
                                    friArr.splice(friArr.indexOf(slot), 1);
                                  }}
                                >
                                  Edit
                                </div>
                                <div
                                  onClick={() => {
                                    setDelPop(!delPop);
                                  }}
                                >
                                  Delete
                                  <div
                                    className="delPopup"
                                    style={{
                                      display: delPop ? "block" : "none",
                                    }}
                                  >
                                    <span>Are You Sure?</span>
                                    <div className="buttons">
                                      <button
                                        onClick={() => {
                                          for (
                                            var i = 0;
                                            i < friArr?.length;
                                            i++
                                          ) {
                                            if (friArr[i] == slot) {
                                              var spliced = friArr.splice(i, 1);
                                            }
                                          }
                                          setDelPop(false);
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        onClick={() => {
                                          setDelPop(false);
                                        }}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="satpref"
                      style={{
                        display: satArr?.length >= 1 ? "flex" : "none",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h5 style={{ fontSize: "18px", fontWeight: "500" }}>
                        Saturday
                      </h5>
                      <ul>
                        {satArr.map((slot) => (
                          <div
                            style={{
                              width: "170px",
                              height: "35px",
                              borderRadius: "17.5px",
                              backgroundColor: "#7FFF00",
                              color: "#000000",
                              padding: "10px 5px 10px 12px",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              marginBottom: "5px",
                              border: "1px solid black",
                              fontSize: "15px",
                            }}
                            key={slot}
                          >
                            {slot}
                            <div style={{ marginRight: "-10px" }}>
                              <MoreVertIcon
                                style={{ color: "#000000", cursor: "pointer" }}
                                onClick={() => {
                                  setEditDelete6(!editDelete6);
                                }}
                              />
                              <div
                                className="editdelete"
                                style={{
                                  display: editDelete6 ? "flex" : "none",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    const addPref =
                                      document.querySelector(".popup");
                                    addPref.classList.remove("hide");
                                    setSelectedTime(
                                      moment(slot.substr(0, 7), "h:mm a")
                                    );

                                    var start = moment(
                                      slot.substr(0, 7),
                                      "h:mm a"
                                    );
                                    var end = moment(
                                      slot.substr(11, 18),
                                      "h:mm a"
                                    );
                                    var duration = moment.duration(
                                      end.diff(start)
                                    );
                                    var mins = parseInt(duration.asMinutes());
                                    if (mins % 30 == 0) {
                                      setSlots(mins / 30);
                                    } else {
                                      setSlots(mins / 30 + 1);
                                    }
                                    setTimeSlot([]);
                                    days.forEach((day) => {
                                      day.isChecked = false;
                                    });
                                    days.some((day) => {
                                      if (day.name == "Saturday") {
                                        day.isChecked = true;
                                      }
                                    });
                                    satArr.splice(satArr.indexOf(slot), 1);
                                  }}
                                >
                                  Edit
                                </div>
                                <div
                                  onClick={() => {
                                    setDelPop(!delPop);
                                  }}
                                >
                                  Delete
                                  <div
                                    className="delPopup"
                                    style={{
                                      display: delPop ? "block" : "none",
                                    }}
                                  >
                                    <span>Are You Sure?</span>
                                    <div className="buttons">
                                      <button
                                        onClick={() => {
                                          for (
                                            var i = 0;
                                            i < satArr?.length;
                                            i++
                                          ) {
                                            if (satArr[i] == slot) {
                                              var spliced = satArr.splice(i, 1);
                                            }
                                          }
                                          setDelPop(false);
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        onClick={() => {
                                          setDelPop(false);
                                        }}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="sunpref"
                      style={{
                        display: sunArr?.length >= 1 ? "flex" : "none",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h5 style={{ fontSize: "18px", fontWeight: "500" }}>
                        Sunday
                      </h5>
                      <ul>
                        {sunArr.map((slot) => (
                          <div
                            style={{
                              width: "170px",
                              height: "35px",
                              borderRadius: "17.5px",
                              backgroundColor: "#7FFF00",
                              color: "#000000",
                              padding: "10px 5px 10px 12px",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              marginBottom: "5px",
                              border: "1px solid black",
                              fontSize: "15px",
                            }}
                            key={slot}
                          >
                            {slot}
                            <div style={{ marginRight: "-10px" }}>
                              <MoreVertIcon
                                style={{ color: "#000000", cursor: "pointer" }}
                                onClick={() => {
                                  setEditDelete7(!editDelete7);
                                }}
                              />
                              <div
                                className="editdelete"
                                style={{
                                  display: editDelete7 ? "flex" : "none",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    const addPref =
                                      document.querySelector(".popup");
                                    addPref.classList.remove("hide");
                                    setSelectedTime(
                                      moment(slot.substr(0, 7), "h:mm a")
                                    );

                                    var start = moment(
                                      slot.substr(0, 7),
                                      "h:mm a"
                                    );
                                    var end = moment(
                                      slot.substr(11, 18),
                                      "h:mm a"
                                    );
                                    var duration = moment.duration(
                                      end.diff(start)
                                    );
                                    var mins = parseInt(duration.asMinutes());
                                    if (mins % 30 == 0) {
                                      setSlots(mins / 30);
                                    } else {
                                      setSlots(mins / 30 + 1);
                                    }
                                    setTimeSlot([]);
                                    days.forEach((day) => {
                                      day.isChecked = false;
                                    });
                                    days.some((day) => {
                                      if (day.name == "Sunday") {
                                        day.isChecked = true;
                                      }
                                    });
                                    sunArr.splice(sunArr.indexOf(slot), 1);
                                  }}
                                >
                                  Edit
                                </div>
                                <div
                                  onClick={() => {
                                    setDelPop(!delPop);
                                  }}
                                >
                                  Delete
                                  <div
                                    className="delPopup"
                                    style={{
                                      display: delPop ? "block" : "none",
                                    }}
                                  >
                                    <span>Are You Sure?</span>
                                    <div className="buttons">
                                      <button
                                        onClick={() => {
                                          for (
                                            var i = 0;
                                            i < sunArr?.length;
                                            i++
                                          ) {
                                            if (sunArr[i] == slot) {
                                              var spliced = sunArr.splice(i, 1);
                                            }
                                          }
                                          setDelPop(false);
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        onClick={() => {
                                          setDelPop(false);
                                        }}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="cust-btn add-time-btn"
                    onClick={() => {
                      const addPref = document.querySelector(".popup");
                      addPref.classList.remove("hide");
                      setSelectedTime("00:00:01");
                      setSlots(0);
                      setTimeSlot([]);
                      days.forEach((day) => {
                        day.isChecked = false;
                      });
                      setButtonPress(false);
                    }}
                    style={{
                      display: showPref ? "flex" : "none",
                      width: "250px",
                      height: "40px",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                      marginLeft: "0%",
                      borderRadius: "10px",
                    }}
                  >
                    Add More Time Preferences +
                  </button>
                </div>
                {/* Whenever a sort of variable is used somewhere but not declared initially, the whole screen just goes blank and no error shows up; See the console to find the error in bundle.js and debug it accordingly; most probably just initiate the variable used */}
                <div className="popup hide" style={{ height: "auto" }}>
                  <button
                    type="button"
                    className="cust-btn close-btn"
                    onClick={() => {
                      const addPref = document.querySelector(".popup");
                      addPref.classList.add("hide");
                      setSelectedTime("00:00:01");
                      setSlots(0);
                      setTimeSlot([]);
                      days.forEach((day) => {
                        day.isChecked = false;
                      });
                      setButtonPress(false);
                    }}
                  >
                    <i className="fa-solid fa-circle-xmark"></i>
                  </button>

                  {/* ------------------------- */}
                  <div className="select flex">
                    <div
                      className="leftpart"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {/* <div className="multiselect">
                        <h5>Choose the Day(s)</h5> */}
                      {/* <div
                          className="selectBox"
                          onClick={() => {
                            showCheckboxes();
                          }}
                        >
                          <select>
                            <option>Select Days</option>
                          </select>
                          <div className="overSelect"></div>
                        </div> */}
                      {/* <div
                          style={{ display: "flex", flexDirection: "column" }}
                        > */}
                      {/* <label for="monday">
                            <input
                              type="checkbox"
                              checked={days["monday"] || false}
                              onChange={handleDaysChange}
                              id="monday"
                            />
                            Monday
                          </label>
                          <label for="tuesday">
                            <input
                              type="checkbox"
                              checked={days["tuesday"] || false}
                              onChange={handleDaysChange}
                              id="tuesday"
                            />
                            Tuesday
                          </label>
                          <label for="wednesday">
                            <input
                              type="checkbox"
                              checked={days["wednesday"] || false}
                              onChange={handleDaysChange}
                              id="wednesday"
                            />
                            Wednesday
                          </label>
                          <label for="thursday">
                            <input
                              type="checkbox"
                              checked={days["thursday"] || false}
                              onChange={handleDaysChange}
                              id="thursday"
                            />
                            Thursday
                          </label>
                          <label for="friday">
                            <input
                              type="checkbox"
                              checked={days["friday"] || false}
                              onChange={handleDaysChange}
                              id="friday"
                            />
                            Friday
                          </label>
                          <label for="saturday">
                            <input
                              type="checkbox"
                              checked={days["saturday"] || false}
                              onChange={handleDaysChange}
                              id="saturday"
                            />
                            Saturday
                          </label>
                          <label for="sunday">
                            <input
                              type="checkbox"
                              checked={days["sunday"] || false}
                              onChange={handleDaysChange}
                              id="sunday"
                            />
                            Sunday
                          </label>
                          <label for="all" style={{ marginTop: "7.5px" }}>
                            <input
                              type="checkbox"
                              checked={
                                (days["sunday"] &&
                                  days["monday"] &&
                                  days["tuesday"] &&
                                  days["wednesday"] &&
                                  days["thursday"] &&
                                  days["friday"] &&
                                  days["saturday"]) ||
                                false
                                // (days["sunday"] || false)&&(days["monday"] || false)
                              }
                              onChange={handleDaysChange}
                              id="monday"
                            />
                            <i style={{ color: "blue" }}>Select All</i>
                          </label> */}
                      {/* <label for="custom">
                            <input
                              type="checkbox"
                              checked={days["custom"] || false}
                              onChange={handleDaysChange}
                              id="custom"
                            />
                            Custom
                          </label> */}

                      {/* </div>
                      </div> */}
                      <form className="form w-100">
                        <h3
                          style={{
                            border:
                              !days.some((day) => day.isChecked === true) &&
                              buttonPress
                                ? "1px solid red"
                                : "",
                          }}
                        >
                          Select Days
                        </h3>

                        {days.map((day, index) => (
                          <div className="form-check" key={index}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={day.name}
                              checked={day?.isChecked || false}
                              onChange={handleDaysChange}
                            />
                            <label className="form-check-label ms-2">
                              {day.name}
                            </label>
                          </div>
                        ))}
                        <div
                          className="form-check"
                          style={{ marginTop: "7.5px" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="allSelect"
                            // checked={
                            //   users.filter((user) => user?.isChecked !== true)?.length < 1
                            // }
                            checked={
                              !days.some((day) => day?.isChecked !== true)
                            }
                            onChange={handleDaysChange}
                          />
                          <label className="form-check-label ms-2">
                            <i style={{ color: "blue" }}>Select All</i>
                          </label>
                        </div>
                      </form>
                      {/* <div
                        className="left_dropdown"
                        style={{ marginTop: "20px" }}
                      >
                        <span
                          style={{
                            marginRight: "20px",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          Choose the Duration of the Interview:
                        </span>
                        <select
                          className="duration_dropdown"
                          value={duration}
                          onChange={durationChange}
                        >
                          <option value="30">30 Minutes</option>
                          <option value="45">45 Minutes</option>
                          <option value="60">60 Minutes</option>
                          <option value="75">75 Minutes</option>
                          <option value="90">90 Minutes</option>
                        </select>
                      </div> */}
                    </div>
                    {/* -------------------- */}
                    <div className="time">
                      <h5>Availability</h5>
                      <div className="flex" style={{ flexDirection: "column" }}>
                        {/* <div>
                          <label htmlFor="from">From</label>
                          <input
                            type="time"
                            value={time["from"] || "00:00"}
                            onChange={handleTimeChange}
                            name="from"
                            id="from"
                          />
                        </div>
                        <div>
                          <label htmlFor="to">To</label>
                          <input
                            type="time"
                            name="to"
                            value={time["to"] || "00:00"}
                            onChange={handleTimeChange}
                            id="to"
                          />
                        </div> */}
                        <div style={{ marginLeft: "-15px" }}>
                          <span style={{ marginRight: "20px" }}>
                            Available From
                          </span>
                          {/* <TimePicker
                            label="Time"
                            defaultValue={now}
                            placeholder="Select Time"
                            use12Hours
                            showSecond={false}
                            focusOnOpen={true}
                            minuteStep={15}
                            format={format}
                            onChange={getTime}
                          /> */}
                          <TimePicker
                            use12Hours
                            format="h:mm a"
                            label="Time"
                            className="timepicker"
                            size="large"
                            placeholder="Select Time"
                            minuteStep={30}
                            // onChange={getTime1}
                            allowClear={false}
                            locale={{
                              ...locale,
                              lang: {
                                ...locale.lang,
                                now: "Now",
                                ok: "Save",
                              },
                            }}
                            value={moment(selectedTime, "HH:mm")}
                            onSelect={(value) => {
                              const rawTime = moment(value);
                              setSelectedTime(rawTime);
                              if (slots != 0) {
                                getTimeSlots(selectedTime, slots);
                              }
                              console.log(timeSlot);
                            }}
                            renderExtraFooter={() => (
                              <Button
                                type="primary"
                                onClick={() => {
                                  const start = moment();
                                  const remainder = 15 - (start.minute() % 15);
                                  const rawTime = moment(start).add(
                                    remainder,
                                    "minutes"
                                  );
                                  setSelectedTime(rawTime);
                                  if (slots != 0) {
                                    getTimeSlots(selectedTime, slots);
                                  }
                                }}
                              >
                                Now
                              </Button>
                            )}
                            showNow={false}
                            style={{
                              border:
                                selectedTime == "00:00:01" && buttonPress
                                  ? "1px solid red"
                                  : "",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: "15px",
                            display: "flex",
                            marginLeft: "-5px",
                          }}
                        >
                          <span>No. of Mock Interviews </span>
                          {selectedTime != "00:00:01" ? (
                            <Tooltip title={tooltipText()} placement="right">
                              <InfoOutlinedIcon
                                style={{
                                  color: "blue",
                                  width: "15px",
                                  height: "15px",
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                  marginTop: "5px",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="No. of Mock Interviews which you can take in one go, one after another, from your starting time onwards"
                              placement="right"
                            >
                              <InfoOutlinedIcon
                                style={{
                                  color: "blue",
                                  width: "15px",
                                  height: "15px",
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                  marginTop: "5px",
                                }}
                              />
                            </Tooltip>
                          )}

                          {/* <input
                            type="text"
                            pattern="[0-9]*"
                            value={slots}
                            onChange={(e) => {
                              setSlots((v) =>
                                e.target.validity.valid ? e.target.value : v
                              );
                              getTimeSlots(selectedTime, slots);
                            }}
                          /> */}
                          <div>
                            <select
                              className="slots_dropdown"
                              id="slots_dropdown"
                              value={slots}
                              onChange={(e) => {
                                setSlots(parseInt(e.target.value, 10));
                                if (selectedTime != "00:00:01") {
                                  getTimeSlots(selectedTime, slots);
                                }
                              }}
                              // onChange={getTimeSlots(selectedTime, slots)}
                              defaultValue="Select"
                              style={{
                                width: "120px",
                                height: "30px",
                                border:
                                  slots == 0 && buttonPress
                                    ? "1px solid red"
                                    : "",
                              }}
                            >
                              <option value="0">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>
                        <div
                          style={{ marginLeft: "-140px", marginTop: "15px" }}
                        >
                          <span>{selectedSlots()}</span>

                          {timeSlot?.length > 1 &&
                          timeSlot[timeSlot?.length - 2].includes(
                            "- 11:59 pm"
                          ) ? (
                            <div>{timeSlot[timeSlot?.length - 2]}</div>
                          ) : (
                            ""
                          )}
                          {timeSlot?.length > 1 &&
                          timeSlot[timeSlot?.length - 1].includes(
                            "12:00 am - 12:00 am"
                          ) ? (
                            ""
                          ) : (
                            <div>{timeSlot[timeSlot?.length - 1]}</div>
                          )}
                        </div>
                        {/* <div style={{ marginLeft: "17px", marginTop: "10px" }}>
                          <span style={{ marginRight: "20px" }}>To</span>
                          <TimePicker
                            onChange={getTime2}
                            className="timepicker"
                            label="Time"
                            size="large"
                            placeholder="Select Time"
                            format="h:mm a"
                            minuteStep={15}
                            use12Hours
                            defaultValue={then}
                            disabledHours={disabledHours}
                            disabledMinutes={disabledMinutes}
                            allowClear={false}
                            showNow={false}
                            locale={{
                              ...locale,
                              lang: {
                                ...locale.lang,
                                now: "Now",
                                ok: "Save",
                              },
                            }}
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      // console.log(
                      //   "Days: " +
                      //     JSON.stringify(days) +
                      //     "Time: " +
                      //     JSON.stringify(time)
                      // );
                      setButtonPress(true);
                      if (
                        !days.some((day) => day.isChecked === true) ||
                        selectedTime == "00:00:01" ||
                        slots == 0
                      ) {
                        handleButtonClick();
                      } else {
                        setShowPref(true);
                        const addPref = document.querySelector(".popup");
                        addPref.classList.add("hide");
                        //console.log(days);
                        if (
                          days.some(
                            (e) => e.name === "Monday" && e.isChecked === true
                          ) ||
                          (days.some(
                            (e) => e.name === "Sunday" && e.isChecked === true
                          ) &&
                            timeSlot?.length > 1 &&
                            timeSlot[timeSlot?.length - 2].includes(
                              "- 11:59 pm"
                            ))
                        ) {
                          fetchMon();
                        }
                        if (
                          days.some(
                            (e) => e.name === "Tuesday" && e.isChecked === true
                          ) ||
                          (days.some(
                            (e) => e.name === "Monday" && e.isChecked === true
                          ) &&
                            timeSlot?.length > 1 &&
                            timeSlot[timeSlot?.length - 2].includes(
                              "- 11:59 pm"
                            ))
                        ) {
                          fetchTues();
                        }
                        if (
                          days.some(
                            (e) =>
                              e.name === "Wednesday" && e.isChecked === true
                          ) ||
                          (days.some(
                            (e) => e.name === "Tuesday" && e.isChecked === true
                          ) &&
                            timeSlot?.length > 1 &&
                            timeSlot[timeSlot?.length - 2].includes(
                              "- 11:59 pm"
                            ))
                        ) {
                          fetchWed();
                        }
                        if (
                          days.some(
                            (e) => e.name === "Thursday" && e.isChecked === true
                          ) ||
                          (days.some(
                            (e) =>
                              e.name === "Wednesday" && e.isChecked === true
                          ) &&
                            timeSlot?.length > 1 &&
                            timeSlot[timeSlot?.length - 2].includes(
                              "- 11:59 pm"
                            ))
                        ) {
                          fetchThurs();
                        }
                        if (
                          days.some(
                            (e) => e.name === "Friday" && e.isChecked === true
                          ) ||
                          (days.some(
                            (e) => e.name === "Thursday" && e.isChecked === true
                          ) &&
                            timeSlot?.length > 1 &&
                            timeSlot[timeSlot?.length - 2].includes(
                              "- 11:59 pm"
                            ))
                        ) {
                          fetchFri();
                        }
                        if (
                          days.some(
                            (e) => e.name === "Saturday" && e.isChecked === true
                          ) ||
                          (days.some(
                            (e) => e.name === "Friday" && e.isChecked === true
                          ) &&
                            timeSlot?.length > 1 &&
                            timeSlot[timeSlot?.length - 2].includes(
                              "- 11:59 pm"
                            ))
                        ) {
                          fetchSat();
                        }
                        if (
                          days.some(
                            (e) => e.name === "Sunday" && e.isChecked === true
                          ) ||
                          (days.some(
                            (e) => e.name === "Saturday" && e.isChecked === true
                          ) &&
                            timeSlot?.length > 1 &&
                            timeSlot[timeSlot?.length - 2].includes(
                              "- 11:59 pm"
                            ))
                        ) {
                          fetchSun();
                        }
                        // if (
                        //   days.some(
                        //     (e) => e.name === "Tuesday" && e.isChecked === true
                        //   ) ||
                        //   (days.some(
                        //     (e) => e.name === "Monday" && e.isChecked === true
                        //   ) &&
                        //     timeSlot?.length > 1 &&
                        //     timeSlot[timeSlot?.length - 2].includes(
                        //       "- 11:59 pm"
                        //     ))
                        // ) {
                        //   var ArrUnsort=[];
                        //   timeSlot.some((e)=>{
                        //     if(e.includes("am -")){
                        //       ArrUnsort.push(e);
                        //     }
                        //   });
                        //   tuesArr.some((e)=>{
                        //     if(e.includes("am -")){
                        //       ArrUnsort.push(e);
                        //     }
                        //   });
                        //   ArrUnsort.sort();
                        //   tuesArr=[];
                        //   tuesArr.push(...ArrUnsort);
                        //   ArrUnsort=[];
                        //   timeSlot.some((e)=>{
                        //     if(e.includes("pm -")){
                        //       ArrUnsort.push(e);
                        //     }
                        //   });
                        //   tuesArr.some((e)=>{
                        //     if(e.includes("pm -")){
                        //       ArrUnsort.push(e);
                        //     }
                        //   });
                        //   ArrUnsort.sort();
                        //   tuesArr.push(...ArrUnsort);
                        // }
                        console.log(monArr);
                        console.log(tuesArr);
                        setEditDelete1(false);
                        setEditDelete2(false);
                        setEditDelete3(false);
                        setEditDelete4(false);
                        setEditDelete5(false);
                        setEditDelete6(false);
                        setEditDelete7(false);
                      }
                    }}
                    className="cust-btn add-btn"
                  >
                    Save
                  </button>
                  {isAlertVisible && (
                    <div className="alert-container">
                      {errorMessage()}
                      {/* {!days.some((day) => day.isChecked === true) ? (
                        <div className="alert-inner">
                          Please select the [Day(s)] on which you are available
                          before saving!
                        </div>
                      ) : (
                        ""
                      )}
                      {selectedTime == "00:00:01" ? (
                        <div className="alert-inner">
                          Please select the [From Time] before saving!
                        </div>
                      ) : (
                        ""
                      )}
                      {slots == 0 ? (
                        <div className="alert-inner">
                          Please select the [Number of Mock Interviews] before saving!
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Col-30 */}

            <div className="profile-form-profile">
              {/* Profile Photo */}

              <div className="profile-photo">
                <h4>Profile Photo</h4>
                <img
                  src={require("../../images/photo.png")}
                  className="upload"
                  alt="Upload"
                />
                <input
                  type="file"
                  name="profile"
                  id="profile"
                  value={inputs.profile || ""}
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={(e) => {
                    handleChange(e);
                    var image = document.querySelector(".upload");
                    image.src = URL.createObjectURL(e.target.files[0]);
                  }}
                />
              </div>
            </div>
          </div>
          <button type="submit" className="cust-btn create-btn">
            Create Account
          </button>
        </form>
      </div>
    </>
  );
};

export default InterviewerProfileFormOld;
