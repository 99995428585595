import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { toastStyle } from "../../../utility/helper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UserContext from "../../../contexts/UserContext";
import axios from "axios";
import { BASE } from "../../../constants/endpoints";
import { downloadPDF } from "../../../utility/helper";
import { Oval } from "react-loader-spinner";
import { Box, Chip, Button, IconButton, MenuItem, Pagination, Select, Radio } from "@mui/material";
import { Grid, Typography } from '@mui/material';
import { Divider } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { FormControl, FormControlLabel } from "@mui/material";
import { FormGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RadioGroup } from "@mui/material";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { IoCloseCircle } from "react-icons/io5";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AddedStudents = () => {
  const { user } = useContext(UserContext);
  console.log(user);
  const navigate = useNavigate();
  const [reg, setReg] = useState([]);
  const [ans, setAns] = useState([]);
  const [year, setYear] = useState(null);
  const [branch, setBranch] = useState(null);
  const [program, setProgram] = useState(null);
  const [appearing, setAppearing] = useState(null);
  const [name, setName] = useState(null);

  const [branches, setBranches] = useState([]);
  const [page, setPage] = useState(1);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);
  const [allAdmins, setAllAdmins] = useState([]);
  const [adminNames, setAdminNames] = useState([]);
  const [adminIds, setAdminIds] = useState([]);
  const [adminName, setAdmin] = useState("");
  const [search, setSearch] = useState(null);
  const [isloaded, setIsloaded] = useState(false)
  const [couponAdmin, setCouponAdmin] = useState("");
  const [canProceed, setCanProceed] = useState(false)
  const [noOfStudents, setNoOfStudents] = useState(0)
  const [noOfInterviews, setNoOfInterviews] = useState(0)
  const [payment, setPayment] = useState(-1)
  const [amount2, setAmount2] = useState(0)
  const [isPaymentDone, setIsPaymentDone] = useState(false)
  const [couponStudents, setCouponStudents] = useState([])
  const [generateCouponLoading, setGenerateCouponLoading] = useState(false);

  const nameFilter = (e) => {
    setName(e.target.value);
    let finalf = reg;
    if (e.target.value && (e.target.value !== null || e.target.value !== "")) {
      finalf = finalf.filter(
        (res) =>
          res.sName.toLowerCase().includes(e.target.value.toLowerCase()) ===
          true
      );
    }

    if (search) {
      finalf = finalf.filter((res) => res.addedBy === search);
    }

    if (year) {
      finalf = finalf.filter((res) => res.year === `${year}`);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.branch === branch);
    }
    if (program) {
      finalf = finalf.filter((res) => res.program === program);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter((res) => res.appearingFor.one === true && res.appearingFor.two === false);
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter((res) => res.appearingFor.two === true && res.appearingFor.one === false);
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.appearingFor.one === true && res.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };
  const handleChange = (event, value) => {
    window.scrollTo(0, 0);
    if (page - value == 1) {
      handleLeft()
    } else {
      handleRight()
    }
    setPage(value);
  };
  const yearFilter = (e) => {
    setYear(e.target.value);
    let finalf = reg;
    if (e.target.value) {
      finalf = finalf.filter((res) => res.year === `${e.target.value}`);
    }

    if (search) {
      finalf = finalf.filter((res) => res.addedBy === search);
    }

    if (branch) {
      finalf = finalf.filter((res) => res.branch === branch);
    }
    if (name) {
      finalf = finalf.filter(
        (res) => res.sName.toLowerCase().includes(name.toLowerCase()) === true
      );
    }
    if (program) {
      finalf = finalf.filter((res) => res.program === program);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter((res) => res.appearingFor.one === true && res.appearingFor.two === false);
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter((res) => res.appearingFor.two === true && res.appearingFor.one === false);
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.appearingFor.one === true && res.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };
  const branchFilter = (e) => {
    console.log(e.target.value);
    setBranch(e.target.value);
    let finalf = reg;

    if (search) {
      finalf = finalf.filter((res) => res.addedBy === search);
    }

    if (year) {
      finalf = finalf.filter((res) => res.year === year);
    }
    if (e.target.value) {
      finalf = finalf.filter((res) => res.branch === e.target.value);
    }
    if (name) {
      finalf = finalf.filter(
        (res) => res.sName.toLowerCase().includes(name.toLowerCase()) === true
      );
    }
    if (program) {
      finalf = finalf.filter((res) => res.program === program);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter((res) => res.appearingFor.one === true && res.appearingFor.two === false);
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter((res) => res.appearingFor.two === true && res.appearingFor.one === false);
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.appearingFor.one === true && res.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };
  const programFilter = (e) => {
    console.log(e.target.value);
    setProgram(e.target.value);
    let finalf = reg;

    if (search) {
      finalf = finalf.filter((res) => res.addedBy === search);
    }

    if (year) {
      finalf = finalf.filter((res) => res.year === year);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.branch === branch);
    }
    if (name) {
      finalf = finalf.filter(
        (res) => res.sName.toLowerCase().includes(name.toLowerCase()) === true
      );
    }
    if (e.target.value) {
      finalf = finalf.filter((res) => res.program === e.target.value);
    }
    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter((res) => res.appearingFor.one === true && res.appearingFor.two === false);
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter((res) => res.appearingFor.two === true && res.appearingFor.one === false);
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.appearingFor.one === true && res.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };
  const appearingFilter = (e) => {
    console.log(e.target.value);
    console.log(reg);
    setAppearing(
      e.target.value === 1
        ? "Campus Placements"
        : e.target.value === 2
          ? "Internship Interviews"
          : e.target.value === 3
            ? "Both"
            : ""
    );
    let finalf = reg;

    if (search) {
      finalf = finalf.filter((res) => res.addedBy === search);
    }

    if (year) {
      finalf = finalf.filter((res) => res.year === year);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.branch === branch);
    }
    if (name) {
      finalf = finalf.filter(
        (res) => res.sName.toLowerCase().includes(name.toLowerCase()) === true
      );
    }
    if (program) {
      finalf = finalf.filter((res) => res.program === program);
    }
    if (e.target.value) {
      if (e.target.value === 1 || e.target.value === "1") {
        finalf = finalf.filter((res) => res.appearingFor.one === true && res.appearingFor.two === false);
      } else if (e.target.value === 2 || e.target.value === "2") {
        finalf = finalf.filter((res) => res.appearingFor.two === true && res.appearingFor.one === false);
      } else if (e.target.value === 3 || e.target.value === "3") {
        finalf = finalf.filter(
          (res) =>
            res.appearingFor.one === true && res.appearingFor.two === true
        );
      }
    }
    setAns(finalf);
  };

  const searchingFilter = (e) => {

    setSearch(adminNames[e.target.value - 1]);
    let finalf = reg;


    if (e.target.value) {
      // console.log(reg,"--31--12-",adminNames[e.target.value-1],e.target.value);
      finalf = finalf.filter((res) => {
        return res.addedBy === adminNames[e.target.value - 1];
      });
    }

    if (year) {
      finalf = finalf.filter((res) => res.year === year);
    }
    if (branch) {
      finalf = finalf.filter((res) => res.branch === branch);
    }
    if (name) {
      finalf = finalf.filter(
        (res) => res.sName.toLowerCase().includes(name.toLowerCase()) === true
      );
    }
    if (program) {
      finalf = finalf.filter((res) => res.program === program);
    }

    if (appearing) {
      if (appearing === "Campus Placements") {
        finalf = finalf.filter((res) => res.appearingFor.one === true && res.appearingFor.two === false);
      } else if (appearing === "Internship Interviews") {
        finalf = finalf.filter((res) => res.appearingFor.two === true && res.appearingFor.one === false);
      } else if (appearing === "Both") {
        finalf = finalf.filter(
          (res) =>
            res.appearingFor.one === true && res.appearingFor.two === true
        );
      }
    }

    setAns(finalf);

  }

  const nameDelete = (e) => {
    let s = { target: { value: null } };
    nameFilter(s);
  };
  const yearDelete = (e) => {
    let s = { target: { value: null } };
    yearFilter(s);
  };
  const branchDelete = (e) => {
    let s = { target: { value: null } };
    branchFilter(s);
  };
  const programDelete = (e) => {
    let s = { target: { value: null } };
    programFilter(s);
  };
  const appearingDelete = (e) => {
    let s = { target: { value: null } };
    appearingFilter(s);
  };
  const searchingDelete = (e) => {
    let s = { target: { value: null } };
    searchingFilter(s);
  };


  useEffect(() => {



  }, [search])

  useEffect(() => {
    async function fetchData() {
      axios.get(`${BASE}/send/admin/registered/${user.id}`).then((response) => {
        // console.log(response);
        console.log(response.data, "-----2112872178278278821-----------");
        setReg(response.data.ans);
        setAns(response.data.ans.slice(0, 100));
        setBranches(response.data.branches.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())))

        let x = [];
        let y = [];
        for (let i = 0; i < response.data.ans.length; i++) {
          x.push(response.data.ans[i].addedBy);
          y.push(response.data.ans[i].adminId);
        }
        setAdminNames(x.filter((v, i, a) => a.indexOf(v) === i));
        setAdminIds(y.filter((v, i, a) => a.indexOf(v) === i));
        setIsloaded(true);
        // x = [];
        // for (let i = 0; i < response.data.length; i++) {
        //   x.push(response.data[i].branch);
        // }

        // setBranches(x.filter((v, i, a) => a.indexOf(v) === i));

      });
    }
    fetchData();
  }, []);

  useEffect(() => {
    axios
      .get(`${BASE}/send/all-admins/${user.iid}`)
      .then((res) => {
        setAllAdmins(res.data);
        console.log(res.data, "--021-0-120-31");
        // res.data?.map((item) => {console.log(item.isGenerated)})
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  useEffect(() => {
    console.log(noOfStudents);
    console.log(noOfInterviews);
    console.log(payment);
    if (payment === 1 || payment === "1") {
      Number(noOfStudents) &&
        Number(noOfInterviews) &&
        Number(noOfStudents) <= 500 &&
        setAmount2(
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 489 * 100
          ) / 100
        );

      Number(noOfStudents) > 500 &&
        Number(noOfStudents) <= 900 &&
        setAmount2(
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 475 * 100
          ) / 100
        );

      Number(noOfStudents) > 900 &&
        setAmount2(
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 449 * 100
          ) / 100
        );
    }
    if (payment === 3 || payment === "3") {
      Number(noOfStudents) &&
        Number(noOfInterviews) &&
        Number(noOfStudents) <= 500 &&
        setAmount2(
          (Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 489
          ) *
            100) /
          200
        );

      Number(noOfStudents) > 500 &&
        Number(noOfStudents) <= 900 &&
        setAmount2(
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 475
          ) / 200
        );

      Number(noOfStudents) > 900 &&
        setAmount2(
          Math.round(
            Number(noOfStudents) * Number(noOfInterviews) * 449
          ) / 200
        );
    }
    if (payment === 2 || payment === "2") {
      setAmount2(0);
    }

    console.log(amount2)
  }, [noOfStudents, noOfInterviews, payment])

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const savePaymentDetails = async (req, admin) => {
    axios
      .post(`${BASE}/payment`, { req, admin })
      .then((res) => {
        axios.patch(`${BASE}/send/updateAdminPayment/${admin}`);
        setTimeout(() => {
          window.location.reload(true);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function displayRazorpay2() {
    return new Promise(async function (resolve) {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const data = await fetch(`${BASE}/razorpay`, {
        method: "POST",
        body: JSON.stringify({
          id: user.id,
          amount: amount2,
          admin: user.id,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }).then((t) => t.json());

      const { id } = data;
      console.log(data);
      const options = {
        key:
          process.env.REACT_APP_RAZORPAY_TEST_MODE === "ON"
            ? process.env.REACT_APP_RAZORPAY_TEST_KEY_ID
            : process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID,
        currency: data.currency,
        amount:
          process.env.REACT_APP_RAZORPAY_1RUPEE_LIVE_MODE === "ON"
            ? amount2 * 100
            : amount2 * 100,
        order_id:
          process.env.REACT_APP_RAZORPAY_TEST_MODE === "ON" ? undefined : id,
        // id: id,
        name: `Fyzen Career Solutions Pvt Ltd`,
        description: "Thank you for the confirmation!",
        image:
          "https://media-exp1.licdn.com/dms/image/C560BAQExXV7Oc9sZMA/company-logo_200_200/0/1629144901212?e=2147483647&v=beta&t=x7BZQmslIuY0OgabE7lpKn45A4m53N2jezdjXc6nT_Q",
        handler: async function (response) {
          console.log(
            "Inside handler function. Output from Razorpay. response= :-",
            response
          );
          console.log(response, "---12-3--2-213-12-33-12", user.id);
          setGenerateCouponLoading(true);
          await generateCoupon2();
          // await savePaymentDetails(response, user.id);
          // setreset(false);
        },
        prefill: {
          name: `${user.iname}`,
          email: `${user.iemail}`,
          contact: `${user.imobNumber}`,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
      // paymentObject.on("payment.failed", function (response) {
      //   console.log("Before adding entry :", userData.id, id);
      //   axios.post(`${BASE}/failedPayments`, { id: userData.id });
      //   // alert(response.error.description);
      //   console.log("Payment failed :", response);
      // });
      setIsPaymentDone(true);
    });
  }

  const generateCoupon2 = async () => {
    return new Promise(async function (resolve) {
      setGenerateCouponLoading(true)
      let am = 0;
      if (payment === 2) {
        if (noOfStudents <= 500) {
          am =
            Math.round(
              Number(noOfStudents) * Number(noOfInterviews) * 489 * 100
            ) / 100;
        } else if (noOfStudents > 500 && noOfStudents <= 900) {
          am =
            Math.round(
              Number(noOfStudents) * Number(noOfInterviews) * 475 * 100
            ) / 100;
        } else if (noOfStudents > 900) {
          am =
            Math.round(
              Number(noOfStudents) * Number(noOfInterviews) * 449 * 100
            ) / 100;
        }
      }
      console.log(adminIds[couponAdmin - 1]);
      console.log(couponAdmin);
      let couponAdminId = -1;
      if (couponAdmin >= 1) couponAdminId = adminIds[couponAdmin - 1];
      console.log(couponAdmin);
      console.log(adminIds);
      const res = await axios.patch(
        `${BASE}/send/admin/generateCouponCodes2/${user.id}/${payment === 1 ? 0 : payment === 3 ? amount2 : am
        }`, {
        couponAdminId,
        couponStudents,
        payment,
        noOfStudents,
        noOfInterviews,
        status: "registered"
      }
      );
      console.log(res);
      toast.success(`Coupon generated Successfully`, {
        ...toastStyle.success,
      });
      setIsPaymentDone(false);
      setGenerateCouponLoading(false)
      window.location.reload(true);
    });
    // handleSend(admin);
  }

  const handleLeft = () => {
    if (page !== 1) {
      setAns(reg.slice(page * 100 - 200, (page - 1) * 100));
      setPage(page - 1);
      setRight(true);
      if (page - 1 === 1) {
        setLeft(false);
      }
    } else {
      setLeft(false);
    }
  };

  const handleRight = () => {
    if (page !== Math.ceil(reg.length / 100)) {
      setAns(reg.slice(page * 100, page * 100 + 100));
      setPage(page + 1);
      setLeft(true);
      if (page + 1 === Math.ceil(reg.length / 100)) {
        setRight(false);
      }
    } else {
      setRight(false);
    }
  };

  const handleCancel = (event) => {
    setCanProceed(false);
    setCouponAdmin("");
    setNoOfInterviews(0);
    setPayment(-1);
  }

  const handleCouponAdmin = (event) => {
    setCouponAdmin(event.target.value);
    console.log(adminNames[event.target.value - 1]);
    let couponAdminStudents = reg;
    let couponEmails = [];
    if (event.target.value && event.target.value !== "") couponAdminStudents = couponAdminStudents.filter((student) => student.addedBy === adminNames[event.target.value - 1]);
    couponAdminStudents = couponAdminStudents.filter((student) => student.sInterviews > 0);
    setNoOfStudents(couponAdminStudents.length);
    couponAdminStudents.forEach((student) => couponEmails.push(student.sEmail));
    setCouponStudents(couponEmails);
    console.log(couponEmails)
  }

  const handleChangeRadio2 = (event) => {
    setPayment(event.target.value);
  }

  return (
    <>
      {isloaded ?
        (<div>
          {/* <div
        className="w-auto text-lg px-2 py-1 border-2 rounded bg-white grid grid-cols-5 s-mobile-admin-searchbar"
        id="searchbar-input"
      >
        <input
          type="text"
          className="mx-1 py-2 outline-none w-auto col-span-4 text-base"
          value={name || ""}
          onChange={nameFilter}
          name="search"
          id="search"
          placeholder="Search Student by Name"
        />
        <div
          className="text-2xl w-12 cursor-pointer hover:text-sky-200 col-span-1 flex flex-row justify-between gap-1"
          id="search-bar"
        >
          {name ? (
            <IconButton sx={{ p: 0, minWidth: 0 }} onClick={nameDelete}>
              <DeleteIcon />
            </IconButton>
          ) : (
            <div className="h-6 w-6"></div>
          )}
          <IconButton sx={{ p: 0, minWidth: 0 }}>
            <SearchIcon />
          </IconButton>
        </div>
      </div> */}

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container alignItems="center" sx={{ backgroundColor: 'rgb(255, 222, 222)', padding: '5px', margin: "20px 0", maxWidth: "fit-content" }} className="rounded-lg">
              <Grid item>
                <Typography sx={{ padding: "0 10px" }}>Generate more Coupon Codes for the students added by </Typography>
              </Grid>
              <Grid item sx={{ paddingRight: "10px", paddingLeft: "10px" }}>
                <Select style={{ height: "40px", minWidth: "100px" }} value={couponAdmin} onChange={handleCouponAdmin}>
                  <MenuItem value={0}>All Admins</MenuItem>
                  {adminNames.map((item, index) => {
                    console.log('adminNames ', item);
                    return (
                      <MenuItem key={index} value={index + 1}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item>
                <Typography sx={{ padding: "0 10px 0 0" }}>who have registered till now</Typography>
              </Grid>
              {((couponAdmin !== undefined && couponAdmin !== "") &&
                <Grid item sx={{ paddingLeft: "10px" }}>
                  <Button variant="contained" onClick={() => { setCanProceed(true) }}>Proceed</Button>
                </Grid>
              )}
              {((couponAdmin !== undefined && couponAdmin !== "") &&
                <Grid item>
                  <Button variant="link" onClick={() => { handleCancel() }}>Cancel</Button>
                </Grid>
              )}
            </Grid>
          </div>

          {canProceed &&
            (
              <Typography
                variant="h5"
                component="h5"
                align="center"
                style={{
                  margin: "20px",
                  fontFamily: "'Ubuntu', sans-serif",
                  fontSize: '30px'
                }}
              >
                {`Generate more Coupon Codes for ${!couponAdmin ? "All students" : adminNames[couponAdmin - 1] === user.aFname + " " + user.aLname ? "Students added by me" : "Students added by " + adminNames[couponAdmin - 1]} who have registered`}
              </Typography>)
          }

          {canProceed &&
            (<form>
              <div className="profile-form-cont2">
                <div className="profile-form-text">
                  <div className="institution cust-row flex max-[600px]:flex-col max-[600px]:mb-[30px]">
                    <div style={{ width: '100%' }} className="cust-row flex max-[600px]:m-[0px]">
                      <div className="element w-50">
                        <label htmlFor="number">
                          Total No. of Students interested for Mock Interviews
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="number"
                          name="number"
                          className="border-2 w-[100%] rounded h-[56px] max-[600px]:h-[40px]"
                          placeholder="Number of Students"
                          displayEmpty
                          // error={fieldError.institute}
                          value={noOfStudents}
                          onChange={(e) => {
                            setNoOfStudents(e.target.value);
                          }}
                          autoComplete="nope"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="element">
                      <label htmlFor="interviews">
                        No. of Mock Interviews to be booked per student
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        value={noOfInterviews || ""}
                        name="interviews"
                        id="interviews"
                        onChange={(e) => {
                          setNoOfInterviews(e.target.value);
                        }}
                        className="h-[56px] max-[600px]:h-[40px]"
                        displayEmpty
                        MenuProps={MenuProps}
                        sx={{ width: 1 }}
                        inputProps={{ "aria-label": "Without label" }}
                        defaultValue=""
                      // disabled={
                      //   userData.isPayment === true ||
                      //   userData.number === 0 ||
                      //   ((payment === 1 ||
                      //     payment === "1" ||
                      //     payment === 3 ||
                      //     payment === "3") &&
                      //     userData.isPayment === false &&
                      //     userData.interviews === user.interviews &&
                      //     Number(payment) === Number(userData.iPaymentPref) &&
                      //     userData.number === user.number &&
                      //     amount !== 0)
                      // }
                      >
                        <MenuItem hidden disabled value="">
                          Select No. of Interviews
                        </MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <p style={{ maxWidth: '300px', width: '90%' }} className="bg-red-500 p-[10px] text-white rounded max-[600px]:m-[auto]">
                    Total Number of Interviews = {noOfStudents * noOfInterviews}
                  </p>
                  {noOfStudents > 0 && (
                    <div>
                      <Divider sx={{ marginTop: "20px" }}></Divider>
                      <FormControl
                        sx={{ mt: 1 }}
                        component="fieldset"
                        variant="standard"
                      >
                        <label
                          htmlFor="ibranches"
                          style={{
                            color: "#095a54",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          Your Plan
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <FormGroup>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              // onChange={handleChangeRadio}
                              label="Silver Plan: [No. of Students <= 500] : 10% Discount in Service Charges"
                              checked={noOfStudents <= 500}
                              disabled={
                                noOfStudents > 500
                                // || user.isPayment ||
                                // (userData.isPayment === false &&
                                //   userData.interviews === user.interviews &&
                                //   Number(payment) ===
                                //   Number(userData.iPaymentPref) &&
                                //   userData.number === user.number &&
                                //   amount !== 0)
                              }
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              // onChange={handleChangeRadio}
                              label="Gold Plan: [500 < No. of Students <= 900] : 25% Discount in Service Charges"
                              checked={
                                noOfStudents <= 900 && noOfStudents > 500
                              }
                              disabled={
                                noOfStudents > 900 || noOfStudents <= 500
                                // || userData.number <= 500 ||
                                // user.isPayment ||
                                // (userData.isPayment === false &&
                                //   userData.interviews === user.interviews &&
                                //   Number(payment) ===
                                //   Number(userData.iPaymentPref) &&
                                //   userData.number === user.number &&
                                //   amount !== 0)
                              }
                            />
                            <FormControlLabel
                              value={3}
                              control={<Radio />}
                              // onChange={handleChangeRadio}
                              label="Diamond Plan: [No. of Students > 900] : 50% Discount in Service Charges"
                              checked={noOfStudents > 900}
                              disabled={
                                noOfStudents <= 900
                                // || user.isPayment ||
                                // (userData.isPayment === false &&
                                //   userData.interviews === user.interviews &&
                                //   Number(payment) ===
                                //   Number(userData.iPaymentPref) &&
                                //   userData.number === user.number &&
                                //   amount !== 0)
                              }
                            />
                          </RadioGroup>
                        </FormGroup>
                      </FormControl>
                      <>
                        <p style={{ maxWidth: '300px', width: '90%' }} className="mt-5 p-[10px] text-white rounded bg-blue-500 min-w-[300px] max-[600px]:m-[auto]">
                          Total Amount ={" INR "}
                          {payment === 1
                            ? amount2
                            : payment === 3
                              ? amount2 * 2
                              : noOfStudents <= 500
                                ? Math.round(
                                  Number(noOfStudents) *
                                  Number(noOfInterviews) *
                                  489 *
                                  100
                                ) / 100
                                : noOfStudents <= 900 && noOfStudents > 500
                                  ? Math.round(
                                    Number(noOfStudents) *
                                    Number(noOfInterviews) *
                                    475 *
                                    100
                                  ) / 100
                                  : Math.round(
                                    Number(noOfStudents) *
                                    Number(noOfInterviews) *
                                    449 *
                                    100
                                  ) / 100}
                        </p>
                        <Divider sx={{ marginTop: "20px" }}></Divider>
                      </>
                    </div>
                  )}
                  <div>
                    {noOfStudents > 0 && (
                      <FormControl
                        sx={{ mt: 3 }}
                        component="fieldset"
                        variant="standard"
                      >
                        <label
                          htmlFor="ibranches"
                          style={{
                            color: "#095a54",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          Payment Preference
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {/* <FormLabel
                  component="legend"
                  sx={{
                    color: "#095a54",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Payment Preference <span style={{ color: "red" }}>*</span>
                </FormLabel> */}
                        <FormGroup>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              onChange={handleChangeRadio2}
                              label="Institute will pay the complete amount for mock interviews for all the students"
                              checked={payment === 1 || payment === "1"}
                            // disabled={
                            // user.isPayment ||
                            // (userData.isPayment === false &&
                            //   userData.interviews === user.interviews &&
                            //   Number(payment) ===
                            //   Number(userData.iPaymentPref) &&
                            //   userData.number === user.number &&
                            //   amount !== 0)
                            // }
                            />
                            <FormControlLabel
                              value={3}
                              control={<Radio />}
                              onChange={handleChangeRadio2}
                              label="Institute and Students will pay 50% each of the complete amount for each mock interview"
                              checked={payment === 3 || payment === "3"}
                            // disabled={
                            //   user.isPayment ||
                            //   (userData.isPayment === false &&
                            //     userData.interviews === user.interviews &&
                            //     Number(payment) ===
                            //     Number(userData.iPaymentPref) &&
                            //     userData.number === user.number &&
                            //     amount !== 0)
                            // }
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              onChange={handleChangeRadio2}
                              label="Students will pay the complete amount (on their own) for their mock interviews individually"
                              checked={payment === 2 || payment === "2"}
                            // disabled={
                            //   user.isPayment ||
                            //   (userData.isPayment === false &&
                            //     userData.interviews === user.interviews &&
                            //     Number(payment) ===
                            //     Number(userData.iPaymentPref) &&
                            //     userData.number === user.number &&
                            //     amount !== 0)
                            // }
                            />
                          </RadioGroup>
                        </FormGroup>
                      </FormControl>
                    )}
                    <Divider sx={{ marginTop: "20px" }}></Divider>
                  </div>
                  {(payment === 1 ||
                    payment === "1" ||
                    payment === 3 ||
                    payment === "3" ||
                    payment === 2 ||
                    payment === "2") &&
                    noOfInterviews > 0 &&
                    noOfStudents > 0 && (
                      <>
                        <p style={{ maxWidth: '300px', width: '90%' }} className="my-[20px] p-[10px] text-white rounded w-[90%] max-w-[250px]  bg-green-500 max-[600px]:mx-[auto]">
                          Amount Payable
                          = {" INR "}{amount2}
                        </p>
                        <Divider sx={{ marginTop: "20px" }}></Divider>
                      </>
                    )}
                </div>
              </div>

              {(payment === 1 ||
                payment === "1" ||
                payment === 3 ||
                payment === "3") &&
                noOfInterviews > 0 &&
                noOfStudents > 0 && (
                  <div className="relative flex justify-center mr-4 mt-2 mb-2">
                    <form>
                      <Button
                        variant="contained"
                        onClick={() => {
                          displayRazorpay2();
                          // await generateCoupon2();
                        }}
                        style={{
                          fontSize: "15px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        Pay & Generate Coupon Codes
                      </Button>
                    </form>
                    {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value=""
                IconComponent={() => (
                  <FontAwesomeIcon
                    className="text-center text-white border-none absolute -z-1"
                    icon={faChevronDown}
                    style={{ pointerEvents: "none" }}
                  />
                )}
                className="h-[39px] w-[40px] flex items-center justify-center bg-[#1876D1] text-white border-none rounded-[5px]"
              >
                <MenuItem
                  onClick={() => {
                    navigate("/admin/current-students");
                  }}
                  className=""
                  value="pay-later"
                >
                  PAY LATER
                </MenuItem>
              </Select> */}
                    {/* <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip className="tooltip ">
                    If you choose [Pay Later], you or any other admin <br />{" "}
                    can make the payment from the page of [Added Students]{" "}
                    <br /> on [Current Students] page
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  className="mr-[10px] rounded-full bg-white text-black p-2 w-[15px]"
                  icon={faInfo}
                  style={{
                    border: "1px solid black",
                    transform: "scale(0.75)",
                  }}
                />
              </OverlayTrigger> */}
                    {/* <div className="tt ml-[10px]">
                <InfoOutlined
                  sx={{
                    color: "rgb(83, 81, 81)",
                    fontSize: "20px",
                  }}
                />
                <span
                  style={{
                    background: "black",
                    color: "white",
                    padding: "3px 5px",
                    top: "-60px",
                    textAlign: "left",
                  }}
                  className="tttext"
                >
                  If you choose [Pay Later], you or any other admin <br />{" "}
                  can make the payment from the page of [Added Students]{" "}
                  <br /> on [Current Students] page
                </span>
              </div> */}
                  </div>
                )}
            </form>)
          }

          {(payment === 2 || payment === "2") && noOfInterviews > 0 && noOfStudents > 0 && (
            <div className="profile-form-cont2">
              <div className="profile-form-text">
                <div style={{ margin: '10px auto' }} className="relative flex my-2 max-[600px]:flex-col">
                  {
                    !generateCouponLoading && noOfStudents > 0 &&
                    <Button
                      color="secondary"
                      onClick={() => { generateCoupon2() }}
                      // disabled={generated || user.isGenerated}
                      variant="contained"
                      className="max-[600px]:my-[1rem]"
                      style={{ margin: '1rem auto' }}
                    >
                      Generate Coupon Codes
                    </Button>}
                  {generateCouponLoading &&
                    <>
                      <div style={{ height: '7em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div class="preloader">
                          <div class="preloader__square"></div>
                          <div class="preloader__square"></div>
                          <div class="preloader__square"></div>
                          <div class="preloader__square"></div>
                        </div>
                        <div class="status s-mobile-one-word-per-line">Generating Coupon Codes<span class="status__dot">.</span><span class="status__dot">.</span><span class="status__dot">.</span></div>
                      </div>
                    </>
                  }
                  {/* {(generated || user.isGenerated) && (
                  <div className="max-[600px]:text-[14px]">
                    <p style={{ paddingLeft: 10 }}>
                      <i
                        class="fa fa-check-circle"
                        style={{ color: "#2aad05" }}
                      ></i>{" "}
                      Coupon Codes Generated Successfully
                    </p>
                    <p style={{ paddingLeft: 10 }}>
                      <i
                        class="fa fa-check-circle"
                        style={{ color: "#2aad05" }}
                      ></i>{" "}
                      The coupon code(s) have been sent to the students
                      individually via Gmail and WhatsApp
                    </p>
                  </div>
                )} */}
                </div>
              </div>
            </div>
          )}

          <div
            className="w-auto text-lg px-2 py-1 bg-white rounded-md grid grid-cols-5 s-mobile-admin-searchbar"
            id="searchbar-input"
            style={{ marginTop: "2rem" }}
          >
            <input
              type="text"
              className="mx-1 py-2 outline-none w-auto col-span-4 text-base"
              value={name || ""}
              onChange={nameFilter}
              name="search"
              id="search"
              placeholder="Search Student by Name"
            />
            <div
              className="text-2xl w-12 cursor-pointer hover:text-sky-200 col-span-1 flex flex-row justify-between gap-1"
              id="search-bar"
            >
              {name ? (
                <IconButton
                  sx={{ p: "3px", minWidth: 0, border: "1px solid gray" }}
                  onClick={nameDelete}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <div className="h-6 w-6"></div>
              )}
              <IconButton
                sx={{
                  p: "3px",
                  minWidth: 0,
                  border: "1px solid gray",
                  marginLeft: "5px",
                }}
              >
                <SearchIcon />
              </IconButton>
            </div>
          </div>
          <div style={{ alignItems: 'flex-start', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} className="flex justify-between p-3 my-3 rounded-lg s-desktop-card s-desktop-card-mx">
            <div className="mt-0">
              <Select
                // multiple
                displayEmpty
                required
                value={year}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    return year
                  }
                  return "Year"
                }}
                onChange={yearFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={"1st year"}>1st year</MenuItem>
                <MenuItem value={"2nd year"}>2nd year</MenuItem>
                <MenuItem value={"3rd year"}>3rd year</MenuItem>
                <MenuItem value={"4th year"}>4th year</MenuItem>
                <MenuItem value={"5th year"}>5th year</MenuItem>
                <MenuItem value={"6th year"}>6th year</MenuItem>
              </Select>
              {(year && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: '0.75rem 0.75rem 0rem 0.75rem',
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={year}
                    label={year}
                    onDelete={() => {
                      yearDelete(year);
                    }}
                  />
                </Box>
              ) : null}
            </div>
            <div className="mt-0">
              <Select
                // multiple
                displayEmpty
                required
                value={branch}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    return branch
                  } else return "Branch"
                }}
                onChange={branchFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem hidden disabled value="">
                  Select Branch/Department
                </MenuItem>
                {branches?.map((branch, index) => (
                  <MenuItem key={index} value={branch}>
                    {branch}
                  </MenuItem>
                ))}
                {/* <MenuItem value="Aerospace Engineering">
              Aerospace Engineering
            </MenuItem>
            <MenuItem value="Applied Mechanics">Applied Mechanics</MenuItem>
            <MenuItem value="Biomedical Engineering ">
              Biomedical Engineering
            </MenuItem>
            <MenuItem value="Bioscience and Bioengineering">
              Bioscience and Bioengineering
            </MenuItem>
            <MenuItem value="Biotechnology">Biotechnology</MenuItem>
            <MenuItem value="Chemical Engineering">
              Chemical Engineering
            </MenuItem>
            <MenuItem value="Chemistry">Chemistry</MenuItem>
            <MenuItem value="Civil Engineering">Civil Engineering</MenuItem>
            <MenuItem value="Computer Science and Engineering">
              Computer Science and Engineering
            </MenuItem>
            <MenuItem value="Economics">Economics</MenuItem>
            <MenuItem value="Electrical Engineering">
              Electrical Engineering
            </MenuItem>
            <MenuItem value="Electrical and Electronics Engineering">
              Electrical and Electronics Engineering
            </MenuItem>
            <MenuItem value="Electronics and Communication Engineering">
              Electronics and Communication Engineering
            </MenuItem>
            <MenuItem value="Engineering Design">Engineering Design</MenuItem>
            <MenuItem value="Humanities and Social Sciences">
              Humanities and Social Sciences
            </MenuItem>
            <MenuItem value="Management/Business Studies">
              Management/Business Studies
            </MenuItem>
            <MenuItem value="Mathematics/Mathematics and Computing">
              Mathematics/Mathematics and Computing
            </MenuItem>
            <MenuItem value="Mechanical Engineering">
              Mechanical Engineering
            </MenuItem>
            <MenuItem value="Metallurgical and Materials Engineering">
              Metallurgical and Materials Engineering
            </MenuItem>
            <MenuItem value="Mining Engineering">Mining Engineering</MenuItem>
            <MenuItem value="Ocean Engineering">Ocean Engineering</MenuItem>
            <MenuItem value="Physics/Engineering Physics">
              Physics/Engineering Physics
            </MenuItem> */}
              </Select>
              {(branch && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: '0.75rem 0.75rem 0rem 0.75rem',
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={branch}
                    label={branch}
                    onDelete={() => {
                      branchDelete(branch);
                    }}
                  />
                </Box>
              ) : null}
            </div>
            <div className="mt-0">
              <div>
                <Select
                  // multiple
                  displayEmpty
                  required
                  value={program}
                  sx={{ width: 1 }}
                  renderValue={() => {
                    if (window.innerWidth < 600) {
                      return program
                    }
                    else return "Program"
                  }}
                  onChange={programFilter}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="B.Tech">B.Tech</MenuItem>
                  <MenuItem value="Dual Degree (B.Tech + M.Tech)">
                    Dual Degree (B.Tech + M.Tech)
                  </MenuItem>
                  <MenuItem value="Dual Degree (BS + MS)">
                    Dual Degree (BS + MS)
                  </MenuItem>
                  <MenuItem value="M.Tech">M.Tech</MenuItem>
                  <MenuItem value="MS (by Research)">MS (by Research)</MenuItem>
                  <MenuItem value="MBA">MBA</MenuItem>
                  <MenuItem value="M.Sc">M.Sc</MenuItem>
                  <MenuItem value="MA">MA</MenuItem>
                  <MenuItem value="Intergrated MA">Intergrated MA</MenuItem>
                  <MenuItem value="PhD">PhD</MenuItem>
                </Select>
              </div>
              {(program && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: '0.75rem 0.75rem 0rem 0.75rem',
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={program}
                    label={program}
                    onDelete={() => {
                      programDelete(program);
                    }}
                  />
                </Box>
              ) : null}
            </div>
            <div className="mt-0">
              <Select
                // multiple
                displayEmpty
                required
                value={appearing}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    return appearing
                  } else return "Appearing For"
                }}
                onChange={appearingFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={1}>Campus Placements</MenuItem>
                <MenuItem value={2}>Internship Interviews</MenuItem>
                <MenuItem value={3}>Both</MenuItem>
              </Select>
              {(appearing && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: '0.75rem 0.75rem 0rem 0.75rem',
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={appearing}
                    label={appearing}
                    onDelete={() => {
                      appearingDelete(appearing);
                    }}
                  />
                </Box>
              ) : null}
            </div>
            <div className="mt-0">

              <Select
                // multiple
                displayEmpty
                required
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    return search
                  } else return "Added By"
                }}
                onChange={searchingFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={0}>All</MenuItem>
                {adminNames.map((item, index) => {
                  console.log('adminNames ', item);
                  return (
                    <MenuItem key={index} value={index + 1}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              {(search && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: '0.75rem 0.75rem 0rem 0.75rem',
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={search}
                    label={search}
                    onDelete={() => {
                      searchingDelete(search);
                    }}
                  />
                </Box>
              ) : null}
            </div>
          </div>

          {/* mobile filters */}
          <div style={{ alignItems: 'flex-start', backgroundColor: 'rgb(255, 222, 222)', flexWrap: 'wrap', gap: '0.5rem', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: "0.75rem 0.5rem" }} className="flex justify-between bg-white p-3 my-3 rounded-lg s-mobile-card s-mobile-card-mx">

            {/* <div style={{alignItems:'flex-start',flexWrap:'wrap',gap:'0.5rem',padding:"0.75rem 0rem"}} className="flex justify-between start min-[600px]:items-start s-mobile-card"> */}
            <div className=" w-[48%] relative">
              <Select
                // multiple
                displayEmpty
                required
                value={year}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    if (year == null || year === "") return "Year"
                    return year
                  }
                  return "Year"
                }}
                onChange={yearFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={"1st year"}>1st year</MenuItem>
                <MenuItem value={"2nd year"}>2nd year</MenuItem>
                <MenuItem value={"3rd year"}>3rd year</MenuItem>
                <MenuItem value={"4th year"}>4th year</MenuItem>
                <MenuItem value={"5th year"}>5th year</MenuItem>
                <MenuItem value={"6th year"}>6th year</MenuItem>
              </Select>
              {(year && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: 2,
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={year}
                    label={year}
                    onDelete={() => {
                      yearDelete(year);
                    }}
                  />
                </Box>
              ) : null}
              {/* {
            (year && window.innerWidth < 600) ? (
              <button onClick={() => setYear(null)} style={{ right: 0, position: 'absolute', height: '100%', borderRadius: '4px' }} className="button-13 absolute"><IoCloseCircle style={{ display: 'inline-block', fontSize: '1rem' }} /></button>
            ) : null
          } */}
              {
                (year && window.innerWidth < 600) ? (
                  <button onClick={() => { setYear(null); yearDelete(year); }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                ) : null
              }
            </div>
            <div className="w-[48%] relative">
              <div className="">
                <Select
                  // multiple
                  displayEmpty
                  required
                  value={program}
                  sx={{ width: 1 }}
                  renderValue={() => {
                    if (window.innerWidth < 600) {
                      if (program == null || program === "") return "Program"
                      return program
                    }
                    else return "Program"
                  }}
                  onChange={programFilter}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="B.Tech">B.Tech</MenuItem>
                  <MenuItem value="Dual Degree (B.Tech + M.Tech)">
                    Dual Degree (B.Tech + M.Tech)
                  </MenuItem>
                  <MenuItem value="Dual Degree (BS + MS)">
                    Dual Degree (BS + MS)
                  </MenuItem>
                  <MenuItem value="M.Tech">M.Tech</MenuItem>
                  <MenuItem value="MS (by Research)">MS (by Research)</MenuItem>
                  <MenuItem value="MBA">MBA</MenuItem>
                  <MenuItem value="M.Sc">M.Sc</MenuItem>
                  <MenuItem value="MA">MA</MenuItem>
                  <MenuItem value="Intergrated MA">Intergrated MA</MenuItem>
                  <MenuItem value="PhD">PhD</MenuItem>
                </Select>
              </div>
              {(program && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: 2,
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={program}
                    label={program}
                    onDelete={() => {
                      programDelete(program);
                    }}
                  />
                </Box>
              ) : null}
              {
                (program && window.innerWidth < 600) ? (
                  <button onClick={() => { setProgram(null); programDelete(program); }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                ) : null
              }
            </div>
            <div className=" w-[100%] relative">
              <Select
                // multiple
                displayEmpty
                required
                value={branch}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    if (branch == null || branch === "") return "Branch"
                    return branch
                  } else return "Branch"
                }}
                onChange={branchFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem hidden disabled value="">
                  Select Branch/Department
                </MenuItem>
                {branches?.map((branch, index) => (
                  <MenuItem key={index} value={branch}>
                    {branch}
                  </MenuItem>
                ))}
                {/* <MenuItem value="Aerospace Engineering">
              Aerospace Engineering
            </MenuItem>
            <MenuItem value="Applied Mechanics">Applied Mechanics</MenuItem>
            <MenuItem value="Biomedical Engineering ">
              Biomedical Engineering
            </MenuItem>
            <MenuItem value="Bioscience and Bioengineering">
              Bioscience and Bioengineering
            </MenuItem>
            <MenuItem value="Biotechnology">Biotechnology</MenuItem>
            <MenuItem value="Chemical Engineering">
              Chemical Engineering
            </MenuItem>
            <MenuItem value="Chemistry">Chemistry</MenuItem>
            <MenuItem value="Civil Engineering">Civil Engineering</MenuItem>
            <MenuItem value="Computer Science and Engineering">
              Computer Science and Engineering
            </MenuItem>
            <MenuItem value="Economics">Economics</MenuItem>
            <MenuItem value="Electrical Engineering">
              Electrical Engineering
            </MenuItem>
            <MenuItem value="Electrical and Electronics Engineering">
              Electrical and Electronics Engineering
            </MenuItem>
            <MenuItem value="Electronics and Communication Engineering">
              Electronics and Communication Engineering
            </MenuItem>
            <MenuItem value="Engineering Design">Engineering Design</MenuItem>
            <MenuItem value="Humanities and Social Sciences">
              Humanities and Social Sciences
            </MenuItem>
            <MenuItem value="Management/Business Studies">
              Management/Business Studies
            </MenuItem>
            <MenuItem value="Mathematics/Mathematics and Computing">
              Mathematics/Mathematics and Computing
            </MenuItem>
            <MenuItem value="Mechanical Engineering">
              Mechanical Engineering
            </MenuItem>
            <MenuItem value="Metallurgical and Materials Engineering">
              Metallurgical and Materials Engineering
            </MenuItem>
            <MenuItem value="Mining Engineering">Mining Engineering</MenuItem>
            <MenuItem value="Ocean Engineering">Ocean Engineering</MenuItem>
            <MenuItem value="Physics/Engineering Physics">
              Physics/Engineering Physics
            </MenuItem> */}
              </Select>
              {(branch && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: 2,
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={branch}
                    label={branch}
                    onDelete={() => {
                      branchDelete(branch);
                    }}
                  />
                </Box>
              ) : null}
              {
                (branch && window.innerWidth < 600) ? (
                  <button onClick={() => { setBranch(null); branchDelete(branch); }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                ) : null
              }
            </div>
            <div className="w-[48%] relative">

              <Select
                // multiple
                displayEmpty
                required
                value={appearing}
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    if (appearing == null || appearing === "") return "Appearing For"
                    return appearing
                  } else return "Appearing For"
                }}
                onChange={appearingFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={1}>Campus Placements</MenuItem>
                <MenuItem value={2}>Internship Interviews</MenuItem>
                <MenuItem value={3}>Both</MenuItem>
              </Select>
              {
                (appearing && window.innerWidth < 600) ? (
                  <button onClick={() => { setAppearing(null); appearingDelete(appearing) }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                ) : null
              }
              {(appearing && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: 2,
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={appearing}
                    label={appearing}
                    onDelete={() => {
                      appearingDelete(appearing);
                    }}
                  />
                </Box>
              ) : null}
            </div>
            <div className=" w-[48%] relative">

              <Select
                // multiple
                displayEmpty
                required
                sx={{ width: 1 }}
                renderValue={() => {
                  if (window.innerWidth < 600) {
                    if (search == null) return "Added By"
                    return search
                  } else return "Added By"
                }}
                onChange={searchingFilter}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={0}>All</MenuItem>
                {adminNames.map((item, index) => {
                  return (
                    <MenuItem key={index} value={index + 1}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              {(search && window.innerWidth > 600) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    width: 1,
                    p: 2,
                  }}
                >
                  <Chip
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                    key={search}
                    label={search}
                    onDelete={() => {
                      searchingDelete(search);
                    }}
                  />
                </Box>
              ) : null}
              {
                (search && window.innerWidth < 600) ? (
                  <button onClick={() => { setSearch(null); searchingDelete(search); }} style={{ "right": "2.5px", "position": "absolute", "height": "29px", "top": "2px", "borderRadius": "4px", "padding": "0px 4px", "boxShadow": "0px 0px 4px #0000005e" }} className="button-13 absolute"><IoCloseCircle style={{ "display": "inline-block", "fontSize": "1.3rem", "transform": "translateY(-1.5px)" }} /></button>
                ) : null
              }
            </div>
          </div>




          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: '#dbf2fd' }} sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}>
                <TableRow className="table-admin">
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}>Sr. No.</TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}>Student's Name</TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Email
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Whatsapp No.
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Program
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Year
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Branch/Dept.
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Appearing for
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    No. of Mocks Left
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    <span style={{ width: '100%' }}> Coupon Codes</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className="tooltip">
                          The coupon codes which <br /> are struck-through have <br /> been already used by <br /> the respective student(s) <br /> to book mock interview(s)
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        className="mr-[10px] rounded-full bg-black text-white p-1 w-[20px] scale-75"
                        icon={faInfo}
                      />
                    </OverlayTrigger>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    No. of Mocks Done
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Resume
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }} align="right">
                    Added By
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}>
                {ans !== [] &&
                  ans?.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                        backgroundColor: `${index % 2 === 1 ? "#f2f2f2" : "#ffffff"}`,

                      }}
                    >
                      <TableCell
                        sx={{ textAlign: "center" }}
                        component="th"
                        scope="row"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        <a
                          style={{ color: "blue" }}
                          href="#"
                          onClick={() => {
                            window.open(
                              `${process.env.REACT_APP_FRONTEND_SERVER_URL}/admin/student/${item.id}`,
                              "_blank"
                            );
                          }}
                        >
                          {item.sName}
                        </a>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", wordBreak: 'break-all', minWidth: '250px' }} align="right">
                        {item.sEmail}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        {item.sWhatsapp}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        {item.program}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        {item.year}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        {item.branch}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        {item.appearingFor.one ? "Campus Placements" : ""} <br />
                        {item.appearingFor.two ? "Internship Interviews" : ""}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        {item.sInterviews === -1 ? "-" : item.sInterviews}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        {item.sCoupon.length !== 0
                          ? item.sCoupon.map((item2, index) => <span style={{ textDecoration: `${item.used[index] ? "line-through" : "none"}` }} >{item2.code} &nbsp;</span>)
                          : "-"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        {item.intDone}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        <span>
                          <a href="#" onClick={() => downloadPDF(item.resume)}>
                            <i class="fa fa-book" aria-hidden="true"></i>
                            Resume
                          </a>
                        </span>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        {item.addedBy}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="flex justify-center mt-3">
            <div className="flex bg-white p-[10px] rounded-xl">
              {/* {left && (
            <FontAwesomeIcon
              onClick={handleLeft}
              className="cursor-pointer"
              icon={faChevronLeft}
            />
          )}
          <p className="mx-[10px]">{page}</p>
          {right && (
            <FontAwesomeIcon
              onClick={handleRight}
              className="cursor-pointer"
              icon={faChevronRight}
            />
          )} */}
              <Pagination
                count={Math.ceil(reg?.length / 100)}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </div>
          </div>
        </div>) :
        (
          (
            <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
              <Oval
                height={120}
                width={120}
                color="#306bc9"
                wrapperStyle={{ padding: "50%, 50%" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#90caf9"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          )
        )}
    </>
  );
};

export default AddedStudents;