import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ZoomContext, ZoomMediaContext, ZoomUserContext } from "../../Context/globalContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FaVideo } from "react-icons/fa";
import { FaVideoSlash } from "react-icons/fa";
import { AiOutlineAudio } from "react-icons/ai";
import { AiOutlineAudioMuted } from "react-icons/ai";
import { MdOutlineSpatialAudio } from "react-icons/md";
import { PiPhoneDisconnectFill } from "react-icons/pi";
import { LuScreenShare } from "react-icons/lu";
import { LuScreenShareOff } from "react-icons/lu";
import { FcEndCall } from "react-icons/fc";
import { IoChatbubblesOutline } from "react-icons/io5";
import { FaChalkboardTeacher } from "react-icons/fa";
import { RiFullscreenFill } from "react-icons/ri";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import placeholderImg from "../../assets/placeholder-img.png";
import ChatComponent from "../Chat/Chat";
import audioConnected from "../../assets/join-call.mp3";
import "./VideoZoom.css";
import ZoomVideo, { VideoQuality } from "@zoom/videosdk";
import { Modal, Box, Button, Typography, IconButton } from "@mui/material";
import {
    BASE,
    VIDEO_CALL_UPLOAD_FILE,
    VIDEO_CALL_GET_TIMER,
    VIDEO_CALL_GET_CHATS,
    VIDEO_CALL_GET_FILE,
    GET_STUDENT_INTERVIEWS,
    GET_INTERVIEWER_INTERVIEWS,
    FEEDBACK,
} from "../../../../constants/endpoints";
import { GET_UPCOMING_MOCK_INTERVIEWS, GET_REQUEST_INTERVIEWS, GET_STUDENT_INFO, GET_INETRVIEWER_INFO } from "../../../../constants/endpoints";
import moment from "moment";
import PdfViewer from "../SidePanel/PdfViewer";
import SidePanel from "../SidePanel/SidePanel";
import Notifications from "./Notifications";
import { convertTimeToDecimal, giveTime } from "./utils";
import Timer from "./TimerNew";
import RateAndReview from "./RateAndReview";
import { Close } from "@mui/icons-material";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EndCallModal from "./EndCallModal";
import toast from "react-hot-toast";
import { toastStyle, downloadPDF, programValues } from "../../../../utility/helper";
import ErrorBoundary from "../ErrorBoundary";

const VideoZoom = () => {
    //variables related to interview details, timer, notification and other functionalities start here
    let studentDetails;
    let interviewerDetails;
    const displayStats = process.env.REACT_APP_ZOOM_SESSION_TIMERS == "ON" ? true : false;
    const FRONTEND_BASE = process.env.REACT_APP_FRONTEND_SERVER_URL;
    const [interviewInfo, setInterviewInfo] = useState(); //info about the interview itself
    const [interviewDetails, setInterviewDetails] = useState(); //to print the details on the top bar
    const [notification, setNotification] = useState(false); //if notification has to be shown or not
    const [notifications, setNotifications] = useState([]);
    const [nextInterviewTime, setNextInterviewTime] = useState(); //time for next interview
    const [seconds, setSeconds] = useState(0); //to store the seconds of interaction
    const [videoMicTime, setVideoMicTime] = useState({
        mic: 0,
        video: 0,
        screen_share: 0,
    });
    const [student, setStudent] = useState(); //check whether the person is student or not
    const [valid, setValid] = useState(false); //validity of the people attending the interview
    const [showPdf, setShowPdf] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [feedback, setFeedback] = useState(false);
    const [rated, setRated] = useState(false);
    const [meetingEnded, setMeetingEnded] = useState(false);
    const [idleTime, setIdleTime] = useState(0);
    const IDLE_TIME_LIMIT = 10 * 60;
    const NEXT_MOCK_WARNTIME = { 45: 35 * 60, 30: 27 * 60 }[interviewInfo?.sessDuration];
    const MOCK_END_WARNTIME = { 45: 55 * 60, 30: 25 * 60 }[interviewInfo?.sessDuration];
    const AUTO_CLOSE_TIME = { 45: 60 * 60 + 15, 30: 30 * 60 + 15 }[interviewInfo?.sessDuration];
    const FEEDBACK_TIME = { 45: 40 * 60, 30: 1000 * 60 }[interviewInfo?.sessDuration]; //Feedback not present for 30 minute sessions
    const [studentDetailsFromServer, setStudentDetailsFromServer] = useState(null);
    const [interviewerDetailsFromServer, setInterviewerDetailsFromServer] = useState(null);
    const [receivedParticipantDetails, setReceivedParticipantDetails] = useState(false);
    const [studentID, setStudentID] = useState(null);
    const [interviewerID, setInterviewerID] = useState(null);
    const [studentProfilePic, setStudentProfilePic] = useState(null);
    const [interviewerProfilePic, setInterviewerProfilePic] = useState(null);
    const [studentName, setStudentName] = useState(null);
    const [interviewerName, setInterviewerName] = useState(null);
    const [studentMicStatus, setStudentMicStatus] = useState(false);
    const [interviewerMicStatus, setInterviewerMicStatus] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [unread, setUnread] = useState(0);
    //variables related to interview details, timer, notification and other functionalities end here

    //variables related to coducting meeting and included functionalities start here

    const ASPECT_RATIO = 16 / 9;
    const CENTRAL_HEIGHT = window.innerWidth > 1000 ? 580 : window.innerHeight - 280;
    const CENTRAL_WIDTH = ASPECT_RATIO * CENTRAL_HEIGHT;
    const SIDE_HEIGHT = window.innerHeight > 1000 ? 100 : 80;
    const SIDE_WIDTH = ASPECT_RATIO * SIDE_HEIGHT;
    const selfViewRef = useRef(null);
    const userViewRef = useRef(null);
    const selfShareVideoRef = useRef(null);
    const selfShareCanvasRef = useRef(null);
    const userShareRefCanvas = useRef(null);
    const audioRef = useRef(null);

    const { mock_id: ROOM_ID, id: accountId, name: userName } = useParams();
    console.log("This is mock id:", ROOM_ID);

    const navigate = useNavigate();
    const client = useContext(ZoomContext);
    const mediaStream = useContext(ZoomMediaContext);
    const user = useContext(ZoomUserContext);

    const shareRef = useRef(null);
    const [showChatBox, setShowChatBox] = useState(false);
    const [videoStarted, setVideoStarted] = useState(false);
    const [audioStarted, setAudioStarted] = useState(false);
    const videoPlayerRef = useRef(null);
    const [isMuted, setIsMuted] = useState(false);
    const [videoGrid, setVideoGrid] = useState(null);
    const [isSAB, setIsSAB] = useState(false);
    const [elements, setElements] = useState([]);
    const [userVideoStarted, setUserVideoStarted] = useState(false);
    const [checkTwoParticipants, setCheckTwoParticipants] = useState(false);
    const [isShareScreen, setIsShareScreen] = useState(false);
    const [shareScreenDiv, setShareScreenDiv] = useState(null);
    const [isReceiveSharing, setIsReceiveSharing] = useState(false);
    const [isStartedShare, setIsStartedShare] = useState(false);
    const [activeSharingId, setActiveSharingId] = useState(0);
    const [isUserSharing, setIsUserSharing] = useState(false);
    const [sharedContentDimension, setSharedContentDimension] = useState({
        width: 0,
        height: 0,
    });
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isFullScreenVideo, setIsFullScreenVideo] = useState(false);
    const [endCallModalOpen, setEndCallModalOpen] = useState(false);
    const [showSidePanel, setShowSidePanel] = useState(true);
    const [isMouseMoving, setIsMouseMoving] = useState(false);
    const [isRightContainerVisible, setIsRightContainerVisible] = useState(true);
    const [rightMargin, setRightMargin] = useState("15px");
    const [isBreakpoint, setIsBreakpoint] = useState(false);
    const [distinctUserCriteria, setCriteria] = useState(false);
    const [participantDetails, setParticipantDetails] = useState([]);

    //variables related to coducting meeting and included functionalities end here

    const toggleRightContainerVisibility = () => {
        setIsRightContainerVisible(!isRightContainerVisible);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            console.log("Running periodic upload script");
            axios
                .get(VIDEO_CALL_GET_TIMER + "/" + ROOM_ID)
                .then(async (res) => {
                    const t = res.data.seconds;
                    if (t < seconds) {
                        try {
                            await axios
                                .post(`${BASE}/vc/timer/${ROOM_ID}`, {
                                    seconds: Number(seconds),
                                })
                                .catch((error) => {
                                    console.error("Error making POST request:", error);
                                });
                            console.log("Uploaded timer data successfully, uploading videoMicTime:", videoMicTime);
                        } catch (error) {
                            console.error("Error uploading timer:", error);
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error making GET request:", error);
                });
        }, 4.15 * 60 * 1000)
        return () => clearInterval(intervalId);
    }, [])

    useEffect(() => {
        let sFound = false;
        let iFound = false;

        client.getAllUser().map((participant) => {
            if (String(participant.displayName).startsWith("S")) {
                sFound = true;
            }
            if (String(participant.displayName).startsWith("I")) {
                iFound = true;
            }
        });
        if (sFound && iFound) {
            setCriteria(true);
        } else {
            setCriteria(false);
        }
    }, [client.getAllUser().length]);

    useEffect(() => {
        let mousemovetimer;

        const handleMouseMove = () => {
            setIsMouseMoving(true);

            // Clear any existing timer
            if (mousemovetimer) {
                clearTimeout(mousemovetimer);
            }

            // Set a timer to reset the state after 1 second of inactivity
            mousemovetimer = setTimeout(() => {
                setIsMouseMoving(false);
            }, 3000);
        };

        // Add event listener for mouse movement
        window.addEventListener("mousemove", handleMouseMove);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            if (mousemovetimer) {
                clearTimeout(mousemovetimer);
            }
        };
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            leaveCall(false);
            event.returnValue = "Are you sure you want to leave the session"; // This triggers the confirmation dialog in most browsers
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        window.addEventListener("popstate", handleBeforeUnload);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            window.removeEventListener("popstate", handleBeforeUnload);
        };
    }, []);

    const handleEndCallModalOpen = () => setEndCallModalOpen(true);
    const handleEndCallModalClose = () => setEndCallModalOpen(false);

    const isSupportWebCodecs = () => {
        console.log("WebCodecs enabled: ", typeof window.MediaStreamTrackProcessor === "function");
        return typeof window.MediaStreamTrackProcessor === "function";
    };

    const checkParticipants = (criteria) => {
        let participants = client.getAllUser();
        if (criteria === "enlist") {
            return participants;
        }
        return participants.length;
    };
    function waitForDomContentLoaded() {
        return new Promise((resolve) => {
            if (document.readyState === "complete") {
                resolve();
            } else {
                window.addEventListener("load", resolve, { once: true });
            }
        });
    }

    const marginCorrector = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth < 1198) {
            setShowSidePanel(false);
            setIsBreakpoint(true);
            setIsRightContainerVisible(true);
            return `${(1198 - viewportWidth) / 2 - 30}px`;
        } else {
            setIsBreakpoint(false);
            return "15px";
        }
    };

    useEffect(() => {
        const updateMargin = () => {
            setRightMargin(marginCorrector());
        };

        window.addEventListener("resize", updateMargin);
        updateMargin(); // Call initially to set the margin on component mount

        return () => {
            window.removeEventListener("resize", updateMargin);
        };
    }, []);

    // All logic related to getting timer information, interview details starts here

    useEffect(() => {
        const getInter = async () => {
            await axios
                .get(`${GET_UPCOMING_MOCK_INTERVIEWS + "/" + ROOM_ID}`)
                .then((res) => {
                    console.log("This is the first response of getInter() ==>", res);
                    setValid(true);
                })
                .catch((error) => {
                    alert("Invalid Mock ID");
                    setValid(false);
                    //   leaveHandler();
                });

            await axios
                .get(`${GET_REQUEST_INTERVIEWS + "/" + ROOM_ID}`)
                .then((res) => {
                    setInterviewInfo(res.data);
                    console.log("This are the details of interview ===> ", res.data);

                    let student_id = res.data.studId;
                    let interviewer_id = res.data.interviewerId;
                    setStudentID(student_id);
                    setInterviewerID(interviewer_id);
                    let fromTime = res.data.fromTime;
                    let toTime = res.data.toTime;
                    let dateOfInterview = res.data.date;

                    let dateArray = res.data.date.split("/");
                    let date = new Date();
                    let currentDateArray = [date.getDate(), date.getMonth() + 1, date.getFullYear(), date.getHours() + date.getMinutes() / 60];

                    let currDate = moment(date).format("DD/MM/YYYY");

                    if (accountId === interviewer_id) {
                        axios
                            .post(`${BASE}/vc/timer/interviewer/${ROOM_ID}`, {
                                interviewer: true,
                            })
                            .catch((error) => {
                                console.error("Error making POST request:", error);
                            });

                        // getting upcoming interviews
                        axios
                            .get(GET_INTERVIEWER_INTERVIEWS + "/" + accountId)
                            .then((res) => {
                                let time = 1000;

                                res.data.map((ele) => {
                                    if (ele.date === dateOfInterview && ele.fromTime <= toTime + 0.75 && ele.fromTime > fromTime) {
                                        time = time > ele.fromTime ? ele.fromTime : time;
                                    }

                                    if (time != 1000) {
                                        setNextInterviewTime(time);
                                        setNotification(true);
                                    }
                                });
                            })
                            .catch((err) => {
                                console.log(err);
                            });

                        // getting info of student
                        axios
                            .get(`${GET_STUDENT_INFO}/${student_id}`)
                            .then((res) => {
                                studentDetails = res.data;
                                // console.log(studentDetails, "----s-t-u-d--")
                                setInterviewDetails({ student: res.data });

                                // mic and video timer for interviewer
                                axios
                                    .get(VIDEO_CALL_GET_TIMER + "/" + ROOM_ID)
                                    .then((res) => {
                                        // console.log(res.data, "----o-0-9-0--");
                                        setVideoMicTime({
                                            mic: res.data?.interviewer_mic,
                                            video: res.data?.interviewer_video,
                                            screen_share: res.data?.interviewer_screen_share,
                                        });
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            })
                            .catch((err) => {
                                alert("Invalid Student ID");
                                setValid(false);
                                preLeaveUpload();
                            });
                    }

                    // if user is student
                    else if (accountId === student_id) {
                        setStudent(true);
                        setValid(true);

                        axios
                            .post(`${BASE}/vc/timer/student/${ROOM_ID}`, {
                                student: true,
                            })
                            .catch((error) => {
                                console.error("Error making POST request:", error);
                            });

                        // getting upcoming interviews
                        axios
                            .get(GET_STUDENT_INTERVIEWS + "/" + accountId)
                            .then((res) => {
                                let time = 1000;

                                res.data.map((ele) => {
                                    if (dateOfInterview === ele.date && ele.fromTime <= toTime + 0.75 && ele.fromTime > fromTime) {
                                        // $(".bell__point").removeClass("hide")
                                        time = time > ele.fromTime ? ele.fromTime : time;
                                    }

                                    if (time != 1000) {
                                        console.log("From time ==> ", ele.fromTime);
                                        console.log("Next Interview =>", time);
                                        setNextInterviewTime(time);
                                        setNotification(true);
                                    }
                                });
                            })
                            .catch((err) => {
                                console.log(err);
                            });

                        // getting interviewer info
                        axios
                            .get(`${GET_INETRVIEWER_INFO}/${interviewer_id}`)
                            .then((res) => {
                                interviewerDetails = res.data;
                                // console.log(interviewerDetails, "----i-n-t-w--")

                                setInterviewDetails({ interviewer: res.data });

                                //mic and video timer for student
                                axios
                                    .get(VIDEO_CALL_GET_TIMER + "/" + ROOM_ID)
                                    .then((res) => {
                                        // Check for NaN values and set them to zero if needed
                                        const micValue = isNaN(res.data?.student_mic) ? 0 : res.data?.student_mic;
                                        const videoValue = isNaN(res.data?.student_video) ? 0 : res.data?.student_video;
                                        const screenShareValue = isNaN(res.data?.student_screen_share) ? 0 : res.data?.student_screen_share;

                                        // Update state with the adjusted values
                                        setVideoMicTime({
                                            mic: micValue,
                                            video: videoValue,
                                            screen_share: screenShareValue,
                                        });
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            })
                            .catch((err) => {
                                alert("Invalid Interviewer ID");
                                // setValid(false);
                                // leaveHandler();
                            });
                    } else {
                        alert("You aren't authorized to enter this meet");

                        setValid(false);
                        preLeaveUpload();
                    }
                })
                .catch((err) => {
                    alert("No such interview");

                    setValid(false);
                    preLeaveUpload();
                });
        };
        console.log("==================== Fired ======================");
        getInter();

        const timer = () => {
            axios
                .get(VIDEO_CALL_GET_TIMER + "/" + ROOM_ID)
                .then((res) => {
                    const t = res.data.seconds;
                    if (t) {
                        setSeconds(res.data.seconds);
                    }
                })
                .catch((error) => {
                    console.error("Error making GET request:", error);
                });
        };
        setTimeout(() => {
            timer();
        }, 1000);
    }, []);

    useEffect(() => {
        const currentDate = new Date();
        const currentTime24Format = convertTimeToDecimal(currentDate);
        console.log("Current time ===> ", currentTime24Format);
        const delay = nextInterviewTime - currentTime24Format;
        if (delay >= 0) {
            const delayMilliseconds = Math.floor(delay * 60 * 60 * 1000 - 5 * 60 * 1000);
            console.log("This is the time till next interview ==>", delayMilliseconds);
            setTimeout(() => {
                setNotifications([
                    ...notifications,
                    {
                        message: `You have another mock interview at ${giveTime(nextInterviewTime)}. ${accountId.startsWith("S")
                            ? `Please join it after
                      completing and rating the ongoing mock interview`
                            : `Please join it after
                      completing the ongoing mock interview and sending the
                      feedback to the student`
                            }`,
                    },
                ]);
                setIsOpen(true);
            }, delayMilliseconds);
        }
    }, [nextInterviewTime]);

    useEffect(() => {
        if (seconds >= AUTO_CLOSE_TIME) {
            leaveCall(true);
            alert("This session has ended");
        }
    }, [seconds]);

    useEffect(() => {
        if (seconds > MOCK_END_WARNTIME) {
            setNotifications([
                ...notifications,
                {
                    message: `This mock interview will end in 5 minutes`,
                },
            ]);
            setIsOpen(true);
        }
    }, [seconds > MOCK_END_WARNTIME]);

    useEffect(() => {
        console.log(FEEDBACK_TIME, seconds);
        if (seconds > FEEDBACK_TIME) {
            if (accountId.startsWith("I") && !feedback) {
                axios
                    .get(`${FEEDBACK}/${ROOM_ID}`)
                    .then((res) => {
                        if (res.data) setFeedback(true);
                    })
                    .catch((err) => {
                        setFeedback(false);
                    });
            } else if (accountId.startsWith("S") && !rated) {
                axios
                    .get(`${BASE}/send/getRating/${ROOM_ID}`)
                    .then((res) => {
                        if (res.data) {
                            setRated(true);
                        }
                    })
                    .catch((err) => {
                        setRated(false);
                    });
            }
        }
        return;
    }, [seconds]);

    useEffect(() => {
        if (idleTime > IDLE_TIME_LIMIT) {
            toast.error("Idle Time exceeded. Try joining after some time", {
                ...toastStyle.error,
            });
            setTimeout(() => {
                leaveCall(false);
            }, 2000);
        }
    }, [idleTime > IDLE_TIME_LIMIT]);

    useEffect(() => {
        const setPlaceholderValues = async () => {
            if (studentID) {
                await axios
                    .get(`${GET_STUDENT_INFO}/${studentID}`)
                    .then((res) => {
                        setStudentDetailsFromServer(res.data);
                    })
                    .catch((error) => {
                        console.log("Error in fetching student details  ==>", error);
                    });
            }
            if (interviewerID) {
                await axios
                    .get(`${GET_INETRVIEWER_INFO}/${interviewerID}`)
                    .then((res) => {
                        setInterviewerDetailsFromServer(res.data);
                    })
                    .catch((error) => {
                        console.log("Error in fetching student details  ==>", error);
                    });
            }
            setReceivedParticipantDetails(true);
        };
        if (studentID && interviewerID) setPlaceholderValues();
    }, [studentID, interviewerID]);

    useEffect(() => {
        if (receivedParticipantDetails) {
            console.log("===============>>><<<============", studentDetailsFromServer.photo, interviewerDetailsFromServer.photo);
            setStudentProfilePic(studentDetailsFromServer.photo);
            setInterviewerProfilePic(interviewerDetailsFromServer.photo);
            setStudentName(studentDetailsFromServer.fname + " " + studentDetailsFromServer.lname)
            setInterviewerName(interviewerDetailsFromServer.fname + " " + interviewerDetailsFromServer.lname)
        }
    }, [receivedParticipantDetails]);

    // All logic related to getting timer information, interview details ends here

    const startVideoButton = useCallback(async () => {
        if (!videoStarted) {
            setVideoStarted(true);

            if (mediaStream.isRenderSelfViewWithVideoElement()) {
                mediaStream
                    .startVideo({
                        videoElement: document.querySelector("#self-view-video"),
                    })
                    .then(() => {
                        // video successfully started and rendered
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                mediaStream
                    .startVideo()
                    .then(() => {
                        mediaStream
                            .renderVideo(selfViewRef.current, client.getCurrentUserInfo().userId, CENTRAL_WIDTH, CENTRAL_HEIGHT, 0, 0, 3)
                            .then(() => {
                                // video successfully started and rendered
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } else {
            mediaStream
                .stopVideo()
                .then(() => {
                    mediaStream.detachVideo(client.getCurrentUserInfo().userId);
                    setVideoStarted(false);
                })
                .catch((error) => {
                    console.log("This error happened while closing video ==>", error);
                });
        }
    }, [mediaStream, videoStarted, client, isSAB]);

    //Render User Videos
    const renderUserVideo = async (payload) => {
        try {
            let participants = client.getAllUser();
            console.log("Call participants: ", participants);
            console.log("Condition is :", payload[0].userId !== client.getCurrentUserInfo().userId, payload[0].bVideoOn === true);
            if (payload[0].userId !== client.getCurrentUserInfo().userId && payload[0].bVideoOn === true) {
                console.log("Rendering of User Video has started", payload[0]);
                waitForDomContentLoaded()
                    .then(async () => {
                        try {
                            mediaStream.renderVideo(userViewRef.current, payload[0].userId, CENTRAL_WIDTH, CENTRAL_HEIGHT, 0, 0, VideoQuality.Video_720P);
                            console.log("Attached video for ==>", payload[0].userId);
                            setUserVideoStarted(true);
                        } catch (error) {
                            console.log("Some error occured ==>", error);
                        }
                    })
                    .catch((error) => console.log("Something wrong happened ===> ", error));
            }
            if (payload[0].bVideoOn === false && payload[0].userId !== client.getCurrentUserInfo().userId) {
                console.log("Detached video for ==>", payload[0].userId);
                await mediaStream.stopRenderVideo(userViewRef.current, payload[0].userId);
                setUserVideoStarted(false);
            }
        } catch (error) {
            console.log("This error happened in rendering video ===> ", error);
        }
    };

    //useEffect with dependency on audioStarted to connect to audio once user has joined meet
    useEffect(() => {
        let audioDecode;
        let audioEncode;
        const connectAudio = async () => {
            var isSafari = window.safari !== undefined;
            console.log("Is this Safari Browser =>", isSafari);
            if (isSafari && !audioStarted) {
                // desktop Safari, check if desktop Safari has initialized audio
                if (audioEncode && audioDecode) {
                    // desktop Safari has initialized audio, continue to start audio
                    await mediaStream.startAudio({
                        backgroundNoiseSuppression: true,
                    });
                    // await mediaStream.enableOriginalSound(true)
                    setAudioStarted(true);
                    setIsMuted(false);
                } else {
                    // desktop Safari has not initialized audio, retry or handle error
                    console.log("Safari audio has not finished initializing");
                }
            } else if (!isSafari && !audioStarted) {
                await mediaStream.startAudio({
                    backgroundNoiseSuppression: true,
                });
                // await mediaStream.enableOriginalSound(true)
                setAudioStarted(true);
                setIsMuted(false);
            }
        };
        connectAudio();
        if (audioStarted) audioRef.current.play();
    }, [audioStarted]);

    const toggleAudio = useCallback(async () => {
        let audioDecode;
        let audioEncode;

        // event listener to see when desktop Safari has initialized audio
        var isSafari = window.safari !== undefined;
        console.log("Is this Safari Browser =>", isSafari);
        if (isSafari && !audioStarted) {
            // desktop Safari, check if desktop Safari has initialized audio
            if (audioEncode && audioDecode) {
                // desktop Safari has initialized audio, continue to start audio
                await mediaStream.startAudio({
                    backgroundNoiseSuppression: true,
                });
                // await mediaStream.enableOriginalSound(true)
                setAudioStarted(true);
                setIsMuted(false);
            } else {
                // desktop Safari has not initialized audio, retry or handle error
                console.log("Safari audio has not finished initializing");
            }
        } else if (!isSafari && !audioStarted) {
            await mediaStream.startAudio({
                backgroundNoiseSuppression: true,
            });
            // await mediaStream.enableOriginalSound(true)
            setAudioStarted(true);
            setIsMuted(false);
        } else {
            if (isMuted) {
                await mediaStream.unmuteAudio();
                setIsMuted(false);
                console.log("======================");
                console.log("Mic is now switched on");
            } else {
                await mediaStream.muteAudio();
                setIsMuted(true);
                console.log("======================");
                console.log("Mic is now switched off");
            }
        }
    }, [client, mediaStream, audioStarted, isMuted]);

    const shareScreen = useCallback(async () => {
        if (isShareScreen) {
            try {
                // if (checkParticipants() > 1) {
                //     swapElements(rightDiv1Ref)
                // } else {
                // temp disabled swapElements(centralDivRef);
                // }
                // swapElements(rightDiv2Ref)
                await mediaStream.stopShareScreen();
                waitForDomContentLoaded()
                    .then(() => {
                        if (selfShareVideoRef.current) {
                            // Stop and clear the video content
                            selfShareVideoRef.current.pause();
                            selfShareVideoRef.current.src = "";
                            selfShareVideoRef.current.load();
                        }

                        if (selfShareCanvasRef.current) {
                            // Clear the canvas content
                            const context = selfShareCanvasRef.current.getContext("2d");
                            context.clearRect(0, 0, selfShareCanvasRef.current.width, selfShareCanvasRef.current.height);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error("Error clearing share screen children", error);
            }
            setIsShareScreen(false);
        } else {
            if (isSupportWebCodecs()) {
                try {
                    // if (shareScreenDiv) shareScreenDiv.innerHTML = "";
                } catch (error) {
                    console.log("Error clearing share screen children", error);
                }
                try {
                    await mediaStream.startShareScreen(selfShareVideoRef.current);
                    setIsShareScreen(true);
                } catch (error) {
                    console.error("Some error happened while sharing screen ==>", error);
                    if (error?.reason === "user deny screen share") {
                        setIsShareScreen(false);
                    }
                }
            } else {
                try {
                    // await mediaStream.startShareScreen(document.querySelector("self-share-canvas"));
                    await mediaStream.startShareScreen(selfShareCanvasRef.current);
                    setIsShareScreen(true);
                } catch (error) {
                    console.error("Some error happened while sharing screen ==>", error);
                    if (error?.reason === "user deny screen share") {
                        setIsShareScreen(false);
                    }
                }
            }
        }
    }, [isShareScreen]);

    const handleRenderUserScreenShare = useCallback(
        async (payload) => {
            // if (payload[0].userId !== client.getCurrentUserInfo().userId && payload[0].sharerOn === true) {
            //     console.log("Rendering user's shared screen ====>", payload);

            //     console.log("✌️");
            //     setIsUserSharing(true);
            //     // swapElements(rightDiv3Ref)

            //     try {
            //         await mediaStream.startShareView(
            //             userShareRefCanvas.current,
            //             payload[0].userId
            //         );
            //     } catch (error) {
            //         console.log(error);
            //     }
            // }
            if (payload[0].userId !== client.getCurrentUserInfo().userId && payload[0].sharerOn === false) {
                console.log("😒");
                setIsUserSharing(false);
                // swapElements(centralDivRef)
                try {
                    await mediaStream.stopShareView(userShareRefCanvas.current, payload[0].userId);
                    console.log("🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️");
                    // temp disabled swapElements(centralDivRef);
                    // temp disabled swapElements(centralDivRef);
                    // temp disabled swapElements(centralDivRef);
                    // temp disabled swapElements(centralDivRef);
                } catch (error) {
                    console.log(error);
                }
            }
        },
        [isUserSharing, client, mediaStream]
    );

    const preLeaveUpload = async () => {
        console.log("Starting preLeaveUpload");

        const url = student ? `${BASE}/vc/video-mic/student/timer/${ROOM_ID}` : `${BASE}/vc/video-mic/interviewer/timer/${ROOM_ID}`;
        try {
            // Upload timer data
            await axios
                .post(`${BASE}/vc/timer/${ROOM_ID}`, {
                    seconds: Number(seconds),
                })
                .catch((error) => {
                    console.error("Error making POST request:", error);
                });
            console.log("Uploaded timer data successfully, uploading videoMicTime:", videoMicTime);
        } catch (error) {
            console.error("Error uploading timer:", error);
        }
        try {
            // Upload videoMicTime data
            await axios.put(url, videoMicTime).catch((error) => {
                console.error("Error making PUT request:", error);
            });
            console.log("Upload successful");
        } catch (error) {
            console.error("Error uploading videoMicTime:", error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    };

    const postLeaveNavigationHandler = () => {
        let finalUrl;
        console.log("postLeaveNavigationHandler called, seconds:", seconds);

        if (idleTime >= IDLE_TIME_LIMIT) {
            if (accountId.startsWith("S")) {
                finalUrl = `/midend/student/vc/${ROOM_ID}/idle_time_exceeded`;
            } else {
                finalUrl = `/midend/interviewer/vc/${ROOM_ID}/idle_time_exceeded`;
            }
        } else if (seconds >= FEEDBACK_TIME) {
            if (seconds >= AUTO_CLOSE_TIME) {
                if (accountId.startsWith("S")) {
                    finalUrl = `/end/student/vc/${ROOM_ID}/session_ended`;
                } else {
                    finalUrl = `/end/interviewer/vc/${ROOM_ID}/session_ended`;
                }
            } else {
                if (accountId.startsWith("S")) {
                    finalUrl = `/end/student/vc/${ROOM_ID}/send_feedback`;
                } else {
                    finalUrl = `/end/interviewer/vc/${ROOM_ID}/send_feedback`;
                }
            }
        } else {
            if (accountId.startsWith("S")) {
                finalUrl = `/midend/student/vc/${ROOM_ID}/disconnected`;
            } else {
                finalUrl = `/midend/interviewer/vc/${ROOM_ID}/disconnected`;
            }
        }
        console.log("Navigating to:", finalUrl);
        navigate(finalUrl);
    };

    const leaveCall = async (end) => {
        // client.off("peer-video-state-change", (payload) =>
        //   console.log("This is on leave call ===>", payload)
        // );
        // const mediaStream = client.getMediaStream();
        console.log("Reached here 555555555");
        try {
            if (end !== true) preLeaveUpload();
        } catch (error) {
            console.log("🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️👋");
        }
        try {
            console.log("👋👋👋👋👋");

            // const element = await mediaStream.detachVideo(user?.userId);
            // await mediaStream.stopShareView();
            // await mediaStream.stopAudio();
            // console.log(element);
            console.log("End Call ? ==>", end);
            if (end === true) {
                const sessionId = client.getSessionInfo().sessionId;
                console.log("This is the session ID ====>", sessionId);
                let encodedSessionId = sessionId.includes("/") || sessionId.includes("+") ? encodeURIComponent(encodeURIComponent(sessionId)) : sessionId;
                console.log("This is the double encoded session ID ====>", encodedSessionId);
                try {
                    const response = await axios
                        .post(`${BASE}/zoom/end-session`, {
                            session_id: sessionId,
                        })
                        .catch((error) => {
                            console.error("Error making POST request:", error);
                        });
                    console.log("Response after ending session ===>", response);
                } catch (error) {
                    console.log("This error happened while closing the connection ==>", error);
                    // leaveCall(false);
                }

                // navigate("/");
            } else {
                await client.leave();
                postLeaveNavigationHandler();
            }
        } catch (error) {
            console.log("Error in leaving call", error);
        }
    };

    const handleUserUpdated = useCallback(async (payload) => {
        console.log("This is user-updated ====>", payload);
        setParticipantDetails(client.getAllUser())
        if (payload && "bVideoOn" in payload[0]) {
            console.log("renderUserVideo() has been called");
            renderUserVideo(payload);
        }
        if (payload && "sharerOn" in payload[0]) {
            handleRenderUserScreenShare(payload);
        }
        if (client.getAllUser().length > 1) {
            setCheckTwoParticipants(true);
        }
    }, []);
    const handleUserAdded = useCallback(async (payload) => {
        console.log("This is user-added ====>", payload);
        if (payload && payload[0].displayName === accountId) {
            console.log("Leave Call when duplicate user joins called =========");
            setTimeout(() => {
                leaveCall(false); //To avoid same user joining from multiple devices
            }, 2000);
        }
        // temp disabled swapElements(rightDiv1Ref);
    }, []);
    const handleUserRemoved = useCallback(async (payload) => {
        console.log("This is user-removed ====>", payload);
        // temp disabled swapElements(centralDivRef);
        let participants = client.getAllUser();
        console.log("These are the participants ===>", participants);
        if (participants && participants.length > 1) {
            setCheckTwoParticipants(true);
        } else {
            setCheckTwoParticipants(false);
        }
    }, []);
    const handleConnectionChange = useCallback(async (payload) => {
        console.log("The connection changed===>", payload);

        if (payload && payload.state === "Closed") {
            console.log("This session is now closed");
            setMeetingEnded(true);

            // postLeaveNavigationHandler();
        }

        if (payload.state === "Connected") {
            console.log("The session has been connected");
        }
    }, []);
    const handleActiveShareChange = useCallback((payload) => {
        console.log("Active share change has been called ===> 🙌", payload);
        if (payload.state === "Active" && payload.userId != client.getCurrentUserInfo().userId) {
            setIsUserSharing(true);
            const tempFunction = async () => {
                await mediaStream.startShareView(userShareRefCanvas.current, payload.userId);
            };
            tempFunction();

            // temp disabled swapElements(rightDiv3Ref);
        }
        // if (payload.state === "Inactive" && payload.userId != client.getCurrentUserInfo().userId) {
        //     setIsUserSharing(false);
        //     // swapElements(rightDiv1Ref)
        //     mediaStream.stopShareView();
        // }
    }, []);
    const handlePassiveStopSharing = useCallback((payload) => {
        // swapElements(rightDiv2Ref)
        console.log("This is passively stop share ", payload);
        // if (checkParticipants() > 1) {
        //     swapElements(rightDiv1Ref)
        // } else {
        // }
        shareScreen();
        setIsShareScreen(false);
    }, []);

    // UseEffect hooks to handle swapping functionality starts here
    useEffect(() => {
        if (isShareScreen) swapElements(rightDiv2Ref);
        else if (checkTwoParticipants) swapElements(rightDiv1Ref);
        else swapElements(centralDivRef);
    }, [isShareScreen]);

    useEffect(() => {
        if (isUserSharing) swapElements(rightDiv3Ref);
        else if (checkTwoParticipants) swapElements(rightDiv1Ref);
        else swapElements(centralDivRef);
    }, [isUserSharing]);

    useEffect(() => {
        if (checkTwoParticipants && distinctUserCriteria) swapElements(rightDiv1Ref);
        else swapElements(centralDivRef);
    }, [checkTwoParticipants]);
    // UseEffect hooks to handle swapping functionality ends here
    const [uploadedData, setUploadedData] = useState(false);
    useEffect(() => {
        try {
            console.log("Starting preLeaveUpload...");
            if (meetingEnded) {
                preLeaveUpload();
                setUploadedData(true);
            }
            console.log("preLeaveUpload finished successfully.");
        } catch (error) {
            console.log("Error during preLeaveUpload:", error);
        }
    }, [meetingEnded]);

    useEffect(() => {
        if (uploadedData) {
            postLeaveNavigationHandler();
        }
    }, [uploadedData]);
    useEffect(() => {
        client.on("user-updated", handleUserUpdated);
        client.on("user-added", handleUserAdded);
        client.on("user-removed", handleUserRemoved);
        client.on("connection-change", handleConnectionChange);
        client.on("active-share-change", handleActiveShareChange);
        client.on("passively-stop-share", handlePassiveStopSharing);
        return () => {
            client.off("user-updated", handleUserUpdated);
            client.off("user-added", handleUserAdded);
            client.off("user-removed", handleUserRemoved);
            client.off("connection-change", handleConnectionChange);
            client.off("active-share-change", handleActiveShareChange);
            client.off("passively-stop-share", handlePassiveStopSharing);
        };
    }, [client, mediaStream, handleUserUpdated, handleUserAdded, handleUserRemoved, handleActiveShareChange, handlePassiveStopSharing, handleConnectionChange]);

    useEffect(() => {
        console.log("I am running to check the number of participants");
        let participants = client.getAllUser();
        Promise.all(
            participants.map((participant) => {
                renderUserVideo([participant]);
                // handleRenderUserScreenShare([participant])
            })
        );
        if (participants && participants.length > 1) {
            setCheckTwoParticipants(true);
            console.log("There are two participants ==>", participants);

            // if (!trackCenter || trackCenter != rightDiv1Ref) {
            //     console.log("I am swapping user video with self video");
            //     swapElements(rightDiv1Ref)
            // }
        } else {
            setCheckTwoParticipants(false);
        }
    }, [client.getAllUser()]);

    const toggleFullScreen = () => {
        if (!userShareRefCanvas.current) return;

        // if (!isFullScreen) {
        if (userShareRefCanvas.current.requestFullscreen) {
            userShareRefCanvas.current.requestFullscreen();
        } else if (userShareRefCanvas.current.mozRequestFullScreen) {
            // Firefox
            userShareRefCanvas.current.mozRequestFullScreen();
        } else if (userShareRefCanvas.current.webkitRequestFullscreen) {
            // Chrome, Safari and Opera
            userShareRefCanvas.current.webkitRequestFullscreen();
        } else if (userShareRefCanvas.current.msRequestFullscreen) {
            // IE/Edge
            userShareRefCanvas.current.msRequestFullscreen();
        }
        setIsFullScreen(true);
        // } else {
        //     if (document.exitFullscreen) {
        //         document.exitFullscreen();
        //     } else if (document.mozCancelFullScreen) { // Firefox
        //         document.mozCancelFullScreen();
        //     } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        //         document.webkitExitFullscreen();
        //     } else if (document.msExitFullscreen) { // IE/Edge
        //         document.msExitFullscreen();
        //     }
        //     setIsFullScreen(false);
        // }
    };
    const toggleFullScreenUserVideo = () => {
        if (!userViewRef.current) return;

        // if (!isFullScreen) {
        if (userViewRef.current.requestFullscreen) {
            userViewRef.current.requestFullscreen();
        } else if (userViewRef.current.mozRequestFullScreen) {
            // Firefox
            userViewRef.current.mozRequestFullScreen();
        } else if (userViewRef.current.webkitRequestFullscreen) {
            // Chrome, Safari and Opera
            userViewRef.current.webkitRequestFullscreen();
        } else if (userViewRef.current.msRequestFullscreen) {
            // IE/Edge
            userViewRef.current.msRequestFullscreen();
        }
        setIsFullScreenVideo(true);
        // } else {
        //     if (document.exitFullscreen) {
        //         document.exitFullscreen();
        //     } else if (document.mozCancelFullScreen) { // Firefox
        //         document.mozCancelFullScreen();
        //     } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        //         document.webkitExitFullscreen();
        //     } else if (document.msExitFullscreen) { // IE/Edge
        //         document.msExitFullscreen();
        //     }
        //     setIsFullScreen(false);
        // }
    };

    const calculateLeftPosition = () => {
        const percentageLeft = window.innerWidth * 0.123;
        return showSidePanel ? Math.max(160, percentageLeft) : "0px";
    };

    useEffect(() => {
        if (showPdf) {
            setIsRightContainerVisible(false);
        } else {
            setIsRightContainerVisible(true);
        }
    }, [showPdf]);

    useEffect(() => {
        setTimeout(() => {
            client.getAllUser().map(async (participant) => {
                if (participant.displayName === accountId && participant.userId !== client.getCurrentUserInfo().userId) {
                    try {
                        await client.removeUser(participant.userId);
                    } catch (error) {
                        console.log("Error in removing user ===>", error);
                    }
                }
            });
        }, 3000);
    }, [client?.getAllUser().length]);

    useEffect(() => {
        setUnread(0);
    }, [showChatBox]);

    useEffect(() => {
        if (mediaStream === undefined) {
            leaveCall(false);
        }
    }, [mediaStream]);

    useEffect(() => {
        console.log("LOL, I'm invoked", participantDetails);
        if (participantDetails.length > 0) {
            participantDetails.map((participant) => {
                if (participant.displayName.startsWith('S')) {
                    setStudentMicStatus(participant.muted === true ? true : false);
                } else {
                    setInterviewerMicStatus(participant.muted === true ? true : false);
                }
            })
        }
    }, [participantDetails])


    //Logic for Swapping Divs
    const centralDivRef = useRef(null);
    const rightDiv1Ref = useRef(null);
    const rightDiv2Ref = useRef(null);
    const rightDiv3Ref = useRef(null);
    const [swapped, setSwapped] = useState(false);
    const [trackCenter, setCenter] = useState(centralDivRef);

    const swapElements = (targetRef) => {
        if (!trackCenter || !targetRef || trackCenter.current === targetRef.current) {
            return;
        }

        const centralDiv = trackCenter.current;
        const rightDiv = targetRef.current;
        const centralParent = centralDiv.parentNode;
        const rightParent = rightDiv.parentNode;

        if (!centralParent || !rightParent) {
            return;
        }
        if (!centralParent.className.includes("zoom-central-div")) {
            return;
        }
        const placeholder = document.createElement("div");
        centralParent.insertBefore(placeholder, centralDiv);
        rightParent.insertBefore(centralDiv, rightDiv);
        centralParent.insertBefore(rightDiv, placeholder);

        centralParent.removeChild(placeholder);

        // Update trackCenter to the new central div reference
        setCenter(targetRef);
        setSwapped(!swapped); // Toggle swapped state for re-rendering
    };

    // DOM model
    //
    // zoom-container
    // |-- zoom-vc-navbar
    // |---- div=Box
    // |---- zoom-interview-type-header
    // |------ h1
    // |------ Notifications
    // |---- zoom-timer-div-with-feedback
    // |------ Link=Button
    // |------ Timer
    // |---- audio
    // |-- zoom-media-section
    // |---- div=SidePanel
    // |---- zoom-side-panel=PdfViewer
    // |---- zoom-central-div
    // |------ zoom-self-view zoom-central-div-content
    // |-------- <>
    // |---------- zoom-video-component
    // |------------ img
    // |---------- canvas/video
    // |---- zoom-right-container
    // |------ zoom-user-view zoom-right-div
    // |-------- <>
    // |---------- zoom-video-component
    // |------------ img
    // |---------- canvas
    // |------ zoom-self-share zoom-right-div
    // |-------- share-screen-div-zoom
    // |---------- div
    // |------------ self-share-screen-video-zoom
    // |------------ self-share-canvas-zoom
    // |------ zoom-user-share zoom-right-div
    // |-------- div
    // |---------- div
    // |------------ canvas
    // |------------ button
    // |---- ChatComponent
    // |---- button
    // |-- zoom-footer
    // |---- zoom-footer-left
    // |------ button
    // |------ button
    // |------ button
    // |------ button
    // |------ EndCallModal
    // |---- zoom-footer-right
    // |------ button
    // |------ button
    // |-------- OverlayTrigger
    //

    return (
        <ErrorBoundary>
            <div className="zoom-container">
                <div className="zoom-vc-navbar " style={{ height: isBreakpoint ? (seconds > FEEDBACK_TIME ? "84px" : "65px") : "inherit" }}>
                    <div className="zoom-panel-buttons-mobile">
                        <div className="zoom-details-btn-mobile">
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            >
                                Details
                            </Button>
                            <div>
                                <Modal open={showModal} onClose={() => setShowModal(false)}>
                                    <Box
                                        sx={{
                                            width: ["90%", "70%", "50%"], // Responsive width
                                            bgcolor: "#383838",
                                            borderRadius: "10px",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            boxShadow: 24,
                                            p: 4,
                                            maxHeight: "90vh", // Ensure it doesn't overflow vertically
                                            overflowY: "auto", // Scrollable content if it overflows
                                        }}
                                    >
                                        <IconButton aria-label="close" onClick={() => setShowModal(false)} sx={{ position: "absolute", top: 8, right: 8 }}>
                                            <Close sx={{ fill: "white", zoom: 1.2 }} />
                                        </IconButton>
                                        <Typography variant="h5" sx={{ fontWeight: "bold" }} component="h2" gutterBottom>
                                            Interview Details
                                        </Typography>
                                        {interviewDetails?.student ? (
                                            <Box sx={{ p: 1, maxWidth: "600px" }}>
                                                <Typography variant="body1">
                                                    <b>Mock Interview of:</b>&nbsp;
                                                    {interviewDetails.student.fname}&nbsp;{interviewDetails.student.lname},&nbsp;
                                                    {programValues[interviewDetails.student.program]},&nbsp;
                                                    {interviewDetails.student.year},&nbsp;
                                                    {interviewDetails.student.branch},&nbsp;
                                                    {interviewDetails.student.institute}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box sx={{ p: 1, maxWidth: "600px" }}>
                                                <Typography variant="body1">
                                                    <b>Mock Interview by:</b>&nbsp;
                                                    {interviewDetails?.interviewer?.fname}&nbsp;{interviewDetails?.interviewer?.lname},&nbsp;
                                                    {interviewDetails?.interviewer?.designation},&nbsp;
                                                    {interviewDetails?.interviewer?.currCompany}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box sx={{ display: "flex", px: 1, pt: 1, pb: 0 }}>
                                            <Typography variant="body1">
                                                <b>Profile:</b>
                                            </Typography>
                                            <Typography variant="body1" sx={{ ml: 1 }}>
                                                {interviewInfo?.profile}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", px: 1, pt: 0 }}>
                                            <Typography variant="body1">
                                                <b>Slot:</b>
                                            </Typography>
                                            <Typography variant="body1" sx={{ ml: 1 }}>
                                                {giveTime(interviewInfo?.fromTime)} - {giveTime(interviewInfo?.toTime)}, {interviewInfo?.date}
                                            </Typography>
                                        </Box>
                                        <Typography variant="body1" sx={{ ml: 1 }}>
                                            <b>Mock ID:</b> {ROOM_ID}
                                        </Typography>
                                    </Box>
                                </Modal>
                            </div>
                        </div>
                        <div className="zoom-document-btn-mobile">
                            {interviewDetails ? (
                                interviewDetails.student ? (
                                    <Button
                                        variant="outlined"
                                        color="warning"
                                        onClick={() => {
                                            downloadPDF(interviewDetails?.student.resume);
                                        }}
                                    >
                                        Resume
                                    </Button>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        color="warning"
                                        onClick={() => {
                                            interviewDetails?.interviewer.isUnavailable
                                                ? toast.loading(`Sorry! the user has ${interviewDetails?.interviewer.isDisable ? "disabled" : ""} ${interviewDetails?.interviewer.isDelete ? "deleted" : ""} this account`, {
                                                    ...toastStyle.loading,
                                                    position: "top-center",
                                                    duration: 2000,
                                                })
                                                : window.open(`${FRONTEND_BASE}/s/interviewer/${interviewDetails?.interviewer.id}`, "_blank");
                                        }}
                                    >
                                        Profile
                                    </Button>
                                )
                            ) : null}
                        </div>
                    </div>

                    <div className="zoom-navbar-logo">
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", pointerEvents: "none", userSelect: "none" }}>
                            <h1 style={{ color: "#02A3E8", fontSize: "clamp(1.2rem,2.5vw,1.6rem)" }}>interwiu.com</h1>
                        </Box>
                    </div>
                    <div className="zoom-interview-type-header">
                        <h1
                            style={{
                                color: "white",
                                fontSize: "clamp(0.8rem,2.5vw,1.6rem)",
                                zoom: 0.9,
                                pointerEvents: "none",
                                userSelect: "none",
                            }}
                        >
                            {{ interview: "Mock Interview", mentorship: "Mentorship Session ", resume: "Resume Building Session" }[interviewInfo?.sessType]}
                        </h1>
                        <div className="notifications-zoom">
                            <Notifications notifications={notifications} isOpen={isOpen} setIsOpen={setIsOpen} />
                        </div>
                    </div>

                    <div className="zoom-timer-div-with-feedback">
                        <div className="feedback-review-button-zoom">
                            {accountId.startsWith("I")
                                ? seconds > FEEDBACK_TIME &&
                                interviewInfo?.sessDuration === 45 && (
                                    <Link
                                        to={{
                                            pathname: "/feedback/interviewer/vc/" + ROOM_ID + "/vc",
                                        }}
                                        target="_blank"
                                        className="feedback__button-mobile"
                                        style={{
                                            pointerEvents: feedback ? "none" : "auto",
                                        }}
                                    // state={{redirectedFrom: "vc"}}
                                    >
                                        <Button
                                            className={"feedback__button" + (feedback ? "" : " blink")}
                                            variant="outlined"
                                            // disabled={feedback}
                                            sx={{
                                                padding: "0px 5px",
                                                margin: "0 5px",
                                                color: "white",
                                                border: "1px solid gray",
                                            }}
                                        >
                                            {!feedback ? (isBreakpoint ? "Feedback" : "Send Feedback") : isBreakpoint ? "Submitted" : "Feedback Sent"}
                                        </Button>
                                    </Link>
                                )
                                : /* TEMP remove `false &&`*/
                                seconds > FEEDBACK_TIME && <RateAndReview mock_id={ROOM_ID} setRated={setRated} rated={rated} />}
                        </div>
                        <div className="zoom-timer">
                            <Timer
                                timerColor={seconds > AUTO_CLOSE_TIME - 5 * 60 ? "red" : "white"}
                                seconds={seconds}
                                setSeconds={setSeconds}
                                videoMicTime={videoMicTime}
                                setVideoMicTime={setVideoMicTime}
                                idleTime={idleTime}
                                setIdleTime={setIdleTime}
                                isMuted={isMuted}
                                videoStarted={videoStarted}
                                isShareScreen={isShareScreen}
                            />
                            <div className="zoom-in-meeting-timers" style={{ color: "white", padding: "10px", display: displayStats ? "flex" : "none" }}>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="tooltip-bottom">
                                            Unread: {unread}
                                            <br />
                                            isHost: {client?.getCurrentUserInfo()?.isHost ? "true" : "false"}
                                            <br />
                                            isManager: {client?.getCurrentUserInfo()?.isManager ? "true" : "false"}
                                            <br />
                                            Idle Time: {idleTime}
                                            <br />
                                            Mic Time: {videoMicTime.mic}
                                            <br />
                                            Video Time: {videoMicTime.video}
                                            <br />
                                            Screen Share Time: {videoMicTime.screen_share}
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        <IoMdInformationCircleOutline />
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                    <audio ref={audioRef} src={audioConnected} />
                </div>
                <button
                    onClick={toggleRightContainerVisibility}
                    className="zoom-right-container-toggle-btn"
                    style={{
                        position: "absolute",
                        top: "var(--header-height)",
                        right: "10px",
                        zIndex: 2,
                        display: (checkTwoParticipants && distinctUserCriteria) || isShareScreen ? "block" : "none",
                        color: "white",
                        padding: "5px 7px",
                        backgroundColor: "var(--main-darklg)",
                        fontSize: "12px",
                    }}
                >
                    {isRightContainerVisible ? "Hide Panel" : "View Panel"}
                </button>
                <div className="zoom-media-section">
                    <div
                        style={{
                            width: showPdf ? null : showSidePanel ? "15%" : "0",
                            minWidth: showSidePanel ? "200px " : "0",
                            display: !showPdf && showSidePanel ? "block" : "none",
                            position: "absolute",
                            top: "var(--header-height)",
                            left: "0",
                            zIndex: "15",
                        }}
                    >
                        {!showPdf && showSidePanel && <SidePanel accountId={accountId} iDetails={interviewDetails} iInfo={interviewInfo} seconds={0} mockId={ROOM_ID} setShowPdf={setShowPdf} />}
                    </div>
                    <div className="zoom-side-panel" style={{ width: showPdf ? "50%" : null, display: showPdf ? "block" : "none" }}>
                        {showPdf && <PdfViewer iDetails={interviewDetails} mockId={ROOM_ID} setShowPdf={setShowPdf} showPdf={showPdf} />}
                    </div>

                    <div className="zoom-central-div" style={{ position: "relative" }}>
                        <div
                            style={{
                                paddingRight: (checkTwoParticipants || isShareScreen) && !showPdf ? "auto" : "20px !important",
                                marginLeft: "30px !important",
                            }}
                            ref={centralDivRef}
                            className="zoom-self-view zoom-central-div-content  zoom-div-visible"
                            onClick={() => {
                                swapElements(centralDivRef);
                            }}
                        >
                            {!mediaStream?.isRenderSelfViewWithVideoElement() ? (
                                <>
                                    <div
                                        className="zoom-video-component"
                                        style={{
                                            padding: "5px",
                                            margin: "5px",
                                            display: !videoStarted ? "flex" : "none",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img src={receivedParticipantDetails ? (accountId.startsWith("S") ? studentProfilePic : interviewerProfilePic) : placeholderImg} className="zoom-placeholder-img"></img>
                                        {/* <p>(You)</p> */}
                                    </div>
                                    <canvas
                                        ref={selfViewRef}
                                        id="self-view-canvas"
                                        width={CENTRAL_WIDTH}
                                        height={CENTRAL_HEIGHT}
                                        style={{
                                            border: "1px solid black",
                                            display: videoStarted ? "block" : "none",
                                            flexDirection: "column",
                                            // justifyContent: "center",
                                            // alignItems: "center",
                                            padding: "0 !important",
                                            margin: "0 !important",
                                            backgroundColor: "transparent !important",
                                        }}
                                    ></canvas>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="zoom-video-component"
                                        style={{
                                            padding: "5px",
                                            margin: "5px",
                                            display: !videoStarted ? "flex" : "none",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img src={receivedParticipantDetails ? (accountId.startsWith("S") ? studentProfilePic : interviewerProfilePic) : placeholderImg} className="zoom-placeholder-img"></img>
                                        {/* <p>(You)</p> */}
                                    </div>
                                    {/* <div style={{ display: videoStarted ? "block" : "none" }}> */}
                                    <video
                                        id="self-view-video"
                                        style={{
                                            display: videoStarted ? "block" : "none",
                                            width: videoStarted ? `${CENTRAL_WIDTH}px` : "0",
                                            height: videoStarted ? `${CENTRAL_HEIGHT}px` : "0",
                                        }}
                                    ></video>
                                    {/* </div> */}
                                </>
                            )}
                        </div>
                        <p
                            style={{
                                position: "absolute",
                                bottom: "15px",
                                left: "15px",
                                zIndex: 2,
                                fontSize: "18px",
                                display: centralDivRef?.current?.parentNode?.className?.includes("zoom-central-div") ? "flex" : "none",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                                // padding: "10px",
                            }}
                        >
                            {receivedParticipantDetails ? (accountId.startsWith("S") ? studentName : interviewerName) : ""}
                        </p>
                        <p
                            style={{
                                position: "absolute",
                                top: "15px",
                                right: "15px",
                                zIndex: 2,
                                fontSize: "18px",
                                display: centralDivRef?.current?.parentNode?.className?.includes("zoom-central-div") ? "flex" : "none",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                                // padding: "10px",
                            }}
                        >
                            {receivedParticipantDetails ? (accountId.startsWith("S") ? studentMicStatus === true ? <AiOutlineAudioMuted /> : "" : interviewerMicStatus === true ? <AiOutlineAudioMuted /> : "") : ""}
                        </p>
                    </div>

                    <div className="zoom-right-container" style={{ display: ((checkTwoParticipants && distinctUserCriteria) || isShareScreen) && isRightContainerVisible ? "flex" : "none" }}>
                        <div
                            ref={rightDiv1Ref}
                            className={`zoom-user-view zoom-right-div`}
                            style={{ display: checkTwoParticipants ? "flex" : "none", justifyContent: "center", alignItems: "center" }}
                            onClick={() => swapElements(rightDiv1Ref)}
                        >
                            <div
                                className="zoom-video-component"
                                style={{
                                    padding: "5px",
                                    margin: "5px",
                                    display: !userVideoStarted ? "flex" : "none",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img src={receivedParticipantDetails ? (accountId.startsWith("S") ? interviewerProfilePic : studentProfilePic) : placeholderImg} className="zoom-placeholder-img"></img>
                            </div>
                            {/* <div className="zoom-user-view-canvas-div"> */}
                            <canvas id="user-view-canvas" width={CENTRAL_WIDTH} height={CENTRAL_HEIGHT} ref={userViewRef} style={{ display: userVideoStarted ? "block" : "none" }}></canvas>
                            <button
                                style={{
                                    position: "absolute",
                                    bottom: "15px",
                                    right: "15px",
                                    zIndex: 2,
                                    fontSize: "18px",
                                    display: userVideoStarted && rightDiv1Ref?.current?.parentNode?.className?.includes("zoom-central-div") ? "flex" : "none",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "white",
                                    backgroundColor: "gray",
                                    padding: "10px",
                                    borderRadius: "50%",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent triggering the parent div's onClick
                                    toggleFullScreenUserVideo();
                                }}
                            >
                                {isFullScreen ? <RiFullscreenFill /> : <RiFullscreenFill />}
                            </button>
                            <p
                                style={{
                                    position: "absolute",
                                    bottom: "15px",
                                    left: "15px",
                                    zIndex: 6,
                                    fontSize: "18px",
                                    display: rightDiv1Ref?.current?.parentNode?.className?.includes("zoom-central-div") ? "flex" : "none",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "white",
                                    // padding: "10px",
                                }}
                            >
                                {receivedParticipantDetails ? (accountId.startsWith("S") ? interviewerName : studentName) : ""}
                            </p>
                            <p
                                style={{
                                    position: "absolute",
                                    top: "15px",
                                    right: "15px",
                                    zIndex: 2,
                                    fontSize: "18px",
                                    display: rightDiv1Ref?.current?.parentNode?.className?.includes("zoom-central-div") ? "flex" : "none",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "white",
                                    // padding: "10px",
                                }}
                            >
                                {receivedParticipantDetails ? (accountId.startsWith("S") ? interviewerMicStatus === true ? <AiOutlineAudioMuted /> : "" : studentMicStatus === true ? <AiOutlineAudioMuted /> : "") : ""}
                            </p>
                            {/* </div> */}
                        </div>
                        <div ref={rightDiv2Ref} className={`zoom-self-share zoom-right-div`} style={{ display: isShareScreen ? "flex" : "none" }} onClick={() => swapElements(rightDiv2Ref)}>
                            <div id="share-screen-div-zoom">
                                {!isShareScreen && (
                                    <p>
                                        No Content.
                                        <br />
                                        Switch to Video
                                    </p>
                                )}
                                <div style={{ display: isShareScreen ? "flex" : "none" }}>
                                    <video
                                        id="self-share-screen-video-zoom"
                                        style={{
                                            display: isSupportWebCodecs() ? "flex" : "none",
                                        }}
                                        ref={selfShareVideoRef}
                                    ></video>
                                    <canvas
                                        id="self-share-canvas-zoom"
                                        width={CENTRAL_WIDTH}
                                        height={CENTRAL_HEIGHT}
                                        ref={selfShareCanvasRef}
                                        style={{
                                            display: !isSupportWebCodecs() ? "flex" : "none",
                                        }}
                                    ></canvas>
                                </div>
                            </div>
                        </div>
                        <div ref={rightDiv3Ref} className={`zoom-user-share zoom-right-div`} style={{ display: isUserSharing ? "block" : "none" }} onClick={() => swapElements(rightDiv3Ref)}>
                            <div style={{ height: "auto", width: CENTRAL_WIDTH, aspectRatio: "16/9" }}>
                                {!isUserSharing && (
                                    <p>
                                        No Content.
                                        <br />
                                        Switch to Video
                                    </p>
                                )}
                                <div>
                                    <canvas
                                        ref={userShareRefCanvas}
                                        id="users-screen-share-content-canvas-zoom"
                                        style={{ display: isUserSharing ? "flex" : "none" }}
                                        height={CENTRAL_HEIGHT}
                                        width={CENTRAL_WIDTH}
                                    ></canvas>
                                    <button
                                        style={{
                                            position: "absolute",
                                            bottom: "15px",
                                            right: "15px",
                                            zIndex: 2,
                                            fontSize: "18px",
                                            display: isUserSharing && rightDiv3Ref?.current?.parentNode?.className?.includes("zoom-central-div") ? "flex" : "none",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "white",
                                            backgroundColor: "gray",
                                            padding: "10px",
                                            borderRadius: "50%",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent triggering the parent div's onClick
                                            toggleFullScreen();
                                        }}
                                    >
                                        {isFullScreen ? <RiFullscreenFill /> : <RiFullscreenFill />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ChatComponent showChatBox={showChatBox} setShowChatBox={setShowChatBox} interviewInfo={interviewInfo} unread={unread} setUnread={setUnread} />
                    {!showPdf && (
                        <button
                            className="zoom-sidePanel-toggle-btn"
                            style={{
                                position: "absolute",
                                top: "var(--header-height)",
                                left: calculateLeftPosition(),
                                zIndex: 15,
                                padding: "10px",
                                backgroundColor: isMouseMoving || showSidePanel ? "#343333" : "transparent",
                                color: "white",
                                borderRadius: "0 50% 50% 0",
                                display: isMouseMoving || showSidePanel ? "block" : "none",
                                transition: "background-color 500ms ease",
                            }}
                            onClick={() => setShowSidePanel(!showSidePanel)}
                        >
                            {showSidePanel ? <MdKeyboardDoubleArrowLeft /> : <MdKeyboardDoubleArrowRight />}
                        </button>
                    )}
                </div>

                <div className="zoom-footer">
                    {/* Left buttons */}
                    <div className="zoom-footer-left">
                        <button onClick={startVideoButton}>
                            {videoStarted ? (
                                <FaVideo />
                            ) : (
                                <div style={{ color: "red" }}>
                                    <FaVideoSlash />
                                </div>
                            )}
                        </button>
                        <button
                            onClick={() => {
                                toggleAudio();
                            }}
                        >
                            {/* {audioStarted ? ( */}
                            {isMuted || !audioStarted ? (
                                <div style={{ color: "red" }}>
                                    <AiOutlineAudioMuted />
                                </div>
                            ) : (
                                <AiOutlineAudio />
                            )}
                            {/* ) : (
                            <MdOutlineSpatialAudio />
                        )} */}
                        </button>
                        <button
                            disabled={isUserSharing}
                            style={{ display: ZoomVideo.checkSystemRequirements().screen ? "flex" : "none" }}
                            onClick={() => {
                                shareScreen();
                            }}
                        >
                            {isShareScreen ? <LuScreenShareOff /> : <LuScreenShare />}
                        </button>
                        <button onClick={handleEndCallModalOpen}>
                            <div style={{ color: "red" }}>
                                <PiPhoneDisconnectFill />
                            </div>
                        </button>

                        <EndCallModal open={endCallModalOpen} handleClose={handleEndCallModalClose} leaveCall={leaveCall} />
                        {/* <button
                        onClick={() => {
                            leaveCall(true);
                        }}
                    >
                        <FcEndCall />
                    </button> */}
                    </div>
                    {/* Right buttons */}
                    <div className="zoom-footer-right">
                        <button onClick={() => setShowChatBox(!showChatBox)}>
                            <IoChatbubblesOutline />
                        </button>
                        {unread !== 0 && !showChatBox && <p className="zoom-unread-notification">{unread}</p>}
                        <button>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip className="tooltip">
                                        Whiteboard
                                        <br />
                                        1. Click to open Whiteboard
                                        <br />
                                        2. Click on "Share" and copy the link
                                        <br />
                                        3. Paste the link in the chatbox for everybody to join it
                                    </Tooltip>
                                }
                            >
                                <a href="https://webwhiteboard.com" target="_blank">
                                    <FaChalkboardTeacher />
                                </a>
                            </OverlayTrigger>
                        </button>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default React.memo(VideoZoom);
