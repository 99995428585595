import React, { useContext, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import axios from "axios";
import "./InterviewerDashboard.css";
import { BASE } from "../../constants/endpoints";
import UserContext from "../../contexts/UserContext";
import {
  INTERVIEWER_PROFILE_FORM_ROUTE,
  INTERVIEWER_RECIEVED_REQUESTS_ROUTE,
} from "../../constants/routes";
import IconButton from "@mui/material/IconButton";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { getCompanyLogo, t24HDecimalTo12HFormat } from "../../utility/helper";
import CoreChip from "../misc/CoreChip";

const InterviewerDashboard = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  console.log(user.currCompany)
  const logo = (getCompanyLogo(user.currCompany))
  useEffect(() => {
    console.log(user);
    
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let navigate = useNavigate();
  useEffect(() => {
    document.title =
      "Profile - " + (user.fname + (user.lname ? " " + user.lname : ""));
  });

  // Check if image exist
  function imageExists(image_url) {
    var http = new XMLHttpRequest();
    http.open("HEAD", image_url, false);
    http.send();
    return http.status != 404;
  }

  /*  
    To convert 24 Hour Decimal Time format to 12 Hours format(for ease of processing in frontend)
    E.g. 12.25 -> 12:15 PM
  */
  // For converting Generic Time Preferences for frontend using backend
  const gnrcSchdForFrontend = (weekSchedule) => {
    let result = {};
    weekSchedule?.map((daySchedule) => {
      result[daySchedule.day] = daySchedule.sessions.map((session) => ({
        init: [session.from * 2, session.to * 2 - 1],
        final: [
          t24HDecimalTo12HFormat(session.from),
          t24HDecimalTo12HFormat(session.to),
        ],
      }));
    });
    return result;
  };

  // Generic Time Preferences for Frontend
  var gnrcSlots = gnrcSchdForFrontend(user.GnrcSchedule);
  console.log("gnrcSlots: ", gnrcSlots);

  // Convert Custom Schedule for Frontend
  const custSchdForFrontend = (schedule) => {
    let result = [];
    schedule?.map((item) => {
      let temp = [];
      item.sessions.map((slotItem) => {
        temp.push({
          init: [slotItem.from * 2, slotItem.to * 2 - 1],
          final: [
            t24HDecimalTo12HFormat(slotItem.from),
            t24HDecimalTo12HFormat(slotItem.to),
          ],
        });
      });
      result.push({ date: item.date, slots: temp });
    });
    return result;
  };

  // array.sort(function (a, b) {
  //   // Turn your strings into dates, and then subtract them
  //   // to get a value that is either negative, positive, or zero.
  //   return new Date(b.date) - new Date(a.date);
  // });
  var custSlots = custSchdForFrontend(user.CustomSchedule).sort(function (
    a,
    b
  ) {
    var aa = a.date.split("/").reverse().join(),
      bb = b.date.split("/").reverse().join();
    return aa < bb ? -1 : aa > bb ? 1 : 0;
  });
  console.log("custSlots: ", custSlots);
  function ImageExist(url) 
  {
    if(url?.includes('.svg')){
      var img = new Image();
      img.src = url;
      return img?.getBBox?.height !== 0;
    }else{
      var img2 = new Image();
      img2.src = url;
      return img2?.height !== 0;
    }
  }
  let imgExist=false;
  // console.log(logo)
  return (
    <>
      <div className="interviews">
        <div className="w-full p-2 my-2 border-2 rounded flex justify-center">
          <Typography
            variant="h4"
            component="h4"
            sx={{
              // textAlign: "center",
              display: "inline",
              color: "#1265a8",
              fontWeight: "bold",
              margin: "0 auto",
            }}
          >
            My Profile
          </Typography>
        </div>
        <div className="flex" style={{alignItems:'stretch',gap:'0.5rem'}} id="profile-cont">
          <div style={{flex:'3'}} className=" overflow-hidden">
            
            <div className="border rounded mb-2">
                <div className="h-32 opacity-80 s-mobile-overflow-h" style={{ position: 'relative' }}>
                  <img src={require("../../images/cover2.webp")} alt="Cover" />
                  <div className="s-mobile-card" style={{ position: 'absolute', bottom: '-50px', left: '0', right: '0', justifyContent: 'center' }}>
                    <div className="" style={{ width: '43%' }}>
                      <img
                        src={user.photo}
                        style={{ width: '100%', height: '100%' }}
                        className="h-full w-full object-cover rounded-full p-1 border bg-white"
                        alt="Profile"
                      />
                    </div>
                  </div>
                </div>
                <div className="relative py-2 s-mobile-flex-dir-col s-desktop-card" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} id="profile-bio">
                  <div className="" style={{ transform: 'translate(14%,-50%)', width: '20%', alignSelf: 'flex-start' }}>
                    <div className="" style={{ width: '100%' }}>
                      <img
                        src={user.photo}
                        className="h-full w-full object-cover rounded-full p-1 border bg-white"
                        alt="Profile"
                      />
                    </div>
                  </div>
                  <div style={{maxWidth: '350px'}}> 
                    <Typography
                      variant="h4"
                      component="h4"
                      sx={{
                        color: "#e84c3d",
                        fontWeight: "bold",
                      }}
                    >
                      {user.fname + (user.lname ? " " + user.lname : "")}
                    </Typography>
                    <div
                      className="flex flex-col"
                      style={{ alignItems: "flex-start" }}
                    >
                      <Chip
                      className="s-mobile-changing-chip-property mt-2"
                      // label={`${profiledata.designation} ${profiledata.company} ${profiledata.location}`}
                      label={user.designation}
                      sx={{ fontSize: "16px" }}
                    />
                    </div>
                    <div
                      className="flex flex-col mt-2"
                      style={{ alignItems: "flex-start" }}
                    >
                      <Chip
                      className="s-mobile-changing-chip-property"
                      // label={`${profiledata.designation} ${profiledata.company} ${profiledata.location}`}
                      label={ user.currCompany}
                      sx={{ fontSize: "16px" }}
                    />
                    </div>
                    {/* <Typography
                    variant="h6"
                    component="h6"
                    sx={{ lineHeight: 1, fontSize: "16px" }}
                  >
                    B.Tech, IIT Kharagpur
                  </Typography> */}

                    <p className="text-gray-500 leading-5"></p>
                    <div className="text-2xl my-1 flex gap-2">
                      {user.linkedInURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-linkedin"
                            onClick={() => {
                              window.open(
                                user.linkedInURL
                                  ? user.linkedInURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.githubURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-black cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-github"
                            onClick={() => {
                              window.open(
                                user.githubURL ? user.githubURL : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.twitterURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-twitter"
                            onClick={() => {
                              window.open(
                                user.twitterURL
                                  ? user.twitterURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div style={{alignSelf:'flex-end'}} className="relative">
                    
                    <div
                      className="text-center mr-2 border rounded-md p-1 mx-auto text-green-600 cursor-pointer hover:text-blue-600 hover:bg-gray-100"
                      onClick={() => {
                        navigate("/i/profile-form");
                      }}
                    >
                      <p>
                        <i className="fa-solid fa-pen-to-square"></i> Edit
                        Profile
                      </p>
                    </div>
                  </div>
                </div>

                <div className="s-mobile-card" style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', marginTop: '17%' }} id="profile-bio">

                  <div style={{padding:'0.5rem'}}>
                    <Typography
                      variant="h4"
                      component="h4"
                      sx={{
                        color: "#e84c3d",
                        fontWeight: "bold",
                      }}
                    >
                      {user.fname + (user.lname ? " " + user.lname : "")}
                    </Typography>
                    <div
                      className="flex flex-col mt-2"
                      style={{ alignItems: "flex-start" }}
                    >
                      
                      <Chip
                      className="s-mobile-changing-chip-property"
                      // label={`${profiledata.designation} ${profiledata.company} ${profiledata.location}`}
                      label={user.designation}
                      sx={{ fontSize: "16px" }}
                    />
                    </div>
                    <div
                      className="flex flex-col mt-2"
                      style={{ alignItems: "flex-start" }}
                    >
                      
                      <Chip
                      className="s-mobile-changing-chip-property"
                      // label={`${profiledata.designation} ${profiledata.company} ${profiledata.location}`}
                      label={ user.currCompany}
                      sx={{ fontSize: "16px" }}
                    />
                    </div>

                    <p className="text-gray-500 leading-5"></p>
                    <div className="text-2xl my-1 flex gap-2">
                      {user.linkedInURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-linkedin"
                            onClick={() => {
                              window.open(
                                user.linkedInURL
                                  ? user.linkedInURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.githubURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-black cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-github"
                            onClick={() => {
                              window.open(
                                user.githubURL ? user.githubURL : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                      {user.twitterURL ? (
                        <div className="hover:text-blue-500 hover:bg-gray-100 text-blue-500 cursor-pointer border rounded p-2 w-12 text-center">
                          <i
                            className="fa-brands fa-twitter"
                            onClick={() => {
                              window.open(
                                user.twitterURL
                                  ? user.twitterURL
                                  : "/dashboard",
                                "_blank"
                              );
                            }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="relative s-desktop-card">
                    
                    <div style={{ display: 'flex' }}
                      className="text-center mr-2 border rounded-md p-1 mx-auto text-green-600 cursor-pointer hover:text-blue-600 hover:bg-gray-100"
                      onClick={() => {
                        navigate("/i/profile-form");
                      }}
                    >
                      <p>
                        <i className="fa-solid fa-pen-to-square"></i> Edit
                        Profile
                      </p>
                    </div>
                  </div>
                  <div className="s-mobile-card" style={{justifyContent:'flex-end',paddingRight:'0.5rem',width:'100%',marginBottom:'10px',alignItems:'center'}}>
                    
                    <div style={{ display: 'flex' }}
                      className="text-center border rounded-md p-1 text-green-600 cursor-pointer hover:text-blue-600 hover:bg-gray-100"
                      onClick={() => {
                        navigate("/i/profile-form");
                      }}
                    >
                      <p>
                        <i className="fa-solid fa-pen-to-square"></i> Edit
                        Profile
                      </p>
                    </div>
                  </div>
                </div>
              </div>


              {/*  */}
            <div className="border rounded p-1 py-2 text-justify s-mobile-card" style={{gap:'0.5rem',padding:'0.5rem',flexDirection:'column'}}>
                <p className="text-sm font-medium">
                  Would you like to see the students who are interested to have
                  a Mock Interview or Mentorship Sessions with you and obtain your Feedback on their
                  performance in real time?
                </p>
                <div className="flex justify-center mt-1">
                  <Button
                    variant="contained"
                    sx={{ textAlign: "center", fontSize: 11 }}
                    onClick={() => {
                      navigate(INTERVIEWER_RECIEVED_REQUESTS_ROUTE);
                    }}
                  >
                    Check Received Requests
                  </Button>
                </div>
              </div>
            <div className="rounded border p-2">
              <div className="mb-3">
                {user.desc && <><div><Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "darkgreen",
                  }}
                  className="s-mobile-profile-subHeading"
                >
                  Description
                </Typography></div>
                <p style={{whiteSpace:'pre-wrap'}} className="mx-3">{user.desc}</p></>}
                
              </div>
              <div className="my-3">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "darkgreen",
                  }}
                  className="s-mobile-profile-subHeading"
                >
                  Profile Preferences
                </Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.3rem', height: "auto" }} className="mx-3">
                  {user?.profiles.map((profile) => (
                    <Chip
                      key={"profile" + profile}
                      style={{ margin: "0.2rem" }}
                      label={profile}
                      variant="outlined"
                    />
                  ))}
                  {
                  user?.coreprofiles?.map((coreProfile) => (
                      <CoreChip 
                      page="iprofile"
                      customStyle={{
                        margin: "0.2rem"
                      }}
                      branch={coreProfile} specs={
                        user?.specializations?.filter((s) => s.profile === coreProfile && s.subDomains?.filter(sd => sd !== "Other").length>0)
                        .map(spec => ({
                          profile: spec.profile,
                          subDomains: spec.subDomains.filter(sd => sd !== "Other")
                        }))
                      }
                      />
                    ))
                }
                </div>
                {user.paymentPref && user.paymentPref!=="" && <><Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "darkgreen",
                  }}
                  className="s-mobile-profile-subHeading"
                >
                  Payment Preferences
                </Typography>
                <Chip className="mx-3"
                  style={{ margin: "0.2rem" }}
                  label={user.paymentPref[0].toUpperCase()+user.paymentPref.slice(1,user.paymentPref?.length)}
                  variant="outlined"></Chip></>
                  }

                  {
                    user.prevCompanies.length!==0 && user.prevCompanies && <><Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "darkgreen",
                    }}
                    className="s-mobile-profile-subHeading"
                    >
                      Previous Companies
                      </Typography>
                      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.3rem' }}  className="">
                        {user?.prevCompanies.map((company) => (
                          <Chip
                            key={"company" + company}
                            style={{ margin: "0.2rem" }}
                            label={company}
                            variant="outlined"
                          />
                        ))}
                      </div>
                    </>
                  }
              </div>

              <div className="mt-3">
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "darkgreen",
                  }}
                  className="s-mobile-profile-subHeading"
                >
                  Time Preferences for Mock Interviews
                  {/* {"(" +
                  new Date().toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }) +
                  ")"} */}
                </Typography>
                <div>
                  <div className="rounded-md border border-black my-2 p-2">
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "green",
                        fontWeight: "bold",
                      }}
                      className="s-mobile-profile-text"
                    >
                      Day-wise Time Preferences
                    </Typography>
                    {Object.keys(gnrcSlots)?.length !== 0
                      ? Object.keys(gnrcSlots).map((item) =>
                          gnrcSlots[item]?.length !== 0 ? (
                            <div className="flex" style={{gap:'0.3rem',margin:'0.2rem'}}>
                              <p style={{flex:'1'}} className="col-span-1 capitalize s-desktop-card ">
                                {item === "mon"
                                  ? "Monday"
                                  : item === "tue"
                                  ? "Tuesday"
                                  : item === "wed"
                                  ? "Wednesday"
                                  : item === "thu"
                                  ? "Thursday"
                                  : item === "fri"
                                  ? "Friday"
                                  : item === "sat"
                                  ? "Saturday"
                                  : item === "sun"
                                  ? "Sunday"
                                  : null}
                              </p>
                              <div style={{flex:'4',flexWrap:'wrap'}} className="col-span-5 flex flex-row gap-2 s-desktop-card">
                                {gnrcSlots[item].map((arr) => (
                                  <Chip
                                    label={arr.final[0] + " - " + arr.final[1]}
                                    variant="outlined"
                                  />
                                ))}
                              </div>
                              <p style={{flex:'1'}} className="col-span-1 capitalize s-mobile-card s-mobile-profile-text">
                                {item === "mon"
                                  ? "Monday"
                                  : item === "tue"
                                  ? "Tuesday"
                                  : item === "wed"
                                  ? "Wednesday"
                                  : item === "thu"
                                  ? "Thursday"
                                  : item === "fri"
                                  ? "Friday"
                                  : item === "sat"
                                  ? "Saturday"
                                  : item === "sun"
                                  ? "Sunday"
                                  : null}
                              </p>
                              <div style={{flex:'2'}} className="col-span-5 flex flex-row gap-2 s-mobile-card">
                                  <Chip
                                    className="s-mobile-changing-chip-property-day-wise"
                                    label={(gnrcSlots[item].map((arr) => {return (arr.final[0] + " - " + arr.final[1])})).join('\r\n')}
                                    variant="outlined"
                                  />
                              </div>
                            </div>
                          ) : null
                        )
                      : "No Schedule Specified"}
                  </div>
                  
                  {/*
                  <div className="rounded-md border border-black my-2 p-2">
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      Revised Schedule
                      <Tooltip
                        title={`Summary of the dates for which you have edited your time preferences`}
                      >
                        <IconButton>
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    {custSlots?.length !== 0
                      ? custSlots.map((item) => (
                          <div className="grid grid-cols-6 gap-2 my-2 mx-2">
                            <p className="col-span-1">{item.date}</p>
                            <div className="col-span-5 flex flex-row gap-2">
                              {item.slots?.length !== 0 ? (
                                item.slots.map((slot) => (
                                  <Chip
                                    label={
                                      slot.final[0] + " - " + slot.final[1]
                                    }
                                    variant="outlined"
                                  />
                                ))
                              ) : (
                                <Chip label="Holiday" variant="outlined" />
                              )}
                            </div>
                          </div>
                        ))
                      : "No Schedule Specified"}
                  </div>
                  */}
                </div>
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                <div className="tt ml-auto">
                <InfoOutlined sx={{color:'rgb(83, 81, 81)', fontSize:'20px'}}/>
                      <span className="tttext">By keeping an account at interwiu.com, you agree to our Terms and Conditions and Privacy Policy</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{flex:1,margin:'0px'}} className="border rounded p-2 s-desktop-card s-margin-0">
            <div>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                Feeds
              </Typography>
              {/* <div className="border rounded drop-shadow-lg my-2">
                <img
                  src={require("../../images/join-now.jpg")}
                  alt="Join Now"
                />
              </div> */}
              <div className="border rounded p-1 py-2 text-justify">
                <p className="text-sm font-medium">
                  Would you like to see the students who are interested to have
                  a Mock Interview with you and obtain your Feedback on their
                  performance in real time?
                </p>
                <div className="flex justify-center mt-1">
                  <Button
                    variant="contained"
                    sx={{ textAlign: "center", fontSize: 11 }}
                    onClick={() => {
                      navigate(INTERVIEWER_RECIEVED_REQUESTS_ROUTE);
                    }}
                  >
                    Check Mock Interview Requests
                  </Button>
                </div>
              </div>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  marginTop: "10px",
                }}
              >
                Interview Metrics
              </Typography>
              <div style={{ textAlign: "center" }}>
                {/* No interviews yet! */}
                {/* <img src={require("../../images/graph.png")} alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewerDashboard;
