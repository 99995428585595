import React, { useState } from "react";
import "./Navbar.css";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import './Contact.css';

const Contact = () => {
  // Contact Form Inputs
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    comment: "",
  });
  // Handling Change
  const handleChange = (event) => {
    const name = event.target.id;
    const value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };
  // Handling Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputs);
    setInputs({
      name: "",
      email: "",
      comment: "",
    });
  };
  const user =
    JSON.parse(localStorage.getItem("isAuthenticated")) ||
    JSON.parse(localStorage.getItem("isIAuthenticated"));
  return (
    <div className="relative" id="contact-us-container">
      {/* <div className="absolute" id="contact-us-cover"></div> */}
      <div
        className="top-1/2 left-1/2 bg-white flex justify-between items-center p-8 rounded-lg gap-8"
        id="contact-us"
      >
        {/* <div
          className="absolute h-16 w-16 rounded-l-lg right-0 bottom-10 flex justify-center cursor-pointer"
          id="linkedin-icon"
        >
          <a href="https://www.linkedin.com/company/fyzen-career-solutions/">
            <i class="fa-brands fa-linkedin-in text-3xl text-white"></i>
          </a>
        </div> */}
        <div className="w-full">
          <Typography
            variant="h4"
            component="h4"
            sx={{ color: "#676df5", fontWeight: "bold" }}
            gutterBottom
          >
            Get In Touch
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: "#a9a9a9", fontWeight: "bold" }}
            gutterBottom
          >
            We are here! How can we help you?
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              id="name"
              label="Name"
              placeholder="Please Enter Your Name"
              type="text"
              value={inputs.name}
              onChange={handleChange}
              sx={{ width: 1, my: 1 }}
            />
            <TextField
              id="email"
              label="Email"
              placeholder="Please Enter Your Email"
              type="email"
              value={inputs.email}
              onChange={handleChange}
              sx={{ width: 1, my: 1 }}
            />
            <TextField
              id="comment"
              label="Comment"
              placeholder="Write you comment..."
              multiline
              rows={4}
              value={inputs.comment}
              onChange={handleChange}
              sx={{ width: 1, my: 1 }}
            />
            <Button
              variant="contained"
              type="submit"
              sx={{
                width: 1,
                py: 1,
                backgroundColor: "#676df5",
                fontSize: 16,
                my:1,
                fontWeight: "bold",
              }}
            >
              Submit
            </Button>
          </form>
        </div>
        <div className="w-full">
          <div className="flex justify-center">
            <img
              src={require("../../images/contact-us-image.jpg")}
              style={{ height: "300px" }}
              alt="Contact Us"
            />
          </div>
          <div className="flex gap-3" style={{alignItems:'flex-start', marginBottom:'0.5rem'}}>
            <div className="flex" style={{marginTop:'15px',}}>
              <div
                className="h-12 w-12 border border-black rounded-full flex justify-center text-xl"
                style={{ color: "#676df5" }}
              >
                <i class="fa-sharp fa-solid fa-location-dot"></i>
              </div>
            </div>
            <div className="">
              <Typography
                variant="subtitle2"
                sx={{ color: "gray" }}
                gutterBottom
              >
                Registered Address
              </Typography>
              <Typography variant="body1" gutterBottom>
                Fyzen Career Solutions Private Limited, 090/LT-1 (B), Tambri
                Osmanabad, Maharashtra – 413501, India.
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "gray" }}
                gutterBottom
              >
                Correspondence Address
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utsav Bhardwaj, Indian Institute of Technology Delhi, New Delhi,
                Delhi – 110016, India.
              </Typography>
            </div>
          </div>
          <div className="flex gap-3 my-2">
            <div className="flex items-center">
              <div
                className="h-12 w-12 border border-black rounded-full flex justify-center text-xl"
                style={{ color: "#676df5" }}
              >
                <i class="fa-solid fa-phone"></i>
              </div>
            </div>
            <div className="">
              <Typography variant="body1" gutterBottom>
                +91-9176583479
              </Typography>
            </div>
          </div>
          <div className="flex gap-3 my-2">
            <div className="flex items-center">
              <div
                className="h-12 w-12 border border-black rounded-full flex justify-center text-xl"
                style={{ color: "#676df5" }}
              >
                <i class="fa-solid fa-envelope"></i>
              </div>
            </div>
            <div className="">
              <Typography variant="body1" gutterBottom>
                support@interwiu.com
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
