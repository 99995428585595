import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import Pricing from "./PricingPage";
import Profiles from "./Profiles";
import SeeAllTestimonial from "./SeeAllTestimonial";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import "./HomeRoutes.css";
import CancellationPolicy from "./CancellationPolicy";
import PaymentPolicy from "./PaymentPolicy";
const HomeRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="profiles" element={<Profiles />} />
        <Route path="testimonials" element={<SeeAllTestimonial />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />

        <Route
          path="*"
          element={
            <section style={{minHeight:"100vh"}} class="page_404">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 ">
                    <div class="col-sm-10 col-sm-offset-1  text-center">
                      <div class="four_zero_four_bg">
                        <h1 class="text-center ">404</h1>
                      </div>

                      <div class="contant_box_404">
                        <h3 class="h2">Look like you're lost</h3>

                        <p>the page you are looking for not avaible!</p>

                        <a href="/" class="link_404">
                          Go to Home
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          }
        />
        <Route path="/cancellation-policy" element={<CancellationPolicy/>}/>
        <Route path="/payment-policy" element={<PaymentPolicy/>}/>
      </Routes>
    </div>
  );
};

export default HomeRoutes;
