import { Button } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../contexts/UserContext";
import "./Welcome.css";

const Welcome = () => {
  const { user, setUser } = useContext(UserContext);

  const [instituteName, setInstituteName] = useState();

  useEffect(() => {
    setInstituteName(user.iname);
  }, [user]);

  useEffect(()=>{
    setTimeout(()=>{
        window.location.href=process.env.REACT_APP_FRONTEND_SERVER_URL +
            "/admin/add-students"
    },7000);
  })

  return (
    <div className="welcome">
      <p>
      <h1 className="text-[64px]">Welcome</h1>
<p>
        We warmly welcome {!user.isAdmin && "you to the Admin Panel of "}<strong>{instituteName ? instituteName : ""}</strong>{" "}
        {user.isAdmin ? "to" : "at"} <strong>interwiu.com</strong>. We look forward to providing the students of{" "}
        <strong>{instituteName ? instituteName : ""}</strong> with a great
        experience here! <br/>As an Admin of{" "}
        <strong>{instituteName ? instituteName : ""}</strong>, please{" "}
        {/* <Button variant="contained" color="primary"  style={{margin:"0 10px"}}>
          <a
            href={
              process.env.REACT_APP_FRONTEND_SERVER_URL +
              "/admin/add-students"
            }
          >
            Add Students
          </a>{" "}
        </Button> */}
        <strong><a href={
              process.env.REACT_APP_FRONTEND_SERVER_URL +
              "/admin/add-students"
            } 
            style={{color:"blue",textDecoration:"underline"}}>Add Students </a></strong> 
         of your institute at interwiu.com, or ask any of your Co-Admins to add
        the students here
        </p>
      </p>
    </div>
  );
};

export default Welcome;
