import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import "./FeedbackInterviewer.css"
import {
  GET_UPCOMING_MOCK_INTERVIEWS,
  GET_REQUEST_INTERVIEWS,
  FEEDBACK,
  GET_STUDENT_INFO,
} from "../../../constants/endpoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Radio,
  RadioGroup,
  Button,
  FormControl,
  FormControlLabel,
  TextareaAutosize,
} from "@mui/material";
import { giveTime, giveYears } from "../utils";
import { downloadPDF } from "../../../utility/helper";
import { toast } from "react-hot-toast";
// import { socket } from "../utils";


const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const FeedbackInterviewer = () => {
  const { mock_id, from } = useParams();
  const [interviewInfo, setInterviewInfo] = useState();
  const [selectedValue, setSelectedValue] = useState(1);
  const [textValue, setTextValue] = useState({ q5: [] });
  const [interviewDetails, setInterviewDetails] = useState();
  const [suggestions, setSuggestions] = useState()
  const [alreadySubmitted, setAlreadySubmitted] = useState(false)

  const [count, setCount] = useState(2)

  const navigate = useNavigate();

  useEffect(() => {
    if (!mock_id) return;
    let interruptData = window.localStorage.getItem("vcInterrupt")
    if (interruptData === '[object Object]' || !interruptData)
      interruptData = {
        mockId: [],
        mockStatus: {}
      }
    else {
      interruptData = JSON.parse(interruptData)
    }
    interruptData.mockStatus = {
      ...interruptData.mockStatus,
      [mock_id]: {
        ...interruptData.mockStatus[mock_id],
        feedback: alreadySubmitted
      }
    }
    window.localStorage.setItem("vcInterrupt", JSON.stringify(interruptData))
  }, [alreadySubmitted, mock_id])

  useEffect(() => {
    console.log("from: ", from)
    // getting upcoming mock interviewes to check if the form link is valid or not
    axios
      .get(`${GET_UPCOMING_MOCK_INTERVIEWS + "/" + mock_id}`)
      .then((res) => {
        console.log("valid feedback form");
      })
      .catch((err) => {
        alert("Invalid Link");
        window.open("about:blank", "_self");
        window.close();
      });

    // getting interview details and from that getting student details for header
    axios
      .get(`${GET_REQUEST_INTERVIEWS + "/" + mock_id}`)
      .then((res) => {
        setInterviewDetails(res.data);
        let student_id = res.data.studId;
        axios
          .get(`${GET_STUDENT_INFO}/${student_id}`)
          .then((res) => {
            setInterviewInfo(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    axios.get(`${FEEDBACK}/${mock_id}`)
      .then((res) => {
        if (res.data) {
          setAlreadySubmitted(true)
          setSelectedValue(res.data.scores)
          setTextValue(res.data.text)
        }

      })
      .catch((err) => {
        console.log(err)
        setAlreadySubmitted(false)
      })
  }, []);

  const handleSubmit = () => {

    // checking if all radio buttons are pressed or not
    if (!selectedValue.q1 || !selectedValue.q2 || !selectedValue.q3 || !selectedValue.q4) {

      alert("Fill all the entries");
      return;
    }

    let text = textValue;

    for (let i in suggestions) {
      text["q5"].push(suggestions[i]);
    }


    console.log(text);

    // saving feedback
    axios
      .post(FEEDBACK + "/" + mock_id, {
        scores: selectedValue,
        text: text,
      })
      .then((res) => {
        toast.success("Feedback Sent Successfully");
        setAlreadySubmitted(true)
        // socket.emit("feedback-sent", {msg: "Feedback Sent Event", roomId: mock_id})
        setTimeout(() => {
          // window.open("about:blank", "_self");
          // window.close();
          from === "vc" ?
            window.close() :
            navigate("/i/completed-interviews")
        }, 2000)
        console.log(res.data);
      })
      .catch((err) => {
        setAlreadySubmitted(false)
        alert("Answer all the questions");
        console.log(err);
      });
  };

  const textChange = (e) => {
    if (e.target.name === "q1") {
      setTextValue({ ...textValue, q1: e.target.value });
    } else if (e.target.name === "q2") {
      setTextValue({ ...textValue, q2: e.target.value });
    } else if (e.target.name === "q3") {
      setTextValue({ ...textValue, q3: e.target.value });
    } else if (e.target.name === "q4") {
      setTextValue({ ...textValue, q4: e.target.value });
    }
  };

  const handleChange = (e) => {

    if (e.target.name === "q1") {
      setSelectedValue({ ...selectedValue, q1: e.target.value });
    } else if (e.target.name === "q2") {
      setSelectedValue({ ...selectedValue, q2: e.target.value });
    } else if (e.target.name === "q3") {
      setSelectedValue({ ...selectedValue, q3: e.target.value });
    } else if (e.target.name === "q4") {
      setSelectedValue({ ...selectedValue, q4: e.target.value });
    }

  };

  const addBlock = (e) => {

    let element = document.createElement("div")
    element.setAttribute("class", "q5_" + (count + 1))
    element.classList.add("box__div")
    let text = document.createElement("textarea")
    text.setAttribute("name", "q5_" + (count + 1))
    text.setAttribute("class", "box__text")
    text.setAttribute("placeholder", "Action Plan - " + (count + 2))
    text.addEventListener("change", (e) => {
      setSuggestions({ ...suggestions, [e.target.name]: e.target.value })

    });
    element.append(text);

    $(`.q5_${count}`).after(element);

    // $(`.${"q5_" + (count + 1)}`).on("change",textChange)

    setCount(count + 1);
  };

  return (
    <div className="feedback mb-5">
      <div className="top__header">
        <Typography variant="h3" style={{ fontSize: 'clamp(2rem,5vw,3rem', padding: '10px' }}>FEEDBACK FORM</Typography>
        <div style={{ display: "flex", flexWrap: 'wrap' }}>
          <p style={{ margin: "0 8px" }}>
            <strong>Student: </strong>{" "}
            {interviewInfo?.fname + " " + interviewInfo?.lname},{" "}
            {interviewInfo &&
              interviewInfo?.program === "btech"
              ? "B.Tech"
              : interviewInfo?.program === "dual"
                ? "Dual Degree (B.Tech + M.Tech)"
                : interviewInfo?.program === "dualbsms"
                  ? "Dual Degree (BS + MS)"
                  : interviewInfo?.program === "mtech"
                    ? "M.Tech"
                    : interviewInfo?.program === "ms"
                      ? "MS (by Research)"
                      : interviewInfo?.program === "mba"
                        ? "MBA"
                        : interviewInfo?.program === "msc"
                          ? "M.Sc"
                          : interviewInfo?.program === "ima"
                            ? "Intergrated MA"
                            : interviewInfo?.program === "ma"
                              ? "MA"
                              : "PhD"},{" "}
            {interviewInfo?.year}, {" "}
            {interviewInfo?.branch}, {" "}
            {interviewInfo?.institute}


          </p>
          {/* <p style={{ margin: "0 8px" }}>
            <strong>Program: </strong>
            {interviewInfo?.program[0].toUpperCase() +
              interviewInfo?.program.slice(1)}
          </p>
          <p style={{ margin: "0 8px" }}>
            <strong>Year: </strong>
            {giveYears(interviewInfo?.year)} Year
          </p>
          <p style={{ margin: "0 8px" }}>
            <strong>Branch: </strong>
            {interviewInfo?.branch}
          </p> */}
          <p style={{ margin: "0 8px" }}>
            {" "}
            <strong>Slot: </strong>
            {giveTime(interviewDetails?.fromTime)} -{" "}
            {giveTime(interviewDetails?.toTime)}, {interviewDetails?.date}
          </p>
          <p style={{ margin: "0 8px" }}>
            <strong>Profile: </strong>
            {interviewDetails?.profile}
          </p>
          <p style={{ margin: "0 8px" }}>
            <strong>Mock ID: </strong>
            {mock_id}
          </p>
        </div>
        <Button
          variant="outlined"
          color="warning"
          style={{ padding: "0px 5px", margin: "5px 5px" }}
          onClick={() => {
            downloadPDF(interviewInfo?.resume);
          }}
        >
          Resume
        </Button>
      </div>
      <div className="questions">
        <div className="q__1 question">
          <Typography variant="p">
            1. How good was the candidate’s knowledge, skillset, and experience
            in the desired role?
          </Typography>
          <FormControl>
            <div className="questions__radio">
              {arr.map((ele) => (
                <FormControlLabel
                  value="bottom"
                  key={ele}
                  style={{ margin: '0px', width: '10px' }}
                  control={
                    <Radio
                      className="radio"
                      checked={selectedValue?.q1 == ele}
                      onChange={handleChange}
                      value={ele}
                      name="q1"

                    />
                  }
                  label={ele}
                  labelPlacement="bottom"
                />
              ))}
            </div>
          </FormControl>
          <div className="degree">
            <Typography variant="p">Terrible</Typography>
            <Typography variant="p">Amazing</Typography>
          </div>
          <div>
            <TextareaAutosize

              minRows={3}
              style={{
                width: "100%",
                marginTop: "20px",
                height: "115px",
                padding: "10px",
                // overflow: "scroll",
              }}
              name="q1"
              onChange={textChange}
              placeholder="Add suggestions for Improvement"
            />
          </div>
        </div>
        <div className="q__2 question">
          <Typography variant="p">
            2. How good was the candidate’s approach and ability to solve a
            problem?
          </Typography>
          <FormControl>
            <div className="questions__radio">
              {arr.map((ele) => (
                <FormControlLabel
                  value="bottom"
                  key={ele}
                  style={{ margin: '0px', width: '10px' }}

                  control={
                    <Radio
                      className="radio"
                      checked={selectedValue?.q2 == ele}
                      onChange={handleChange}
                      value={ele}
                      name="q2"

                    />
                  }
                  label={ele}
                  labelPlacement="bottom"
                />
              ))}
            </div>
          </FormControl>

          <div className="degree">
            <Typography variant="p">Terrible</Typography>
            <Typography variant="p">Amazing</Typography>
          </div>
          <div>
            <TextareaAutosize

              minRows={3}
              style={{
                width: "100%",
                marginTop: "20px",
                height: "115px",
                padding: "10px",
                // overflow: "scroll",
              }}
              name="q2"
              onChange={textChange}
              placeholder="Add suggestions for Improvement"
            />
          </div>
        </div>
        <div className="q__3 question">
          <Typography variant="p">
            3. How good was the candidate’s introduction?
          </Typography>
          <FormControl>
            <div className="questions__radio">
              {arr.map((ele) => (
                <FormControlLabel
                  value="bottom"
                  key={ele}
                  style={{ margin: '0px', width: '10px' }}

                  control={
                    <Radio
                      className="radio"
                      checked={selectedValue?.q3 == ele}
                      onChange={handleChange}
                      value={ele}
                      name="q3"

                    />
                  }
                  label={ele}
                  labelPlacement="bottom"
                />
              ))}
            </div>
          </FormControl>
          <div className="degree">
            <Typography variant="p">Terrible</Typography>
            <Typography variant="p">Amazing</Typography>
          </div>
          <div>
            <TextareaAutosize

              minRows={3}
              style={{
                width: "100%",
                marginTop: "20px",
                height: "115px",
                padding: "10px",
                // overflow: "scroll",
              }}
              name="q3"
              onChange={textChange}
              placeholder="Add suggestions for Improvement"
            />
          </div>
        </div>
        <div className="q__4 question">
          <Typography variant="p">
            4. How good were the candidate’s body language and communication
            skills?
          </Typography>
          <FormControl>
            <div className="questions__radio">
              {arr.map((ele) => (
                <FormControlLabel
                  key={ele}
                  value="bottom"
                  style={{ margin: '0px', width: '10px' }}

                  control={
                    <Radio
                      className="radio"
                      checked={selectedValue?.q4 == ele}
                      onChange={handleChange}
                      value={ele}
                      name="q4"

                    />
                  }
                  label={ele}
                  labelPlacement="bottom"
                />
              ))}
            </div>
          </FormControl>
          <div className="degree">
            <Typography variant="p">Terrible</Typography>
            <Typography variant="p">Amazing</Typography>
          </div>
          <div>
            <TextareaAutosize

              minRows={3}
              style={{
                width: "100%",
                marginTop: "20px",
                height: "115px",
                padding: "10px",
                // overflow: "scroll",
              }}
              name="q4"
              onChange={textChange}
              placeholder="Add suggestions for Improvement"
            />
          </div>
        </div>
        <div className="q__5 question">
          <Typography variant="p">
            5. Action Plan for the candidate to improve, and thus ensure the
            success
          </Typography>

          <div className={"q5_0"}>
            <TextareaAutosize

              minRows={3}
              style={{
                width: "100%",
                marginTop: "20px",
                height: "115px",
                padding: "10px",
                // overflow: "scroll",
              }}
              name={"q5_0"}
              onChange={(e) => { setSuggestions({ ...suggestions, [e.target.name]: e.target.value }) }}
              placeholder="Action Plan - 1"
            />

          </div>
          <div className={"q5_1"}>
            <TextareaAutosize

              minRows={3}
              style={{
                width: "100%",
                marginTop: "20px",
                height: "115px",
                padding: "10px",
                // overflow: "scroll",
              }}
              name={"q5_1"}
              onChange={(e) => { setSuggestions({ ...suggestions, [e.target.name]: e.target.value }) }}
              placeholder="Action Plan - 2"
            />

          </div>
          <div className={"q5_2"}>
            <TextareaAutosize

              minRows={3}
              style={{
                width: "100%",
                marginTop: "20px",
                padding: "10px",
                height: "115px",
                // overflow: "scroll",
              }}
              name={"q5_2"}
              onChange={(e) => { setSuggestions({ ...suggestions, [e.target.name]: e.target.value }) }}
              placeholder="Action Plan - 3"
            />

          </div>
          <button
            style={{
              fontSize: "20px",
              margin: "10px 0",
              borderRadius: "100%",
              height: "40px",
              width: "40px",
              backgroundColor: "green",
              color: "white",
            }}
            onClick={addBlock}
          >
            +
          </button>
        </div>

        <Button
          variant="contained"
          size="large"
          className="submit__question"
          onClick={handleSubmit}
          disabled={alreadySubmitted}
        >
          {alreadySubmitted ? "Feedback Sent" : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default FeedbackInterviewer;
