import React, { useContext } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Typography from "@mui/material/Typography";
import { Box, Chip, FormControl, FormControlLabel, FormGroup, FormLabel, Modal, Radio, RadioGroup } from "@mui/material";
import axios from "axios";
import { BASE } from "../../../constants/endpoints";
import { downloadPDF, programValues, t24HDecimalTo12HFormat, toastStyle, convertDateFormat, isIIT } from "../../../utility/helper";
import toast from "react-hot-toast";
import LZString from "lz-string";
import CancelIcon from "@mui/icons-material/Cancel";
import { Calendar } from "react-multi-date-picker";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import Snackbar from "@mui/material/Snackbar";
import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment"; // Getting day from date and modifying date format
import { INTERVIEWER_PROFILE_FORM_ROUTE, STUDENT_PROFILE_FORM_ROUTE } from "../../../constants/routes.js";
import UserContext from "../../../contexts/UserContext.js";
import {
  INTERVIEWERS_GETBYFILTERS_ENDPOINT,
} from "../../../constants/endpoints";
import SlotAlreadyBooked from "../../Dashboard/popups/SlotAlreadyBooked.js";
import {
  compareDate,
  compareSlots,
  getCompanyLogo,
  t12HTo24HDecimalFormat,
} from "../../../utility/helper.js";
import { AiTwotoneCalendar } from "react-icons/ai";
import { InfoOutlined } from "@mui/icons-material";
import StudCardDetails from "components/misc/StudCardDetails";
import { FaCopy } from "react-icons/fa";


const ReceivedRequest = ({
  name,
  desg,
  slotStart,
  subprofile,
  date,
  slotEnd,
  branch,
  college,
  image,
  program,
  year,
  reqId,
  profile,
  sId,
  status,
  zIndex,
  shift,
  remove,
  index,
  sIndex,
  counter,
  accepted,
  setAccepted,
  declined,
  setDeclined,
  cardId,
  isAccepted,
  resume,
  isRescheduled,
  mockId,
  isDisable,
  isDelete,
  isUnavailable,
  reschedule,
  sessDuration,
  sessType
}) => {
  const { user } = useContext(UserContext);
  const iId = user.id
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  const [accept, setAccept] = useState(false);
  const [decline, setDecline] = useState(false);
  const [open, setOpen] = useState(false)
  const [openDecline, setOpenDecline] = useState(false);
  const [radioValue, setRadioValue] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [requests, setRequests] = useState([]);
  const [interviewer, setInterViewer] = React.useState(null);
  const [slotsArray, setSlotsArray] = useState([]);
  const [isSlotAlreadyBooked, setisSlotAlredyBooked] = useState(false);
  const [radio, setRadio] = useState(null);
  let navigate = useNavigate();
  const [requestData, setRequestData] = useState({
    studentId: sId,
    interviewerId: iId,
    requestId: "#Yet_to_be_generated",
    slot: "",
    date: moment(selectedDate).format("DD/MM/YYYY"),
    profile: "",
    requestStatus: 0,
    paymentId: "nan",
    paymentStatus: "Not Done",
  });
  const slotBookedModalHandler = (data) => {
    setisSlotAlredyBooked(data);
  };
  useEffect(() => {
    if (requestData.slot) setSendBtnDisabled(true);
    else setSendBtnDisabled(false);
  }, [requestData]);
  const displaySlots = (date) => {
    const currentDate = moment(date).format("DD/MM/YYYY");
    const currentDay = moment(date).format("ddd").toLowerCase();
    let check = false;
    if (custSchd !== 0) {
      custSchd?.map((item) => {
        if (item.date === currentDate) {
          check = true;
          let slotArray = [];
          item.sessions?.map((sess) => {
            let duration = sess.to - sess.from
              // console.log(sess.to, sess.from, duration, duration % 0.75)
              let i = sess.from 
              while (true) {
                let slot = {
                  from: i,
                  to: i + 0.75
                }
                if (i === 0 && duration % 0.75 !== 0) {
                  
                  let extra = duration - Math.floor(duration/0.75)*0.75
                  if (duration < 0.75) {
                    
                    return;
                  } else {
                    slot.from += extra
                    slot.to += extra
                    i += extra
                  }
                } else if (slot.to > 24) {
                  slot.to -= 24
                }
                if (sessDuration === 30) {
                  slot.to -= 0.25;
                }
                slotArray.push([slot.from, slot.to]) 
                i += 0.75
                if (i >= sess.to)
                  break;
              }
          });

          setSlotsArray([...slotArray]);
        }
      });
    }
    if (!check) {
      if (gnrcSchd?.length !== 0) {
        gnrcSchd?.map((item) => {
          if (item.day === currentDay) {
            let slotArray = [];
            item.sessions?.map((sess) => {
              let duration = sess.to - sess.from
              // console.log(sess.to, sess.from, duration, duration % 0.75)
              let i = sess.from 
              while (true) {
                let slot = {
                  from: i,
                  to: i + 0.75
                }
                if (i === 0 && duration % 0.75 !== 0) {
                  
                  let extra = duration - Math.floor(duration/0.75)*0.75
                  if (duration < 0.75) {
                    
                    return;
                  } else {
                    slot.from += extra
                    slot.to += extra
                    i += extra
                  }
                } else if (slot.to > 24) {
                  slot.to -= 24
                }
                if (sessDuration === 30) {
                  slot.to -= 0.25;
                }
                slotArray.push([slot.from, slot.to]) 
                i += 0.75
                if (i >= sess.to)
                  break;
              }
            });
            setSlotsArray([...slotArray]);
          }
        });
      }
    }
  };
  const currReq = requests.filter(
    (item) =>
      item.date?.indexOf(moment(selectedDate).format("DD/MM/YYYY")) !== -1
  );
  let classes = "rounded-full bg-green-400 my-1 px-2 py-1";
  const settingColor = (item) => {
    if (currReq?.length !== 0) {
      for (let i = 0; i < currReq?.length; i++) {
        if (
          currReq[i].fromTime === item &&
          currReq[i].reqStatus === "ReqAccepted"
        ) {
          classes = "rounded-full bg-yellow-400 my-1 px-2 min-[600px]:py-1";
          return true;
        } else if (
          currReq[i].fromTime === item &&
          currReq[i].reqStatus === "ReqPaid"
        ) {
          classes = "rounded-full bg-red-400 my-1 px-2 min-[600px]:py-1";
          return true;
        }
      }
    }
    classes = "rounded-full bg-green-400 my-1 px-2 min-[600px]:py-1";
    return true;
  };
  useEffect(() => {
    displaySlots(selectedDate);
    setRequestData({
      ...requestData,
      date: moment(selectedDate).format("DD/MM/YYYY"),
      slot: "",
    }); // When date changes then date will be updated and slot will reset
  }, [selectedDate]);
  useEffect(() => {
    console.log('intId', iId)
    axios.get(`${BASE}/int/interviewer/${iId}`).then((res) => {
      // console.log(res.data)
      setInterViewer(res.data);
    }
    )
  }, [])


  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(mockId);
      toast.success(`Copied to clipboard`, {
        ...toastStyle.success,
      });
    } catch (error) {
      console.error('Failed to copy text:', error);
      toast.success(`Failed to copy text`, {
        ...toastStyle.loading,
      });
    }
  };

  const id = interviewer?.id
  // const name=
  //   interviewer?.fname +
  //   (interviewer?.lname
  //     ? " " + interviewer?.lname
  //     : "")

  const designation = interviewer?.designation
  const company = interviewer?.currCompany
  const price = {45:"INR 400",30:"INR 250"}[sessDuration]
  const profiles = interviewer?.profiles || []
  const photo = interviewer?.photo
  const logo = getCompanyLogo(interviewer?.currCompany)
  const gnrcSchd =
    interviewer?.GnrcSchedule
      ? interviewer?.GnrcSchedule
      : []

  const custSchd =
    interviewer?.CustomSchedule
      ? interviewer?.CustomSchedule
      : []
  const handleChangeRadio = (event) => {
    console.log(event.target.value);
    console.log(radioValue)
    setRadioValue(event.target.value);
  };
  const handleSubmit = async (data) => {
    axios.patch(`${BASE}/send/suggestSlot/${reqId}`, { date: moment(selectedDate).format("DD/MM/YYYY"), slot: radio })
      .then((res) => {
        console.log(res.data);
        setOpenDecline(false);
        setDecline(true);
        toast.success('Slot Suggestion sent to Student', {
          ...toastStyle.success,
          position: 'top-center'
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      })
      .catch((e) => { console.log(e) })
  }
  const handleChangeRadio2 = (event) => {
    console.log(event.target.value);
    console.log(radioValue)
    setRadio(event.target.value);
  };
  const handleCloseDecline = () => {
    setOpenDecline(false);
  }
  const handleRequestData = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // Append the property if doesn't exist and if does then update
    setRequestData((requestData) => ({
      ...requestData,
      [name]: value,
      studentId: sId,
      interviewerId: iId,
    }));
  };
  const checkInterviewerAvailability = async (requestData) => {
    console.log("requestData :-", requestData);
    let searchStr = "";
    searchStr += "?id=" + requestData.interviewerId;
    searchStr += "&profile=" + String(requestData.profile);
    searchStr +=
      "&time=" +
      requestData.slot.slice(1).split(":")[0] +
      "," +
      requestData.slot.slice(1).split(":")[1];
    searchStr += "&date=" + requestData.date;

    console.log("Rakesh Search STring");
    console.log(searchStr);
    let isInterviewerAvailable = false;
    await axios
      .get(INTERVIEWERS_GETBYFILTERS_ENDPOINT + searchStr)
      .then((res) => {
        console.log(
          "res.data[0].id :- ",
          res.data[0].id,
          " requestData.interviewerId :- ",
          requestData.interviewerId,
          " res.data[0].id === requestData.interviewerId :- ",
          res.data.id === requestData.interviewerId
        );
        console.log("Rakesh res.data?.length:");
        console.log(res.data?.length);
        console.log("Rakesh res Data:");
        console.log(res.data);
        if (res.data?.length === 0) {
          setisSlotAlredyBooked(true);
        }
        if (res.data?.length === 1) {
          isInterviewerAvailable = true;
        }
      })
      .catch((err) => console.log(err));
    console.log("isInterviewerAvailable", isInterviewerAvailable);
    return isInterviewerAvailable;
  };
  const handleOpen = () => {
    setOpen(true);
    async function fetchData() {
      axios
        .get(`${BASE}/send/getAllInterviewRequestById/${id}`)
        .then((response) => {
          setRequests(response.data);
          displaySlots(selectedDate);
        });
    }
    fetchData();
  };
  const handleClose = () => {
    setOpen(false);
    //setRequestData({});
    setSelectedDate(new Date());
  };
  const [datePickerMobile, setDatePickerMobile] = useState(false)
  const [toolTip, setToolTip] = useState(false);
  return (
    <>
      <Modal
        open={openDecline}
        onClose={handleCloseDecline}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          overflow: "hidden",
          width: '95%',
          maxWidth: "630px",
        }}>
          <IconButton style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '4' }} onClick={handleCloseDecline}>
            <CancelIcon sx={{ color: "red" }} />
          </IconButton>
          <FormControl
            sx={{ my: 2, mx: 3 }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend" sx={{ fontSize: '17px', paddingBottom: '5px', width: '100%' }}>

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <p>Choose an option</p>
              </div>
            </FormLabel>

            <FormGroup>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={`Decline this request and suggest ${name} to book another slot`}
                  onChange={handleChangeRadio}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="Just Decline this request"
                  onChange={handleChangeRadio}
                />
              </RadioGroup>
            </FormGroup>
          </FormControl>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
              radioValue === '1' &&
              <>
                <Button
                  sx={{ mt: -1, mb: 2 }}
                  variant="contained"
                  onClick={handleOpen}
                >
                  Choose a new Slot
                </Button>
                <Modal open={open} onClose={handleClose}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      borderRadius: 2,
                      boxShadow: 24,
                      overflow: "hidden",
                      maxWidth: "750px",
                      width: "95%",
                    }}
                  >
                    <div className="flex flex-row justify-between gap-2 bg-blue-500 p-2">
                      <Typography
                        id="modal-modal-title"
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          color: "white",
                          mx: 2,
                        }}
                        className="slot-headding"
                      >
                        Suggest a new slot
                      </Typography>
                      <IconButton onClick={handleClose}>
                        <CancelIcon sx={{ color: "white" }} />
                      </IconButton>
                    </div>
                    <div className="grid grid-cols-2 max-[600px]:grid-cols-1 gap-3">
                      <div className="col-span-1 m-2 flex flex-row justify-center">
                        <div>
                          <Typography
                            id="modal-modal-title"
                            variant="h5"
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                              my: 1,
                            }}
                            style={{ marginBottom: '20px' }}
                            className="slot-name"
                          >
                            To: {name}
                          </Typography>
                          <div className="w-full flex flex-row justify-center my-1 hide-picker">
                            <Calendar
                              minDate={new Date().setDate(new Date().getDate())}
                              zIndex={0}
                              value={selectedDate}
                              onChange={(e) => setSelectedDate(new Date(e))}
                              mapDays={({ date, selectedDate, isSameDate }) => {
                                let props = {};
                                if (isSameDate(date, selectedDate)) {
                                  props.style = {
                                    color: "#fff",
                                    backgroundColor: "#00a86b",
                                    border: "1px solid #000",
                                  };
                                  return props;
                                }
                              }}
                            />
                          </div>
                          {/* <div className="s-mobile-card" style={{ position: 'relative' }}>
                        <span style={{ fontWeight: '500', lineHeight: '30px', marginRight: '10px' }}>Select Date: </span><button class="button-13" onClick={() => setDatePickerMobile(!datePickerMobile)} role="button">{selectedDate !== ""
                          ? moment(selectedDate).format("DD MMM YYYY") + " "
                          : ""} <AiTwotoneCalendar style={{ display: 'inline-block', verticalAlign: 'text-top' }} /></button>
                        {
                          datePickerMobile && <Calendar
                            className="s-mobile-calendar-calendar"
                            minDate={new Date().setDate(new Date().getDate())}
                            zIndex={1}
                            value={selectedDate}
                            onChange={(e) => { setSelectedDate(new Date(e)); setDatePickerMobile(!datePickerMobile) }}
                            mapDays={({ date, selectedDate, isSameDate }) => {
                              let props = {};
                              if (isSameDate(date, selectedDate)) {
                                props.style = {
                                  color: "#fff",
                                  backgroundColor: "#00a86b",
                                  border: "1px solid #000"
                                };
                                return props;
                              }
                            }}
                          />
                        }
                      </div> */}
                          <div className="s-mobile-card flex" style={{ justifyContent: "flex-end" }}>
                            <div className="tt ml-auto">
                              <span style={{ fontWeight: '500', lineHeight: '30px', marginRight: '10px' }}>Select Date: </span><button class="button-13" onClick={() => setDatePickerMobile(!datePickerMobile)} role="button">{selectedDate !== ""
                                ? moment(selectedDate).format("DD MMM YYYY") + " "
                                : ""} <AiTwotoneCalendar style={{ display: 'inline-block', verticalAlign: 'text-top' }} /></button>
                              <span style={{ background: 'black', color: 'white', padding: '3px 5px', top: '-146px', textAlign: 'left' }} className="calendar-mobile-tooltip tttext">
                                <Calendar
                                  className="s-mobile-calendar-calendar"
                                  minDate={new Date().setDate(new Date().getDate())}
                                  zIndex={1}
                                  value={selectedDate}
                                  onChange={(e) => { setSelectedDate(new Date(e)); setDatePickerMobile(!datePickerMobile) }}
                                  mapDays={({ date, selectedDate, isSameDate }) => {
                                    let props = {};
                                    if (isSameDate(date, selectedDate)) {
                                      props.style = {
                                        color: "#fff",
                                        backgroundColor: "#00a86b",
                                        border: "1px solid #000"
                                      };
                                      return props;
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 m-2 my-2 flex flex-row justify-center items-center">
                        <div>
                          {/* <Typography
                          variant="h5"
                          component="h5"
                          sx={{ mb: "2px" }}
                        >
                          Choose a Profile
                        </Typography>
                        <RadioGroup
                          id="profile"
                          name="profile"
                          row
                          value={requestData.profile || ""}
                          onChange={handleRequestData}
                          sx={{
                            maxHeight: "100px",
                            overflowX: "auto",
                          }}
                        >
                          {profiles.map((profileItem) => (
                            <FormControlLabel
                              value={profileItem}
                              control={<Radio sx={{ py: "2px" }} />}
                              label={profileItem}
                            />
                          ))}
                        </RadioGroup>
                        <hr className="mx-4 my-3 border-black" /> */}
                          <div className="relative border-2 border-gray-400 rounded p-1 my-1 flex flex-wrap">
                            <div className="flex mr-3">
                              <div className="inline-block rounded-full w-4 h-4 mx-1 bg-green-500"></div>
                              Available
                            </div>
                            <div className="flex mr-3">
                              <div className="inline-block rounded-full w-4 h-4 mx-1 bg-orange-400"></div>
                              Conditionally Available
                            </div>
                            <div className="flex mr-3">
                              <div className="inline-block rounded-full w-4 h-4 mx-1 bg-red-500"></div>
                              Occupied
                            </div>
                            <div className="absolute right-0 flex mr-3 text-blue-600 w-5 cursor-pointer">
                              {/* <Tooltip
                              open={toolTip}

                              disableFocusListener
                                title={
                                  <>
                                    <p className="my-1">
                                      Green: The slot is completely available to you
                                    </p>
                                    <p className="my-1">
                                      Orange: Another student has already chosen the
                                      slot, but is yet to pay and confirm. You can
                                      still request that slot, and pay first after
                                      acceptance to confirm
                                    </p>
                                    <p className="my-1">
                                      Red: The slot is not available to you, because
                                      another student has already confirmed the slot
                                    </p>
                                  </>
                                }
                              >
                                <img
                                onClick={() => setToolTip(!toolTip)}
                                  src={require("../../../images/exclamation.png")}
                                  alt="Description"
                                  style={{ transform: "rotateZ(180deg)" }}
                                />
                              </Tooltip> */}
                              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <div className="tt ml-auto">
                                  <InfoOutlined
                                    sx={{ color: "rgb(83, 81, 81)", fontSize: "20px" }}
                                  />
                                  <span style={{ background: 'black', color: 'white', padding: '3px 5px', top: '-146px', textAlign: 'left' }} className="tttext">
                                    <p className="my-1 mb-[0.5rem]">
                                      <span style={{ color: "lime", fontWeight: 'bold' }}>Green: </span>The slot is completely available to you
                                    </p>
                                    <p className="my-1 mb-[0.5rem]">
                                      <span style={{ color: "orange", fontWeight: 'bold' }}>Orange: </span>Another student has already chosen the
                                      slot, but is yet to pay and confirm. You can
                                      still request that slot, and pay first after
                                      acceptance to confirm
                                    </p>
                                    <p className="my-1">
                                      <span style={{ color: "red", fontWeight: 'bold' }}>Red: </span>The slot is not available to you, because
                                      another student has already confirmed the slot
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Typography variant="h5" component="h5" className="heading1">
                            Choose a Slot
                          </Typography>
                          <RadioGroup
                            row
                            name="slot"
                            id="slot"
                            value={requestData.slot || ""}
                            onChange={(e) => { handleRequestData(e); handleChangeRadio2(e) }}
                            sx={{ maxHeight: "150px", overflowX: "auto" }}
                          >
                            {slotsArray?.length !== 0 ? (
                              slotsArray.map(
                                (item) =>
                                  (compareSlots(item[0]) ||
                                    !compareDate(selectedDate, new Date())) &&
                                  settingColor(item[0]) === true && (
                                    <div className={classes}>
                                      <FormControlLabel
                                        value={"#" + item[0] + ":" + item[1]}
                                        key={"#" + item[0] + ":" + item[1]}
                                        control={<Radio sx={{ py: 0 }} />}
                                        label={
                                          t24HDecimalTo12HFormat(item[0]) +
                                          " - " +
                                          t24HDecimalTo12HFormat(item[1])
                                        }
                                        disabled={classes.includes("red")}
                                      />
                                    </div>
                                  )
                              )
                            ) : (
                              <p className="text-center">
                                No slots available on{" "}
                                <span className="font-bold">
                                  {moment(selectedDate).format("Do MMM, YYYY")}
                                </span>
                              </p>
                            )}
                          </RadioGroup>
                          <div
                            className="text-center border mt-2 rounded-md py-0.5 text-green-600 cursor-pointer hover:text-blue-600 hover:bg-gray-100"
                            onClick={() => navigate(INTERVIEWER_PROFILE_FORM_ROUTE)}
                            style={{ maxWidth: '40%', fontSize: '15px', marginLeft: 'auto', borderColor: 'green' }}
                          >
                            <p>
                              <i className="fa-solid fa-plus"></i> Add a New Slot
                            </p>
                          </div>
                          {requestData.slot && <div className="mt-2">Suggested Slot: {t24HDecimalTo12HFormat(requestData.slot.replaceAll('#', '').split(':')[0])}  -
                            {t24HDecimalTo12HFormat(requestData.slot.split(':')[1])}</div>}
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex flex-row justify-center my-2" style={{ background: ' #ffdede', margin: '0px', padding: '8px', marginTop: '20px' }}>
                      <Button
                        color="error"
                        variant="contained"
                        disabled={!sendBtnDisabled}
                        sx={{ backgroundColor: "red" }}
                        onClick={() => {
                          console.log(
                            "date: ",
                            moment(selectedDate).format("DD/MM/YYYY")
                          );
                          console.log(
                            "requestData Before sending to checkInterviewerAvailability(): ",
                            requestData
                          );
                          console.log("Sending Request...");
                          checkInterviewerAvailability(requestData).then(
                            (isInterviewerAvailable) => {
                              if (isInterviewerAvailable)
                                handleSubmit();
                            }
                          );
                          handleClose();
                        }}
                      >
                        Decline Original Request
                      </Button>
                    </div>
                  </Box>
                </Modal>
                {isSlotAlreadyBooked ? (
                  <SlotAlreadyBooked
                    isSlotAlreadyBooked={isSlotAlreadyBooked}
                    slotBookedModalHandler={slotBookedModalHandler}
                  />
                ) : null}
              </>
            }
            {/* normal decline */}
            {
              radioValue === '2' && <Button
                variant="contained"
                color="error"
                sx={{ backgroundColor: "red", mt: -1, mb: 2 }}
                onClick={() => {
                  console.log('decline 1++++++++++', isRescheduled)
                  // Remove This item
                  setOpenDecline(false)
                  setDecline(true);
                  // setDeclined(() => declined + 1);
                  // setTimeout(() => {
                  //   remove(index, sIndex);
                  //   setDecline(false);
                  // }, 1000);
                  const obj = {
                    status: "ReqDeclined",
                  };
                  axios
                    .patch(`${BASE}/send/updateInterviewReq/${reqId}`, obj)
                    .then((response) => {
                      console.log('s-request declined response', response, 's-req-data', obj, `${BASE}/send/updateInterviewReq/${reqId}`);
                      toast.success('Request Declined Successfully', {
                        ...toastStyle.success,
                        position: "top-center",
                        style: {
                          // marginTop:'30%',
                          border: '1px solid #713200',
                          padding: '16px',
                          color: '#fff',
                          backgroundColor: '#0e8f11'
                        }
                      });
                      setTimeout(() => {
                        window.location.reload(false);
                      }, 3000);
                    });

                  // window.alert("Are you sure?");
                }}
              >
                Decline
              </Button>
            }
          </div>
        </Box>
      </Modal>
      {(isRescheduled === 'true' || isRescheduled === true) && reschedule.status === "sent" && <div className={'box-shadow-request-card px-3 py-2 s-desktop-card'} style={{ position: 'absolute', right: '30px', zIndex: '2', backgroundColor: '#f5ed4e', borderRadius: '10px' }}>
        <p>Reschedule Request</p>
      </div>}
      <div
        className={
          "relative w-full overflow-hidden bg-white box-shadow-request-card grid grid-cols-4 rounded-lg my-4 p-2 s-desktop-card"
        }
        style={{ zIndex: 1 }}
        id={"stack-" + cardId}
      >

        {/* Accepted */}
        {accept ? (
          <div
            className="absolute z-10 bg-gray-500/50 ring-8 ring-green-500 ring-inset h-full w-full overflow-hidden"
            id="accepted"
          >
            <div className="flex justify-center items-center h-full ">
              <div className="px-4 py-2 bg-green-500 rounded-md">
                <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                  Accepted
                </Typography>
              </div>
            </div>
          </div>
        ) : null}

        {/* Declined */}
        {decline ? (
          <div
            className="absolute z-10 bg-gray-500/50 ring-8 ring-red-600 ring-inset h-full w-full overflow-hidden"
            id="decline"
          >
            <div className="flex justify-center items-center h-full">
              <div className="px-4 py-2 bg-red-600 rounded-md">
                <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                  Declined
                </Typography>
              </div>
            </div>
          </div>
        ) : null}

        {/* -------- */}

        <div className="border-r border-gray-400 my-auto col-span-1">
          <img
            src={image}
            className="h-32 rounded-full border-2 border-black p-1 block mx-auto"
            alt="Profile"
          />
          <button onClick={() => {
            isUnavailable ? toast.loading(`Sorry! the user has ${isDisable ? 'disabled' : ''} ${isDelete ? 'deleted' : ''} this account`, {
              ...toastStyle.loading,
              position: 'top-center',
              duration: 2000,
            }) : window.open(`${process.env.REACT_APP_FRONTEND_SERVER_URL}/i/student/${sId}`, "_blank")
          }} className="view-profile-card-btn w-4/5 mt-2 mx-auto">
            View Profile
          </button>
        </div>
        <div className="px-3 col-span-3">
          <div className="flex py-0 justify-between w-full">
            <div className="flex gap-x-2">
              <div className="w-12 h-12 drop-shadow-2 mx-1 rounded-lg">
                {isIIT(college) ? (
                  <img
                    src={require("../../Dashboard/Logos_IITs/" +
                      college.split(" ").join("_") +
                      "_Logo.png")}
                    // className="w-12 h-12 object-contain"
                    alt={college}
                  />
                ) : <img
                  src={require('../../Dashboard/Logos_IITs/interwiu_logo.png')}
                  // className="w-12 h-12 object-contain"
                  alt={college}
                />}
              </div>
              <div>
                {/* <h4 className="text-2xl text-left font-medium leading-6">
                  {name}
                </h4>
                <p>{programValues[program]} - {college}</p>
                <p>{branch}</p> */}
                <h4 className="text-2xl text-left font-medium leading-6">{name}</h4>
                <p>{programValues[program]}, {year}, {branch}</p>
                <p>{college}</p>
              </div>
            </div>
            <div className="flex gap-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <div className="flex justify-center h-10 text-blue-500 text-2xl">
                <Button variant="contained" onClick={() => downloadPDF((resume))}>
                  <i className="fa-solid fa-link mr-1"></i> Resume
                </Button>

              </div>
              {
                mockId &&
                <div className="text-green-700 pb-0 text-lg font-bold">
                  {{interview: "Mock Interview ID", mentorship: "Mentorship Session ID", resume:"Resume Building Session ID"}[sessType]}: {mockId}
                </div>
              }
              {/* <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
                <i className="fa-solid fa-share"></i>
              </div> */}
              {/* {isRescheduled === 'true' && <p>Reschedule Request</p>} */}
            </div>
          </div>
          {/* <div
            className="flex justify-between text-center my-1 gap-x-1 mx-2"
            style={{ alignItems: "flex-start" }}
          >
            <div className="col-span-2">
              <p className="text-base text-gray-400">Profile</p>
              <Chip
                label={subprofile ? `${subprofile} (${profile})`: profile}
                color="info"
                sx={{ fontWeight: "medium",
                height: "auto",
                display: "block",
                maxWidth: "fit-content",
                px: 0.5,
                '& .MuiChip-label' :{
                  whiteSpace: "pre-wrap",
                  display: "flex",
                  flexWrap: "wrap",
                  px: 0.5,
                  fontSize: {
                    sm: "0.8rem"
                  }
                }
                }}
              />
            </div>
            <div className="col-span-2">
              <p className="text-base text-gray-400">Price</p>
              <p className="text-lg">INR process.env.REACT_APP_STD_45MIN</p>
            </div>
            <div className="col-span-2">
              <p className="text-base text-gray-400">Session Duration</p>
              <p className="text-lg">30 Min</p>


            </div>
            <div className="col-span-3" style={{minWidth: "177px"}}>
              <p className="text-base text-gray-400">Interview Slot</p>
              <span className="text-lg">{t24HDecimalTo12HFormat(
                [true,"true"].includes(isRescheduled)?reschedule.details?.reschFromTime: slotStart)}</span>
              <span> &nbsp; - &nbsp;</span>
              <span className="text-lg">{t24HDecimalTo12HFormat(
                [true,"true"].includes(isRescheduled)?reschedule.details?.reschToTime: slotEnd)}</span>
              
              <p className="text-lg">{console.log(reschedule) || convertDateFormat(
                [true,"true"].includes(isRescheduled)?reschedule.details?.reschDate || date :date)}</p>
            </div>
          </div> */}
          <StudCardDetails
            subprofile={subprofile}
            profile={profile}
            isRescheduled={isRescheduled ?? "other"}
            reschedule={reschedule ?? {
              status: "",
              details: null
            }}
            price={price}
            slotEnd={slotEnd}
            slotStart={slotStart}
            date={date}
            sessDuration={sessDuration}
            sessType={sessType}
          />
          {status === "ReqAccepted" && <div className="flex justify-center"><div className="border rounded p-2 inline w-fit" style={{ borderColor: 'red', color: 'red' }}>
            Waiting for {name} to Pay and Confirm
          </div></div>}
          {/* {
              isAccepted === true && <div className="border rounded p-2 inline">
              <div className="flex">
                <img
                  src={require("../../../images/loading.gif")}
                  alt="loading"
                  style={{
                    width: "30px",
                    marginRight: "10px",
                  }}
                />
                <p>Waiting for John Doe to Pay & Confirm</p>
              </div>
            </div>
            } */}
          <div className="flex justify-center">
            {isAccepted === false && <Button
              variant="contained"
              color="success"
              sx={{ marginRight: "10px" }}
              onClick={() => {
                const obj = {
                  status: "ReqAccepted",
                };

                (isRescheduled === true || isRescheduled === 'true') && reschedule.status === "sent" ?
                  axios.patch(`${BASE}/send/handleRescheduleInterviewer/${reqId}`, {
                    status: "accepted",
                    reschDetails: reschedule.details
                  }).then(
                    (res) => {
                      const { data2 } = axios.patch(
                        `${BASE}/send/autorejectrequests/${reqId}`
                      ).then((res) => {
                        toast.success('Request Accepted Successfully', {
                          ...toastStyle.success,
                          position: "top-center",
                          style: {
                            // marginTop:'30%',
                            border: '1px solid #713200',
                            padding: '16px',
                            color: '#fff',
                            backgroundColor: '#0e8f11'
                          }
                        });
                        setTimeout(() => {
                          window.location.reload(true);
                        }, 2000);
                        console.log(res);
                      }).catch((e) => {
                        console.log(e)
                      })
                      console.log(res);
                    }).catch((e) => {
                      console.log(e)
                    }) :
                  axios
                    .patch(`${BASE}/send/updateInterviewReq/${reqId}`, obj)
                    .then((response) => {
                      console.log(response);
                      toast.success('Requests Accepted Successfully', {
                        ...toastStyle.success,
                        position: "top-center",
                        style: {
                          // marginTop:'30%',
                          border: '1px solid #713200',
                          padding: '16px',
                          color: '#fff',
                          backgroundColor: '#0e8f11'
                        }
                      });
                      setTimeout(() => {
                        window.location.reload(true);
                      }, 2000);
                    }).catch((err) => {
                      console.log(err);
                      setTimeout(() => {
                        window.location.reload(true);
                      }, 3000);
                    });
                // Remove This item
                setAccept(true);
                // setAccepted(() => accepted + 1);
                // setTimeout(() => {
                //   remove(index, sIndex);
                //   setAccept(false);
                // }, 1000);
                // window.alert("Are you sure?");
              }}
            >
              Accept
            </Button>}
            {isAccepted === false && <Button
              variant="contained"
              color="error"
              sx={{ backgroundColor: "red" }}
              onClick={() => {
                // console.log('decline 2 ++++++++++')
                // desktop decline

                (isRescheduled === true || isRescheduled === 'true') && reschedule.status === "sent" ?
                  axios.patch(`${BASE}/send/handleRescheduleInterviewer/${reqId}`, {
                    status: "declined",
                  })
                    .then((res) => {
                      toast.success("Reschedule request declined", {
                        ...toastStyle.error,
                        position: "top-center"
                      })
                      setTimeout(() => {
                        window.location.reload(true)
                      }, 1000)
                      console.log(res)
                    })
                    .catch((err) => {
                      toast.error("Reschedule request decline failed", {
                        ...toastStyle.error,
                        position: "top-center"
                      })
                      setTimeout(() => {
                        window.location.reload(true)
                      })
                      console.log(err)
                    }) :
                  setOpenDecline(true);
              }}
            >
              Decline
            </Button>}
            {/* {isAccepted === false && <Button
              variant="contained"
              color="error"
              sx={{ backgroundColor: "red" }}
              onClick={() => {
                // Remove This item
                setDecline(true);
                // setDeclined(() => declined + 1);
                // setTimeout(() => {
                //   remove(index, sIndex);
                //   setDecline(false);
                // }, 1000);
                const obj = {
                  status: "ReqDeclined",
                };
                axios
                  .patch(`${BASE}/send/updateInterviewReq/${id}`, obj)
                  .then((response) => {
                    console.log(response);
                    toast.success('Request Declined Successfully', {
                      ...toastStyle.success,
                      position: "top-center",
                      style:{
                        // marginTop:'30%',
                        border: '1px solid #713200',
                        padding: '16px',
                        color: '#fff',
                        backgroundColor:'#0e8f11'
                      }
                    });
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 3000);
                  });

                // window.alert("Are you sure?");
              }}
            >
              Decline
            </Button>} */}
          </div>
        </div>
      </div>


      {/* mobile-------------- card */}


      <div
        className={
          "relative w-full overflow-hidden bg-white box-shadow-request-card grid grid-cols-4 rounded-lg my-4 s-card-main s-mobile-card s-mobile-card-shadow"
        }
        style={{ zIndex: 1 }}
        id={"stack-" + cardId}
      >
        {(isRescheduled === "true" || isRescheduled === true) && reschedule.status === "sent" && (
          <div
            className={"s-mobile-card"}
            style={{
              position: "absolute",
              left: "0px",
              zIndex: "2",
              backgroundColor: "#f5ed4e",
              borderBottomRightRadius: "10px",
              padding: '0.2rem 0.75rem 0.1rem 0.75rem',
            }}
          >
            <p style={{
              fontSize: '0.8rem',
              fontFamily: 'sans-serif',
              letterSpacing: '1.5px'
            }}>Reschedule Request</p>
          </div>
        )}

        <Button className="box-shadow-request-card s-mobile-resume-btn" variant="contained" onClick={() => downloadPDF((resume))}>
          <i className="fa-solid fa-link mr-1"></i> Resume
        </Button>

        {/* Accepted */}
        {accept ? (
          <div
            className="absolute z-10 bg-gray-500/50 ring-8 ring-green-500 ring-inset h-full w-full overflow-hidden"
            id="accepted"
          >
            <div className="flex justify-center items-center h-full ">
              <div className="px-4 py-2 bg-green-500 rounded-md">
                <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                  Accepted
                </Typography>
              </div>
            </div>
          </div>
        ) : null}

        {/* Declined */}
        {decline ? (
          <div
            className="absolute z-10 bg-gray-500/50 ring-8 ring-red-600 ring-inset h-full w-full overflow-hidden"
            id="decline"
          >
            <div className="flex justify-center items-center h-full">
              <div className="px-4 py-2 bg-red-600 rounded-md">
                <Typography variant="h4" component="h4" sx={{ color: "white" }}>
                  Declined
                </Typography>
              </div>
            </div>
          </div>
        ) : null}

        {/* -------- */}


        <div className="s-profile">
          <div className=" my-auto col-span-1 s-profile-p-p">
            <img
              src={image}
              className="rounded-full border-2 border-black p-1 block mx-auto"
              alt="Profile"
              width={{ width: "100%" }}
            />
            <button
              style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                isUnavailable ? toast.loading(`Sorry! the user has ${isDisable ? 'disabled' : ''} ${isDelete ? 'deleted' : ''} this account`, {
                  ...toastStyle.loading,
                  position: 'top-center',
                  duration: 2000,
                }) : window.open(`${process.env.REACT_APP_FRONTEND_SERVER_URL}/i/student/${sId}`, "_blank")
              }}
              className="view-profile-card-btn mt-2 mx-auto view-profile-card-btn-mobile"
            >
              View Profile
            </button>
          </div>
          <div
            className="flex py-1 justify-between w-full s-profile-n-d-c"
            style={{ alignItems: "start" }}
          >
            <h4 className="text-2xl font-medium">{name}</h4>
            <p>{programValues[program]}, {year}, {branch}</p>
            <p>{college}</p>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ marginBottom: '8px' }}>
            <div className="s-duration-price" style={{ marginBottom: '10px' }}>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Duration : </span>
                <span className="text-lg">{sessDuration} Min</span>
              </div>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Price : </span>
                <span className="text-lg">{price}</span>
              </div>
            </div>

            <div style={{ justifyContent: 'flex-start' }} className="s-duration-price">
              <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold', marginRight: '5px', transform: 'translateY(2px)' }}>Interview Slot : </p>
              <div >
                <span className="text-lg"> {t24HDecimalTo12HFormat([true, "true"].includes(isRescheduled) ? reschedule.details?.reschFromTime : slotStart)}</span>
                <span> - </span>
                <span className="text-lg">{t24HDecimalTo12HFormat([true, "true"].includes(isRescheduled) ? reschedule.details?.reschToTime : slotEnd)}</span>
                <p className="text-lg">{convertDateFormat([true, "true"].includes(isRescheduled) ? reschedule.details?.reschDate || date : date)}</p>
              </div>
            </div>

            <div className="s-profile-profiles">
              <div className=" col-span-2">
                <div className="text-lg flex flex-wrap gap-1">
                  <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold' }}>Profile : </p>
                  <Chip
                    className="s-mobile-changing-chip-property"
                    label={subprofile ? `${subprofile} (${profile})` : profile}
                    color="info"
                    sx={{ fontWeight: "medium" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="" style={{ textAlign: "center" }}>
          {mockId && (
            <div className="text-green-700 pb-2 text-lg font-bold"
              style={{
                fontSize: '13px',
                textAlign: 'center'
              }}>
              {{interview: "Mock Interview ID", mentorship: "Mentorship Session ID", resume:"Resume Building Session ID"}[sessType]}: {mockId} <FaCopy style={{ display: 'inline', fontSize: '1rem', transform: 'translate(6px,-3px)' }} onClick={handleCopy} />
            </div>
          )}
        </div>


        <div className=" col-span-3">
          {status === "ReqAccepted" && <div className="border text-center p-2" style={{ borderColor: 'red', color: 'red', borderRadius: '0px 0px 10px 10px' }}>
            Waiting for {name} to Pay and Confirm
          </div>}
          <div className="flex justify-center" style={{ gap: '3px' }}>
            {isAccepted === false && <Button
              variant="contained"
              color="success"
              style={{ width: '100%', borderRadius: '0px', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset' }}
              // onClick={() => {
              //   const obj = {
              //     status: "ReqAccepted",
              //   };
              //   (isRescheduled === true || isRescheduled === 'true') ?
              //     axios.patch(`${BASE}/send/appendBookedSlot/${reqId}`, { status: "accepted", reschDetails: reschedule.details }).then((res) => {
              //       console.log(res);
              //       setTimeout(() => {
              //         window.location.reload(true);
              //       }, 2000);

              //     }).catch((e) => {
              //       console.log(e)
              //     }) :
              //     axios
              //       .patch(`${BASE}/send/updateInterviewReq/${reqId}`, obj)
              //       .then((response) => {
              //         console.log(response);
              //         toast.success('Requests Accepted Successfully', {
              //           ...toastStyle.success,
              //           position: "top-center",
              //           style: {
              //             // marginTop:'30%',
              //             border: '1px solid #713200',
              //             padding: '16px',
              //             color: '#fff',
              //             backgroundColor: '#0e8f11'
              //           }
              //         });
              //         setTimeout(() => {
              //           window.location.reload(true);
              //         }, 2000);
              //       }).catch((err) => {
              //         console.log(err);
              //         setTimeout(() => {
              //           window.location.reload(true);
              //         }, 3000);
              //       });

              //   // Remove This item
              //   setAccept(true);
              //   // setAccepted(() => accepted + 1);
              //   // setTimeout(() => {
              //   //   remove(index, sIndex);
              //   //   setAccept(false);
              //   // }, 1000);
              //   // window.alert("Are you sure?");
              // }}
              onClick={() => {
                const obj = {
                  status: "ReqAccepted",
                };

                (isRescheduled === true || isRescheduled === 'true') &&
                 reschedule.status === "sent" ?
                  axios.patch(`${BASE}/send/handleRescheduleInterviewer/${reqId}`, {
                    status: "accepted",
                    reschDetails: reschedule.details
                  }).then(
                    (res) => {
                      toast.success('Request Accepted Successfully', {
                            ...toastStyle.success,
                            position: "top-center",
                            style: {
                            marginTop:'30%',
                            border: '1px solid #713200',
                            padding: '16px',
                            color: '#fff',
                            backgroundColor: '#0e8f11'
                          }
                        });
                      const { data2 } = axios.patch(
                        `${BASE}/send/autorejectrequests/${reqId}`
                      ).then((res) => {
                        setTimeout(() => {
                          window.location.reload(true);
                        }, 2000);
                        console.log(res);
                      }).catch((e) => {
                        setTimeout(() => {
                          window.location.reload(true);
                        }, 2000);
                        console.log(e)
                      })
                      console.log(res);
                    }).catch((e) => {
                      console.log(e)
                    }) :
                  axios
                    .patch(`${BASE}/send/updateInterviewReq/${reqId}`, obj)
                    .then((response) => {
                      console.log(response);
                      toast.success('Requests Accepted Successfully', {
                        ...toastStyle.success,
                        position: "top-center",
                        style: {
                          // marginTop:'30%',
                          border: '1px solid #713200',
                          padding: '16px',
                          color: '#fff',
                          backgroundColor: '#0e8f11'
                        }
                      });
                      setTimeout(() => {
                        window.location.reload(true);
                      }, 2000);
                    }).catch((err) => {
                      console.log(err);
                      setTimeout(() => {
                        window.location.reload(true);
                      }, 3000);
                    });
                // Remove This item
                setAccept(true);
                // setAccepted(() => accepted + 1);
                // setTimeout(() => {
                //   remove(index, sIndex);
                //   setAccept(false);
                // }, 1000);
                // window.alert("Are you sure?");
              }}
            >
              Accept
            </Button>}
            {isAccepted === false && <Button
              style={{ width: '100%', borderRadius: '0px', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset' }}

              variant="contained"
              color="error"
              sx={{ backgroundColor: "#e31515" }}
              // onClick={() => {

              //   (isRescheduled === true || isRescheduled === 'true') ?
              //     axios.patch(`${BASE}/send/appendBookedSlot/${reqId}`, { status: "declined" }).then((res) => {
              //       console.log(res);
              //     }).catch((e) => {
              //       console.log(e)
              //     }) : setOpenDecline(true);
              // }}
              onClick={() => {

                (isRescheduled === true || isRescheduled === 'true') && reschedule.status === "sent" ?
                  axios.patch(`${BASE}/send/handleRescheduleInterviewer/${reqId}`, {
                    status: "declined",
                  })
                    .then((res) => {
                      toast.success("Reschedule request declined", {
                        ...toastStyle.error,
                        position: "top-center"
                      })
                      setTimeout(() => {
                        window.location.reload(true)
                      }, 1000)
                      console.log(res)
                    })
                    .catch((err) => {
                      toast.error("Reschedule request decline failed", {
                        ...toastStyle.error,
                        position: "top-center"
                      })
                      // setTimeout(() => {
                      //   window.location.reload(true)
                      // })
                      console.log(err)
                    }) :
                  setOpenDecline(true);
              }}
            >
              Decline
            </Button>}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceivedRequest;