import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Signupstudent.css";
// import { GoogleLogin } from "react-google-login";
import axios from "axios";
import {
  BASE,
  AUTH_STD_GOOGLE_ENDPOINT,
  AUTH_ADM_GOOGLE_ENDPOINT,
} from "../../constants/endpoints";
import {
  HOME_ROUTE,
  STUDENT_FIND_AN_INTERVIEWER_ROUTE,
  STUDENT_PROFILE_FORM_ROUTE,
} from "../../constants/routes";
import { auth, googleProvider } from "../../firebase";
import UserContext from "../../contexts/UserContext";
import toast from "react-hot-toast";
import { toastStyle } from "../../utility/helper";

const remove = () => {
  const loginFunc = document.querySelector(".signup-admin");
  loginFunc.classList.add("hide");
  console.log("buttonClicked")
};
function childClick(event) {
  // console.log("Child clicked");
  event.stopPropagation(); // stop propagation to parent
}


const Signupadmin = () => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleAdmGoogle = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        // console.log(res.user);
        const email_verified = res.user.emailVerified;
        const iemail = res.user.email;
        const iname = res.user.displayName;
        const sub = res.user.uid;
        const picture = res.user.photoURL;
        axios({
          method: "POST",
          url: AUTH_ADM_GOOGLE_ENDPOINT,
          data: { email_verified, iemail, iname, sub, picture },
        }).then((response) => {
          console.log("Google Login Success", response);
          setUser(response.data.user);
          localStorage.setItem(
            "isAAuthenticated",
            JSON.stringify({ token: response.data.token })
          );
          remove();
          if (response.data.user.institute) navigate("/admin/profile-form");
          else {
            navigate("/admin/profile-form");
            setTimeout(() => {
              toast.success(`You Logged in Successfully`, {
                ...toastStyle.success,
              });
            }, 100);
            setTimeout(() => {
              toast.loading(`Please fill your profile details`, {
                ...toastStyle.loading,
                duration: 3500,
              });
            }, 2500);
          }
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <div className="signup-admin hide" style={{ backdropFilter: "blur(10px)", paddingTop:"70px"}} onClick={() => {
      remove();
    }}>
      <div className="login" style={{margin: "10px", minHeight: "50vh"}} onClick={(e)=>childClick(e)}>
        <div
          className="cross"
          onClick={() => {
            remove();
          }}
        >
          <i className="fa-solid fa-circle-xmark"></i>
        </div>
        <div className="login-head login-head-2">
          <p
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              lineHeight: "1.2",
              backgroundColor: "#E4EDF3",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <span>
              Let’s appear for a Mock Interview and obtain Feedback on your
              performance in realtime !
            </span>
            <span style={{ fontWeight: "bold", marginTop: "10px" }}>
              Create an Account now !
            </span>
          </p>
        </div>
        <div className="login-system">
          <div className="col">
            <h1 style={{paddingBottom:"10px"}}>Create an Account as an<br/>Institute Admin</h1>
            {/* <GoogleLogin
              clientId="752367686296-0scm5me6nrjuc64f9dc36i88c7mstjrt.apps.googleusercontent.com"
              buttonText="Sign in with Google"
              onSuccess={responseSuccessGoogleStd}
              onFailure={responseErrorGoogleStd}
              cookiePolicy={'single_host_origin'}
            /> */}
            <form onSubmit={(e) => handleAdmGoogle(e)}>
              <button className="signin-btn login" type="submit">
                <img src={require("../../images/google.png")} alt="Google" />
                Create Account with Google
              </button>
            </form>
          </div>
          <div className="login-image col">
            <img src={require("../../images/group3.png")} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signupadmin;
