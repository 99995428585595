import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../constants/variables";
import Navbarlogedin from "./Navbarlogedin";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddedStudents from "./currentStudents/AddedStudents";
import AwaitedStudents from "./currentStudents/AwaitedStudents";
import RegisteredStudents from "./currentStudents/RegisteredStudents";
import UserContext from "../../contexts/UserContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faDeleteLeft,
  faDumpster,
  faTrash,
  faPersonWalkingArrowLoopLeft,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
const CurrentStudents = () => {
  const [value, setValue] = React.useState("1");
  const { user } = useContext(UserContext);
  console.log(user);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tooltip = (
    <p>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip className="tooltip">
          The coupon codes which <br/> are struck-through have <br/> been already used by <br/> the respective student(s) <br/> to book mock interview(s)
          </Tooltip>
        }
      >
        <FontAwesomeIcon
          className="mr-[10px] rounded-full bg-black text-white p-1 w-[20px] scale-75"
          icon={faInfo}
        />
      </OverlayTrigger>
    </p>
  );

  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Current Students</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <Box
        sx={{
          // mt: 4,
          typography: "body1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "20px",
          paddingBottom: "100px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
          }}
          style={{fontSize: 'clamp(20px,6vw,3rem)', marginTop: 'clamp(20px,-6vw,3rem)', marginBottom: 'clamp(20px,-6vw,3rem)'}}
        >
          Current Students
        </Typography>
        <TabContext value={value}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab className="s-mobile-one-word-per-line" icon={tooltip} label="Added Students" value="1" />
              <Tab className="s-mobile-one-word-per-line" icon={tooltip} label="Registered Students" value="2" />
              <Tab className="s-mobile-one-word-per-line" icon={tooltip} label="Awaited Students" value="3" />
            </TabList>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TabPanel className="s-mobile-padding-tab" value="1">
              <AddedStudents />
            </TabPanel>
            <TabPanel className="s-mobile-padding-tab" value="2">
              <RegisteredStudents />
            </TabPanel>
            <TabPanel className="s-mobile-padding-tab" value="3">
              <AwaitedStudents />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>{" "}
    </>
  );
};

export default CurrentStudents;
