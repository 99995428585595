import { Checkbox,Button, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Switch, TextField, alertClasses } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components';
import tw from 'twin.macro';
import { BASE } from '../../constants/endpoints';
import UserContext from '../../contexts/UserContext';
import { COMPANY_NAME } from '../../constants/variables'
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
import NavbarInterviewer from './NavbarInterviewer';
import { toast } from 'react-hot-toast';
import { toastStyle } from '../../utility/helper';
import { HOME_ROUTE } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
const Container = tw.div`relative bg-white`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center pt-10 md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl pt-2 lg:text-5xl text-center md:text-left leading-tight`;

const AccountSettings = () => {
  const { user, setUser } = useContext(UserContext);
  let navigate = useNavigate();
  const subheading = "",
  heading = (
    <>
      <span tw="text-changed-100">Account Settings</span>
      <wbr />
    </>
  ),
  textOnLeft = true;
  const [checked, setChecked] = useState();
  const handleChangeRadio = (event) => {
    console.log(checked);
    setChecked(event.target.value);
  };
  const [checked2, setChecked2] = useState();
  const handleChangeRadio2 = (event) => {
    console.log(checked2);
    setChecked2(event.target.value);
  };
  const [state, setState] = React.useState({
    one: user?.whatsappStop?.one,
    two: user?.whatsappStop?.two,
    three: user?.whatsappStop?.three,
    four: user?.whatsappStop?.four,
    five: user?.whatsappStop?.five,
    six: user?.whatsappStop?.six,
  });
  const { one, two, three, four, five, six } = state;
  const handleChangeCheckBox = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const [state2, setState2] = React.useState({
    one2: user?.emailStop?.one,
    two2: user?.emailStop?.two,
    three2: user?.emailStop?.three,
    four2: user?.emailStop?.four,
    five2: user?.emailStop?.five,
    six2: user?.emailStop?.six,
  });
  const { one2, two2, three2, four2, five2, six2 } = state2;
  const handleChangeCheckBox2 = (event) => {
    setState2({
      ...state2,
      [event.target.name]: event.target.checked,
    });
  };
  const [emailchecked, setEmailchecked] = useState(true)
  const [whatsappchecked, setWhatsappchecked] = useState(true)
  const [disablechecked, setDisablechecked] = useState(false)
  const [deleteChecked, setDeleteChecked] = useState(false)

  const handleChangedisable = (e) => {
    setDisablechecked(!disablechecked);
    // setWhatsappchecked(false);
    // setEmailchecked(false)
    setDeleteChecked(false)
  }
  const handleChangewhatsapp = (e) => {
    if(whatsappchecked===true){
      setState({
        ...state,
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
      });
    }
    if(whatsappchecked===false){
      setState({
        ...state,
        one: true,
        two: true,
        three: true,
        four: true,
        five: true,
        six: true,
      });
    }
    setWhatsappchecked(!whatsappchecked)
    setDeleteChecked(false)
    setDisablechecked(false);
  }
  const handleChangeemail = (e) => {
    if(emailchecked===true){
      setState2({
        ...state2,
        one2: false,
        two2: false,
        three2: false,
        four2: false,
        five2: false,
        six2: false,
      });
    }
    if(emailchecked===false){
      setState2({
        ...state2,
        one2: true,
        two2: true,
        three2: true,
        four2: true,
        five2: true,
        six2: true,
      });
    }
    setEmailchecked(!emailchecked)
    setDeleteChecked(false)
    setDisablechecked(false);
  }
  const handleChangeDelete = () =>{ 
    setDeleteChecked(!deleteChecked)
    setDisablechecked(false);
    // setWhatsappchecked(false);
    // setEmailchecked(false)
  }
  useEffect(() => {
    if(one2===false && two2===false && three2===false
      && four2===false && five2===false){
        setEmailchecked(false);
      }
      if(one2===true || two2===true || three2===true
        || four2===true || five2===true || six2===true){
          setEmailchecked(true);
        }
  }, [one2, two2, three2, four2, five2, six2])
  useEffect(() => {
    if(one===false && two===false && three===false
      && four===false && five===false){
        setWhatsappchecked(false);
      }
      if(one===true || two===true || three===true
        || four===true || five===true || six===true){
          setWhatsappchecked(true);
        }
  }, [one, two, three, four, five, six])
  const [data, setData] = React.useState({
    textofArea: "",
  });
  const [error, setError] = useState(false);
  const handleChangeofBox = (e) => {
    const value = e.target.value;
    setError(false)
    setData({
      ...data,
      [e.target.name]: value,
    });
  };
  const [data2, setData2] = React.useState({
    textofArea: "",
  });
  const [error2, setError2] = useState(false);
  const handleChangeofBox2 = (e) => {
    const value = e.target.value;
    setError2(false)
    setData2({
      ...data,
      [e.target.name]: value,
    });
  };
  const handleSubmit = () => {
    console.log('inside')
    console.log(checked, checked2)
    console.log(data.textofArea)
    if(((checked===6||checked==='6') && (data.textofArea===null || data.textofArea==="" || !data.textofArea))){
        setError(true);
        return
    }
    if(((checked2===7 || checked2==='7') && (data2.textofArea===null || data2.textofArea==="" || !data2.textofArea))){
        setError2(true);
        return
    }
    axios.patch(`${BASE}/send/accountSettings/int/${user.id}`, {emailchecked, whatsappchecked, disablechecked, deleteChecked, state, state2, checked, checked2, data, data2}).then((res)=>{
      if(disablechecked){
        toast.success(`Account disabled Successfully`, {
          ...toastStyle.success,
        });
        if (localStorage.getItem("isIAuthenticated"))
        localStorage.removeItem("isIAuthenticated");
        setUser({});
        navigate(HOME_ROUTE);
        setTimeout(() => {
          window.location.reload(true);
        }, 1500);
      }
      else if(deleteChecked){
        toast.success(`Account deleted Successfully`, {
          ...toastStyle.success,
        });
        if (localStorage.getItem("isIAuthenticated"))
        localStorage.removeItem("isIAuthenticated");
        setUser({});
        navigate(HOME_ROUTE);
        setTimeout(() => {
          window.location.reload(true);
        }, 1500);
      }
      else {
          toast.success(`Account Settings updated Successfully`, {
            ...toastStyle.success,
          });
        
      }
      console.log(res)
    }).catch((err)=>{
      console.log(err)
    })
  }
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Cancel Interview</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <NavbarInterviewer/>
      <Container style={{paddingLeft:3}}>
        <TwoColumn  style={{paddingLeft:'20px'}}>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              {/* {description && <Description>{description}</Description>} */}
            </TextContent>
            <p style={{fontSize:'20px'}} className="py-0">Account Status : Active</p>
            <div style={{fontSize:'20px'}} className="pt-2"><span>Deactivate Account? </span><Switch  
              checked={disablechecked}
              disabled={deleteChecked}
              onChange={handleChangedisable}
              color="primary" 
            /></div>
            {disablechecked && <div>
              <FormControl
                component="fieldset"
                variant="standard"
              >
                <FormLabel component="legend">
                  Why do you want to deactivate your account?
                </FormLabel>
                <FormGroup>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={1}
                      label="I will be very busy with something else for sometime"
                      onChange={handleChangeRadio}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={2}
                      label="I am not happy with the present remuneration, and will be back if it is increased "
                      onChange={handleChangeRadio}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={3}
                      label="I am not receiving any/enough mock interview requests"
                      onChange={handleChangeRadio}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={4}
                      label="I am finding it difficult to manage time for mock interviews at present  "
                      onChange={handleChangeRadio}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={5}
                      label="This is temporary, and I will be back"
                      onChange={handleChangeRadio}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={6}
                      label="Other"
                      onChange={handleChangeRadio}
                    />
                    <TextField
                      type="text"
                      name="textofArea"
                      label="Describe your issue(s) in detail"
                      value={data.textofArea}
                      onChange={handleChangeofBox}
                      multiline
                      rows={4}
                      sx={{ mt: 2 }}
                      error={error}
                    />
                  </RadioGroup>
                </FormGroup>
              </FormControl>
            </div>}

            <div style={{fontSize:'20px'}} className="pt-5"><span>Delete Account? </span><Switch  
              checked={deleteChecked}
              disabled={disablechecked}
              onChange={handleChangeDelete}
              color="primary" 
            /></div>
            {deleteChecked && <div>
              <FormControl
                component="fieldset"
                variant="standard"
              >
                <FormLabel component="legend">
                  Why do you want to delete your account?
                </FormLabel>
                <FormGroup>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={1}
                      label="I want to create a new profile using another Gmail address "
                      onChange={handleChangeRadio2}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={2}
                      label="I am not happy with the present remuneration"
                      onChange={handleChangeRadio2}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={3}
                      label="I did not like it taking mock interviews here"
                      onChange={handleChangeRadio2}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={4}
                      label="I am not receiving any/enough mock interview requests"
                      onChange={handleChangeRadio2}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={5}
                      label="The platform is very intrusive and irritating, and it disrupts my routine life"
                      onChange={handleChangeRadio2}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={6}
                      label="I have some other commitments and don’t want to take mock interviews anymore  "
                      onChange={handleChangeRadio2}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={7}
                      label="Other"
                      onChange={handleChangeRadio2}
                    />
                    <TextField
                      type="text"
                      name="textofArea"
                      label="Describe your issue(s) in detail"
                      multiline
                      rows={4}
                      sx={{ mt: 2 }}
                      value={data2.textofArea}
                      onChange={handleChangeofBox2}
                      error={error2}
                    />
                  </RadioGroup>
                </FormGroup>
              </FormControl>
            </div>}

            <div style={{fontSize:'20px'}} className="py-1 pt-5"><span>WhatsApp Notifications? </span><Switch
              checked={whatsappchecked}
              disabled={disablechecked || deleteChecked}
              onChange={handleChangewhatsapp}
              color="primary" 
            /></div>
            {<div>
              <FormControl
                component="fieldset"
                variant="standard"
              >
                {/* <FormLabel component="legend">
                  Which notifications you want to recieve?
                </FormLabel> */}
               
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={one}
                        onChange={handleChangeCheckBox}
                        disabled={disablechecked || deleteChecked}
                        name="one"
                      />
                    }
                    label="Received Requests "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={two}
                        onChange={handleChangeCheckBox}
                        disabled={disablechecked || deleteChecked}
                        name="two"
                      />
                    }
                    label="Confirmation of a Mock Interview"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={three}
                        onChange={handleChangeCheckBox}
                        disabled={disablechecked || deleteChecked}
                        name="three"
                      />
                    }
                    label="Upcoming Interviews "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={four}
                        onChange={handleChangeCheckBox}
                        disabled={disablechecked || deleteChecked}
                        name="four"
                      />
                    }
                    label="Rescheduled Mock Interviews"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={five}
                        onChange={handleChangeCheckBox}
                        disabled={disablechecked || deleteChecked}
                        name="five"
                      />
                    }
                    label="Canceled Mock Interviews"
                  />
                </FormGroup>
              </FormControl>
            </div>}
            <div style={{fontSize:'20px'}} className="py-1 pt-5"><span>Email Notifications? </span><Switch
              disabled={disablechecked || deleteChecked}
              checked={emailchecked}
              onChange={handleChangeemail}
              color="primary" 
            /></div>
            {<div>
              <FormControl
                component="fieldset"
                variant="standard"
              >
                {/* <FormLabel component="legend">
                  Which notifications you want to recieve?
                </FormLabel> */}
               
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={one2}
                        onChange={handleChangeCheckBox2}
                        disabled={disablechecked || deleteChecked}
                        name="one2"
                      />
                    }
                    label="Received Requests "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={two2}
                        onChange={handleChangeCheckBox2}
                        disabled={disablechecked || deleteChecked}
                        name="two2"
                      />
                    }
                    label="Confirmation of a Mock Interview"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={three2}
                        onChange={handleChangeCheckBox2}
                        disabled={disablechecked || deleteChecked}
                        name="three2"
                      />
                    }
                    label="Upcoming Interviews "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={four2}
                        onChange={handleChangeCheckBox2}
                        disabled={disablechecked || deleteChecked}
                        name="four2"
                      />
                    }
                    label="Rescheduled Mock Interviews"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={five2}
                        onChange={handleChangeCheckBox2}
                        disabled={disablechecked || deleteChecked}
                        name="five2"
                      />
                    }
                    label="Canceled Mock Interviews"
                  />
                </FormGroup>
              </FormControl>
            </div>}
            <Button variant="contained" sx={{marginBottom:'20px', marginTop:'20px'}}
                onClick={handleSubmit}
            >Update Settings</Button>
          </TextColumn>
        </TwoColumn>
      </Container>
    </>
  )
}

export default AccountSettings