import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup, CloseButton } from "react-bootstrap";
import { ZoomUserContext, ZoomContext } from "../../Context/globalContext";
import { FiDownload, FiPaperclip } from "react-icons/fi";
import { ChatFileUploadStatus } from "@zoom/videosdk";
import { IoChatbubblesOutline } from "react-icons/io5";
import { FaFileArrowUp, FaRegPaperPlane } from "react-icons/fa6";
import { ImCancelCircle } from "react-icons/im";
import "./Chat.css"
import { MdDeleteForever } from "react-icons/md";
import Close from "@mui/icons-material/Close";
import { useParams } from "react-router";
import toast from "react-hot-toast";
import { toastStyle } from "../../../../utility/helper";
const ChatComponent = ({ showChatBox, setShowChatBox, interviewInfo, unread, setUnread }) => {
  const client = useContext(ZoomContext);
  const currentUser = useContext(ZoomUserContext);
  const { mock_id: ROOM_ID, id: accountId, name: userName } = useParams();
  const chat = client.getChatClient();


  const [messages, setMessages] = useState([]);
  const [chatInput, setChatInput] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [uploadProgress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const handleCloseChatBox = () => setShowChatBox(false);

  const handleChatInput = (e) => setChatInput(e.target.value);
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setIsDisabled(true);
      setSelectedFile(e.target.files[0]);
    }

  };
  const handleRemoveFile = () => {
    setIsDisabled(false);
    setSelectedFile(null);
  };

  const handleSubmitChat = () => {
    if (chatInput) {
      handleText(chatInput);
      setChatInput("");
    }
    if (selectedFile) {
      setIsDisabled(false);
      console.log(selectedFile);
      handleFile(selectedFile);
      setSelectedFile(null);
    }
  };

  const handleText = async (text) => {
    const response = await chat.sendToAll(text);
    console.log("This is the response after sending text chat =>", response);
  };

  const handleFile = async (file) => {
    window.cancelSendFile = await chat.sendFile(file, 0).catch(error => {
      if (error.reason === "File size exceeds the maximum limit.") {
        toast.error("File size exceeded. Only files less than 100 MB can be shared", {
          ...toastStyle.error,
        });
      }
    });
  };

  const downloadFile = async (id, fileUrl, fileName) => {
    try {
      const response = await chat.downloadFile(id, fileUrl);
      console.log("This is the response after receiving file =>", response);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  useEffect(() => {
    const handleMessage = async (payload) => {
      const { id, message, sender, file } = payload;

      // const senderName = sender.name === currentUser.name ? "You" :sender.name;
      const senderName = sender.name === currentUser.name ? "You" : "Other";
      setUnread((prev) => sender.name !== currentUser.name ? prev + 1 : prev)
      setMessages((prevMessages) => {
        const exists = prevMessages.some((msg) => msg.id === id);
        if (!exists) {
          const newMessage = {
            id,
            user: senderName,
            text: message,
            fileUrl: file ? file.fileUrl : null,
            fileName: file ? file.name : null,
            senderName: sender.name,
          };
          return [...prevMessages, newMessage];
        }
        return prevMessages;
      });
    };

    client.on("chat-on-message", handleMessage);

    return () => {
      client.off("chat-on-message", handleMessage);
    };
  }, [client, currentUser.name]);
  useEffect(() => {
    client.on("chat-file-upload-progress", (payload) => {
      const { status, progress, fileName } = payload;
      if (status === ChatFileUploadStatus.InProgress) {
        setIsUploading(true);
        // Todo: display file upload progress
        console.log(`File ${fileName} upload progress:${progress}`);
        setProgress(progress);
      } else if (status === ChatFileUploadStatus.Fail) {
        setIsUploading(false);
        // Due to network issues, the file upload failed. You can retry the file upload using the `retryToken`.
        // When responding to the retry button on the UI, you can use the following code:
        const { retryToken } = payload;
        console.log("File upload failed");
        // chat.sendFile(retryToken, userId);
      } else if (status === ChatFileUploadStatus.Cancel) {
        setIsUploading(false);

        // The user has actively canceled the file upload.
        console.log(`File ${fileName} upload canceled`);
      } else if (status === ChatFileUploadStatus.Success) {
        setIsUploading(false);

        setProgress(100);
        console.log(`File ${fileName} upload successful.`);
      }
    });
    return () => {
      client.off("chat-file-upload-progress");
    };
  }, [client, uploadProgress]);


  // DOM map
  // ChatComponent
  // |--- ZoomChatOuterDiv (div.zoom-chat-outer-div)
  // |   |--- ZoomChatHeader (div.zoom-chat-header)
  // |   |   |--- h6 (Message)
  // |   |   |--- i (Close icon)
  // |--- ZoomMainChatBody (div.zoom-main-chat-body)
  // |   |--- ZoomChatBox (div.zoom-chat-box)
  // |   |   |--- Message (div)
  // |   |   |   |--- Sender's Message (div)
  // |   |   |   |   |--- ZoomChatSenderNameTag (strong.zoom-chat-sender-name-tag)
  // |   |   |   |   |--- ZoomChatMessageContent (div.zoom-chat-message-content)
  // |   |   |   |       |--- Message Text (msg.text)
  // |   |   |   |       |--- Optional File Name (msg.fileName)
  // |   |   |   |       |--- Download File Icon (FiDownload)
  // |   |   |   |--- Other Sender's Message (div)
  // |   |   |       |--- ZoomChatSenderNameTag (strong.zoom-chat-sender-name-tag)
  // |   |   |       |--- ZoomChatMessageContent (div.zoom-chat-message-content)
  // |   |   |           |--- Message Text (msg.text)
  // |   |   |           |--- Optional File Name (msg.fileName)
  // |   |   |           |--- Download File Icon (FiDownload)
  // |--- ZoomChatMessageInput (InputGroup.zoom-chat-message-input)
  // |   |--- Form.Control (Form.Control)
  // |   |   |--- Input Field (type="text")
  // |   |   |   |--- Placeholder (isDisabled ? selectedFile.name : "Type a message")
  // |   |   |   |--- Value (isUploading ? `Upload progress : ${uploadProgress}%` : chatInput)
  // |   |   |   |--- Disabled (isDisabled || isUploading)
  // |   |   |   |--- onChange (handleChatInput)
  // |   |   |   |--- onKeyPress (handleSubmitChat)
  // |   |--- ZoomChatButtonGroup (div.zoom-chat-button-group)
  // |       |--- Remove File Button (Button.variant="danger")
  // |       |   |--- Delete Forever Icon (MdDeleteForever)
  // |       |--- Attach File Button (Button.variant="outline-secondary")
  // |       |   |--- Paperclip Icon (FiPaperclip)
  // |       |--- Send Message Button (Button.variant="primary")
  // |           |--- Paper Plane Icon (FaRegPaperPlane)
  // |--- File Input (Form.Control#fileInput)
  //     |--- File Selector (type="file")
  //     |   |--- onChange (handleFileChange)



  return (
    <>
      {/* <button onClick={handleShowChatBox}><IoChatbubblesOutline /></button> */}

      <div className="zoom-chat-outer-div" style={{ display: showChatBox ? "flex" : "none" }}>
        <div className=" zoom-chat-header" >
          <h6>Message</h6>
          <i onClick={handleCloseChatBox} style={{ cursor: "pointer" }}><Close /></i>
        </div>
        <div className="zoom-main-chat-body">
          <div
            className="zoom-chat-box "
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent:
                    msg.senderName === currentUser.name
                      ? "flex-end"
                      : "flex-start",
                  marginBottom: "5px",
                }}
              >
                <div
                  style={{
                    padding: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    backgroundColor:
                      msg.senderName === currentUser.name
                        ? "#0096ff"
                        : "#ffe6e6",
                    color:
                      msg.senderName === currentUser.name ? "white" : "black",
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "70%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "left",
                      minWidth: "70px",
                    }}
                  >
                    {/* <strong className="zoom-chat-sender-name-tag">{msg.user === "You" ? "" : msg.user}</strong>{" "} */}
                    <strong className="zoom-chat-sender-name-tag">{msg.user === "You" ? "" : ""}</strong>{" "}
                    <div className="zoom-chat-message-content" >
                      <p className="message-text-zoom">{msg.text} {msg.fileName ? msg.fileName : ""}</p>
                      {msg.fileName && (
                        <p
                          className="file-download-zoom-btn"
                        >
                          <FiDownload

                            onClick={() =>
                              downloadFile(msg.id, msg.fileUrl, msg.fileName)
                            }
                          />
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
        <InputGroup className="zoom-chat-message-input">
          <Form.Control
            type="text"
            placeholder={isDisabled ? selectedFile?.name : "Type a message"}
            value={
              isUploading ? `Upload progress : ${uploadProgress}%` : chatInput
            }
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !isDisabled && !isUploading) {
                handleSubmitChat();
                e.preventDefault(); // Prevents the default action of Enter key
              }
            }}
            disabled={isDisabled || isUploading}
            onChange={isDisabled ? null : handleChatInput}
            style={{
              background: isUploading
                ? `linear-gradient(to right, green ${uploadProgress}%, #f4f4f4 ${uploadProgress}%)`
                : isDisabled
                  ? "#f4f4f4"
                  : "",
              transition: 'background 0.3s ease-in-out',
            }}
          />

          <div className="zoom-chat-button-group">
            {selectedFile && (
              <Button style={{ color: "rgb(255, 68, 68)" }} onClick={handleRemoveFile}>
                <ImCancelCircle /></Button>
            )}
            <Button
              style={{ display: isDisabled ? "none" : "block" }}
              onClick={() => document.getElementById("fileInput").click()}
            >
              <FiPaperclip />
            </Button>
            <Button onClick={handleSubmitChat}>
              <FaRegPaperPlane />
            </Button>
          </div>

        </InputGroup>
        <Form.Control
          id="fileInput"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
    </>
  );
};

export default React.memo(ChatComponent);
