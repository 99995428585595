import React, { useContext, useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-date-picker";
import Navbarlogedin from "./Navbarlogedin";
import AutoRejectedRequest from "./cards/AutoRejectedRequest";
import "./SentRequests.css";
import UserContext from "../../contexts/UserContext";
import { BASE } from "../../constants/endpoints";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { getCompanyLogo } from "../../utility/helper";
const AutoRejectedRequests = () => {
  // const [date, setDate] = React.useState(null);
  const { user } = useContext(UserContext);
  const [autorejectedRequest, setautorejectedRequest] = useState([]);
  const [SentInterViewers, setSentInterViewers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const params = {
    reqStatus: "ReqAutoRejected",
    userRole: "Student",
  };
  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, { params })
        .then((response) => {
          console.log(response);
          setautorejectedRequest(response.data.Requests);
          setSentInterViewers(response.data.SentInterViewers);
          console.log(response.data.Requests);
          setLoaded(true);
        });
    }
    fetchData();
  }, []);
  return (
    <>
      <Navbarlogedin />
      <div className="sent-request interviews">
        <div style={{minHeight:'100vh'}} className="interviews-container">
          <div className="flex justify-center items-center my-4" id="head">
            <h2 className="interview-head text-2xl font-medium">
              Auto-Rejected Requests {autorejectedRequest?.length}
            </h2>
          </div>
          <div className="interviews-cont">
            <p className="text-center mx-1 text-red-600">
              Mock Interview Request(s) which were automatically cancelled
              before you paid, because another student paid for the respective
              slot before you
            </p>
            {loaded ? (
              <div className="interviews-list">
                {autorejectedRequest?.map((result, index) => (
                  <>
                    <AutoRejectedRequest
                      id={SentInterViewers[index].id}
                      user={user}
                      name={
                        SentInterViewers[index].fname +
                        " " +
                        SentInterViewers[index].lname
                      }
                      desg={SentInterViewers[index].designation}
                      price={process.env.REACT_APP_STD_45MIN}
                      profile={result.profile}
                      subprofile={result.subprofile}
                      slotStart={autorejectedRequest[index].fromTime}
                      company={SentInterViewers[index]?.currCompany}
                      slotEnd={autorejectedRequest[index].toTime}
                      status={autorejectedRequest[index].reqStatus}
                      date={autorejectedRequest[index].date}
                      image={SentInterViewers[index].photo}
                      logo={getCompanyLogo(SentInterViewers[index].currCompany)}
                      sessDuration={autorejectedRequest[index].sessDuration}
                      sessType={autorejectedRequest[index].sessType}
                    />
                  </>
                ))}
              </div>
            ) : (
              <div className="fullPageLoader">
                <Oval
                  height={120}
                  width={120}
                  color="#306bc9"
                  wrapperStyle={{ padding: "50%, 50%" }}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#90caf9"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoRejectedRequests;
