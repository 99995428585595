import React, { useContext } from "react";
// import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
// import { useLocation, useNavigate } from "react-router-dom";
import ReceivedRequests from "./InterviewerRequest";
import AcceptedRequests from "./InterviewerAccepted";
import CompletedInterviews from "./InterviewerCompleted";
import UpcomingInterviews from "./InterviewerUpcoming";
import InterviewerProfileForm from "./InterviewerProfileForm";
import InterviewerProfileFormOld from "./OldProfileForm";
import InterviewProfile from "./BriefInterviewerProfile";
import InterviewerDashboard from "./InterviewerDashboard";
import Myprofile from "./Myprofile";
import TimePicker from "./TimePreference/TimePicker";
import UserContext from "../../contexts/UserContext";
import ReportIssue from "../Interviewer/ReportIssueInterviewer";
import ICancel from "../Interviewer/ICancel";
import Std2Int from "../Dashboard/Std2Int";
import Welcome from "./Welcome";
import NotAuthenticated from "./NotAuthenticated";
import StudentDashboard from "../Dashboard/BriefStudentsProfile";
import AccountSettings from "./AccountSettings";
import DeactivatedWelcome from "./DeactivateWelcome";
import CanceledInterviews from "./sub-section/CanceledInterviews";
// import { INTERVIEWER_WELCOME_ROUTE } from "../../constants/routes";

const notAuthenticatedContent = {
  "Recieved Requests":
    "Please complete your account first to start receiving the mock interview requests from students",
  "Accepted Requests":
    "Please complete your account first to start receiving and accepting the mock interview requests of students",
  "Upcoming Interviews":
    "Please complete your account first to allow students schedule the mock interviews with you as per your availability",
  "Completed Interviews":
    "Please complete your account first to allow students schedule the mock interviews with you as per your availability",
  default: "Please complete your account first",
};

const InterviewerRoutes = () => {
  // const navigate = useNavigate();
  // const location = useLocation();
  const { user } = useContext(UserContext);

  // // Welcome Router
  // useEffect(() => {
  //   if(user?.designation)
  //     navigate(INTERVIEWER_WELCOME_ROUTE);
  // }, [location, user]);

  return (
    <div>
      <Routes>
        <Route
          exact
          path="recieved-requests"
          element={
            user?.designation ? (
              <ReceivedRequests />
            ) : (
              <NotAuthenticated
                text={notAuthenticatedContent["Recieved Requests"]}
              />
            )
          }
        />
        <Route
          exact
          path="accepted-requests"
          element={
            user?.designation ? (
              <AcceptedRequests />
            ) : (
              <NotAuthenticated
                text={notAuthenticatedContent["Accepted Requests"]}
              />
            )
          }
        />
        <Route
          exact
          path="upcoming-interviews"
          element={
            user?.designation ? (
              <UpcomingInterviews />
            ) : (
              <NotAuthenticated
                text={notAuthenticatedContent["Upcoming Interviews"]}
              />
            )
          }
        />
        <Route
          exact
          path="completed-interviews"
          element={
            user?.designation ? (
              <CompletedInterviews />
            ) : (
              <NotAuthenticated
                text={notAuthenticatedContent["Completed Interviews"]}
              />
            )
          }
        />
        <Route
          exact
          path="canceled-interviews"
          element={
            user?.designation ? (
              <CanceledInterviews />
            ) : (
              <NotAuthenticated
                text={notAuthenticatedContent["Completed Interviews"]}
              />
            )
          }
        />
        <Route
          exact
          path="myprofile"
          element={
            user?.designation ? (
              <Myprofile />
            ) : (
              <NotAuthenticated text={notAuthenticatedContent["default"]} />
            )
          }
        />
        <Route
          exact
          path="dashboard"
          element={
            user?.designation ? (
              <InterviewerDashboard />
            ) : (
              <NotAuthenticated text={notAuthenticatedContent["default"]} />
            )
          }
        />
        <Route
          exact
          path="report"
          element={
            user?.designation ? (
              <ReportIssue />
            ) : (
              <NotAuthenticated text={notAuthenticatedContent["default"]} />
            )
          }
        />
        <Route
          exact
          path="cancel"
          element={
            user?.designation ? (
              <ICancel />
            ) : (
              <NotAuthenticated text={notAuthenticatedContent["default"]} />
            )
          }
        />
        <Route path="deactivated" element={<DeactivatedWelcome />} />
        <Route exact path="profile-form" element={<InterviewerProfileForm />} />
        <Route
          exact
          path="profile-form-old"
          element={<InterviewerProfileFormOld />}
        />
        <Route exact path="timepicker" element={<TimePicker />} />
        <Route exact path="profile/:id" element={<Std2Int />} />
        <Route exact path="welcome" element={<Welcome />} />
        <Route
          exact
          path="account"
          element={
            user?.designation ? (
              <AccountSettings />
            ) : (
              <NotAuthenticated
                text={notAuthenticatedContent["Accepted Requests"]}
              />
            )
          }
        />
        <Route
          exact
          path="student/:id"
          element={
              <StudentDashboard />
          }
        />
        {/* <Route
          exact
          path="interviewer/:id"
          element={
            user?.designation ? (
              <InterviewProfile />
            ) : (
              <NotAuthenticated
                text={notAuthenticatedContent["Recieved Requests"]}
              />
            )
          }
        /> */}
      </Routes>
    </div>
  );
};

export default InterviewerRoutes;
