import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faDeleteLeft,
  faDumpster,
  faTrash,
  faPersonWalkingArrowLoopLeft,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UserContext from "../../../contexts/UserContext";
import axios from "axios";
import { BASE } from "../../../constants/endpoints";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { Button, IconButton, MenuItem, Pagination, Select } from "@mui/material";
import { toast } from "react-hot-toast";
import { Oval } from "react-loader-spinner";

const AwaitedStudents = () => {
  const { user } = useContext(UserContext);
  console.log(user);
  const [awaited, setAwaited] = useState([]);
  const [ans, setAns] = useState([]);
  const [name, setName] = useState(null);

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);
  const [adminNames, setAdminNames] = useState([]);
  const [adminName, setAdmin] = useState("");
  const [allAdmins, setAllAdmins] = useState([]);
  const [del, setDel] = useState(false);
  const [isloaded, setIsloaded] = useState(false)

  useEffect(() => {
    axios
      .get(`${BASE}/send/all-admins/${user.iid}`)
      .then((res) => {
        setAllAdmins(res.data);
        // res.data?.map((item) => {console.log(item.isGenerated)})
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  useEffect(() => {
    if (search && (search !== null || search !== "")) {
      let r = [];
      for (let i = 0; i < awaited.length; i++) {
        // console.log(awaited[i].sName.toLowerCase(), name.toLowerCase());
        if (
          awaited[i].sName.toLowerCase().includes(name.toLowerCase()) &&
          awaited[i].addedBy.toLowerCase().includes(search.toLowerCase())
        ) {
          r.push(awaited[i]);
        }
      }
      // setAns(r.slice((page-1)*100,100));
      setAns(r);
    } else if (name && (name !== null || name !== "")) {
      // console.log(name.toLowerCase());
      let r = [];
      for (let i = 0; i < awaited.length; i++) {
        // console.log(awaited[i].sName.toLowerCase(), name.toLowerCase());
        if (awaited[i].sName.toLowerCase().includes(name.toLowerCase())) {
          r.push(awaited[i]);
        }
      }
      // setAns(r.slice((page-1)*100,100));
      setAns(r);
    } else {
      // setAns(awaited.slice((page-1)*100,100));
      setAns(awaited);
    }
  }, [name]);

  useEffect(() => {
    if (name && (name !== null || name !== "")) {
      let r = [];
      for (let i = 0; i < awaited.length; i++) {
        // console.log(ans[i].addedBy);
        if (
          awaited[i].sName.toLowerCase().includes(name.toLowerCase()) &&
          awaited[i].addedBy.toLowerCase().includes(search.toLowerCase())
        ) {
          r.push(awaited[i]);
        }
      }
      // setAns(r.slice((page-1)*100,100));
      setAns(r);
    } else if (search && (search !== null || search !== "")) {
      // console.log(true);
      let r = [];
      for (let i = 0; i < awaited.length; i++) {
        // console.log(ans[i].addedBy);
        if (awaited[i].addedBy.toLowerCase().includes(search.toLowerCase())) {
          r.push(awaited[i]);
        }
      }
      // setAns(r.slice((page-1)*100,100));
      setAns(r);
    } else {
      // setAns(awaited.slice((page-1)*100,100));
      setAns(awaited);
    }
  }, [search]);

  useEffect(() => {
    async function fetchData() {
      axios.get(`${BASE}/send/admin/awaited/${user.id}`).then((response) => {
        setAwaited(response.data);
        setAns(response.data.slice(0, 100));
        if (response.data.length > 100) {
          setRight(true);
        }
        let x = [];
        console.log(response.data, "--------------------");
        for (let i = 0; i < response.data.length; i++) {
          x.push(response.data[i].addedBy);
        }
        setAdminNames(x.filter((v, i, a) => a.indexOf(v) === i));
        setIsloaded(true);
      });
    }
    fetchData();
  }, []);
  const handleSubmit = async () => {
    axios
      .patch(`${BASE}/send/admin/sendAwaitedRemainder`, { awaited })
      .then((response) => {
        console.log(response);
      });
  };

  const handleChange = (event, value) => {
    window.scrollTo(0, 0);
    if(page-value==1){
      handleLeft()
    }else{
      handleRight()
    }
    setPage(value);
  };

  const handleLeft = () => {
    if (page !== 1) {
      setAns(awaited.slice(page * 100 - 200, (page - 1) * 100));
      setPage(page - 1);
      setRight(true);
    window.scrollTo(0, 0);
      if (page - 1 === 1) {
        setLeft(false);
      }
    } else {
      setLeft(false);
    }
  };

  const handleRight = () => {
    if (page !== Math.ceil(awaited.length / 100)) {
      setAns(awaited.slice(page * 100, page * 100 + 100));
      setPage(page + 1);
      setLeft(true);
    window.scrollTo(0, 0);
      if (page + 1 === Math.ceil(awaited.length / 100)) {
        setRight(false);
      }
    } else {
      setRight(false);
    }
  };

  const getInterviews = (item) => {


    for (let i=0;i< allAdmins.length;i++) {

      if (allAdmins[i].id == item.adminId) {
        return allAdmins[i].interviews;
      }
    }
    return 0;
  };

  const handleShow = async (adminId = "", sEmail = "",sName="") => {
    setShow(!show);
    setDel({ adminId, sEmail ,sName, instituteId: user.iid});
  };

  const handleDelete = async () => {
    setShow(!show);

    if (del.adminId === "") {
      axios
        .post(`${BASE}/send/admin/deleteFiltered`, { ans })
        .then((res) => {
          toast.success("Deleted Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        });
    } else {
      axios
        .post(`${BASE}/send/admin/deleteOneStudent`, { del })
        .then((res) => {
          toast.success("Deleted Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        });
    }
  };

  return (
    <>
    {isloaded ?
    (<div className="">
      {show && (
        <div className="popup w-[90%] max-w-[400px] flex flex-col">
          <p className="text-[20px]">Are you sure you want to remove {del.sName ? del.sName : "students added by "+search}?</p>
          <div
            className="mt-[50px] flex justify-evenly"
            style={{ width: "100%" }}
          >
            <Button variant="contained" color="success" onClick={handleDelete}>
              YES
            </Button>
            <Button variant="contained" color="error" onClick={()=>{handleShow()}}>
              NO
            </Button>
          </div>
        </div>
      )}
      <div className="flex justify-between mb-3" style={{ width: "100%",alignItems: 'stretch', gap: '0.5rem' }}>
        <Button style={{flex:1,fontSize:'13px'}} onClick={handleSubmit} variant="contained">
          Remind Students for Account Creation
        </Button>
        { (user.aFname + " " + user.aLname === search) && (
          <Button style={{flex:1,fontSize:'13px'}} color="error" onClick={()=>{handleShow()}} variant="contained">
            Remove Students Added by {adminNames[adminName - 1]}
          </Button>
        )}
        {/* <Button color="error" onClick={handleShow} variant="contained">
          Delete
        </Button> */}
      </div>
      {/* <div
        style={{ marginBottom: '10px', borderRadius: '10px', display: 'flex', justifyContent: 'space-between', padding: '5px 30px' }}
        className="w-auto text-lg px-2 py-1 border-2 rounded bg-white grid grid-cols-5 s-mobile-admin-searchbar"
        id="searchbar-input"
      >
        <input
          type="text"
          className="mx-1 py-2 outline-none w-auto col-span-4 text-base"
          value={name || ""}
          onChange={(e) => {
            setName(e.target.value);
          }}
          name="search"
          id="search"
          placeholder="Search Interviewer by Name"
        />
        <div
          className="text-2xl w-12 cursor-pointer hover:text-sky-200 col-span-1 flex flex-row justify-between gap-1"
          id="search-bar"
        >
          {name ? (
            <IconButton
              sx={{ p: 0, minWidth: 0 }}
              onClick={() => setName(null)}
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <div className="h-6 w-6"></div>
          )}
          <IconButton sx={{ p: 0, minWidth: 0 }}>
            <SearchIcon />
          </IconButton>
        </div>
      </div> */}
      <div
        className="w-auto text-lg px-2 py-1 bg-white rounded-md grid grid-cols-5 s-mobile-admin-searchbar mb-3"
        id="searchbar-input"
      >
        <input
          type="text"
          className="mx-1 py-2 outline-none w-auto col-span-4 text-base"
          value={name || ""}
          onChange={(e) => {
            setName(e.target.value);
          }}
          name="search"
          id="search"
          placeholder="Search Student by Name"
        />
        <div
          className="text-2xl w-12 cursor-pointer hover:text-sky-200 col-span-1 flex flex-row justify-between gap-1"
          id="search-bar"
        >
          {name ? (
            <IconButton
              sx={{ p: "3px", minWidth: 0, border: "1px solid gray" }}
              onClick={() => setName(null)}
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <div className="h-6 w-6"></div>
          )}
          <IconButton
            sx={{
              p: "3px",
              minWidth: 0,
              border: "1px solid gray",
              marginLeft: "5px",
            }}
          >
            <SearchIcon />
          </IconButton>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{background:'#dbf2fd'}} sx={{ textAlign: "center" }}>
            <TableRow className="table-admin">
              <TableCell sx={{ textAlign: "center" , fontSize:"15px",fontWeight:800 }}>Sr. No.</TableCell>
              <TableCell sx={{ textAlign: "center" , fontSize:"15px",fontWeight:800 }}>Student's Name</TableCell>
              <TableCell sx={{ textAlign: "center" , fontSize:"15px",fontWeight:800 }} align="right">
                Email
              </TableCell>
              <TableCell sx={{ textAlign: "center"  , fontSize:"15px",fontWeight:800}} align="right">
                Whatsapp No.
              </TableCell>
              <TableCell sx={{ textAlign: "center" , fontSize:"15px",fontWeight:800 }} align="right">
                No. of Mock Interviews
              </TableCell>
              <TableCell sx={{ textAlign: "center" , fontSize:"15px",fontWeight:800 }} align="right">
                Coupon Codes
                <p>
                <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip className="tooltip">
                               The coupon codes which <br/> are struck-through have <br/> been already used by <br/> the respective student(s) <br/> to book mock interview(s)
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon
                              className="mr-[10px] rounded-full bg-black text-white p-1 w-[20px] scale-75"
                              icon={faInfo}
                            />
                          </OverlayTrigger>
                          </p>
              </TableCell>
              <TableCell sx={{ textAlign: "center" , fontSize:"15px",fontWeight:800 }} align="right">
                Added By
                <Select
                  style={{minWidth: '80px', maxWidth: '250px'}}
                  labelId="demo-simple-select-label"
                  className="ml-2"
                  id="demo-simple-select"
                  value={adminName}
                  onChange={(e) => {
                    setSearch(adminNames[e.target.value - 1]);
                    setAdmin(e.target.value);
                  }}
                >
                  <MenuItem value={0}>All</MenuItem>
                  {adminNames.map((item, index) => {
                    return (
                      <MenuItem key={index} value={index + 1}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
              <TableCell sx={{ textAlign: "center", fontSize: "15px", fontWeight: 800 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ textAlign: "center" }}>
            {ans &&
              ans !== [] &&
              ans.map((item, index) =>
              item?.sCoupon?.length == 0 && getInterviews(item)==0 ? (
                  <></>
                ) : (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      backgroundColor: `${index % 2 === 1 ? "#f2f2f2" : "#ffffff"}`,
                    }}
                    
                  >
                    <TableCell
                      sx={{ textAlign: "center" }}
                      component="th"
                      scope="row"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} align="right">
                      {item.sName}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", wordBreak: 'break-all', minWidth:'250px' }} align="right">
                      {item.sEmail}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} align="right">
                      {item.sWhatsapp}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} align="right">
                      {item.sInterviews === 0 ? "-" : item.sInterviews}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} align="right">
                      {item?.sCoupon?.length !== 0
                        ? item?.sCoupon?.map((item2, index) => (
                            <span key={index} style={{ textDecoration: `${item.used[index] ? "line-through" : "none"}` }}>
                              {item2.code}
                              {index !== item.sCoupon.length - 1 ? "," : ""}{" "}
                              &nbsp;
                            </span>
                          ))
                        : "-"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} align="right">
                      {item.addedBy}
                    </TableCell>
                    {user.id === item.adminId && (
                      <TableCell sx={{ textAlign: "center" }} align="right">
                        <FontAwesomeIcon
                          color="red"
                          className="cursor-pointer"
                          icon={faTrash}
                          onClick={() => {
                            handleShow(item.adminId, item.sEmail,item.sName);
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-center mt-3">
        <div className="flex bg-white p-[10px] rounded-xl">
          {/* {left && (
            <FontAwesomeIcon
              onClick={handleLeft}
              className="cursor-pointer"
              icon={faChevronLeft}
            />
          )}
          <p className="mx-[10px]">{page}</p>
          {right && (
            <FontAwesomeIcon
              onClick={handleRight}
              className="cursor-pointer"
              icon={faChevronRight}
            />
          )} */}
          <Pagination
            count={Math.ceil(awaited?.length / 100)}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </div>
      </div>
    </div>) : 
    (
      (
        <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )
    )
    }
    </>
  );
};

export default AwaitedStudents;