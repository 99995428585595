import React, { useContext, useEffect, useRef, useState } from "react";
import CompletedInterview from "./cards/CompletedInterview";
import Card from "./cards/Card";
import "./InterviewerCompleted.css";
// import TextField from "@mui/material/TextField";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DatePicker from "react-date-picker";
// import Snackbar from "@mui/material/Snackbar";
// import Slide from "@mui/material/Slide";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../constants/variables";
import { Oval, ThreeDots } from "react-loader-spinner";
import UserContext from "../../contexts/UserContext";
import axios from "axios";
import { BASE } from "../../constants/endpoints";
import {
  compareReverse,
  compareSlots,
  getCountForUpcoming,
  t24HDecimalTo12HFormat,
} from "../../utility/helper";
import moment from "moment-timezone";
import Pagination from "@mui/material/Pagination";

const InterviewerCompleted = () => {
  const { user } = useContext(UserContext);
  const [completedRequest, setrecievedRequest] = useState([]);
  const [SentStudents, setSentStudents] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const params = {
    reqStatus: "ReqPaid",
    userRole: "Interviewer",
    completed: "Completed"
  };
  useEffect(() => {
    async function fetchData() {
      axios
        .get(`${BASE}/send/getInterviewRequestById/${user.id}`, { params })
        .then((response) => {
          console.log(response)
          setrecievedRequest(response.data.Requests);
          let temp_s = response.data.Requests
            .filter((result, index) => moment().tz("Asia/Kolkata").diff(moment(result.date + " " + t24HDecimalTo12HFormat(result.toTime), "DD/MM/YYYY hh:mm a"), "seconds") > 0)
          setrecievedRequest(temp_s);
          setSentStudents(response.data.SentInterViewers)
          console.log(response.data.Requests)
          setLoaded(true);

          let ints = response.data.Requests;

          ints?.map((int) => {
            axios.get(`${BASE}/send/interview/completedInterviewRequestById/${int.reqId}`)
              .then((response) => {
                console.log(response.data);
              }).catch((err) => {
                console.log(err);
              })
          })
        })
    }
    fetchData();
  }, []);
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, []);
  // function TransitionRight(props) {
  //   setDate(null);
  //   return <Slide {...props} direction="right" />;
  // }
  const [cards, setCards] = React.useState([]);
  const [date, setDate] = React.useState(null);
  // const [open, setOpen] = React.useState(false);
  // const [transition, setTransition] = React.useState(undefined);
  // const handleClick = (Transition) => () => {
  //   setTransition(() => Transition);
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  const count = completedRequest?.length - getCountForUpcoming(completedRequest);

  // Pagination
  const [page, setPage] = useState(1); // No of Pages
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [pageNo, setPageNo] = useState([0, 10]);
  useEffect(() => {
    // console.log([page * 10 - 1, page * 10]);
    setPageNo([(page - 1) * 10, page * 10]);
    window.scrollTo(0, 0);
  }, [page, completedRequest]);
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Completed Interviews</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <div className="interviews min-h-screen">
        <div className="flex justify-center items-center mb-4" id="head">
          <h2 className="interview-head text-2xl font-medium">
            Completed Interviews (
            {/* {completedRequest
              ? page * 10 -
                9 +
                " - " +
                (count > page * 10
                  ? page * 10
                  : count)
              : null}
            ) out of ({completedRequest ? count : 0}) */}

            {completedRequest.length
              ? page * 10 -
              9 +
              " - " +
              (completedRequest.length > page * 10
                ? page * 10
                : completedRequest.length)
              : null}
            ) out of ({completedRequest ? completedRequest?.length : 0})

          </h2>
        </div>
        <div className="flex justify-between mx-8 s-mobile-flex-dir-col">
          <div>
            Showing the results for {date ? date.toDateString() : "all days"}
          </div>
          {/*
          <div className="flex gap-2">
            <label htmlFor="date">Filter by Date: </label>
            <DatePicker
              onChange={(newValue) => {
                setDate(newValue);
              }}
              value={date}
            />
          </div>
          */}

        </div>
        {loaded ? (<div className="mx-auto max-w-[900px]">
          {/* <CompletedInterview
            name="John Doe"
            desg="B.Tech, CSE, IIT Delhi"
            slot="05:30 PM - 06:00 PM"
            id="{result._id}"
            status="accepted"
            payment={true}
          /> */}
          {completedRequest?.length !== 0 ? (
            <>
              {completedRequest
                ?.slice(pageNo[0], pageNo[1])
                .map(
                  (result, index) =>
                    (moment().tz("Asia/Kolkata").diff(moment(result.date + " " + t24HDecimalTo12HFormat(result.toTime), 'DD/MM/YYYY hh:mm a'), 'seconds')) > 0 && (
                      <CompletedInterview
                        user={user}
                        name={
                          SentStudents[pageNo[0] + index].fname +
                          " " +
                          SentStudents[pageNo[0] + index].lname
                        }
                        branch={SentStudents[pageNo[0] + index].branch}
                        college={SentStudents[pageNo[0] + index].institute}
                        program={SentStudents[pageNo[0] + index].program}
                        year={SentStudents[pageNo[0] + index].year}
                        price="400"
                        profile={result.profile}
                        subprofile={result.subprofile}
                        slotStart={completedRequest[pageNo[0] + index].fromTime}
                        slotEnd={completedRequest[pageNo[0] + index].toTime}
                        status={completedRequest[pageNo[0] + index].reqStatus}
                        id={completedRequest[pageNo[0] + index].studId}
                        iId={completedRequest[pageNo[0] + index].interviewerId}
                        date={completedRequest[pageNo[0] + index].date}
                        mockId={completedRequest[pageNo[0] + index].mockId}
                        payment={result.payment}
                        resume={SentStudents[pageNo[0] + index].resume}
                        image={SentStudents[pageNo[0] + index].isDisabled === true || SentStudents.isDelete === true ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' : SentStudents[pageNo[0] + index].photo}
                        isUnavailable={SentStudents[pageNo[0] + index].isDisabled === true || SentStudents[pageNo[0] + index].isDelete === true}
                        isDisable={SentStudents[pageNo[0] + index].isDisabled === true}
                        isDelete={SentStudents[pageNo[0] + index].isDelete === true}
                        sessDuration={completedRequest[pageNo[0] + index].sessDuration}
                        sessType={completedRequest[pageNo[0] + index].sessType}
                      />
                    )
                )}
              {/* Pagination */}
              <div className="flex flex-row justify-center mt-4 my-2">
                <Pagination
                  count={Math.ceil((count) / 10)}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </div>
            </>
          ) : <></>
            /*
            (
              <div className="mt-52 flex flex-col justify-center items-center p-2 text-xl text-blue-600 text-center font-medium">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#265bd1"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={false}
                />
                <p>No interviews yet</p>
              </div>
            )
            */
          }
        </div>) : <div className="fullPageLoader">
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>}
      </div>
    </>
  );
};

export default InterviewerCompleted;
