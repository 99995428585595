import React from "react";

const Navbar = () => {
  return (
    <>
      <div>
        <h1 className="text-2xl font-medium">interwiu.com</h1>
      </div>
      <ul>
        <li>Received Request</li>
        <li>Accepted Request</li>
        <li>Upcoming Interview</li>
        <li>Completed Interview</li>
      </ul>
    </>
  );
};

export default Navbar;
