import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import UserContext from '../../../contexts/UserContext';
import './Sessions.css';

function Sessions() {
    const { user } = useContext(UserContext);
    const [sessions, setSessions] = useState({});

    const getBookings = async (caId) => {
        const body = { caId: caId };
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/programs/ca/get-sessions`, body);
            setSessions(response.data);
        } catch (error) {
            console.log("Error in getting bookings ==>", error);
        }
    };

    useEffect(() => {
        if (user.caId) {
            getBookings(user.caId);
        }
    }, [user]);

    // Convert the sessions object to an array and sort it by session count
    const sortedSessions = Object.entries(sessions).sort((a, b) => b[1] - a[1]);

    return (
        <div className="bookings-container">
            {sortedSessions.length > 0 ? (
                <>
                    <h2 className="bookings-header">Total Session Bookings: {sortedSessions.length}</h2>
                    <p className="share-message">
                        <strong>{user.caId}</strong> is your coupon code. Use this code to get a flat 10% discount on a session. Share it with your friends and ask them to use this coupon code when booking a session.
                    </p>
                    <div className="names-grid">
                        <div className="names-row names-header">
                            <div className="name-item">Name</div>
                            <div className="name-item">Number of Sessions</div>
                        </div>
                        {sortedSessions.map(([name, sessionCount], index) => (
                            <div key={index} className="names-row">
                                <div className="name-item">{name}</div>
                                <div className="name-item">{sessionCount}</div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <p className="no-bookings-message">
                    No one has booked a session at <span className="highlight">interwiu.com</span> using your referral code <strong className="code">{user.caId}</strong> so far. Share it with your friends and ask them to book a session ASAP!
                </p>
            )}
        </div>
    );
}

export default Sessions;
