import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TabOne from "./StudentsHIW";

const Working = () => {
  return (
    <Box
      sx={{
        typography: "body1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          mt: 4,
        }}
      >
        How it works
      </Typography>
      <Box sx={{ width: "100%" }}>
        <TabOne />
      </Box>
    </Box>
  );
};
export default Working;
