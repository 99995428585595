import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import RateAndReview from "../RateAndReview";
import { GET_REQUEST_INTERVIEWS, GET_STUDENT_INFO, GET_INETRVIEWER_INFO } from "../../../../constants/endpoints";
import "./StudentMidEndZoom.css";
import TextLoading from "../CallEndPages/TextLoading";

const StudentMidEndZoom = () => {
  const { mock_id: ROOM_ID, message: message } = useParams();
  const [studentDetails, setStudentDetails] = useState();

  const location = useLocation();
  const customMessage =
    {
      idle_time_exceeded: "Due to inactivity, the session has timed out. Please wait for a few moments before attempting to re-join.",
    }[message] || "";

  useEffect(() => {
    // getting data about the interview and make link
    axios
      .get(`${GET_REQUEST_INTERVIEWS + "/" + ROOM_ID}`)
      .then((res) => {
        let student_id = res.data.studId;

        axios
          .get(`${GET_STUDENT_INFO}/${student_id}`)
          .then((res) => {
            setStudentDetails(res.data);
          })
          .catch((err) => {
            alert("Invalid Interviewer ID");
            console.log(err);
          });
      })
      .catch((err) => {
        alert("Invalid Link");
      });
  }, []);

  return (
    <div className="studentend">
      {customMessage !== undefined && customMessage !== null && customMessage !== "" ? (
        <>
          <p style={{ margin: "20px 0" }}>{customMessage}</p>
          <p style={{ margin: "40px 0" }}>
            If this mock interview session is yet to be completed, you can&nbsp;
            {studentDetails?.id ? (
              <Link to={"/valid/vc/" + ROOM_ID + "/" + studentDetails?.id + "/" + studentDetails?.fname} style={{ color: "blue", textDecoration: "underline" }}>
                Re-Join
              </Link>
            ) : (
              <TextLoading text="Re-Join" />
            )}
            &nbsp;it
          </p>
        </>
      ) : (
        <>
          <p style={{ margin: "20px 0" }}>It seems like there is some interruption</p>
          <p style={{ margin: "40px 0" }}>
            If this mock interview session is yet to be completed, you can&nbsp;
            {/* <Link to={"/s/upcoming-interviews"} style={{color:"blue",textDecoration:"underline"}} > Re-Join </Link> */}
            {studentDetails?.id ? (
              <Link
                to={"/valid/vc/" + ROOM_ID + "/" + studentDetails?.id + "/" + studentDetails?.fname}
                // to={
                //   "/valid/vc/" +
                //   ROOM_ID +
                //   "?id=" +
                //   studentDetails?.id +
                //   "&name=" +
                //   studentDetails?.fname
                // }
                style={{ color: "blue", textDecoration: "underline" }}
                target="_blank"
                onClick={() => {
                  window.close();
                }}
              >
                Re-Join
              </Link>
            ) : (
              <TextLoading text="Re-Join" />
            )}
            &nbsp;it
          </p>
        </>
      )}
    </div>
  );
};

export default StudentMidEndZoom;
