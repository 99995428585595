// import io from "socket.io-client";
import { BASE, GET_INTERVIEW_DETAILS } from "../../../../constants/endpoints";
// import Peer from "peerjs";
import $ from "jquery";
import axios from "axios";
// import {
//   GET_REQUEST_INTERVIEWS,
//   GET_STUDENT_INFO,
//   GET_INETRVIEWER_INFO,
// } from "../../constants/endpoints";
import { AiOutlineConsoleSql } from "react-icons/ai";


// let socket = io(BASE + "/", {
//   closeOnBeforeunload: false, // to make socket unload after pages get unloaded and required functions on beforeUnload eventlistener is implemented
//   path: "/socket",
//   transports: ["websocket", "polling"],
//   // pingTimeout: 200*60*1000,
// });





// const peer = new Peer({
//   host: 'interwiu.com',
//   port: 443,
//   path: '/peerjs',
//   proxied: true,
//   secure: true,
//   debug: 3,
//   // pingInterval: 1000,
//   config: {
//     // An ICE server is a STUN or TURN server considered by a WebRTC RTCPeerConnection for self discovery, NAT traversal, and/or relay.
//     iceServers: [
//       { url: "stun:interwiu.com" },
//       { url: "stun:stun01.sipphone.com" },
//       { url: "stun:stun.ekiga.net" },
//       { url: "stun:stunserver.org" },
//       { url: "stun:stun.softjoys.com" },
//       { url: "stun:stun.voiparound.com" },
//       { url: "stun:stun.voipbuster.com" },
//       { url: "stun:stun.voipstunt.com" },
//       { url: "stun:stun.voxgratia.org" },
//       { url: "stun:stun.xten.com" },
//       {
//         url: "turn:interwiu.com:443?transport=tcp",
//         credential: "test123",
//         username: "test"
//       },
//       {
//         url: "turn:interwiu.com:3478?transport=udp",
//         credential: "test123",
//         username: "test"
//       },
//       {
//         url: "turn:192.158.29.39:3478?transport=udp",
//         credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
//         username: "28224511:1379330808",
//       },
//       {
//         url: "turn:192.158.29.39:3478?transport=tcp",
//         credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
//         username: "28224511:1379330808",
//       },
//       {
//         url: 'turn:turn.anyfirewall.com:443?transport=tcp',
//         credential: 'webrtc',
//         username: 'webrtc'
//       }
//     ],
//   },
// });


// // TO HOST PEERJS LOCALLY
// const peer = new Peer({
//   host: 'localhost',
//   port: 9000,
//   path: '/',
//   config: {
//       // An ICE server is a STUN or TURN server considered by a WebRTC RTCPeerConnection for self discovery, NAT traversal, and/or relay.
//     iceServers: [
//       { url: "stun:stun01.sipphone.com" },
//       { url: "stun:stun.ekiga.net" },
//       { url: "stun:stunserver.org" },
//       { url: "stun:stun.softjoys.com" },
//       { url: "stun:stun.voiparound.com" },
//       { url: "stun:stun.voipbuster.com" },
//       { url: "stun:stun.voipstunt.com" },
//       { url: "stun:stun.voxgratia.org" },
//       { url: "stun:stun.xten.com" },
//       {
//         url: "turn:192.158.29.39:3478?transport=udp",
//         credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
//         username: "28224511:1379330808",
//       },
//       {
//         url: "turn:192.158.29.39:3478?transport=tcp",
//         credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
//         username: "28224511:1379330808",
//       },
//       {
//         url: 'turn:turn.anyfirewall.com:443?transport=tcp',
//         credential: 'webrtc',
//         username: 'webrtc'
//     }
//     ],
//   },
// });



// creating peer object to make connections and make peerId and use peer functions
// let peer = new Peer({
//   // host: "127.0.0.1",
//   // port: 9000,
//   // path: "/peerjs",
//   host: "0.peerjs.com",
//   port: 443,
//   secure: false,
//   pingInterval: 1000,

//   config: {
//     // An ICE server is a STUN or TURN server considered by a WebRTC RTCPeerConnection for self discovery, NAT traversal, and/or relay.
//     iceServers: [
//       { url: "stun:stun01.sipphone.com" },
//       { url: "stun:stun.ekiga.net" },
//       { url: "stun:stunserver.org" },
//       { url: "stun:stun.softjoys.com" },
//       { url: "stun:stun.voiparound.com" },
//       { url: "stun:stun.voipbuster.com" },
//       { url: "stun:stun.voipstunt.com" },
//       { url: "stun:stun.voxgratia.org" },
//       { url: "stun:stun.xten.com" },
//       {
//         url: "turn:192.158.29.39:3478?transport=udp",
//         credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
//         username: "28224511:1379330808",
//       },
//       {
//         url: "turn:192.158.29.39:3478?transport=tcp",
//         credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
//         username: "28224511:1379330808",
//       },
//     ],
//   },
//   debug: 3,
// });



const convertTimeToDecimal = (date) => {
  // Ensure the input is a Date object
  if (!(date instanceof Date)) {
    throw new Error("Input must be a Date object");
  }

  // Extract hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Convert time to decimal format
  const decimalTime = hours + minutes / 60;

  return decimalTime;
}


// function to given time in 12hrs format
const giveTime = (time) => {
  let hour =
    Math.trunc(time) < 10
      ? "0" + JSON.stringify(Math.trunc(time))
      : JSON.stringify(Math.trunc(time));
  let minute =
    (time - Math.trunc(time)) * 60 < 10
      ? "0" + JSON.stringify((time - Math.trunc(time)) * 60)
      : JSON.stringify((time - Math.trunc(time)) * 60);

  if (hour >= 12) {
    if (hour >= 13) {
      return hour - 12 + ":" + minute + " PM";
    } else {
      return hour + ":" + minute + " PM";
    }
  }
  if (hour === 0) {
    hour = 12;
  }
  return hour + ":" + minute + " AM";
  // return 1;
};

// function to give years of student
const giveYears = (y) => {
  console.log(y, "----------------");
  if (y == "1") {
    return y + "st";
  } else if (y == "2") {
    return y + "nd";
  } else if (y == "3") {
    return y + "rd";
  } else {
    return y + "th";
  }
};

const testImage = "/tempImage.png";



const getImages = async (mockId) => {
  let res = await axios.get(`${GET_INTERVIEW_DETAILS}/${mockId}?page=vc`)
  // interviewer_details = res.data.interviewer
  // student_details = res.data.student
  // interviewer_details = { image: testImage };
  // student_details = { image: testImage };
  // console.log(err)
  console.log("get images")
  return { interviewer_details: res.data.interviewer, student_details: res.data.student }

  //  let interview = await axios.get(`${GET_REQUEST_INTERVIEWS + "/" + mockId}`)
  //     .then((res) => {
  //       let student_id = res.data.studId;
  //       let interviewer_id = res.data.interviewerId;

  //       // getting student photo
  //       axios
  //         .get(`${GET_STUDENT_INFO}/${student_id}`)
  //         .then((res) => {
  //           student_details.id = res.data.id;
  //           student_details.fname = res.data.fname;
  //           student_details.image = res.data.photo;

  //                 // getting interviewer photo
  //       axios
  //       .get(`${GET_INETRVIEWER_INFO}/${interviewer_id}`)
  //       .then((res) => {
  //         interviewer_details.id = res.data.id;
  //         interviewer_details.fname = res.data.fname;
  //         interviewer_details.image = res.data.photo;
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // return {interviewer_details, student_details}
};

const getProgram = (program) => {
  console.log(program, "-----------------");
  switch (program) {
    case "dual":
      return "Dual Degree (B.Tech + M.Tech)";
    case "btech":
      return "B.Tech";
    case "mtech":
      return "M.Tech";
    case "dualbsms":
      return "Dual Degree (B.S. + M.S.)";
    case "phd":
      return "PhD";
    case "ma":
      return "MA";
    case "ima":
      return "Intergrated MA";
    case "msc":
      return "M.Sc.";
    case "mba":
      return "MBA";
    case "ms":
      return "MS (Research)";
    case "mca":
      return "MCA";
  }
};


// function to put name if video is off
// const putName = async (
//   accountId,
//   mockId,
//   peerId,
//   interviewDetails,
//   studentDetails,
//   interviewerDetails,
//   idOfUser,
//   user,
//   vid
// ) => {
//   // console.log("putname", new Date())
//   //name pinning and unpinning
//   $(".text__name").remove();
//   let videoArray = $("video");
//   console.log("putname")
//   // console.log("before",interviewer_details, student_details, interviewerDetails, studentDetails, interviewDetails)
//   // setTimeout(() => {
//   const {interviewer_details, student_details} =  await getImages(mockId);
//   // },2000)
//   console.log(interviewer_details, student_details)
//   for (let i = 0; i < videoArray.length; i++) {

//     // if the video is a screen-share video
//     if (videoArray[i].getAttribute("name")?.split("/")[1] === "screen-share") {

//       // if the video div has class unpinned i.e. sidebar
//       if ($(videoArray[i]).parent().hasClass("unpinned__video")) {  
//         if (
//           videoArray[i].getAttribute("name")?.split("/")[0] === idOfUser ||
//           videoArray[i].getAttribute("name")?.split("/")[0] === peerId
//         ) {
//           $(
//             `<p class='text__name text__unpin text_screen_share' style='color:white' name='${videoArray[
//               i
//             ].getAttribute("name")}' >${user?.slice(0,6)+"..."} Screen</p>`
//           ).insertBefore(videoArray[i]);
//         }
//         else {
//           $(
//             `<p class='text__name text__unpin text_screen_share' style='color:white' name='${videoArray[
//               i
//             ].getAttribute("name")}' >${
//               // interviewDetails?.student || studentDetails
//                 student_details.id === accountId
//                 ? student_details.fname?.slice(0,6)+"..." || studentDetails?.fname?.slice(0,6)+"..."
//                 : interviewer_details.fname?.slice(0,6)+"..." ||
//                   interviewerDetails?.fname?.slice(0,6)+"..."
//             } Screen</p>`
//           ).insertBefore(videoArray[i]);
//         }
//       } else if (videoArray[i].classList.contains("pin__video")) {
//         $(videoArray[i]).prev().remove();
//       }
//       continue;
//     }

//     // if the video div is of user
//     if (
//       (videoArray[i].getAttribute("name") == peerId ||
//         videoArray[i].getAttribute("name") == idOfUser) &&
//       !videoArray[i].captureStream().getVideoTracks()[0].enabled
//     ) {
//       if (videoArray[i].classList.contains("pin__video")) {
//         $(
//           `<p class='text__name text__pin' style='color:white' name='${videoArray[
//             i
//           ].getAttribute("name")}' >${user}</p>`
//         ).insertBefore(videoArray[i]);
//       } else if ($(videoArray[i]).parent().hasClass("unpinned__video")) {
//         $(
//           // `<p class='text__name text__unpin' style='color:white' name='${videoArray[i].getAttribute("name")}' >${user}</p>`
//           `<image class="text__name text__unpin" style='color:white' src='${
//             student_details.id === accountId
//               ? student_details.image
//               : interviewer_details.image
//           }' name='${videoArray[i].getAttribute("name")}' />`
//         ).insertBefore(videoArray[i]);
//       } else {
//         $(
//           `<p class='text__name text__centerpin' style='color:white' name='${videoArray[
//             i
//           ].getAttribute("name")}' >${user}</p>`
//         ).insertBefore(videoArray[i]);
//       }
//     } 
//     // if the video div is of the other user
//     else if (
//       videoArray[i].captureStream().getVideoTracks()[0].enabled &&
//       videoArray[i].getAttribute("name") != peerId &&
//       videoArray[i].getAttribute("name") != idOfUser &&
//       !vid
//     ) {
//       if (videoArray[i].classList.contains("pin__video")) {
//         $(
//           `<p class='text__name text__pin' style='color:white' name='${videoArray[
//             i
//           ].getAttribute("name")}' >${
//             // interviewDetails?.student || studentDetails
//             student_details.id === accountId  
//             ? student_details.fname || studentDetails?.fname
//               : interviewer_details.fname ||
//                 interviewerDetails?.fname
//           }</p>`
//         ).insertBefore(videoArray[i]);
//       } else if ($(videoArray[i]).parent().hasClass("unpinned__video")) {
//         $(
//           // `<p class='text__name text__unpin' style='color:white' name='${videoArray[i].getAttribute("name")}' >${
//           //   interviewDetails?.student || studentDetails
//           //     ? interviewDetails?.student?.fname || studentDetails?.fname
//           //     : interviewDetails?.interviewer?.fname || interviewerDetails?.fname
//           // }</p>`
//           `<image class="text__name text__unpin" style='color:white' src='${
//             student_details.id === accountId
//               ? student_details.image
//               : interviewer_details.image
//           }' name='${videoArray[i].getAttribute("name")}' />`
//         ).insertBefore(videoArray[i]);
//       } else {
//         $(
//           `<p class='text__name text__centerpin' style='color:white' name='${videoArray[
//             i
//           ].getAttribute("name")}' >${
//             student_details.id === accountId
//             // interviewDetails?.student || studentDetails
//               ? student_details.fname || studentDetails?.fname
//               : interviewer_details.fname ||
//                 interviewerDetails?.fname
//           }</p>`
//         ).insertBefore(videoArray[i]);
//       }
//     }
//   }
// };

export { giveTime, giveYears, getProgram, getImages, convertTimeToDecimal };
