import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";

// import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
// import NavbarInterviewer from "./NavbarInterviewer.js";
import { TextField, Button, Grid } from "@mui/material";
// import EmailIllustrationSrc from "../../images/email-illustration.svg";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import ErrorIcon from "@mui/icons-material/Error";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import "./CompletedInterviews.css";
import { COMPANY_NAME } from "../../constants/variables.js";
import axios from "axios";
import { BASE } from "../../constants/endpoints.js";
import UpcomingInterview from "./cards/UpcomingInterview2.js";
import {
  compareTimeForReport,
  getCompanyLogo,
  toastStyle,
} from "../../utility/helper.js";
import { toast } from "react-hot-toast";
import Navbarlogedin from "./Navbarlogedin.js";

const Container = tw.div`relative bg-white`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Issue = () => {
  const [state, setState] = React.useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
  });
  const { one, two, three, four, five } = state;
  const subheading = "",
    heading = (
      <>
        <span tw="">Report an Issue</span>
        <wbr />
      </>
    ),
    description =
      "Please Enter the Mock Interview ID and then proceed to report your issue",
    textOnLeft = true;
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [inputs, setInputs] = React.useState({
    idVerify: "",
  });
  const [cards, setCards] = React.useState(null);
  const [interviewer, setInterViewer] = React.useState(null);
  const [verified, setVerified] = useState(false);
  const [status, setStatus] = useState();
  const [error, setError] = useState(false);
  const [mockDetails, setMockDetails] = useState({});
  const handleChange = (event) => {
    setChangingInput(true);
    const name = event.target.name;
    const value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };
  const [changingInput, setChangingInput] = useState(false);

  async function fetchData() {
    setChangingInput(false);
    setVerified(false);
    console.log(inputs.idVerify);
    if (inputs.idVerify === "") {
      window.alert("Please enter a valid Mock Interview ID");
      return;
    }
    await axios
      .get(`${BASE}/send/getRequest/${inputs.idVerify}`)
      .then((response) => {
        console.log(response.data);
        setCards(response.data);
        setVerified(true);
        axios
          .get(`${BASE}/int/interviewer/${response.data.interviewerId}`)
          .then((res) => {
            console.log(res.data);
            setInterViewer(res.data);
          });
      })
      .catch((error) => {
        window.alert("Please enter a valid Mock Interview ID");
        setStatus("invalid");
        setVerified(false);
        console.error(error);
      });
    await axios
      .get(`${BASE}/send/getMockInterview/${inputs.idVerify}`)
      .then((res) => {
        setMockDetails(res.data);
      });
  }
  useEffect(() => {
    const result = compareTimeForReport(
      cards?.fromTime,
      cards?.toTime,
      cards?.date
    );
    setStatus(result);
    console.log(status);
    console.log(cards);
  }, [cards]);

  const handleChangeCheckBox = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const [data, setData] = React.useState({
    textofArea: "",
  });
  const handleChangeofBox = (e) => {
    const value = e.target.value;
    setError(false);
    setData({
      ...data,
      [e.target.name]: value,
    });
  };
  const handleSubmit = () => {
    if (
      five === true &&
      (data.textofArea === null || data.textofArea === "" || !data.textofArea)
    ) {
      setError(true);
      return;
    }
    console.log(one, two, three, four, five);
    console.log(data.textofArea);
    axios
      .patch(`${BASE}/send/updateMockInterview/${inputs.idVerify}`, {
        state,
        data,
        role: "Stud",
      })
      .then((res) => {
        console.log(res);
        toast.success("Report Sent Successfully", {
          ...toastStyle.success,
          position: "top-center",
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  console.log("->>>>>>>>>>", interviewer);
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Report an Issue</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      <Container>
        <TwoColumn style={{ padding: "10px" }}>
          <TextColumn
            textOnLeft={textOnLeft}
            style={{
              maxWidth: "1000px",
              marginLeft: "auto",
              marginRight: "auto",
              minHeight: "87vh",
              marginTop: "2vw",
            }}
          >
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading
                style={{
                  textAlign: "left",
                  textShadow: "1px 1px 3px #b7b7b7",
                  color: "black",
                }}
              >
                {heading}
              </Heading>
              {description && (
                <Description style={{ textAlign: "left" }}>
                  {description}
                </Description>
              )}
            </TextContent>
            {/* <Container>
        <TwoColumn style={{paddingLeft:'20px'}}>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              {description && <Description>{description}</Description>}
            </TextContent> */}
            {/* <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  id="outlined-basic"
                  label="Enter the Mock Interview ID"
                  variant="outlined"
                  type="text"
                  name="idVerify"
                  className="border-2 rounded"
                  value={inputs.idVerify}
                  onChange={handleChange}
                  placeholder="Mock Id"
                  sx={{ width: 1 }}
                />
                <Button onClick={fetchData}>Verify</Button>
              </Grid>
            </Grid> */}
            <div style={{ marginTop: "20px" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <TextField
                  id="outlined-basic"
                  label="Enter the Mock Interview ID"
                  variant="outlined"
                  type="text"
                  name="idVerify"
                  className="border-2 rounded"
                  value={inputs.idVerify}
                  onChange={handleChange}
                  placeholder="Mock Id"
                />
                <Button
                  onClick={fetchData}
                  variant={
                    !changingInput && verified ? "outlined" : "contained"
                  }
                  color={!changingInput && verified ? "success" : "primary"}
                >
                  {!changingInput && verified ? "Verified" : "Verify"}
                </Button>
              </div>
            </div>
            {verified && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <h5>Details of the Mock Interview </h5>
                  {cards && (
                    <UpcomingInterview
                      name={interviewer?.fname}
                      desg={interviewer?.designation}
                      company={interviewer?.currCompany}
                      price={cards?.price}
                      profile={cards?.profile}
                      subprofile={cards?.subprofile}
                      slotStart={cards?.fromTime}
                      slotEnd={cards?.toTime}
                      status={cards?.reqStatus}
                      id={interviewer?.id}
                      date={cards?.date}
                      image={interviewer?.photo}
                      mockId={cards?.mockId}
                      logo={getCompanyLogo(interviewer?.currCompany)}
                      req_id={cards?.reqId}
                      payment={cards?.payment}
                      // rescheduled='true'
                      isCancelled={cards?.cancelled?.isCancelled}
                      isUnavailable={
                        cards?.isDisabled === true || cards?.isDelete === true
                      }
                      isDisable={cards?.isDisabled === true}
                      isDelete={cards?.isDelete === true}
                      reschedule={cards?.reschedule}
                      isRescheduled={cards?.reschedule?.isRescheduled}
                      sessDuration={cards?.sessDuration}
                      sessType={cards?.sessType}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            {verified && mockDetails?.StudentIssue?.issueReported === true && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorIcon
                  color="error"
                  style={{ marginRight: "1.5%" }}
                  fontSize="large"
                />
                <p
                  className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                  style={{ color: "#d32f2f" }}
                >
                  Sorry! You've already reported an issue for this Mock
                  Interview
                </p>
              </div>
            )}
            {verified && status === "upcoming" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ErrorIcon
                  color="error"
                  style={{ marginRight: "1.5%" }}
                  fontSize="large"
                />
                <p
                  className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                  style={{ color: "#d32f2f" }}
                >
                  Sorry! You can report an issue about this Mock Interview only
                  after its slot is over
                </p>
              </div>
            )}
            {verified && status === "invalid" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorIcon
                  color="error"
                  style={{ marginRight: "1.5%" }}
                  fontSize="large"
                />
                <p
                  className="mt-4 mb-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100"
                  style={{ color: "#d32f2f" }}
                >
                  Sorry! Time Limit of Reporting an Issue about this Mock
                  Interview has expired! You could report it only upto 6 hours
                  after this mock interview’s time-slot
                </p>
              </div>
            )}
            {verified &&
              status === "6 hours" &&
              mockDetails?.StudentIssue?.issueReported !== true && (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend">
                      Please Check the issues you faced
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={one}
                            onChange={handleChangeCheckBox}
                            name="one"
                          />
                        }
                        label="Faced network issues in between the Mock Interview"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={two}
                            onChange={handleChangeCheckBox}
                            name="two"
                          />
                        }
                        label="Interviewer joined late by more than 15 minutes for the mock interview but left on time"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={three}
                            onChange={handleChangeCheckBox}
                            name="three"
                          />
                        }
                        label="Structure of mock interview was not as per the real interviews   "
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={four}
                            onChange={handleChangeCheckBox}
                            name="four"
                          />
                        }
                        label="Interviewer focused more on mentoring and guidance, rather asking the interview-questions"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={five}
                            onChange={handleChangeCheckBox}
                            name="five"
                          />
                        }
                        label="Other Issues"
                      />
                      <TextField
                        type="text"
                        name="textofArea"
                        label="Describe your issue(s) in detail"
                        multiline
                        rows={4}
                        sx={{ mt: 2 }}
                        value={data.textofArea}
                        onChange={handleChangeofBox}
                        error={error}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
            {verified &&
              status === "6 hours" &&
              mockDetails?.StudentIssue?.issueReported !== true && (
                <Grid item sx={{ mt: 2, mb: 2 }}>
                  <Button variant="contained" onClick={handleSubmit}>
                    Report the Issue
                  </Button>
                </Grid>
              )}
          </TextColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
export default Issue;
