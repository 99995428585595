import React, { useState, useContext  } from "react";
import "./Footer.css";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import Draggable from "react-draggable";
import ReactMarkdown from "markdown-to-jsx";
import { Link as LinkScroll } from "react-scroll";
import tw from "twin.macro";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as SvgDecoratorBlob1 } from "./../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "./../../images/svg-decorator-blob-8.svg";
import UserContext from "../../contexts/UserContext.js";
// constants
import {
  SUCCESS_STORIES_ROUTE,
  CONTACT_US_ROUTE,
  ABOUT_US_ROUTE,
  PRICING_ROUTE,
  PROFILES_ROUTE,
  FAQS_STUDENTS_ROUTE,
  FAQS_PROFESSIONAL_ROUTE,
  FAQS_ADMIN_ROUTE,
  HOW_IT_WORKS_PROFESSIONALS_ROUTE,
  HOW_IT_WORKS_STUDENTS_ROUTE,
  HOW_IT_WORKS_ADMINS_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
  PRIVACY_POLICY_ROUTE,
} from "../../constants/routes";
import {
  CONTACT_EM_URL,
  CONTACT_FB_URL,
  CONTACT_IN_URL,
  CONTACT_WB_URL,
} from "../../constants/urls";
import { LOGO_SVG } from "../../constants/images";
// data
import content from "./data/footerDialog";
// mui
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  PaperProps,
} from "@mui/material";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function MarkdownListItem(props) {
  return <Box component="li" sx={{ mt: 1, typography: "body1" }} {...props} />;
}

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "h4",
        component: "h1",
      },
    },
    h2: {
      component: Typography,
      props: { gutterBottom: true, variant: "h6", component: "h2" },
    },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: "subtitle1" },
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: "caption",
        paragraph: true,
      },
    },
    p: {
      component: Typography,
      props: { paragraph: true },
    },
    a: { component: Link },
    li: {
      component: MarkdownListItem,
    },
  },
};

const Footer = () => {
  const { user, setUser } = useContext(UserContext);
  const FAQSContainer = tw.dl` max-w-4xl relative`;
  const FAQ = tw.div`cursor-pointer mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
  const Question = tw.dt`flex justify-between items-center`;
  const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
  const QuestionToggleIcon = motion(styled.span`
    ${tw`ml-2 transition duration-300`}
    svg {
      ${tw`w-6 h-6`}
    }
  `);
  const Answer = motion(tw.dd`text-sm sm:text-base leading-relaxed`);

  const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
    ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
  `;
  const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
    ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
  `;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);

    // console.log(activeQuestionIndex);
  };

  const faqs = [
    {
      question: "Company",
      answer: function () {
        return (
          <ul className="footer-links footer-links-m">
            <li onClick={() => window.scrollTo(0, 0)}>
              {location.pathname === "/" ? (
                <LinkScroll to="testimonials" spy={true} smooth={true}>
                  Success Stories
                </LinkScroll>
              ) : (
                <Link to={SUCCESS_STORIES_ROUTE}>Success Stories</Link>
              )}
            </li>
            <li onClick={() => window.scrollTo(0, 0)}>
              <Link to={CONTACT_US_ROUTE}>Contact Us</Link>
            </li>
            <li onClick={() => window.scrollTo(0, 0)}>
              <Link to={ABOUT_US_ROUTE}>About Us</Link>
            </li>
          </ul>
        );
      },
    },
    {
      question: "Platform",
      answer: function () {
        return (
          <ul className="footer-links footer-links-m">
            <li onClick={() => window.scrollTo(0, 0)}>
              {location.pathname === "/" ? (
                <LinkScroll to="howItWorks" spy={true} smooth={true}>
                  How It Works?
                </LinkScroll>
              ) : (
                <Link
                  to={
                    user?.role === "Interviewer"
                      ? HOW_IT_WORKS_PROFESSIONALS_ROUTE
                      : user?.role === "Student" 
                      ? HOW_IT_WORKS_STUDENTS_ROUTE
                      : HOW_IT_WORKS_ADMINS_ROUTE
                  }
                >
                  How It Works?
                </Link>
              )}
            </li>
            <li onClick={() => window.scrollTo(0, 0)}>
              {location.pathname === "/" ? (
                <LinkScroll to="profiles" spy={true} smooth={true}>
                  Profiles
                </LinkScroll>
              ) : (
                <Link to={PROFILES_ROUTE}>Profiles</Link>
              )}
            </li>
            <li onClick={() => window.scrollTo(0, 0)}>
              {location.pathname === "/" ? (
                <LinkScroll to="pricing" spy={true} smooth={true}>
                  Pricing
                </LinkScroll>
              ) : (
                <Link to={PRICING_ROUTE}>Pricing</Link>
              )}
            </li>
            <li onClick={() => window.scrollTo(0, 0)}>
              {location.pathname === "/" ? (
                <LinkScroll to="faqs" spy={true} smooth={true}>
                  FAQs
                </LinkScroll>
              ) : (
                <Link
                  to={
                    user?.role === "Interviewer"
                      ? FAQS_PROFESSIONAL_ROUTE
                      : user?.role === "Student"
                      ? FAQS_STUDENTS_ROUTE
                      : FAQS_ADMIN_ROUTE
                  }
                >
                  FAQs
                </Link>
              )}
            </li>
          </ul>
        );
      },
    },
    {
      question: "Legal",
      answer: function () {
        return (
          <ul className="footer-links footer-links-m">
            <li>
              <a onClick={(e) => setDialogState(e.target.innerText)}>
                Payment Policy
              </a>
            </li>
            <li>
              <a onClick={(e) => setDialogState(e.target.innerText)}>
                Rescheduling Policy
              </a>
            </li>
            {/* <li>
            <a onClick={(e) => setDialogState(e.target.innerText)}>
              Cancellation and Refund Policy
            </a>
          </li> */}
            <li>
              <Link to={TERMS_AND_CONDITIONS_ROUTE}>Terms and Conditions</Link>
            </li>
            <li>
              <Link to={PRIVACY_POLICY_ROUTE}>Privacy Policy</Link>
            </li>
            <li>
              <a onClick={(e) => setDialogState(e.target.innerText)}>
                Report an Issue Policy
              </a>
            </li>
            <li>
              <a onClick={(e) => setDialogState(e.target.innerText)}>
                Cancellation Policy
              </a>
            </li>
          </ul>
        );
      },
    },
    {
      question: "Info",
      answer: function () {
        return (
          <ul className="footer-links footer-links-m">
            <li>
              <a onClick={(e) => setDialogState(e.target.innerText)}>
                Why interwiu.com?
              </a>
            </li>
            <li>
              <a onClick={(e) => setDialogState(e.target.innerText)}>
                Why Mock Interviews & Mentorship?
              </a>
            </li>
            <li>
              <a onClick={(e) => setDialogState(e.target.innerText)}>
                Guidelines for Students
              </a>
            </li>
            <li>
              <a onClick={(e) => setDialogState(e.target.innerText)}>
                Guidelines for Professionals
              </a>
            </li>
            <li>
              <a onClick={(e) => setDialogState(e.target.innerText)}>
                Guidelines for Institute Admins
              </a>
            </li>
          </ul>
        );
      },
    },
  ];
  // ------------------------------ FAQS ------------------------------ //

  const location = useLocation();
  // const user =
  //   JSON.parse(localStorage.getItem("isAuthenticated")) ||
  //   JSON.parse(localStorage.getItem("isIAuthenticated"));
  const [dialogState, setDialogState] = useState("");
  // console.log(user.role);
  return (
    <footer className="site-footer">
      <Dialog
        className="footer__head"
        open={Boolean(dialogState)}
        onClose={() => setDialogState("")}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        {dialogState ? (
          <>
            <DialogTitle
              style={{ cursor: "move", fontWeight: "600", color: "#666666" }}
              id="draggable-dialog-title"
            >
              {dialogState}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <ReactMarkdown options={options} className="markdown">
                  {content[dialogState]}
                </ReactMarkdown>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                autoFocus
                style={{ textTransform: "none" }}
                onClick={() => setDialogState("")}
              >
                Got It
              </Button>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
      <div className="container footer__body">
        <div className="row">
          <div
            className="footer__logo"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={LOGO_SVG}
              style={{
                borderRadius: "50%",
                width: "75px",
                height: "75px",
                backgroundColor: "white",
                border: "10px solid white",
              }}
              alt=""
            />
            <div style={{ display: "flex", flexDirection: "column" ,marginLeft: '30px'}}>
              <h4
                style={{
                  color: "white",
                  fontSize: "30px",
                  marginBottom: "10px",
                  transform: "translateX(-15px)",
                }}
              >
                interwiu.com by
              </h4>
              <h3
                style={{
                  color: "#02A3E8",
                  fontSize: "30px",
                  marginBottom: "5px",
                }}
              >
                Fyzen Career Solutions Pvt Ltd
              </h3>
              <p
                className="text-justify"
                style={{
                  color: "white",
                  fontSize: "14px",
                  marginBottom: "0",
                  marginLeft: "25px",
                  fontFamily:"sans-serif"
                }}
              >
                a Career Management Tech Start-up by Alumni of IIT Madras
              </p>
            </div>
          </div>
          <hr style={{ margin: "10px 0" }} />
          <div className="footer__bodyMid">
            {faqs.map((faq, index) => {
              return (
                <div className="col-sm-12 col-md-3" key={index}>
                  <h6>{faq.question}</h6>
                  {faq.answer()}
                </div>
              );
            })}
            {/* <div className="col-sm-12 col-md-3">
              <h6>Company</h6>
              <ul className="footer-links">
                <li onClick={() => window.scrollTo(0, 0)}>
                  {location.pathname === "/" ? (
                    <LinkScroll to="testimonials" spy={true} smooth={true}>
                      Success Stories
                    </LinkScroll>
                  ) : (
                    <Link to={SUCCESS_STORIES_ROUTE}>Success Stories</Link>
                  )}
                </li>
                <li onClick={() => window.scrollTo(0, 0)}>
                  <Link to={CONTACT_US_ROUTE}>Contact Us</Link>
                </li>
                <li onClick={() => window.scrollTo(0, 0)}>
                  <Link to={ABOUT_US_ROUTE}>About Us</Link>
                </li>
                  </ul>
                  {faqs[0].answer()}
            </div>
            <div className="col-xs-6 col-md-3">
              <h6>Platform</h6>
              
              <ul className="footer-links">
                <li onClick={() => window.scrollTo(0, 0)}>
                  {location.pathname === "/" ? (
                    <LinkScroll to="howItWorks" spy={true} smooth={true}>
                      How It Works?
                    </LinkScroll>
                  ) : (
                    <Link
                      to={
                        user?.role === "Interviewer"
                          ? HOW_IT_WORKS_PROFESSIONALS_ROUTE
                          : HOW_IT_WORKS_STUDENTS_ROUTE
                      }
                    >
                      How It Works?
                    </Link>
                  )}
                </li>
                <li onClick={() => window.scrollTo(0, 0)}>
                  {location.pathname === "/" ? (
                    <LinkScroll to="profiles" spy={true} smooth={true}>
                      Profiles
                    </LinkScroll>
                  ) : (
                    <Link to={PROFILES_ROUTE}>Profiles</Link>
                  )}
                </li>
                <li onClick={() => window.scrollTo(0, 0)}>
                  {location.pathname === "/" ? (
                    <LinkScroll to="pricing" spy={true} smooth={true}>
                      Pricing
                    </LinkScroll>
                  ) : (
                    <Link to={PRICING_ROUTE}>Pricing</Link>
                  )}
                </li>
                <li onClick={() => window.scrollTo(0, 0)}>
                  {location.pathname === "/" ? (
                    <LinkScroll to="faqs" spy={true} smooth={true}>
                      FAQs
                    </LinkScroll>
                  ) : (
                    <Link
                      to={
                        user?.role === "Interviewer"
                          ? FAQS_PROFESSIONAL_ROUTE
                          : FAQS_STUDENTS_ROUTE
                      }
                    >
                      FAQs
                    </Link>
                  )}
                </li>
              </ul>
              {faqs[1].answer()}
            </div>
            <div className="col-xs-6 col-md-3">
              <h6>Legal</h6>
              <ul className="footer-links">
                <li>
                  <a onClick={(e) => setDialogState(e.target.innerText)}>
                    Payment Policy
                  </a>
                </li>
                <li>
                  <a onClick={(e) => setDialogState(e.target.innerText)}>
                    Rescheduling Policy
                  </a>
                </li>
                <li>
                  <a onClick={(e) => setDialogState(e.target.innerText)}>
                    Cancellation and Refund Policy
                  </a>
                </li>
                <li>
                  <Link to={TERMS_AND_CONDITIONS_ROUTE}>
                    Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to={PRIVACY_POLICY_ROUTE}>Privacy Policy</Link>
                </li>
                <li>
                  <a onClick={(e) => setDialogState(e.target.innerText)}>
                    Report an Interview Issue Policy
                  </a>
                </li>
                <li>
                  <a onClick={(e) => setDialogState(e.target.innerText)}>
                    Cancellation and Refund Policy
                  </a>
                </li>
              </ul>
              {faqs[2].answer()}
            </div>
            <div className="col-xs-6 col-md-3">
              <h6>Info</h6>
              <ul className="footer-links">
                <li>
                  <a onClick={(e) => setDialogState(e.target.innerText)}>
                    Why interwiu.com?
                  </a>
                </li>
                <li>
                  <a onClick={(e) => setDialogState(e.target.innerText)}>
                    Why Mock Interviews with Feedback?
                  </a>
                </li>
                <li>
                  <a onClick={(e) => setDialogState(e.target.innerText)}>
                    Guidelines for Students
                  </a>
                </li>
                <li>
                  <a onClick={(e) => setDialogState(e.target.innerText)}>
                    Guidelines for Interviewers
                  </a>
                </li>
              </ul>
              {faqs[3].answer()}
            </div> */}
          </div>

          {/* --------------------- MOBILE FOOTER --------------------------- */}

          <FAQSContainer className="mobile-hedder">
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
                style={{
                  backgroundColor: "hsl(228, 6%, 26%)",
                  color: "white",
                  fontWeight: "300",
                  marginTop: `${index == 0 ? "0px" : "20px"}`,
                }}
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: {
                      opacity: 0,
                      height: 0,
                      marginTop: "0px",
                      display: "none",
                    },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                  // dangerouslySetInnerHTML={{ __html: faq.answer }}
                >
                  {faq.answer()}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>

          {/* --------------------- -------------- --------------------------- */}
        </div>
        <hr style={{ margin: "10px 0" }} />
      </div>
      <div className="container footer__foot">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text" style={{ color: "white" }}>
              Copyright &copy; 2023 All Rights Reserved by&nbsp;
              <a href="/" style={{ color: "#02A3E8" }}>
                Fyzen Career Solutions Pvt Ltd
              </a>
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              {/* <li>
                <a
                  className="facebook"
                  href={CONTACT_FB_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li> */}
              <li>
                <a
                  className="linkedin"
                  href={CONTACT_IN_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  className="envelope"
                  href={CONTACT_EM_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-envelope"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;