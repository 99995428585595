import {
    Container,
    Typography,
} from '@mui/material'

const CancellationPolicy = () => {
    return (
        <Container sx={{
            pt: 3,
            px: 2
        }}>
            <Typography variant="h4">Cancellation Policy</Typography>
            <Typography sx={{
                maxWidth: "85vw"
            }}>
                <br/>
                This policy applies to only confirmed (upcoming) mock interviews. A student or an interviewer, anyone can cancel a mock interview. There are four major clauses in this policy, which are listed below.
                <br/> ● If a student cancels a mock interview anytime during the last 6 hours preceding the respective mock interview, the student will get a coupon code using which he/she can avail 10% flat discount on his/her next mock interview booking, and the respective interviewer will get 75% of his/her mock interview fee.
                <br/> ● If a student cancels a mock interview anytime between 6 and 12 hours preceding the respective mock interview, the student will get a coupon code using which he/she can avail 25% flat discount on his/her next mock interview booking, and the respective interviewer will get 50% of his/her mock interview fee.
                <br/> ● If a student cancels a mock interview anytime before the last 12 hours preceding the respective mock interview, the student will get a coupon code using which he/she can avail 50% flat discount on his/her next mock interview booking, and the respective interviewer will get 0% of his/her mock interview fee.
                <br/> ● If an interviewer cancels a mock interview anytime before its scheduled time, the student will get a coupon code using which he/she can avail 100% flat discount on his/her next mock interview booking, and the respective interviewer will get 0% of his/her mock interview fee.
                
            </Typography>
        </Container>
    )
}

export default CancellationPolicy