import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import Chip from "@mui/material/Chip";
import Snackbar from "@mui/material/Snackbar";
import { Box, Grid, MenuItem, Select } from "@mui/material";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment"; // Getting day from date and modifying date format
import { Calendar } from "react-multi-date-picker";
import { STUDENT_PROFILE_FORM_ROUTE } from "../../../constants/routes.js";
import UserContext from "../../../contexts/UserContext.js";
import {
  BASE,
  INTERVIEWERS_GETBYFILTERS_ENDPOINT,
} from "../../../constants/endpoints";
import axios from "axios";
import SlotAlreadyBooked from "../popups/SlotAlreadyBooked.js";
import {
  compareDate,
  compareSlots,
  convertDateFormat,
  getCompanyLogo,
  t12HTo24HDecimalFormat,
  t24HDecimalTo12HFormat,
  toastStyle,
} from "../../../utility/helper.js";
import toast from "react-hot-toast";
import { Divider } from "antd";
import { AiTwotoneCalendar } from "react-icons/ai";
import { InfoOutlined } from "@mui/icons-material";
import StudCardDetails from "components/misc/StudCardDetails.js";
import { FaCopy } from "react-icons/fa";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: "400px",
      transform: "translateX(4%)",
    },
  },
};

const CanceledInterview = ({
  name,
  desg,
  price,
  image,
  profile,
  subprofile,
  slotStart,
  date,
  slotEnd,
  ccompany,
  status,
  id,
  logo,
  suggestedSlotStart,
  suggestedSlotEnd,
  suggestedDate,
  reqId,
  mockId,
  isRescheduled,
  cancelledBy,
  cancelledDate,
  cancelledTime,
  sessDuration,
  sessType
}) => {
  const { user } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [requests, setRequests] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [interviewer, setInterViewer] = useState(null);
  const [slotsArray, setSlotsArray] = useState([]);
  const [requestData, setRequestData] = useState({
    studentId: user.id,
    interviewerId: id,
    requestId: "#Yet_to_be_generated",
    slot: "",
    date: moment(selectedDate).format("DD/MM/YYYY"),
    profile: "",
    subprofile: "",
    requestStatus: 0,
    paymentId: "nan",
    paymentStatus: "Not Done",
  });
  console.log("RESCHHHHH", isRescheduled);
  const [isSlotAlreadyBooked, setisSlotAlredyBooked] = useState(false);
  const slotBookedModalHandler = (data) => {
    setisSlotAlredyBooked(data);
  };
  const checkInterviewerAvailability = async (requestData) => {
    console.log("requestData :-", requestData);
    let searchStr = "";
    searchStr += "?id=" + requestData?.interviewerId;
    searchStr += "&profile=" + String(requestData?.profile);
    searchStr +=
      "&time=" +
      requestData?.slot.slice(1).split(":")[0] +
      "," +
      requestData?.slot.slice(1).split(":")[1];
    searchStr += "&date=" + requestData?.date;

    console.log("Rakesh Search STring");
    console.log(searchStr);
    let isInterviewerAvailable = false;
    await axios
      .get(INTERVIEWERS_GETBYFILTERS_ENDPOINT + searchStr)
      .then((res) => {
        console.log(
          "res.data[0].id :- ",
          res.data[0].id,
          " requestData?.interviewerId :- ",
          requestData?.interviewerId,
          " res.data[0].id === requestData?.interviewerId :- ",
          res.data.id === requestData?.interviewerId
        );
        console.log("Rakesh res.data?.length:");
        console.log(res.data?.length);
        console.log("Rakesh res Data:");
        console.log(res.data);
        if (res.data?.length === 0) {
          setisSlotAlredyBooked(true);
        }
        if (res.data?.length === 1) {
          isInterviewerAvailable = true;
        }
      })
      .catch((err) => console.log(err));
    console.log("isInterviewerAvailable", isInterviewerAvailable);
    return isInterviewerAvailable;
  };
  useEffect(() => {
    console.log("intId", id);
    axios.get(`${BASE}/int/interviewer/${id}`).then((res) => {
      console.log(res.data);
      setInterViewer(res.data);
    });
  }, []);
  const designation = interviewer?.designation;
  const company = interviewer?.currCompany;
  // const price=interviewer?.price || `INR ${process.env.REACT_APP_STD_45MIN}`
  const profiles = interviewer?.profiles || [];
  const coreprofiles = interviewer?.coreprofiles || [];
  const specializations = interviewer?.specializations.map(spec => ({
    ...spec,
    subDomains: spec.subDomains.filter(sD => sD !== "Other")
  })) || [];
  const photo = interviewer?.photo;
  // const logo=getCompanyLogo(interviewer?.currCompany)
  const gnrcSchd = interviewer?.GnrcSchedule ? interviewer?.GnrcSchedule : [];

  const custSchd = interviewer?.CustomSchedule
    ? interviewer?.CustomSchedule
    : [];

  const studentId = user.id;
  const displaySlots = (date) => {
    const currentDate = moment(date).format("DD/MM/YYYY");
    const currentDay = moment(date).format("ddd").toLowerCase();
    let check = false;
    if (custSchd !== 0) {
      custSchd?.map((item) => {
        if (item.date === currentDate) {
          check = true;
          let slotArray = [];
          item.sessions?.map((sess) => {
            let duration = sess.to - sess.from
              // console.log(sess.to, sess.from, duration, duration % 0.75)
              let i = sess.from 
              while (true) {
                let slot = {
                  from: i,
                  to: i + 0.75
                }
                if (i === 0 && duration % 0.75 !== 0) {
                  
                  let extra = duration - Math.floor(duration/0.75)*0.75
                  if (duration < 0.75) {
                    
                    return;
                  } else {
                    slot.from += extra
                    slot.to += extra
                    i += extra
                  }
                } else if (slot.to > 24) {
                  slot.to -= 24
                }
                slotArray.push([slot.from, slot.to]) 
                i += 0.75
                if (i >= sess.to)
                  break;
              }
          });

          setSlotsArray([...slotArray]);
        }
      });
    }
    if (!check) {
      if (gnrcSchd?.length !== 0) {
        gnrcSchd?.map((item) => {
          if (item.day === currentDay) {
            let slotArray = [];
            item.sessions?.map((sess) => {
              let duration = sess.to - sess.from
              // console.log(sess.to, sess.from, duration, duration % 0.75)
              let i = sess.from 
              while (true) {
                let slot = {
                  from: i,
                  to: i + 0.75
                }
                if (i === 0 && duration % 0.75 !== 0) {
                  
                  let extra = duration - Math.floor(duration/0.75)*0.75
                  if (duration < 0.75) {
                    
                    return;
                  } else {
                    slot.from += extra
                    slot.to += extra
                    i += extra
                  }
                } else if (slot.to > 24) {
                  slot.to -= 24
                }
                slotArray.push([slot.from, slot.to]) 
                i += 0.75
                if (i >= sess.to)
                  break;
              }
            });
            setSlotsArray([...slotArray]);
          }
        });
      }
    }
  };
  useEffect(() => {
    displaySlots(selectedDate);
    setRequestData({
      ...requestData,
      date: moment(selectedDate).format("DD/MM/YYYY"),
      slot: "",
    }); // When date changes then date will be updated and slot will reset
  }, [selectedDate]);
  const handleRequestData = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // Append the property if doesn't exist and if does then update
    if (value === "none") {
      setRequestData((requestData) => ({
        ...requestData,
        [name]: "",
        studentId: studentId,
        interviewerId: id,
      }));
    }
    else {
      setRequestData((requestData) => ({
        ...requestData,
        [name]: value,
        studentId: studentId,
        interviewerId: id,
        [name === "profile" ? "subprofile" : null]: null
      }));
    }
  };
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  useEffect(() => {
    if (requestData.profile && requestData.slot) setSendBtnDisabled(true);
    else setSendBtnDisabled(false);
  }, [requestData]);
  const handleOpen = () => {
    setOpen(true);
    async function fetchData() {
      axios
        .get(`${BASE}/send/getAllInterviewRequestById/${id}`)
        .then((response) => {
          setRequests(response.data);
          displaySlots(selectedDate);
        });
    }
    fetchData();
  };
  const handleClose = () => {
    setOpen(false);
    //setRequestData({});
    setSelectedDate(new Date());
  };
  useEffect(() => {
    async function fetchData() {
      console.log(moment(selectedDate).format("DD/MM/YYYY"));
      axios
        .get(`${BASE}/send/getAllInterviewRequestById/${id}`)
        .then((response) => {
          setRequests(response.data);
        });
    }
    fetchData();
  }, []);
  function ImageExist(url) {
    if (url?.includes(".svg")) {
      var img = new Image();
      img.src = url;
      return img?.getBBox?.height !== 0;
    } else {
      var img2 = new Image();
      img2.src = url;
      return img2?.height !== 0;
    }
  }
  let imgExist = false;
  const currReq = requests.filter(
    (item) =>
      item.date?.indexOf(moment(selectedDate).format("DD/MM/YYYY")) !== -1
  );
  let classes = "rounded-full bg-green-400 my-1 px-2 py-1";
  const settingColor = (item) => {
    if (currReq?.length !== 0) {
      for (let i = 0; i < currReq?.length; i++) {
        if (
          currReq[i].fromTime === item &&
          currReq[i].reqStatus === "ReqAccepted"
        ) {
          classes = "rounded-full bg-yellow-400 my-1 px-2 min-[600px]:py-2";
          return true;
        } else if (
          currReq[i].fromTime === item &&
          currReq[i].reqStatus === "ReqPaid"
        ) {
          classes = "rounded-full bg-red-400 my-1 px-2 min-[600px]:py-2";
          return true;
        }
      }
    }
    classes = "rounded-full bg-green-400 my-1 px-2 min-[600px]:py-2";
    return true;
  };

  const addEntryToInterviewReqTable = (requestData) => {
    console.log("Yes");
    var newReq = {
      //reqId: generateId(),
      studId: studentId,
      interviewerId: requestData.interviewerId,
      profile: requestData.profile,
      subprofile: requestData.subprofile,
      date: requestData.date, // [DD/MM/YYYY]
      fromTime: requestData.slot.slice(1)?.split(":")[0], // Time is in 24 hour decimal format.
      toTime: requestData.slot.slice(1)?.split(":")[1],
      reqStatus: "ReqSent",
      mockId: "",
      reqStatusTime: [new Date(), null, null, null, null],
    };
    axios.post(`${BASE}/send/sendInterviewReq`, newReq).then((response) => {
      console.log("🎃", response.status, response.data);
      if (response.data?.success == false) {
        toast.error(response.data.msg, {
          ...toastStyle.error,
          position: "top-center",
        });
        return;
      }
      toast.success("Request Sent Successfully", {
        ...toastStyle.success,
        position: "top-center",
      });
    });
  };

  // const addEntryToInterviewReqTable = (requestData) => {
  //   console.log("Yes");
  //   var newReq = {
  //     date: requestData.date,
  //     fromTime: requestData.slot.slice(1).split(":")[0],
  //     toTime: requestData.slot.slice(1).split(":")[1],
  //     status: "ReqSent",
  //   };
  // axios
  //   .patch(`${BASE}/send/updateInterviewReq/${reqId}`, newReq)
  //   .then((response) => {
  //     console.log(response);
  //     toast.success("Request Sent Successfully", {
  //       ...toastStyle.success,
  //       position: "top-center",
  //     });
  //     setTimeout(() => {
  //       window.location.reload(true);
  //     }, 1000);
  //   });

  // };

  const [datePickerMobile, setDatePickerMobile] = useState(false)
  const [toolTip, setToolTip] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(mockId);
      toast.success(`Copied to clipboard`, {
        ...toastStyle.success,
      });
    } catch (error) {
      console.error('Failed to copy text:', error);
      toast.success(`Failed to copy text`, {
        ...toastStyle.loading,
      });
    }
  };

  return (
    <>
      {(isRescheduled === "true" || isRescheduled === true) && (
        <div
          className={"box-shadow-request-card px-3 py-2"}
          style={{
            position: "absolute",
            right: "10px",
            zIndex: "2",
            backgroundColor: "#f5ed4e",
            borderRadius: "10px",
          }}
        >
          <p>Rescheduled</p>
        </div>
      )}
      <div className="relative sent-item my-4 p-2 s-desktop-card">
        <div
          className="sent-item-profile"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <img src={image} alt="Profile" />
          <button
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                "_blank"
              );
            }}
            className="cust-btn sent-view-btn"
          >
            View Profile
          </button>
        </div>
        <div className="sent-item-info">
          <div className="sent-item-head">
            <div>
              <div className="flex py-1 justify-between w-full">
                <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
                  {ImageExist(logo[0]) &&
                    imgExist === false &&
                    (imgExist = true) && (
                      <img src={logo[0]} alt="logo" className="h-full w-full" />
                    )}
                  {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
                    <img src={logo[1]} alt="logo" className="h-full w-full" />
                  )}
                  {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
                    <img src={logo[2]} alt="logo" className="h-full w-full" />
                  )}
                  {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
                    <img src={logo[3]} alt="logo" className="h-full w-full" />
                  )}
                  {!imgExist &&
                    !ImageExist(logo[0]) &&
                    !ImageExist(logo[1]) &&
                    !ImageExist(logo[2]) &&
                    !ImageExist(logo[3]) &&
                    (imgExist = true) && (
                      <img src={logo[4]} alt="logo" className="h-full w-full" />
                    )}
                </div>
                <div>
                  <div>
                    <h4 className="text-2xl font-medium leading-4">{name}</h4>
                    <p>
                      {" "}
                      {desg}, {ccompany}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="head-icon flex">
                    <i className="fa-solid fa-bookmark"></i>
                  </div>
                  <div className="head-icon flex">
                    <i className="fa-solid fa-share"></i>
                  </div>
                </div>
              </div>
              {/* <div className="details flex">
                <div className="detail">
                  <p className="detail-head">Profile</p>
                  <p className="detail-body">{subprofile? `${subprofile} (${profile})`:profile}</p>
                </div>
                <div className="detail">
                  <p className="detail-head">Price</p>
                  <p className="detail-body">INR {price}</p>
                </div>
                <div className="detail">
                  <p className="detail-head">Session Duration</p>
                  <p className="detail-body">30 Min</p>
                </div>
                <div className="detail">
                  <p className="detail-head">Interview Slot</p>
                  <span className="text-lg">
                    {t24HDecimalTo12HFormat(slotStart)}
                  </span>
                  <span> &nbsp; - &nbsp;</span>
                  <span className="text-lg">
                    {t24HDecimalTo12HFormat(slotEnd)}
                  </span>
                  <p className="text-lg">{date}</p>
                </div> 
              </div> */}
              <StudCardDetails
                subprofile={subprofile}
                profile={profile}
                isRescheduled={"other"}
                reschedule={{
                  status: "",
                  details: null
                }}
                price={price}
                slotEnd={slotEnd}
                slotStart={slotStart}
                date={date}
                sessDuration={sessDuration}
                sessType={sessType}
              />
              <div className="sent-item-btns flex justify-center">
                <p className="text-red-700 font-medium text-lg border-2  px-2 py-1 rounded">
                  <i className="fa-solid fa-xmark"></i> Mock Interview Cancelled
                </p>
                {suggestedDate && suggestedSlotEnd && suggestedSlotStart && (
                  <Button
                    style={{ textTransform: "none", marginLeft: "15px" }}
                    variant="contained"
                    onClick={handleOpen}
                  >
                    Choose Another Slot
                  </Button>
                )}
                {/* <Modal open={open} onClose={handleClose}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      borderRadius: 2,
                      boxShadow: 24,
                      overflow: "hidden",
                      minWidth: "750px",
                    }}
                  >
                    <div className="flex flex-row justify-between gap-2 bg-blue-500 p-2">
                      <Typography
                        id="modal-modal-title"
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          color: "white",
                          mx: 2,
                        }}
                      >
                        Request a Mock Interview (with Feedback Session)
                      </Typography>
                      <IconButton onClick={handleClose}>
                        <CancelIcon sx={{ color: "white" }} />
                      </IconButton>
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                      <div className="col-span-1 m-2 flex flex-row justify-center">
                        <div>
                          <Typography
                            id="modal-modal-title"
                            variant="h5"
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                              my: 1,
                            }}
                          >
                            To: {name}
                          </Typography>
                          <div className="w-full flex flex-row justify-center my-1">
                            <Calendar
                              minDate={new Date().setDate(new Date().getDate())}
                              zIndex={0}
                              value={selectedDate}
                              onChange={(e) => setSelectedDate(new Date(e))}
                              mapDays={({ date, selectedDate, isSameDate }) => {
                                let props = {};
                                if (isSameDate(date, selectedDate)) {
                                  props.style = {
                                    color: "#fff",
                                    backgroundColor: "#00a86b",
                                    border: "1px solid #000",
                                  };
                                  return props;
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 m-2 my-2 flex flex-row justify-center items-center">
                        <div>
                          <Typography
                            variant="h5"
                            component="h5"
                            sx={{ mb: "2px" }}
                          >
                            Choose a Profile
                          </Typography>
                          <RadioGroup
                            id="profile"
                            name="profile"
                            row
                            value={requestData.profile || ""}
                            onChange={handleRequestData}
                            sx={{
                              maxHeight: "100px",
                              overflowX: "auto",
                            }}
                          >
                            {profiles.map((profileItem) => (
                              <FormControlLabel
                                value={profileItem}
                                control={<Radio sx={{ py: "2px" }} />}
                                label={profileItem}
                              />
                            ))}
                          </RadioGroup>
                          <hr className="mx-4 my-3 border-black" />
                          <div className="relative border-2 border-gray-400 rounded p-1 my-1 flex flex-wrap">
                            <div className="flex mr-3">
                              <div className="inline-block rounded-full w-4 h-4 mx-1 bg-green-500"></div>
                              Available
                            </div>
                            <div className="flex mr-3">
                              <div className="inline-block rounded-full w-4 h-4 mx-1 bg-orange-400"></div>
                              Conditionally Available
                            </div>
                            <div className="flex mr-3">
                              <div className="inline-block rounded-full w-4 h-4 mx-1 bg-red-500"></div>
                              Occupied
                            </div>
                            <div className="absolute right-0 flex mr-3 text-blue-600 w-5 cursor-pointer">
                              <Tooltip
                                title={
                                  <>
                                    <p className="my-1">
                                      Green: The slot is completely available to
                                      you
                                    </p>
                                    <p className="my-1">
                                      Orange: Another student has already chosen
                                      the slot, but is yet to pay and confirm.
                                      You can still request that slot, and pay
                                      first after acceptance to confirm
                                    </p>
                                    <p className="my-1">
                                      Red: The slot is not available to you,
                                      because another student has already
                                      confirmed the slot
                                    </p>
                                  </>
                                }
                              >
                                <img
                                  src={require("../../../images/exclamation.png")}
                                  alt="Description"
                                  style={{ transform: "rotateZ(180deg)" }}
                                />
                              </Tooltip>
                            </div>
                          </div>
                          <Typography variant="h5" component="h5">
                            Choose a Slot
                          </Typography>
                          <RadioGroup
                            row
                            name="slot"
                            id="slot"
                            value={requestData.slot || ""}
                            onChange={handleRequestData}
                            sx={{ maxHeight: "150px", overflowX: "auto" }}
                          >
                            {slotsArray?.length !== 0 ? (
                              slotsArray.map(
                                (item) =>
                                  (compareSlots(item[0]) ||
                                    !compareDate(selectedDate, new Date())) &&
                                  settingColor(item[0]) === true && (
                                    <div className={classes}>
                                      <FormControlLabel
                                        value={"#" + item[0] + ":" + item[1]}
                                        key={"#" + item[0] + ":" + item[1]}
                                        control={<Radio sx={{ py: 0 }} />}
                                        label={
                                          t24HDecimalTo12HFormat(item[0]) +
                                          " - " +
                                          t24HDecimalTo12HFormat(item[1])
                                        }
                                        disabled={classes.includes("red")}
                                      />
                                    </div>
                                  )
                              )
                            ) : (
                              <p className="text-center">
                                No slots available on{" "}
                                <span className="font-bold">
                                  {moment(selectedDate).format("Do MMM, YYYY")}
                                </span>
                              </p>
                            )}
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex flex-row justify-center my-2">
                      <Button
                        color="success"
                        variant="contained"
                        disabled={!sendBtnDisabled}
                        onClick={() => {
                          console.log(
                            "date: ",
                            moment(selectedDate).format("DD/MM/YYYY")
                          );
                          console.log(
                            "requestData Before sending to checkInterviewerAvailability(): ",
                            requestData
                          );
                          console.log("Sending Request...");
                          checkInterviewerAvailability(requestData).then(
                            (isInterviewerAvailable) => {
                              if (isInterviewerAvailable)
                                addEntryToInterviewReqTable(requestData);
                            }
                          );
                          handleClose();
                        }}
                      >
                        Send Request
                      </Button>
                    </div>
                  </Box>
                </Modal> */}
                {isSlotAlreadyBooked ? (
                  <SlotAlreadyBooked
                    isSlotAlreadyBooked={isSlotAlreadyBooked}
                    slotBookedModalHandler={slotBookedModalHandler}
                  />
                ) : null}
              </div>
              {suggestedDate && suggestedSlotEnd && suggestedSlotStart && (
                <div className="py-2">
                  <span className="text-lg">Suggested Slot :- </span>
                  <span className="text-lg">
                    {t24HDecimalTo12HFormat(suggestedSlotStart)}
                  </span>
                  <span> &nbsp; - &nbsp;</span>
                  <span className="text-lg">
                    {t24HDecimalTo12HFormat(suggestedSlotEnd)},{" "}
                  </span>
                  <span className="text-lg">{convertDateFormat(suggestedDate)}</span>
                </div>
              )}
              {mockId && (
                <div
                  className="text-green-700 text-lg font-bold pt-1 flex"
                  style={{ fontSize: "16px" }}
                >
                  {{interview: "Mock Interview ID", mentorship: "Mentorship Session ID", resume:"Resume Building Session ID"}[sessType]}: {mockId};{" "}
                  {cancelledBy &&
                    cancelledDate &&
                    cancelledTime &&
                    `Canceled by ${cancelledBy[0] === "I" ? "Interviewer" : "You"
                    } at ${cancelledTime} on ${cancelledDate}`}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>


      {/* mobile card */}

      <div
        className="relative overflow-hidden s-card-main s-mobile-card s-mobile-card-shadow"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="absolute bottom-1 right-2 text-right">
          <p
            className="text-xs text-gray-400"
            style={{ fontSize: "0.65rem" }}
          >
            Sent 15 Min ago
          </p>
        </div>
        <div className="s-profile">
          <div
            className=" my-auto col-span-1 s-profile-p-p"

          >
            <img
              src={image}
              className="rounded-full border-2 border-black p-1 block mx-auto"
              alt="Profile"
              width={{ width: "100%" }}
            />
            <button
              style={{ whiteSpace: "nowrap" }}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/interviewer/${id}`,
                  "_blank"
                );
              }}
              className="view-profile-card-btn  mt-2 mx-auto view-profile-card-btn-mobile"
            >
              View Profile
            </button>
          </div>
          <div
            className="flex py-1 justify-between w-full s-profile-n-d-c"
            style={{ alignItems: "start" }}
          >
            {/* <div className="h-14 w-14 p-1 rounded-md" id="int-logo">
      {ImageExist(logo[0]) &&
        imgExist === false &&
        (imgExist = true) && (
          <img src={logo[0]} alt="logo" className="h-full w-full" />
        )}
      {!imgExist && ImageExist(logo[1]) && (imgExist = true) && (
        <img src={logo[1]} alt="logo" className="h-full w-full" />
      )}
      {!imgExist && ImageExist(logo[2]) && (imgExist = true) && (
        <img src={logo[2]} alt="logo" className="h-full w-full" />
      )}
      {!imgExist && ImageExist(logo[3]) && (imgExist = true) && (
        <img src={logo[3]} alt="logo" className="h-full w-full" />
      )}
      {!imgExist &&
        !ImageExist(logo[0]) &&
        !ImageExist(logo[1]) &&
        !ImageExist(logo[2]) &&
        !ImageExist(logo[3]) &&
        (imgExist = true) && (
          <img src={logo[4]} alt="logo" className="h-full w-full" />
        )}
    </div> */}
            {/* <div className="text-center "> */}
            <h4 className="text-2xl font-medium">{name}</h4>
            <p>{desg}</p>
            <p>{company}</p>
            {/* </div> */}
            {/* <div className="flex gap-2">
      <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-blue-500 text-2xl hover:text-blue-600 cursor-pointer">
        <i className="fa-solid fa-bookmark"></i>
      </div>
      <div className="flex justify-center border rounded-md p-2 w-10 h-10 text-green-500 text-2xl hover:text-green-600 cursor-pointer">
        <i className="fa-solid fa-share"></i>
      </div>
    </div> */}
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ marginBottom: '8px' }}>
            <div className="s-duration-price" style={{ marginBottom: '10px' }}>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Duration : </span>
                <span className="text-lg">{sessDuration} Min</span>
              </div>
              <div className="">
                <span className="text-base text-gray-400" style={{ fontWeight: 'bold', color: 'black' }}>Price : </span>
                <span className="text-lg">{price}</span>
              </div>
            </div>

            <div style={{ justifyContent: 'flex-start' }} className="s-duration-price">
              <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold', marginRight: '5px', transform: 'translateY(2px)' }}>Interview Slot : </p>
              <div >
                <span className="text-lg"> {t24HDecimalTo12HFormat(slotStart)}</span>
                <span> - </span>
                <span className="text-lg">{t24HDecimalTo12HFormat(slotEnd)}</span>
                <p className="text-lg">{convertDateFormat(date)}</p>
              </div>
            </div>

            {suggestedDate && suggestedSlotEnd && suggestedSlotStart && (
              <div className="s-duration-price" style={{ marginTop: '7px', justifyContent: 'flex-start' }}>
                <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold', marginRight: '5px' }}>Suggested Slot : </p>
                <div >
                  <span className="text-lg"> {t24HDecimalTo12HFormat(suggestedSlotStart)}</span>
                  <span> - </span>
                  <span className="text-lg">{t24HDecimalTo12HFormat(suggestedSlotEnd)}</span>
                  <p className="text-lg">{convertDateFormat(suggestedDate)}</p>
                </div>
              </div>
            )}

            <div className="s-profile-profiles">
              <div className=" col-span-2">
                <div className="text-lg flex flex-wrap gap-1">
                  <p className="text-base text-gray-400" style={{ color: 'black', fontWeight: 'bold' }}>Profile : </p>
                  <Chip
                    label={subprofile ? `${subprofile} (${profile})` : profile}
                    color="info"
                    className="s-mobile-changing-chip-property"
                    sx={{
                      fontWeight: "medium",
                      height: "auto",
                      '& .MuiChip-label': {

                        whiteSpace: "pre-wrap",
                        display: "flex",
                        flexWrap: "wrap"
                      }
                    }}
                  />
                </div>
              </div>
            </div>




          </div>
        </div>

        {mockId && (
          <div className="text-green-700 pb-2 text-lg font-bold" style={{ fontSize: "13px", textAlign: 'center' }}  >
            Mock Interview ID: {mockId} <FaCopy style={{ display: 'inline', fontSize: '1rem', transform: 'translate(6px,-3px)' }} onClick={handleCopy} />
          </div>
        )}
        <div className="relative flex justify-center">
          <div style={{ textAlign: "center", alignItems: "center", width: '100%', display: 'flex', gap: '5px' }}>
            <div className="sent-item-btns flex justify-center items-stretch" style={{ width: '100%',alignItems:'stretch' }}>
              <p className="text-red-700 font-medium text-lg border-2 rounded flex" style={{ whiteSpace: 'normal', fontSize: '1rem', width: '100%', borderRadius: '0px'}}>
                <i className="fa-solid fa-xmark ml-[10px]"></i><span>Mock Interview Cancelled</span>
              </p>
              {suggestedDate && suggestedSlotEnd && suggestedSlotStart && (
                <Button
                  style={{ textTransform: "none", whiteSpace: "normal", fontSize: "1rem", width: '100%', borderRadius: '0px' }}
                  variant="contained"
                  onClick={handleOpen}
                  className="font-medium"
                >
                  Choose Another Slot
                </Button>
              )}
              {/* <Modal open={open} onClose={handleClose}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    boxShadow: 24,
                    overflow: "hidden",
                    minWidth: "750px",
                  }}
                >
                  <div className="flex flex-row justify-between gap-2 bg-blue-500 p-2">
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "white",
                        mx: 2,
                      }}
                    >
                      Request a Mock Interview (with Feedback Session)
                    </Typography>
                    <IconButton onClick={handleClose}>
                      <CancelIcon sx={{ color: "white" }} />
                    </IconButton>
                  </div>
                  <div className="grid grid-cols-2 gap-3">
                    <div className="col-span-1 m-2 flex flex-row justify-center">
                      <div>
                        <Typography
                          id="modal-modal-title"
                          variant="h5"
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            my: 1,
                          }}
                        >
                          To: {name}
                        </Typography>
                        <div className="w-full flex flex-row justify-center my-1">
                          <Calendar
                            minDate={new Date().setDate(new Date().getDate())}
                            zIndex={0}
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(new Date(e))}
                            mapDays={({ date, selectedDate, isSameDate }) => {
                              let props = {};
                              if (isSameDate(date, selectedDate)) {
                                props.style = {
                                  color: "#fff",
                                  backgroundColor: "#00a86b",
                                  border: "1px solid #000",
                                };
                                return props;
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 m-2 my-2 flex flex-row justify-center items-center">
                      <div>
                        <Typography
                          variant="h5"
                          component="h5"
                          sx={{ mb: "2px" }}
                        >
                          Choose a Profile
                        </Typography>
                        <RadioGroup
                          id="profile"
                          name="profile"
                          row
                          value={requestData.profile || ""}
                          onChange={handleRequestData}
                          sx={{
                            maxHeight: "100px",
                            overflowX: "auto",
                          }}
                        >
                          {profiles.map((profileItem) => (
                            <FormControlLabel
                              value={profileItem}
                              control={<Radio sx={{ py: "2px" }} />}
                              label={profileItem}
                            />
                          ))}
                        </RadioGroup>
                        <hr className="mx-4 my-3 border-black" />
                        <div className="relative border-2 border-gray-400 rounded p-1 my-1 flex flex-wrap">
                          <div className="flex mr-3">
                            <div className="inline-block rounded-full w-4 h-4 mx-1 bg-green-500"></div>
                            Available
                          </div>
                          <div className="flex mr-3">
                            <div className="inline-block rounded-full w-4 h-4 mx-1 bg-orange-400"></div>
                            Conditionally Available
                          </div>
                          <div className="flex mr-3">
                            <div className="inline-block rounded-full w-4 h-4 mx-1 bg-red-500"></div>
                            Occupied
                          </div>
                          <div className="absolute right-0 flex mr-3 text-blue-600 w-5 cursor-pointer">
                            <Tooltip
                              title={
                                <>
                                  <p className="my-1">
                                    Green: The slot is completely available to
                                    you
                                  </p>
                                  <p className="my-1">
                                    Orange: Another student has already chosen
                                    the slot, but is yet to pay and confirm.
                                    You can still request that slot, and pay
                                    first after acceptance to confirm
                                  </p>
                                  <p className="my-1">
                                    Red: The slot is not available to you,
                                    because another student has already
                                    confirmed the slot
                                  </p>
                                </>
                              }
                            >
                              <img
                                src={require("../../../images/exclamation.png")}
                                alt="Description"
                                style={{ transform: "rotateZ(180deg)" }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                        <Typography variant="h5" component="h5">
                          Choose a Slot
                        </Typography>
                        <RadioGroup
                          row
                          name="slot"
                          id="slot"
                          value={requestData.slot || ""}
                          onChange={handleRequestData}
                          sx={{ maxHeight: "150px", overflowX: "auto" }}
                        >
                          {slotsArray?.length !== 0 ? (
                            slotsArray.map(
                              (item) =>
                                (compareSlots(item[0]) ||
                                  !compareDate(selectedDate, new Date())) &&
                                settingColor(item[0]) === true && (
                                  <div className={classes}>
                                    <FormControlLabel
                                      value={"#" + item[0] + ":" + item[1]}
                                      key={"#" + item[0] + ":" + item[1]}
                                      control={<Radio sx={{ py: 0 }} />}
                                      label={
                                        t24HDecimalTo12HFormat(item[0]) +
                                        " - " +
                                        t24HDecimalTo12HFormat(item[1])
                                      }
                                      disabled={classes.includes("red")}
                                    />
                                  </div>
                                )
                            )
                          ) : (
                            <p className="text-center">
                              No slots available on{" "}
                              <span className="font-bold">
                                {moment(selectedDate).format("Do MMM, YYYY")}
                              </span>
                            </p>
                          )}
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-row justify-center my-2">
                    <Button
                      color="success"
                      variant="contained"
                      disabled={!sendBtnDisabled}
                      onClick={() => {
                        console.log(
                          "date: ",
                          moment(selectedDate).format("DD/MM/YYYY")
                        );
                        console.log(
                          "requestData Before sending to checkInterviewerAvailability(): ",
                          requestData
                        );
                        console.log("Sending Request...");
                        checkInterviewerAvailability(requestData).then(
                          (isInterviewerAvailable) => {
                            if (isInterviewerAvailable)
                              addEntryToInterviewReqTable(requestData);
                          }
                        );
                        handleClose();
                      }}
                    >
                      Send Request
                    </Button>
                  </div>
                </Box>
              </Modal> */}
              <Modal class open={open} onClose={handleClose}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    boxShadow: 24,
                    overflow: "hidden",
                    maxWidth: "750px",
                    width: "95%",
                  }}
                >
                  <div className="flex flex-row justify-between gap-2 bg-blue-500 p-2">
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "white",
                        mx: 2,
                      }}
                      className="slot-headding"
                    >
                      Request a Mock Interview (with Feedback Session)
                    </Typography>
                    <IconButton onClick={handleClose}>
                      <CancelIcon sx={{ color: "white" }} />
                    </IconButton>
                  </div>
                  {coreprofiles.length > 0 && specializations && specializations?.map(s => s.subDomains.length === 0).includes(false) ? <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "start",
                      background: "transparent",
                      pointerEvents: "none"
                    }}
                  >
                    <div className="tt ml-auto mr-2">
                      <InfoOutlined
                        sx={{ color: "rgb(83, 81, 81)", fontSize: "20px", pointerEvents: "auto" }}
                      />
                      <span style={{ background: 'black', color: 'white', padding: '3px 5px' }} className="tttext">
                        Choosing a specialization for a Core Profile is not mandatory
                      </span>
                    </div>
                  </div> : null}
                  <div className="grid grid-cols-2 max-[600px]:grid-cols-1 gap-3">
                    <div className="col-span-1 m-2 flex flex-row justify-center">
                      <div>
                        <Typography
                          id="modal-modal-title"
                          variant="h5"
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            my: 1,
                          }}
                          style={{ marginBottom: "20px" }}
                          className="slot-name"
                        >
                          To: {name}
                        </Typography>
                        <div className="w-full flex flex-row justify-center my-1 hide-picker">
                          <Calendar
                            minDate={new Date().setDate(new Date().getDate())}
                            zIndex={0}
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(new Date(e))}
                            mapDays={({ date, selectedDate, isSameDate }) => {
                              let props = {};
                              if (isSameDate(date, selectedDate)) {
                                props.style = {
                                  color: "#fff",
                                  backgroundColor: "#00a86b",
                                  border: "1px solid #000",
                                };
                                return props;
                              }
                            }}
                          />
                        </div>
                        {/* <MuiPickersUtilsProvider className="s-mobile-card" utils={LocalizedUtils}>
                        <DatePicker

                          style={{ flex: 1, }}
                          disablePast
                          placeholder="Select Date"
                          zIndex={0}
                          value={selectedDate}
                          showTabs={false}
                          onChange={(e) => { setSelectedDate(new Date(e)) }}
                        // onChange={(e) => setSelectedDate(new Date(e))}
                        />
                      </MuiPickersUtilsProvider> */}
                        {/* <div className="s-mobile-card" style={{ position: 'relative' }}>
                        <span style={{ fontWeight: '500', lineHeight: '30px', marginRight: '10px' }}>Select Date: </span><button class="button-13" onClick={() => setDatePickerMobile(!datePickerMobile)} role="button">{selectedDate !== ""
                          ? moment(selectedDate).format("DD MMM YYYY") + " "
                          : ""} <AiTwotoneCalendar style={{ display: 'inline-block', verticalAlign: 'text-top' }} /></button>
                        {
                          datePickerMobile && <Calendar
                            className="s-mobile-calendar-calendar"
                            minDate={new Date().setDate(new Date().getDate())}
                            zIndex={1}
                            value={selectedDate}
                            onChange={(e) => { setSelectedDate(new Date(e)); setDatePickerMobile(!datePickerMobile) }}
                            mapDays={({ date, selectedDate, isSameDate }) => {
                              let props = {};
                              if (isSameDate(date, selectedDate)) {
                                props.style = {
                                  color: "#fff",
                                  backgroundColor: "#00a86b",
                                  border: "1px solid #000"
                                };
                                return props;
                              }
                            }}
                          />
                        }
                      </div> */}
                        <div
                          className="s-mobile-card"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <div className="tt ml-auto">
                            <span
                              style={{
                                fontWeight: "500",
                                lineHeight: "30px",
                                marginRight: "10px",
                              }}
                            >
                              Select Date:{" "}
                            </span>
                            <button
                              class="button-13"
                              onClick={() =>
                                setDatePickerMobile(!datePickerMobile)
                              }
                              role="button"
                            >
                              {selectedDate !== ""
                                ? moment(selectedDate).format("DD MMM YYYY") + " "
                                : ""}{" "}
                              <AiTwotoneCalendar
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "text-top",
                                }}
                              />
                            </button>
                            <span
                              style={{
                                background: "black",
                                color: "white",
                                padding: "3px 5px",
                                top: "-146px",
                                textAlign: "left",
                              }}
                              className="calendar-mobile-tooltip tttext"
                            >
                              <Calendar
                                className="s-mobile-calendar-calendar"
                                minDate={new Date().setDate(new Date().getDate())}
                                zIndex={1}
                                value={selectedDate}
                                onChange={(e) => {
                                  setSelectedDate(new Date(e));
                                  setDatePickerMobile(!datePickerMobile);
                                }}
                                mapDays={({ date, selectedDate, isSameDate }) => {
                                  let props = {};
                                  if (isSameDate(date, selectedDate)) {
                                    props.style = {
                                      color: "#fff",
                                      backgroundColor: "#00a86b",
                                      border: "1px solid #000",
                                    };
                                    return props;
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 m-2 my-2 flex flex-row justify-center items-center">
                      <div>
                        <Typography
                          variant="h5"
                          component="h5"
                          sx={{ mb: "2px" }}
                          className="heading1"
                        >
                          Choose a Profile
                        </Typography>
                        <RadioGroup
                          id="profile"
                          name="profile"
                          row
                          value={requestData.profile || ""}
                          onChange={handleRequestData}
                          sx={{
                            maxHeight: "100px",
                            overflowX: "auto",
                          }}
                        >
                          {profiles?.map((profileItem) =>
                            coreprofiles?.includes(profileItem) ? null : (
                              <FormControlLabel
                                value={profileItem}
                                control={<Radio sx={{
                                  verticalAlign: "middle",
                                  py: { xl: 0.5, lg: 0.5, md: 0.5, sm: 0, xs: 0 }
                                }} />}
                                label={profileItem}
                                className="normalText"
                              />
                            )
                          )}
                          {
                            coreprofiles.map((coreProfile) => (
                              <div style={{
                                display: "flex",
                                flexDirection: "column"
                              }}>
                                <FormControlLabel
                                  value={coreProfile}
                                  control={<Radio sx={{
                                    verticalAlign: "middle",
                                    py: { xl: 0.5, lg: 0.5, md: 0.5, sm: 0, xs: 0 }
                                  }} />}
                                  label={coreProfile}
                                  className="normalText"
                                />
                                {specializations.map((spec) => (
                                  spec.profile === requestData.profile && spec.profile === coreProfile ? (


                                    spec.subDomains.length > 0 ?
                                      <Select
                                        displayEmpty
                                        required
                                        sx={{
                                          // transform: "scale(0.8)",
                                          ml: 4,
                                          maxWidth: "300px",
                                          whiteSpace: "break-spaces",
                                          pr: 3,
                                          pl: 1
                                        }}
                                        name="subprofile"
                                        renderValue={() => (
                                          requestData.subprofile || "Select Specialization"
                                        )}
                                        MenuProps={MenuProps}
                                        value={
                                          requestData.subprofile
                                        }
                                        onChange={handleRequestData}
                                      >
                                        {
                                          ["none", ...spec.subDomains]
                                            .map((sD) => (
                                              <MenuItem sx={{
                                                whiteSpace: "break-spaces",
                                                '&:nth-child(even)': {
                                                  backgroundColor: "rgba(151,151,151,0.2)"
                                                },
                                                '&:nth-child(odd):hover': {
                                                  backgroundColor: "rgba(255,255,255,0.6)"
                                                }
                                              }} value={sD}>{sD[0].toUpperCase() + sD.substring(1)}</MenuItem>
                                            ))
                                        }
                                      </Select> : null


                                  ) : null
                                ))
                                }
                              </div>
                            ))

                          }
                        </RadioGroup>
                        <hr className="mx-4 my-3 border-black" />
                        <div className="relative border-2 border-gray-400 rounded p-1 my-1 flex flex-wrap">
                          <div className="flex mr-3">
                            <div className="inline-block rounded-full w-4 h-4 mx-1 bg-green-500"></div>
                            Available
                          </div>
                          <div className="flex mr-3">
                            <div className="inline-block rounded-full w-4 h-4 mx-1 bg-orange-400"></div>
                            Conditionally Available
                          </div>
                          <div className="flex mr-3">
                            <div className="inline-block rounded-full w-4 h-4 mx-1 bg-red-500"></div>
                            Occupied
                          </div>
                          <div className="absolute right-0 flex mr-3 text-blue-600 w-5 cursor-pointer">
                            {/* <Tooltip
                            open={toolTip}

                            disableFocusListener
                            title={
                              <>
                                <p className="my-1">
                                  Green: The slot is completely available to you
                                </p>
                                <p className="my-1">
                                  Orange: Another student has already chosen the
                                  slot, but is yet to pay and confirm. You can
                                  still request that slot, and pay first after
                                  acceptance to confirm
                                </p>
                                <p className="my-1">
                                  Red: The slot is not available to you, because
                                  another student has already confirmed the slot
                                </p>
                              </>
                            }
                          >
                            <img
                              onClick={() => setToolTip(!toolTip)}
                              src={require("../../../images/exclamation.png")}
                              alt="Description"
                              style={{ transform: "rotateZ(180deg)" }}
                            />
                          </Tooltip> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div className="tt ml-auto">
                                <InfoOutlined
                                  sx={{
                                    color: "rgb(83, 81, 81)",
                                    fontSize: "20px",
                                  }}
                                />
                                <span
                                  style={{
                                    background: "black",
                                    color: "white",
                                    padding: "3px 5px",
                                    top: "-146px",
                                    textAlign: "left",
                                  }}
                                  className="tttext"
                                >
                                  <p className="my-1 mb-[0.5rem]">
                                    <span
                                      style={{
                                        color: "lime",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Green:{" "}
                                    </span>
                                    The slot is completely available to you
                                  </p>
                                  <p className="my-1 mb-[0.5rem]">
                                    <span
                                      style={{
                                        color: "orange",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Orange:{" "}
                                    </span>
                                    Another student has already chosen the slot,
                                    but is yet to pay and confirm. You can still
                                    request that slot, and pay first after
                                    acceptance to confirm
                                  </p>
                                  <p className="my-1">
                                    <span
                                      style={{ color: "red", fontWeight: "bold" }}
                                    >
                                      Red:{" "}
                                    </span>
                                    The slot is not available to you, because
                                    another student has already confirmed the slot
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Typography
                          variant="h5"
                          component="h5"
                          className="heading1"
                        >
                          Choose a Slot
                        </Typography>
                        <RadioGroup
                          row
                          name="slot"
                          id="slot"
                          value={requestData.slot || ""}
                          onChange={handleRequestData}
                          sx={{ maxHeight: "150px", overflowX: "auto" }}
                        >
                          {slotsArray?.length !== 0 ? (
                            slotsArray.map(
                              (item) =>
                                (compareSlots(item[0]) ||
                                  !compareDate(selectedDate, new Date())) &&
                                settingColor(item[0]) === true && (
                                  <div className={classes}>
                                    <FormControlLabel
                                      value={"#" + item[0] + ":" + item[1]}
                                      key={"#" + item[0] + ":" + item[1]}
                                      control={<Radio sx={{ py: 0 }} />}
                                      label={
                                        t24HDecimalTo12HFormat(item[0]) +
                                        " - " +
                                        t24HDecimalTo12HFormat(item[1])
                                      }
                                      disabled={classes?.includes("red")}
                                    />
                                  </div>
                                )
                            )
                          ) : (
                            <p className="text-center">
                              No slots available on{" "}
                              <span className="font-bold">
                                {moment(selectedDate).format("Do MMM, YYYY")}
                              </span>
                            </p>
                          )}
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full flex flex-row justify-center my-2"
                    style={{
                      background: " #ffdede",
                      margin: "0px",
                      padding: "8px",
                    }}
                  >
                    <Button
                      color="success"
                      variant="contained"
                      disabled={!sendBtnDisabled}
                      // onClick={() => {
                      //   console.log(
                      //     "date: ",
                      //     moment(selectedDate).format("DD/MM/YYYY")
                      //   );
                      //   console.log(
                      //     "requestData Before sending to checkInterviewerAvailability(): ",
                      //     requestData
                      //   );
                      //   console.log("Sending Request...");
                      //   checkInterviewerAvailability(requestData).then(
                      //     (isInterviewerAvailable) => {
                      //       if (isInterviewerAvailable)
                      //         addEntryToInterviewReqTable(requestData);
                      //     }
                      //   );
                      //   handleClose();
                      // }}
                      onClick={() => {
                        console.log(
                          "date: ",
                          moment(selectedDate).format("DD/MM/YYYY")
                        );
                        console.log(
                          "requestData Before sending to checkInterviewerAvailability(): ",
                          requestData
                        );
                        console.log("Sending Request...");
                        checkInterviewerAvailability(requestData).then(
                          (isInterviewerAvailable) => {
                            if (isInterviewerAvailable)
                              addEntryToInterviewReqTable(requestData);
                          }
                        );
                        handleClose();
                      }}
                    >
                      Send Request
                    </Button>
                  </div>
                </Box>
              </Modal>
              {isSlotAlreadyBooked ? (
                <SlotAlreadyBooked
                  isSlotAlreadyBooked={isSlotAlreadyBooked}
                  slotBookedModalHandler={slotBookedModalHandler}
                />
              ) : null}
            </div>

          </div>
        </div>

      </div>

    </>
  );
};

export default CanceledInterview;