import { Button } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router";
import UserContext from "../../contexts/UserContext";
import { BASE } from "../../constants/endpoints";
import { INTERVIEWER_RECIEVED_REQUESTS_ROUTE, STUDENT_FIND_AN_INTERVIEWER_ROUTE } from "../../constants/routes";
import { toastStyle } from "../../utility/helper";
import "./Welcome.css";
import { Window } from "@mui/icons-material";
import { Oval } from "react-loader-spinner";

const DeactivatedWelcome = () => {
  const {user} = useContext(UserContext)
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const handleSubmit = async(req,res) => {
    setLoader(true);
    axios.post(`${BASE}/send/int/reactivate/${user.id}`).then((res)=>{
        console.log(res);
        toast.success(`Account Reactivated Successfully, Redirecting to main page`, {
            ...toastStyle.success,
          });
        setTimeout(() => {
            window.location.href = (INTERVIEWER_RECIEVED_REQUESTS_ROUTE)
            setLoader(false)
        }, 5000);
    }).catch((e)=>{
        console.log(e)
    })
  }
  if(loader){
    return <div style={{height:'100vh', width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}><Oval
    height={120}
    width={120}
    color="#306bc9"
    wrapperStyle={{padding:"50%, 50%"}}
    wrapperClass=""
    visible={true}
    ariaLabel="oval-loading"
    secondaryColor="#90caf9"
    strokeWidth={2}
    strokeWidthSecondary={2}
    />
    </div>
  }
  return (
    <div className="welcome">
      <div className="welcome__box">
        <h1>
          <span>Oops!</span>
          <br />
          You had deactivated your account, but we are really glad to see you back!
        </h1>
        <Button onClick={handleSubmit} style={{marginTop:'15px'}} variant="contained">Reactivate Your Account</Button>
        <p style={{marginTop:'5px', fontWeight:'normal', fontSize:'15px'}}>(It'll bring back the account as it was)</p>
      </div>
    </div>
  );
};

export default DeactivatedWelcome;
