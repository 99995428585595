import React, { useState, useEffect, useRef, useContext } from 'react';
import { ZoomContext, ZoomMediaContext } from '../../Context/globalContext';
function Timer({ timerColor, seconds, setSeconds, videoMicTime, setVideoMicTime, idleTime, setIdleTime, isMuted, videoStarted, isShareScreen }) {
    const client = useContext(ZoomContext);
    const mediaStream = useContext(ZoomMediaContext);
    const intervalRef = useRef(null);
    const activityInterval = useRef(null);
    const idleInterval = useRef(null);
    const [isTimerRunning, setIsTimerRunning] = useState(false);

    useEffect(() => {
        const updateActivityTimers = () => {
            activityInterval.current = setInterval(() => {
                setVideoMicTime((prev) => {
                    const updatedTime = {
                        mic: prev.mic + (isMuted ? 0 : 1),
                        video: prev.video + (videoStarted ? 1 : 0),
                        screen_share: prev.screen_share + (isShareScreen ? 1 : 0),
                    };
                    return updatedTime;
                });
            }, 1000);
        };

        updateActivityTimers();

        return () => {
            clearInterval(activityInterval.current);
        };
    }, [isMuted, videoStarted, isShareScreen]);

    useEffect(() => {
        if (client.getAllUser().length < 2) {
            idleInterval.current = setInterval(() => {
                setIdleTime((prev) => {
                    return prev + 1;
                })
            }, 1000)
        } else {
            setIdleTime(0);
            clearInterval(idleInterval.current);
        }
    }, [client.getAllUser().length])

    useEffect(() => {
        console.log("++++", videoMicTime)
    }, [videoMicTime])

    const handleUserAdded = (payload) => {
        if (!isTimerRunning) {
            const numParticipants = client.getAllUser().length;
            if (numParticipants >= 2) {
                setIsTimerRunning(true);
            }
        }
    }
    const handlleUserRemoved = (payload) => {
        if (client.getAllUser().length < 2)
            setIsTimerRunning(false);
    }

    const handleUserUpdated = (payload) => {
        console.log("User updated in Timer called", payload);
        if (payload.muted === false) {

        } else {

        }
    }

    const handleConnectionChange = (payload) => {
        if (payload.state === "Closed") {
            setIsTimerRunning(false);
            clearInterval(activityInterval.current);
        }
    }
    useEffect(() => {
        handleUserAdded("Starting run")
    }, [])
    useEffect(() => {
        client.on("user-removed", handlleUserRemoved);
        client.on("user-added", handleUserAdded);
        client.on("user-updated", handleUserUpdated)
        client.on("connection-change", handleConnectionChange)
        return () => {
            client.off("user-added", handleUserAdded);
            client.off("user-removed", handlleUserRemoved);
            client.off("user-updated", handleUserUpdated)
            client.off("connection-change", handleConnectionChange)



        }

    }, [client, mediaStream]);

    useEffect(() => {
        if (isTimerRunning) {
            intervalRef.current = setInterval(() => {
                setSeconds(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(intervalRef.current);
        }

        return () => clearInterval(intervalRef.current);
    }, [isTimerRunning]);

    const formatTime = (time) => {
        const hours = String(Math.floor(time / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0');
        const seconds = String(time % 60).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    return (
        <div style={{ marginLeft: "10px", color: timerColor }}>{formatTime(seconds)}</div>
    );
}

export default Timer;
