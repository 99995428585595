import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import axios from "axios";
import "../Dashboard/ProfileForm.css";
import toast from "react-hot-toast";
import { toastStyle } from "../../utility/helper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Helmet } from "react-helmet";
import { BASE } from "../../constants/endpoints";
import Navbarlogedin from "./Navbarlogedin";
import { Button, FormControlLabel, FormGroup, RadioGroup } from "@mui/material";
import { COMPANY_NAME } from "../../constants/variables";
import UserContext from "../../contexts/UserContext";
import FileBase from "react-file-base64";
import { Oval } from "react-loader-spinner";
import Radio from "@mui/material/Radio";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import FormControl from "@mui/material/FormControl";
import { Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import './addStudents.css'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const savePaymentDetails = async (req, admin) => {
  axios
    .post(`${BASE}/payment`, { req, admin })
    .then((res) => {
      axios.patch(`${BASE}/send/updateAdminPayment/${admin}`);
      // setTimeout(() => {
      //   window.location.reload(true);
      // }, 500);
    })
    .catch((err) => {
      console.log(err);
    });
};
const AddStudents = () => {
  const location = useLocation();
  const { user, setUser } = useContext(UserContext);
  const [userData, setUserData] = useState({});
  const [branch, setBranch] = useState("");
  const [csv, setCsv] = useState(userData.csvLink);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [checked, setChecked] = useState();
  const [payment, setPayment] = useState(
    userData.iPaymentPref == 0 ? 2 : userData.iPaymentPref
  );
  const [isPayment, setIsPayment] = useState(false);
  const [newImage, setnewImage] = useState("");
  const [loaded, setLoaded] = useState(true);
  const [noOfInterviews, setNoOfInterviews] = useState(0);
  const [amount, setAmount] = useState(0);
  const [sheets, setSheets] = useState(userData.sheetsURL);
  const [hideReset, setreset] = useState(true);
  const navigate = useNavigate();
  const [showResetPopup, setShowResetPopup] = useState(false);
  const [state2, setState2] = React.useState({
    aCheckbox1: user.aCheckbox1,
    aCheckbox2: user.aCheckbox2,
  });

  const savePaymentDetailsforOption1 = (admin) => {
    setreset(false);
    axios.patch(`${BASE}/send/updateAdminPayment/${admin}`);
    // setTimeout(() => {
    //   window.location.reload(true);
    // }, 500);
  };

  async function displayRazorpay() {
    return new Promise(async function(resolve) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const data = await fetch(`${BASE}/razorpay`, {
      method: "POST",
      body: JSON.stringify({
        id: userData.id,
        amount : amount,
        admin: user.id,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((t) => t.json());

    const { id } = data;
    console.log(data);
    const options = {
      key: process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID,
        // process.env.REACT_APP_RAZORPAY_TEST_MODE === "ON"
        //   ? process.env.REACT_APP_RAZORPAY_TEST_KEY_ID
        //   : process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID,
      currency: data.currency,
      amount: amount * 100,
        // process.env.REACT_APP_RAZORPAY_1RUPEE_LIVE_MODE === "ON"
        //   ? amount * 100
        //   : amount * 100,
      order_id: id,
        // process.env.REACT_APP_RAZORPAY_TEST_MODE === "ON" ? undefined : id,
      // id: id,
      name: `Fyzen Career Solutions Pvt Ltd`,
      description: "Thank you for the confirmation!",
      image:
        "https://media-exp1.licdn.com/dms/image/C560BAQExXV7Oc9sZMA/company-logo_200_200/0/1629144901212?e=2147483647&v=beta&t=x7BZQmslIuY0OgabE7lpKn45A4m53N2jezdjXc6nT_Q",
      handler: async function (response) {
        console.log(
          "Inside handler function. Output from Razorpay. response= :-",
          response
        );
        console.log(response, "---12-3--2-213-12-33-12", user.id);
        setIsPayment(true);
        await savePaymentDetails(response, user.id);
        await generateCoupon(userData.csvLink);
        await handleReset();
        setreset(false);
      },
      prefill: {
        name: `${user.iname}`,
        email: `${user.iemail}`,
        contact: `${user.imobNumber}`,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    // paymentObject.on("payment.failed", function (response) {
    //   console.log("Before adding entry :", userData.id, id);
    //   axios.post(`${BASE}/failedPayments`, { id: userData.id });
    //   // alert(response.error.description);
    //   console.log("Payment failed :", response);
    // });
    });
  }
  const [fieldError, setFieldError] = useState({
    Pos: false,
    program: false,
    ibranch: false,
  });
  const [teammates, setTeammates] = useState([
    {
      tName: "",
      tEmail: "",
      tPos: "",
      tCheckbox1: false,
      tCheckbox2: false,
    },
  ]);
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(user);
    console.log(userData);
  }, [location]);
  useEffect(() => {
    if(userData.number === 0 && userData.interviews !== 0){
      handleReset();
    }
  }, [userData.number, userData.interviews]);
  useEffect(() => {
    if(userData?.isPayment && userData?.isGenerated){
      handleReset();
    }
  }, [userData.isPayment, userData.isGenerated, location])
  useEffect(() => {
    Number(userData.number) &&
      Number(userData.interviews) &&
      setNoOfInterviews(Number(userData.number) * Number(userData.interviews));
  }, [userData.number, userData.interviews]);
  useEffect(() => {
    if (payment === 1 || payment === "1") {
      Number(userData.number) &&
        Number(userData.interviews) &&
        Number(userData.number) <= 500 &&
        setAmount(
          Math.round(
            Number(userData.number) * Number(userData.interviews) * 489 * 100
          ) / 100
        );

      Number(userData.number) > 500 &&
        Number(userData.number) <= 900 &&
        setAmount(
          Math.round(
            Number(userData.number) * Number(userData.interviews) * 475 * 100
          ) / 100
        );

      Number(userData.number) > 900 &&
        setAmount(
          Math.round(
            Number(userData.number) * Number(userData.interviews) * 449 * 100
          ) / 100
        );
    }
    if (payment === 3 || payment === "3") {
      Number(userData.number) &&
        Number(userData.interviews) &&
        Number(userData.number) <= 500 &&
        setAmount(
          (Math.round(
            Number(userData.number) * Number(userData.interviews) * 489
          ) *
            100) /
          200
        );

      Number(userData.number) > 500 &&
        Number(userData.number) <= 900 &&
        setAmount(
          Math.round(
            Number(userData.number) * Number(userData.interviews) * 475
          ) / 200
        );

      Number(userData.number) > 900 &&
        setAmount(
          Math.round(
            Number(userData.number) * Number(userData.interviews) * 449
          ) / 200
        );
    }
    if (payment === 2 || payment === "2") {
      setAmount(0);
    }
  }, [userData.number, userData.interviews, payment]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserData((userData) => ({ ...userData, [name]: value }));
  };
  const downloadCSV = (base64) => {
    return `data:text/csv;base64,${base64}`;
  };
  useEffect(() => {
    setSheets(user.sheetsURL);
    setUserData(user);
    setPayment(user.iPaymentPref);
    setIsPayment(user.isPayment);
  }, [user]);
  useEffect(() => {
    if (user.teamMates !== {}) {
      setTeammates(user.teamMates);
    }
  }, [user]);
  const handleChangeRadio = (event) => {
    console.log(checked);
    setChecked(event.target.value);
  };
  const handleChangeRadio2 = (event) => {
    console.log(payment);
    setPayment(event.target.value);
  };
  const handleSubmitCsv = async (base64) => {
    try {
      const res = await axios.patch(`${BASE}/send/admin/uploadcsv/${user.id}`, {
        base64,
      });

      if (res.data.msg !== "") {
        // for (let i = 0; i < res.data.duplicates.length; i++) {
        //   toast.error(res.data.duplicates[i], {
        //     ...toastStyle.error,
        //   });
        // }
        toast.error(res.data.msg, {
          ...toastStyle.error,
        });
        setCsv(!csv);
      }
      // else {
      // }
      toast.success(`CSV Uploaded Successfully`, {
        ...toastStyle.success,
      });
      console.log(userData, "--21-123-12-231-132-231");
      setUserData(res.data.admin);
      setCsv(false);
      // setTimeout(() => {
      //   window.location.reload(true);
      // }, 3000);
    } catch (error) {
      if (error.response.data?.numStudErr) {
        toast.error(
          `Please upload a csv file with atleast 10 students in beginning`,
          {
            ...toastStyle.error,
          }
        );
        setCsv(!csv);
      }
    }
  };
  const [generated, setGenerated] = useState(false);
  const [generateCouponLoading, setGenerateCouponLoading] = useState(false);
  const generateCoupon = async (base64) => {
    return new Promise(async function(resolve) {
    setGenerateCouponLoading(true)
    let am = 0;
    if (payment == 2) {
      if (userData.number <= 500) {
        am =
          Math.round(
            Number(userData.number) * Number(userData.interviews) * 489 * 100
          ) / 100;
      } else if (userData.number > 500 && userData.number <= 900) {
        am =
          Math.round(
            Number(userData.number) * Number(userData.interviews) * 475 * 100
          ) / 100;
      } else if (userData.number > 900) {
        am =
          Math.round(
            Number(userData.number) * Number(userData.interviews) * 449 * 100
          ) / 100;
      }
    }

    const res = await axios.patch(
      `${BASE}/send/admin/generateCouponCodes/${user.id}/${payment == 1 ? 0 : payment == 3 ? amount : am
      }`
    );
    toast.success(`Coupon generated Successfully`, {
      ...toastStyle.success,
    });
    setGenerated(true);
    setGenerateCouponLoading(false)
    handleSend();
    await handleReset();
  });
  };
  const handleSend = async (base64) => {
    const res = await axios.patch(
      `${BASE}/send/admin/sendCouponCodes/${user.id}`
    );
    toast.success(`Coupon Sent Successfully`, {
      ...toastStyle.success,
    });

    setreset(true);
    // setTimeout(() => {
    //   window.location.reload(true);
    // }, 2000);
  };
  const handlePrefChange = (e) => {
    const profile = e.target.value;
    if (profile)
      setUserData((userData) =>
        userData.profiles.indexOf(profile) === -1
          ? { ...userData, profiles: [profile] }
          : userData
      );
  };
  const handleChangeCheckBox2 = (event) => {
    setState2({
      ...state2,
      [event.target.name]: event.target.checked,
    });
  };
  const handleProfilePrefDelete = (label) =>
    setUserData((userData) => ({
      ...userData,
      profiles: userData.profiles.filter((item) => item !== label),
    }));
  // Handle Change for Targeted Companies
  const handleChangeTargetedCompanies = (index, profile, value) => {
    let tarComObj = [...teammates];
    tarComObj[index][profile] = value;
    setTeammates(tarComObj);
  };
  const handleBranchChange = () => {
    if (branch) {
      setUserData((userData) =>
        userData?.ibranches?.indexOf(branch) === -1
          ? { ...userData, ibranches: [...userData.ibranches, branch] }
          : userData
      );
      setBranch("");
    }
  };
  const handleBranchDelete = (label) => {
    setUserData((userData) => ({
      ...userData,
      ibranches: userData.ibranches.filter((item) => item !== label),
    }));
  };
  const handleFiles = (files) => { };
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    // if (!userData.title) {
    //   const box = document.getElementById("title");
    //   setErrs({ ...errs, title: true });
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // } else setErrs({ ...errs, title: false });

    // if (!userData.fname) {
    //   const box = document.getElementById("fname");
    //   box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // } else document.getElementById("fname").style.borderColor = "#E5E7EB";

    // if (!userData.lname) {
    //   const box = document.getElementById("lname");
    //   box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // } else document.getElementById("lname").style.borderColor = "#E5E7EB";
    // if (
    //   !(userData.mobNumber && isValidPhoneNumber(String(userData.mobNumber)))
    // ) {
    //   const box = document.getElementById("mobNum");
    //   box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // } else document.getElementById("mobNum").style.borderColor = "#e5e7eb";
    // if (!userData.institute) {
    //   setFieldError({ ...fieldError, institute: true });
    //   const box = document.getElementById("institute");
    //   box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // }
    // if (!userData.program) {
    //   setFieldError({ ...fieldError, program: true });
    //   const box = document.getElementById("program");
    //   box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // }
    // if (!userData.upiId) {
    //   setFieldError({ ...fieldError, upiId: true });
    //   const box = document.getElementById("upiId");
    //   box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // }
    // if (!userData.branch) {
    //   setFieldError({ ...fieldError, branch: true });
    //   const box = document.getElementById("branch");
    //   box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // }
    // if (!user.resume && !newResume) {
    //   setFieldError({ ...fieldError, resume: true });
    //   const box = document.getElementById("resume");
    //   box.style.borderColor = "#FF0000";
    //   window.scrollTo(0, box.offsetTop);
    //   return box.focus();
    // }
    setUpdateDetails(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const fd = new FormData();
      fd.append("hi", "hello");
      fd.append("studentinfo", userData);
      fd.append("comp", teammates);
      userData.teamMates = teammates;
      // if (imageSize) {
      //   const box = document.getElementById("image");
      //   return window.scrollTo(0, box.offsetTop);
      // }
      // if (resumeSize) {
      //   const box = document.getElementById("resume");
      //   return window.scrollTo(0, box.offsetTop);
      // }
      // if (newImage) {
      //   userData.photo = newImage;
      // }
      // if (newResume) {
      //   userData.resume = newResume;
      // }
      const { data } = await axios.patch(
        `${BASE}/send/adminUpdate/${user.id}`,
        { userData, state2, teammates, newImage, payment, prevTeammates: teammates },
        config
      );
      toast.success(`${"Saved Successfully"}`, {
        ...toastStyle.success,
      });
      setUserData(data);
      setPayment(data.iPaymentPref);
      setUpdateDetails(false);
      if (payment === "2" || payment === 2) {
        // navigate("/admin/current-students");
        setIsPayment(true);
        savePaymentDetailsforOption1(user.id);
        console.log(payment);
        await generateCoupon(userData.csvLink);
      } else {
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
      // window.location.href = `${process.env.REACT_APP_FRONTEND_SERVER_URL}/s/find-an-interviewer`;
    } catch (error) {
      toast.error(`${error.message}`, {
        ...toastStyle.error,
      });
    }
  };
  const handleGoogleSheets = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .patch(`${BASE}/send/admin/getSheets/${userData.id}`, { config })
      .then((res) => {
        setSheets(res.data);
        window.open(res.data, "_blank");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  if (updateDetails) {
    return (
      <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
        <Oval
          height={120}
          width={120}
          color="#306bc9"
          wrapperStyle={{ padding: "50%, 50%" }}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#90caf9"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }
  const handleReset = async () => {
    console.log("asdfghjklkjhgfdsasdfghjkl")
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .post(`${BASE}/send/admin/reset/${userData.id}`, { config })
      .then((res) => {
        console.log(res);
        toast.success(`Reset successful`, {
          ...toastStyle.success,
        });
        setGenerated(false);
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <Helmet>
        <title>{COMPANY_NAME} | Add Students</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Navbarlogedin />
      {loaded ? (
        <div className="profile-form py-1">
          {/* <h1 className="text-2xl font-medium text-center py-4 leading-3 text-blue-500 pt-5">
            You’re just a step away from registering your Institute at
            interwiu.com
          </h1> */}
          <h1
            className="text-2xl font-medium text-center py-4 leading-3 text-black-500"
            style={{ color: "rgb(9,90,84)" }}
          >
            Add Students
          </h1>
          <div
            className="profile-form-cont2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="profile-form-text">
              <div className="relative flex mr-4 my-2 w-[100%]" style={{ justifyContent: 'space-between', flexDirection: 'column-reverse', gap: '1rem' }}>
                <Button
                  // onClick={() => {
                  //   sheets && sheets !== ""
                  //     ? window.open(sheets, "_blank")
                  //     : handleGoogleSheets();
                  // }}
                  onClick={() => {
                    handleGoogleSheets();
                  }}
                  sx={{ paddingLeft: "0" }}
                  style={{
                    backgroundColor: "rgba(0,0,255,0.6)",
                    color: "white",
                    padding: "10px 10px",
                    width: '90%',
                    maxWidth: '300px',
                    alignSelf: 'baseline'
                  }}
                >
                  Open a Google Sheet
                </Button>
                {showResetPopup && (
                  <div className="popup w-[90%] max-w-[400px] flex flex-col" style={{height : "450px"}}>
                    <p className="text-[20px] text-center">Resetting at this stage will delete all students whom you had added earlier, and for whom you are yet to make the payment and generate coupon codes. So, if you would like to retain them here, you can add them also in the new list of students that you might be planning to upload now (if they are not there in the new list).</p>
                    <div
                      className="mt-[50px] flex justify-evenly"
                      style={{ width: "100%" }}
                    >
                      <Button variant="contained" color="success" onClick={handleReset}>
                        Reset
                      </Button>
                      <Button variant="contained" color="error" onClick={() => { setShowResetPopup(false) }}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
                {(user.isPayment === false || generated || user.isGenerated || user.number === 0) && (
                  <div className="" style={{ alignSelf: 'end' }}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        if(userData.number) setShowResetPopup(true);
                        else handleReset();
                      }}
                      color="error"
                      style={{ border: "2px solid red" }}
                    >
                      <strong>Reset</strong>
                    </Button>
                  </div>
                )}
              </div>
              <p className="text-[14px]">
                Please circulate the URL of above Google Sheet across the students of your institute and ask them to fill-in their respective details (Name, Gmail address, and WhatsApp number). Once all relevant students have filled-it up, please download this Google Sheet as a <strong>.csv</strong> file and upload it below.
              </p>
              {!user.isGenerated && user.interviews == 0 && (
                <div className="relative flex mr-4 my-2">
                  <div className="filebase" id="filebase">
                    <FileBase
                      type="file"
                      multiple={false}
                      onDone={async ({ base64, size }) => {
                        setCsv(base64);
                        // if(base64.split('/')[1].startsWith("csv")){
                        //   // toast.error("Please upload a .csv file only", {
                        //   //   ...toastStyle.error
                        //   // })
                        //   document.getElementById("nonCsvUpload").style.visibility = "hidden";
                        // }
                        // else{
                        //   document.getElementById("nonCsvUpload").style.visibility = "visible";
                        // }
                      }}
                    />
                  </div>
                </div>
              )}
              {csv && csv !== "" && csv !== userData.csvLink && (
                <>
                  <div className="relative flex mr-4 my-2">
                    <Button
                      onClick={() => {
                        console.log(csv);
                        if (csv.split('/')[1].startsWith("csv")) {
                          handleSubmitCsv(csv.split(",")[1])
                          // toast.error("Please upload a .csv file only", {
                          //   ...toastStyle.error
                          // })
                          // document.getElementById("nonCsvUpload").style.visibility = "visible";
                        }
                      }}
                      sx={{ paddingLeft: "0" }}
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        padding: "10px 10px",
                      }}
                    >
                      Upload CSV File
                    </Button>
                    {!csv.split('/')[1].startsWith("csv") && <p id="nonCsvUpload" style={{ color: "red", paddingLeft: "10px" }}>Please upload a csv file only</p>}
                  </div>
                </>
              )}
              {userData.csvLink && userData.csvLink !== "" && (
                <div className="relative my-2 max-[600px]:text-[14px]" id="filebase">
                  <span>
                    <strong>File Uploaded Successfully!</strong> <br />
                    You can&nbsp;{" "}
                  </span>
                  <a
                    href={downloadCSV(userData.csvLink)}
                    download={`Students_Data.csv`}
                    style={{ color: "blue", fontSize: '14px' }}
                  >
                    Download &nbsp;
                  </a>
                  <span>
                    the last uploaded file. In order to update students
                    information, you can reupload the file using the above
                    button.
                  </span>
                </div>
              )}
            </div>

          </div>
          <form onSubmit={handleSubmit}>
            <div className="profile-form-cont2">
              <div className="profile-form-text">
                <div className="institution cust-row flex max-[600px]:flex-col max-[600px]:mb-[30px]">
                  <div style={{ width: '100%' }} className="cust-row flex max-[600px]:m-[0px]">
                    <div className="element w-50">
                      <label htmlFor="number">
                        Total No. of Students interested for Mock Interviews
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        name="number"
                        className="border-2 w-[100%] rounded h-[56px] max-[600px]:h-[40px]"
                        placeholder="Number of Students"
                        displayEmpty
                        error={fieldError.institute}
                        value={userData.number}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        autoComplete="nope"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="element">
                    <label htmlFor="interviews">
                      No. of Mock Interviews to be booked per student
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      value={userData.interviews || ""}
                      name="interviews"
                      id="interviews"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className="h-[56px] max-[600px]:h-[40px]"
                      displayEmpty
                      MenuProps={MenuProps}
                      sx={{ width: 1 }}
                      inputProps={{ "aria-label": "Without label" }}
                      defaultValue=""
                      disabled={
                        userData.isPayment === true ||
                        userData.number === 0 ||
                        ((payment === 1 ||
                          payment === "1" ||
                          payment === 3 ||
                          payment === "3") &&
                          userData.isPayment === false &&
                          userData.interviews === user.interviews &&
                          Number(payment) === Number(userData.iPaymentPref) &&
                          userData.number === user.number &&
                          amount !== 0)
                      }
                    >
                      <MenuItem hidden disabled value="">
                        Select No. of Interviews
                      </MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </div>
                </div>
                <p style={{ maxWidth: '300px', width: '90%' }} className="bg-red-500 p-[10px] text-white rounded max-[600px]:m-[auto]">
                  Total Number of Interviews = {noOfInterviews}
                </p>

                {userData.number > 0 && (
                  <div>
                    <Divider sx={{ marginTop: "20px" }}></Divider>
                    <FormControl
                      sx={{ mt: 1 }}
                      component="fieldset"
                      variant="standard"
                    >
                    <div style={{display : "flex", alignItems : "center"}}>
                      <label
                        htmlFor="ibranches"
                        style={{
                          color: "#095a54",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Your Plan
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <span>
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip className="tooltip" >
                            In the total charges of a Mock Interview, <br />i.e. INR {process.env.REACT_APP_STD_45MIN}, INR 400 is the <br />Interviewer's Fee (fixed) and INR 99 <br />is the Service Charge (discountable)
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            className="mr-[10px] rounded-full bg-black text-white p-1 w-[20px] scale-75"
                            icon={faInfo}
                            style={{ verticalAlign: "middle" }}
                          />
                        </OverlayTrigger>
                        </span>
                        </div>
                      <FormGroup>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            onChange={handleChangeRadio}
                            label="Silver Plan: [No. of Students <= 500] : 10% Discount in Service Charges"
                            checked={userData.number <= 500}
                            disabled={
                              userData.number > 500 ||
                              user.isPayment ||
                              (userData.isPayment === false &&
                                userData.interviews === user.interviews &&
                                Number(payment) ===
                                Number(userData.iPaymentPref) &&
                                userData.number === user.number &&
                                amount !== 0)
                            }
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            onChange={handleChangeRadio}
                            label="Gold Plan: [500 < No. of Students <= 900] : 25% Discount in Service Charges"
                            checked={
                              userData.number <= 900 && userData.number > 500
                            }
                            disabled={
                              userData.number > 900 ||
                              userData.number <= 500 ||
                              user.isPayment ||
                              (userData.isPayment === false &&
                                userData.interviews === user.interviews &&
                                Number(payment) ===
                                Number(userData.iPaymentPref) &&
                                userData.number === user.number &&
                                amount !== 0)
                            }
                          />
                          <FormControlLabel
                            value={3}
                            control={<Radio />}
                            onChange={handleChangeRadio}
                            label="Diamond Plan: [No. of Students > 900] : 50% Discount in Service Charges"
                            checked={userData.number > 900}
                            disabled={
                              userData.number <= 900 ||
                              user.isPayment ||
                              (userData.isPayment === false &&
                                userData.interviews === user.interviews &&
                                Number(payment) ===
                                Number(userData.iPaymentPref) &&
                                userData.number === user.number &&
                                amount !== 0)
                            }
                          />
                        </RadioGroup>
                      </FormGroup>
                    </FormControl>

                    <>
                      <p style={{ maxWidth: '300px', width: '90%' }} className="mt-5 p-[10px] text-white rounded bg-blue-500 min-w-[300px] max-[600px]:m-[auto]">
                        Total Amount ={" INR "}
                        {payment == 1
                          ? amount
                          : payment == 3
                            ? amount * 2
                            : userData.number <= 500
                              ? Math.round(
                                Number(userData.number) *
                                Number(userData.interviews) *
                                489 *
                                100
                              ) / 100
                              : userData.number <= 900 && userData.number > 500
                                ? Math.round(
                                  Number(userData.number) *
                                  Number(userData.interviews) *
                                  475 *
                                  100
                                ) / 100
                                : Math.round(
                                  Number(userData.number) *
                                  Number(userData.interviews) *
                                  449 *
                                  100
                                ) / 100}
                      </p>
                      <Divider sx={{ marginTop: "20px" }}></Divider>
                    </>
                  </div>
                )}
                <div>
                  {userData.number > 0 && (
                    <FormControl
                      sx={{ mt: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <label
                        htmlFor="ibranches"
                        style={{
                          color: "#095a54",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Payment Preference
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      {/* <FormLabel
                      component="legend"
                      sx={{
                        color: "#095a54",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      Payment Preference <span style={{ color: "red" }}>*</span>
                    </FormLabel> */}
                      <FormGroup>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            onChange={handleChangeRadio2}
                            label="Institute will pay the complete amount for mock interviews for all the students"
                            checked={payment === 1 || payment === "1"}
                            disabled={
                              user.isPayment ||
                              (userData.isPayment === false &&
                                userData.interviews === user.interviews &&
                                Number(payment) ===
                                Number(userData.iPaymentPref) &&
                                userData.number === user.number &&
                                amount !== 0)
                            }
                          />

                          <FormControlLabel
                            value={3}
                            control={<Radio />}
                            onChange={handleChangeRadio2}
                            label="Institute and Students will pay 50% each of the complete amount for each mock interview"
                            checked={payment === 3 || payment === "3"}
                            disabled={
                              user.isPayment ||
                              (userData.isPayment === false &&
                                userData.interviews === user.interviews &&
                                Number(payment) ===
                                Number(userData.iPaymentPref) &&
                                userData.number === user.number &&
                                amount !== 0)
                            }
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            onChange={handleChangeRadio2}
                            label="Students will pay the complete amount (on their own) for their mock interviews individually"
                            checked={payment === 2 || payment === "2"}
                            disabled={
                              user.isPayment ||
                              (userData.isPayment === false &&
                                userData.interviews === user.interviews &&
                                Number(payment) ===
                                Number(userData.iPaymentPref) &&
                                userData.number === user.number &&
                                amount !== 0)
                            }
                          />
                        </RadioGroup>
                      </FormGroup>
                    </FormControl>
                  )}
                  <Divider sx={{ marginTop: "20px" }}></Divider>
                </div>

                {(payment === 1 ||
                  payment === "1" ||
                  payment === 3 ||
                  payment === "3" ||
                  payment === 2 ||
                  payment === "2") &&
                  userData.number > 0 && (
                    <>
                      <p style={{ maxWidth: '300px', width: '90%' }} className="my-[20px] p-[10px] text-white rounded w-[90%] max-w-[250px]  bg-green-500 max-[600px]:mx-[auto]">
                        Amount {userData.isPayment === true ? "Paid" : "Payable"}{" "}
                        = {" INR "}{amount}
                      </p>
                      <Divider sx={{ marginTop: "20px" }}></Divider>
                    </>
                  )}
              </div>
            </div>
            {isPayment === false &&
              userData.isPayment === false &&
              userData.number !== 0 &&
              payment !== 0 &&
              userData.interviews > 0 &&
              (userData.interviews !== user.interviews ||
                Number(payment) !== Number(userData.iPaymentPref) ||
                userData.number !== user.number) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="px-2"
                >
                  <div></div>
                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="cust-btn create-btn"
                  >
                    {!user?.imobNumber ? "Create an Account" : (payment === "2" || payment === 2) ? "Save Details and Generate Coupon Codes" : "Save Details"}
                  </button>
                </div>
              )}
            {/* {userData.isPayment === false &&
              (userData.interviews !== user.interviews ||
                payment !== userData.iPaymentPref ||
                userData.number !== user.number) && (
                <Divider
                  sx={{ marginBottom: "20px", marginTop: "5px" }}
                ></Divider>
              )} */}
            {(payment === 1 ||
              payment === "1" ||
              payment === 3 ||
              payment === "3") &&
              isPayment === false &&
              userData.isPayment === false &&
              userData.interviews === user.interviews &&
              Number(payment) === Number(userData.iPaymentPref) &&
              userData.number === user.number &&
              user.number > 0 && (
                <div className="relative flex justify-center mr-4 mt-2 mb-2">
                  <form>
                    <Button
                      variant="contained"
                      onClick={displayRazorpay}
                      style={{
                        fontSize: "15px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      Pay & Generate Coupon Codes
                    </Button>
                  </form>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value=""
                    IconComponent={() => (
                      <FontAwesomeIcon
                        className="text-center text-white border-none absolute -z-1"
                        icon={faChevronDown}
                        style={{ pointerEvents: "none" }}
                      />
                    )}
                    className="h-[39px] w-[40px] flex items-center justify-center bg-[#1876D1] text-white border-none rounded-[5px]"
                  >
                    <MenuItem
                      onClick={() => {
                        navigate("/admin/current-students");
                      }}
                      className=""
                      value="pay-later"
                    >
                      PAY LATER
                    </MenuItem>
                  </Select>
                  {/* <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip className="tooltip ">
                        If you choose [Pay Later], you or any other admin <br />{" "}
                        can make the payment from the page of [Added Students]{" "}
                        <br /> on [Current Students] page
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      className="mr-[10px] rounded-full bg-white text-black p-2 w-[15px]"
                      icon={faInfo}
                      style={{
                        border: "1px solid black",
                        transform: "scale(0.75)",
                      }}
                    />
                  </OverlayTrigger> */}
                  <div className="tt ml-[10px]">
                    <InfoOutlined
                      sx={{
                        color: "rgb(83, 81, 81)",
                        fontSize: "20px",
                      }}
                    />
                    <span
                      style={{
                        background: "black",
                        color: "white",
                        padding: "3px 5px",
                        top: "-60px",
                        textAlign: "left",
                      }}
                      className="tttext"
                    >
                      If you choose [Pay Later], you or any other admin <br />{" "}
                      can make the payment from the page of [Added Students]{" "}
                      <br /> on [Current Students] page
                    </span>
                  </div>
                </div>
              )}
          </form>
          {(isPayment === true || isPayment === "true" || user.isPayment === true || user.isPayment === "true") && (
            <div className="profile-form-cont2">
              <div className="profile-form-text">
                {userData.csvLink && userData.csvLink !== "" && (
                  <div style={{ margin: '10px auto' }} className="relative flex my-2 max-[600px]:flex-col">
                    {/* {
                      !generateCouponLoading && userData.number === user.number && user.number > 0 &&
                      <Button
                        color="secondary"
                        onClick={() => generateCoupon(userData.csvLink)}
                        disabled={generated || user.isGenerated}
                        variant="contained"
                        className="max-[600px]:my-[1rem]"
                        style={{ margin: '1rem auto' }}
                      >
                        Generate Coupon Codes
                      </Button>} */}
                    {generateCouponLoading &&
                      <>
                        <div style={{ height: '7em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <div class="preloader">
                            <div class="preloader__square"></div>
                            <div class="preloader__square"></div>
                            <div class="preloader__square"></div>
                            <div class="preloader__square"></div>
                          </div>
                          <div class="status s-mobile-one-word-per-line">Generating Coupon Codes<span class="status__dot">.</span><span class="status__dot">.</span><span class="status__dot">.</span></div>
                        </div>
                      </>
                    }
                    {(generated || user.isGenerated) && (
                      <div className="max-[600px]:text-[14px]">
                        <p style={{ paddingLeft: 10 }}>
                          <i
                            class="fa fa-check-circle"
                            style={{ color: "#2aad05" }}
                          ></i>{" "}
                          Coupon Codes Generated Successfully
                        </p>
                        <p style={{ paddingLeft: 10 }}>
                          <i
                            class="fa fa-check-circle"
                            style={{ color: "#2aad05" }}
                          ></i>{" "}
                          The coupon code(s) have been sent to the students
                          individually via Gmail and WhatsApp
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {/* send coupon codes button */}
          {/* {(user.isPayment === true || user.isPayment === "true") && (
            <div className="profile-form-cont2">
              <div className="profile-form-text">
                {userData.csvLink && userData.csvLink !== "" && (
                  <div className="relative flex mr-4 my-2">
                    <Button onClick={() => handleSend()}>
                      Send Coupon Codes to Students
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )} */}
        </div>
      ) : (
        <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </>
  );
};

export default AddStudents;