import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import "./Welcome.css";

const Welcome = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="welcome">
        <div className="welcome__box">
            <h1><span>Congratulations!</span><br />You have successfully created an account as a Professional (i.e., an Interviewer)!</h1>
            <p>You will start receiving the Mock Interview Requests from students soon!</p>
            <p>Wish you a Great Experience at interwiu.com!</p>
            <p>For more details, please check your registered Gmail. </p>
        </div>
    </div>
  );
};

export default Welcome;
