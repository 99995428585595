import { Grid, Box, Chip, Badge, Stack } from '@mui/material';
import { Typography } from 'antd';
import { t24HDecimalTo12HFormat, convertDateFormat } from 'utility/helper';


function StudCardDetails(props) {
  const {
    subprofile,
    profile,
    price,
    slotStart,
    slotEnd,
    isRescheduled,
    reschedule,
    date,
    sessDuration,
    sessType
  } = props;

  return (
    <Box>
      <div className='flex justify-center w-full'>
        <div className={`
        ${{ mentorship: "bg-emerald-100", interview: "bg-orange-200", resume: "bg-emerald-100" }[sessType]}
         rounded-lg px-2`}>
          {{ interview: " Mock Interview", mentorship: " Mentorship Session", resume: " Resume Building Session" }[sessType]}
        </div>
      </div>
      <Box sx={{ display: 'flex', textAlign: "center", justifyContent: 'space-between', alignItems: 'flex-start', my: 1, ml: 2, mr: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 'fit-content' }}>
          <p className="text-base text-gray-400">Profile</p>
          <Chip
            label={subprofile ? `${subprofile} (${profile})` : profile}
            color="info"
            sx={{
              fontWeight: 'medium', height: 'auto', display: 'inline-flex', maxWidth: '100%', px: 0.5, flexShrink: 0,
              '& .MuiChip-label': {
                whiteSpace: 'pre-wrap', display: 'flex', flexWrap: 'wrap', px: 0.5,
                fontSize: { sm: '0.8rem' }
              }
            }}
          />
        </Box>
        <Grid container justifyContent={"space-around"} spacing={1} sx={{ minWidth: '65%', maxWidth: "85%", mr: 0 }}>
          <Grid item xs={9} sm={3} minWidth={"100px"}>
            <Box minWidth={"inherit"}>
              <p className="text-base text-gray-400">Price</p>
              <p className="text-lg">INR {(price + '').startsWith("INR ") ? price.split(" ")[1] : price}</p>
            </Box>
          </Grid>
          <Grid item xs={9} sm={3} minWidth={"100px"}>
            <Box minWidth={"inherit"}>
              <p className="text-base text-gray-400">Duration</p>
              <p className="text-lg">{sessDuration}&nbsp;Min</p>
            </Box>
          </Grid>
          <Grid item xs={9} sm={3} minWidth={"185px"} sx={{}}>
            <Box minWidth={"inherit"}>
              <p className="text-base text-gray-400">Slot</p>
              <span className="text-lg">
                {t24HDecimalTo12HFormat([true, "true"].includes(isRescheduled) ? reschedule.details?.reschFromTime : slotStart)}
              </span>
              <span> &nbsp; - &nbsp;</span>
              <span className="text-lg">{t24HDecimalTo12HFormat([true, "true"].includes(isRescheduled) ? reschedule.details?.reschToTime : slotEnd)}</span>
              <p className="text-lg">{convertDateFormat([true, "true"].includes(isRescheduled) ? reschedule.details?.reschDate || date : date)}</p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default StudCardDetails