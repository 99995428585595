import React from "react";
import { Link } from "react-router-dom";
import { HOME_ROUTE } from "../../constants/routes";
import "./NotAuthorised.css";
import { CircularProgress } from "@material-ui/core";
import { Oval } from "react-loader-spinner";

const NotAuthorised = () => {
  return (
    <div className="notAuthorised">
      <div className="notAuthorised__box">
        <div className="fullPageLoader" style={{ paddingTop: "80px" }}>
          <Oval
            height={120}
            width={120}
            color="#306bc9"
            wrapperStyle={{ padding: "50%, 50%" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#90caf9"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
        {/* <CircularProgress/> */}
        {/* <h1>
          <span>Not Authorized!</span>
          <br />
          You are not authorized to view the requested page!
        </h1>
        <p>
          If this is unexpected, please write to us here: <a href="mailto:support@interwiu.com">support@interwiu.com</a>
        </p>
        <p>We apologize for the inconvenience.</p>
        <p>Click here to redirect back to <Link style={{color: "#02A3E8"}} to={HOME_ROUTE}>Home</Link></p> */}
      </div>
    </div>
  );
};

export default NotAuthorised;
